import { FUND_VIEW_ROUTE_FACTORY, LENDER_VIEW_ROUTE_FACTORY } from '../../App.routes';

export const getEntityOrFundRoute = (
    props: {
        isFund?: boolean;
        shortCode: Maybe<string>;
    },
    queryParams?: { focus: string }
) => {
    return (props.isFund ? FUND_VIEW_ROUTE_FACTORY : LENDER_VIEW_ROUTE_FACTORY)(props.shortCode!, queryParams);
};
