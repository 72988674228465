import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, FormikProvider, useFormik } from 'formik';
import { Badge, Container, Dialog, DialogActions, DialogContent } from '@mui/material';
import { DocPurpose, DocumentInInput, DocumentStorage, KYCDocSubcategory, KYCDocType } from 'interfaces/types';
import { docPurposeLabels, DOCUMENTS_WITH_PRIVATE_ACCESS_CONFIGURABLE } from '../../Documents.const';
import { proxyAPIValidationError, submitFormikAndScrollToInvalidField } from 'lib/forms';
import { IKYCFileUploadForm, validationSchema } from './DocumentUploadDialog.const';
import { filesToContentFileInInput } from '../../../../hooks/useDocumentUpload';
import { LoadingSpinnerButton } from 'lib/buttons/LoadingSpinnerButton';
import { makeDialogState } from 'store/dialogState/makeDialogState';
import { DialogCloseButton } from 'lib/modals/DialogCloseButton';
import { dateToModelValue, fromDateISO } from 'lib/utils/date';
import { DocumentUploadForm } from './DocumentUploadForm';
import { NonFalsy, NOOP } from 'lib/utils/helpers';
import { kycDocTypeLabels } from 'App/App.const';
import { RowStack } from 'lib/cdk/RowStack';
import { KYCDocumentFragment } from '../../KYCDocument.fragment';
import { useAppSnackbar } from '../../../AppNotification';
import { useDocumentMutation } from './Document.mutation';
import { useKYCDocTypesQuery } from '../DocumentsSelectList/KYCDocTypes.query';

interface IDocumentUploadDialogValue {
    id?: UUID;
    docType?: Maybe<KYCDocType>;
    docSubcategory?: Maybe<KYCDocSubcategory>;
    validFromDate?: Maybe<LocalDate>;
    validToDate?: Maybe<LocalDate>;
    comment?: string | null;
    isPrivate?: boolean;
    name?: Maybe<string>;
    taxId?: Maybe<UUID>;
    docPurpose: DocPurpose;
    documents?: {
        displayName: Maybe<string>;
    }[];
}

interface IDocumentUploadDialogState {
    parentRef: UUID | undefined;
    parentName: string | undefined;
    value: IDocumentUploadDialogValue;
    onUploadSuccess?: (newDocumentId: UUID, purpose: DocPurpose) => void;
    onDocumentAssign?: (newDocument: KYCDocumentFragment, docPurpose: DocPurpose) => void;
    onSubmit?: (document: DocumentInInput) => Promise<void>;
    config?: IDocumentUploadDialogConfig;
    purpose?: DocPurpose;
    onClose?: () => void;
}

export const useDocumentUploadDialog = makeDialogState<IDocumentUploadDialogState>();

const getInitialValues = (value: IDocumentUploadDialogValue | undefined): Partial<IKYCFileUploadForm> => {
    const { docType, docPurpose, docSubcategory, documents, name, comment, validToDate, validFromDate, isPrivate } =
        value || {};
    const privateAccessConfigurable = docType && DOCUMENTS_WITH_PRIVATE_ACCESS_CONFIGURABLE.includes(docType);
    return {
        docPurpose: docPurpose || void 0,
        docType: docType || void 0,
        docSubcategory: docSubcategory || void 0,
        isPrivate: privateAccessConfigurable ? isPrivate ?? true : void 0,
        validFromDate: fromDateISO(validFromDate),
        validToDate: fromDateISO(validToDate),
        name: name || '',
        comment: comment || void 0,
        files:
            documents?.map(({ displayName }) => {
                return new File([], displayName || 'unknown file');
            }) || [],
    };
};

export interface IDocumentUploadDialogConfig {
    multipleFilesUpload?: boolean;
    docTypeChangeEnabled?: boolean;
    docPurposeChangeEnabled?: boolean;
    storage: DocumentStorage;
}

interface IDocumentUploadDialogProps {
    config: IDocumentUploadDialogConfig;
    updateData?: () => Promise<void>;
}

const getTitle = (docType: Maybe<KYCDocType> | undefined, parentName: string | undefined) => {
    const parentLabel = parentName ? `: ${parentName}` : '';
    const docTypeLabel = kycDocTypeLabels[docType!] || '';
    return `${docTypeLabel ? `${docTypeLabel}: ` : 'Add document'}${parentLabel}`;
};

export const DocumentUploadDialog: React.FC<IDocumentUploadDialogProps> = ({ config, updateData = NOOP }) => {
    const snackbar = useAppSnackbar();
    const [saveDocument, { error }] = useDocumentMutation();
    const [{ close }, isOpen, state] = useDocumentUploadDialog();
    const { value, parentRef, parentName } = state || {};
    const { onClose = NOOP, onSubmit, onUploadSuccess = NOOP, onDocumentAssign } = state || {};
    const multipleFilesUpload = state?.config?.multipleFilesUpload || config.multipleFilesUpload;
    const docTypeChangeEnabled = state?.config?.docTypeChangeEnabled || config.docTypeChangeEnabled;
    const docPurposeChangeEnabled = state?.config?.docPurposeChangeEnabled || config.docPurposeChangeEnabled;
    const docAssignFlowEnabled = Boolean(onDocumentAssign);

    const storage = state?.config?.storage || config.storage;
    const kycDocTypesQuery = useKYCDocTypesQuery({
        fetchPolicy: 'no-cache',
        skip: !isOpen,
        variables: { parentRef, storage },
    });

    const [prevValues, setPrevValues] = useState<Partial<typeof initialValues>>({});
    const initialValues = getInitialValues(value);

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        validate: proxyAPIValidationError(prevValues, error),
        onSubmit: async (values, { setSubmitting }): Promise<KYCDocumentFragment | undefined> => {
            setPrevValues(values);
            setSubmitting(true);
            try {
                const files = formik.values.files?.filter(NonFalsy) || [];
                const contentFiles = await filesToContentFileInInput(files);
                const docSubcategory = formik.values.docSubcategory || null;
                const document = {
                    id: value?.id,
                    parentRef,
                    docType: values?.docType,
                    docSubcategory,
                    validFromDate: dateToModelValue(formik.values.validFromDate!),
                    validToDate: dateToModelValue(formik.values.validToDate),
                    comment: formik.values.comment,
                    isPrivate: formik.values.isPrivate ?? null,
                    name: formik.values.name,
                    taxId: value?.taxId,
                    contentFiles,
                    storage,
                };

                const closeAndReset = () => {
                    close();
                    formik.resetForm();
                    if (!docAssignFlowEnabled) {
                        snackbar.show({ message: 'Files were uploaded' });
                    }
                };

                if (onSubmit) {
                    await onSubmit(document);
                    closeAndReset();
                } else {
                    const response = await saveDocument({ variables: { document } });
                    await updateData();
                    const newDocument = response.data?.document;
                    const docPurpose = formik.values.docPurpose;
                    if (newDocument) {
                        onUploadSuccess(newDocument.id, docPurpose!);
                    }
                    closeAndReset();
                    return newDocument;
                }
            } catch (e) {
                setSubmitting(false);
                snackbar.showError({ message: (e as Error).message || 'Failed to submit files' }, e);
            }
        },
    });
    const filesLength = formik.values.files?.length || 0;
    const docTypes = kycDocTypesQuery.data?.docTypes || [];

    const handleClose = () => {
        close();
        formik.resetForm();
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth={'sm'} data-testid={'DocumentUploadDialog'} fullWidth>
            <FormikProvider value={formik}>
                <Box component={Form}>
                    <DialogTitle>
                        {getTitle(value?.docType, parentName)}
                        <DialogCloseButton onClick={handleClose} />
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '70vh' }}>
                        <Container maxWidth={'sm'} sx={{ margin: '24px 0' }}>
                            <DocumentUploadForm
                                docTypes={docTypes}
                                config={{
                                    commentBoxEnabled: !value?.id,
                                    fileChangingEnabled: !value?.id,
                                    docPurposeChangeEnabled,
                                    multipleFilesUpload,
                                    docTypeChangeEnabled,
                                }}
                            />
                        </Container>
                    </DialogContent>
                    <DialogActions>
                        {docAssignFlowEnabled ? (
                            <RowStack spacing={2} flex={1}>
                                <Button color={'secondary'} onClick={handleClose} className={'mr-auto'}>
                                    Cancel
                                </Button>
                                <LoadingSpinnerButton
                                    label={'Save'}
                                    variant={'outlined'}
                                    testId={'SubmitButton'}
                                    spin={formik.isSubmitting}
                                    onClick={() => submitFormikAndScrollToInvalidField(formik)}
                                />
                                <LoadingSpinnerButton
                                    label={`Save & Add to ${docPurposeLabels[formik.values.docPurpose!]}`}
                                    testId={'SubmitAndAssignButton'}
                                    spin={formik.isSubmitting}
                                    onClick={async () => {
                                        const newDocument = await submitFormikAndScrollToInvalidField(formik);
                                        const docPurpose = formik.values.docPurpose;
                                        if (newDocument && onDocumentAssign && docPurpose) {
                                            onDocumentAssign(newDocument, docPurpose);
                                        }
                                    }}
                                />
                            </RowStack>
                        ) : (
                            <Badge badgeContent={filesLength} color={'success'} invisible={filesLength <= 1}>
                                <LoadingSpinnerButton
                                    testId={'SubmitButton'}
                                    label={'Save'}
                                    spin={formik.isSubmitting}
                                    onClick={() => submitFormikAndScrollToInvalidField(formik)}
                                />
                            </Badge>
                        )}
                    </DialogActions>
                </Box>
            </FormikProvider>
        </Dialog>
    );
};
