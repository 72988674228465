import React from 'react';
import { IClientOnboardingStepComponent } from '../../interfaces/IClientOnboardingStepComponent';
import { ClientOnboardingContentFrame } from '../../ClientOnboardingContentFrame';
import { FundsSetupListForm } from './FundsSetupListForm';
import { DocumentStorage, useFundsInvestmentManagersQuery } from '../../../../../../interfaces/model';
import { FundsSetupNextStepButton } from './FundsSetupNextStepButton';
import { voidWrapper } from '../../../../../../lib/utils/helpers';
import { NonApplicableDocumentDialog } from '../../../../../components/Documents/components/CreateNonApplicableDocumentDialog';
import { DocumentUploadDialog } from '../../../../../components/Documents/components/DocumentUploadDialog';
import { AddDocumentsDialog } from '../../../../../components/Documents/components/AddDocumentsDialog';

interface IFundsSetupProps extends IClientOnboardingStepComponent {
    onFooterButtonClick?: (e: React.MouseEvent) => void;
    noPreview?: boolean;
    finalStep?: boolean;
    onGoToLegalEntitiesClick?: () => void;
}

export const FundsSetup: React.FC<IFundsSetupProps> = ({
    stepIndex,
    disabled,
    topCo,
    onFooterButtonClick,
    noPreview,
    finalStep,
    onGoToLegalEntitiesClick,
}) => {
    const fundsInvestmentManagers = useFundsInvestmentManagersQuery({
        fetchPolicy: 'cache-and-network',
        skip: !topCo?.id,
        variables: {
            topCoRef: topCo?.id!,
        },
    });

    const updateData = voidWrapper(fundsInvestmentManagers.refetch);

    return (
        <ClientOnboardingContentFrame
            label={'Funds'}
            description={'Please set up the funds you want to trade for on this platform'}
            action={
                <FundsSetupNextStepButton
                    stepIndex={stepIndex}
                    disabled={disabled}
                    legalEntities={fundsInvestmentManagers.data?.entities.data || []}
                    onClick={onFooterButtonClick}
                    finalStep={finalStep}
                    onGoToLegalEntitiesClick={onGoToLegalEntitiesClick}
                />
            }
        >
            <FundsSetupListForm
                hasInvestmentManagers={!!fundsInvestmentManagers.data?.entities.total}
                updateData={voidWrapper(fundsInvestmentManagers.refetch)}
                disabled={fundsInvestmentManagers.loading}
                topCo={topCo}
                noPreview={noPreview}
                onGoToLegalEntitiesClick={onGoToLegalEntitiesClick}
            />
            <DocumentUploadDialog config={{ storage: DocumentStorage.KYC }} updateData={updateData} />
            <NonApplicableDocumentDialog onSaveSuccess={updateData} />
            <AddDocumentsDialog updateData={updateData} />
        </ClientOnboardingContentFrame>
    );
};
