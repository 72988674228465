import cl from 'classnames';
import React, { useRef, useState } from 'react';
import { LoadingContentWrap } from 'lib/dataDisplay/LoadingContentWrap';
import styles from './TermsAndConditions.module.css';

interface ITermsAndConditionsProps {
    onScrolledDown: (isScrolledDown: boolean) => void;
    className?: string;
}

export const TermsAndConditions: React.FC<ITermsAndConditionsProps> = ({ onScrolledDown, className }) => {
    const [isLoading, setLoading] = useState(true);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    return (
        <LoadingContentWrap loading={isLoading}>
            <iframe
                ref={iframeRef}
                title={'TermsAndConditions'}
                onLoad={(event) => {
                    const frame = event.target as HTMLIFrameElement;
                    const doc = frame.contentDocument || frame.contentWindow?.document;
                    doc?.addEventListener('scroll', (e) => {
                        const body = (e.target as Document).body;
                        const scrollTop = body.scrollTop;
                        const scrollHeight = body.scrollHeight;
                        if (scrollTop + frame.clientHeight >= scrollHeight - 400) {
                            onScrolledDown(true);
                        }
                    });
                    setLoading(false);
                }}
                src={`${process.env.PUBLIC_URL}/TermsAndConditions.html`}
                className={cl(styles.iframe, className)}
            />
        </LoadingContentWrap>
    );
};
