import React from 'react';
import Edit from '@mui/icons-material/Edit';
import { IListRow } from '../../../../lib/dataDisplay/List';
import { ListRow } from '../../../../lib/dataDisplay/List/components/ListRow';
import {
    ActionsCell,
    DateValueCell,
    JurisdictionCell,
    ValueCell,
} from '../../../../lib/dataDisplay/List/components/ListCells';
import { ListIconButton } from '../../../../lib/dataDisplay/List/components/ListIconButton';
import { useTaxIdEditDialog } from './TaxIdEditDialog';
import { TaxIdDeleteIconButton } from './TaxIdDeleteIconButton';
import { DocumentDownloadIconButton } from '../../../components/Documents/components/DocumentDownloadIconButton';
import { ITaxIdEditDialogValue } from './TaxIdEditDialog/TaxIdEditDialog.const';

export interface ITaxIdsListItem extends ITaxIdEditDialogValue {
    documentId: Maybe<UUID>;
    validFromDate: Maybe<string> | undefined;
    validToDate: Maybe<string> | undefined;
    actions?: never;
}

type ITaxIdsListRowProps = IListRow<
    ITaxIdsListItem,
    {
        onDelete: (taxId: UUID) => void;
        editable: boolean;
    }
>;

export const TaxIdsListRow: React.FC<ITaxIdsListRowProps> = ({ data, columns, props }) => {
    const [{ open: openTaxIdEditDialog }] = useTaxIdEditDialog();

    return (
        <>
            <ListRow>
                {columns.map(({ id }) => (
                    <React.Fragment key={id}>
                        {id === 'country' && <JurisdictionCell value={data.country} showFullName />}
                        {id === 'taxIdValue' && (
                            <ValueCell className={'word-break-all'} nowrap={false}>
                                {data.taxIdValue}
                            </ValueCell>
                        )}
                        {id === 'validFromDate' && <DateValueCell value={data.validFromDate} />}
                        {id === 'validToDate' && <DateValueCell value={data.validToDate} />}
                        {id === 'actions' && (
                            <ActionsCell align={'right'}>
                                {data.documentId && <DocumentDownloadIconButton documentId={data.documentId} />}
                                {props.editable && (
                                    <>
                                        <ListIconButton
                                            icon={Edit}
                                            onClick={() => openTaxIdEditDialog({ taxId: data })}
                                        />
                                        <TaxIdDeleteIconButton taxId={data} onDelete={props.onDelete} />
                                    </>
                                )}
                            </ActionsCell>
                        )}
                    </React.Fragment>
                ))}
            </ListRow>
        </>
    );
};
