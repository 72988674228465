import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ButtonProps } from '@mui/material';
import React, { useContext, useState } from 'react';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
    CLIENT_ONBOARDING_PROGRESS_STEPS_MAPPING,
    CLIENT_ONBOARDING_STEPS_ROUTE_COMPONENT_MAP,
    ClientOnboardingContext,
} from '../../ClientOnboarding.const';
import { LoadingSpinnerButton } from '../../../../../../lib/buttons/LoadingSpinnerButton';
import { useClientOnboardingProgressMutation } from '../../../../../../interfaces/model';
import { setOnboardingProgress } from '../../../../../App.actions';
import styles from './ClientOnboardingNextStepButton.module.css';
import { NonFalsy } from '../../../../../../lib/utils/helpers';

interface IClientOnboardingFrameNavigationProps extends ButtonProps {
    stepIndex: number;
    disabled: boolean;
    spin?: boolean;
    onClick?: (e: React.MouseEvent) => Promise<boolean>;
    route?: string;
    finalStep?: boolean;
}

export const ClientOnboardingNextStepButton: React.FC<IClientOnboardingFrameNavigationProps> = ({
    stepIndex,
    disabled,
    spin,
    route,
    onClick = () => Promise.resolve(true),
    finalStep,
    ...prop
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isSaving, setSaving] = useState(false);
    const { steps, updateData, topCoRef } = useContext(ClientOnboardingContext);
    const [saveProgress] = useClientOnboardingProgressMutation();
    const nextStep = CLIENT_ONBOARDING_STEPS_ROUTE_COMPONENT_MAP[steps[stepIndex + 1]?.step];
    const isLastStep = finalStep || stepIndex === steps.length - 1;
    const navigateNext = async (e: React.MouseEvent) => {
        const shouldNavigate = await onClick(e);
        if (!shouldNavigate || !topCoRef) {
            return;
        }

        const currentStep = CLIENT_ONBOARDING_PROGRESS_STEPS_MAPPING[steps[stepIndex].step];
        const currentProgress = steps
            .filter(({ complete }) => complete)
            .map(({ step }) => CLIENT_ONBOARDING_PROGRESS_STEPS_MAPPING[step])
            .filter(NonFalsy);

        if (currentStep && !currentProgress.includes(currentStep)) {
            setSaving(true);
            const progress = [...currentProgress, currentStep];
            await saveProgress({
                variables: {
                    id: topCoRef,
                    progress,
                },
            });
            dispatch(setOnboardingProgress(progress));
            setSaving(false);
            await updateData();
        }

        const nextRoute = nextStep?.route || route;

        if (nextRoute) {
            history.push(nextRoute);
        }
    };

    return (
        <LoadingSpinnerButton
            testId={`ClientOnboarding:${isLastStep ? 'submitButton' : 'continueButton'}`}
            size={'large'}
            spin={!!spin || isSaving}
            color={'primary'}
            variant={'contained'}
            onClick={navigateNext}
            className={styles.button}
            disabled={disabled}
            {...prop}
        >
            {isLastStep && 'Save & Finish'}
            {!isLastStep && (
                <Box padding={'0 0 0 16px'}>
                    Continue
                    <ArrowForwardIosOutlinedIcon className={styles.arrow} />
                </Box>
            )}
        </LoadingSpinnerButton>
    );
};
