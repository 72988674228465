import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { COUNTERPARTY_KYC_ROUTE_FACTORY } from '../../../../App.routes';
import { useCounterpartyKYCUrlState } from '../useCounterpartyKYCUrlState';
import { CounterpartyTargetFragment } from '../../../../../interfaces/model';

interface IOppositeCounterpartiesKYCLinkProps {
    value: { id: UUID; topCoRef: UUID };
    targets: CounterpartyTargetFragment[];
}

export const OppositeCounterpartiesKYCLink: React.FC<IOppositeCounterpartiesKYCLinkProps> = ({ value, targets }) => {
    const [urlState] = useCounterpartyKYCUrlState();

    return (
        <IconButton
            title={'Switch to entity counterparties'}
            size={'small'}
            color={'secondary'}
            component={Link}
            to={COUNTERPARTY_KYC_ROUTE_FACTORY({
                ...urlState,
                search: (targets[0]?.entity || targets[0]?.loanFund)?.name,
                targets: [value.id],
                topCoRef: value.topCoRef,
            })}
        >
            <AltRouteIcon fontSize={'inherit'} />
        </IconButton>
    );
};
