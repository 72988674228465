import React from 'react';
import Stack from '@mui/material/Stack';
import { isDocumentNotApplicable } from '../../../Documents.const';
import { DocumentReviews } from './DocumentsUploadHistoryList/components/DocumentReviews';
import { LinkWithIcon } from '../../../../../../lib/navigation/LinkWithIcon';
import { KYCDocumentFragment } from '../../../../../../interfaces/model';
import { dateFromISOToFormat } from '../../../../../../lib/utils/date';
import { ROLE_VIEW_ROUTE_FACTORY } from '../../../../../App.routes';
import { RowStack } from '../../../../../../lib/cdk/RowStack';
import { ValueBox } from '../../../../../../lib/cdk/ValueBox';
import { IDocumentViewDialogParent } from '../interfaces';

interface IDocumentsLastUpdateDetailsComponentProps {
    value: KYCDocumentFragment;
    updateData: () => Promise<void>;
    parent: IDocumentViewDialogParent | undefined;
}

export const DocumentsLastUpdateDetailsComponent: React.FC<IDocumentsLastUpdateDetailsComponentProps> = ({
    value,
    parent,
    updateData,
}) => {
    const isNotApplicable = isDocumentNotApplicable(value);

    return (
        <Stack spacing={5}>
            <RowStack spacing={5} alignItems={'flex-start'}>
                <ValueBox label={'Valid From'}>
                    {dateFromISOToFormat(value.validFromDate) || (isNotApplicable ? 'N/A' : 'Unknown')}
                </ValueBox>
                {value.validToDate && <ValueBox label={'Valid To'}>{dateFromISOToFormat(value.validToDate)}</ValueBox>}
                <ValueBox label={'Filed by'}>
                    {value.role && (
                        <LinkWithIcon to={ROLE_VIEW_ROUTE_FACTORY(value.role.shortCode!)}>
                            {value.role.name}
                        </LinkWithIcon>
                    )}
                </ValueBox>
                {value.comment && <ValueBox label={'Comment'}>{value.comment}</ValueBox>}
            </RowStack>
            <DocumentReviews
                value={value}
                parent={parent && { ...parent, hasDocumentApproveAccess: false }}
                updateData={updateData}
                kycMode={false}
            />
        </Stack>
    );
};
