import * as yup from 'yup';
import {
    Capabilities,
    EmailNotificationType,
    EntityRelationsFragment,
    SuperadminCapabilities,
    TOTPRequired,
} from '../../../../../interfaces/model';
import { PASSWORD_REQUIREMENTS_VALIDATORS } from '../steps/RoleGeneralInfoEdit/PasswordRequirements';
import { VALIDATION_MESSAGES, yupArrayTypeFix } from '../../../../../lib/forms';
import { fromEntries } from '../../../../../lib/utils/helpers';
import { UserType } from '../steps/RoleGeneralInfoEdit/RoleGeneralInfoEdit.const';

export const hasDirectorRelation = (entityRelations: Maybe<{ director?: boolean }[]> | undefined) => {
    return !!entityRelations?.length && entityRelations.some(({ director }) => director);
};

export const hasAllDirectorFieldsFilled = (role: IRoleForm) => {
    return role.birthday && role.nationality && role.country && role.streetName && role.postcode && role.city;
};

export const ONE_WEEK_IN_MINUTES = 10080;

const directorFieldRequired = (schema: yup.AnySchema) => {
    return schema.when('isDirector', {
        is: true,
        then: schema.required(VALIDATION_MESSAGES.REQUIRED),
    });
};

interface IFormEntityRelation {
    id: string;
    name: string;
    enabled: boolean;
    director: boolean;
    psc: boolean;
    ubo: boolean;
}

export const validationSchemaFactory = (userRole: UUID | undefined, securePasswordRequired: boolean) =>
    yup.object({
        id: yup.string(),
        isDirector: yup.boolean(),
        name: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
        email: yup
            .string()
            .email()
            .when('id', {
                is: (id: string | undefined | null) => !id,
                then: yup.string().email().required(VALIDATION_MESSAGES.REQUIRED),
            }),
        totpRequired: yup.mixed<TOTPRequired>().required(VALIDATION_MESSAGES.REQUIRED),
        phone: yup.string(),
        isAdmin: yup.boolean(),
        login: yup.string(),
        password: yup.string().test({
            name: 'Password requirements',
            test: !securePasswordRequired
                ? () => true
                : async (passwordString, { createError }) => {
                      const errors = PASSWORD_REQUIREMENTS_VALIDATORS.filter(({ test }) => !test(passwordString)).map(
                          ({ id, message }) => [id, message] as [string, string]
                      );

                      if (passwordString && errors.length > 0) {
                          return createError({ message: fromEntries(errors) });
                      }
                      return true;
                  },
        }),
        currentPassword: yup
            .string()
            .nullable()
            .when(['id', 'password'], {
                is: (id: string, password: string) =>
                    !!id && id === userRole && password?.length && password.length > 0,
                then: yup.string().required(VALIDATION_MESSAGES.REQUIRED).nullable(),
            }),
        confirmPassword: yup
            .string()
            .nullable()
            .when(['id', 'password'], {
                is: (id: string, password: string) =>
                    !!id && id === userRole && password?.length && password.length > 0,
                then: yup
                    .string()
                    .oneOf([yup.ref('password'), null], VALIDATION_MESSAGES.PASSWORDS_DONT_MATCH)
                    .required(VALIDATION_MESSAGES.REQUIRED)
                    .nullable(),
            }),
        jobTitle: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
        /**
         * we use 2 fake fields, 'member' and 'additional' capabilities and then join them into 'capabilities' on submit
         */
        memberCapabilities: yup.array().of(yup.mixed<Capabilities>()).required(),
        additionalCapabilities: yup.array().of(yup.mixed<Capabilities>()).required(),
        capabilities: yup.array().of(yup.mixed<Capabilities>()).required(),
        superadminCapabilities: yup.array().of(yup.mixed<SuperadminCapabilities>()),
        emailNotifications: yup.array().of(yup.mixed<EmailNotificationType>()).required(),
        entityRelations: yup.array().of(yupArrayTypeFix(yup.mixed<IFormEntityRelation>())).nullable(),
        directorRelations: yup
            .array()
            .of(yupArrayTypeFix(yup.mixed<IFormEntityRelation>()))
            .nullable()
            .when('isDirector', {
                is: true,
                then: yup
                    .array()
                    .of(
                        yupArrayTypeFix(
                            yup.mixed<{
                                id: string;
                                name: string;
                                enabled: boolean;
                                director: boolean;
                                psc: boolean;
                                ubo: boolean;
                            }>()
                        )
                    )
                    .nullable()
                    .test({
                        name: 'min-directors',
                        message: 'User should be added as a director for at least 1 entity',
                        test(value) {
                            const directors = value?.filter((item: EntityRelationsFragment) => item.director);
                            return !!directors?.length && directors.length > 0;
                        },
                    }),
            }),
        birthday: directorFieldRequired(yup.date().nullable()),
        nationality: directorFieldRequired(yup.string().nullable()),
        country: directorFieldRequired(yup.string().nullable()),
        streetName: directorFieldRequired(yup.string().nullable()),
        postcode: directorFieldRequired(yup.string().nullable()),
        city: directorFieldRequired(yup.string().nullable()),
        suite: yup.string().nullable(),
        currentStep: yup.number(),
        sessionTimeout: yup
            .number()
            .nullable()
            .min(5, VALIDATION_MESSAGES.MIN())
            .max(ONE_WEEK_IN_MINUTES, VALIDATION_MESSAGES.MAX())
            .required(VALIDATION_MESSAGES.REQUIRED),
        logoutAfterSpecificTime: yup.boolean(),
        autoLogoutAt: yup
            .mixed()
            .nullable()
            .when('logoutAfterSpecificTime', {
                is: (logoutAfterSpecificTime: boolean) => logoutAfterSpecificTime,
                then: yup.mixed().nullable().required(VALIDATION_MESSAGES.REQUIRED),
            }),
        timezone: yup
            .string()
            .nullable()
            .when('logoutAfterSpecificTime', {
                is: (logoutAfterSpecificTime: boolean) => logoutAfterSpecificTime,
                then: yup.string().nullable().required(VALIDATION_MESSAGES.REQUIRED),
            }),
        singleSession: yup.boolean().nullable(),
        allMemberCapabilities: yup.boolean().nullable(),
        allEmailNotifications: yup.boolean().nullable(),
        userType: yup.mixed<UserType>().nullable(),
    });

export type IRoleForm = yup.TypeOf<ReturnType<typeof validationSchemaFactory>>;
