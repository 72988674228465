import * as yup from 'yup';
import { Country } from '../../interfaces/model';
import { VALIDATION_MESSAGES } from './forms.const';

export const addressSchema = yup.object().shape({
    country: yup.mixed<Country>().required(VALIDATION_MESSAGES.REQUIRED),
    city: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    streetName: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    postcode: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
});
