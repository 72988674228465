import React from 'react';
import cl from 'classnames';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { BoxProps } from '@mui/material';
import styles from './PageTitle.module.css';

interface IPageTitleProps extends Omit<BoxProps, 'title'> {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    className?: string;
    children?: React.ReactNode;
}

export const PageTitle: React.FC<IPageTitleProps> = ({ title, subtitle, className, children, ...props }) => {
    return (
        <Box className={cl(styles.wrap, className)} {...props}>
            <Stack spacing={1} sx={{ marginBottom: subtitle ? '24px' : void 0 }}>
                <h2 className={styles.title}>{title}</h2>
                {subtitle && (
                    <Box maxWidth={'65%'} className={'body2 secondary'}>
                        {subtitle}
                    </Box>
                )}
            </Stack>
            <Box marginLeft={'auto'} marginBottom={'auto'}>
                {children}
            </Box>
        </Box>
    );
};
