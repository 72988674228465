import React from 'react';
import Link from '@mui/material/Link';

const ExternalLink: React.FC<{ href: string; children?: React.ReactNode }> = ({ href, children }) => (
    <Link href={href} target={'_blank'} rel="noopener noreferrer" underline={'none'}>
        {children}
    </Link>
);

export const AuthenticatorsList: React.FC = () => {
    return (
        <span className={'input-label'}>
            <ExternalLink href={'https://support.1password.com/one-time-passwords/'}>1Password</ExternalLink>,{' '}
            <ExternalLink href={'https://authy.com/'}>Authy</ExternalLink>,{' '}
            <ExternalLink href={'https://www.lastpass.com/solutions/authentication'}>LastPass</ExternalLink>,{' '}
            <ExternalLink href={'https://support.google.com/accounts/answer/1066447'}>
                Google Authenticator
            </ExternalLink>{' '}
            or{' '}
            <ExternalLink href={'https://www.microsoft.com/en-us/security/mobile-authenticator-app'}>
                Microsoft Authenticator
            </ExternalLink>
        </span>
    );
};
