import React from 'react';
import { MenuItem, MenuItemProps } from '@mui/material';
import styles from './UserProfileMenuItem.module.css';
import { AvatarIcon } from '../AvatarIcon';

interface IUserProfileMenuItemProps extends MenuItemProps {
    userName: string;
    isAdmin: boolean;
    userCompany: Maybe<string> | undefined;
    component?: React.ElementType;
    to?: string;
}

export const UserProfileMenuItem: React.FC<IUserProfileMenuItemProps> = ({
    userCompany,
    userName,
    isAdmin,
    ...props
}) => {
    return (
        <MenuItem {...props}>
            <div className={styles.iconWrap}>
                <AvatarIcon name={userName} isAdmin={isAdmin} />
            </div>
            <div className={styles.textWrap} title={`${userName} - ${userCompany}`}>
                <span className={styles.nameText}>{userName}</span>
                <span className={styles.companyText}>{userCompany}</span>
            </div>
        </MenuItem>
    );
};
