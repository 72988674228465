import React, { useLayoutEffect, useRef, useState } from 'react';
import cl from 'classnames';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styles from './NoDataPlaceholderRow.module.css';

export interface INoDataPlaceholderRowProps {
    text?: React.ReactNode;
    colspan: number;
    className?: string;
    children?: React.ReactNode;
}

const DEFAULT_TEXT = 'No elements';

const getElementVerticalPaddings = (element: Element | null) => {
    let top = 0;
    let bottom = 0;
    if (element) {
        const paddingTop = parseInt(getComputedStyle(element).paddingTop, 10);
        const paddingBottom = parseInt(getComputedStyle(element).paddingBottom, 10);
        if (!isNaN(paddingTop)) {
            top = paddingTop;
        }
        if (!isNaN(paddingBottom)) {
            bottom = paddingBottom;
        }
    }
    return { top, bottom };
};

const subscribeOnContentResize = (element: HTMLElement | null, onResize: (rect: DOMRect) => void) => {
    const resizeObserver = new ResizeObserver((entries) => {
        onResize(entries[0].contentRect);
    });
    if (element) {
        resizeObserver.observe(element);
        return () => resizeObserver.unobserve(element);
    }
};

export const NoDataPlaceholderRow: React.FC<INoDataPlaceholderRowProps> = ({
    text = DEFAULT_TEXT,
    colspan,
    className,
    children,
}) => {
    const cellRef = useRef<Element>(null);
    const childrenRef = useRef<HTMLSpanElement>(null);
    const [initialUpdate, setInitialUpdate] = useState(true);
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        const { top, bottom } = getElementVerticalPaddings(cellRef.current);
        const updateHeight = (newHeight: number | undefined) => {
            if (newHeight !== height) {
                const value = (newHeight || 0) + top + bottom;
                setHeight(value < 40 ? 40 : value);
            }
        };

        if (initialUpdate) {
            const contentHeight = Math.round(childrenRef.current?.getBoundingClientRect().height || 0);
            setInitialUpdate(false);
            updateHeight(contentHeight);
        }
        return subscribeOnContentResize(childrenRef.current, (rect) => {
            updateHeight(rect.height);
        });
    }, [height, initialUpdate]);

    return (
        <TableRow className={cl('non-interactive', className, styles.row)} sx={{ '--row-min-height': `${height}px` }}>
            <TableCell colSpan={colspan} align={'center'} ref={cellRef}>
                <span ref={childrenRef} className={styles.childrenWrap}>
                    {children || text}
                </span>
            </TableCell>
        </TableRow>
    );
};
