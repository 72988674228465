import React from 'react';
import cl from 'classnames';
import { Card } from '@mui/material';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { SvgIconComponent } from '@mui/icons-material';
import styles from './FeedbackMessageCard.module.css';

export type FeedbackSeverity = 'success' | 'error';

interface IFeedbackMessageCardProps {
    severity: FeedbackSeverity;
    title: React.ReactNode;
    heading?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
}

const SEVERITY_ICONS: Record<FeedbackSeverity, SvgIconComponent> = {
    success: DoneIcon,
    error: WarningAmberIcon,
};

const SEVERITY_CLASSES: Record<FeedbackSeverity, string> = {
    success: styles.success,
    error: styles.error,
};

export const FeedbackMessageCard: React.FC<IFeedbackMessageCardProps> = ({
    severity,
    title,
    heading,
    children,
    className,
}) => {
    const Icon = SEVERITY_ICONS[severity];

    return (
        <Card className={className} data-testid={'FeedbackMessageCard'}>
            <div className={cl(styles.heading, SEVERITY_CLASSES[severity])}>
                {heading}
                <div className={styles.iconBox}>
                    <Icon fontSize={'large'} />
                </div>
            </div>
            <Stack alignItems={'center'}>
                <h4 className={styles.title}>{title}</h4>
                <div className={styles.body}>{children}</div>
            </Stack>
        </Card>
    );
};
