import React from 'react';
import { FormikProvider } from 'formik';
import { Container, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useElementFocusByQueryParamEffect } from 'lib/hooks/useElementFocusByQueryParamEffect';
import { useEntityEditFormik } from '../../CreateEntityWizardDialog/useEntityEditFormik';
import { makeDialogState } from '../../../../../store/dialogState/makeDialogState';
import { EditEntityForm } from '../../CreateEntityWizardDialog/EditEntityForm';
import { useEntityKeyInformationQuery } from './EntityKeyInformation.query';
import { DataErrorPlaceholder } from 'lib/dataDisplay/DataErrorPlaceholder';
import { DialogSubmitActions } from 'lib/modals/DialogSubmitActions';
import { DialogCloseButton } from 'lib/modals/DialogCloseButton';
import { DialogFormComponent } from 'lib/forms';

interface IEditKeyInformationDialogState {
    entityId: UUID;
}

interface IEditKeyInformationDialogProps {
    onClose: () => void;
}

export const useEditKeyInformationDialog = makeDialogState<IEditKeyInformationDialogState>();

export const EditEntityKeyInformationDialog: React.FC<IEditKeyInformationDialogProps> = ({ onClose }) => {
    const [{ close }, isOpen, state] = useEditKeyInformationDialog();
    const { entityId } = state || {};

    const { data, error, loading } = useEntityKeyInformationQuery({
        fetchPolicy: 'no-cache',
        skip: !entityId || !isOpen,
        variables: {
            entityId: entityId!,
        },
    });

    const formik = useEntityEditFormik({
        entity: data?.entity,
        onSaveSuccess() {
            onClose();
            setTimeout(close);
        },
    });

    useElementFocusByQueryParamEffect({ shouldPerformFocus: isOpen && !loading });

    const clearAndClose = () => {
        if (!formik.isSubmitting) {
            formik.resetForm();
            onClose();
            close();
        }
    };

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={isOpen}
                maxWidth={'md'}
                onClose={clearAndClose}
                PaperComponent={DialogFormComponent}
                fullWidth
            >
                <DialogTitle>
                    Edit key information
                    <DialogCloseButton onClick={clearAndClose} />
                </DialogTitle>
                <DialogContent>
                    <Container maxWidth={'sm'}>
                        {error && <DataErrorPlaceholder error={error} />}
                        {!error && <EditEntityForm disabled={loading} />}
                    </Container>
                </DialogContent>
                <DialogSubmitActions spin={formik.isSubmitting} onClose={clearAndClose} />
            </Dialog>
        </FormikProvider>
    );
};
