import React from 'react';
import { TextInput } from '../TextInput';
import { IconWithTooltip } from '../../icons/IconWithTooltip';
import InfoIcon from '@mui/icons-material/Info';
import styles from './DomainsInput.module.css';

interface IDomainsInputProps {
    name: string;
    value: string[] | null | undefined;
    onChange: (domains: string[]) => void;
    disabled?: boolean;
}

export const DomainsInput: React.FC<IDomainsInputProps> = ({ name, value, disabled, onChange }) => {
    return (
        <TextInput
            fullWidth
            name={name}
            placeholder={'domain.com, other_domain.uk'}
            value={value?.join(', ')}
            onChange={(e) => {
                const text = e.target.value;
                onChange(text.split(', '));
            }}
            disabled={disabled}
            InputProps={{
                endAdornment: (
                    <IconWithTooltip
                        className={styles.infoButton}
                        Icon={InfoIcon}
                        text={
                            'Add the e-mail domains permitted for your users. This is an additional security measure that prevents information being sent to the wrong companies.'
                        }
                    />
                ),
            }}
        />
    );
};
