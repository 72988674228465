import cl from 'classnames';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import { Logout } from '@mui/icons-material';
import React, { useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import { CLIENT_SETUP_ROUTE_FACTORY, EVENTS_ROUTE, ROLE_VIEW_ROUTE_FACTORY, SETTINGS_ROUTE } from '../../../App.routes';
import { useFromNavigation } from '../../../../lib/hooks/router/useFromNavigation';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useUserCapabilities } from '../../../hooks/useUserCapabilities';
import { useVersionDialog, VersionDialog } from '../../VersionDialog';
import { RecentUsersMenu } from './RecentUsersMenu/RecentUsersMenu';
import { useControlledUserSwitch } from './useControlledUserSwitch';
import { ControlledProfileBadge } from './ControlledProfileBadge';
import { useUserDetails } from '../../../hooks/useUserDetails';
import { UserProfileMenuItem } from './UserProfileMenuItem';
import { useAppSnackbar } from '../../AppNotification';
import { userLogout } from '../../../App.actions';
import { AvatarIcon } from './AvatarIcon';
import { useAppSpinner } from '../../../hooks/useAppSpinner';
import { useLogoutLazyQuery } from './Logout.query';

interface IProfileButtonProps {
    logoutOnly?: boolean;
    roleProfileRouteFactory?: (shortCode: string) => string;
    className?: string;
}

export const ProfileButton: React.FC<IProfileButtonProps> = ({
    className,
    roleProfileRouteFactory = ROLE_VIEW_ROUTE_FACTORY,
    logoutOnly,
}) => {
    const spinner = useAppSpinner();
    const anchorEl = useRef(null);
    const dispatch = useDispatch();
    const snackBar = useAppSnackbar();
    const client = useApolloClient();
    const fromNavigation = useFromNavigation();
    const [versionDialog] = useVersionDialog();
    const [logout] = useLogoutLazyQuery({
        fetchPolicy: 'no-cache',
    });
    const [switchUser] = useControlledUserSwitch();
    const { isAdmin, authRole, role, isAgent } = useUserDetails();
    const { createBorrowerTopCo, createLenderTopCo } = useUserCapabilities();
    const isControlledByOtherUser = role?.id && authRole?.id && authRole?.id !== role.id;
    const hasControlledUserSwitchAccess = role?.hasSwitchAccess || !isControlledByOtherUser;
    const userCompany = role?.company;
    const userName = role?.name || '';
    const [isOpen, setIsOpen] = useState(false);
    const closeMenu = () => setIsOpen(false);

    const handleLogout = async () => {
        closeMenu();
        try {
            spinner.show();
            if (isControlledByOtherUser) {
                await switchUser(authRole);
            } else {
                await logout();
                await client.clearStore();
                dispatch(userLogout());
            }
            spinner.hide();
        } catch (e) {
            spinner.hide();
            snackBar.showError({ message: 'Failed to logout' }, e);
        }
    };

    const openVersionDialog = () => {
        versionDialog.open();
        closeMenu();
    };

    return (
        <Box className={cl(className)} display={'flex'} alignItems={'center'}>
            {isControlledByOtherUser && <ControlledProfileBadge role={authRole} />}
            <Tooltip title={'Profile'} arrow>
                <IconButton size={'small'} ref={anchorEl} onClick={() => setIsOpen(!isOpen)}>
                    <AvatarIcon name={userName} isAdmin={isAdmin} />
                </IconButton>
            </Tooltip>
            <Menu
                open={isOpen}
                anchorEl={anchorEl.current}
                sx={{ width: '280px' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: -4,
                    horizontal: 'right',
                }}
                onClose={() => setIsOpen(!isOpen)}
            >
                <UserProfileMenuItem
                    userName={userName}
                    isAdmin={isAdmin}
                    userCompany={userCompany}
                    component={Link}
                    to={roleProfileRouteFactory(role?.shortCode!)}
                    onClick={closeMenu}
                />
                {!logoutOnly && hasControlledUserSwitchAccess && <RecentUsersMenu onItemClick={closeMenu} />}
                {(isAdmin || (!logoutOnly && !isAgent && (createBorrowerTopCo || createLenderTopCo))) && (
                    <MenuItem component={Link} to={CLIENT_SETUP_ROUTE_FACTORY(fromNavigation)} onClick={closeMenu}>
                        <ListItemIcon>
                            <AddCircleOutlineIcon color={'secondary'} fontSize="small" />
                        </ListItemIcon>
                        Setup new client
                    </MenuItem>
                )}
                {!logoutOnly && isAdmin && (
                    <MenuItem component={Link} to={EVENTS_ROUTE} onClick={closeMenu}>
                        <ListItemIcon>
                            <AssignmentOutlinedIcon color={'secondary'} fontSize={'small'} />
                        </ListItemIcon>
                        Events
                    </MenuItem>
                )}
                <Divider />
                {!logoutOnly && isAdmin && (
                    <MenuItem onClick={openVersionDialog}>
                        <ListItemIcon>
                            <InfoOutlinedIcon color={'secondary'} fontSize={'small'} />
                        </ListItemIcon>
                        Version
                    </MenuItem>
                )}
                {isAdmin && (
                    <MenuItem component={Link} to={SETTINGS_ROUTE} onClick={closeMenu}>
                        <ListItemIcon>
                            <SettingsIcon color={'secondary'} fontSize={'small'} />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout color={'secondary'} fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            <VersionDialog />
        </Box>
    );
};
