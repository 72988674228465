import React from 'react';
import { Link } from 'react-router-dom';
import cl from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import styles from './MenuGroup.module.css';
import { HoverMenu } from '../HoverMenu';

export interface IMenuGroup {
    label: string;
    url?: string;
    active: boolean;
    subItems: IMenuGroup[];
    menuGroup?: Maybe<string>;
}

interface IMenuGroupProps {
    value: IMenuGroup;
    className?: string;
}

export const MenuGroup: React.FC<IMenuGroupProps> = ({ value, className }) => {
    const { label, subItems } = value;

    const triggerElement =
        subItems?.length === 1 ? (
            <Link key={subItems[0].url} className={cl('item', subItems[0].active && 'active')} to={subItems[0].url}>
                {subItems[0].label}
            </Link>
        ) : (
            label
        );

    return (
        <HoverMenu
            triggerElement={triggerElement}
            className={cl(
                'item',
                subItems?.some((subItem) => subItem.active) && 'active',
                styles.menuGroupTrigger,
                className
            )}
        >
            {subItems?.map((subItem) => {
                const { url, active } = subItem;
                return (
                    <Link className={active ? 'active' : 'inactive'} key={url} to={url}>
                        <MenuItem key={url}>
                            <span>{subItem.label}</span>
                        </MenuItem>
                    </Link>
                );
            })}
        </HoverMenu>
    );
};
