import React, { useContext } from 'react';
import { Box, Paper, Switch } from '@mui/material';
import { ClientType, EmailNotificationType } from '../../../../../interfaces/model';
import { emailNotificationTypeLabels } from '../../Roles.const';
import { EmailNotificationsDictionaryFragment, useClientTypeNotificationsQuery } from './ClientTypeNotifications.query';
import { IndentedMultiSelectList } from '../RoleCapabilitiesList/IndentedMultiSelectList';
import { FormikContext, FormikContextType } from 'formik';
import { IRoleForm } from '../../RoleEditDialog/useRoleEditFormik/useRoleEditFormik.const';
import { LoadingContentWrap } from '../../../../../lib/dataDisplay/LoadingContentWrap';

interface IRoleNotificationsSelectListProps {
    value: EmailNotificationType[];
    onChange: (value: EmailNotificationType[]) => void;
    clientType: ClientType;
}

const getNotificationOptionsByClientType = (
    clientType: ClientType,
    dictionary: EmailNotificationsDictionaryFragment[]
) => {
    return dictionary
        .filter((item) => {
            return item.clientType.includes(clientType);
        })
        .map((item) => {
            return {
                id: item.value,
                name: emailNotificationTypeLabels[item.value],
                value: item.value,
            };
        });
};

export const RoleNotificationsSelectList: React.FC<IRoleNotificationsSelectListProps> = ({
    value,
    onChange,
    clientType,
}) => {
    const { data, loading, error } = useClientTypeNotificationsQuery();
    const formik = useContext<FormikContextType<IRoleForm>>(FormikContext);

    return (
        <Paper elevation={0} sx={{ background: 'var(--bg-default)' }}>
            <LoadingContentWrap loading={loading} error={error}>
                <Box margin="0 16px 0 auto" padding={'12px 16px'} display={'flex'} alignItems={'center'}>
                    <Switch
                        color={'primary'}
                        onChange={() =>
                            formik.setFieldValue('allEmailNotifications', !formik.values.allEmailNotifications)
                        }
                        checked={!!formik.values.allEmailNotifications}
                        size={'small'}
                    />
                    <span className={'ml-12'}>All Notifications</span>
                </Box>
                {!formik.values.allEmailNotifications && (
                    <IndentedMultiSelectList
                        options={getNotificationOptionsByClientType(clientType, data?.emailNotifications || [])}
                        onChange={onChange}
                        value={value}
                        editable
                        headerLabel={'Notifications'}
                    />
                )}
            </LoadingContentWrap>
        </Paper>
    );
};
