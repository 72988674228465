import React from 'react';
import Delete from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDocumentDelete } from './DocumentsViewDialog/components/DocumentReviewButton/useDocumentDelete';
import { DeleteType, DocumentInInput, KYCDocumentFragment } from 'interfaces/model';
import { ListIconButton } from 'lib/dataDisplay/List/components/ListIconButton';

interface IDocumentDeleteIconButtonProps {
    document: Partial<Pick<KYCDocumentFragment, 'id' | 'docType' | 'validFromDate' | 'docSubcategory'>> | undefined;
    children?: React.ReactNode;
    parentName?: string;
    onEditSubmit?: (document: DocumentInInput) => Promise<void>;
    mode?: DeleteType;
    onDeleteSuccess: () => Promise<void>;
    disabled?: boolean;
}

export const DocumentDeleteIconButton: React.FC<IDocumentDeleteIconButtonProps> = ({
    document,
    parentName,
    onDeleteSuccess,
    onEditSubmit,
    mode = DeleteType.TRASH,
    disabled,
}) => {
    const deleteDocument = useDocumentDelete({
        document,
        parentName: parentName || '',
        onDeleteSuccess,
        onEditSubmit,
        mode,
    });
    const isPermanent = mode === DeleteType.PERMANENT;
    return (
        <ListIconButton
            title={isPermanent ? 'Delete from system' : 'Delete'}
            icon={isPermanent ? DeleteForeverIcon : Delete}
            onClick={deleteDocument}
            disabled={disabled}
        />
    );
};
