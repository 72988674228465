export type ISelectOption<TKeys, TValue> = {
    id: TKeys;
    name?: string;
    disabled?: boolean;
    extraInfo?: { [key: string]: string | undefined | null };
    value: TValue;
};

export const selectOptionFactory = <TKey extends string, T extends Record<TKey, TKey>>(
    enumObj: T,
    labels: Partial<Record<TKey, string>>
) => {
    return (id: TKey): ISelectOption<TKey, T[TKey]> => ({ id, name: labels[id], value: enumObj[id] });
};

export const makeSelectSortedOptions = <TKey extends string, T extends Record<TKey, TKey>>(
    enumObj: T,
    labels: Partial<Record<TKey, string>>
) => {
    return Object.keys(enumObj)
        .map((key) => selectOptionFactory(enumObj, labels)(key as TKey))
        .sort(({ name: nameA }, { name: nameB }) => nameA!.localeCompare(nameB!));
};
