import isNil from 'lodash/isNil';
import { FormikContext } from 'formik';
import Stack from '@mui/material/Stack';
import React, { useContext, useEffect, useRef } from 'react';
import { StandardTextFieldProps } from '@mui/material/TextField';
import { SingleSymbolInput } from './SingleSymbolInput';
import { FormHelperText } from '@mui/material';

interface ISeparateSymbolInputProps extends Omit<StandardTextFieldProps, 'onChange' | 'onSubmit' | 'value' | 'size'> {
    value?: string | undefined;
    onChange?: (value: string) => void;
    size?: 'medium' | 'large';
    autoSubmit?: boolean;
    onSubmit?: (code: string) => void;
    length?: number;
}

export const SeparateSymbolInput: React.FC<ISeparateSymbolInputProps> = ({
    name,
    onChange: onChangeProp,
    value: valueProp,
    size = 'medium',
    length = 6,
    autoSubmit,
    onSubmit,
    ...props
}) => {
    const inputsRefs = useRef<Array<HTMLDivElement | null>>([]);
    const getInputByIndex = (index: number) => inputsRefs.current[index]?.querySelector('input');

    const formik = useContext(FormikContext);
    const formField = name ? formik?.getFieldProps(name) : void 0;
    const formFieldMeta = name ? formik?.getFieldMeta(name) : void 0;
    const value = (!isNil(valueProp) ? valueProp : formField?.value) || '';
    const touched = formFieldMeta?.touched;
    const error = formFieldMeta?.error;
    const showError = touched && !!error;

    useEffect(() => {
        if (!value) {
            const firstInput = getInputByIndex(0);
            if (firstInput && firstInput !== document.activeElement) {
                setTimeout(() => firstInput.focus(), 700);
            }
        }
    });

    const handleChange = (newValue: string) => {
        if (onChangeProp) {
            onChangeProp(newValue);
        } else if (formik && name) {
            formik.setFieldValue(name, newValue);
        }
    };

    const updateValue = (index: number, symbol: string | undefined) => {
        const newValue = [...value];
        newValue[index] = symbol;
        const newValueString = newValue.join('');
        handleChange(newValueString);
        if (autoSubmit && index === length - 1) {
            if (onSubmit) {
                onSubmit(newValueString);
            }
            if (formik && name) {
                formik.submitForm();
            }
        }
    };

    const focusInput = (index: number, next: boolean) => {
        const nextInput = getInputByIndex(index + (next ? 1 : -1));
        nextInput?.select();
        nextInput?.focus();
    };

    return (
        <Stack>
            <Stack direction={'row'} spacing={1}>
                {Array.from({ length }).map((_, index) => (
                    <SingleSymbolInput
                        key={index}
                        ref={(el) => {
                            inputsRefs.current[index] = el;
                        }}
                        error={showError}
                        {...props}
                        size={size}
                        type={'number'}
                        autoFocus={index === 0}
                        onFocus={(e) => e.target.select()}
                        onValueChange={(newSymbol) => {
                            updateValue(index, newSymbol);
                            focusInput(index, !!newSymbol);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === value[index]) {
                                focusInput(index, true);
                            }

                            if (e.key === 'Delete' || e.key === 'Backspace') {
                                updateValue(index, void 0);
                                focusInput(index, false);
                            }

                            if (props.onKeyDown) {
                                props.onKeyDown(e);
                            }
                        }}
                        value={value[index]}
                        disabled={formik?.isSubmitting}
                    />
                ))}
            </Stack>
            <FormHelperText>Please enter {length}-digit code</FormHelperText>
        </Stack>
    );
};
