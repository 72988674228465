import React from 'react';
import cl from 'classnames';
import { Badge } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import styles from './CollapseTrigger.module.css';
import { OverridableStringUnion } from '@mui/types';
import { IconButtonPropsSizeOverrides } from '@mui/material/IconButton/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export interface ICollapseTriggerProps {
    isActive: boolean;
    onClick?: (event: React.MouseEvent) => void;
    showRedDot?: boolean;
    className?: string;
    testId?: string;
    size?: OverridableStringUnion<'small' | 'medium' | 'large', IconButtonPropsSizeOverrides>;
    tabIndex?: number;
}

export const CollapseTrigger: React.FC<ICollapseTriggerProps> = ({
    isActive,
    onClick,
    className,
    testId,
    showRedDot,
    size,
    tabIndex,
}) => {
    const handleClick = (e: React.MouseEvent) => {
        if (onClick) {
            e.stopPropagation();
            e.preventDefault();
            onClick(e);
        }
    };

    return (
        <Badge variant={'dot'} color={'error'} invisible={isActive || !showRedDot}>
            <span className={cl(className)} data-testid={testId || 'CollapseTrigger'}>
                <IconButton onClick={handleClick} color={'secondary'} size={size || 'small'} tabIndex={tabIndex}>
                    <ArrowBackIosNewIcon className={cl(styles.icon, isActive && styles.active)} fontSize={'inherit'} />
                </IconButton>
            </span>
        </Badge>
    );
};
