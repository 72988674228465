import { useParams } from 'react-router';
import React, { useEffect } from 'react';
import { EntitiesEmbedded } from '../../Entities/ViewEntity/EntitiesEmbedded/EntitiesEmbedded';
import { IDocumentsListParent } from '../../../components/Documents/interfaces/IDocumentsListParent';
import { EditFundKeyInformationDialog } from '../../Funds/EditFundKeyInformationDialog';
import { CreateEntityWizardDialog } from '../../Entities/CreateEntityWizardDialog';
import { DocPurpose, DocumentStorage, EntityType, IdType } from 'interfaces/types';
import { DataErrorPlaceholder } from 'lib/dataDisplay/DataErrorPlaceholder';
import { CreateFundWizard } from '../../Funds/CreateFundWizard/CreateFundWizard';
import { DocumentsList } from '../../../components/Documents/DocumentsList';
import { ViewSystemAdminCapabilities } from './ViewSystemAdminCapabilities';
import { RoleEditDialog, useRoleEditDialog } from '../RoleEditDialog';
import { ViewRoleDetails } from './ViewRoleDetails/ViewRoleDetails';
import { isLenderEntityType } from '../../Entities/Entities.helpers';
import { FundsEmbeddedList } from '../../Funds/FundsEmbeddedList';
import { ViewRoleNotifications } from './ViewRoleNotifications';
import { ViewRoleCapabilities } from './ViewRoleCapabilities';
import { ViewRoleHeading } from './ViewRoleHeading';
import { voidWrapper } from 'lib/utils/helpers';
import { useRoleQuery } from './Role.query';
import { useViewRoleUrlState } from './useViewRoleUrlState';

interface IViewRoleProps {
    config?: {
        breadcrumbsInteractive?: boolean;
    };
}

export const ViewRole: React.FC<IViewRoleProps> = ({ config }) => {
    const [urlState, setUrlState] = useViewRoleUrlState();
    const [{ open: openRoleEditDialog }] = useRoleEditDialog();
    const params = useParams<{ sc?: string }>();
    const shortCode = (params.sc || '').toLowerCase();
    const { data, error, refetch } = useRoleQuery({
        fetchPolicy: 'no-cache',
        variables: { id: shortCode },
        skip: !shortCode,
    });

    useEffect(() => {
        if (urlState.edit) {
            openRoleEditDialog({ topCoRef: data?.role?.topCoRef, roleId: shortCode, roleName: data?.role?.name || '' });
        }
    }, [data?.role?.name, data?.role?.topCoRef, openRoleEditDialog, shortCode, urlState.edit]);
    const updateData = voidWrapper(refetch);

    if (error) {
        return <DataErrorPlaceholder error={error} onRetry={updateData} />;
    }

    const role = data?.role || void 0;

    const kycListParent: IDocumentsListParent = {
        name: role?.name || 'Unknown',
        id: role?.id,
        type: IdType.ROLE,
        topCoRef: role?.topCoRef,
        hasDocumentEditAccess: !!role?.hasDocumentEditAccess,
        hasDocumentApproveAccess: !!role?.hasDocumentApproveAccess,
        update: updateData,
    };

    if (role?.deleted) {
        return <ViewRoleHeading role={role} updateData={updateData} />;
    }

    return (
        <>
            <ViewRoleHeading role={role} updateData={updateData} config={config} />
            <ViewRoleDetails role={role} className={'mb-32 mt-16'} updateData={updateData} />
            <DocumentsList
                parent={kycListParent}
                value={role?.kycs || []}
                config={{
                    storage: DocumentStorage.KYC,
                    purpose: DocPurpose.KYC,
                }}
            />
            {role?.entityRelations && (
                <EntitiesEmbedded
                    title={role?.topCo?.entityType === EntityType.BORROWER_TOP_LEVEL ? 'Subsidiaries' : 'Entities'}
                    reloadData={updateData}
                    entityRelations={role.entityRelations}
                />
            )}
            {role?.topCo?.entityType && isLenderEntityType(role?.topCo.entityType) && (
                <FundsEmbeddedList
                    funds={role.loanFunds}
                    reloadData={updateData}
                    topCoRef={role.topCoRef}
                    entityRef={null}
                />
            )}
            {!role?.isAdmin && role?.capabilities && <ViewRoleCapabilities capabilities={role?.capabilities} />}
            {role?.isAdmin && role?.superadminCapabilities && (
                <ViewSystemAdminCapabilities capabilities={role?.superadminCapabilities} />
            )}
            {role?.email && role?.emailNotifications && <ViewRoleNotifications value={role?.emailNotifications} />}

            <EditFundKeyInformationDialog />
            <CreateFundWizard onSaveSuccess={updateData} />
            <CreateEntityWizardDialog onSaveSuccess={updateData} />
            <RoleEditDialog
                onSaveSuccess={updateData}
                onClose={() => setUrlState({ ...urlState, edit: void 0 }, { replace: false })}
            />
        </>
    );
};
