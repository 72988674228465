import React from 'react';
import { TableCell } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export const PublicityCell: React.FC<{ value: boolean }> = ({ value }) => (
    <TableCell className={'white-space-nowrap'}>
        {value ? (
            <span className={'success'}>
                <LockIcon fontSize={'small'} color={'inherit'} /> Private
            </span>
        ) : (
            <span className={'warning'}>
                <LockOpenIcon fontSize={'small'} color={'inherit'} /> Public
            </span>
        )}
    </TableCell>
);
