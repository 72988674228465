import * as yup from 'yup';
import { DocPurpose, KYCDocSubcategory, KYCDocType } from '../../../../../interfaces/model';
import { DOCUMENTS_WITH_EXPIRY_DATE, SUBCATEGORIES_MAP } from '../../Documents.const';
import { VALIDATION_MESSAGES } from '../../../../../lib/forms';
import { MIN_DATE } from '../../../../../lib/utils/date';

export const validationSchema = yup.object({
    docPurpose: yup.mixed<DocPurpose>().required(VALIDATION_MESSAGES.REQUIRED),
    docType: yup.mixed<KYCDocType>().required(VALIDATION_MESSAGES.REQUIRED),
    validFromDate: yup
        .date()
        .nullable()
        .when('validToDate', {
            is: (validToDate: Date | undefined) => Boolean(validToDate),
            then: yup.date().max(yup.ref('validToDate'), VALIDATION_MESSAGES.MAX_DATE),
        })
        .required(VALIDATION_MESSAGES.REQUIRED),
    validToDate: yup.date().when('docType', {
        is: (docType: KYCDocType) => DOCUMENTS_WITH_EXPIRY_DATE.includes(docType),
        then: yup
            .date()
            .nullable()
            .min(yup.ref('validFromDate') || MIN_DATE, VALIDATION_MESSAGES.MIN_DATE)
            .required(VALIDATION_MESSAGES.REQUIRED),
    }),
    name: yup.string(),
    comment: yup.string(),
    files: yup.array().of(yup.mixed<File>()).min(1, VALIDATION_MESSAGES.FILE_REQUIRED).required(),
    isPrivate: yup.boolean(),
    docSubcategory: yup.mixed<KYCDocSubcategory>().when('docType', {
        is: (docType: KYCDocType) => SUBCATEGORIES_MAP[docType]?.length > 0,
        then: yup.mixed<KYCDocSubcategory>().required(VALIDATION_MESSAGES.REQUIRED),
    }),
});

export type IKYCFileUploadForm = yup.TypeOf<typeof validationSchema>;
