import React from 'react';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { extendTheme } from '../../../cdk/lib.const';

const TABLE_PROPS_OVERRIDE = {
    component: 'div',
} as any; // TODO fix typings

const divTableTheme = (parentTheme: Theme) =>
    extendTheme(parentTheme)({
        components: {
            MuiTable: {
                defaultProps: TABLE_PROPS_OVERRIDE,
                styleOverrides: {
                    root: {
                        position: 'relative',
                    },
                },
            },
            MuiTableBody: {
                defaultProps: TABLE_PROPS_OVERRIDE,
            },
            MuiTableRow: {
                defaultProps: TABLE_PROPS_OVERRIDE,
            },
            MuiTableCell: {
                defaultProps: TABLE_PROPS_OVERRIDE,
            },
            MuiTableHead: {
                defaultProps: TABLE_PROPS_OVERRIDE,
            },
        },
    });

export const DivTableTheme: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={divTableTheme}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
};
