import React from 'react';
import { useConfirmDialog } from '../../../../../../lib/modals/ConfirmDialog';
import { ClientOnboardingNextStepButton } from '../../ClientOnboardingFrame/ClientOnboardingNextStepButton';

interface ILegalEntitiesSetupNextButtonProps {
    stepIndex: number;
    disabled: boolean;
    dataLength: number;
    complete: boolean;
    onDialogConfirm: () => void;
    onClick?: () => void;
}

export const LegalEntitiesSetupNextButton: React.FC<ILegalEntitiesSetupNextButtonProps> = ({
    dataLength,
    disabled,
    stepIndex,
    complete,
    onDialogConfirm,
    onClick,
}) => {
    const [confirmDialog] = useConfirmDialog();

    return (
        <ClientOnboardingNextStepButton
            stepIndex={stepIndex}
            disabled={disabled}
            onClick={async () => {
                if (complete || dataLength > 0) {
                    if (onClick) {
                        onClick();
                    }
                    return true;
                }

                return new Promise((res) => {
                    confirmDialog.open({
                        title: 'No entities added',
                        body: 'Please enter at least one entity to proceed',
                        submitButtonLabel: 'Add entity',
                        onConfirm: async () => {
                            res(false);
                            onDialogConfirm();
                        },
                        onCancel: () => res(false),
                    });
                });
            }}
        />
    );
};
