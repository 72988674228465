import React from 'react';
import Box from '@mui/material/Box';
import { isDocTypeWithSubcategory, SUBCATEGORIES_MAP } from '../../Documents.const';
import { IDataroomUrlState } from '../../../../pages/Dataroom/useDataroomUrlState';
import { kycDocSubcategoryLabels, kycDocTypeLabels } from 'App/App.const';
import { selectOptionFactory } from 'lib/inputs/Select.const';
import { SearchComponent } from 'lib/inputs/SearchComponent';
import { DocPurpose, KYCDocType } from 'interfaces/types';
import { KYCDocTypeFragment } from './KYCDocTypes.query';
import { Dropdown } from 'lib/inputs/Dropdown';

interface IDocumentsSelectListFilterPanelProps {
    value: IDataroomUrlState;
    onChange: (value: IDataroomUrlState) => void;
    docTypes: KYCDocTypeFragment[];
    showPurposeSwitch?: boolean;
    disableCategoryChange?: boolean;
    disabled?: boolean;
}

const docPurposeFilterLabels: Record<DocPurpose, string> = {
    FACILITY: 'Facility documents',
    KYC: 'KYC documents',
    OTHER: 'Other documents',
};

const getDocTypeOptions = (
    docTypes: KYCDocTypeFragment[],
    purpose: Maybe<DocPurpose> | undefined,
    docType: Maybe<KYCDocType> | undefined
) => {
    const options = docTypes
        .filter((type) => !purpose || type.purpose === purpose)
        .map(({ type: id }) => ({
            id,
            name: kycDocTypeLabels[id],
            value: id,
        }));

    if (docType && options.length === 0) {
        return [{ id: docType, name: kycDocTypeLabels[docType], value: docType }];
    }

    return options;
};

export const getPurposeOptions = (docTypes: KYCDocTypeFragment[]) => {
    return Array.from(new Set(docTypes.map(({ purpose }) => purpose))).map(
        selectOptionFactory(DocPurpose, docPurposeFilterLabels)
    );
};

export const DocumentsSelectListFilterPanel: React.FC<IDocumentsSelectListFilterPanelProps> = ({
    value,
    onChange,
    docTypes,
    showPurposeSwitch,
    disableCategoryChange,
    disabled,
}) => {
    const subcategoryOptions = value.docType
        ? SUBCATEGORIES_MAP[value.docType].map((id) => ({
              id,
              name: kycDocSubcategoryLabels[id],
              value: id,
          }))
        : [];
    const isFacility = value.purpose === DocPurpose.FACILITY;

    return (
        <Box display={'flex'} gap={'16px'} flexWrap={'wrap'}>
            <SearchComponent
                size={'medium'}
                value={value.name}
                sx={{ flex: 1, minWidth: '120px', maxWidth: '600px', marginRight: 'auto' }}
                onChange={(name) => onChange({ ...value, name })}
            />

            {showPurposeSwitch && (
                <Dropdown
                    sx={{ width: '15%' }}
                    options={getPurposeOptions(docTypes)}
                    value={value.purpose}
                    onChange={(option) =>
                        onChange({
                            ...value,
                            purpose: option.value,
                            docType: void 0,
                            docSubcategory: void 0,
                        })
                    }
                    placeholder={'Document type'}
                    disabled={disableCategoryChange || disabled}
                    fullWidth
                />
            )}
            <Dropdown
                placeholder={'Category'}
                sx={{ width: isFacility ? '200px' : 'calc(400px + 16px)' }}
                options={getDocTypeOptions(docTypes, value.purpose, value.docType)}
                value={value.docType}
                onChange={(option) => onChange({ ...value, docType: option?.value, docSubcategory: void 0 })}
                disabled={disabled}
                fullWidth
                clearable
            />
            {isFacility && (
                <Dropdown
                    sx={{ width: '200px' }}
                    placeholder={'Subcategory'}
                    options={subcategoryOptions}
                    value={value.docSubcategory}
                    onChange={(option) => onChange({ ...value, docSubcategory: option?.value })}
                    disabled={disabled || !value.docType || !isDocTypeWithSubcategory(value.docType)}
                    fullWidth
                    clearable
                />
            )}
        </Box>
    );
};
