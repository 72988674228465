import Big, { BigSource } from 'big.js';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';

export const toBigInt = (value: BigSource | null | undefined) => {
    if (!isNil(value)) {
        try {
            return Big(value);
        } catch (e) {
            return null;
        }
    }
    return null;
};

export const toPercentage = (value: number | undefined | null) =>
    isNumber(value) ? toBigInt(value)?.div(100).toNumber() : value;

export const fromPercentage = (value: number | undefined | null) =>
    isNumber(value) ? toBigInt(value)?.mul(100).toNumber() : value;

export const bigIntAddition = (a: MaybeUndefined<number>, b: MaybeUndefined<number>) => {
    if (isNumber(a) && isNumber(b)) {
        return toBigInt(a)
            ?.add(toBigInt(b) || 0)
            ?.toNumber();
    }
    return null;
};

export const bigIntSubstraction = (a: MaybeUndefined<number>, b: MaybeUndefined<number>) => {
    if (isNumber(a) && isNumber(b)) {
        return toBigInt(a)
            ?.sub(toBigInt(b) || 0)
            ?.toNumber();
    }
    return null;
};

export const bigIntDivision = (a: number | undefined | null, b: number | undefined | null) => {
    if (isNumber(a) && isNumber(b)) {
        return toBigInt(a)
            ?.div(b || 1)
            .toNumber();
    }
    return null;
};

export const bigIntMultiplication = (a: number | undefined | null, b: number | undefined | null) => {
    if (isNumber(a) && isNumber(b)) {
        return toBigInt(a)
            ?.mul(b || 1)
            .toNumber();
    }
    return null;
};
