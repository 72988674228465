import React, { useState } from 'react';
import { makeDialogState } from '../../../../../store/dialogState/makeDialogState';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSetRoleMutation, ViewEntityFragment } from '../../../../../interfaces/model';
import { DialogCloseButton } from '../../../../../lib/modals/DialogCloseButton';
import { TopCoRolesList } from './TopCoRolesList/TopCoRolesList';
import { useRoleEditDialog } from '../../../Roles/RoleEditDialog';
import Box from '@mui/material/Box';
import styles from './AddExistingRolesDialog.module.css';
import { useAppSnackbar } from '../../../../components/AppNotification';
import { useParentContext } from '../../../../../lib/cdk/ParentContext';
import { IRolesFilters, RolesFilterPanel } from './RolesFilterPanel';

interface IAddExistingRolesDialogProps {
    entity: ViewEntityFragment;
}

export const useAddExistingRolesDialog = makeDialogState();

const DIALOG_TEST_ID = 'AddExistingRolesDialog';

const getPeopleLabel = (noOfPeople: number) => (noOfPeople > 1 ? 'people' : 'person');

export const AddExistingRolesDialog: React.FC<IAddExistingRolesDialogProps> = ({ entity }) => {
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [filters, setFilters] = useState<IRolesFilters>({});
    const parent = useParentContext();
    const snackbar = useAppSnackbar();
    const [roleEditDialog] = useRoleEditDialog();
    const [{ close }, isOpen] = useAddExistingRolesDialog();
    const [setRole] = useSetRoleMutation();

    const handleRoleSelect = (id: UUID) => {
        if (selectedRoles.includes(id)) {
            setSelectedRoles(selectedRoles.filter((item) => item !== id));
        } else {
            setSelectedRoles([...selectedRoles, id]);
        }
    };

    const handleAddExistingUsers = async () => {
        try {
            setIsSubmitting(true);
            const newRelation = {
                entityId: entity.id,
                enabled: true,
                director: false,
                psc: false,
                ubo: false,
            };
            await Promise.all(
                selectedRoles.map((selectedRole) => {
                    const existingRelations =
                        entity.roles?.find((role) => role.id === selectedRole)?.entityRelations || [];
                    return setRole({
                        variables: {
                            role: {
                                id: selectedRole,
                                entityRelations: [...existingRelations, newRelation],
                            },
                        },
                    });
                })
            );
            await parent.update();
            snackbar.show({ message: 'Users successfully added' });
            clearAndClose();
            setIsSubmitting(false);
        } catch (e) {
            snackbar.showError({ message: 'Failed to add users' }, e);
            setIsSubmitting(false);
        }
    };

    const clearAndClose = () => {
        setSelectedRoles([]);
        setFilters({});
        close();
    };

    const numberOfSelectedUsersString = `${selectedRoles.length} ${getPeopleLabel(selectedRoles.length)}`;

    return (
        <Dialog open={isOpen} fullWidth onClose={clearAndClose} maxWidth={'md'} data-testid={DIALOG_TEST_ID}>
            <DialogTitle>
                Add people to {`"${entity.name}"`}
                <DialogCloseButton onClick={clearAndClose} />
            </DialogTitle>
            <DialogContent
                sx={{
                    background: (theme) => theme.palette.background.default,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: '24px',
                }}
            >
                <Container maxWidth={'md'}>
                    <RolesFilterPanel
                        topCoRef={entity.topCoRef}
                        topCoEntityType={entity.topCo?.entityType}
                        value={filters}
                        onChange={setFilters}
                        entityId={entity.id}
                    />
                    <TopCoRolesList
                        filters={filters}
                        disabled={isSubmitting}
                        entity={entity}
                        selectedRoles={selectedRoles}
                        onRoleSelect={handleRoleSelect}
                    />
                </Container>
            </DialogContent>
            <DialogActions
                sx={{
                    background: (theme) => {
                        if (selectedRoles.length > 0) {
                            return 'var(--bg-paper-light)';
                        }
                        return theme.palette.background.paper;
                    },
                }}
            >
                {selectedRoles.length > 0 && (
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flex={1}>
                        <p className={styles.peopleLabel}>{`${numberOfSelectedUsersString} selected`}</p>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            data-testid={`${DIALOG_TEST_ID}:addExistingPeopleButton`}
                            onClick={handleAddExistingUsers}
                            disabled={isSubmitting}
                        >
                            {`Add ${numberOfSelectedUsersString}`}
                        </Button>
                    </Box>
                )}
                {selectedRoles.length === 0 && (
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        data-testid={`${DIALOG_TEST_ID}:addNewPeopleButton`}
                        onClick={() => {
                            roleEditDialog.open({
                                topCoRef: entity.topCoRef,
                                entityType: entity.entityType,
                                entityRef: entity.id,
                            });
                        }}
                    >
                        Add New People
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
