import React from 'react';
import { documentDownloadUrl } from '../../../API';
import { FileDownload } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { API_ROOT_SELECTOR } from '../../../App.selectors';
import { ListIconButton } from '../../../../lib/dataDisplay/List/components/ListIconButton';

interface IDocumentDownloadIconButtonProps {
    documentId: UUID;
    title?: string;
}

export const DocumentDownloadIconButton: React.FC<IDocumentDownloadIconButtonProps> = ({ title, documentId }) => {
    const apiRoot = useSelector(API_ROOT_SELECTOR);

    return (
        <ListIconButton
            title={title || 'Download file'}
            icon={FileDownload}
            onClick={() => window.open(documentDownloadUrl(apiRoot, documentId), '_blank')}
        />
    );
};
