import React from 'react';
import cl from 'classnames';
import styles from './CollapsibleRow.module.css';
import TableRow from '@mui/material/TableRow';
import { TableCell, TableRowProps } from '@mui/material';

export interface ICollapsibleRowProps extends TableRowProps {
    expand: boolean;
    dark?: boolean;
    colspan?: number;
    className?: string;
    contentClassName?: string;
    renderCollapsed?: boolean;
}

export const CollapsibleRow: React.FC<ICollapsibleRowProps> = ({
    expand,
    dark,
    renderCollapsed,
    colspan,
    children,
    className,
    contentClassName,
    ...props
}) => {
    return (
        <TableRow
            className={cl(
                'non-interactive',
                className,
                expand ? styles.expand : styles.collapse,
                dark && styles.darkBg
            )}
            {...props}
        >
            {colspan ? (
                <TableCell className={cl(styles.wrap, contentClassName)} colSpan={colspan}>
                    {(expand || renderCollapsed) && children}
                </TableCell>
            ) : (
                (expand || renderCollapsed) && children
            )}
        </TableRow>
    );
};
