import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import React, { useLayoutEffect, useState } from 'react';
import isFunction from 'lodash/isFunction';

export interface IPageHeadingPortalProps {
    className?: string;
}

const containerRef = React.createRef<HTMLDivElement>();

export const PageHeadingPortal: React.FC<IPageHeadingPortalProps> = ({ className }) => {
    return <Box display={'flex'} flexDirection={'column'} className={className} ref={containerRef} />;
};

export const PageHeading = React.forwardRef<Element, { children: React.ReactNode }>(({ children }, ref) => {
    const container = ReactDOM.findDOMNode(containerRef.current) as Element;

    /**
     * For a first render, when AppHeadingPortal and AppHeading are being rendered in the same cycle,
     * containerRef will always be null for AppHeading. To fix that, we use useLayoutEffect,
     * which forces AppHeading to re-render with existing containerRef
     */
    const [containerExists, setContainerExists] = useState(false);
    useLayoutEffect(() => {
        if (!containerExists) {
            setContainerExists(true);
        }
    }, [containerExists]);

    useLayoutEffect(() => {
        if (ref) {
            if (isFunction(ref)) {
                ref(container);
            } else {
                (ref as any).current = container;
            }
        }
    }, [container, ref]);

    return container && ReactDOM.createPortal(children, container);
});
