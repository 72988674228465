import React from 'react';
import styles from './ReadonlyValue.module.css';
import { useFormikField } from '../../forms/useFormikField';
import { ValidationMessage } from '../../forms/ValidationMessage';

interface IReadonlyValueProps {
    value?: string;
    name?: string;
}

export const ReadonlyValue: React.FC<IReadonlyValueProps> = ({ name, value: valueProp }) => {
    const { meta, field } = useFormikField<string>(name);

    const value = valueProp || field?.value;
    const touched = meta?.touched;
    const error = meta?.error;

    return (
        <div>
            <div className={styles.value}>{value}</div>
            {name && touched && error && <ValidationMessage field={name} />}
        </div>
    );
};
