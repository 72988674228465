const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

type RGBColor = readonly [number, number, number];

const calculateLuminance = (rgb: RGBColor) => {
    let r = rgb[0] / 255;
    let g = rgb[1] / 255;
    let b = rgb[2] / 255;

    r = r <= 0.03928 ? r / 12.92 : ((r + 0.055) / 1.055) ** 2.4;
    g = g <= 0.03928 ? g / 12.92 : ((g + 0.055) / 1.055) ** 2.4;
    b = b <= 0.03928 ? b / 12.92 : ((b + 0.055) / 1.055) ** 2.4;

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const calculateContrast = (rgb1: RGBColor, rgb2: RGBColor) => {
    const luminance1 = calculateLuminance(rgb1);
    const luminance2 = calculateLuminance(rgb2);
    return luminance1 > luminance2
        ? (luminance1 + 0.05) / (luminance2 + 0.05)
        : (luminance2 + 0.05) / (luminance1 + 0.05);
};

const hexToRgb = (hex: string) => {
    const r = `0x${hex[1] + hex[2]}`;
    const g = `0x${hex[3] + hex[4]}`;
    const b = `0x${hex[5] + hex[6]}`;
    return [+r, +g, +b] as const;
};

const TEXT_COLOR_DARK = '#1d2b3c';
const TEXT_COLOR_LIGHT = '#e8e8e8';

const getTextColor = (bgColorHex: string) => {
    const bgColor = hexToRgb(bgColorHex);
    const contrastLight = calculateContrast(bgColor, hexToRgb(TEXT_COLOR_LIGHT));
    const contrastDark = calculateContrast(bgColor, hexToRgb(TEXT_COLOR_DARK));
    if (contrastLight > contrastDark) {
        return TEXT_COLOR_LIGHT;
    }
    return TEXT_COLOR_DARK;
};

export const getAvatarColors = (name: string) => {
    const bgColor = stringToColor(name);
    const textColor = getTextColor(bgColor);
    return [bgColor, textColor] as const;
};
