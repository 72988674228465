import { CounterpartyStatus } from '../../../interfaces/model';

export enum CounterpartiesListMode {
    DEFAULT = 'DEFAULT',
    FLAT = 'FLAT',
}

export const counterpartyStatusLabels: Record<CounterpartyStatus, string> = {
    UNAPPROVED: 'Unapproved',
    ACTION_REQUIRED: 'Action required',
    IN_PROGRESS: 'In progress',
    APPROVED: 'Approved',
};

export const counterpartyStatusColors: Record<CounterpartyStatus, Colors> = {
    UNAPPROVED: 'secondary',
    ACTION_REQUIRED: 'error',
    IN_PROGRESS: 'warning',
    APPROVED: 'success',
};
