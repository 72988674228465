import { useDispatch } from 'react-redux';
import { appHideSpinner, appShowSpinner } from '../App.actions';

export const useAppSpinner = () => {
    const dispatch = useDispatch();
    return {
        show: () => dispatch(appShowSpinner()),
        hide: () => dispatch(appHideSpinner()),
    };
};
