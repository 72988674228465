import React from 'react';
import cl from 'classnames';
import { SvgIconComponent } from '@mui/icons-material';
import {
    Button,
    ButtonGroup,
    ButtonProps,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from './SplitButton.module.css';
import { LoadingSpinnerButton } from '../LoadingSpinnerButton';

export interface ISplitButtonOption {
    id: string;
    name: string;
    testId?: string;
    primary?: boolean;
    className?: string;
    color?: Colors;
    Icon?: SvgIconComponent;
    onClick: (option: ISplitButtonOption) => void;
}

export interface ISplitButtonProps extends Pick<ButtonProps, 'size' | 'color' | 'variant' | 'className' | 'disabled'> {
    spin?: boolean;
    options: ISplitButtonOption[];
}

export const SplitButton: React.FC<ISplitButtonProps> = ({ options, className, spin, ...props }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const primaryOption = options.find(({ primary }) => primary) || options[0];
    const secondaryOptions = options.filter(({ id }) => id !== primaryOption.id);

    if (!primaryOption) {
        return null;
    }

    return (
        <React.Fragment>
            <ButtonGroup variant={'contained'} className={className} ref={anchorRef} disableElevation>
                <LoadingSpinnerButton
                    {...props}
                    spin={!!spin}
                    color={primaryOption.color}
                    label={primaryOption.name}
                    data-testid={primaryOption.testId}
                    className={cl('primaryAction', styles.primaryAction, primaryOption.className)}
                    onClick={() => primaryOption.onClick(primaryOption)}
                />
                {secondaryOptions.length > 0 && (
                    <Button
                        {...props}
                        className={styles.moreActionsButton}
                        onClick={(e) => {
                            e.preventDefault();
                            setOpen((prevOpen) => !prevOpen);
                        }}
                        disabled={spin || props.disabled}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                )}
            </ButtonGroup>
            {secondaryOptions.length > 0 && (
                <Popper
                    open={open}
                    placement={'bottom-end'}
                    sx={{ zIndex: 1300 }}
                    anchorEl={anchorRef.current}
                    modifiers={[
                        {
                            name: 'offset',
                            enabled: true,
                            options: {
                                offset: [0, 5],
                            },
                        },
                    ]}
                    disablePortal={false}
                    transition
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps} style={{ transformOrigin: 'end top' }}>
                            <Paper
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                elevation={3}
                            >
                                <ClickAwayListener
                                    onClickAway={(event) => {
                                        if (!anchorRef.current?.contains(event.target as HTMLElement)) {
                                            setOpen(false);
                                        }
                                    }}
                                >
                                    <MenuList autoFocusItem>
                                        {secondaryOptions.map(({ Icon, testId, ...option }) => (
                                            <MenuItem
                                                key={option.id}
                                                data-testid={testId}
                                                className={cl(option.className, option.color)}
                                                onClick={() => {
                                                    setOpen(false);
                                                    if (option.onClick) {
                                                        option.onClick(option);
                                                    }
                                                }}
                                            >
                                                {Icon && <Icon color={'secondary'} sx={{ marginRight: '16px' }} />}
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </React.Fragment>
    );
};
