import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Country, KYCDocType, TaxIdFragment, TaxIdInput } from '../../../../../interfaces/model';
import { VALIDATION_MESSAGES, yupArrayTypeFix } from '../../../../../lib/forms';
import { fromDateISO } from '../../../../../lib/utils/date';

export const validationSchema = yup.object({
    id: yup.string(),
    country: yup.mixed<Country>().required(VALIDATION_MESSAGES.REQUIRED),
    taxIdValue: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    files: yup.array(yupArrayTypeFix(yup.mixed<File>())).when('id', {
        is: (id: string) => !id,
        then: yup
            .array(yupArrayTypeFix(yup.mixed<File>()))
            .min(1, VALIDATION_MESSAGES.FILE_REQUIRED)
            .required(VALIDATION_MESSAGES.FILE_REQUIRED),
    }),
    validFromDate: yup
        .date()
        .nullable()
        .when('validToDate', {
            is: (validToDate: Date | undefined) => Boolean(validToDate),
            then: yup.date().max(yup.ref('validToDate'), VALIDATION_MESSAGES.MAX_DATE),
        })
        .required(VALIDATION_MESSAGES.REQUIRED),
    validToDate: yup
        .date()
        .nullable()
        .min(yup.ref('validFromDate'), VALIDATION_MESSAGES.MIN_DATE)
        .min(new Date(), 'Date must not be in the past')
        .required(VALIDATION_MESSAGES.REQUIRED),
});

export type ITaxIdEditDialogValue = Pick<TaxIdFragment, 'id' | 'country' | 'taxIdValue' | 'kycId'> & {
    document: {
        name: Maybe<string> | undefined;
        validFromDate: Maybe<LocalDate> | undefined;
        validToDate: Maybe<LocalDate> | undefined;
        documents: { displayName: Maybe<string> }[];
    } | null;
};

export const getInitialValues = (taxId: Maybe<ITaxIdEditDialogValue> | undefined) => {
    return {
        id: taxId?.id,
        name: taxId?.document?.name,
        docType: KYCDocType.TAX_ID_FORM,
        country: taxId?.country,
        taxIdValue: taxId?.taxIdValue,
        kycId: taxId?.kycId,
        files:
            taxId?.document?.documents.map(({ displayName }) => {
                return new File([], displayName || 'unknown file');
            }) || [],
        validFromDate: fromDateISO(taxId?.document?.validFromDate),
        validToDate: fromDateISO(taxId?.document?.validToDate || void 0),
        comment: '',
    };
};

export const prepareTaxIdModelValue = (
    taxId: {
        id: UUID | undefined;
        country: Country | undefined;
        kycId: Maybe<UUID> | undefined;
        taxIdValue: string | undefined;
    },
    fileId?: Maybe<UUID>
): TaxIdInput => {
    return {
        id: taxId?.id || uuid(),
        country: taxId?.country || Country.GB,
        taxIdValue: taxId?.taxIdValue || '-',
        kycId: fileId || taxId.kycId,
    };
};

export const removeExtraTaxIdModelFields = (taxId: TaxIdFragment): TaxIdInput => {
    return {
        id: taxId.id,
        taxIdValue: taxId.taxIdValue,
        country: taxId.country,
        kycId: taxId.kycId,
    };
};

export interface ITaxIdEntityFundEdit extends TaxIdFragment {
    taxIdFiles?: File[];
    validFromDate?: Date;
    validToDate?: Date;
}

export interface IDocumentEdit {
    name: string | undefined;
    comment: string | undefined;
    validFromDate: Date | undefined;
    validToDate: Date | undefined;
    files: File[];
}
