import React from 'react';
import {
    FundEntityCounterpartyKYCListRow,
    IEntityCounterpartyKYCListRowItem,
} from './FundEntityCounterpartyKYCListRow';
import { useFundEntityCounterpartyKYCListQuery } from './FundEntityCounterpartyKYCList.query';
import { prepareData } from './FundEntityCounterpartyKYCList.const';
import { List } from 'lib/dataDisplay/List/List';
import { IListColumn } from 'lib/dataDisplay/List';
import { NonFalsy } from 'lib/utils/helpers';
import { EntityType } from 'interfaces/types';

export interface IEntityCounterpartyKYCListChild {
    id: UUID;
    entityType?: EntityType;
    loanFunds?: Maybe<{ id: UUID }[]>;
}

export interface IFundEntityCounterpartyKYCListValue extends IEntityCounterpartyKYCListChild {
    topCoRef: UUID | null;
    entityType?: EntityType;
    childs?: Maybe<IEntityCounterpartyKYCListChild[]>;
}

export interface IEntityCounterpartyKYCListProps {
    value: IFundEntityCounterpartyKYCListValue;
    targets: UUID[];
    otherCompanyViewMode?: boolean;
}

export const FundEntityCounterpartyKYCList: React.FC<IEntityCounterpartyKYCListProps> = ({
    value,
    targets: targetRefs,
    otherCompanyViewMode,
}) => {
    const isTopCo = value.id === value.topCoRef;
    const isCollapsible = isTopCo || !otherCompanyViewMode;
    const { data, loading, error } = useFundEntityCounterpartyKYCListQuery({
        fetchPolicy: 'cache-and-network',
        skip: targetRefs.length === 0,
        variables: {
            targetRefs,
            topCoRef: otherCompanyViewMode ? value.topCoRef : void 0,
        },
    });

    const columns: IListColumn<IEntityCounterpartyKYCListRowItem>[] = otherCompanyViewMode
        ? (
              [
                  { id: 'targetName', label: 'My fund / entity' },
                  { id: 'changedAt', label: 'Last change' },
                  { id: 'status', label: 'KYC' },
                  { id: 'hasBlockingTradeDependency', label: 'Trade dep.', nowrap: true, sort: false, shrink: true },
                  { id: 'actions', label: 'Actions', sort: false, shrink: true },
              ] as const
          ).filter(NonFalsy)
        : (
              [
                  { id: 'topCoName', label: 'TopCo' } as const,
                  { id: 'headquarters', label: 'Headquarters' } as const,
                  isTopCo && ({ id: 'targetName', label: 'My fund / entity' } as const),
                  { id: 'changedAt', label: 'Last change' },
                  { id: 'status', label: 'KYC' },
                  { id: 'hasBlockingTradeDependency', label: 'Trade dep.', nowrap: true, sort: false, shrink: true },
                  { id: 'actions', label: 'Actions', sort: false, shrink: true },
              ] as const
          ).filter(NonFalsy);

    return (
        <List
            columns={columns}
            data={prepareData(data?.counterpartyList.topCos || [], value.id, isTopCo, !!otherCompanyViewMode)}
            rowComponent={FundEntityCounterpartyKYCListRow}
            rowComponentProps={{
                targets: targetRefs,
                topCoRef: value.topCoRef,
                collapsible: isCollapsible,
            }}
            loading={loading}
            error={error}
            pagination
        />
    );
};
