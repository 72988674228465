import React from 'react';
import cl from 'classnames';
import Stack from '@mui/material/Stack';
import { Box, StackProps } from '@mui/material';
import styles from './Fieldset.module.css';

export interface IFieldsetProps extends Omit<StackProps, 'title'> {
    title: React.ReactNode;
    description?: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    required?: boolean;
    hide?: boolean;
    marginBottom?: string | number;
}

export const Fieldset: React.FC<IFieldsetProps> = ({
    title,
    description,
    children,
    className,
    required,
    hide,
    marginBottom = '32px',
    ...props
}) => {
    if (hide) {
        return null;
    }

    return (
        <Box className={cl(styles.container, className)} marginBottom={marginBottom}>
            <div className={cl(styles.title, required && styles.requiredAsterisk)}>{title}</div>
            {description && <div className={styles.description}>{description}</div>}
            <Stack className={styles.content} {...props}>
                {children}
            </Stack>
        </Box>
    );
};
