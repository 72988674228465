import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute';
import * as ROUTES from './App.routes';
import { useUserDetails } from './hooks/useUserDetails';

const Facilities = React.lazy(() => import('./pages/Facilities'));
const ViewFacility = React.lazy(() => import('./pages/Facilities/tabs/FacilitiesTab/ViewFacility'));
const EditFacility = React.lazy(() => import('./pages/Facilities/tabs/FacilitiesTab/EditFacility'));
const ViewTrade = React.lazy(() => import('./pages/Trades/ViewTrade'));
const EditTrade = React.lazy(() => import('./pages/Trades/EditTrade'));
const LendersList = React.lazy(() => import('./pages/Entities/LendersList'));
const BorrowersList = React.lazy(() => import('./pages/Entities/BorrowersList'));
const AgentsList = React.lazy(() => import('./pages/Entities/AgentsList'));
const ViewEntity = React.lazy(() => import('./pages/Entities/ViewEntity'));
const SponsorsList = React.lazy(() => import('./pages/Entities/SponsorsList'));
const FundAdminsList = React.lazy(() => import('./pages/Entities/FundAdminsList'));
const TradesList = React.lazy(() => import('./pages/Trades/TradesList'));
const FundsList = React.lazy(() => import('./pages/Funds/FundsList'));
const ViewFund = React.lazy(() => import('./pages/Funds/ViewFund'));
const ViewRole = React.lazy(() => import('./pages/Roles/ViewRole'));
const Dataroom = React.lazy(() => import('./pages/Dataroom'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Notifications = React.lazy(() => import('./pages/Notifications'));
const HoldingsList = React.lazy(() => import('./pages/Holdings/HoldingsList'));
const HoldingsDetails = React.lazy(() => import('./pages/Holdings/HoldingsDetails'));
const BusinessHolidays = React.lazy(() => import('./pages/BusinessHolidays'));
const InterestRates = React.lazy(() => import('./pages/InterestRates'));
const Payments = React.lazy(() => import('./pages/Payments'));
const EventsList = React.lazy(() => import('./pages/Events/EventsList'));
const ViewEvent = React.lazy(() => import('./pages/Events/ViewEvent'));
const ClientOnboarding = React.lazy(() => import('./pages/Onboarding/ClientOnboarding'));
const ClientSetup = React.lazy(() => import('./pages/Onboarding/ClientSetup'));
const CreateEntityWizard = React.lazy(() => import('./pages/Entities/CreateEntityWizard'));
const Counterparties = React.lazy(() => import('./pages/Counterparties/Counterparties'));
const CounterpartiesEdit = React.lazy(() => import('./pages/Counterparties/CounterpartiesEdit'));
const CounterpartyKYC = React.lazy(() => import('./pages/Counterparties/CounterpartyKYC'));
const CounterpartyKYCEdit = React.lazy(() => import('./pages/Counterparties/CounterpartyKYCEdit'));
const AppSettings = React.lazy(() => import('./pages/Settings'));

export const Body = () => {
    const { isAdmin } = useUserDetails();

    return (
        <Switch>
            <ProtectedRoute path={ROUTES.DASHBOARD_ROUTE} component={Dashboard} />

            <Route path={ROUTES.ROLE_DOCUMENTS_ROUTE_FACTORY()} component={Dataroom} />
            <Route path={ROUTES.ROLE_VIEW_ROUTE_FACTORY()} component={ViewRole} />

            <ProtectedRoute path={ROUTES.COUNTERPARTIES_EDIT_ROUTE_FACTORY()} component={CounterpartiesEdit} />
            <ProtectedRoute path={ROUTES.COUNTERPARTIES_ROUTE_FACTORY()} component={Counterparties} />

            <ProtectedRoute path={ROUTES.COUNTERPARTY_KYC_EDIT_ROUTE_FACTORY()} component={CounterpartyKYCEdit} />
            <ProtectedRoute path={ROUTES.COUNTERPARTY_KYC_ROUTE_FACTORY()} component={CounterpartyKYC} />

            <Route exact path={ROUTES.DOCUMENTS_ROUTE} component={Dataroom} />

            <Route path={ROUTES.LENDER_DOCUMENTS_ROUTE_FACTORY()} component={Dataroom} />
            <ProtectedRoute exact path={ROUTES.LENDERS_ROUTE} component={LendersList} />
            <ProtectedRoute exact path={ROUTES.LENDERS_CREATE_ROUTE} component={CreateEntityWizard} />
            <ProtectedRoute path={ROUTES.LENDER_VIEW_ROUTE_FACTORY()} component={ViewEntity} />

            <Route path={ROUTES.BORROWER_DOCUMENTS_ROUTE_FACTORY()} component={Dataroom} />

            {/* Borrowers are not protected to allow private lenders see their borrowers */}
            <Route exact path={ROUTES.BORROWERS_ROUTE} component={BorrowersList} />

            <ProtectedRoute exact path={ROUTES.BORROWERS_CREATE_ROUTE} component={CreateEntityWizard} />
            {/* Borrowers are not protected to allow private lenders see their borrowers */}
            <Route path={ROUTES.BORROWER_VIEW_ROUTE_FACTORY()} component={ViewEntity} />

            <Route path={ROUTES.AGENT_DOCUMENTS_ROUTE_FACTORY()} component={Dataroom} />
            <ProtectedRoute exact path={ROUTES.AGENTS_ROUTE} component={AgentsList} />
            <ProtectedRoute path={ROUTES.AGENT_VIEW_ROUTE_FACTORY()} component={ViewEntity} />

            <Route path={ROUTES.FUND_ADMIN_DOCUMENTS_ROUTE_FACTORY()} component={Dataroom} />
            <ProtectedRoute exact path={ROUTES.FUND_ADMINS_ROUTE} component={FundAdminsList} />
            <ProtectedRoute exact path={ROUTES.FUND_ADMIN_VIEW_ROUTE_FACTORY()} component={ViewEntity} />

            <Route path={ROUTES.SPONSOR_DOCUMENTS_ROUTE_FACTORY()} component={Dataroom} />
            <ProtectedRoute exact path={ROUTES.SPONSORS_ROUTE} component={SponsorsList} />
            <ProtectedRoute path={ROUTES.SPONSOR_VIEW_ROUTE_FACTORY()} component={ViewEntity} />

            <ProtectedRoute exact path={ROUTES.TRADES_ROUTE} component={TradesList} />
            <ProtectedRoute path={ROUTES.TRADE_CREATE_ROUTE} component={EditTrade} />
            <ProtectedRoute path={ROUTES.TRADE_EDIT_ROUTE_FACTORY()} component={EditTrade} />
            <ProtectedRoute path={ROUTES.TRADE_VIEW_ROUTE_FACTORY()} component={ViewTrade} />

            <Route exact path={ROUTES.NOTIFICATION_TAB_ROUTE_FACTORY()} component={Notifications} />

            <ProtectedRoute exact path={ROUTES.HOLDINGS_ROUTE} component={HoldingsList} />
            <ProtectedRoute exact path={ROUTES.HOLDINGS_DETAILS_ROUTE_FACTORY()} component={HoldingsDetails} />

            <ProtectedRoute exact path={ROUTES.INTEREST_ROUTE} component={InterestRates} />
            <ProtectedRoute exact path={ROUTES.PAYMENTS_ROUTE_TAB_FACTORY()} component={Payments} />

            <Route path={ROUTES.FUND_DOCUMENTS_ROUTE_FACTORY()} component={Dataroom} />
            <ProtectedRoute exact path={ROUTES.FUND_ROUTE} component={FundsList} />
            <ProtectedRoute path={ROUTES.FUND_VIEW_ROUTE_FACTORY()} component={ViewFund} />

            <ProtectedRoute path={ROUTES.FACILITY_CREATE_ROUTE} component={EditFacility} />
            <ProtectedRoute path={ROUTES.FACILITY_EDIT_ROUTE_FACTORY()} component={EditFacility} />
            <ProtectedRoute path={ROUTES.FACILITY_VIEW_ROUTE_FACTORY()} component={ViewFacility} />
            <ProtectedRoute exact path={ROUTES.FACILITIES_ROUTE_TAB_FACTORY()} component={Facilities} />

            <ProtectedRoute exact path={ROUTES.BUSINESS_HOLIDAYS_ROUTE} component={BusinessHolidays} />

            <Route exact path={ROUTES.EVENTS_ROUTE} component={EventsList} />
            <Route path={ROUTES.EVENT_VIEW_ROUTE_FACTORY()} component={ViewEvent} />

            <Route exact path={ROUTES.CLIENT_SETUP_ROUTE_FACTORY()} component={ClientSetup} />
            <Route exact path={ROUTES.CLIENT_ONBOARDING_ROUTE} component={ClientOnboarding} />

            {isAdmin && <Route exact path={ROUTES.SETTINGS_ROUTE} component={AppSettings} />}

            <Route path={'/'} exact>
                <Redirect to={ROUTES.DASHBOARD_ROUTE} />
            </Route>
            {/*TODO implement 404 page */}
            <Route path={'*'}>Page not found</Route>
        </Switch>
    );
};
