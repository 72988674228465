import React from 'react';
import cl from 'classnames';
import styles from './TreeItem.module.css';

interface ITreeItemProps {
    offset: number;
    active?: boolean;
    onClick?: () => void;
    className?: string;
    interactive?: boolean;
    children: React.ReactNode;
}

const LEVEL_OFFSETS = [styles.offset0, styles.offset1, styles.offset2];

export const TreeItem: React.FC<ITreeItemProps> = ({
    className,
    children,
    active,
    offset,
    onClick,
    interactive = true,
}) => {
    return (
        <div
            className={cl(
                className,
                styles.treeItem,
                interactive && styles.interactive,
                active && styles.active,
                LEVEL_OFFSETS[offset]
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
};
