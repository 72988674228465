import React from 'react';
import { IListColumn, List } from '../../../../../../lib/dataDisplay/List';
import { DivTableTheme } from '../../../../../../lib/dataDisplay/List/themes/DivTableTheme';
import {
    EntityFacilitiesListRowFragment,
    FacilityType,
    PrivateFacilityMemberStatus,
} from '../../../../../../interfaces/model';
import {
    EntityFacilitiesListRow,
    IFacilitiesListRowItem,
    prepareEntityFacilitiesListItem,
} from './EntityFacilitiesListRow';
import { NonFalsy } from 'lib/utils/helpers';

interface IEntityFacilitiesListProps {
    facilities: EntityFacilitiesListRowFragment[];
    updateData: () => void;
    testId?: string;
}

export const EntityFacilitiesList: React.FC<IEntityFacilitiesListProps> = ({ facilities, testId }) => {
    const rows = facilities.map(prepareEntityFacilitiesListItem);
    const displayTrancheColumn = rows.some(({ type }) => ![FacilityType.RCF, FacilityType.DELAYED_DRAW].includes(type));
    const displayAccessRightsColumn = rows.some(
        ({ facility }) =>
            facility.hasPrivateRequestAccess || facility.isPrivateFacilityMember === PrivateFacilityMemberStatus.PRIVATE
    );
    const columns: IListColumn<IFacilitiesListRowItem>[] = (
        [
            { id: 'shortCode', label: 'ID', shrink: true },
            { id: 'issuerName', label: 'Issuer' },
            { id: 'type', label: 'Facility' },
            displayTrancheColumn && ({ id: 'tranche', label: 'Tranche' } as const),
            { id: 'rank', label: 'Rank' },
            { id: 'currency', label: 'Currency' },
            { id: 'facilitySize', label: 'Facility size' },
            displayAccessRightsColumn && ({ id: 'access', label: 'Access', sort: false } as const),
            { id: 'actions', label: 'Actions', sort: false, shrink: true },
        ] as const
    ).filter(NonFalsy);

    return (
        <DivTableTheme>
            <List
                data={rows}
                columns={columns}
                rowComponent={EntityFacilitiesListRow}
                rowComponentProps={{
                    updateData: () => {},
                }}
                testId={testId}
                pagination
            />
        </DivTableTheme>
    );
};
