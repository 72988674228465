import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import styles from './LoginScreenFrame.module.css';
import { Form } from 'formik';

export const LoginScreenFrame: React.FC<{
    title: React.ReactNode;
    description: React.ReactNode;
    children?: React.ReactNode;
}> = ({ title, description, children }) => (
    <Stack spacing={1}>
        <Box marginBottom={'2vh'}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.description}>{description}</div>
        </Box>
        <Stack spacing={3} component={Form}>
            {children}
        </Stack>
    </Stack>
);
