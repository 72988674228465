import React from 'react';
import { Alert, Breadcrumbs } from '@mui/material';
import { LENDER_VIEW_ROUTE_FACTORY, LENDERS_ROUTE } from '../../../../App.routes';
import { AgentManagementLabel } from '../../../../common/AgentManagementLabel';
import { PageHeading, PageTitle } from '../../../../components/PageHeading';
import { Breadcrumb } from 'lib/navigation/Breadcrumbs/Breadcrumb';
import { AppTitle } from '../../../../components/AppTitle';
import { LoanFundFragment } from 'interfaces/model';

interface IViewFundHeadingProps {
    fund: LoanFundFragment | undefined;
    config?: {
        breadcrumbsInteractive?: boolean;
    };
}

const FundBreadcrumbs: React.FC<IViewFundHeadingProps> = ({ fund, config }) => {
    const topCo = fund?.topCo;
    const entity = fund?.entity;
    const interactive = config?.breadcrumbsInteractive !== false;
    const topCoRoute = topCo && interactive ? LENDER_VIEW_ROUTE_FACTORY(topCo?.shortCode!) : void 0;
    const investmentManagerRoute = entity && interactive ? LENDER_VIEW_ROUTE_FACTORY(entity?.shortCode!) : void 0;

    return (
        <Breadcrumbs sx={{ marginTop: '8px' }}>
            <Breadcrumb to={interactive ? LENDERS_ROUTE : void 0}>Lenders</Breadcrumb>
            <Breadcrumb to={topCoRoute}>{topCo?.name}</Breadcrumb>
            <Breadcrumb to={investmentManagerRoute}>{entity?.name}</Breadcrumb>
            <Breadcrumb>Funds</Breadcrumb>
            <Breadcrumb>{fund?.name}</Breadcrumb>
        </Breadcrumbs>
    );
};

export const ViewFundHeading: React.FC<IViewFundHeadingProps> = ({ fund, config }) => {
    return (
        <PageHeading>
            <AppTitle value={fund?.name} />
            <PageTitle
                title={
                    <span>
                        {fund?.name}
                        {fund?.isAgentExt && <AgentManagementLabel />}
                    </span>
                }
            />
            <FundBreadcrumbs fund={fund} config={config} />
            {fund?.deleted && (
                <Alert severity="warning" sx={{ marginTop: '8px' }}>
                    This loan fund has been removed from the system and cannot be used
                </Alert>
            )}
        </PageHeading>
    );
};
