import Box from '@mui/material/Box';
import isEqual from 'lodash/isEqual';
import { FormikContext, setIn } from 'formik';
import React, { useContext } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress, TextField, TextFieldProps, Tooltip } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import { ValidationMessage } from '../forms/ValidationMessage';
import { ISelectOption } from './Select.const';

export interface IDropdownProps<
    TValue,
    T extends ISelectOption<string, TValue>,
    Multiple extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> extends Omit<
        AutocompleteProps<T, Multiple, boolean, FreeSolo>,
        'value' | 'renderInput' | 'onChange' | 'disableClearable' | 'color'
    > {
    name?: string;
    value?: TValue;
    onChange?: (value: T) => void;
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
    InputProps?: TextFieldProps;
    placeholder?: string;
    minWidth?: string;
    maxWidth?: string;
    helperText?: React.ReactNode;
    autoFocus?: boolean;
    error?: boolean;
    clearable?: boolean;
    startAdornment?: React.ReactNode;
    getDisabledOptionTooltipText?: (option: T) => React.ReactNode;
    color?: TextFieldProps['color'];
}

export function filterOptionsFn<T extends { name?: string }>(options: T[], state: FilterOptionsState<T>) {
    const inputValue = state.inputValue.toLowerCase();
    return options.filter(({ name }) => {
        return name?.toLowerCase().includes(inputValue) || name?.toLowerCase().includes(inputValue);
    });
}

export function Dropdown<
    TValue,
    T extends ISelectOption<string, TValue>,
    Multiple extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>({
    id,
    name,
    value: valueProp,
    onChange: onChangeProp,
    placeholder = 'Select option',
    renderInput,
    options,
    error,
    minWidth = '160px',
    maxWidth,
    autoFocus,
    disabled,
    InputProps,
    getOptionLabel,
    helperText,
    loading,
    clearable,
    renderOption,
    startAdornment,
    getDisabledOptionTooltipText,
    sx,
    color,
    ...props
}: IDropdownProps<TValue, T, Multiple, FreeSolo>) {
    const fieldName = name || id;
    const formik = useContext(FormikContext);
    const formField = fieldName ? formik?.getFieldProps(fieldName) : void 0;
    const formFieldMeta = fieldName ? formik?.getFieldMeta(fieldName) : void 0;

    const value = valueProp ?? formField?.value;
    const valueOption = options.find((option) => option.id === String(value));
    const onChange = onChangeProp || ((option: T) => name && formik.setFieldValue(name, option.value));
    const renderOptionLabel = (option: T) => {
        return (getOptionLabel && option && getOptionLabel(option)) || option?.name || '';
    };

    const handleOnBlur = () => {
        if (name) {
            formik.setTouched(setIn({ ...formik.touched }, name, true), true);
        }
    };

    return (
        <>
            <Autocomplete
                {...props}
                id={id || fieldName}
                options={options}
                sx={{ minWidth, maxWidth, ...sx }}
                // Pass null to prevent warning in console
                value={(valueOption || null) as any}
                filterOptions={filterOptionsFn}
                onChange={(_, option) => {
                    if (fieldName) {
                        formik.setFieldTouched(fieldName, true, false);
                    }
                    onChange(option as T);
                }}
                onBlur={handleOnBlur}
                getOptionLabel={renderOptionLabel}
                renderOption={
                    renderOption ||
                    ((params, option) => {
                        if (params['aria-disabled'] && getDisabledOptionTooltipText) {
                            const newParams = { ...params };
                            delete newParams['aria-disabled'];
                            return (
                                <li {...newParams} key={option.id}>
                                    <Tooltip title={getDisabledOptionTooltipText(option)} arrow>
                                        <span
                                            aria-disabled={'true'}
                                            className={'MuiAutocomplete-option'}
                                            style={{ paddingLeft: 0, pointerEvents: 'all' }}
                                        >
                                            {renderOptionLabel(option)}
                                        </span>
                                    </Tooltip>
                                </li>
                            );
                        }

                        return (
                            <li {...params} key={option.id}>
                                {renderOptionLabel(option)}
                            </li>
                        );
                    })
                }
                isOptionEqualToValue={props.isOptionEqualToValue || isEqual}
                renderInput={
                    renderInput ||
                    ((params) => {
                        return (
                            <TextField
                                error={error || (formFieldMeta?.touched && !!formFieldMeta.error)}
                                {...params}
                                color={color}
                                placeholder={placeholder}
                                helperText={helperText}
                                autoFocus={autoFocus}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment,
                                    endAdornment: loading ? (
                                        <Box
                                            position={'absolute'}
                                            right={'12px'}
                                            top={'50%'}
                                            sx={{ transform: 'translateY(-50%)' }}
                                        >
                                            <CircularProgress color={'primary'} size={24} />
                                        </Box>
                                    ) : (
                                        params.InputProps?.endAdornment
                                    ),
                                }}
                                {...InputProps}
                            />
                        );
                    })
                }
                clearIcon={<ClearIcon fontSize="small" data-testid={'DataSelect:clear'} />}
                disabled={loading || disabled || formik?.isSubmitting}
                disableClearable={!clearable}
            />
            {fieldName && <ValidationMessage field={fieldName} />}
        </>
    );
}
