import React from 'react';
import { Alert, Box, Breadcrumbs, Tooltip } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { getEntityViewRouteFactory } from '../../Entities/Entities.helpers';
import { RootBreadcrumb } from 'lib/navigation/Breadcrumbs/RootBreadcrumb';
import { PageHeading, PageTitle } from '../../../components/PageHeading';
import { Breadcrumb } from 'lib/navigation/Breadcrumbs/Breadcrumb';
import { AppTitle } from '../../../components/AppTitle';
import { IdType, RoleFragment } from 'interfaces/model';

interface IViewRoleHeadingProps {
    role: RoleFragment | undefined;
    updateData: () => Promise<void>;
    config?: {
        breadcrumbsInteractive?: boolean;
    };
}

const SystemAdminShield: React.FC = () => (
    <Box display={'flex'} fontSize={14} alignItems={'center'} marginLeft={'8px'}>
        <Tooltip title={'System Administrator'}>
            <AdminPanelSettingsIcon color={'primary'} fontSize={'large'} sx={{ marginLeft: '4px' }} />
        </Tooltip>
    </Box>
);

const RoleBreadcrumbs: React.FC<{
    role: RoleFragment | undefined;
    config: IViewRoleHeadingProps['config'];
}> = ({ role, config }) => {
    const topCo = role?.topCo;
    const topCoRoute =
        topCo && config?.breadcrumbsInteractive !== false ? getEntityViewRouteFactory(topCo)(topCo.shortCode!) : void 0;

    return (
        <Breadcrumbs sx={{ marginTop: '8px' }}>
            {topCo && <RootBreadcrumb value={{ idType: IdType.ENTITY, entity: topCo }} />}
            <Breadcrumb to={topCoRoute}>{role?.topCo?.name}</Breadcrumb>
            <Breadcrumb>Roles</Breadcrumb>
            <Breadcrumb>{role?.name}</Breadcrumb>
        </Breadcrumbs>
    );
};

export const ViewRoleHeading: React.FC<IViewRoleHeadingProps> = ({ role, config }) => {
    return (
        <PageHeading>
            <AppTitle value={`${role?.name} - Role KYC`} />
            <PageTitle
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        {role?.name}
                        {role?.isAdmin && <SystemAdminShield />}
                    </Box>
                }
            />
            <RoleBreadcrumbs role={role} config={config} />
            {role?.deleted && (
                <Alert severity="warning" sx={{ marginTop: '8px' }}>
                    This role has been removed from the system and cannot be used
                </Alert>
            )}
        </PageHeading>
    );
};
