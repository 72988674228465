import React from 'react';
import { DocPurpose, DocumentInInput, IdType, KYCDocType, KYCDocumentFragment } from '../../../../interfaces/model';
import { IDocumentUploadDialogConfig, useDocumentUploadDialog } from './DocumentUploadDialog';
import { useNonApplicableDocumentDialog } from './CreateNonApplicableDocumentDialog';
import { ISplitButtonOption, SplitButton } from '../../../../lib/buttons/SplitButton';
import { IDocumentCategoryListRowItem } from '../DocumentsList/DocumentCategoryList/DocumentCategoryListRow';
import { DocumentCategoryList } from '../DocumentsList/DocumentCategoryList';
import { IListColumn } from '../../../../lib/dataDisplay/List';

const COLUMNS: IListColumn<IDocumentCategoryListRowItem>[] = [
    { id: 'docType', label: 'Document' },
    { id: 'createdAt', label: 'Uploaded' },
    { id: 'userName', label: 'User' },
    { id: 'actions', label: 'Actions', shrink: true, sort: false },
];

export const SingleDocumentList: React.FC<{
    docType: KYCDocType;
    parent:
        | {
              id: UUID | undefined;
              topCoRef: UUID | undefined;
              name: string | undefined;
              shortCode: string | undefined;
              docTypes: KYCDocType[];
          }
        | undefined;
    value: KYCDocumentFragment | undefined;
    onSubmit?: (document: DocumentInInput) => Promise<void>;
    config?: IDocumentUploadDialogConfig;
    updateData?: () => Promise<void>;
    disabled?: boolean;
    listEmbedded?: boolean;
}> = ({ docType, value, parent, updateData, onSubmit, disabled, listEmbedded }) => {
    const [nonApplicableDocumentDialog] = useNonApplicableDocumentDialog();
    const [documentUploadDialog] = useDocumentUploadDialog();
    const parentRef = parent?.id;

    if (value && parent) {
        return (
            <DocumentCategoryList
                data={[value]}
                columns={COLUMNS}
                parent={{
                    ...parent,
                    type: IdType.ENTITY,
                    hasDocumentEditAccess: true,
                    hasDocumentApproveAccess: false,
                    update: updateData || (async () => void 0),
                }}
                onEditSubmit={onSubmit}
                embedded={listEmbedded}
            />
        );
    }

    const options: ISplitButtonOption[] = [
        {
            id: 'add',
            name: 'Add',
            testId: 'DocumentUploadButton:add',
            primary: true,
            onClick: () => {
                documentUploadDialog.open({
                    value: { docPurpose: DocPurpose.KYC, docType },
                    parentName: parent?.name,
                    parentRef,
                    onSubmit,
                });
            },
        },
        {
            id: 'markAsNotApplicable',
            name: 'Not applicable',
            onClick: () => {
                nonApplicableDocumentDialog.open({
                    docType,
                    parentRef,
                    onSubmit,
                });
            },
        },
    ];

    return <SplitButton options={options} disabled={disabled} />;
};
