import cl from 'classnames';
import isNil from 'lodash/isNil';
import * as Apollo from '@apollo/client';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { QueryResult } from '@apollo/client/react/types/types';
import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { NoDataPlaceholderRow } from './components/NoDataPlaceholderRow';
import { SortableCell, SortOrder } from './components/SortableCell';
import { LoadingOverlayRow } from './components/LoadingOverlayRow';
import { Direction, PagingInput } from '../../../interfaces/model';
import { EmbeddedTableTheme } from './themes/EmbeddedTableTheme';
import { DataErrorPlaceholder } from '../DataErrorPlaceholder';
import { ListRowSpacer } from './components/ListRowSpacer';
import { IGqlListColumn, IGqlListRow } from './interfaces';
import { OperationVariables } from '@apollo/client/core';
import styles from './List.module.css';
import { ParentContext } from '../../cdk/ParentContext';
import { CompactTableTheme } from './themes/CompactTableTheme';
import { NonFalsy } from '../../utils/helpers';
import { EmptyStatePlaceholder, IEmptyStatePlaceholderProps } from '../../../App/common/EmptyStatePlaceholder';

type IQuery<TData, TVariables> = (
    baseOptions: Apollo.QueryHookOptions<TData, TVariables>
) => QueryResult<TData, TVariables>;

type IResult<TData, TItem> = (data?: TData) => { data: TItem[] | undefined; total: number | undefined } | undefined;

interface IGqlQueryListProps<
    TItem extends { id: string },
    TData,
    TVariables extends OperationVariables = OperationVariables,
    TProps extends object = object
> {
    columns: IGqlListColumn<TItem>[];

    query: IQuery<TData, TVariables>;
    variables: Partial<TVariables>;
    skipFetch?: boolean;

    getResult: IResult<TData, TItem>;
    defaultSortBy: keyof TItem;
    defaultSortDirection?: Direction;
    rowComponent: React.FC<IGqlListRow<TItem, TProps>>;
    rowComponentProps?: TProps;
    RowSpacerProps?: { height?: string };
    footer?: React.ReactNode;
    highlightText?: string;
    className?: string;
    stickyHeader?: boolean;
    pagination?: boolean;
    embedded?: boolean;
    compact?: boolean;
    size?: number;
    listSizes?: number[];
    onCompleted?: (data: TData) => void;
    children?: ReactNode | undefined;
    noDataText?: string;
    emptyStatePlaceholderProps?: IEmptyStatePlaceholderProps;
    hideHeader?: boolean;
    testId?: string;
    maxHeight?: string;
    fetchPolicy?: Apollo.WatchQueryFetchPolicy;
}

const DEFAULT_LIST_SIZE = 10;
const DEFAULT_LIST_SIZES = [5, 10, 20, 50, 100];

export function prepareGQLListData<T, TItem>(
    data: { data: T[]; total: number } | undefined,
    itemMapper: (item: T) => TItem | TItem[] | null
) {
    return {
        data: data?.data.flatMap(itemMapper).filter(NonFalsy),
        total: data?.total,
    };
}

export function GqlQueryList<
    TProps extends object,
    TItem extends { id: string },
    TData = any,
    TVariables extends OperationVariables = OperationVariables
>({
    columns,
    query,
    variables,
    fetchPolicy = 'cache-and-network',
    skipFetch,
    getResult,
    defaultSortBy,
    defaultSortDirection = Direction.ASC,
    rowComponent: Row,
    rowComponentProps = {} as TProps,
    RowSpacerProps,
    footer,
    highlightText,
    className,
    onCompleted,
    stickyHeader,
    embedded,
    compact,
    size = DEFAULT_LIST_SIZE,
    listSizes = DEFAULT_LIST_SIZES,
    pagination = true,
    noDataText,
    emptyStatePlaceholderProps,
    maxHeight,
    hideHeader,
    children,
    testId,
}: IGqlQueryListProps<TItem, TData, TVariables, TProps>) {
    const [page, setPage] = useState<PagingInput>({ page: 0, size });
    const [sortDirection, setSortDirection] = useState<Direction>(defaultSortDirection);
    const [sortBy, setSortBy] = useState<keyof TItem>(defaultSortBy);

    const { data, loading, error, refetch } = query({
        skip: skipFetch,
        fetchPolicy,
        onCompleted,
        variables: {
            ...(variables as TVariables),
            page,
            sort: { property: sortBy, direction: sortDirection },
        },
    });

    const filterJsonString = JSON.stringify(variables?.filter);
    useEffect(() => {
        setPage({ page: 0, size });
    }, [filterJsonString, size]);

    const tableRef = useRef(null);
    const result = getResult(data);
    const totalCount = result?.total;
    const items = result?.data || [];
    const EmbeddedTheme = embedded ? EmbeddedTableTheme : React.Fragment;
    const CompactTheme = compact ? CompactTableTheme : React.Fragment;

    const handleSort = (sortByProperty: keyof TItem) => {
        if (sortBy !== sortByProperty) {
            setSortBy(sortByProperty);
            setSortDirection(defaultSortDirection);
        } else {
            setSortDirection(sortDirection === Direction.ASC ? Direction.DESC : Direction.ASC);
        }
    };

    const parentProviderValue = useMemo(
        () => ({
            update: async () => {
                await refetch();
            },
        }),
        [refetch]
    );

    const showEmptyStatePlaceholder = !loading && !error && (result?.total ? result.total <= 0 : true);

    if (showEmptyStatePlaceholder) {
        return (
            <ParentContext.Provider value={parentProviderValue}>
                <EmptyStatePlaceholder elevation={0} {...emptyStatePlaceholderProps} />
                {children}
            </ParentContext.Provider>
        );
    }

    return (
        <ParentContext.Provider value={parentProviderValue}>
            <div>
                <CompactTheme>
                    <EmbeddedTheme>
                        <TableContainer
                            className={cl(styles.wrap, className, loading && styles.wrapMinHeight)}
                            sx={{ maxHeight }}
                            data-testid={testId}
                        >
                            <Table
                                ref={tableRef}
                                className={cl('position-relative', items.length === 0 && styles.gqlListTable)}
                                stickyHeader={stickyHeader}
                            >
                                {!hideHeader && (
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(
                                                ({
                                                    id,
                                                    label = '',
                                                    align,
                                                    sortBy: sortByProperty,
                                                    sort,
                                                    shrink,
                                                    nowrap,
                                                    headerClassName,
                                                }) => {
                                                    if (sort === false || !label || id === 'actions') {
                                                        return (
                                                            <TableCell
                                                                key={String(id)}
                                                                align={align}
                                                                className={cl(
                                                                    shrink && styles.shrinkWidth,
                                                                    nowrap && 'white-space-nowrap',
                                                                    headerClassName
                                                                )}
                                                            >
                                                                {label}
                                                            </TableCell>
                                                        );
                                                    }
                                                    const sortByField = sortByProperty || id;

                                                    return (
                                                        <SortableCell
                                                            key={String(id)}
                                                            order={sortDirection.toLowerCase() as SortOrder}
                                                            align={align}
                                                            className={cl(
                                                                shrink && styles.shrinkWidth,
                                                                nowrap && 'white-space-nowrap',
                                                                headerClassName
                                                            )}
                                                            isActive={sortBy === sortByField}
                                                            onSort={() => handleSort(sortByField)}
                                                        >
                                                            {label}
                                                        </SortableCell>
                                                    );
                                                }
                                            )}
                                        </TableRow>
                                    </TableHead>
                                )}
                                <TableBody>
                                    {!error &&
                                        items.map((item, index) => (
                                            <React.Fragment key={index}>
                                                {item && (
                                                    <Row
                                                        item={item}
                                                        highlightText={highlightText}
                                                        props={rowComponentProps}
                                                        columns={columns}
                                                        updateData={parentProviderValue.update}
                                                        isLast={index === items.length - 1}
                                                        isFirst={index === 0}
                                                        index={index}
                                                    />
                                                )}
                                                {!item && (
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <TableCell key={column.id}>
                                                                <Skeleton
                                                                    variant="text"
                                                                    animation="wave"
                                                                    width="100%"
                                                                />
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                )}
                                                <ListRowSpacer colspan={columns.length} {...RowSpacerProps} />
                                            </React.Fragment>
                                        ))}
                                    {((!loading && totalCount === 0) || error) && (
                                        <NoDataPlaceholderRow colspan={columns.length} text={noDataText}>
                                            {error && (
                                                <DataErrorPlaceholder
                                                    onRetry={parentProviderValue.update}
                                                    error={error}
                                                />
                                            )}
                                        </NoDataPlaceholderRow>
                                    )}
                                    {loading && <LoadingOverlayRow tableRef={tableRef} />}
                                </TableBody>
                                {footer}
                            </Table>
                        </TableContainer>
                        {!isNil(totalCount) && pagination && totalCount >= Math.min(...listSizes) && (
                            <TablePagination
                                component="div"
                                count={totalCount}
                                rowsPerPage={page.size}
                                rowsPerPageOptions={listSizes}
                                page={page.page}
                                onPageChange={(e, newPage) => setPage({ page: newPage, size: page.size })}
                                onRowsPerPageChange={(e) =>
                                    setPage({
                                        page: 0,
                                        size: (e.target.value as unknown as number) || DEFAULT_LIST_SIZE,
                                    })
                                }
                            />
                        )}
                    </EmbeddedTheme>
                </CompactTheme>
            </div>
            {children}
        </ParentContext.Provider>
    );
}
