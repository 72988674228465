import React from 'react';
import cl from 'classnames';
import isNil from 'lodash/isNil';
import Clear from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { NOOP } from '../../utils/helpers';
import styles from './SearchComponent.module.css';
import { Fade } from '@mui/material';

interface ISearchComponentProps extends Omit<StandardTextFieldProps, 'onChange'> {
    containerClass?: string;
    onChange: (value: string) => void;
    testId?: string;
}

export const SearchComponent: React.FC<ISearchComponentProps> = ({
    className,
    onChange = NOOP,
    value = '',
    testId = '',
    placeholder = 'Search',
    ...props
}) => {
    return (
        <TextField
            variant="outlined"
            className={cl(className, styles.textField)}
            data-testid={testId}
            placeholder={placeholder}
            onChange={(e) => {
                const newQuery = e.target.value;
                onChange(newQuery);
            }}
            InputProps={{
                startAdornment: <SearchIcon className={styles.searchIcon} />,
                endAdornment: (
                    <InputAdornment position="end">
                        <Fade in={!isNil(value) && value !== ''}>
                            <IconButton
                                edge="end"
                                size={'small'}
                                className={styles.clearButton}
                                onClick={() => onChange('')}
                            >
                                <Clear fontSize={'inherit'} />
                            </IconButton>
                        </Fade>
                    </InputAdornment>
                ),
            }}
            value={value}
            size={'small'}
            autoFocus
            {...props}
        />
    );
};
