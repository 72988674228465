import React, { useState } from 'react';
import Edit from '@mui/icons-material/Edit';
import { ActionsCell, ValueCell } from 'lib/dataDisplay/List/components/ListCells';
import { ListIconButton } from 'lib/dataDisplay/List/components/ListIconButton';
import { BankAccountDeleteIconButton } from './BankAccountDeleteIconButton';
import { bankAccountPurposeLabels } from '../BankAccountEditDialog.const';
import { useBankAccountEditDialog } from '../BankAccountEditDialog';
import { ListRow } from 'lib/dataDisplay/List/components/ListRow';
import { BankAccountFieldsView } from '../BankAccountFieldsView';
import { CollapseTrigger } from 'lib/buttons/CollapseTrigger';
import { CollapsibleRow } from 'lib/legacy/CollapsibleRow';
import { BankAccountInput } from 'interfaces/model';
import { IListRow } from 'lib/dataDisplay/List';

export interface IBankAccountsListItem extends BankAccountInput {
    actions?: never;
}

type IBankAccountsListRowProps = IListRow<
    IBankAccountsListItem,
    {
        onDeleteConfirm: (bankAccountId: UUID) => Promise<void>;
        updateData: () => Promise<void>;
        hasEditAccess: boolean;
        hasDeleteAccess: boolean;
    }
>;

export const BankAccountsListRow: React.FC<IBankAccountsListRowProps> = ({ data, columns, props }) => {
    const [isExpanded, setExpanded] = useState(false);
    const [bankAccountEditDialog] = useBankAccountEditDialog();

    return (
        <>
            <ListRow focused={isExpanded}>
                {columns.map(({ id }) => (
                    <React.Fragment key={id}>
                        {id === 'accountName' && <ValueCell>{data.accountName}</ValueCell>}
                        {id === 'purpose' && (
                            <ValueCell>{data.purpose && bankAccountPurposeLabels[data.purpose]}</ValueCell>
                        )}
                        {id === 'currency' && <ValueCell>{data.currency}</ValueCell>}
                        {id === 'actions' && (
                            <ActionsCell align={'right'}>
                                {props.hasEditAccess && (
                                    <ListIconButton
                                        icon={Edit}
                                        onClick={() => bankAccountEditDialog.open({ bankAccount: data })}
                                    />
                                )}
                                {props.hasDeleteAccess && (
                                    <BankAccountDeleteIconButton
                                        bankAccount={data}
                                        onDeleteConfirm={props.onDeleteConfirm}
                                        onDeleteSuccess={props.updateData}
                                    />
                                )}
                                <CollapseTrigger isActive={isExpanded} onClick={() => setExpanded(!isExpanded)} />
                            </ActionsCell>
                        )}
                    </React.Fragment>
                ))}
            </ListRow>
            <CollapsibleRow expand={isExpanded} colspan={columns.length} renderCollapsed={false}>
                <BankAccountFieldsView
                    accountFields={data.accountFields || []}
                    intermediaryFields={data.intermediaryFields || []}
                    correspondingFields={data.correspondingFields || []}
                />
            </CollapsibleRow>
        </>
    );
};
