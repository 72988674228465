import React from 'react';
import styles from './NoValuePlaceholder.module.css';
import { NO_VALUE_PLACEHOLDER } from '../lib.const';
import { Tooltip } from '@mui/material';

export interface INoValuePlaceholderProps {
    text?: React.ReactNode;
    title?: string;
    notApplicable?: boolean;
}

export const NoValuePlaceholder: React.FC<INoValuePlaceholderProps> = ({ text, title = '', notApplicable }) => {
    const getText = () => {
        return notApplicable ? 'N/A' : text || NO_VALUE_PLACEHOLDER;
    };
    if (title) {
        return (
            <Tooltip arrow title={title}>
                <span>{getText()}</span>
            </Tooltip>
        );
    }
    return (
        <span className={styles.placeholder} title={title}>
            {getText()}
        </span>
    );
};
