import React from 'react';
import { Paper } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { List } from '../../../../../../lib/dataDisplay/List/List';
import { DirectorsListRow, IDirectorsListRowItem } from './DirectorsListRow';
import { DirectorRoleFragment } from '../../../../../../interfaces/model';
import { IListColumn } from '../../../../../../lib/dataDisplay/List';
import { sortCompare } from '../../../../../../lib/utils/helpers';
import { DirectorDetailsDialog } from '../../DirectorDetailsDialog';

interface IDirectorSelectListProps {
    data: DirectorRoleFragment[];
    error: ApolloError | undefined;
    onSelect?: (roleId: UUID) => void;
    onRemove?: (roleId: UUID) => void;
    updateData: () => Promise<void>;
    loading: boolean;
}

const COLUMNS: IListColumn<IDirectorsListRowItem>[] = [
    { id: 'name', label: 'Name' },
    { id: 'actions', label: 'Actions', shrink: true, sort: false },
];

const prepareData = (data: DirectorRoleFragment[]): IDirectorsListRowItem[] => {
    return data.sort((a, b) => sortCompare(a.name || '', b.name || ''));
};

export const DirectorsList: React.FC<IDirectorSelectListProps> = ({
    error,
    loading,
    data,
    updateData,
    onSelect,
    onRemove,
}) => {
    return (
        <Paper
            elevation={0}
            sx={{ background: 'var(--bg-default)', padding: '24px' }}
            onClick={(e) => e.stopPropagation()}
        >
            <List
                columns={COLUMNS}
                data={prepareData(data)}
                rowComponent={DirectorsListRow}
                rowComponentProps={{ onSelect, editable: !onSelect, onRemove, updateData }}
                pagination={false}
                loading={loading}
                error={error}
            />
            <DirectorDetailsDialog />
        </Paper>
    );
};
