import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const containerRef = React.createRef<HTMLDivElement>();

export const ClientOnboardingNavigationPortalContainer: React.FC = () => {
    return <div ref={containerRef} />;
};

export const ClientOnboardingNavigationPortal: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const container = ReactDOM.findDOMNode(containerRef.current) as Element;

    /**
     * Sometimes containerRef may be null. To fix that, we use useLayoutEffect,
     * which forces Portal to re-render with existing containerRef
     */
    const [containerExists, setContainerExists] = useState(false);
    useLayoutEffect(() => {
        if (!containerExists) {
            setContainerExists(true);
        }
    }, [container, containerExists]);

    return container && ReactDOM.createPortal(children, container);
};
