import * as yup from 'yup';
import { DocPurpose, KYCDocCategory, KYCDocSubcategory, KYCDocType } from 'interfaces/types';
import { useUrlState } from 'lib/hooks/router/useUrlState';
import { yupEnum } from 'lib/forms';

export const useDocumentsUrlState = () => {
    return useUrlState({
        validationSchema: {
            id: yup.string(),
            year: yup.string(),
            category: yupEnum(KYCDocCategory),
            docType: yupEnum(KYCDocType),
            docSubcategory: yupEnum(KYCDocSubcategory),
            purpose: yupEnum(DocPurpose),
            deleted: yup.boolean(),
            upload: yup.boolean(),
        },
    });
};

export type IDocumentsUrlState = ReturnType<typeof useDocumentsUrlState>[0];
