import { useContext } from 'react';
import isString from 'lodash/isString';
import { FieldHelperProps, FieldMetaProps, FormikContext, FormikContextType } from 'formik';

export const useFormikField = <T, TFormik = Record<string, unknown>>(name: string | undefined) => {
    const formik = useContext<FormikContextType<TFormik>>(FormikContext);
    return {
        field: isString(name) ? formik?.getFieldProps<T>(name) : void 0,
        meta: isString(name) ? formik?.getFieldMeta<T>(name) : ({} as FieldMetaProps<T>),
        helpers: isString(name) ? formik?.getFieldHelpers<T>(name) : ({} as FieldHelperProps<T>),
        formik,
    };
};
