import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import { ApolloError } from '@apollo/client';
import isPlainObject from 'lodash/isPlainObject';
import { ValidationAlertFragment } from '../../App/common/Validation/ValidationAlert.fragment';

export const NonFalsy = <T>(n?: T | null | false | 0 | 0n | '' | typeof NaN): n is T => Boolean(n);

/**
 * @deprecated
 */
export const nonNull = <T>(value: T | undefined | null) => value ?? void 0;

export const fromEntries = <TKey extends string, T extends unknown>(iterable: [TKey, T][]) => {
    return [...iterable].reduce((acc, [key, val]) => {
        acc[key] = val;
        return acc;
    }, {} as Record<TKey, T>);
};

export const NOOP = () => {};
export const NOOP_ASYNC = async () => {};
export const NIL_UUID = '00000000-0000-0000-0000-000000000000';
export const isDevMode = () => process.env.NODE_ENV === 'development';
export const voidWrapper = (fn: Function) => async () => {
    await fn();
};

export const sortCompare = (a: string = '', b: string = '') => {
    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    return 0;
};

export const stringIncludesCaseInsensitive = (a: string, b: string) =>
    String(a).trim().toLowerCase().includes(String(b).trim().toLowerCase());

export const getTraceIdFromApolloError = (error: ApolloError): string | undefined =>
    error?.graphQLErrors?.[0]?.extensions?.traceId as string;

export const getTraceIdFromResponse = (response: Response): string | undefined =>
    response.headers.get('uber-trace-id')?.split(':')[0];

export const ERROR_TRACE_UI_URL_FACTORY = (traceId: string) => {
    const traceUrl = process.env.REACT_APP_TRACE_URL;
    return traceUrl && `${traceUrl}/${traceId}`;
};

export const withoutGqlFields = <T extends object>(value: T | undefined) => {
    const omitTypeName = (item: T) => omit(item, '__typename') as T;
    if (Array.isArray(value)) {
        return value?.map(omitTypeName) as T;
    }
    return value && omitTypeName(value);
};

export const fileToBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
        const trimBase64Prefix = (data: string) => data.replace(/^.*base64,/, '');
        const reader = new FileReader();
        reader.onload = () => resolve(trimBase64Prefix(reader.result as string));
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });

export const flattenObject = <T extends object>(obj: T) => {
    const result: Record<string, unknown> = {};
    const flatten = (collection: Object, prefix = '', suffix = '') => {
        forEach(collection, (value, key) => {
            const path = `${prefix}${key}${suffix}`;

            if (isArray(value)) {
                flatten(value, `${path}[`, ']');
            } else if (isPlainObject(value)) {
                flatten(value, `${path}.`);
            } else {
                result[path] = value as unknown;
            }
        });
    };
    flatten(obj);
    return result;
};

export const toggleValueInArray = <T>(values: T[], value: T) => {
    if (values.includes(value)) {
        return values.filter((item) => item !== value);
    } else {
        return [...values, value];
    }
};

export const getMessageFromApolloError = (error: unknown) => {
    const validationError =
        error instanceof ApolloError
            ? error.graphQLErrors.find((item) => {
                  return item.extensions?.type === 'ValidationError';
              })
            : null;
    const validationFragments = validationError?.extensions?.errors as ValidationAlertFragment[];
    return validationFragments?.[0];
};
