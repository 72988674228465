import Stack from '@mui/material/Stack';
import React, { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { Box, Container, Dialog, DialogTitle, Tooltip } from '@mui/material';
import { AuthenticatorsList } from 'App/components/Auth/Login/LoginForm/AuthenticatorsList';
import { TOTP_RESPONSE_CODE_LENGTH } from 'App/components/Auth/Login/Login.const';
import { useTotpCodeEnable } from 'App/components/Auth/useTotpCodeEnable';
import { LoadingContentWrap } from 'lib/dataDisplay/LoadingContentWrap';
import { TotpQRCodeImage } from 'App/components/Auth/TotpQRCodeImage';
import { SeparateSymbolInput } from 'lib/inputs/SeparateSymbolInput';
import { DialogSubmitActions } from 'lib/modals/DialogSubmitActions';
import { makeDialogState } from 'store/dialogState/makeDialogState';
import { DialogCloseButton } from 'lib/modals/DialogCloseButton';
import styles from './TOTPDialog.module.css';
import Link from '@mui/material/Link';

interface ITOTPDialogProps {
    onConfirm: () => void;
}

export const useTOTPDialog = makeDialogState();

export const TOTPDialog: React.FC<ITOTPDialogProps> = ({ onConfirm }) => {
    const [{ close }, isOpened] = useTOTPDialog();
    const [codeValue, setCodeValue] = useState<string>();
    const { submitCode, codeInvalid, setCodeInvalid, loading, error, reset } = useTotpCodeEnable({
        onConfirm: async () => {
            setCodeValue(void 0);
            onConfirm();
            close();
        },
    });

    const clearAndClose = () => {
        reset();
        setCodeValue(void 0);
        close();
    };

    return (
        <Dialog
            open={isOpened}
            onClose={clearAndClose}
            PaperProps={{ sx: { maxWidth: '724px' } }}
            data-testid={'TOTPDialog'}
            fullWidth
        >
            <DialogTitle>
                Set up two-factor authentication
                <DialogCloseButton onClick={clearAndClose} />
            </DialogTitle>
            <DialogContent>
                <Container maxWidth={'md'}>
                    <LoadingContentWrap loading={loading} error={error}>
                        <Stack spacing={2}>
                            <p>
                                To protect your data and security we use 2 factor authentication (2FA) to access to our
                                platform. This means that we now require you to setup 2FA using a 3rd party
                                authentication. This is a one-time process.{' '}
                                <Tooltip
                                    title={
                                        'Two-factor authentication bolsters the security of your account by demanding' +
                                        ' more than merely a password for access. This method safeguards your account' +
                                        ' against hacking attempts, as any potential intruders would need not only ' +
                                        'your login information but also physical access to your smartphone.'
                                    }
                                    arrow
                                >
                                    <Link underline={'none'} className={'input-label'}>
                                        Why do I need this?
                                    </Link>
                                </Tooltip>
                            </p>
                            <Box>
                                <h6>Please follow these steps:</h6>
                                <Box className={styles.qrCodeWRap}>
                                    <TotpQRCodeImage className={styles.qrCodeImg} skipLoad={!isOpened} />
                                    <p className={styles.note}>
                                        * Scan QR code using authenticator app not phone camera
                                    </p>
                                </Box>
                                <ol className={styles.list}>
                                    <li>
                                        Download any authenticator app to your smartphone. This may be one of:{' '}
                                        {<AuthenticatorsList />}
                                    </li>
                                    <li>
                                        Scan the QR code using the authenticator app installed on your smartphone. The
                                        app will now generate a unique 6-digit code which we will use to log in.
                                    </li>
                                    <li>
                                        <p>
                                            Input the 6-digit code displayed on your screen into the input below. From
                                            now on, you'll be asked to input a code each time you log in.
                                        </p>

                                        <Stack spacing={1} alignItems={'flex-start'}>
                                            <SeparateSymbolInput
                                                size={'medium'}
                                                value={codeValue}
                                                length={TOTP_RESPONSE_CODE_LENGTH}
                                                onSubmit={submitCode}
                                                onChange={(code) => {
                                                    setCodeValue(code);
                                                    setCodeInvalid(false);
                                                }}
                                                onKeyDown={async (e) => {
                                                    if (e.code === 'Enter') {
                                                        await submitCode(codeValue);
                                                    }
                                                }}
                                                error={codeInvalid}
                                                autoSubmit
                                            />
                                        </Stack>
                                    </li>
                                </ol>
                            </Box>
                        </Stack>
                    </LoadingContentWrap>
                </Container>
            </DialogContent>
            <DialogSubmitActions
                onSubmit={() => submitCode(codeValue)}
                onClose={clearAndClose}
                spin={loading}
                canSubmit
            />
        </Dialog>
    );
};
