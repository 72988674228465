import React from 'react';
import { v4 as uuid } from 'uuid';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router';
import Button from '@mui/material/Button';
import { ClientOnboardingNextStepButton } from '../../ClientOnboardingFrame/ClientOnboardingNextStepButton';
import { FeedbackMessageDialog, useFeedbackMessageDialog } from 'lib/modals/FeedbackMessageDialog';
import { CLIENT_ONBOARDING_ROUTES } from 'App/App.routes';

interface IFundsSetupNextStepButtonProps {
    stepIndex: number;
    disabled: boolean;
    legalEntities: { loanFunds: Maybe<object[]> }[];
    onClick?: (e: React.MouseEvent) => void;
    onGoToLegalEntitiesClick?: () => void;
    finalStep?: boolean;
}

const FEEDBACK_DIALOG_ID = uuid();

export const FundsSetupNextStepButton: React.FC<IFundsSetupNextStepButtonProps> = ({
    stepIndex,
    disabled,
    legalEntities,
    onClick,
    finalStep,
    onGoToLegalEntitiesClick,
}) => {
    const history = useHistory();
    const [feedbackDialog] = useFeedbackMessageDialog({ id: FEEDBACK_DIALOG_ID });

    return (
        <>
            <ClientOnboardingNextStepButton
                stepIndex={stepIndex}
                disabled={disabled}
                onClick={async (e) => {
                    const nonAttachedIms = legalEntities.filter(
                        ({ loanFunds }) => !loanFunds || loanFunds.length === 0
                    );
                    if (nonAttachedIms.length > 0) {
                        feedbackDialog.open({
                            severity: 'error',
                            title: 'Non-linked investment managers',
                            message: (
                                <Stack spacing={4} alignItems={'center'}>
                                    <span>
                                        You have at least one investment manager with no funds linked to it. Please add
                                        all the funds managed by these entities here.
                                    </span>
                                    <Button
                                        size={'large'}
                                        variant={'contained'}
                                        onClick={() => {
                                            feedbackDialog.close();
                                            if (onGoToLegalEntitiesClick) {
                                                onGoToLegalEntitiesClick();
                                            } else {
                                                history.push(CLIENT_ONBOARDING_ROUTES.LEGAL_ENTITIES);
                                            }
                                        }}
                                    >
                                        Go to legal entities
                                    </Button>
                                </Stack>
                            ),
                        });
                        return false;
                    }
                    if (onClick) {
                        onClick(e);
                    }
                    return true;
                }}
                finalStep={finalStep}
            />
            <FeedbackMessageDialog id={FEEDBACK_DIALOG_ID} />
        </>
    );
};
