import { useSelector } from 'react-redux';
import {
    API_ROOT_SELECTOR,
    SECURE_PASSWORD_SELECTOR,
    UPLOAD_FILE_EXTENSIONS_SELECTOR,
    VERSION_SELECTOR,
    FEATURES_SELECTOR,
    OPENREPLAY_SELECTOR,
    WORDPRESS_SELECTOR,
} from '../App.selectors';
import { AppVersion, Features, Openreplay, Wordpress } from '../../interfaces/model';

export const useAppConfig = () => {
    const apiRoot = useSelector(API_ROOT_SELECTOR);
    const securePasswordRequired = useSelector(SECURE_PASSWORD_SELECTOR);
    const fileExtensionsSelector = useSelector(UPLOAD_FILE_EXTENSIONS_SELECTOR);
    const versionSelector = useSelector(VERSION_SELECTOR);
    const featuresSelector = useSelector(FEATURES_SELECTOR);
    const openReplaySelector = useSelector(OPENREPLAY_SELECTOR);
    const wordpressSelector = useSelector(WORDPRESS_SELECTOR);
    return {
        securePasswordRequired: Boolean(securePasswordRequired),
        uploadFileExtensions: fileExtensionsSelector as string[],
        apiRoot,
        version: versionSelector as AppVersion,
        features: featuresSelector as Features,
        openReplay: openReplaySelector as Openreplay,
        wordpress: wordpressSelector as Wordpress,
    };
};
