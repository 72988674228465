import * as yup from 'yup';
import { VALIDATION_MESSAGES } from 'lib/forms';
import { ErrorCode } from '../../../../interfaces/model';
import { GraphQLError } from 'graphql/error';
import { ApolloError } from '@apollo/client';

export const LOGIN_NOT_FOUND = 'Login is not found';
export const PASSWORD_INCORRECT = 'Password is incorrect';
export const TOTP_CODE_INCORRECT = 'Code is incorrect';

export enum LoginFormMode {
    LOGIN_PASSWORD = 'LOGIN_PASSWORD',
    TWO_FACTOR_CODE = 'TWO_FACTOR_CODE',
}

export const TOTP_RESPONSE_CODE_LENGTH = 6;

export const loginFormValidationSchema = yup.object({
    login: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    password: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    mode: yup.mixed<LoginFormMode>(),
    totpCode: yup.string().when('mode', {
        is: LoginFormMode.TWO_FACTOR_CODE,
        then: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    }),
});

export type ILoginForm = yup.TypeOf<typeof loginFormValidationSchema>;

export const findErrorOfType = (error: unknown, code: ErrorCode): GraphQLError | undefined => {
    if (error instanceof ApolloError) {
        return error.graphQLErrors.find(({ extensions }) => extensions?.code === code);
    }
};
