import isNil from 'lodash/isNil';
import { v4 as uuid } from 'uuid';
import { FormikContext } from 'formik';
import Stack from '@mui/material/Stack';
import React, { forwardRef, useContext } from 'react';
import { Box, Checkbox as MuiCheckbox, CheckboxProps, FormHelperText, FormLabel, Grid } from '@mui/material';
import { ValidationMessage } from '../ValidationMessage';

interface IFormCheckboxProps extends CheckboxProps {
    name?: string;
    label?: React.ReactNode;
    onCheck?: (checked: boolean) => void;
    helperText?: React.ReactNode;
    xs?: number;
}

export const FormCheckbox = forwardRef<HTMLDivElement, IFormCheckboxProps>(
    ({ id, name, label, helperText, checked: valueProp, disabled, xs = 12, onCheck, ...props }, ref) => {
        const formik = useContext(FormikContext);
        const formField = name ? formik?.getFieldProps(name) : void 0;
        const formFieldMeta = name ? formik?.getFieldMeta(name || '') : void 0;
        const error = formFieldMeta?.error;
        const touched = formFieldMeta?.touched;
        const checked = !isNil(valueProp) ? valueProp : formField?.value;
        const checkBoxId = id || name || uuid();
        const handleClick = () => {
            if (onCheck) {
                onCheck(!checked);
            } else if (formik && name) {
                formik.setFieldValue(name, !checked);
            }
        };
        const showError = name && error && touched;

        return (
            <Grid ref={ref} xs={xs} item>
                <Box display={'flex'} alignItems={'flex-start'}>
                    <MuiCheckbox
                        {...props}
                        id={checkBoxId}
                        checked={!!checked}
                        onClick={handleClick}
                        disabled={disabled || formik.isSubmitting}
                        sx={{ margin: '-12px 0 0 -9px' }}
                        className={showError ? 'error' : ''}
                    />
                    {label && (
                        <Stack spacing={0.5}>
                            <FormLabel required={props.required} htmlFor={checkBoxId} className={'nomargin'}>
                                {label}
                            </FormLabel>
                            {helperText && <FormHelperText>{helperText}</FormHelperText>}
                        </Stack>
                    )}
                </Box>
                {showError && <ValidationMessage field={name} />}
            </Grid>
        );
    }
);
