import React from 'react';
import { DateTime } from 'luxon';
import { Alert, Box, Button } from '@mui/material';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import { Capabilities, RoleFragment, useSetRoleMutation } from '../../../../../interfaces/model';
import { useAppSnackbar } from '../../../../components/AppNotification';
import { DATE_HOUR_VIEW_FORMAT } from '../../../../../lib/utils/date';
import { useUserDetails } from '../../../../hooks/useUserDetails';
import { RowStack } from '../../../../../lib/cdk/RowStack';
import { useCapability } from '../../../../hooks/useCapability';
import { VerifyEmailButton } from './VerifyEmailButton';

interface IRoleEmailProps {
    role: RoleFragment | undefined;
    updateData: () => void;
}

const formatDate = (date: Maybe<LocalDate>): string => {
    return date ? DateTime.fromJSDate(new Date(date)).toFormat(DATE_HOUR_VIEW_FORMAT) : '';
};

const allowNewVerificationRequest = (codeExpiresAt: string | undefined | null): boolean => {
    if (!codeExpiresAt) {
        return true;
    }
    const expirationDate = new Date(codeExpiresAt);
    const now = new Date();
    return now.getTime() > expirationDate.getTime();
};

export const RoleEmailVerificationAlert: React.FC<IRoleEmailProps> = ({ role, updateData }) => {
    const snackbar = useAppSnackbar();
    const [updateRole] = useSetRoleMutation();
    const { isAdmin, role: currentUserRole } = useUserDetails();
    const canManageStructure = useCapability(Capabilities.MANAGE_STRUCTURE);
    const isSelfEditing = currentUserRole?.id === role?.id;

    const emailVerificationAllowed =
        (isSelfEditing || isAdmin) &&
        role?.email &&
        !role?.emailVerified &&
        allowNewVerificationRequest(role?.emailVerifiedExpiredAt);

    const verificationHasBeenSent = !role?.emailVerified && role?.emailVerifiedExpiredAt && role?.emailVerifiedSendAt;
    const isTopCoAdmin = canManageStructure && role?.topCoRef && currentUserRole?.topCoRef === role.topCoRef;
    const allowVerificationSkip = role?.email && (isAdmin || isTopCoAdmin);

    const markEmailAsVerified = async () => {
        if (role) {
            try {
                await updateRole({
                    variables: {
                        role: {
                            id: role.id,
                            emailVerified: true,
                        },
                    },
                });
                await updateData();
                snackbar.show({ message: 'Email has been verified' });
            } catch (e) {
                snackbar.showError({ message: `Failed to mark email as verified for the user "${role.name}"` }, e);
            }
        }
    };

    if (!role?.email || role?.emailVerified !== false) {
        return null;
    }

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Alert
                severity="warning"
                icon={<SafetyCheckIcon sx={{ fontSize: '24px' }} color={'warning'} />}
                action={
                    <RowStack spacing={1}>
                        {allowVerificationSkip && (
                            <Button
                                size={'small'}
                                color={'secondary'}
                                variant={'outlined'}
                                onClick={markEmailAsVerified}
                            >
                                Mark as verified
                            </Button>
                        )}
                        {emailVerificationAllowed && (
                            <VerifyEmailButton updateData={updateData} roleId={role.id} roleEmail={role.email || ''} />
                        )}
                    </RowStack>
                }
            >
                {verificationHasBeenSent
                    ? `Verification e-mail has been sent at ${formatDate(role.emailVerifiedSendAt)}. ` +
                      `You can request a new code at ${formatDate(role.emailVerifiedExpiredAt)}.`
                    : 'Email is not verified'}
            </Alert>
        </Box>
    );
};
