import React, { useState } from 'react';
import { IListRow } from '../../../../../../lib/dataDisplay/List';
import { ListRow } from '../../../../../../lib/dataDisplay/List/components/ListRow';
import {
    ActionsCell,
    CheckboxCell,
    ColoredTextCell,
    ValueCell,
} from '../../../../../../lib/dataDisplay/List/components/ListCells';
import {
    Capabilities,
    EntityRelationsFragment,
    EntityType,
    TopCoRoleFragment,
} from '../../../../../../interfaces/model';
import { getEntityTitleByType } from '../../LegalEntities/LegalEntities.helpers';
import { ListCellItemWrapper } from '../../../../../../lib/dataDisplay/List/components/ListCellItemWrapper';
import { IconWithTooltip } from '../../../../../../lib/icons/IconWithTooltip';
import VerifiedIcon from '@mui/icons-material/Verified';
import { ListIconButton } from '../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { Edit } from '@mui/icons-material';
import { RoleDeleteIconButton } from '../../EntityRoles/RoleList/RoleListRow/RoleDeleteIconButton';
import { useRoleEditDialog } from '../../../../Roles/RoleEditDialog';
import { CollapsibleRow } from '../../../../../../lib/legacy/CollapsibleRow';
import { RoleEntitiesList } from './RoleEntitiesList/RoleEntitiesList';
import { CollapseTrigger } from '../../../../../../lib/buttons/CollapseTrigger';
import { IRolesFilters } from '../RolesFilterPanel';
import { ledgercommRoleLabels } from '../../../../../App.const';
import { Tooltip } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Tag } from '../../../../../../lib/dataDisplay/Tag';
import { hasDirectorRelation } from '../../../../Roles/RoleEditDialog/useRoleEditFormik/useRoleEditFormik.const';
import Box from '@mui/material/Box';
import styles from './TopCoRolesListRow.module.css';
import { useCapability } from '../../../../../hooks/useCapability';

export interface ITopCoRolesListItem extends TopCoRoleFragment {
    actions?: never;
    checkbox?: never;
    name: string;
    numberOfEnabledEntities: number;
    entityRelations: EntityRelationsFragment[];
    selected: boolean;
}

type IComponentProps = {
    topCoType: EntityType | undefined;
    reloadData: () => void;
    onRoleSelect: (id: UUID) => void;
    selectedRoles: UUID[];
    disabled: boolean;
    filters: IRolesFilters;
};

type ITopCoRolesListRowProps = IListRow<ITopCoRolesListItem, IComponentProps>;

export const TopCoRolesListRow: React.FC<ITopCoRolesListRowProps> = ({ data, columns, props }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isSelected = props.selectedRoles.includes(data.id);

    const entityTypeLabel = getEntityTitleByType(props.topCoType, { plural: true });
    const canLoggedInUserManageStructure = useCapability(Capabilities.MANAGE_STRUCTURE);
    const canViewedUserManageStructure = !!data.capabilities?.includes(Capabilities.MANAGE_STRUCTURE);
    const isViewedUserDirector = hasDirectorRelation(data.entityRelations);

    const [{ open: openRoleEditDialog }] = useRoleEditDialog();

    return (
        <>
            <ListRow focused={isExpanded || isSelected}>
                {columns.map(({ id }) => (
                    <React.Fragment key={id}>
                        {id === 'checkbox' && (
                            <CheckboxCell
                                className={styles.checkboxCell}
                                disabled={props.disabled}
                                value={data.selected}
                                onChange={() => props.onRoleSelect(data.id)}
                            />
                        )}
                        {id === 'name' && (
                            <ValueCell highlight={props.filters.name}>
                                <div>
                                    {data.name}
                                    <Box display={'flex'}>
                                        {canViewedUserManageStructure && <Tag color={'purple'}>Admin</Tag>}
                                        {isViewedUserDirector && <Tag color={'yellow'}>Director</Tag>}
                                    </Box>
                                </div>
                            </ValueCell>
                        )}
                        {id === 'numberOfEnabledEntities' && (
                            <ValueCell>
                                {data.numberOfEnabledEntities} {entityTypeLabel}
                            </ValueCell>
                        )}
                        {id === 'jobTitle' && (
                            <ValueCell width={'100px'} className={'white-space-break-spaces'}>
                                <ListCellItemWrapper>
                                    {ledgercommRoleLabels[data.jobTitle!] || data.jobTitle}
                                    {data.isAdmin && (
                                        <Tooltip title={'System Administrator'}>
                                            <AdminPanelSettingsIcon
                                                color={'primary'}
                                                fontSize={'small'}
                                                sx={{ marginLeft: '4px' }}
                                            />
                                        </Tooltip>
                                    )}
                                </ListCellItemWrapper>
                            </ValueCell>
                        )}
                        {id === 'email' && (
                            <ValueCell placeholderProps={{ text: 'Not set' }}>
                                {data.email && (
                                    <ListCellItemWrapper>
                                        {data.email}
                                        {data.emailVerified && (
                                            <IconWithTooltip
                                                text={'Verified'}
                                                Icon={VerifiedIcon}
                                                color={'success'}
                                                sx={{ marginLeft: '4px' }}
                                            />
                                        )}
                                    </ListCellItemWrapper>
                                )}
                            </ValueCell>
                        )}
                        {id === 'deactivated' && (
                            <ColoredTextCell
                                sx={{ width: '120px' }}
                                value={data.deactivated ? 'deactivated' : 'active'}
                                textMap={{ deactivated: 'Deactivated', active: 'Active' }}
                                colorsMap={{ deactivated: 'secondary', active: 'success' }}
                            />
                        )}
                        {id === 'actions' && (
                            <ActionsCell className={styles.actionsCell}>
                                {data.hasEditAccess && (
                                    <>
                                        <ListIconButton
                                            icon={Edit}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openRoleEditDialog({
                                                    roleId: data.id,
                                                    roleName: data.name || 'N/A',
                                                    topCoRef: data.topCoRef,
                                                    onSaveSuccess: () => {
                                                        if (isSelected) {
                                                            props.onRoleSelect(data.id);
                                                        }
                                                        props.reloadData();
                                                    },
                                                });
                                            }}
                                        />
                                        {canLoggedInUserManageStructure && (
                                            <RoleDeleteIconButton
                                                role={data}
                                                onSuccess={() => {
                                                    props.reloadData();
                                                    if (isSelected) {
                                                        props.onRoleSelect(data.id);
                                                    }
                                                }}
                                            />
                                        )}
                                        <CollapseTrigger
                                            isActive={isExpanded}
                                            onClick={() => setIsExpanded(!isExpanded)}
                                        />
                                    </>
                                )}
                            </ActionsCell>
                        )}
                    </React.Fragment>
                ))}
            </ListRow>
            <CollapsibleRow expand={isExpanded} colspan={columns.length} contentClassName={styles.collapsibleRow}>
                <RoleEntitiesList role={data} topCoType={props.topCoType} />
            </CollapsibleRow>
        </>
    );
};
