import React from 'react';
import { TableCell, TableCellProps } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import styles from './DocumentFileIconCell.module.css';

export const DocumentFileIconCell: React.FC<TableCellProps> = (props) => {
    return (
        <TableCell className={styles.fileIconCell} {...props}>
            <div className={styles.fileIconWrap}>
                <ArticleIcon color={'secondary'} />
            </div>
        </TableCell>
    );
};
