import { Entity, Facility, IdType, LoanFund, Role } from '../../interfaces/model';

type WithOutParent<T> = Omit<T, 'topCo' | 'entity'>;
type PartialModel<T> = Partial<WithOutParent<T>> & {
    topCo?: Maybe<Partial<WithOutParent<Entity>>>;
    entity?: Maybe<Partial<Entity>>;
};

export interface IPartialModel {
    id: UUID;
    idType: IdType;
    entity?: PartialModel<Entity> | null;
    loanFund?: PartialModel<LoanFund> | null;
    role?: PartialModel<Role> | null;
    facility?: PartialModel<Facility> | null;
}

export const getModelByIdType = <T extends IPartialModel>(value: T | undefined) => {
    if (value) {
        switch (value.idType) {
            case IdType.ENTITY:
                return value.entity;
            case IdType.FACILITY:
                return value.facility;
            case IdType.LOAN_FUND:
                return value.loanFund;
            case IdType.ROLE:
                return value.role;
        }
    }
};
