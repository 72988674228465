import React from 'react';
import { Link } from 'react-router-dom';
import { ClientOnboardingContainer } from '../../ClientOnboardingFrame/ClientOnboardingContainer';
import { BackButton } from '../../../../../../lib/navigation/BackButton';
import { PageHeadingPortal } from '../../../../../components/PageHeading';
import { CLIENT_ONBOARDING_ROUTES } from '../../../../../App.routes';
import { ViewEntity } from '../../../../Entities/ViewEntity';

export const LegalEntityPreview: React.FC = () => {
    return (
        <ClientOnboardingContainer title={'Lender onboarding'}>
            <BackButton sx={{ margin: '40px 0 24px 0' }}>
                <Link to={CLIENT_ONBOARDING_ROUTES.LEGAL_ENTITIES}>Back to Legal Entities</Link>
            </BackButton>
            <PageHeadingPortal />
            <ViewEntity config={{ breadcrumbsInteractive: false }} />
        </ClientOnboardingContainer>
    );
};
