import React from 'react';
import { BaseFormComponent, IBaseFormComponentProps } from './BaseFormComponent';
import { TextInput } from '../../inputs/TextInput';

interface IFormTextInputProps extends IBaseFormComponentProps {
    minRows?: number;
    multiline?: boolean;
}

export const FormTextInput: React.FC<IFormTextInputProps> = ({
    name,
    placeholder = 'Enter value',
    helperText,
    multiline,
    minRows,
    ...props
}) => (
    <BaseFormComponent name={name} {...props}>
        <TextInput
            name={name}
            placeholder={placeholder}
            helperText={helperText}
            multiline={multiline}
            minRows={minRows}
            fullWidth
        />
    </BaseFormComponent>
);
