import React from 'react';
import { Tooltip } from '@mui/material';
import { ILGCLogoProps, LGCLogo } from '../../../../lib/cdk/LGCLogo';

export const KYCLedgerCommReviewMark: React.FC<ILGCLogoProps> = () => {
    return (
        <Tooltip title={'By System Administrator'}>
            <LGCLogo alt={'By System Administrator'} width={'16px'} height={'16px'} />
        </Tooltip>
    );
};
