import * as yup from 'yup';
import { useUrlState } from 'lib/hooks/router/useUrlState';
import { KYCDocType } from 'interfaces/types';
import { yupEnum } from 'lib/forms';

export const useViewEntityUrlState = () => {
    return useUrlState({
        validationSchema: {
            edit: yup.boolean(),
            focus: yup.string(),
            kycId: yup.string(),
            kyc: yupEnum(KYCDocType),
        },
    });
};

export type IViewEntityUrlState = ReturnType<typeof useViewEntityUrlState>[0];
