import React from 'react';
import { NoDataPlaceholderRow } from 'lib/dataDisplay/List/components/NoDataPlaceholderRow';
import { DataErrorPlaceholder } from 'lib/dataDisplay/DataErrorPlaceholder';
import { DivTableTheme } from 'lib/dataDisplay/List/themes/DivTableTheme';
import { NoElementsPlaceholder } from '../../../../NoElementsPlaceholder';
import { IPeopleListItem, PeopleListRow } from './PeopleListRow';
import { preparePeopleListData } from './preparePeopleListData';
import { usePeopleSetupQuery } from 'interfaces/model';
import { IListColumn } from 'lib/dataDisplay/List';
import { List } from 'lib/dataDisplay/List/List';
import { voidWrapper } from 'lib/utils/helpers';

interface IRoleListProps {
    query: ReturnType<typeof usePeopleSetupQuery>;
    pagination?: boolean;
    noPreview?: boolean;
    embedded?: boolean;
}

const COLUMNS: IListColumn<IPeopleListItem>[] = [
    { id: 'name', label: 'User' },
    { id: 'jobTitle', label: 'Job Title', shrink: true },
    { id: 'status', label: 'Status', sort: false, shrink: true },
    { id: 'actions', label: 'Actions', sort: false, shrink: true },
];

export const PeopleList: React.FC<IRoleListProps> = ({ query, noPreview }) => {
    const data = preparePeopleListData(query?.data?.roles.data || []);
    const { loading, error, refetch } = query;
    const reloadData = voidWrapper(refetch);

    return (
        <DivTableTheme>
            <List
                data={data}
                columns={COLUMNS}
                rowComponent={PeopleListRow}
                noDataPlaceholder={
                    <NoDataPlaceholderRow colspan={COLUMNS.length}>
                        {query?.data?.roles.data.length === 0 && (
                            <NoElementsPlaceholder text={'No people. Start adding by clicking the button below'} />
                        )}
                        {error && <DataErrorPlaceholder onRetry={reloadData} error={error} />}
                        {loading && 'Loading data'}
                    </NoDataPlaceholderRow>
                }
                rowComponentProps={{
                    reloadData,
                    noPreview,
                }}
                onRetry={reloadData}
                loading={loading}
                error={error}
                pagination
                size={10}
            />
        </DivTableTheme>
    );
};
