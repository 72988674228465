import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { FormHelperText, FormLabel, GridProps } from '@mui/material';
import { IconWithTooltip } from '../../icons/IconWithTooltip';

export interface IBaseFormComponentProps extends GridProps {
    name: string;
    placeholder?: string;
    label?: React.ReactNode;
    tooltip?: React.ReactNode;
    tooltipMaxWidth?: string;
    helperText?: React.ReactNode;
    required?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
}

export const BaseFormComponent: React.FC<IBaseFormComponentProps> = ({
    name,
    label,
    required,
    placeholder,
    helperText,
    tooltip,
    tooltipMaxWidth,
    xs = 12,
    children,
    ...props
}) => (
    <Grid {...props} xs={xs} item>
        <Stack spacing={1}>
            <Stack spacing={0.5}>
                <Box display={'flex'} alignItems={'center'}>
                    {label && (
                        <FormLabel htmlFor={name} className={'nomargin'} required={required}>
                            {label}
                        </FormLabel>
                    )}
                    {tooltip && (
                        <IconWithTooltip
                            text={tooltip}
                            sx={{ padding: '4px', margin: '-4px 0' }}
                            tooltipProps={
                                tooltipMaxWidth
                                    ? {
                                          componentsProps: { tooltip: { sx: { maxWidth: tooltipMaxWidth } } },
                                      }
                                    : void 0
                            }
                        />
                    )}
                </Box>
                {helperText && <FormHelperText sx={{ lineHeight: 1.166667 }}>{helperText}</FormHelperText>}
            </Stack>
            {children}
        </Stack>
    </Grid>
);
