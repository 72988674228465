import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import React from 'react';
import { CreateEntityWizardDialog, useEntityWizardDialog } from '../../../../../Entities/CreateEntityWizardDialog';
import { getChildEntityType } from '../../../../../Entities/ViewEntity/LegalEntities/LegalEntities.helpers';
import { useLegalEntitiesList } from '../../../ClientOnboarding.const';
import { FieldsetCollapsible } from '../../../../../../../lib/forms/FieldsetCollapsible';
import { getLegalEntitiesDescriptionText, LegalEntitiesSetupList } from './LegalEntitiesSetupList';
import { voidWrapper } from '../../../../../../../lib/utils/helpers';
import { ClientOnboardingFragment, EntityType } from '../../../../../../../interfaces/model';

interface ILegalEntitiesSetupFormProps {
    query: ReturnType<typeof useLegalEntitiesList>;
    topCo: Maybe<ClientOnboardingFragment> | undefined;
    entityType: EntityType;
    noPreview?: boolean;
}

export const LegalEntitiesSetupForm: React.FC<ILegalEntitiesSetupFormProps> = ({
    query,
    topCo,
    entityType,
    noPreview,
}) => {
    const topCoRef = topCo?.id;
    const [editDialog] = useEntityWizardDialog();
    const handleAdd = () =>
        editDialog.open({
            topCoRef,
            parentRef: topCoRef,
            parentEntityType: entityType,
            entityType: getChildEntityType(entityType),
        });
    const hasEntities = (query.data?.entities.total || 0) > 0;

    return (
        <Stack spacing={4}>
            <FieldsetCollapsible
                title={'Legal Entities'}
                description={getLegalEntitiesDescriptionText(entityType)}
                complete={hasEntities}
                defaultExpand
                required
            >
                <Stack spacing={1}>
                    <Box>
                        <Button
                            data-testid={'NewCompanyView:addNewEntityButton'}
                            size={'large'}
                            variant={'contained'}
                            sx={{ minWidth: '120px' }}
                            onClick={handleAdd}
                        >
                            Add entity
                        </Button>
                    </Box>
                    {hasEntities && (
                        <LegalEntitiesSetupList
                            query={query}
                            entityType={entityType}
                            noPreview={noPreview}
                            topCo={topCo}
                        />
                    )}
                </Stack>
            </FieldsetCollapsible>
            <CreateEntityWizardDialog
                onSaveSuccess={voidWrapper(query.refetch)}
                config={{ authorisedSignatorySelect: false }}
            />
        </Stack>
    );
};
