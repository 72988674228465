import { useEffect } from 'react';
import { makeVar } from '@apollo/client';
import { useSelector } from 'react-redux';
import OpenReplay from '@openreplay/tracker';
import { AuthRoleFragment, OpenRelayConfigFragment } from '../components/Auth/Login/Login.query';
import { APP_INITIALIZED_SELECTOR } from '../App.selectors';
import trackerAssist from '@openreplay/tracker-assist';
import { useUserDetails } from './useUserDetails';
import { useAppConfig } from './useAppConfig';

let isInitInProgress = false;
const trackerRef = makeVar<OpenReplay | null>(null);

const initTracker = async (
    authRole: MaybeUndefined<AuthRoleFragment>,
    openReplayConfig: OpenRelayConfigFragment | undefined
) => {
    if (trackerRef() || isInitInProgress || !authRole || !openReplayConfig?.enabled) {
        return;
    }
    isInitInProgress = true;
    const tracker = new OpenReplay({
        projectKey: openReplayConfig.projectKey,
        ingestPoint: openReplayConfig.ingestPoint,
        obscureInputEmails: false,
        obscureTextEmails: false,
        defaultInputMode: 0, // Plain text,
    });

    tracker.use(trackerAssist()); // TODO check the list of available options below

    const email = authRole?.email || void 0;
    const name = authRole?.name || void 0;
    const userId = email || name || authRole?.id;

    userId && tracker.setUserID(userId);
    authRole?.id && tracker.setMetadata('id', authRole?.id);
    name && tracker.setMetadata('name', name);
    email && tracker.setMetadata('email', email);
    authRole?.topCo?.name && tracker.setMetadata('topCo', authRole?.topCo.name);
    const result = await tracker.start();
    if (result.success) {
        trackerRef(tracker);
    }
    isInitInProgress = false;
};

export const getAppTracker = () => trackerRef();

export const useAppTracker = (): OpenReplay | null => {
    const { openReplay } = useAppConfig();
    const { authRole } = useUserDetails();
    const isAppLoaded = useSelector(APP_INITIALIZED_SELECTOR);

    useEffect(() => {
        if (isAppLoaded) {
            initTracker(authRole, openReplay).catch(console.error);
        }
    }, [authRole, isAppLoaded, openReplay]);

    return trackerRef();
};
