import { v4 as uuid } from 'uuid';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DIALOG_STATE_SELECTOR_FACTORY } from './dialogState.reducer';
import { hideDialog, showDialog } from './dialogState.actions';

interface IDialogControls<TState> {
    open: (newState?: TState) => void;
    close: () => void;
}

type IDialogTuple<TState extends object> = [IDialogControls<TState>, boolean, TState | undefined];
export type IDialogHook<TState extends object> = (props?: { id?: UUID; state?: TState }) => IDialogTuple<TState>;

/**
 * TODO should be possible to open by url
 * TODO should be able to open programmatically
 * TODO should affect history on open  & replace(push)
 * Returns [controls, visible, state]
 */
export const makeDialogState = <T extends object>(): IDialogHook<T> => {
    const DEFAULT_DIALOG_ID = uuid();
    return (props) => {
        const dispatch = useDispatch();
        const dialogId = props?.id || DEFAULT_DIALOG_ID;
        const dialogState = useSelector(DIALOG_STATE_SELECTOR_FACTORY<T>(dialogId));
        const isOpened = !!dialogState?.visible;

        const open = useCallback(
            (newState?: T) => {
                dispatch(
                    showDialog({
                        id: dialogId,
                        value: newState || {},
                    })
                );
            },
            [dialogId, dispatch]
        );

        const close = useCallback(() => {
            dispatch(hideDialog({ id: dialogId }));
        }, [dialogId, dispatch]);

        return [{ open, close }, isOpened, dialogState?.state];
    };
};
