import { useHistory } from 'react-router-dom';
import React, { useCallback } from 'react';

export function useLinkNavigationBehavior() {
    const history = useHistory();
    return useCallback(
        (event: React.MouseEvent, route: string) => {
            if (event.ctrlKey || event.metaKey) {
                window.open(route, '_blank');
            } else {
                history.push(route);
            }
        },
        [history]
    );
}
