import React from 'react';
import { Box, FormLabel, Link } from '@mui/material';
import { useTermsAndConditionsDialog } from '../../TermsAndConditionsDialog';
import { LoadingSpinnerButton } from 'lib/buttons/LoadingSpinnerButton';
import { PasswordInput } from 'lib/inputs/PasswordInput';
import { LoginScreenFrame } from '../LoginScreenFrame';
import { TextInput } from 'lib/inputs/TextInput';

interface ICredentialsLoginScreenProps {
    spin: boolean;
    children?: React.ReactNode;
}

export const CredentialsLoginScreen: React.FC<ICredentialsLoginScreenProps> = ({ spin }) => {
    const [termsAndConditionsDialog] = useTermsAndConditionsDialog();

    return (
        <LoginScreenFrame title={'Log in'} description={'Enter your LedgerComm account credentials'}>
            <Box>
                <FormLabel required htmlFor={'login'}>
                    Email
                </FormLabel>
                <TextInput name={'login'} placeholder={'Enter email'} disabled={spin} fullWidth />
            </Box>
            <Box>
                <FormLabel required htmlFor={'password'}>
                    Password
                </FormLabel>
                <PasswordInput
                    name={'password'}
                    placeholder={'Enter password'}
                    disabled={spin}
                    type={'password'}
                    fullWidth
                />
            </Box>
            <span>
                <Link
                    sx={{ padding: '8px 8px 8px 0', fontWeight: '500' }}
                    href={'api/resetpass'}
                    rel={'noopener noreferrer'}
                    underline={'none'}
                >
                    Forgot password?
                </Link>
            </span>
            <LoadingSpinnerButton label={'Login'} type={'submit'} spin={spin} fullWidth />
            <Link
                underline={'none'}
                component={'button'}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    termsAndConditionsDialog.open({ mode: 'readonly' });
                }}
                sx={{ fontWeight: 500 }}
            >
                Terms and Conditions
            </Link>
        </LoginScreenFrame>
    );
};
