import React from 'react';
import { Container, ContainerProps } from '@mui/material';
import Box from '@mui/material/Box';

interface IAppContainerProps extends ContainerProps {
    className?: string;
}

export const AppContainer: React.FC<IAppContainerProps> = React.forwardRef(
    ({ children, maxWidth = 'lg', className, ...containerProps }, ref) => {
        return (
            <Box className={className || ''} ref={ref}>
                <Container maxWidth={maxWidth} {...containerProps}>
                    {children}
                </Container>
            </Box>
        );
    }
);
