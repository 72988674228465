import React from 'react';
import cl from 'classnames';
import { Badge, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { Warning } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useNotificationsCount } from '../../pages/Notifications/useNotificationsCount';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { NOTIFICATIONS_ROUTE } from '../../App.routes';
import styles from './NotificationsBell.module.css';

export const NotificationsBell = () => {
    const { error, total } = useNotificationsCount(true);

    return (
        <Tooltip title={'Notification Centre'} arrow>
            <IconButton color={'primary'} sx={{ width: 32, height: 32 }} component={Link} to={NOTIFICATIONS_ROUTE}>
                <Badge
                    className={cl(styles.badgeIcon, error ? styles.transparent : styles.colored)}
                    badgeContent={error ? <Warning color={'error'} fontSize={'small'} /> : total}
                    hidden={total === 0 && !error}
                >
                    <NotificationsOutlinedIcon sx={{ fontSize: 28 }} />
                </Badge>
            </IconButton>
        </Tooltip>
    );
};
