import React from 'react';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { IDocumentUploadDialogConfig } from '../../../../components/Documents/components/DocumentUploadDialog';
import { SingleDocumentList } from '../../../../components/Documents/components/SingleDocumentList';
import {
    DocumentStorage,
    KYCCategoriesTypesFragment,
    KYCDocType,
    KYCDocumentFragment,
} from '../../../../../interfaces/model';
import { EmptyStatePlaceholder } from '../../../../common/EmptyStatePlaceholder';
import { Fieldset } from '../../../../../lib/forms/Fieldset';
import { kycDocTypeLabels } from '../../../../App.const';
import { IEntityInitialForm } from '../useEntityEditFormik/useEntityEditFormik.const';
import { ValidationMessage } from '../../../../../lib/forms/ValidationMessage';
import { mapDocumentToFragment } from '../../../../components/Documents/Documents.const';

interface IEntityDocumentsStep {
    topCoRef: UUID | undefined;
    requirements: KYCCategoriesTypesFragment[];
}

const KYC_REQUIREMENTS_CONFIGS: Partial<Record<KYCDocType, IDocumentUploadDialogConfig>> = {
    DIRECTORS_ID_DOCS: { multipleFilesUpload: true, storage: DocumentStorage.KYC },
};

export const EntityDocumentsStep: React.FC<IEntityDocumentsStep> = ({ requirements, topCoRef }) => {
    const formik = useFormikContext<IEntityInitialForm>();
    const entityDocTypes = requirements.map(({ type }) => type);
    const fieldsets = entityDocTypes.map((type) => ({
        docType: type,
        title: kycDocTypeLabels[type],
        config: KYC_REQUIREMENTS_CONFIGS[type],
    }));

    return (
        <Fieldset
            title={'Supporting Documents'}
            description={'Add additional documents that will help us verify your entity'}
            spacing={2}
        >
            <div>
                {fieldsets.length === 0 && (
                    <Box marginTop={'16px'}>
                        <EmptyStatePlaceholder
                            elevation={0}
                            title={'This entity has no KYC requirements'}
                            imageName={'bulk_upload'}
                        />
                    </Box>
                )}
                {fieldsets.length > 0 &&
                    fieldsets.map(({ title, docType, config }) => (
                        <Fieldset key={docType} title={title}>
                            <div>
                                <SingleDocumentList
                                    parent={{
                                        topCoRef,
                                        id: formik.values.id,
                                        name: formik.values.name,
                                        shortCode: formik.values.shortCode || void 0,
                                        docTypes: entityDocTypes,
                                    }}
                                    docType={docType}
                                    value={
                                        mapDocumentToFragment(formik.values.documents[docType]) as KYCDocumentFragment
                                    }
                                    config={config}
                                    onSubmit={async (document) => {
                                        const deleted = document.deleted;

                                        formik.setFieldValue('documents', {
                                            ...formik.values.documents,
                                            [docType]: deleted ? void 0 : document,
                                        });
                                    }}
                                    listEmbedded
                                />
                                <ValidationMessage field={`documents.${docType}`} />
                            </div>
                        </Fieldset>
                    ))}
            </div>
        </Fieldset>
    );
};
