import React from 'react';
import { Box } from '@mui/material';
import { EntitySuggest, useEntitySuggestLazyQuery } from '../../../../../../interfaces/model';
import { NonFalsy } from '../../../../../../lib/utils/helpers';
import { SuggestInput } from '../../../../../../lib/inputs/SuggestInput';
import { CountryFlagAndName } from '../../../../../../lib/dataDisplay/CountryFlagAndName';
import { useFormikField } from '../../../../../../lib/forms/useFormikField';

interface IEntitySuggestInputProps {
    name?: string;
    disabled?: boolean;
    debounceDelay?: number;
}

type IOption = EntitySuggest & { id: string };

const getId = ({ lei, giin, dttp }: EntitySuggest) => [lei, giin, dttp].filter(NonFalsy).join('');

const getMenuOptionLabel = (option: IOption) => {
    return (
        <Box>
            <b>{option.name}</b>
            <div>
                Jurisdiction: <CountryFlagAndName country={option.jurisdiction!} />
            </div>
            {option.lei && <div>LEI: {option.lei}</div>}
            {option.giin && <div>GIIN: {option.giin}</div>}
            {option.dttp && <div>DTTP: {option.dttp}</div>}
        </Box>
    );
};

export const EntityNameSuggestInput: React.FC<IEntitySuggestInputProps> = ({ name = 'name', ...props }) => {
    const { formik } = useFormikField<string, EntitySuggest>(name);

    return (
        <SuggestInput
            {...props}
            name={name}
            placeholder="Enter name"
            query={useEntitySuggestLazyQuery}
            getValue={(values): Maybe<IOption> => {
                const valueProp = values.name
                    ? {
                          name: values.name,
                          jurisdiction: values.jurisdiction || null,
                          lei: values.lei || null,
                          giin: values.giin || null,
                          dttp: values.dttp || null,
                      }
                    : null;
                return valueProp && { ...valueProp, id: getId(valueProp) };
            }}
            getOptions={(data) => (data?.entitySuggest || []).map((option) => ({ ...option, id: getId(option) }))}
            getOptionLabel={(option) => option.name}
            getMenuOptionLabel={getMenuOptionLabel}
            onChange={(option) => {
                formik.setValues({
                    ...formik.values,
                    name: option?.name || '',
                    jurisdiction: option?.jurisdiction,
                    dttp: option?.dttp || '',
                    lei: option?.lei || '',
                    giin: option?.giin || '',
                });
            }}
        />
    );
};
