import React from 'react';
import { ClientOnboardingContainer } from '../../ClientOnboardingFrame/ClientOnboardingContainer';
import { ViewRole } from '../../../../Roles/ViewRole/ViewRole';
import { BackButton } from '../../../../../../lib/navigation/BackButton';
import { Link } from 'react-router-dom';
import { CLIENT_ONBOARDING_ROUTES } from '../../../../../App.routes';
import { PageHeadingPortal } from '../../../../../components/PageHeading';

export const PeopleSetupPreview: React.FC = () => {
    return (
        <ClientOnboardingContainer title={'User onboarding'}>
            <BackButton sx={{ margin: '40px 0 24px 0' }}>
                <Link to={CLIENT_ONBOARDING_ROUTES.PEOPLE}>Back to Onboarding</Link>
            </BackButton>
            <PageHeadingPortal />
            <ViewRole config={{ breadcrumbsInteractive: false }} />
        </ClientOnboardingContainer>
    );
};
