import React from 'react';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import styles from './NoElementsPlaceholder.module.css';

interface INoElementsPlaceholderProps {
    text: string;
    description?: string;
}

export const NoElementsPlaceholder: React.FC<INoElementsPlaceholderProps> = ({ text, description }) => (
    <div className={styles.wrap}>
        <FilterNoneIcon color={'secondary'} className={styles.icon} />
        <div className={styles.textWrap}>
            <span className={styles.text}>{text}</span>
            {description && <span className={styles.descriptionText}>{description}</span>}
        </div>
    </div>
);
