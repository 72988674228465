import * as yup from 'yup';
import { useUrlState } from 'lib/hooks/router/useUrlState';

export const dialogUrlStateValidationSchema = {
    dialog: yup.number(),
    value: yup.string(),
    focus: yup.string(),
};

export const useDialogUrlState = () => {
    return useUrlState({
        validationSchema: dialogUrlStateValidationSchema,
    });
};

export type IDialogUrlState = ReturnType<typeof useDialogUrlState>[0];
