import {
    DeleteType,
    DocumentInInput,
    KYCDocSubcategory,
    KYCDocType,
    KYCDocumentFragment,
    useDocumentMutation,
} from 'interfaces/model';
import { kycDocSubcategoryLabels, kycDocTypeLabels } from '../../../../../../App.const';
import { DATE_HOUR_VIEW_FORMAT, dateFromISOToFormat } from 'lib/utils/date';
import { useDocumentsViewDialog } from '../../DocumentsViewDialog';
import { useAppSnackbar } from '../../../../../AppNotification';
import { useConfirmDialog } from 'lib/modals/ConfirmDialog';
import { NOOP_ASYNC } from '../../../../../../../lib/utils/helpers';

const getBodyText = (props: {
    docType: KYCDocType | undefined;
    validFromDate: Maybe<LocalDate> | undefined;
    parentName?: string;
    docSubcategory?: Maybe<KYCDocSubcategory>;
    mode: DeleteType;
}) => {
    const { docType, validFromDate, parentName, docSubcategory, mode } = props;
    if (mode === DeleteType.PERMANENT) {
        return 'Do you want to delete the document? It will not be possible to revert this action';
    }

    const dateText = validFromDate ? `from ${dateFromISOToFormat(validFromDate, DATE_HOUR_VIEW_FORMAT)}` : '';
    const subCategoryLabel = kycDocSubcategoryLabels[docSubcategory!];
    const subcategoryText = `${subCategoryLabel ? `about "${subCategoryLabel}",` : ''}`;

    return `
        You are removing files ${dateText} ${subcategoryText} upload
        from "${kycDocTypeLabels[docType!]}" ${parentName ? `document on "${parentName}"` : ''}
    `;
};

const getTitleText = (docType: KYCDocType | undefined, subcategory: Maybe<KYCDocSubcategory> | undefined) => {
    return `Delete "${kycDocSubcategoryLabels[subcategory!] || kycDocTypeLabels[docType!] || ''}"`;
};

export const useDocumentDelete = (props: {
    document: Partial<Pick<KYCDocumentFragment, 'id' | 'docType' | 'validFromDate' | 'docSubcategory'>> | undefined;
    parentName?: string;
    onDeleteSuccess: () => Promise<void>;
    onEditSubmit: ((document: DocumentInInput) => Promise<void>) | undefined;
    mode: DeleteType;
}) => {
    const snackbar = useAppSnackbar();
    const [saveDocument] = useDocumentMutation();
    const [{ close }] = useDocumentsViewDialog();
    const [confirmDeleteDialog] = useConfirmDialog();
    const { document, parentName, onDeleteSuccess = NOOP_ASYNC, onEditSubmit, mode } = props;
    const { docType, validFromDate, docSubcategory } = document || {};
    const isPermanent = mode === DeleteType.PERMANENT;

    const handleDelete = async () => {
        try {
            const newDocument: DocumentInInput = {
                id: document?.id,
                deleteType: mode,
                deleted: true,
            };

            if (onEditSubmit) {
                await onEditSubmit(newDocument);
            } else {
                await saveDocument({ variables: { document: newDocument } });
                await onDeleteSuccess();
            }
            close();
            snackbar.show({ message: 'Document has been removed' });
        } catch (e) {
            snackbar.showError({ message: `Failed to remove document` }, e);
        }
    };

    return () =>
        confirmDeleteDialog.open({
            title: getTitleText(docType, docSubcategory),
            body: getBodyText({ docType, validFromDate, parentName, docSubcategory, mode }),
            submitButtonLabel: isPermanent ? 'Delete from system' : 'Confirm',
            confirmButtonColor: isPermanent ? 'error' : 'primary',
            onConfirm: handleDelete,
        });
};
