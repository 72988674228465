import React, { useContext, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import {
    BusinessProfileSetupEntityFragment,
    useBusinessProfileSetupQuery,
    useSetupClientEntityMutation,
} from 'interfaces/model';
import { ClientOnboardingNextStepButton } from '../../ClientOnboardingFrame/ClientOnboardingNextStepButton';
import { businessProfileValidationSchema, IBusinessProfile } from './businessProfileValidationSchema';
import { IClientOnboardingStepComponent } from '../../interfaces/IClientOnboardingStepComponent';
import { ClientOnboardingContext } from '../../ClientOnboarding.const';
import { BusinessProfileSetupForm } from './BusinessProfileSetupForm';
import { ClientOnboardingContentFrame } from '../../ClientOnboardingContentFrame';
import { useAppSnackbar } from 'App/components/AppNotification';
import { FieldsetCollapsible } from 'lib/forms/FieldsetCollapsible';
import { proxyAPIValidationError } from 'lib/forms';
import { ENTITY_TYPE_LABELS } from 'App/App.const';

const getInitialValues = (topco?: Maybe<BusinessProfileSetupEntityFragment>): IBusinessProfile => {
    return {
        name: topco?.name,
        headquarters: topco?.headquarters || void 0,
    };
};

export const BusinessProfileSetup: React.FC<IClientOnboardingStepComponent> = ({ stepIndex, disabled }) => {
    const snackbar = useAppSnackbar();
    const [setupEntity, setupEntityQuery] = useSetupClientEntityMutation();
    const { topCoRef, updateData } = useContext(ClientOnboardingContext);
    const { data, error, loading, refetch } = useBusinessProfileSetupQuery({
        fetchPolicy: 'cache-and-network',
        skip: !topCoRef,
        variables: {
            topCoRef: topCoRef!,
        },
    });

    const initialValues = getInitialValues(data?.entity);
    const [prevValues, setPrevValues] = useState<Partial<typeof initialValues>>({});
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: businessProfileValidationSchema,
        validate: proxyAPIValidationError(prevValues, setupEntityQuery.error),
        onSubmit: async (values) => {
            if (formik.dirty) {
                const { headquarters } = values;
                setPrevValues(values);
                try {
                    await setupEntity({
                        variables: {
                            entity: {
                                id: topCoRef,
                                headquarters,
                            },
                        },
                    });
                    await updateData();
                    await refetch();
                } catch (e) {
                    snackbar.showError(
                        { message: 'Failed to update entity details. Please contact the administrator' },
                        e
                    );
                    return false;
                }
            }
            return true;
        },
    });

    const companyLabel = data?.entity.entityType ? ENTITY_TYPE_LABELS[data.entity.entityType] : '';

    return (
        <FormikProvider value={formik}>
            <ClientOnboardingContentFrame
                label={'Business Profile'}
                component={Form}
                description={
                    'Please provide basic business information to enable us to set up ' +
                    'your comprehensive business profile.'
                }
                error={error}
                action={
                    <ClientOnboardingNextStepButton
                        stepIndex={stepIndex}
                        spin={formik.isSubmitting}
                        disabled={!!error || disabled}
                        onClick={async () => formik.submitForm()}
                    />
                }
            >
                <FieldsetCollapsible title={'Basic information'} defaultExpand>
                    <BusinessProfileSetupForm disabled={loading || disabled} companyLabel={companyLabel} />
                </FieldsetCollapsible>
            </ClientOnboardingContentFrame>
        </FormikProvider>
    );
};
