import { BankAccountFieldType, BankAccountPurpose } from 'interfaces/types';

export const bankAccountPurposeLabels: Partial<Record<BankAccountPurpose, string>> = {
    PRINCIPLE_ONLY: 'Principle Only',
    INTEREST_ONLY: 'Interest Only',
    FEE_ONLY: 'Fee Only',
    PRINCIPLE_INTEREST_FEE: 'Principle, Interest & Fee',
    AGENCY_FEES: 'LedgerComm fee',
};

export const bankAccountFieldTypeLabels: Record<BankAccountFieldType, string> = {
    BIC: 'BIC',
    IBAN: 'IBAN',
    SORT_CODE: 'Sort code',
    ACCOUNT_NAME: 'Account name',
    ACCOUNT_NUMBER: 'Account number',
    SWIFT: 'SWIFT',
    ABA: 'ABA',
    ADDRESS: 'Bank address',
    BANK_NAME: 'Bank name',
    BSB: 'BSB',
    FFC_ACCOUNT_NUMBER: 'FFC Account number',
    FFC_NAME: 'FFC Name',
};

export const DEFAULT_PURPOSES = [
    BankAccountPurpose.PRINCIPLE_ONLY,
    BankAccountPurpose.INTEREST_ONLY,
    BankAccountPurpose.FEE_ONLY,
    BankAccountPurpose.PRINCIPLE_INTEREST_FEE,
];
