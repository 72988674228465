import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import { RoleNotificationsSelectList } from '../../../EditRole/RoleNotificationsSelectList';
import { Fieldset } from '../../../../../../lib/forms/Fieldset';
import { NonFalsy } from '../../../../../../lib/utils/helpers';
import { ClientType } from '../../../../../../interfaces/model';

interface IRoleNotificationsEditProps {
    clientType: ClientType;
}

export const RoleNotificationsEdit: React.FC<IRoleNotificationsEditProps> = ({ clientType }) => {
    const formik = useContext(FormikContext);

    return (
        <Fieldset title={'Notifications'} description={'Select email notifications to receive'}>
            <RoleNotificationsSelectList
                clientType={clientType}
                value={formik.values.emailNotifications?.filter(NonFalsy) || []}
                onChange={(emailNotifications) => formik.setFieldValue('emailNotifications', emailNotifications)}
            />
        </Fieldset>
    );
};
