import * as yup from 'yup';
import { useLocation } from 'react-router';
import { useUrlState } from './useUrlState';

export const useFromNavigationUrlState = () => {
    return useUrlState({
        validationSchema: {
            from: yup.string(),
        },
    });
};

export type IFromNavigationUrlState = ReturnType<typeof useFromNavigationUrlState>[0];

export const useFromNavigation = (): IFromNavigationUrlState => {
    const location = useLocation();
    return { from: `${location.pathname}${location.search}` };
};
