import cl from 'classnames';
import { Collapse } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { createContext, useMemo, useState } from 'react';
import { CollapseTrigger } from '../../buttons/CollapseTrigger';
import styles from './FieldsetCollapsible.module.css';

interface IFieldsetCollapsibleProps {
    title: string;
    description?: string;
    defaultExpand?: boolean;
    required?: boolean;
    complete?: boolean;
    children: React.ReactNode;
}

interface IFieldsetCollapsibleContext {
    expand: () => void;
    setComplete: (value: boolean) => void;
}

export const FieldsetCollapsibleContext = createContext<IFieldsetCollapsibleContext>({
    expand: () => {
        throw new Error('FieldsetCollapsibleContext.expand is not implemented');
    },
    setComplete: () => {
        throw new Error('FieldsetCollapsibleContext.setComplete is not implemented');
    },
});

export const FieldsetCollapsible: React.FC<IFieldsetCollapsibleProps> = ({
    title,
    description,
    defaultExpand = false,
    required,
    complete,
    children,
}) => {
    const [isComplete, setComplete] = useState(false);
    const [isExpanded, setExpanded] = useState(defaultExpand);
    const context = useMemo(() => ({ expand: () => setExpanded(true), setComplete }), []);

    return (
        <div className={styles.container}>
            <div className={styles.titleWrap}>
                <div className={styles.titleWrap}>
                    {complete || isComplete ? (
                        <CheckCircleIcon className={styles.completeIcon} fontSize={'large'} color={'success'} />
                    ) : (
                        <PendingIcon className={styles.completeIcon} fontSize={'large'} color={'secondary'} />
                    )}
                    <span className={cl(styles.title, required && styles.requiredAsterisk)}>{title}</span>
                    <CollapseTrigger
                        isActive={isExpanded}
                        tabIndex={isExpanded ? -1 : void 0}
                        onClick={() => setExpanded(!isExpanded)}
                        size={'medium'}
                    />
                </div>
            </div>
            <Collapse in={isExpanded} timeout="auto">
                <div className={styles.description}>{description}</div>
                <div>
                    <FieldsetCollapsibleContext.Provider value={context}>
                        {children}
                    </FieldsetCollapsibleContext.Provider>
                </div>
            </Collapse>
        </div>
    );
};
