import React from 'react';
import { Paper, Typography } from '@mui/material';
import styles from './OtotDisplay.module.css';

interface IOtotDisplayProps {
    content: string;
}

export const OtotDisplay: React.FC<IOtotDisplayProps> = ({ content }) => {
    return (
        <div data-testid={'Entity:OtotDisplay'}>
            <h4>OTOT</h4>
            <Paper elevation={0} className={styles.ototContainer}>
                <Typography component={'div'} variant={'body2'}>
                    <pre className={styles.multiDisplayPre}>{content}</pre>
                </Typography>
            </Paper>
        </div>
    );
};
