import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FACILITY_CREATE_BY_BORROWER_ROUTE_FACTORY } from '../../../../App.routes';
import { EntityFacilitiesEntityFragment } from '../../../../../interfaces/model';
import { AppEmbeddedListWrap } from '../../../../components/AppEmbeddedListWrap';
import { EntityFacilitiesList } from './EntityFacilitiesList';

interface IEntityFacilitiesProps {
    parentEntity: EntityFacilitiesEntityFragment;
    reloadData: () => void;
    className?: string;
    testId?: string;
}

export const EntityFacilities: React.FC<IEntityFacilitiesProps> = ({ parentEntity, className, reloadData, testId }) => {
    const facilities = [
        ...(parentEntity.facilities || []),
        ...(parentEntity.childs || []).flatMap((child) => child.facilities || []),
    ];
    const actions = parentEntity.caps.createFacility && (
        <Link to={FACILITY_CREATE_BY_BORROWER_ROUTE_FACTORY(parentEntity.topCoRef)}>
            <Button color={'primary'} variant={'contained'} data-testid={`${testId}:addButton`}>
                Add Facility
            </Button>
        </Link>
    );

    return (
        <AppEmbeddedListWrap title={'Facilities'} actions={actions} className={className}>
            <EntityFacilitiesList facilities={facilities} updateData={reloadData} testId={testId} />
        </AppEmbeddedListWrap>
    );
};
