import React from 'react';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import { AvatarIcon } from './AvatarIcon';

interface IControlledProfileBadgeProps {
    role: { id: UUID; name: Maybe<string>; isAdmin: boolean | undefined };
}

export const ControlledProfileBadge: React.FC<IControlledProfileBadgeProps> = ({ role }) => {
    return (
        <Tooltip
            title={
                <Stack alignItems={'center'}>
                    <span>This account is controlled by</span>
                    <span>{role.name}</span>
                </Stack>
            }
            arrow
        >
            <Box sx={{ marginRight: '4px' }}>
                <AvatarIcon name={role.name || ''} isAdmin={!!role.isAdmin} />
            </Box>
        </Tooltip>
    );
};
