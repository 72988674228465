import { ICounterpartyKYCListRowItem } from './CounterpartyKYCListRow';
import { CounterpartyStatus, EntityType } from '../../../../../interfaces/model';
import { getCounterpartyLastChange } from './prepareCounterpartyKYCListRowItem';
import { CounterpartyKYCEntityFragment } from './CounterpartyKYC.query';
import {
    CounterpartyFragment,
    CounterpartyStatusFragment,
} from '../../Counterparties/CounterpartiesList/Counterparties.query';

export interface ICounterpartyKYCChild {
    id: string;
    name: string;
    topCoRef: UUID;
    shortCode: Maybe<string>;
    status: Maybe<CounterpartyStatus>;
    counterpartyKYCStatus: Maybe<CounterpartyStatusFragment[]>;
    counterparties: Maybe<CounterpartyFragment[]>;
    hasBlockingTradeDependency: boolean;
    counterpartyKYCLastChange: Timestamp | undefined;
}

const getStatus = (
    fundEntity: {
        counterpartyKYCStatusAgg: Maybe<CounterpartyStatus>;
        counterpartyKYCStatus: Maybe<{ targetRef: UUID; status: Maybe<CounterpartyStatus> }[]>;
    },
    targetRef: UUID | undefined
) => {
    return targetRef
        ? fundEntity.counterpartyKYCStatus?.find((item) => item.targetRef === targetRef)?.status || null
        : fundEntity.counterpartyKYCStatusAgg;
};

export const flattenCounterpartyKYCChildren = (
    children: CounterpartyKYCEntityFragment[],
    targetRef?: UUID
): ICounterpartyKYCChild[] => {
    return (
        children?.flatMap((child): ICounterpartyKYCListRowItem[] => {
            if (child.entityType === EntityType.LENDER_INVESTMENT_MANAGER) {
                return (
                    child.loanFunds?.map((fund): ICounterpartyKYCListRowItem => {
                        return {
                            ...fund,
                            counterparties: child.counterparties,
                            counterpartyKYCLastChange: getCounterpartyLastChange(fund.counterpartyKYCStatus || []),
                            status: getStatus(fund, targetRef),
                            hasBlockingTradeDependency: !!fund.counterpartyTradeDep,
                            isFund: true,
                        };
                    }) || []
                );
            }

            return [
                {
                    ...child,
                    hasBlockingTradeDependency: !!child.counterpartyTradeDep?.length,
                    counterpartyKYCLastChange: getCounterpartyLastChange(child.counterpartyKYCStatus || []),
                    status: getStatus(child, targetRef),
                },
            ];
        }) || []
    );
};
