import Box from '@mui/material/Box';
import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import { FormLabel, Grid } from '@mui/material';
import { kycReviewStatusOptions, reviewActionColors, reviewActionIcons } from '../../../../../../Documents.const';
import { TextInput } from '../../../../../../../../../lib/inputs/TextInput';
import { FormCheckbox } from '../../../../../../../../../lib/forms/components/FormCheckbox';
import { useUserDetails } from '../../../../../../../../hooks/useUserDetails';
import { Dropdown } from '../../../../../../../../../lib/inputs/Dropdown';

export const DocumentKYCDetailsForm: React.FC = () => {
    const formik = useContext(FormikContext);
    const { isAdmin } = useUserDetails();

    return (
        <Grid container spacing={3} maxWidth={'500px'}>
            <Grid item xs={12}>
                <FormLabel required htmlFor={'status'}>
                    Review status
                </FormLabel>
                <Dropdown
                    name={'status'}
                    placeholder={'Review status'}
                    options={kycReviewStatusOptions}
                    disabled={formik.isSubmitting}
                    renderOption={(params, option) => {
                        if (option) {
                            const Icon = reviewActionIcons[option.value];
                            return (
                                <li {...params} key={option.id}>
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        className={reviewActionColors[option.value]}
                                        padding={'4px'}
                                    >
                                        {Icon && <Icon fontSize={'small'} className={'mr-4'} />}
                                        <span className={'mr-12'}>{option.name}</span>
                                    </Box>
                                </li>
                            );
                        }
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormLabel htmlFor={'comment'}>Comment</FormLabel>
                <TextInput
                    name={'comment'}
                    placeholder={'Review comment'}
                    disabled={formik.isSubmitting}
                    multiline
                    fullWidth
                />
            </Grid>
            {isAdmin && (
                <Grid item xs={12}>
                    <FormCheckbox name={'asAdmin'} label={'Submit as System Administrator'} />
                </Grid>
            )}
        </Grid>
    );
};
