import React from 'react';
import cl from 'classnames';
import styles from './LoadingSpinnerButton.module.css';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { NOOP } from '../../utils/helpers';

export interface ILoadingSpinnerButtonProps extends ButtonProps {
    label?: React.ReactNode;
    testId?: string;
    spin: boolean;
    fullWidth?: boolean;
}

export const LoadingSpinnerButton: React.FC<ILoadingSpinnerButtonProps> = ({
    testId = '',
    label = 'Submit',
    spin,
    onClick = NOOP,
    disabled,
    className = '',
    size,
    variant = 'contained',
    color = 'primary',
    children,
    fullWidth,
    ...props
}) => {
    return (
        <Button
            data-testid={testId}
            size={size}
            color={color}
            variant={variant}
            className={cl(fullWidth && styles.fullWidth, className)}
            onClick={onClick}
            disabled={disabled || spin}
            sx={{ minWidth: '64px' }}
            {...props}
        >
            {spin && <CircularProgress className={styles.spinner} size={24} />}
            {children || label}
        </Button>
    );
};
