import React from 'react';
import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import { Capabilities, useRolesSelectQuery } from '../../../interfaces/model';
import isArray from 'lodash/isArray';
import styles from './RolesSelect.module.css';

interface IRolesMultipleSelectProps {
    value: UUID[] | undefined;
    topCoRef: string;
    capability?: Capabilities;
    onChange: (roleId: Maybe<UUID[]>) => void;
    disabled: boolean;
    id?: string;
    multiple: true;
}

interface IRolesSingleSelectProps {
    value: UUID | undefined;
    topCoRef: string;
    capability?: Capabilities;
    onChange: (roleId: Maybe<UUID>) => void;
    disabled: boolean;
    id?: string;
    multiple?: false;
}

type IRolesSelectProps = IRolesSingleSelectProps | IRolesMultipleSelectProps;

// Note: don't use props destructuring as typescript loses the context
// and cannot distinguish single and multiple mode
export const RolesSelect: React.FC<IRolesSelectProps> = (props) => {
    const { topCoRef, capability, id, disabled } = props;
    const { data } = useRolesSelectQuery({
        fetchPolicy: 'no-cache',
        variables: {
            filter: {
                topCoRef,
                capability,
            },
        },
    });
    const suitableRoles = data?.roles?.data || [];

    return (
        <Select
            id={id}
            disabled={disabled}
            multiple={props.multiple}
            value={props.value}
            onChange={(e) => {
                const newValue = e.target.value;
                if (isArray(newValue) && props.multiple) {
                    props.onChange(newValue);
                }
            }}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <span className={styles.placeholder}>Select users</span>;
                }

                return isArray(selected)
                    ? selected.map((roleId) => suitableRoles.find((role) => role.id === roleId)?.name).join(', ')
                    : suitableRoles.find((role) => role.id === selected)?.name;
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            }}
            displayEmpty
            fullWidth
        >
            {suitableRoles.length === 0 && <MenuItem disabled>No suitable roles found</MenuItem>}
            {suitableRoles.map((role) => (
                <MenuItem
                    key={role.id}
                    value={role.id}
                    onClick={() => {
                        // enables de-selecting items in single-mode
                        // https://github.com/mui/material-ui/issues/20698
                        if (!props.multiple) {
                            if (props.value === role.id) {
                                props.onChange(null);
                            } else {
                                props.onChange(role.id);
                            }
                        }
                    }}
                >
                    <Checkbox
                        checked={isArray(props.value) ? props.value.includes(role.id) : props.value === role.id}
                    />
                    <ListItemText primary={role.name} />
                </MenuItem>
            ))}
        </Select>
    );
};
