import React from 'react';
import { Edit } from '@mui/icons-material';
import { TableRow } from '@mui/material';
import { EntityDeleteIconButton } from '../../../../../../Entities/ViewEntity/LegalEntities/LegalEntitiesList/LegalEntitiesListRow/EntityDeleteIconButton';
import {
    ActionsCell,
    JurisdictionCell,
    ValueCell,
} from '../../../../../../../../lib/dataDisplay/List/components/ListCells';
import { ListIconButton } from '../../../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { ClientOnboardingFragment, ViewEntityFragment } from '../../../../../../../../interfaces/model';
import { IListRow } from '../../../../../../../../lib/dataDisplay/List';
import { ENTITY_TYPE_LABELS } from '../../../../../../../App.const';
import { useEntityWizardDialog } from '../../../../../../Entities/CreateEntityWizardDialog';
import { getChildEntityType } from '../../../../../../Entities/ViewEntity/LegalEntities/LegalEntities.helpers';
import { CLIENT_ONBOARDING_ROUTES } from '../../../../../../../App.routes';
import { Link } from 'react-router-dom';
import { getEntityViewRouteFactory } from '../../../../../../Entities/Entities.helpers';

export interface ILegalEntitiesSetupListRowItem extends ViewEntityFragment {
    actions?: never;
}

interface IProps {
    updateData: () => void;
    noPreview?: boolean;
    topCo: Maybe<ClientOnboardingFragment> | undefined;
}

type ILegalEntitiesSetupListRowProps = IListRow<ILegalEntitiesSetupListRowItem, IProps>;

export const LegalEntitiesSetupListRow: React.FC<ILegalEntitiesSetupListRowProps> = ({ data, columns, props }) => {
    const [editDialog] = useEntityWizardDialog();

    const previewRoute = props.noPreview
        ? getEntityViewRouteFactory(data)(data.shortCode!)
        : CLIENT_ONBOARDING_ROUTES.LEGAL_ENTITIES_PREVIEW_ROUTE_FACTORY(data.shortCode!);
    const handleEdit = () => {
        editDialog.open({
            shortCode: data.shortCode || void 0,
            entityType: data.entityType || getChildEntityType(props.topCo?.entityType),
            parentRef: props.topCo?.id,
            parentEntityType: props.topCo?.entityType,
        });
    };

    return (
        <TableRow target={props.noPreview ? '_blank' : ''} component={Link} to={previewRoute} hover>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'name' && <ValueCell nowrap={false}>{data.name}</ValueCell>}
                    {id === 'jurisdiction' && <JurisdictionCell value={data.jurisdiction} />}
                    {id === 'entityType' && <ValueCell>{ENTITY_TYPE_LABELS[data.entityType]}</ValueCell>}
                    {id === 'actions' && (
                        <ActionsCell
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <>
                                <ListIconButton icon={Edit} onClick={handleEdit} />
                                <EntityDeleteIconButton id={data.id} name={data.name} onSuccess={props.updateData} />
                            </>
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
