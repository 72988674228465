import { CounterpartyKYCEntityWithChildrenFragment } from '../../../../../interfaces/model';
import { NonFalsy, sortCompare } from '../../../../../lib/utils/helpers';
import { ICounterpartyKYCListRowItem } from './CounterpartyKYCListRow';
import { flattenCounterpartyKYCChildren } from './flattenCounterpartyKYCChildren';

export const getCounterpartyLastChange = (items: { changedAt: Maybe<Timestamp> }[]): Timestamp | undefined => {
    return [...items]?.sort((a, b) => sortCompare(b.changedAt || '', a.changedAt || ''))[0]?.changedAt || void 0;
};

export const prepareCounterpartyKYCListRowItem =
    (flatten: boolean) =>
    (item: CounterpartyKYCEntityWithChildrenFragment): ICounterpartyKYCListRowItem[] => {
        if (flatten && item.childs) {
            return flattenCounterpartyKYCChildren(item.childs);
        }

        return [
            {
                ...item,
                status: item.counterpartyKYCStatusAgg,
                counterpartyKYCLastChange: getCounterpartyLastChange(
                    item.childs?.flatMap(({ counterpartyKYCStatus }) => counterpartyKYCStatus).filter(NonFalsy) || []
                ),
                hasBlockingTradeDependency: !!item.counterpartyTradeDep?.length,
                children: flattenCounterpartyKYCChildren(item.childs || []),
            },
        ];
    };
