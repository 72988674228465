import React, { useContext } from 'react';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import { ILoadingSpinnerButtonProps, LoadingSpinnerButton } from '../buttons/LoadingSpinnerButton';
import { FormikContext } from 'formik';

interface IDialogSubmitActionsProps {
    canSubmit?: Maybe<boolean>;
    onSubmit?: () => void;
    onClose: () => void;
    disabled?: boolean;
    spin?: boolean;
    loadingSpinnerButtonProps?: Partial<ILoadingSpinnerButtonProps>;
    color?: Colors;
    submitButtonLabel?: string;
    children?: React.ReactNode;
    className?: string;
}

export const DialogSubmitActions: React.FC<IDialogSubmitActionsProps> = ({
    spin: spinProp,
    canSubmit = true,
    onSubmit,
    onClose,
    disabled,
    submitButtonLabel = 'Submit',
    loadingSpinnerButtonProps,
    color,
    children,
    className,
}) => {
    const formik = useContext(FormikContext);
    const spin = formik?.isSubmitting || spinProp;

    return (
        <DialogActions className={className}>
            <Stack direction={'row'} spacing={1}>
                <Button
                    data-testid={'DialogSubmitActions:cancelButton'}
                    color={canSubmit ? 'secondary' : 'primary'}
                    variant={canSubmit ? 'text' : 'contained'}
                    onClick={onClose}
                    disabled={disabled || spin}
                >
                    {canSubmit ? 'Cancel' : 'Close'}
                </Button>
                {children}
                {canSubmit && (
                    <LoadingSpinnerButton
                        testId={'DialogSubmitActions:submitButton'}
                        spin={!!spin}
                        color={color}
                        label={submitButtonLabel}
                        type={onSubmit ? 'button' : 'submit'}
                        onClick={onSubmit}
                        disabled={disabled}
                        {...loadingSpinnerButtonProps}
                    />
                )}
            </Stack>
        </DialogActions>
    );
};
