import React from 'react';
import Stack from '@mui/material/Stack';
import { dateFromJSToFormat, timeSentenceFromDate } from '../../../../utils/date';
import { IValueCellProps, ValueCell } from './ValueCell';

interface ITimestampCellProps extends IValueCellProps {
    value: Maybe<Timestamp> | undefined;
}

export const TimestampCell: React.FC<ITimestampCellProps> = ({ value, ...props }) => {
    return (
        <ValueCell {...props}>
            {value && (
                <Stack>
                    <span>
                        <b>{timeSentenceFromDate(value, Number.MAX_VALUE)}</b>
                    </span>
                    <span>{dateFromJSToFormat(new Date(value))}</span>
                </Stack>
            )}
        </ValueCell>
    );
};
