import * as yup from 'yup';
import { VALIDATION_MESSAGES } from '../../../../../../../../../lib/forms';
import { ReviewAction } from '../../../../../../../../../interfaces/model';

export const documentKycDetailsFormValidationSchema = yup.object({
    status: yup.mixed<ReviewAction>().required(VALIDATION_MESSAGES.REQUIRED),
    comment: yup.string().nullable(),
    asAdmin: yup.boolean().nullable(),
    isPrivate: yup.boolean(),
});
