import { HIDE_DIALOG, IDialogStateAction, SHOW_DIALOG } from './dialogState.actions';
import { fromEntries } from '../../lib/utils/helpers';
import { IAppState } from '../rootReducer';

interface IDialogState<T extends object = object> {
    visible: boolean;
    state: T;
}

const removeHiddenDialogs = (state: Dict<IDialogState>): Dict<IDialogState> => {
    return fromEntries(Object.entries(state).filter(([_, entry]) => entry.visible));
};

const initialState: Dict<IDialogState> = {};
export const dialogState = (state = initialState, action: IDialogStateAction) => {
    switch (action.type) {
        case SHOW_DIALOG:
            return {
                ...removeHiddenDialogs(state),
                [action.payload.id]: {
                    state: action.payload.value,
                    visible: true,
                },
            };
        case HIDE_DIALOG:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    visible: false,
                },
            };
        default:
            return state;
    }
};

export const DIALOG_STATE_SELECTOR_FACTORY =
    <T extends object>(id: string) =>
    (state: IAppState) =>
        state.dialogState[id] as IDialogState<T>;
