import React from 'react';
import cl from 'classnames';
import styles from './Tag.module.css';

interface ITagProps {
    color: 'yellow' | 'purple';
    children: React.ReactNode;
}

const COLORS_CLASS_NAMES: Record<ITagProps['color'], string> = {
    yellow: styles.yellow,
    purple: styles.purple,
};

export const Tag: React.FC<ITagProps> = ({ color, children }) => {
    return <div className={cl(styles.tag, COLORS_CLASS_NAMES[color])}>{children}</div>;
};
