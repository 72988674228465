import React, { useContext } from 'react';
import { Fieldset } from '../../../../../../lib/forms/Fieldset';
import { FormikContext } from 'formik';
import { NonFalsy } from '../../../../../../lib/utils/helpers';
import { useUserDetails } from '../../../../../hooks/useUserDetails';
import { SystemAdminCapabilitiesList } from '../../../EditRole/SystemAdminCapabilitiesList/SystemAdminCapabilitiesList';

export const SystemAdminPermissionsEdit: React.FC = () => {
    const formik = useContext(FormikContext);
    const { isSre } = useUserDetails();

    return (
        <Fieldset
            title={'Select System Administrator permissions'}
            description={'Select permissions you want the System Administrator to have'}
        >
            <SystemAdminCapabilitiesList
                value={formik.values.superadminCapabilities?.filter(NonFalsy) || []}
                onChange={(newCapabilities) => formik.setFieldValue('superadminCapabilities', newCapabilities)}
                editable={isSre}
            />
        </Fieldset>
    );
};
