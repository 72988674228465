import React from 'react';
import { Paper } from '@mui/material';
import { MultiSelectList } from '../RoleCapabilitiesList/MultiSelectList';
import { SuperadminCapabilities } from '../../../../../interfaces/model';
import { selectOptionFactory } from '../../../../../lib/inputs/Select.const';
import { superAdminCapabilitiesLabels } from '../../../../App.const';

interface ISystemAdminCapabilitiesListProps {
    value: SuperadminCapabilities[];
    onChange: (value: SuperadminCapabilities[]) => void;
    editable: boolean;
}

const optionFactory = selectOptionFactory(SuperadminCapabilities, superAdminCapabilitiesLabels);
const capabilities = Object.keys(SuperadminCapabilities) as SuperadminCapabilities[];
const SYSTEM_ADMIN_CAPABILITIES_OPTIONS = capabilities.map(optionFactory);

export const SystemAdminCapabilitiesList: React.FC<ISystemAdminCapabilitiesListProps> = ({
    value,
    onChange,
    editable,
}) => {
    return (
        <Paper elevation={0} sx={{ background: (theme) => theme.palette.background.default }}>
            <MultiSelectList
                options={SYSTEM_ADMIN_CAPABILITIES_OPTIONS}
                value={value}
                onChange={onChange}
                editable={editable}
            />
        </Paper>
    );
};
