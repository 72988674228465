import React from 'react';
import cl from 'classnames';
import { AppContainer } from '../../../lib/cdk/AppContainer';
import styles from './AppHeader.module.css';
import { ContainerProps } from '@mui/material';

interface IAppHeaderContainerProps extends ContainerProps {
    className?: string;
}

export const AppHeaderContainer: React.FC<IAppHeaderContainerProps> = ({
    children,
    maxWidth,
    className,
    ...containerProps
}) => {
    return (
        <AppContainer className={cl(styles.headerContainer, className)} maxWidth={maxWidth} {...containerProps}>
            {children}
        </AppContainer>
    );
};
