import { DateTime } from 'luxon';
import DateSchema from 'yup/lib/date';
import { VALIDATION_MESSAGES } from './forms.const';

interface IDateDuration {
    plusDays?: number;
    minusDays?: number;
}

const datePlusMinus = (date: Date | undefined, duration: IDateDuration = {}) => {
    return date instanceof Date
        ? DateTime.fromJSDate(date).plus({ days: duration.plusDays }).minus({ days: duration.minusDays }).toJSDate()
        : void 0;
};

export const VALIDATORS = {
    MAX_DATE:
        (duration: IDateDuration = {}) =>
        (date: Date | undefined, schema: DateSchema) => {
            const maxDateJS = datePlusMinus(date, duration);
            return maxDateJS ? schema.max(maxDateJS, VALIDATION_MESSAGES.MAX_DATE({ max: maxDateJS })) : schema;
        },
    MIN_DATE:
        (duration: IDateDuration = {}) =>
        (date: Date | undefined, schema: DateSchema) => {
            const minDateJS = datePlusMinus(date, duration);
            return minDateJS ? schema.min(minDateJS, VALIDATION_MESSAGES.MIN_DATE({ min: minDateJS })) : schema;
        },
};
