import React from 'react';
import { Redirect, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MENU_ITEMS_SELECTOR } from '../App.selectors';
import isString from 'lodash/isString';
import { isMenuItemPathMatchUrl } from './AppHeader';

export const ProtectedRoute: React.FC<RouteProps> = (props) => {
    const menuItems = useSelector(MENU_ITEMS_SELECTOR);
    const appRoutes = menuItems.map(({ url }) => url);
    const path = props.path;

    if (!isString(path)) {
        return null;
    }

    const isRouteAllowed =
        appRoutes.length > 0 &&
        appRoutes.some((url) => {
            return isMenuItemPathMatchUrl(url, path);
        });
    return isRouteAllowed ? <Route {...props} /> : <Redirect to={'/'} />;
};
