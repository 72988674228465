import React from 'react';
import { Paper } from '@mui/material';
import styles from '../ViewEntity.module.css';
import { agentCapabilitiesLabels } from '../../../../App.const';
import { EmptyStatePlaceholder } from '../../../../common/EmptyStatePlaceholder/EmptyStatePlaceholder';
import { AgentCapabilities } from '../../../../../interfaces/model';

interface IAgentCapabilitiesListProps {
    capabilities: AgentCapabilities[];
}

export const AgentCapabilitiesList: React.FC<IAgentCapabilitiesListProps> = ({ capabilities }) => {
    return (
        <>
            {capabilities.length > 0 ? (
                <>
                    <p>Agent permissions</p>
                    <Paper elevation={0} className={styles.capabilitiesContainer}>
                        {capabilities.map((item) => (
                            <span key={item} className={styles.capabilityLabel}>
                                {agentCapabilitiesLabels[item]}
                            </span>
                        ))}
                    </Paper>
                </>
            ) : (
                <EmptyStatePlaceholder elevation={0} title={'This agent has no permissions'} />
            )}
        </>
    );
};
