import cl from 'classnames';
import React, { useContext } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from './TreeFolder.module.css';
import { TreeContext } from '../Tree';
import { Collapse } from '@mui/material';
import { TreeItem } from '../TreeItem';

export const TreeFolder: React.FC<{
    id: string;
    label: string;
    offset: number;
    className?: string;
    children: React.ReactNode;
}> = ({ id, label, className, children, offset }) => {
    const parent = useContext(TreeContext);
    const isExpanded = parent.isExpanded(id);

    return (
        <div className={cl(styles.folder, className)}>
            <TreeItem offset={offset} onClick={() => parent.toggleExpand(id)}>
                <div className={styles.iconsWrap}>
                    <ArrowDropDownIcon className={cl(styles.folderExpandIcon, isExpanded && styles.expanded)} />
                    <FolderIcon color={'warning'} className={styles.folderIcon} />
                </div>
                <span>{label}</span>
            </TreeItem>
            <Collapse in={isExpanded} className={styles.contentWrap}>
                {children}
            </Collapse>
        </div>
    );
};
