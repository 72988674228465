import React from 'react';
import { Link } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { TableRow } from '@mui/material';
import { RoleDeleteIconButton } from '../../../../../../Entities/ViewEntity/EntityRoles/RoleList/RoleListRow/RoleDeleteIconButton';
import { ListIconButton } from '../../../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { ActionsCell, ValueCell } from '../../../../../../../../lib/dataDisplay/List/components/ListCells';
import { peopleListColors, peopleListLabels, PeopleListStatus } from './PeopleList.const';
import { useRoleEditDialog } from '../../../../../../Roles/RoleEditDialog';
import { RoleListRowFragment } from '../../../../../../../../interfaces/model';
import { useUserDetails } from '../../../../../../../hooks/useUserDetails';
import { CLIENT_ONBOARDING_ROUTES, ROLE_VIEW_ROUTE_FACTORY } from '../../../../../../../App.routes';
import { IListRow } from '../../../../../../../../lib/dataDisplay/List';
import { RoleName } from '../../../../../../Roles/RoleName';

export interface IPeopleListItem {
    id: string;
    topCoRef: string;
    name: string;
    jobTitle: string;
    status: PeopleListStatus;
    role: RoleListRowFragment;
    actions?: never;
}

type IComponentProps = {
    reloadData: () => void;
    noPreview?: boolean;
};

type IRoleListRowProps = IListRow<IPeopleListItem, IComponentProps>;

export const PeopleListRow: React.FC<IRoleListRowProps> = ({ data, columns, props }) => {
    const { role } = useUserDetails();
    const [roleEditDialog] = useRoleEditDialog();
    const previewRoute = props.noPreview
        ? ROLE_VIEW_ROUTE_FACTORY(data.role.shortCode!)
        : CLIENT_ONBOARDING_ROUTES.PEOPLE_PREVIEW_ROUTE_FACTORY(data.role.shortCode!);

    return (
        <TableRow component={Link} to={previewRoute} hover>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'name' && (
                        <ValueCell nowrap={false}>
                            <RoleName value={data.role} />
                        </ValueCell>
                    )}
                    {id === 'jobTitle' && <ValueCell>{data.jobTitle}</ValueCell>}
                    {id === 'status' && (
                        <ValueCell color={peopleListColors[data.status]}>{peopleListLabels[data.status]}</ValueCell>
                    )}
                    {id === 'actions' && (
                        <ActionsCell
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            {data.role.hasEditAccess && (
                                <>
                                    <ListIconButton
                                        icon={Edit}
                                        onClick={() =>
                                            roleEditDialog.open({
                                                roleId: data.id,
                                                roleName: data.name,
                                                topCoRef: data.topCoRef,
                                            })
                                        }
                                    />
                                    <RoleDeleteIconButton
                                        role={data.role}
                                        onSuccess={props.reloadData}
                                        disabled={role && role.id === data.id}
                                    />
                                </>
                            )}
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
