import omit from 'lodash/omit';
import React, { useContext, useMemo } from 'react';
import { Datepicker, IDatepickerProps } from '../Datepicker';
import styles from './BusinessCalendarDatePicker.module.css';
import { FormikContext } from 'formik';
import { DateTime } from 'luxon';
import cl from 'classnames';
import set from 'lodash/set';
import get from 'lodash/get';
import { Tooltip } from '@mui/material';
import { getMessageFromApolloError, NOOP } from '../../utils/helpers';
import { DayOfWeek } from '../../../interfaces/model';
import { Spinner } from '../../cdk/Spinner';
import { ApolloError } from '@apollo/client';
import { PickersDay } from '@mui/x-date-pickers';
import WarningIcon from '@mui/icons-material/Warning';
import InputAdornment from '@mui/material/InputAdornment';
import { NonWorkingDayDataFragment, useHolidaysQuery } from './Holidays.query';
import { IconWithTooltip } from '../../icons/IconWithTooltip';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay';
import { dateToModelValue, fromDateISO } from '../../utils/date';

interface IWithCalendarTypeProp {
    calendarType: MaybeUndefined<string[]>;
}

interface IWithCalendarTypeProp {
    calendarType: MaybeUndefined<string[]>;
}

interface IWithExternalDataProp {
    nonWorkingDayData: MaybeUndefined<NonWorkingDayDataFragment>;
}

export type IBusinessCalendarDatePickerProps = (
    | IWithCalendarTypeProp
    | IWithExternalDataProp
    | (IWithCalendarTypeProp & IWithExternalDataProp)
) & {
    loading?: boolean;
    error?: ApolloError;
} & Omit<IDatepickerProps, 'error'>;

export const BusinessCalendarDatePicker: React.FC<IBusinessCalendarDatePickerProps> = ({
    name,
    loading: loadingProp,
    error: errorProp,
    shouldDisableDate = NOOP,
    ...props
}) => {
    const formik = useContext(FormikContext);
    const externalData = 'nonWorkingDayData' in props ? props.nonWorkingDayData : void 0;
    const calendarType = 'calendarType' in props ? props.calendarType : void 0;

    const {
        data: holidayData,
        loading,
        error,
    } = useHolidaysQuery({
        fetchPolicy: 'cache-first',
        skip: !!externalData || !calendarType,
        variables: { calendarType: calendarType! },
    });

    const isLoading = loading || loadingProp;
    const isError = error || errorProp;

    const nationalHolidaysMap = useMemo(
        () =>
            (externalData?.holidays || holidayData?.nationalHolidays.holidays || [])
                .map((value) => {
                    const date = DateTime.fromISO(value.date);
                    return {
                        date: [date.year, date.month - 1, date.day],
                        description: value.description,
                    };
                })
                .reduce((acc, { date, description }) => {
                    const [year, month, day] = date;
                    set(acc, [year, month, day], description);
                    return acc;
                }, {}),
        [holidayData?.nationalHolidays.holidays, externalData?.holidays]
    );

    const isNationalHoliday = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        return !!get(nationalHolidaysMap, [year, month, day], false);
    };

    const getHolidayDescription = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        return get(nationalHolidaysMap, [year, month, day], 'Bank Holiday');
    };

    const isNotWorkingWeekend = (date: Date) => {
        const dayName = date.toLocaleDateString('en-EN', { weekday: 'long' }).toUpperCase();
        const weekendDays = externalData?.weekends || holidayData?.nationalHolidays?.weekends || [];
        const workingWeekendDays =
            externalData?.workingWeekends || holidayData?.nationalHolidays?.workingWeekends || [];
        return weekendDays.includes(dayName as DayOfWeek) && !workingWeekendDays.includes(dateToModelValue(date)!);
    };

    const renderDay = (day: DateTime, selectedDays: DateTime[], pickersDayProps: PickersDayProps<DateTime>) => {
        if (!pickersDayProps.outsideCurrentMonth && pickersDayProps.disabled && isNationalHoliday(day.toJSDate())) {
            const title = getHolidayDescription(day.toJSDate());
            return (
                <Tooltip title={title} key={pickersDayProps.key}>
                    <span className={cl(styles.disabledDateTooltip, styles.holidayPointContainer)}>
                        <PickersDay {...pickersDayProps} disabled={false} onDaySelect={NOOP} />
                        {title !== 'Transfer lockout' && title !== 'Record date lockout' && (
                            <div className={styles.holidayPoint} />
                        )}
                    </span>
                </Tooltip>
            );
        }
        return <PickersDay {...pickersDayProps} />;
    };

    return (
        <Datepicker
            name={name}
            {...omit(props, ['calendarType', 'nonWorkingDayData'])}
            minDate={fromDateISO(externalData?.min) || props.minDate}
            maxDate={fromDateISO(externalData?.max) || props.maxDate}
            shouldDisableDate={(date) =>
                shouldDisableDate(date) || isNationalHoliday(date) || isNotWorkingWeekend(date)
            }
            disabled={props.disabled || formik?.isSubmitting || isLoading}
            renderDay={renderDay}
            InputProps={
                isLoading || isError
                    ? {
                          endAdornment: (
                              <InputAdornment position="end" sx={{ marginRight: '-8px' }}>
                                  {isLoading && <Spinner size={24} />}
                                  {!isLoading && isError && (
                                      <IconWithTooltip
                                          text={getMessageFromApolloError(error)?.comment || 'Unhandled error'}
                                          Icon={WarningIcon}
                                          color={'error'}
                                      />
                                  )}
                              </InputAdornment>
                          ),
                      }
                    : void 0
            }
            fullWidth
        />
    );
};
