import React from 'react';
import groupBy from 'lodash/groupBy';
import { Paper } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { DocumentUploadDialog, IDocumentUploadDialogConfig } from '../components/DocumentUploadDialog';
import { DocumentsViewDialog, IDocumentsViewDialogConfig } from '../components/DocumentsViewDialog';
import { DocPurpose, KYCDocCategory, KYCDocumentFragment } from '../../../../interfaces/model';
import { NonApplicableDocumentDialog } from '../components/CreateNonApplicableDocumentDialog';
import { LoadingContentWrap } from '../../../../lib/dataDisplay/LoadingContentWrap';
import { useDocumentViewDialogOpenEffect } from './useDocumentViewDialogOpenEffect';
import { IEmptyStatePlaceholderProps } from '../../../common/EmptyStatePlaceholder';
import { IDocumentsListParent } from '../interfaces/IDocumentsListParent';
import { AddDocumentsDialog } from '../components/AddDocumentsDialog';
import { AppEmbeddedListWrap } from '../../AppEmbeddedListWrap';
import { DocumentCategoryList } from './DocumentCategoryList';
import { kycDocCategoryLabels } from '../../../App.const';

interface IDocumentsListConfig extends IDocumentsViewDialogConfig, IDocumentUploadDialogConfig {}

interface IDocumentsListProps {
    value: KYCDocumentFragment[];
    parent: IDocumentsListParent;
    emptyStatePlaceholderProps?: IEmptyStatePlaceholderProps;
    config: IDocumentsListConfig;
    error?: ApolloError;
    loading?: boolean;
}

const groupDocuments = (documents: KYCDocumentFragment[]): [KYCDocCategory, KYCDocumentFragment[]][] => {
    const groupedDocuments = groupBy(documents, 'docCategory');
    return Object.values(groupedDocuments).map((group) => {
        const groupCategory = group[0].docCategory;
        return [groupCategory, group];
    });
};

export const DocumentsList: React.FC<IDocumentsListProps> = ({
    value,
    parent,
    loading,
    config,
    emptyStatePlaceholderProps,
    error,
}) => {
    const documents = groupDocuments(value);

    useDocumentViewDialogOpenEffect(parent);

    return (
        <>
            <div data-testid={'KYCList'}>
                {error || loading ? (
                    <AppEmbeddedListWrap title={'Documents'}>
                        <Paper>
                            <LoadingContentWrap loading={loading} error={error} />
                        </Paper>
                    </AppEmbeddedListWrap>
                ) : (
                    documents.map(([category, data]) => (
                        <AppEmbeddedListWrap title={kycDocCategoryLabels[category!]} key={category}>
                            <DocumentCategoryList
                                data={data}
                                parent={parent}
                                emptyStatePlaceholderProps={emptyStatePlaceholderProps}
                                showKycColumns={config.purpose === DocPurpose.KYC}
                            />
                        </AppEmbeddedListWrap>
                    ))
                )}
            </div>
            <DocumentsViewDialog config={config} updateData={parent.update} />
            <DocumentUploadDialog config={config} updateData={parent.update} />
            <NonApplicableDocumentDialog onSaveSuccess={parent.update} />
            <AddDocumentsDialog updateData={parent.update} />
        </>
    );
};
