import { v4 as uuid } from 'uuid';
import PendingIcon from '@mui/icons-material/Pending';
import { SvgIconComponent } from '@mui/icons-material';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    DocPurpose,
    DocumentInInput,
    IdType,
    KYCDocSubcategory,
    KYCDocType,
    KYCDocumentFragment,
    KYCDocumentParentFragment,
    ReviewAction,
    ReviewStatus,
} from '../../../interfaces/model';
import { selectOptionFactory } from '../../../lib/inputs/Select.const';
import { kycDocSubcategoryLabels } from '../../App.const';
import { fromDateISO } from '../../../lib/utils/date';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IDocumentsListParent } from './interfaces/IDocumentsListParent';
import { IDocumentViewDialogParent } from './components/DocumentsViewDialog/interfaces';
import {
    FACILITY_VIEW_ROUTE_FACTORY,
    FUND_VIEW_ROUTE_FACTORY,
    getEntityViewRouteByType,
    ROLE_VIEW_ROUTE_FACTORY,
} from '../../App.routes';

export const DOCUMENTS_WITH_EXPIRY_DATE = [
    KYCDocType.CERTIFICATE_OF_RESIDENCY,
    KYCDocType.TAX_ID_FORM,
    KYCDocType.W8_W9_BEN_FORM,
    KYCDocType.FINANCIALS,
    KYCDocType.MARKETING,
];

export const DOCUMENTS_WITH_PRIVATE_ACCESS_CONFIGURABLE = [
    KYCDocType.CREDIT_DOCUMENTS,
    KYCDocType.FINANCIALS,
    KYCDocType.MARKETING,
    KYCDocType.RATINGS,
];

export const SUBCATEGORIES_MAP: Record<KYCDocType, KYCDocSubcategory[]> = {
    CREDIT_DOCUMENTS: [
        KYCDocSubcategory.CREDIT_AGREEMENT,
        KYCDocSubcategory.AMENDED_CREDIT_AGREEMENT,
        KYCDocSubcategory.AMENDMENT_DOCUMENTATION,
        KYCDocSubcategory.SENIOR_FACILITIES_AGREEMENT,
        KYCDocSubcategory.LETTER_OF_CREDIT,
        KYCDocSubcategory.COLLATERAL_AGREEMENTS,
        KYCDocSubcategory.SECURITY_AGREEMENT,
        KYCDocSubcategory.DEPOSIT_ACCOUNT_CONTROL_AGREEMENT,
        KYCDocSubcategory.UCC_1_FILING,
        KYCDocSubcategory.GUARANTEE_AND_COLLATERAL_AGREEMENT,
        KYCDocSubcategory.SECURITY_ACCOUNT_CONTROL_AGREEMENT,
        KYCDocSubcategory.MORTGAGE_DOCUMENTATION,
        KYCDocSubcategory.CONDITIONS_PRECEDENT,
        KYCDocSubcategory.CONDITIONS_SUBSEQUENT,
        KYCDocSubcategory.CONSENT_REQUEST_LETTER,
        KYCDocSubcategory.SUMMARY_OF_AMENDMENTS,
        KYCDocSubcategory.AMENDMENT_AND_RESTATEMENT_AGREEMENT,
        KYCDocSubcategory.AMENDED_AND_RESTATED_AGREEMENT,
        KYCDocSubcategory.VOTING_SUMMARY_DOCUMENTS,
    ],
    FINANCIALS: [
        KYCDocSubcategory.AUDITED_ANNUAL_FINANCIALS,
        KYCDocSubcategory.SEMI_ANNUAL_FINANCIALS,
        KYCDocSubcategory.MONTHLY_FINANCIALS,
        KYCDocSubcategory.QUARTERLY_FINANCIALS,
        KYCDocSubcategory.COMPLIANCE_CERTIFICATE,
        KYCDocSubcategory.INFORMATION_UNDERTAKINGS,
        KYCDocSubcategory.UTILISATION_REQUEST,
        KYCDocSubcategory.BUDGET,
    ],
    MARKETING: [KYCDocSubcategory.TERMSHEET, KYCDocSubcategory.MANAGEMENT_PRESENTATION],
    RATINGS: [KYCDocSubcategory.FITCH, KYCDocSubcategory.MOODYS, KYCDocSubcategory.SANDP],
    OTHER: [KYCDocSubcategory.OTHER],
    ARTICLES_OF_ASSOCIATION: [],
    CERTIFICATE_OF_INCORP_AOA_MOA: [],
    DIRECTORS_ID_DOCS: [],
    CERTIFICATE_OF_RESIDENCY: [],
    CLO_COLLATERAL_MANAGEMENT_AGREEMENT_OR_WAREHOUSE_DEEDS: [],
    CLO_PROSPECTUS: [],
    ESIG: [],
    INVESTMENT_MANAGEMENT_AGREEMENT: [],
    TAX_ID_FORM: [],
    MEDIA_CHECKS: [],
    PEP: [],
    PSC_PASSPORTS: [],
    SANCTIONS: [],
    SIGNATORY_LIST: [],
    STRUCTURE_CHART: [],
    UBO: [],
    W8_W9_BEN_FORM: [],
    AML_COMFORT_LETTER: [],
    AUTHORISED_SIGNATORY_ID: [],
    EVIDENCE_OF_REGULATORY_STATUS: [],
    PROOF_OF_ADDRESS: [],
    SOURCE_OF_FUNDS_DECLARATION: [],
};

export const isDocTypeWithSubcategory = (type: Maybe<KYCDocType> | undefined): boolean =>
    !!type && SUBCATEGORIES_MAP[type].length > 0;

export const reviewStatusIcons: Record<ReviewStatus, SvgIconComponent> = {
    GREEN: CheckCircleOutlineIcon,
    RED: DoDisturbIcon,
    UNDER_REVIEW: PendingIcon,
    NO_REVIEWERS: PendingIcon,
};

export const reviewActionIcons: Record<ReviewAction, SvgIconComponent> = {
    UPLOADED: CheckCircleOutlineIcon,
    COMMENT: CheckCircleOutlineIcon,
    GREEN: CheckCircleOutlineIcon,
    RED: DoDisturbIcon,
    AMBER: PendingIcon,
    DELETED: HighlightOffIcon,
    REPLACED: PublishedWithChangesIcon,
    RESTORED: PublishedWithChangesIcon,
};

export const reviewStatusColors: Record<ReviewStatus, 'warning' | 'error' | 'success'> = {
    GREEN: 'success',
    RED: 'error',
    UNDER_REVIEW: 'warning',
    NO_REVIEWERS: 'warning',
};

export const reviewStatusLabels: Record<ReviewStatus, string> = {
    GREEN: 'Approved',
    RED: 'Rejected',
    UNDER_REVIEW: 'Under Review',
    NO_REVIEWERS: 'Under Review (no reviewers)',
};

export function formatKycReviewStatus(
    status: { status: ReviewStatus; byAdmin: boolean } | null,
    uploaded: boolean
): string {
    return uploaded ? reviewStatusLabels[status?.status!] + (status?.byAdmin ? ' (by admin)' : '') : 'Not uploaded';
}

export const reviewActionColors: Record<ReviewAction, 'warning' | 'error' | 'success' | 'info'> = {
    UPLOADED: 'info',
    COMMENT: 'info',
    GREEN: 'success',
    RED: 'error',
    AMBER: 'warning',
    DELETED: 'error',
    REPLACED: 'info',
    RESTORED: 'info',
};

export const reviewActionLabels: Record<ReviewAction, string> = {
    GREEN: 'Approved',
    COMMENT: 'Commented',
    RED: 'Rejected',
    AMBER: 'Under Review',
    UPLOADED: 'Uploaded',
    DELETED: 'Deleted',
    REPLACED: 'Replaced',
    RESTORED: 'Restored',
};

export const docPurposeLabels: Record<DocPurpose, string> = {
    FACILITY: 'Facility',
    KYC: 'KYC',
    OTHER: 'Other',
};

const kycReviewStatusOptionFactory = selectOptionFactory(ReviewAction, reviewActionLabels);
export const kycReviewStatusOptions = [
    kycReviewStatusOptionFactory(ReviewAction.AMBER),
    kycReviewStatusOptionFactory(ReviewAction.RED),
    kycReviewStatusOptionFactory(ReviewAction.GREEN),
];

export const documentsSubcategoryOptionsFactory = (subcategories: KYCDocSubcategory[]) => {
    const factory = selectOptionFactory(KYCDocSubcategory, kycDocSubcategoryLabels);
    return subcategories.map(factory);
};

export const getYearFromDate = (date: Date | Maybe<string> | undefined) => fromDateISO(date)?.getFullYear().toString();

export const getDocumentApprovalCounters = (status: KYCDocumentFragment['status'] | undefined) => {
    const agentsApproved = (status?.approvedAgentRefs || []).length;
    const agentsRejected = (status?.rejectedAgentRefs || []).length;
    const agentsPending = (status?.underReviewAgentRefs || []).length;

    return {
        agentsApproved,
        agentsRejected,
        agentsPending,
        total: agentsRejected + agentsApproved + agentsPending,
    };
};

export const mapDocumentToFragment = (value: DocumentInInput | undefined): Partial<KYCDocumentFragment> | undefined => {
    return (
        value && {
            id: value.id || '',
            name: value.name || void 0,
            docType: value.docType || void 0,
            docSubcategory: value.docSubcategory || void 0,
            isPrivate: value.isPrivate || void 0,
            validFromDate: value.validFromDate,
            validToDate: value.validToDate,
            naReason: value.naReason,
            comment: value.comment,
            status: null,
            uploaded: true,
            documents:
                value.contentFiles?.map(({ fileName }) => ({
                    documentId: uuid(),
                    displayName: fileName || '',
                })) || [],
        }
    );
};

export const isDocumentNotApplicable = ({ documents = [], uploaded }: { documents: object[]; uploaded: boolean }) =>
    uploaded && (!documents || documents.length === 0);

export const getDocumentFileName = (documents: { displayName: Maybe<string> }[]) => {
    return documents[0]?.displayName + (documents?.length > 1 ? ` ${documents.length} files` : '');
};

/**
 * @deprecated
 * @TODO Get rid of two parent interfaces - use only one and get rid of this helper
 */
export const mapListParentToDialogParent = (parent: IDocumentsListParent): IDocumentViewDialogParent | undefined => {
    if (!parent.id || !parent.name) {
        return;
    }

    return {
        id: parent.id,
        name: parent.name,
        type: parent.type,
        topCoRef: parent.topCoRef,
        hasDocumentEditAccess: !!parent.hasDocumentEditAccess,
        hasDocumentApproveAccess: !!parent.hasDocumentApproveAccess,
    };
};

export const getViewRouteFactoryByIdType = (
    documentParent: KYCDocumentParentFragment
): ((sc: string | undefined) => string | null) => {
    const type = documentParent.idType;
    switch (type) {
        case IdType.ENTITY:
            return getEntityViewRouteByType(documentParent.entity?.entityType);
        case IdType.FACILITY:
            return (sc: string | undefined) => FACILITY_VIEW_ROUTE_FACTORY(sc, null);
        case IdType.ROLE:
            return ROLE_VIEW_ROUTE_FACTORY;
        case IdType.LOAN_FUND:
            return FUND_VIEW_ROUTE_FACTORY;
        case IdType.VOTING:
            return () => null;
        default:
            throw new Error(`No view route for ${type}`);
    }
};
