import { EntityType, useRelatedEntitiesQuery } from '../../../../../interfaces/model';
import { ROLE_ENTITY_TYPES_MAP } from '../../Roles.const';

export const useRelatedEntitiesList = (
    topCoRef: Maybe<string> | undefined,
    entityType: Maybe<EntityType> | undefined
) => {
    const entityTypes = ROLE_ENTITY_TYPES_MAP[entityType!];
    return useRelatedEntitiesQuery({
        fetchPolicy: 'cache-and-network',
        skip: !topCoRef || !entityTypes,
        variables: {
            topCoRef: topCoRef!,
            entityTypes,
        },
    });
};
