import React, { useContext, useState } from 'react';
import { DoubleValueCell } from '../../../../../lib/dataDisplay/List/components/ListCells/DoubleValueCell';
import {
    ActionsCell,
    CheckboxCell,
    DateValueCell,
    ValueCell,
} from '../../../../../lib/dataDisplay/List/components/ListCells';
import { getDocumentFileName, getViewRouteFactoryByIdType } from '../../Documents.const';
import { kycDocSubcategoryLabels, kycDocTypeLabels } from '../../../../App.const';
import { ListRow } from '../../../../../lib/dataDisplay/List/components/ListRow';
import { IDocumentViewDialogParent } from '../DocumentsViewDialog/interfaces';
import { CollapseTrigger } from '../../../../../lib/buttons/CollapseTrigger';
import { DocumentDownloadIconButton } from '../DocumentDownloadIconButton';
import { CollapsibleRow } from '../../../../../lib/legacy/CollapsibleRow';
import { DocumentStorage, KYCDocumentFragment } from '../../../../../interfaces/model';
import { ParentContext } from '../../../../../lib/cdk/ParentContext';
import { IGqlListRow } from '../../../../../lib/dataDisplay/List';
import { DocumentFileIconCell } from '../DocumentFileIconCell';
import { DocumentSharesList } from './DocumentSharesList';
import { LinkWithIcon } from 'lib/navigation/LinkWithIcon';

export interface IDocumentsSelectListRowItem extends KYCDocumentFragment {
    icon?: never;
}

type IDocumentsSelectListRowProps = IGqlListRow<
    IDocumentsSelectListRowItem,
    {
        selected: KYCDocumentFragment[];
        onSelect: (selected: KYCDocumentFragment[]) => void;
        parent: IDocumentViewDialogParent | undefined;
        updateData: () => Promise<void>;
        multiselect: boolean;
        disabled?: boolean;
    }
>;

const getDocumentSharesCount = (item: IDocumentsSelectListRowItem) => {
    const count = (item.shares || []).length;
    return count === 0 ? 1 : count;
};

const getSharesCount = (item: IDocumentsSelectListRowItem, topCoRef: UUID | undefined) => {
    const shares = item.shares || [];
    const singleLocation = shares.length === 1;
    const parentRef = item?.parent.id;

    if (singleLocation && topCoRef && parentRef === topCoRef && item.storage === DocumentStorage.DATA_ROOM) {
        return <span className={'secondary body2'}>Unshared</span>;
    } else if (singleLocation) {
        const parent = shares[0].parent;
        const parentModel = parent.entity || parent.role || parent.loanFund || parent.facility;
        const route = parentModel && getViewRouteFactoryByIdType(parent)(parentModel?.shortCode!);
        if (route) {
            return <LinkWithIcon to={route}>{parentModel.name}</LinkWithIcon>;
        }
    }

    const count = getDocumentSharesCount(item);
    return <span className={'body2'}>{count} locations</span>;
};

export const DocumentsSelectListRow: React.FC<IDocumentsSelectListRowProps> = ({
    item,
    props,
    columns,
    highlightText,
}) => {
    const parent = useContext(ParentContext);
    const [isExpanded, setExpanded] = useState(false);
    const isSelected = props.selected.map(({ id }) => id).includes(item.id);
    const multiselect = props.multiselect;

    const toggleSelection = () => {
        if (isSelected) {
            props.onSelect(props.selected.filter(({ id }) => id !== item.id));
        } else {
            props.onSelect([...props.selected, item]);
        }
    };

    const isDisabled = !isSelected && (props.disabled || (!multiselect && props.selected.length === 1));

    return (
        <>
            <ListRow focused={isExpanded || isSelected}>
                {columns.map(({ id }) => (
                    <React.Fragment key={id}>
                        {id === 'id' && (
                            <CheckboxCell value={isSelected} onChange={toggleSelection} disabled={isDisabled} />
                        )}
                        {id === 'icon' && <DocumentFileIconCell />}
                        {id === 'name' && (
                            <DoubleValueCell
                                main={item.name}
                                alternative={getDocumentFileName(item.documents)}
                                alternativeClassName={'secondary'}
                                highlightMain={highlightText}
                                width={'25%'}
                                nowrap={false}
                                breakAll
                            />
                        )}
                        {id === 'docType' && (
                            <DoubleValueCell
                                main={kycDocTypeLabels[item.docType]}
                                alternative={kycDocSubcategoryLabels[item.docSubcategory!]}
                                alternativeSuffix={'/ '}
                                nowrap={false}
                            />
                        )}
                        {id === 'shares' && (
                            <ValueCell nowrap={false}>{getSharesCount(item, props.parent?.topCoRef)}</ValueCell>
                        )}
                        {id === 'updatedAt' && <DateValueCell value={item.updatedAt} />}
                        {id === 'actions' && (
                            <ActionsCell align={'right'}>
                                {item.id && <DocumentDownloadIconButton documentId={item.id} />}
                                <CollapseTrigger isActive={isExpanded} onClick={() => setExpanded(!isExpanded)} />
                            </ActionsCell>
                        )}
                    </React.Fragment>
                ))}
            </ListRow>
            <CollapsibleRow expand={isExpanded} colspan={columns.length} dark>
                <DocumentSharesList
                    documentId={item.id}
                    disabled={props.disabled}
                    updateData={async () => {
                        await parent.update();
                        await props.updateData();
                        props.onSelect(props.selected.filter(({ id }) => id !== item.id));
                        setExpanded(false);
                    }}
                />
            </CollapsibleRow>
        </>
    );
};
