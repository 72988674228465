import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { appHideSnackbar } from '../../App.actions';
import { APP_SNACKBAR_CONFIG_SELECTOR } from '../../App.selectors';
import { MessageWithTraceID } from './MessageWithTraceID';
import AlertTitle from '@mui/material/AlertTitle';

export const AppNotification: React.FC = () => {
    const { traceId, closeButton = true, ...config } = useSelector(APP_SNACKBAR_CONFIG_SELECTOR) || {};
    const dispatch = useDispatch();
    const message = config?.message || config?.children;

    return (
        <Snackbar
            open={config?.open}
            autoHideDuration={config?.autoHideDuration || 6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            {...config}
            onClose={(...args) => {
                config?.onClose && config.onClose(...args);
                dispatch(appHideSnackbar());
            }}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={closeButton ? () => dispatch(appHideSnackbar()) : void 0}
                severity={config?.severity}
                action={config?.action}
                closeText={config?.closeText}
                color={config?.color}
                icon={config?.icon}
            >
                {config.title && <AlertTitle>{config?.title}</AlertTitle>}
                {traceId && <MessageWithTraceID message={message} traceId={traceId} />}
                {!traceId && message}
            </MuiAlert>
        </Snackbar>
    );
};
