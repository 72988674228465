import { ILegalEntitiesListItem } from './LegalEntitiesListRow';
import { ChildEntity } from '../../LegalEntities.helpers';
import { ENTITY_TYPE_LABELS } from '../../../../../../App.const';

export function prepareLegalEntitiesListItem(entity: ChildEntity): ILegalEntitiesListItem {
    return {
        id: entity.id,
        name: entity.name,
        jurisdiction: entity.jurisdiction!,
        lenderType: ENTITY_TYPE_LABELS[entity.entityType],
        sec: entity.secRegistered,
        fca: entity.fcaRegistered,
        kyc: entity.kycComplete,
        entity,
    };
}
