import React from 'react';
import cl from 'classnames';
import TableCell from '@mui/material/TableCell';
import { TableSortLabel } from '@mui/material';

export type SortOrder = 'asc' | 'desc';

interface ISortableCellProps {
    isActive: boolean;
    order: SortOrder;
    onSort: () => void;
    className?: string;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    children?: React.ReactNode;
}

export const SortableCell: React.FC<ISortableCellProps> = ({ isActive, order, onSort, children, className, align }) => {
    return (
        <TableCell sortDirection={isActive && order} align={align} className={cl(className)}>
            <TableSortLabel active={isActive} direction={isActive ? order : 'asc'} onClick={onSort}>
                {children}
            </TableSortLabel>
        </TableCell>
    );
};
