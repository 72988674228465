import React, { useRef } from 'react';
import { makeDialogState } from '../../../../store/dialogState/makeDialogState';
import { FormikWizard, IFormWizardStep } from '../../../../lib/cdk/Wizard/FormikWizard';
import { FundKeyInformationStep } from './steps/FundKeyInformationStep';
import { NOOP } from '../../../../lib/utils/helpers';
import { useFundEditFormik } from './useFundEditFormik/useFundEditFormik';
import { FormikProvider } from 'formik';
import { Dialog, DialogTitle } from '@mui/material';
import { DialogFormComponent } from '../../../../lib/forms';
import { DialogCloseButton } from '../../../../lib/modals/DialogCloseButton';
import {
    KYCCategoriesTypesFragment,
    useFundDocumentsTypesQuery,
    useFundKeyInformationQuery,
} from '../../../../interfaces/model';
import { FundDocumentsStep } from './steps/FundDocumentsStep';
import { IEditFundDialogState } from './interfaces/IEditFundDialogState';
import { FundTaxInformationStep } from './steps/FundTaxInformationStep';
import { FundBankAccountsStep } from './steps/FundBankAccountsStep';
import { useDirtyFormikConfirmation } from '../../../../lib/forms/useDirtyFormikConfirmation';

interface IFundCreateWizardProps {
    onSaveSuccess?: () => void;
    onClose?: () => void;
}

export const useFundWizardDialog = makeDialogState<IEditFundDialogState>();

const getSteps = (
    formik: ReturnType<typeof useFundEditFormik>,
    kycRequirements: KYCCategoriesTypesFragment[] | undefined = [],
    topCoRef: UUID | undefined
): IFormWizardStep[] => {
    const firstStepValid =
        Object.keys(formik.errors).filter((field) => !['documents', 'taxIds', 'bankAccounts'].includes(field))
            .length === 0;
    return [
        { name: 'Key information', renderContent: () => <FundKeyInformationStep />, valid: firstStepValid },
        {
            name: 'Documents',
            renderContent: () => <FundDocumentsStep topCoRef={topCoRef} requirements={kycRequirements} />,
            valid: true,
        },
        {
            name: 'Tax ID Forms',
            renderContent: () => <FundTaxInformationStep />,
            valid: !formik.errors.taxIds,
        },
        {
            name: 'Bank accounts',
            renderContent: () => <FundBankAccountsStep />,
            valid: !formik.errors.bankAccounts,
        },
    ];
};

export const CreateFundWizard: React.FC<IFundCreateWizardProps> = ({ onSaveSuccess = NOOP, onClose = NOOP }) => {
    const [{ close }, isOpened, state] = useFundWizardDialog();
    const kycRequirements = useRef<KYCCategoriesTypesFragment[]>([]);

    const { data, error } = useFundKeyInformationQuery({
        fetchPolicy: 'no-cache',
        skip: !state?.shortCode || !isOpened,
        variables: {
            fundId: state?.shortCode!,
        },
    });

    const formik = useFundEditFormik({
        ...state,
        fund: data?.loanfund || void 0,
        onSaveSuccess() {
            onSaveSuccess();
            close();
        },
    });

    const fundType = formik.values.type;
    useFundDocumentsTypesQuery({
        fetchPolicy: 'cache-and-network',
        skip: !fundType || !isOpened,
        variables: { type: fundType! },
        onCompleted({ fundKYC }) {
            kycRequirements.current = fundKYC;
        },
    });

    const clearAndClose = useDirtyFormikConfirmation(formik, {
        title: `You about to cancel loan fund ${formik.values.id ? 'edit' : 'create'}`,
        body: 'All edits will be lost after closing. Are you sure?',
        onConfirm: async () => {
            close();
            onClose();
        },
    });

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={isOpened}
                maxWidth={'md'}
                onClose={clearAndClose}
                PaperComponent={DialogFormComponent}
                fullWidth
            >
                <DialogTitle>
                    {state?.shortCode ? 'Edit' : 'New'} loan fund: {formik.values.name}
                    <DialogCloseButton onClick={clearAndClose} />
                </DialogTitle>
                <FormikWizard
                    maxWidth={'sm'}
                    steps={getSteps(formik, kycRequirements.current, data?.loanfund?.topCoRef)}
                    onCancel={clearAndClose}
                    error={error}
                    disableGutters
                />
            </Dialog>
        </FormikProvider>
    );
};
