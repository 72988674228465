import { IEntityCounterpartyKYCListRowItem } from './FundEntityCounterpartyKYCListRow';
import { FundEntityCounterpartyKYCListEntityFragment } from './FundEntityCounterpartyKYCList.query';
import { flattenCounterpartyKYCChildren } from '../../../../Counterparties/CounterpartyKYC/CounterpartyKYCList/flattenCounterpartyKYCChildren';
import { getEntityOrFundRoute } from '../../../../Counterparties/getEntityOrFundRoute';
import { CounterpartyTradeDependencyFragment } from 'interfaces/model';
import { LENDER_VIEW_ROUTE_FACTORY } from '../../../../../App.routes';
import { CounterpartyStatus, EntityType } from 'interfaces/types';
import { NonFalsy } from 'lib/utils/helpers';
import {
    CounterpartyKYCStatusFragment,
    CounterpartyKYCStatusTargetFragment,
} from '../../../../Counterparties/CounterpartyKYC/CounterpartyKYCList/CounterpartyKYC.query';

type ITargetAggregate = Pick<
    IEntityCounterpartyKYCListRowItem,
    'targetName' | 'targetRoute' | 'changedAt' | 'counterpartyTradeDep' | 'status'
> & {
    target: CounterpartyKYCStatusTargetFragment;
};

const getTargetFields = (item: CounterpartyKYCStatusFragment): ITargetAggregate => {
    const target = item.target.entity || item.target.loanFund;
    return {
        targetName: target?.name || '',
        targetRoute: getEntityOrFundRoute({
            shortCode: target?.shortCode || '',
            isFund: !!item.target.loanFund,
        }),
        changedAt: item.changedAt,
        counterpartyTradeDep: target?.counterpartyTradeDep || [],
        status: item.status,
        target: item.target,
    };
};

const pickMostRecentTimestamp = (dateA: MaybeUndefined<Timestamp>, dateB: MaybeUndefined<Timestamp>) => {
    if (!dateA || !dateB) {
        return dateA || dateB;
    }

    if (new Date(dateA) > new Date(dateB)) {
        return dateA;
    }
    return dateB;
};

const aggregateStatuses = (statuses: CounterpartyStatus[]) => {
    if (!statuses.length) {
        return null;
    }

    if (statuses.some((status) => status === CounterpartyStatus.IN_PROGRESS)) {
        return CounterpartyStatus.IN_PROGRESS;
    } else if (statuses.some((status) => status === CounterpartyStatus.ACTION_REQUIRED)) {
        return CounterpartyStatus.ACTION_REQUIRED;
    } else if (statuses.every((status) => status === CounterpartyStatus.APPROVED)) {
        return CounterpartyStatus.APPROVED;
    } else {
        return CounterpartyStatus.UNAPPROVED;
    }
};

const aggregateTradeDependencies = (
    tradeDependencies: CounterpartyTradeDependencyFragment[]
): CounterpartyTradeDependencyFragment[] => {
    const tradeDependenciesAggregated = tradeDependencies.reduce((acc, item) => {
        let existingItem = acc[item.targetRef];
        if (existingItem) {
            const trades = Object.fromEntries(
                [...(existingItem.trades || []), ...(item.trades || [])]
                    .filter(NonFalsy)
                    .map((trade) => [trade.sharedShortCode, trade])
            );

            existingItem = {
                ...existingItem,
                trades: Object.values(trades),
            };
        }
        acc[item.targetRef] = existingItem || item;
        return acc;
    }, {} as Record<UUID, CounterpartyTradeDependencyFragment>);
    return Object.values(tradeDependenciesAggregated);
};

const aggregateTargets = (targetA: ITargetAggregate, targetB: ITargetAggregate | undefined): ITargetAggregate => {
    if (!targetB) {
        return targetA;
    }

    return {
        ...targetA,
        ...targetB,
        changedAt: pickMostRecentTimestamp(targetA.changedAt, targetB.changedAt),
        status: aggregateStatuses([targetA.status, targetB.status].filter(NonFalsy)),
    };
};

export const prepareData = (
    data: FundEntityCounterpartyKYCListEntityFragment[],
    entityRef: UUID,
    isTopCo: boolean,
    otherCompanyViewMode: boolean
): IEntityCounterpartyKYCListRowItem[] => {
    return data.flatMap((topCo) => {
        const topCoDetails = {
            id: topCo.id,
            topCoName: topCo.name,
            topCoRoute: LENDER_VIEW_ROUTE_FACTORY(topCo.shortCode!),
            headquarters: topCo.headquarters,
            counterpartyTradeDep: aggregateTradeDependencies(
                topCo.childs?.flatMap(({ counterpartyTradeDep }) => counterpartyTradeDep).filter(NonFalsy) || []
            ),
        };

        const targetsAggregated =
            topCo.childs
                ?.flatMap((child) => {
                    if (child.entityType === EntityType.LENDER_INVESTMENT_MANAGER) {
                        return child.loanFunds
                            ?.flatMap(({ counterpartyKYCStatus }) => counterpartyKYCStatus)
                            .filter(NonFalsy);
                    }
                    return child.counterpartyKYCStatus;
                })
                .filter(NonFalsy)
                .reduce((acc, item) => {
                    acc[item.targetRef] = aggregateTargets(getTargetFields(item), acc[item.targetRef]);
                    return acc;
                }, {} as Record<UUID, ITargetAggregate>) || {};

        return Object.entries(targetsAggregated).map(([targetRef, target]) => ({
            ...target,
            ...topCoDetails,
            children: flattenCounterpartyKYCChildren(topCo.childs || [], targetRef).filter((child) => {
                if (!isTopCo && otherCompanyViewMode) {
                    return child.id === entityRef;
                }
                return true;
            }),
            hasBlockingTradeDependency: target.counterpartyTradeDep.length > 0,
            id: topCoDetails.id + targetRef,
        }));
    });
};
