import { MutableRefObject, useLayoutEffect } from 'react';

export const useElementResizeLayoutEffect = (
    ref: MutableRefObject<HTMLElement | undefined>,
    onResize: ResizeObserverCallback
) => {
    useLayoutEffect(() => {
        const element = ref.current;
        const resizeObserver = new ResizeObserver(onResize);
        if (element) {
            resizeObserver.observe(element);
            return () => resizeObserver.unobserve(element);
        }
    });
};
