import React from 'react';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Alert } from '@mui/material';

export const TaxIdInformationAlert: React.FC = () => {
    return (
        <Alert severity={'success'} icon={<TipsAndUpdatesIcon sx={{ fontSize: '24px' }} />}>
            You can come amend tax information at any time. If you do not maintain tax ID's properly, you are liable to
            not getting the appropriate tax relief for entities that otherwise may be entitled to it.
        </Alert>
    );
};
