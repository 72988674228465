import React from 'react';
import { useFormikContext } from 'formik';
import { IEntityEditForm } from '../../../Entities/CreateEntityWizardDialog/useEntityEditFormik/useEntityEditFormik.const';
import { useBankAccountEditDialog } from '../../../../components/BankAccounts/BankAccountEditDialog';
import { Fieldset } from '../../../../../lib/forms/Fieldset';
import Button from '@mui/material/Button';
import { BankAccountsList } from '../../../../components/BankAccounts/BankAccountsList/BankAccountsList';
import { Paper } from '@mui/material';

export const FundBankAccountsStep: React.FC = () => {
    const formik = useFormikContext<IEntityEditForm>();
    const [bankAccountEditDialog] = useBankAccountEditDialog();
    const bankAccounts = formik.values.bankAccounts || [];

    return (
        <Fieldset title={'Bank Account'} description={'You can add multiple bank accounts for this fund'} spacing={2}>
            <div>
                <Button size={'large'} variant={'contained'} onClick={() => bankAccountEditDialog.open()}>
                    Add bank account
                </Button>
            </div>
            <Paper
                elevation={0}
                sx={bankAccounts.length > 0 ? { background: 'var(--bg-default)', padding: '24px' } : void 0}
            >
                <BankAccountsList
                    bankAccounts={bankAccounts}
                    onBankAccountSubmit={async (account) => {
                        await formik.setFieldValue('bankAccounts', [...bankAccounts, account]);
                    }}
                    onDeleteConfirm={async (bankAccountId) => {
                        formik.setFieldValue(
                            'bankAccounts',
                            bankAccounts.filter(({ id }) => id !== bankAccountId)
                        );
                    }}
                    displayEmpty={false}
                    hasDeleteAccess
                    hasEditAccess
                />
            </Paper>
        </Fieldset>
    );
};
