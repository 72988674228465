import React from 'react';
import { EntityRelationsFragment, EntityType } from '../../../../../../interfaces/model';
import { DivTableTheme } from '../../../../../../lib/dataDisplay/List/themes/DivTableTheme';
import { IListColumn, List } from '../../../../../../lib/dataDisplay/List';
import { EntitiesEmbeddedListRow, IEntitiesEmbeddedListItem } from './EntitiesEmbeddedListRow';

interface IEntitiesEmbeddedListProps {
    entityRelations: EntityRelationsFragment[];
    reloadData: () => void;
}

const COLUMNS: IListColumn<IEntitiesEmbeddedListItem>[] = [
    { id: 'name', label: 'Name' },
    { id: 'entityType', label: 'Type' },
    { id: 'ubo', label: 'UBO' },
    { id: 'psc', label: 'PSC' },
    { id: 'actions', label: 'Actions', sort: false, shrink: true },
];

const prepareListItem = (item: EntityRelationsFragment): IEntitiesEmbeddedListItem => {
    return {
        id: item.entityId,
        name: item.entity?.name || 'N/A',
        entityType: item.entity?.entityType,
        ubo: item.ubo,
        psc: item.psc,
        hasEditAccess: !!item.entity?.hasEditAccess,
        shortCode: item.entity?.shortCode || void 0,
    };
};

export const EntitiesEmbeddedList: React.FC<IEntitiesEmbeddedListProps> = ({ entityRelations, reloadData }) => {
    const preparedData = entityRelations
        .filter((item) => item.enabled)
        .map(prepareListItem)
        .filter(
            (item) =>
                item.entityType &&
                ![
                    EntityType.LENDER_TOP_LEVEL,
                    EntityType.BORROWER_TOP_LEVEL,
                    EntityType.AGENT_TOP_LEVEL,
                    EntityType.SPONSOR_TOP_LEVEL,
                    EntityType.FUNDADMIN_TOP_LEVEL,
                ].includes(item.entityType)
        );

    return (
        <DivTableTheme>
            <List
                data={preparedData}
                columns={COLUMNS}
                rowComponent={EntitiesEmbeddedListRow}
                rowComponentProps={{ reloadData }}
                pagination
            />
        </DivTableTheme>
    );
};
