import React from 'react';
import { BankAccountFieldType } from 'interfaces/types';
import { FormTextInput } from 'lib/forms/components/FormTextInput';
import { bankAccountFieldTypeLabels } from '../BankAccountEditDialog.const';

export interface IAccountFieldsProps {
    name: string;
    fields: BankAccountFieldType[];
}

export const AccountFields: React.FC<IAccountFieldsProps> = ({ name, fields }) => {
    return (
        <>
            {fields.map((field) => (
                <FormTextInput key={field} name={`${name}.${field}`} label={bankAccountFieldTypeLabels[field]} />
            ))}
        </>
    );
};
