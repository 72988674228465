import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Container, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { BankAccountInput, BankAccountPurpose, Currency } from 'interfaces/model';
import {
    getInitialValues,
    prepareBankAccountModelValue,
    validationSchema,
} from './BankAccountEditForm/BankAccountEditForm.const';
import { BankAccountEditForm } from './BankAccountEditForm/BankAccountEditForm';
import { FACILITY_CURRENCIES } from '../../pages/Facilities/Facilities.const';
import { DialogSubmitActions } from 'lib/modals/DialogSubmitActions';
import { makeDialogState } from 'store/dialogState/makeDialogState';
import { DialogCloseButton } from 'lib/modals/DialogCloseButton';
import { DEFAULT_PURPOSES } from './BankAccountEditDialog.const';
import { useAppSnackbar } from '../AppNotification';
import { DialogFormComponent } from 'lib/forms';
import { NOOP_ASYNC } from 'lib/utils/helpers';

interface IBankAccountEditDialogProps {
    existingBankAccounts?: BankAccountInput[];
    availablePurposes?: BankAccountPurpose[];
    availableCurrencies?: Currency[];
    onBankAccountSubmit: (bankAccount: BankAccountInput) => Promise<void>;
    onSaveSuccess?: () => Promise<void>;
}

interface IBankAccountEditDialogState {
    title?: string;
    availablePurposes?: BankAccountPurpose[];
    availableCurrencies?: Currency[];
    onBankAccountSubmit?: (bankAccount: BankAccountInput) => Promise<void>;
    onSaveSuccess?: () => Promise<void>;
    bankAccount?: Maybe<BankAccountInput>;
}

export const useBankAccountEditDialog = makeDialogState<IBankAccountEditDialogState>();

export const BankAccountEditDialog: React.FC<IBankAccountEditDialogProps> = ({
    onSaveSuccess = NOOP_ASYNC,
    existingBankAccounts = [],
    onBankAccountSubmit,
    ...props
}) => {
    const snackbar = useAppSnackbar();
    const [{ close }, isOpen, state] = useBankAccountEditDialog();
    const { bankAccount, title } = state || {};

    const availablePurposes = state?.availablePurposes || props.availablePurposes || DEFAULT_PURPOSES;
    const availableCurrencies = state?.availableCurrencies || props.availableCurrencies || FACILITY_CURRENCIES;
    const isExistingCombination = (purpose: BankAccountPurpose | undefined, currency: Currency | undefined) => {
        return Boolean(
            purpose &&
                currency &&
                existingBankAccounts.find((item) => item.purpose === purpose && item.currency === currency)
        );
    };

    const formik = useFormik({
        validationSchema,
        enableReinitialize: isOpen,
        initialValues: getInitialValues(bankAccount, availableCurrencies, isExistingCombination),
        onSubmit: async (values, { resetForm }) => {
            try {
                const submit = state?.onBankAccountSubmit || onBankAccountSubmit;
                await submit(prepareBankAccountModelValue(values));
                setTimeout(async () => {
                    await onSaveSuccess();
                    if (state?.onSaveSuccess) {
                        await state?.onSaveSuccess();
                    }
                    snackbar.show({ message: 'Bank account has been added' });
                });
            } catch (e) {
                snackbar.showError({ message: 'Failed to add bank account' }, e);
            }

            resetForm();
            clearAndClose();
        },
    });

    const clearAndClose = () => {
        formik.resetForm();
        close();
    };

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={isOpen}
                maxWidth={'md'}
                data-testid={'BankAccountDialog'}
                PaperComponent={DialogFormComponent}
                onClose={clearAndClose}
                fullWidth
            >
                <DialogTitle>
                    {bankAccount ? 'Edit' : 'Add'} {title || 'Bank Account'}{' '}
                    <DialogCloseButton onClick={clearAndClose} />
                </DialogTitle>
                <DialogContent>
                    <Container maxWidth={'sm'}>
                        <BankAccountEditForm
                            availablePurposes={availablePurposes}
                            availableCurrencies={availableCurrencies}
                            isExistingCombination={isExistingCombination}
                        />
                    </Container>
                </DialogContent>
                <DialogSubmitActions spin={formik.isSubmitting} onClose={clearAndClose} />
            </Dialog>
        </FormikProvider>
    );
};
