import React from 'react';
import cl from 'classnames';
import styles from './PrivateAccessIcon.module.css';
import { PrivateFacilityMemberStatus } from '../../../../../../../../interfaces/model';
import {
    privateFacilityMemberStatusColors,
    privateFacilityMemberStatusIcons,
    privateFacilityMemberStatusLabels,
} from '../../../../../Facilities.const';

export interface IPrivateAccessIconProps {
    status: PrivateFacilityMemberStatus | undefined;
    displayText?: boolean;
    className?: string;
}

export const PrivateAccessIcon: React.FC<IPrivateAccessIconProps> = ({ status, className, displayText }) => {
    if (!status) {
        return null;
    }

    const Icon = privateFacilityMemberStatusIcons[status!];
    const text = privateFacilityMemberStatusLabels[status!];

    return (
        <div
            title={`${text} access`}
            data-testid={`PrivateAccessIcon:${text.toLowerCase()}`}
            className={cl(privateFacilityMemberStatusColors[status], className)}
        >
            <Icon fontSize={'small'} />
            {displayText && <label className={styles.accessLabel}>{text}</label>}
        </div>
    );
};
