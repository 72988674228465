import React from 'react';
import { useFormikContext } from 'formik';
import { ApolloError } from '@apollo/client';
import { Container, DialogContent } from '@mui/material';
import { LoadingContentWrap } from '../../dataDisplay/LoadingContentWrap';
import { useFormikWizardNavigation } from './useFormikWizardNavigation';
import { WizardNavigation } from './WizardNavigation';
import { WizardFooter } from './WizardFooter';
import { Breakpoint } from '@mui/system';

export interface IFormikWizardConfig {
    steps: IFormWizardStep[];
    nonLinear: boolean | undefined;
}

export interface IFormWizardStep {
    name: string;
    renderContent: (config: IFormikWizardConfig) => React.ReactNode;
    valid: boolean;
}

interface IFormWizardProps {
    steps: IFormWizardStep[];
    error?: ApolloError;
    onCancel: () => void;
    nonLinear?: boolean;
    loading?: boolean;
    maxWidth?: Breakpoint;
    disableGutters?: boolean;
}

export const FormikWizard: React.FC<IFormWizardProps> = ({
    steps,
    error,
    onCancel,
    loading,
    nonLinear,
    disableGutters,
    maxWidth,
}) => {
    const formik = useFormikContext<{ currentStep: number }>();
    const { prevStep, nextStep } = useFormikWizardNavigation({ steps, nonLinear });
    const currentStep = formik.values.currentStep || 0;

    const numberOfSteps = steps.length;

    return (
        <div>
            <WizardNavigation
                steps={steps}
                activeStepIndex={currentStep}
                onStepChange={(stepIndex) => (stepIndex < currentStep ? prevStep : nextStep)(stepIndex)}
            />
            <DialogContent sx={{ padding: disableGutters ? '24px 0' : void 0 }}>
                <Container maxWidth={maxWidth} sx={{ height: '50vh' }} disableGutters>
                    <LoadingContentWrap loading={loading} error={error}>
                        {steps[currentStep]?.renderContent({ nonLinear, steps })}
                    </LoadingContentWrap>
                </Container>
            </DialogContent>
            <WizardFooter
                bgColor={'light'}
                currentStep={currentStep}
                numberOfSteps={numberOfSteps}
                onNextStep={nextStep}
                onPrevStep={prevStep}
                onCancel={onCancel}
            />
        </div>
    );
};
