import React from 'react';
import { Link } from 'react-router-dom';
import { Edit, VpnKey } from '@mui/icons-material';
import { Box, TableCell, TableRow } from '@mui/material';
import { Currency, EntityFacilitiesListRowFragment, FacilityType } from '../../../../../../../interfaces/model';
import { useLinkNavigationBehavior } from '../../../../../../../lib/hooks/router/useLinkNavigationBehavior';
import {
    ActionsCell,
    NumberValueCell,
    ValueCell,
} from '../../../../../../../lib/dataDisplay/List/components/ListCells';
import { FACILITY_EDIT_ROUTE_FACTORY, FACILITY_VIEW_ROUTE_FACTORY } from '../../../../../../App.routes';
import { ListIconButton } from '../../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { PrivateAccessIcon } from '../../../../../Facilities/tabs/FacilitiesTab/ViewFacility/components/PrivateAccessIcon';
import { facilityTypeLabels, hasPrivateAccess } from '../../../../../Facilities/Facilities.const';
import { IListRow } from '../../../../../../../lib/dataDisplay/List';
import { PrivateAccessButton } from '../../../../../Facilities/tabs/FacilitiesTab/ViewFacility/components/PrivateAccessButton';

export interface IFacilitiesListRowItem {
    id: string;
    shortCode: string;
    topCoName: string;
    issuerName: string;
    type: FacilityType;
    tranche: string;
    maturityDate?: string;
    rank: string;
    currency: Currency;
    facilitySize?: number;
    access?: boolean;
    actions?: never;
    facility: EntityFacilitiesListRowFragment;
}

type IEntityFacilitiesListRowProps = IListRow<IFacilitiesListRowItem, { updateData: () => void }>;

export const EntityFacilitiesListRow: React.FC<IEntityFacilitiesListRowProps> = ({
    data,
    columns,
    highlightText,
    props,
}) => {
    const navigateByRoute = useLinkNavigationBehavior();
    return (
        <TableRow component={Link} to={FACILITY_VIEW_ROUTE_FACTORY(data.shortCode, null)} hover>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'shortCode' && <ValueCell>{data.shortCode.toUpperCase()}</ValueCell>}
                    {id === 'issuerName' && (
                        <ValueCell highlight={highlightText} nowrap={false}>
                            {data.issuerName}
                        </ValueCell>
                    )}
                    {id === 'type' && <ValueCell>{facilityTypeLabels[data.type]}</ValueCell>}
                    {id === 'tranche' && (
                        <ValueCell>
                            {data.type !== FacilityType.RCF &&
                                data.type !== FacilityType.DELAYED_DRAW &&
                                data.tranche.toUpperCase()}
                        </ValueCell>
                    )}
                    {id === 'rank' && <ValueCell>{data.rank}</ValueCell>}
                    {id === 'currency' && <ValueCell>{data.currency}</ValueCell>}
                    {id === 'facilitySize' && <NumberValueCell value={data.facilitySize} currency={data.currency} />}
                    {id === 'access' && (
                        <TableCell>
                            {(hasPrivateAccess(data.facility) || data.facility.hasPrivateRequestAccess) && (
                                <Box marginTop={'-8px'} marginBottom={'-8px'} display={'flex'} alignItems={'center'}>
                                    <PrivateAccessIcon
                                        className={'mr-4'}
                                        status={data?.facility.isPrivateFacilityMember}
                                        displayText
                                    />
                                    <PrivateAccessButton
                                        isPrivateFacilityMember={data.facility.isPrivateFacilityMember}
                                        hasFeaturePrivateAccess={data.facility.hasPrivateRequestAccess}
                                        onRequestSent={props.updateData}
                                        facilityId={data.id}
                                        icon={VpnKey}
                                    />
                                </Box>
                            )}
                        </TableCell>
                    )}
                    {id === 'actions' && (
                        <ActionsCell align={'right'}>
                            {data.facility.hasEditAccess && (
                                <ListIconButton
                                    icon={Edit}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigateByRoute(e, FACILITY_EDIT_ROUTE_FACTORY(data.shortCode));
                                    }}
                                />
                            )}
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
