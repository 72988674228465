import React from 'react';
import { Box } from '@mui/material';
import {
    KYCDocCategory,
    KYCDocSubcategory,
    KYCDocType,
    KYCDocumentFragment,
} from '../../../../../../../../../interfaces/model';
import { IDocSubcategory, Year } from '../../../getGroupedDocumentsTree';
import { kycDocSubcategoryLabels } from '../../../../../../../../App.const';
import { IDocumentGroupTreeItem } from '../../DocumentsGroupsTree';
import { getEntries } from '../../DocumentsGroupsTree.helpers';
import { TreeFolder } from '../Tree/TreeFolder';
import styles from './Subcategory.module.css';
import { TreeLeaf } from '../Tree/TreeLeaf';
import { TreeItem } from '../Tree/TreeItem';

const isKYCDocSubcategory = (value: IDocSubcategory): value is KYCDocSubcategory => value in KYCDocSubcategory;

const getYearsTimelineItemId = (id: string | undefined, year: string | number | undefined) =>
    `${id || ''}${year || ''}`;

const getTimelineItemId = (docType: string | undefined, subcategory: IDocSubcategory | undefined) => {
    return `${docType || ''}${subcategory && isKYCDocSubcategory(subcategory) ? subcategory : ''}`;
};

export const Subcategory: React.FC<{
    category: KYCDocCategory;
    docType: KYCDocType;
    value: Record<IDocSubcategory, Record<Year, KYCDocumentFragment[]>>;
    selection: IDocumentGroupTreeItem;
    onSelect: (selection: IDocumentGroupTreeItem) => void;
    hideSelection: boolean;
    offset: number;
}> = ({ category, hideSelection, docType, offset, value, selection, onSelect }) => (
    <>
        {getEntries(value).map(([subcategory, subcategoryItems]) => {
            const isKnownSubCategory = isKYCDocSubcategory(subcategory);
            const selectedYearId = getYearsTimelineItemId(
                getTimelineItemId(selection.docType, selection.subcategory),
                selection.year
            );
            const years = getEntries(subcategoryItems, { reverseSorting: true });
            const id = getTimelineItemId(docType, subcategory);
            const isLast = (index: number) => index + 1 === years.length;
            const timeline = (
                <Box padding={'8px 0'} key={subcategory}>
                    {years.map(([year], index) => (
                        <React.Fragment key={year}>
                            <TreeLeaf
                                offset={offset}
                                active={!hideSelection && getYearsTimelineItemId(id, year) === selectedYearId}
                                onClick={() =>
                                    onSelect({
                                        category,
                                        docType,
                                        subcategory: isKnownSubCategory ? subcategory : void 0,
                                        year,
                                    })
                                }
                            >
                                {year}
                            </TreeLeaf>
                            {!isLast(index) && (
                                <TreeItem offset={offset} className={styles.connectorWrap} interactive={false}>
                                    <div className={styles.connector} />
                                </TreeItem>
                            )}
                        </React.Fragment>
                    ))}
                </Box>
            );

            if (isKnownSubCategory) {
                return (
                    <TreeFolder
                        key={subcategory}
                        id={subcategory}
                        label={kycDocSubcategoryLabels[subcategory]}
                        offset={offset}
                    >
                        {timeline}
                    </TreeFolder>
                );
            }

            return timeline;
        })}
    </>
);
