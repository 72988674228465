import { useFormikContext } from 'formik';
import { TaxIdFragment, TaxIdInput } from '../../../interfaces/model';
import { IDocumentEdit, ITaxIdEditDialogValue } from './TaxIdsList/TaxIdEditDialog/TaxIdEditDialog.const';
import { dateToModelValue } from '../../../lib/utils/date';

interface ITaxItEdit extends Omit<TaxIdFragment, 'document' | 'validFromDate' | 'validToDate'> {
    document: Partial<TaxIdFragment['document']>;
    validFromDate: Date;
    validToDate: Date;
    taxIdFiles: File[];
}

const mapTaxIdEditToListData = (taxIds: ITaxItEdit[] = []): ITaxIdEditDialogValue[] => {
    return taxIds.map((taxId) => {
        return {
            ...taxId,
            document: {
                name: taxId.document?.name || void 0,
                validFromDate: dateToModelValue(taxId.validFromDate),
                validToDate: dateToModelValue(taxId.validToDate),
                documents: taxId.taxIdFiles.map((file) => ({
                    displayName: file.name,
                })),
            },
        };
    });
};

export const useFormikTaxIdEditHelpers = <T extends { taxIds: { id: UUID; taxIdFiles: File[] }[] }>() => {
    const formik = useFormikContext<T>();

    const handleDelete = (taxId: UUID) => {
        formik.setFieldValue(
            'taxIds',
            (formik.values.taxIds || []).filter(({ id }) => id !== taxId)
        );
    };

    const handleSubmit = async (taxId: TaxIdInput, documentEdit: IDocumentEdit) => {
        const taxIds = formik.values.taxIds || [];
        const existingTaxId = taxIds.find(({ id }) => id === taxId.id);
        const existingFiles = existingTaxId?.taxIdFiles || [];
        const newFiles = documentEdit.files;

        formik.setFieldValue('taxIds', [
            ...(formik.values.taxIds || []).filter(({ id }) => id !== taxId.id),
            {
                ...taxId,
                ...documentEdit,
                taxIdFiles: newFiles.length > 0 ? newFiles : existingFiles,
            },
        ]);
    };

    return { handleDelete, handleSubmit, mapTaxIdEditToListData };
};
