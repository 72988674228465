import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Delete from '@mui/icons-material/Delete';
import { DocumentsUploadHistoryList, IDocumentsUploadHistoryListProps } from './DocumentsUploadHistoryList';
import { DocumentsGroupsTree, IDocumentGroupTreeItem } from './DocumentsSubsetGroupsTree';
import { kycDocCategoryLabels } from '../../../../../App.const';
import styles from './DocumentsUploadHistoryView.module.css';
import { DEFAULT_YEAR } from './getGroupedDocumentsTree';
import { DeletedDocumentsList } from './DeletedDocumentsList';
import { DocumentStorage } from '../../../../../../interfaces/model';
import { DocumentsLastUpdateDetailsComponent } from './DocumentsLastUpdateDetailsComponent';

interface IDocumentsUploadHistoryViewProps extends IDocumentsUploadHistoryListProps {
    selection: IDocumentGroupTreeItem;
    onSelect: (selection: IDocumentGroupTreeItem) => void;
    kycMode: boolean;
    updateData: () => Promise<void>;
    focusedItemId: UUID | undefined;
}

const getHeadingText = (selection: IDocumentGroupTreeItem) => {
    if (selection.deleted) {
        return 'Deleted';
    }

    const year = String(selection.year).toUpperCase() !== DEFAULT_YEAR.toUpperCase() ? selection.year : void 0;
    return `${kycDocCategoryLabels[selection.category!] || ''} ${year || ''}`.trim();
};

const DeletedDocumentsMenuItem: React.FC<{ selected: boolean | undefined; onClick: () => void }> = ({
    selected,
    onClick,
}) => (
    <MenuItem onClick={onClick} selected={selected} className={styles.deletedDocumentsMenuItem}>
        <div className={styles.deletedDocumentsMenuItemIconWrap}>
            <Delete color={'secondary'} />
        </div>
        <span className={styles.deletedItemsText}>Trash</span>
    </MenuItem>
);

export const DocumentsUploadsHistoryView: React.FC<IDocumentsUploadHistoryViewProps> = ({
    value,
    parent,
    selection,
    onSelect,
    kycMode,
    focusedItemId,
    updateData,
}) => {
    const isDeletedDocumentsViewMode = !!selection.deleted;
    return (
        <div className={styles.container}>
            <div className={styles.leftSectionWrap}>
                <div className={styles.leftSection}>
                    <DocumentsGroupsTree
                        value={value}
                        onSelect={onSelect}
                        selected={selection}
                        hideSelection={isDeletedDocumentsViewMode}
                    />
                </div>
                <DeletedDocumentsMenuItem
                    selected={isDeletedDocumentsViewMode}
                    onClick={() => onSelect({ deleted: true })}
                />
            </div>
            <div className={styles.rightSection}>
                <h4>{getHeadingText(selection)}</h4>
                {isDeletedDocumentsViewMode ? (
                    <DeletedDocumentsList
                        filter={{
                            deleted: true,
                            id: parent?.id,
                            storage: kycMode ? DocumentStorage.KYC : DocumentStorage.DATA_ROOM,
                        }}
                        parent={parent}
                        updateData={updateData}
                        RowDetailsComponent={DocumentsLastUpdateDetailsComponent}
                        focusedItemId={focusedItemId}
                        onDeleteSuccess={updateData}
                        onRestoreSuccess={updateData}
                    />
                ) : (
                    <DocumentsUploadHistoryList
                        value={value}
                        parent={parent}
                        kycMode={kycMode}
                        selection={selection}
                        focusedItemId={focusedItemId}
                        updateData={updateData}
                    />
                )}
            </div>
        </div>
    );
};
