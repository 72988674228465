import React from 'react';
import { ApolloError } from '@apollo/client';
import { Box, BoxProps } from '@mui/material';
import { DataErrorPlaceholder } from '../DataErrorPlaceholder';
import styles from './LoadingContentWrap.module.css';
import { Spinner } from '../../cdk/Spinner';

interface ILoadingContentWrapProps extends BoxProps {
    loading: boolean | undefined;
    error?: ApolloError;
    children?: React.ReactNode;
    left?: string;
    top?: string;
    size?: number;
}

export const LoadingContentWrap: React.FC<ILoadingContentWrapProps> = ({
    children,
    loading,
    error,
    top = '50%',
    left = '50%',
    size,
    ...props
}) => (
    <Box position={'relative'} display={'flex'} flexDirection={'column'} {...props}>
        {error && <DataErrorPlaceholder error={error} />}
        {!error && children}
        {loading && (
            <Box className={styles.spinner} sx={{ top, left }}>
                <Spinner size={size} />
            </Box>
        )}
    </Box>
);
