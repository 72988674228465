import Grid from '@mui/material/Grid';
import { Box, Checkbox, Collapse, Paper } from '@mui/material';
import React, { useContext } from 'react';
import FormLabel from '@mui/material/FormLabel';
import { FormikContext, FormikContextType } from 'formik';
import { ISelectOption } from '../../../../../../lib/inputs/Select.const';
import { EntityType } from '../../../../../../interfaces/model';
import { Dropdown } from '../../../../../../lib/inputs/Dropdown';
import { totpRequiredOptions } from './RoleGeneralInfoEdit.const';
import { PhoneNumberInput } from '../../../../../../lib/inputs/PhoneNumberInput';
import { PasswordInput } from '../../../../../../lib/inputs/PasswordInput';
import { IRoleForm } from '../../useRoleEditFormik/useRoleEditFormik.const';
import { useUserDetails } from '../../../../../hooks/useUserDetails';
import { TextInput } from '../../../../../../lib/inputs/TextInput';
import { Fieldset } from '../../../../../../lib/forms/Fieldset';
import { NonFalsy } from '../../../../../../lib/utils/helpers';
import { PasswordRequirements } from './PasswordRequirements';
import { useAppConfig } from '../../../../../hooks/useAppConfig';
import { NumberInput } from '../../../../../../lib/inputs/NumberInput';
import { Timepicker } from '../../../../../../lib/inputs/Timepicker';
import Switch from '@mui/material/Switch';
import { Datepicker } from '../../../../../../lib/inputs/Datepicker';
import { CountrySelect } from '../../../../../../lib/inputs/CountrySelect/CountrySelect';
import { AddressFieldsGroup } from '../../../../../common/AddressFieldsGroup';
import { useDirectorMinBirthday } from '../../../../Entities/ViewEntity/DirectorDetailsDialog/DirectorDetailsDialog.const';
import { useLedgercommRoleOptions } from '../../../useLedgercommRoleOptions';
import styles from './RoleGeneralInfoEdit.module.css';
import { TimezoneSelect } from '../../../../../common/TimezoneSelect';

interface IRoleEditFormProps {
    entityType: EntityType | null | undefined;
    showTotpRequiredSelect: boolean;
    showCredentialsInputs?: boolean;
    /*
        When creating a user from 'directors' section
        in entity edit
     */
    disableDirectorCheckbox?: boolean;
}

export const RoleGeneralInfoEdit: React.FC<IRoleEditFormProps> = ({
    entityType,
    showTotpRequiredSelect,
    showCredentialsInputs,
    disableDirectorCheckbox = false,
}) => {
    const { canManageStructure, isAdmin, isSre, role: currentUserRole } = useUserDetails();
    const { securePasswordRequired } = useAppConfig();
    const formik = useContext<FormikContextType<IRoleForm>>(FormikContext);
    const ledgerCommRoleOptions = useLedgercommRoleOptions(entityType);
    const jobTitle = formik.values.jobTitle || '';
    const isStandardJobTitle = ledgerCommRoleOptions.find((item) => item.value === jobTitle);
    const jobTitleOptions: ISelectOption<string, string>[] = [
        !isStandardJobTitle && { id: jobTitle, name: jobTitle, value: jobTitle },
        ...ledgerCommRoleOptions,
    ].filter(NonFalsy);

    const directorMinBirthday = useDirectorMinBirthday();
    const isSelfEditing = currentUserRole?.id === formik?.values?.id;
    const isRoleAdmin = !!formik.values.isAdmin;
    const isCreating = !formik.values.id;
    const showPasswordRequirements = formik.dirty && formik.initialValues.password !== formik.values.password;

    return (
        <div>
            <Fieldset title={'Basic information'}>
                <Grid spacing={3} marginBottom={'16px'} container>
                    <Grid item xs={6}>
                        <FormLabel htmlFor={'name'} required>
                            Full Name
                        </FormLabel>
                        <TextInput name={'name'} placeholder={'Enter full name'} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel htmlFor={'jobTitle'} required>
                            Job Title
                        </FormLabel>
                        <Dropdown
                            // set id to prevent chrome autofilling input with garbage based on "jobTitle" id
                            id={'job-title'}
                            name={'jobTitle'}
                            value={formik.values.jobTitle}
                            onChange={(option) => {
                                formik.setFieldValue('jobTitle', option?.value);
                            }}
                            options={jobTitleOptions}
                            placeholder={'Job Title'}
                            disabled={!canManageStructure}
                            clearable
                            fullWidth
                            freeSolo
                        />
                    </Grid>
                </Grid>
                <Grid spacing={3} container>
                    <Grid item xs={6}>
                        <FormLabel htmlFor={'phone'}>Phone</FormLabel>
                        <PhoneNumberInput
                            onChange={(event) => {
                                const value = event.target.value;
                                formik.setFieldValue('phone', value.replace(/[^+\d]/g, ''));
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Paper elevation={0} className={styles.darkSection}>
                        <Grid item container xs={12} spacing={3}>
                            <Grid item xs={12} alignItems={'center'} display={'flex'} marginLeft={'-4px'}>
                                <Switch
                                    size={'small'}
                                    name={'isDirector'}
                                    disabled={disableDirectorCheckbox}
                                    checked={!!formik.values.isDirector}
                                    onClick={() => {
                                        const newValue = !formik.values.isDirector;
                                        formik.setFieldValue('isDirector', newValue);
                                        formik.setFieldTouched('isDirector', true);
                                        formik.setFieldValue('birthday', directorMinBirthday);
                                        if (!newValue) {
                                            const newEntityRelations = formik.values.entityRelations?.map((item) => {
                                                return {
                                                    ...item,
                                                    director: false,
                                                };
                                            });
                                            formik.setFieldValue('entityRelations', newEntityRelations);
                                            formik.setTouched({
                                                ...formik.touched,
                                                nationality: false,
                                                birthday: false,
                                                country: false,
                                                streetName: false,
                                                postcode: false,
                                                city: false,
                                            });
                                        }
                                    }}
                                />
                                <FormLabel htmlFor={'isDirector'} sx={{ margin: 0 }}>
                                    Director
                                </FormLabel>
                            </Grid>
                            {formik.values.isDirector && (
                                <>
                                    <Grid xs={6} item>
                                        <FormLabel required>Date of birth</FormLabel>
                                        <Datepicker
                                            name={'birthday'}
                                            placeholder={'Select date'}
                                            maxDate={directorMinBirthday}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid xs={6} item>
                                        <FormLabel required>Nationality</FormLabel>
                                        <CountrySelect
                                            name={'nationality'}
                                            placeholder={'Select country'}
                                            disablePortal={false}
                                            clearable={true}
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <FormLabel required>Residency address</FormLabel>
                                        <AddressFieldsGroup />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Paper>
                    {(isSre || (isAdmin && isRoleAdmin)) && (
                        <Grid item xs={12} alignItems={'center'} display={'flex'}>
                            <Checkbox
                                id={'isAdmin'}
                                disabled={!isSre}
                                checked={isRoleAdmin}
                                onClick={() => {
                                    formik.setFieldValue('isAdmin', !isRoleAdmin);
                                }}
                            />
                            <FormLabel htmlFor={'isAdmin'} sx={{ margin: 0 }}>
                                System Administrator
                            </FormLabel>
                        </Grid>
                    )}
                </Grid>
            </Fieldset>
            <Fieldset title={'Credentials'}>
                <Grid spacing={3} marginBottom={'16px'} container>
                    <Grid xs={6} item>
                        <FormLabel htmlFor={'email'} required={!formik.values.id}>
                            E-mail
                        </FormLabel>
                        <TextInput
                            disabled={!canManageStructure}
                            type={'email'}
                            name={'email'}
                            placeholder={'Enter email'}
                            fullWidth
                        />
                    </Grid>
                    {showCredentialsInputs && (
                        <Grid xs={6} item>
                            <FormLabel htmlFor={'login'}>Login</FormLabel>
                            <TextInput
                                name={'login'}
                                placeholder={'Enter login'}
                                onValueChange={(value) =>
                                    formik.setFieldValue('login', value?.replace(/[^0-9a-zA-Z-_]/, '').toLowerCase())
                                }
                                fullWidth
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid spacing={3} container>
                    {!isSelfEditing && showCredentialsInputs && (
                        <Grid xs={6} item>
                            <FormLabel htmlFor={'password'}>Password</FormLabel>
                            <PasswordInput
                                name={'password'}
                                placeholder={'Enter password'}
                                disabled={formik.isSubmitting}
                                showValidationMessage={false}
                                showPlaceholder={!isCreating}
                                fullWidth
                            />
                        </Grid>
                    )}
                    <Box margin={'16px 24px 32px'}>
                        <Collapse
                            in={
                                !isSelfEditing &&
                                showCredentialsInputs &&
                                showPasswordRequirements &&
                                securePasswordRequired
                            }
                        >
                            <PasswordRequirements />
                        </Collapse>
                    </Box>
                </Grid>
            </Fieldset>
            <Fieldset title={'Change Password'} hide={!(showCredentialsInputs && !isCreating && isSelfEditing)}>
                <Grid spacing={3} container>
                    <Grid xs={6} item>
                        <FormLabel htmlFor={'currentPassword'} required>
                            Current Password
                        </FormLabel>
                        <PasswordInput
                            name={'currentPassword'}
                            placeholder={'Enter current password'}
                            disabled={formik.isSubmitting}
                            showPlaceholder={false}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={6} item />
                    <Grid xs={6} item>
                        <FormLabel htmlFor={'password'} required>
                            New Password
                        </FormLabel>
                        <PasswordInput
                            name={'password'}
                            placeholder={'Enter new password'}
                            disabled={formik.isSubmitting}
                            showValidationMessage={false}
                            showPlaceholder={false}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <FormLabel htmlFor={'confirmPassword'} required>
                            Repeat new password
                        </FormLabel>
                        <PasswordInput
                            name={'confirmPassword'}
                            placeholder={'Repeat new password'}
                            disabled={formik.isSubmitting}
                            showPlaceholder={false}
                            fullWidth
                        />
                    </Grid>
                    <Box margin={'16px 24px 32px'}>
                        <Collapse in={showPasswordRequirements && securePasswordRequired}>
                            <PasswordRequirements />
                        </Collapse>
                    </Box>
                </Grid>
            </Fieldset>
            <Fieldset title={'Security'}>
                <Grid spacing={3} container>
                    {showTotpRequiredSelect && (
                        <Grid xs={6} item>
                            <FormLabel htmlFor={'totpRequired'}>Two-Factor Auth Required</FormLabel>
                            <Dropdown name={'totpRequired'} options={totpRequiredOptions} fullWidth />
                        </Grid>
                    )}
                    <Grid xs={6} item>
                        <FormLabel htmlFor={'sessionTimeout'} required>
                            Inactivity timeout (min)
                        </FormLabel>
                        <NumberInput name={'sessionTimeout'} fullWidth />
                    </Grid>
                    <Paper elevation={0} className={styles.darkSection}>
                        <Grid item container xs={12} spacing={3}>
                            <Grid item xs={12} alignItems={'center'} display={'flex'} marginLeft={'-4px'}>
                                <Switch
                                    size={'small'}
                                    id={'logoutAfterSpecificTime'}
                                    checked={formik.values.logoutAfterSpecificTime}
                                    onClick={() => {
                                        formik.setFieldValue(
                                            'logoutAfterSpecificTime',
                                            !formik.values.logoutAfterSpecificTime
                                        );
                                    }}
                                />
                                <FormLabel htmlFor={'logoutAfterSpecificTime'} sx={{ margin: 0 }}>
                                    Automatically log out after specific time
                                </FormLabel>
                            </Grid>
                            {formik.values.logoutAfterSpecificTime && (
                                <>
                                    <Grid item xs={6}>
                                        <FormLabel htmlFor={'autoLogoutAt'}>Time</FormLabel>
                                        <div>
                                            <Timepicker
                                                fullWidth
                                                name={'autoLogoutAt'}
                                                disabled={!formik.values.logoutAfterSpecificTime}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sx={{ paddingTop: '12px' }}>
                                        <FormLabel htmlFor={'timezone'}>Timezone</FormLabel>
                                        <TimezoneSelect
                                            disabled={!formik.values.logoutAfterSpecificTime}
                                            name={'timezone'}
                                            fullWidth
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Paper>
                    <Grid item xs={12} alignItems={'center'} display={'flex'} marginBottom={'32px'}>
                        <Checkbox
                            id={'singleSession'}
                            checked={!!formik.values.singleSession}
                            onClick={() => formik.setFieldValue('singleSession', !formik.values.singleSession)}
                        />
                        <FormLabel htmlFor={'singleSession'} sx={{ margin: 0 }}>
                            Log out from all other sessions after login
                        </FormLabel>
                    </Grid>
                </Grid>
            </Fieldset>
        </div>
    );
};
