import React from 'react';
import { IListRow } from '../../../../../../lib/dataDisplay/List';
import { Link } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import { ActionsCell, BooleanCell, ValueCell } from '../../../../../../lib/dataDisplay/List/components/ListCells';
import { ListIconButton } from '../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { Edit } from '@mui/icons-material';
import { useEntityWizardDialog } from '../../../CreateEntityWizardDialog';
import { EntityType } from '../../../../../../interfaces/model';
import { ENTITY_TYPE_LABELS } from '../../../../../App.const';
import { EntityDeleteIconButton } from '../../LegalEntities/LegalEntitiesList/LegalEntitiesListRow/EntityDeleteIconButton';
import { getEntityViewRouteByType } from '../../../../../App.routes';

export interface IEntitiesEmbeddedListItem {
    id: string;
    name: string;
    entityType: EntityType | undefined;
    ubo: boolean;
    psc: boolean;
    hasEditAccess: boolean;
    shortCode: string | undefined;
    actions?: never;
}

type IComponentProps = {
    reloadData: () => void;
};

type IEntityListRowProps = IListRow<IEntitiesEmbeddedListItem, IComponentProps>;

export const EntitiesEmbeddedListRow: React.FC<IEntityListRowProps> = ({ data, columns, props }) => {
    const [{ open: openEntityEditDialog }] = useEntityWizardDialog();
    const routeFactory = getEntityViewRouteByType(data.entityType);
    return (
        <TableRow component={Link} to={routeFactory(data.shortCode)} hover>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'name' && <ValueCell nowrap={false}>{data.name}</ValueCell>}
                    {id === 'entityType' && (
                        <ValueCell nowrap={false}>
                            {data.entityType ? ENTITY_TYPE_LABELS[data.entityType] : '-'}
                        </ValueCell>
                    )}
                    {id === 'ubo' && <BooleanCell value={data.ubo} />}
                    {id === 'psc' && <BooleanCell value={data.psc} />}
                    {id === 'actions' && (
                        <ActionsCell>
                            {data.hasEditAccess && (
                                <>
                                    <ListIconButton
                                        icon={Edit}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            openEntityEditDialog({
                                                shortCode: data.shortCode,
                                            });
                                        }}
                                    />
                                    <EntityDeleteIconButton
                                        id={data.id}
                                        name={data.name}
                                        onSuccess={props.reloadData}
                                    />
                                </>
                            )}
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
