import React from 'react';
import { CounterpartyKYCChildrenListRow, ICounterpartyChildrenListRowItem } from './CounterpartyKYCChildrenListRow';
import { Capabilities, CounterpartyTargetFragment } from '../../../../../../interfaces/model';
import { IListColumn, List } from '../../../../../../lib/dataDisplay/List';
import { NonFalsy, sortCompare } from '../../../../../../lib/utils/helpers';
import { useUserDetails } from '../../../../../hooks/useUserDetails';
import { useCapability } from '../../../../../hooks/useCapability';
import { ICounterpartyKYCChild } from '../flattenCounterpartyKYCChildren';

interface ICounterpartyKYCChildrenListItem extends ICounterpartyKYCChild {}

interface ICounterpartyChildrenListProps {
    value: ICounterpartyKYCChildrenListItem[];
    targets: CounterpartyTargetFragment[];
    highlightText?: string;
    topCoRef: UUID | null;
    config?: {
        showCounterpartySwitchButton?: boolean;
    };
}

const prepareRowDataItem = (
    item: ICounterpartyKYCChildrenListItem,
    targets: CounterpartyTargetFragment[]
): ICounterpartyChildrenListRowItem => {
    const target = targets[0];
    const targetInvestmentManagerRef = target?.loanFund?.entity?.id || target?.entity?.id;
    const approval = item.counterpartyKYCStatus?.find(({ targetRef }) => target?.id === targetRef);
    const cpApproval = item.counterparties
        ?.find(({ cpEntity }) => cpEntity?.id === targetInvestmentManagerRef)
        ?.kyc.find(({ entity, loanFund }) => (entity || loanFund)?.id === item.id);

    return {
        ...item,
        statusDetails: {
            submittedAt: approval?.changedAt,
            submittedBy: approval?.createdBy,
            authorizedBy: approval?.authorizedBy,
            authorizedAt: approval?.authorizedAt,
            cpSubmittedAt: cpApproval?.createdAt,
            cpSubmittedBy: cpApproval?.createdBy,
            cpAuthorizedBy: cpApproval?.authorizedBy,
            cpAuthorizedAt: cpApproval?.authorizedAt,
        },
    };
};

export const CounterpartyKYCChildrenList: React.FC<ICounterpartyChildrenListProps> = ({
    value,
    targets,
    highlightText,
    config,
    topCoRef,
}) => {
    const { isAdmin, isAgent } = useUserDetails();
    const canManageStructure = useCapability(Capabilities.MANAGE_STRUCTURE);
    const data = value.map((item) => prepareRowDataItem(item, targets)).sort((a, b) => sortCompare(a.name, b.name));
    const { showCounterpartySwitchButton } = config || {};

    const COLUMNS: IListColumn<ICounterpartyChildrenListRowItem>[] = (
        [
            { id: 'name', label: 'Legal entity' },
            { id: 'status', label: 'KYC' },
            showCounterpartySwitchButton &&
                isAdmin &&
                isAgent &&
                ({ id: 'switch', label: 'C/P', sort: false } as const),
            { id: 'docs', label: 'Docs', sort: false },
            canManageStructure && ({ id: 'actions', label: 'Actions', shrink: true, sort: false } as const),
        ] as const
    ).filter(NonFalsy);

    return (
        <List
            columns={COLUMNS}
            rowComponent={CounterpartyKYCChildrenListRow}
            rowComponentProps={{ targets, topCoRef }}
            highlightText={highlightText}
            data={data}
            embedded
        />
    );
};
