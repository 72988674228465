import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

export const MIN_DATE = new Date(-8640000000000000);
export const MAX_DATE = new Date(8640000000000000);

export const DATE_VIEW_FORMAT = 'dd MMM yyyy';
export const DATE_VIEW_FORMAT_NO_YEAR = 'dd MMM';
export const DATE_MODEL_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_MODEL_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const TIME_MODEL_FORMAT = 'HH:mm';
export const DATE_HOUR_VIEW_FORMAT = 'dd MMM yyyy, HH:mm';
export const DATE_VIEW_FORMAT_SHORT = 'MMM y';

export const dateFromJSToFormat = (date: Date | undefined | null, format: string = DATE_VIEW_FORMAT) => {
    if (date) {
        const dateParsed = DateTime.fromJSDate(date);
        return dateParsed.isValid ? dateParsed.toFormat(format) : '';
    }
};

export const dateFromISOToFormat = (date: string | undefined | null, format: string = DATE_VIEW_FORMAT) => {
    if (!date) {
        return void 0;
    }
    const dateParsed = DateTime.fromISO(date);
    return dateParsed.isValid ? dateParsed.toFormat(format) : '';
};

export const dateFromTimestampToFormat = (date: string | undefined | null, format: string = DATE_VIEW_FORMAT) => {
    if (!date) {
        return '';
    }
    const dateParsed = DateTime.fromJSDate(new Date(date));
    return dateParsed.isValid ? dateParsed.toFormat(format) : '';
};

export const dateToModelValue = (date: Date | undefined | null) => {
    if (date) {
        const newValue = DateTime.fromJSDate(date);
        return newValue.isValid ? newValue.toFormat(DATE_MODEL_FORMAT) : void 0;
    }
};

export const dateTimeToModelValue = (date: Date | undefined | null) => {
    if (date) {
        const newValue = DateTime.fromJSDate(date);
        return newValue.isValid ? newValue.toFormat(DATE_TIME_MODEL_FORMAT) : void 0;
    }
};

export const timeToModelValue = (date: DateTime | undefined) => {
    if (date) {
        return date.isValid ? date.toFormat(TIME_MODEL_FORMAT) : void 0;
    }
};

/**
 * Parses date to luxon Date
 * e.g 2020-11-19T14:43:07.109764600+01:00[Europe/Prague]
 */

export const parseDate = (date: string): DateTime => DateTime.fromISO(date.split('[')[0]);
export const compareZoneDates = (a: string | undefined, b: string | undefined): number => {
    if (!a || !b) {
        return 0;
    }
    return parseDate(a).toMillis() - parseDate(b).toMillis();
};

export const startDateCompare = (a: string | undefined | null, b: string | undefined | null): number => {
    if (isNil(a) || a === '') {
        a = '8888-12-31';
    }
    if (isNil(b) || b === '') {
        b = '8888-12-31';
    }
    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    return 0;
};

export const fromDateISO = (dateString: Date | string | undefined | null): Date | undefined => {
    if (dateString instanceof Date) {
        return dateString;
    }
    if (dateString) {
        const date = DateTime.fromISO(dateString);
        return date.isValid ? date.toJSDate() : void 0;
    }
};

export const fromISOToDateTime = (timeString: string | undefined | null): DateTime | null => {
    if (timeString) {
        const date = DateTime.fromISO(timeString);
        return date.isValid ? date : null;
    }
    return null;
};

export const fromDateToTime = (dateString: string | undefined | null): string | undefined => {
    if (dateString) {
        return DateTime.fromISO(dateString).toFormat(TIME_MODEL_FORMAT);
    }
};

export const getMinDateOrToday = (minDate: string | undefined | null, pastDatesAllowed: boolean | undefined | null) => {
    if (minDate) {
        return pastDatesAllowed ? new Date(minDate) : new Date();
    }
    return pastDatesAllowed ? void 0 : new Date();
};

export const timeSentenceFromDate = (startDateString: string | undefined, daysLimit = 3) => {
    if (!startDateString) {
        return null;
    }

    const startDate = DateTime.fromJSDate(new Date(startDateString));
    const currentDate = DateTime.local();
    const difference = currentDate.diff(startDate, ['days', 'hours', 'minutes']);
    if (difference.days === 0) {
        if (difference.hours === 0) {
            if (difference.minutes >= 1) {
                return Math.round(difference.minutes) + ` ${difference.minutes === 1 ? 'minute' : 'minutes'} ago`;
            } else {
                return ' Less than a minute ago';
            }
        } else {
            return difference.hours + ` ${difference.hours === 1 ? 'hour' : 'hours'} ago`;
        }
    } else if (difference.days > daysLimit) {
        return startDate.toFormat(DATE_VIEW_FORMAT);
    } else {
        return difference.days + ` ${difference.days === 1 ? 'day' : 'days'} ago`;
    }
};
