import React from 'react';
import cl from 'classnames';
import { Highlight } from 'react-highlighter-ts';
import { TableCell, TableCellProps } from '@mui/material';
import { INoValuePlaceholderProps, NoValuePlaceholder } from '../../../../cdk/NoValuePlaceholder';

export interface IValueCellProps extends TableCellProps {
    color?: Colors;
    shrink?: boolean;
    nowrap?: boolean;
    breakAll?: boolean;
    highlight?: string;
    placeholderProps?: INoValuePlaceholderProps;
    notApplicable?: boolean;
    testId?: string;
}

export const ValueCell: React.FC<IValueCellProps> = ({
    children,
    color,
    className,
    placeholderProps,
    shrink,
    highlight,
    nowrap = true,
    breakAll,
    notApplicable,
    testId,
    ...props
}) => {
    const content = children ?? <NoValuePlaceholder {...{ ...placeholderProps, notApplicable }} />;
    return (
        <TableCell
            data-testid={testId}
            className={cl(
                color,
                nowrap && 'white-space-nowrap',
                breakAll && 'word-break-all',
                shrink && 'shrink',
                className,
                'text-transform-initial'
            )}
            {...props}
        >
            {highlight && <Highlight search={String(highlight).trim()}>{content}</Highlight>}
            {!highlight && content}
        </TableCell>
    );
};
