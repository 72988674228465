import React from 'react';
import { APP_SPINNER_CONFIG_SELECTOR } from '../App.selectors';
import { Backdrop, BackdropProps, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

interface IAppSpinnerProps {
    show?: boolean;
    backdropProps?: Omit<BackdropProps, 'open'>;
}

export const AppSpinner: React.FC<IAppSpinnerProps> = ({ show, backdropProps }) => {
    const state = useSelector(APP_SPINNER_CONFIG_SELECTOR);
    const isOpened = show || !!state?.show;

    if (!isOpened) {
        return null;
    }

    return (
        <Backdrop
            {...backdropProps}
            sx={{
                zIndex: (theme) => theme.zIndex.modal + 1,
                color: (theme) => theme.palette.primary.main,
            }}
            open={isOpened}
        >
            <CircularProgress color="inherit" disableShrink />
        </Backdrop>
    );
};
