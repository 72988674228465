import React, { useContext } from 'react';
import { BaseSuggestInputProps, SuggestInput } from '../../../../../lib/inputs/SuggestInput';
import { FormikContext } from 'formik';

interface IOption {
    id: string;
    name: string;
}

const getMenuOptionLabel = (option: IOption) => {
    return (
        <div>
            <b>{option.id}</b>
            <div>Name: {option.name}</div>
        </div>
    );
};

export function TaxIdSuggestInput<TData, TVariables extends { search: string }>(
    props: BaseSuggestInputProps<IOption, TData, TVariables>
) {
    const formik = useContext(FormikContext);

    return (
        <SuggestInput
            {...props}
            getValue={(values) => ({ id: values[props.name] || '', name: '' })}
            getOptionLabel={(option) => option.id}
            getMenuOptionLabel={getMenuOptionLabel}
            onChange={({ id, ...restValues }) => {
                formik.setValues({ ...formik.values, [props.name]: id, ...restValues });
            }}
        />
    );
}
