import { createTheme } from '@mui/material/styles';
// Need to import lab overrides, but cannot do it without declaring new variable
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
import { darken, Palette, PaletteOptions } from '@mui/material';

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }

    interface PaletteOptions {
        neutral: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    export interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

export const appThemeDark = createTheme({
    typography: {
        fontFamily: 'var(--font-main)',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#4d7cfe',
            dark: darken('#4d7cfe', 0.1),
        },
        neutral: {
            main: '#FFFFFF',
        },
        secondary: {
            main: '#8590b3',
        },
        warning: {
            main: '#ed6c02',
            light: '#ff9800',
        },
        error: {
            light: '#F75757',
            main: '#eb5757',
            dark: '#c2454b',
            contrastText: '#fff',
        },
        success: {
            light: '#01FAEC',
            main: '#01eee0',
            dark: '#23B899',
            contrastText: '#000',
        },
        text: {
            primary: '#e8e8e8',
            secondary: '#8590b3',
        },
        background: {
            default: '#21273d',
            paper: '#293049',
        },
        info: {
            main: '#1E2F49',
            contrastText: '#03A9F4',
        },
    },
});
