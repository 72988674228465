import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { RouteProps, useHistory, useLocation } from 'react-router';
import { isOnboardingComplete } from '../pages/Onboarding/ClientOnboarding/ClientOnboarding.const';
import { useUserDetails } from '../hooks/useUserDetails';
import { CLIENT_ONBOARDING_ROUTE } from '../App.routes';

export const AppProtectedRoute: React.FC<RouteProps> = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { role, onboardingProgress } = useUserDetails();
    const topCo = role?.topCo;
    const isComplete = isOnboardingComplete(onboardingProgress, topCo?.entityType);
    const isOnboardingRoute = location.pathname.includes(CLIENT_ONBOARDING_ROUTE);

    useEffect(() => {
        if (!isComplete && !isOnboardingRoute) {
            history.push(CLIENT_ONBOARDING_ROUTE);
        }
    }, [history, isComplete, isOnboardingRoute]);

    return <Route {...props} />;
};
