import React from 'react';
import cl from 'classnames';
import styles from './ColoredArea.module.css';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material';

export interface IColoredAreaProps extends BoxProps {
    color: Colors;
    className?: string;
    shape?: 'round' | 'square' | 'squareRounded';
    cornerRadius?: CornerRadius;
}

const SHAPE_CLASSES: Record<string, string> = {
    square: styles.square,
    round: styles.round,
    squareRounded: styles.squareRounded,
};

const CORNER_RADIUS: Record<CornerRadius, string> = {
    xs: styles.cornerRadiusXs,
    sm: styles.cornerRadiusSm,
    md: styles.cornerRadiusMd,
    lg: styles.cornerRadiusLg,
    xl: styles.cornerRadiusXl,
};

export const ColoredArea: React.FC<IColoredAreaProps> = ({
    className,
    color,
    shape = 'round',
    cornerRadius,
    children,
    ...props
}) => {
    return (
        <Box
            className={cl(styles.area, SHAPE_CLASSES[shape], CORNER_RADIUS[cornerRadius!], color, className)}
            padding={'2px'}
            {...props}
        >
            {children}
        </Box>
    );
};
