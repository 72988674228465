import {
    isAgentEntity,
    isBorrowerEntity,
    isFundAdminEntity,
    isLenderEntity,
    isSponsorEntity,
    PartialEntity,
} from '../Entities.helpers';
import { selectOptionFactory } from '../../../../lib/inputs/Select.const';
import { EntityType } from '../../../../interfaces/model';
import { ENTITY_TYPE_LABELS } from '../../../App.const';

export const getEntityTitle = (entity: PartialEntity): string => {
    if (isFundAdminEntity(entity)) {
        return 'fund administrator';
    }

    if (isAgentEntity(entity)) {
        return 'agent';
    }

    if (isBorrowerEntity(entity)) {
        return 'borrower';
    }

    if (isLenderEntity(entity)) {
        return 'lender';
    }

    if (isSponsorEntity(entity)) {
        return 'sponsor';
    }

    return '';
};

const entityTypeOptionsFactory = selectOptionFactory(EntityType, ENTITY_TYPE_LABELS);
export const entityTypeOptions = [
    entityTypeOptionsFactory(EntityType.LENDER_INVESTMENT_BANK),
    entityTypeOptionsFactory(EntityType.LENDER_INVESTMENT_MANAGER),
];
