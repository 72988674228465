import React from 'react';
import { useAppSnackbar } from '../../../../components/AppNotification';
import { useConfirmDialog } from '../../../../../lib/modals/ConfirmDialog';
import { IListIconButtonProps, ListIconButton } from '../../../../../lib/dataDisplay/List/components/ListIconButton';
import Delete from '@mui/icons-material/Delete';
import { AgentFragment, EntityType } from '../../../../../interfaces/model';
import { useParentContext } from '../../../../../lib/cdk/ParentContext';
import { useSetEntityMutation } from '../../queries/SetEntity.mutation';

interface IAgentEntityDeleteButtonProps extends Omit<IListIconButtonProps, 'icon'> {
    agentRef: UUID;
    entityRef: UUID;
    agentName: string;
    entityName: string;
    entityType: EntityType;
    assignedAgents: AgentFragment[];
}

export const AgentEntityDeleteButton: React.FC<IAgentEntityDeleteButtonProps> = ({
    agentRef,
    entityRef,
    agentName,
    entityName,
    entityType,
    assignedAgents,
    ...props
}) => {
    const parent = useParentContext();
    const snackBar = useAppSnackbar();
    const [confirmDialog] = useConfirmDialog();
    const [mutateEntity] = useSetEntityMutation();
    const listLabel = entityType === EntityType.BORROWER_TOP_LEVEL ? 'borrowers' : 'lenders';

    const onConfirm = async () => {
        const newAgents = assignedAgents
            .filter((item) => item.entity?.id !== agentRef)
            .map((item) => {
                return {
                    entityRef: item.entity?.id || '',
                    capabilities: item.capabilities,
                };
            });

        try {
            await mutateEntity({
                variables: {
                    entity: {
                        id: entityRef,
                        assignedAgents: newAgents,
                    },
                },
            });
            await parent.update();
            snackBar.show({
                message: `"${entityName}" has been removed from the list of ${listLabel} for "${agentName}"`,
            });
        } catch (e) {
            snackBar.showError({ message: `Failed to remove ${entityName}` }, e);
        }
    };

    const openDialog = (e: React.MouseEvent) => {
        e.preventDefault();
        confirmDialog.open({
            body: `You are about to remove ${entityName} from the list of ${listLabel} for this agent. Are you sure?`,
            title: `Remove "${entityName}" from the list of ${listLabel} for this agent`,
            onConfirm,
        });
    };

    return <ListIconButton icon={Delete} onClick={openDialog} {...props} />;
};
