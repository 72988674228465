import { Link, LinkProps } from 'react-router-dom';
import React, { HTMLAttributeAnchorTarget } from 'react';
import { INotificationsListUrlState } from './pages/Notifications/tabs/NotificationsList/NotificationsListFilterPanel/useNotificationsListUrlState';
import { IAccrualsPaymentsListFilters } from './pages/Notifications/tabs/NotificationsAccrualsPaymentsList/NotificationsAccrualsPaymentsListPanel';
import { ICounterpartiesEditQueryParams } from './pages/Counterparties/CounterpartiesEdit/useCounterpartiesEditUrlState';
import { EntityType, FacilityStatus, IdType, TradeTicketFilterInput } from '../interfaces/types';
import { IViewEntityUrlState } from './pages/Entities/ViewEntity/ViewEntityDetails/useViewEntityUrlState';
import { ICounterpartiesUrlState } from './pages/Counterparties/Counterparties/useCounterpartiesUrlState';
import { IViewFacilityParams } from './pages/Facilities/tabs/FacilitiesTab/ViewFacility/interfaces';
import { IEntitiesListUrlState } from './pages/Entities/EntitiesList/useEntitiesListUrlState';
import { IViewTradeUrlState } from './pages/Trades/ViewTrade/useViewTradeUrlState';
import { IDocumentsUrlState } from './components/Documents/useDocumentsUrlState';
import { IFromNavigationUrlState } from '../lib/hooks/router/useFromNavigation';
import { IViewRoleUrlState } from './pages/Roles/ViewRole/useViewRoleUrlState';
import { IUAccrualFilterInput, VotingFilterInput } from 'interfaces/types';
import { joinQueryParams } from '../lib/utils/router/joinQueryParams';
import { IViewPaymentParams } from './pages/Payments/interfaces';
import { IPartialModel } from './helpers/getModelByIdType';
import { ICorporateActionsListUrlState } from './pages/Facilities/tabs/CorporateActionsTab/CorporateActionsList/useCorporateActionsListUrlState';
import { IFacilitiesListUrlState } from './pages/Facilities/tabs/FacilitiesTab/FacilitiesTabFiltersPanel/useFacilitiesListUrlState';

export const LOGIN_ROUTE = '/login';
export const DASHBOARD_ROUTE = '/dashboard';
export const SUPPORT_ROUTE = '/support';

export const EVENTS_ROUTE = '/events';
export const EVENT_VIEW_ROUTE_FACTORY = (id = ':id') => `${EVENTS_ROUTE}/${id}`;

export const DOCUMENTS_ROUTE = '/documents';
export const OBJECT_DOCUMENTS_ROUTE_FACTORY =
    (objectUrl: string) =>
    (sc = ':parentSc') =>
        `${objectUrl}/${sc}/documents`;

export const ROLE_ROUTE = '/roles';
export const ROLE_VIEW_ROUTE_FACTORY = (sc: string | null = ':sc', queryParams = {}) =>
    `${ROLE_ROUTE}/${sc?.toLowerCase()}${joinQueryParams(queryParams)}`;
export const ROLE_DOCUMENTS_ROUTE_FACTORY = OBJECT_DOCUMENTS_ROUTE_FACTORY(ROLE_ROUTE);

export const ENTITIES_LIST_ROUTE_FACTORY = (baseUrl: string, queryParams: IEntitiesListUrlState = {}) => {
    return `${baseUrl}${joinQueryParams(queryParams as Dict<string>)}`;
};

export const ENTITY_VIEW_LINK_FACTORY = (baseUrl: string, sc: string, queryParams: IViewEntityUrlState = {}) => {
    return `${baseUrl}/${sc.toLowerCase()}${joinQueryParams(queryParams)}`;
};

export const LENDERS_ROUTE = '/lenders';
export const LENDERS_CREATE_ROUTE = `${LENDERS_ROUTE}/new`;
export const COUNTERPARTIES_ROUTE_FACTORY = (queryParams?: ICounterpartiesUrlState) =>
    `${LENDERS_ROUTE}/counterparties${joinQueryParams(queryParams)}`;
export const COUNTERPARTIES_EDIT_ROUTE_FACTORY = (queryParams?: ICounterpartiesEditQueryParams) =>
    `${COUNTERPARTIES_ROUTE_FACTORY()}/edit${joinQueryParams(queryParams)}`;
export const COUNTERPARTY_KYC_ROUTE_FACTORY = (queryParams?: ICounterpartiesUrlState) =>
    `${LENDERS_ROUTE}/counterparty-kyc${joinQueryParams(queryParams)}`;
export const COUNTERPARTY_KYC_EDIT_ROUTE_FACTORY = (queryParams?: ICounterpartiesEditQueryParams) =>
    `${COUNTERPARTY_KYC_ROUTE_FACTORY()}/edit${joinQueryParams(queryParams)}`;

export const LENDER_VIEW_ROUTE_FACTORY = (sc = ':sc', queryParams: IViewEntityUrlState = {}) =>
    ENTITY_VIEW_LINK_FACTORY(LENDERS_ROUTE, sc, queryParams);
export const LENDER_DOCUMENTS_ROUTE_FACTORY = OBJECT_DOCUMENTS_ROUTE_FACTORY(LENDERS_ROUTE);

export const SPONSORS_ROUTE = '/sponsors';
export const SPONSOR_VIEW_ROUTE_FACTORY = (sc = ':sc', queryParams: IViewEntityUrlState = {}) =>
    ENTITY_VIEW_LINK_FACTORY(SPONSORS_ROUTE, sc, queryParams);
export const SPONSOR_DOCUMENTS_ROUTE_FACTORY = OBJECT_DOCUMENTS_ROUTE_FACTORY(SPONSORS_ROUTE);

export const BORROWERS_ROUTE = '/borrowers';
export const BORROWERS_CREATE_ROUTE = `${BORROWERS_ROUTE}/new`;
export const BORROWER_VIEW_ROUTE_FACTORY = (sc = ':sc', queryParams: IViewEntityUrlState = {}) =>
    ENTITY_VIEW_LINK_FACTORY(BORROWERS_ROUTE, sc, queryParams);
export const BORROWER_DOCUMENTS_ROUTE_FACTORY = OBJECT_DOCUMENTS_ROUTE_FACTORY(BORROWERS_ROUTE);

export const AGENTS_ROUTE = '/agents';
export const AGENT_VIEW_ROUTE_FACTORY = (sc = ':sc', queryParams: IViewEntityUrlState = {}) =>
    ENTITY_VIEW_LINK_FACTORY(AGENTS_ROUTE, sc, queryParams);
export const AGENT_DOCUMENTS_ROUTE_FACTORY = OBJECT_DOCUMENTS_ROUTE_FACTORY(AGENTS_ROUTE);

export const FUND_ADMINS_ROUTE = '/fund-administrators';
export const FUND_ADMIN_VIEW_ROUTE_FACTORY = (sc = ':sc', queryParams: IViewEntityUrlState = {}) =>
    ENTITY_VIEW_LINK_FACTORY(FUND_ADMINS_ROUTE, sc, queryParams);
export const FUND_ADMIN_DOCUMENTS_ROUTE_FACTORY = OBJECT_DOCUMENTS_ROUTE_FACTORY(FUND_ADMINS_ROUTE);

export const TRADES_ROUTE = '/trades';
export const TRADE_BLOTTER_ROUTE_FACTORY = (queryParams: Partial<Record<keyof TradeTicketFilterInput, string>>) =>
    `${TRADES_ROUTE}${joinQueryParams(queryParams)}`;
export const TRADE_CREATE_ROUTE = `${TRADES_ROUTE}/new`;
export const TRADE_EDIT_ROUTE_FACTORY = (sc = ':sc') => `${TRADES_ROUTE}/edit/${sc.toLowerCase()}`;
export const TRADE_VIEW_ROUTE_FACTORY = (sc = ':sc', tab: string | null = ':tab?', queryParams?: IViewTradeUrlState) =>
    `${TRADES_ROUTE}/${sc.toLowerCase()}${tab ? `/${tab}` : ''}${joinQueryParams(queryParams)}`;

export const NOTIFICATIONS_ROUTE = '/notifications';
export const NOTIFICATION_TAB_ROUTE_FACTORY = (
    tab: string | null = ':tab?',
    queryParams:
        | INotificationsListUrlState
        | IAccrualsPaymentsListFilters
        | IUAccrualFilterInput
        | VotingFilterInput = {}
) => `${NOTIFICATIONS_ROUTE}${tab ? `/${tab}` : ''}${joinQueryParams(queryParams)}`;

export const HOLDINGS_ROUTE = '/holdings';
export const HOLDINGS_DETAILS_ROUTE_FACTORY = (sc = ':sc') => `/holdings/details/${sc}`;

export const INTEREST_ROUTE = '/interest-rates';

export const PAYMENTS_ROUTE = '/payments';
export const PAYMENTS_ROUTE_TAB_FACTORY = (tab: string | null = ':tab?', queryParams: IViewPaymentParams = {}) =>
    `${PAYMENTS_ROUTE}${tab ? `/${tab}` : ''}${joinQueryParams(queryParams)}`;

export const FUND_ROUTE = '/funds';
export const FUND_VIEW_ROUTE_FACTORY = (sc: string | null = ':sc', queryParams = {}) =>
    `${FUND_ROUTE}/${sc?.toLowerCase()}${joinQueryParams(queryParams)}`;
export const FUND_DOCUMENTS_ROUTE_FACTORY = OBJECT_DOCUMENTS_ROUTE_FACTORY(FUND_ROUTE);

export const FACILITIES_ROUTE = '/facilities';
export const FACILITIES_ROUTE_TAB_FACTORY = (
    tab: string | null = ':tab?',
    queryParams: ICorporateActionsListUrlState | IFacilitiesListUrlState = {}
) => `${FACILITIES_ROUTE}${tab ? `/${tab}` : ''}${joinQueryParams(queryParams)}`;

export const TRADE_FACILITIES_ROUTE = `${FACILITIES_ROUTE}${joinQueryParams({
    status: FacilityStatus.TRADING,
    trade: true,
})}`;
export const FACILITY_CREATE_ROUTE = `${FACILITIES_ROUTE}/new`;
export const FACILITY_VIEW_ROUTE_FACTORY = (
    sc = ':sc',
    tab: string | null = ':tab?',
    queryParams: IViewFacilityParams | IDocumentsUrlState = {}
) => `${FACILITIES_ROUTE}/view/${sc.toLowerCase()}${tab ? `/${tab}` : ''}${joinQueryParams(queryParams)}`;
export const FACILITY_EDIT_ROUTE_FACTORY = (sc = ':sc') => `${FACILITIES_ROUTE}/edit/${sc.toLowerCase()}`;
export const FACILITY_CREATE_BY_BORROWER_ROUTE_FACTORY = (topCoRef = ':topCoRef') =>
    `${FACILITY_CREATE_ROUTE}?topCoRef=${topCoRef}`;

export const CORPORATE_ACTIONS_ROUTE = `${FACILITIES_ROUTE}/corporate-actions`;

export const BUSINESS_HOLIDAYS_ROUTE = '/calendars';
export const BUSINESS_HOLIDAY_ROUTE_FACTORY = (calendarType: string) =>
    `${BUSINESS_HOLIDAYS_ROUTE}?calendarType=${calendarType}`;

export const CLIENT_SETUP_ROUTE_FACTORY = (queryParams?: IFromNavigationUrlState) =>
    `/client-setup${joinQueryParams(queryParams)}`;
export const CLIENT_ONBOARDING_ROUTE = '/onboarding';
export const SETTINGS_ROUTE = '/settings';

const PEOPLE_ROUTE = `${CLIENT_ONBOARDING_ROUTE}/people`;
const PEOPLE_PREVIEW_ROUTE_FACTORY = (sc: string = ':sc') => `${PEOPLE_ROUTE}/show/${sc}`;

const LEGAL_ENTITIES_ROUTE = `${CLIENT_ONBOARDING_ROUTE}/legal-entities`;
const LEGAL_ENTITIES_PREVIEW_ROUTE_FACTORY = (sc: string = ':sc', queryParams?: IViewEntityUrlState) =>
    `${LEGAL_ENTITIES_ROUTE}/show/${sc}${joinQueryParams(queryParams)}`;

const FUNDS_ROUTE = `${CLIENT_ONBOARDING_ROUTE}/funds`;
const FUNDS_PREVIEW_ROUTE_FACTORY = (sc: string = ':sc', queryParams?: IViewRoleUrlState) =>
    `${FUNDS_ROUTE}/show/${sc}${joinQueryParams(queryParams)}`;

export const CLIENT_ONBOARDING_ROUTES = {
    BUSINESS_PROFILE: `${CLIENT_ONBOARDING_ROUTE}/business-profile`,
    STRUCTURE_AND_OWNERSHIP: `${CLIENT_ONBOARDING_ROUTE}/structure-and-ownership`,

    LEGAL_ENTITIES: LEGAL_ENTITIES_ROUTE,
    LEGAL_ENTITIES_PREVIEW_ROUTE_FACTORY,

    PEOPLE: PEOPLE_ROUTE,
    PEOPLE_PREVIEW_ROUTE_FACTORY,

    FUNDS: FUNDS_ROUTE,
    FUNDS_PREVIEW_ROUTE_FACTORY,

    AUTHORIZED_SIGNATURES: `${CLIENT_ONBOARDING_ROUTE}/authorized-signatures`,
    END: `${CLIENT_ONBOARDING_ROUTE}/end`,
    NO_ACCESS: `${CLIENT_ONBOARDING_ROUTE}/no-access`,
};

export const getEntityListRouteByType = (entityType: EntityType | undefined) => {
    switch (entityType) {
        case EntityType.BORROWER_TOP_LEVEL:
        case EntityType.BORROWER_SUBSIDIARY:
            return BORROWERS_ROUTE;
        case EntityType.LENDER_TOP_LEVEL:
        case EntityType.LENDER_INVESTMENT_BANK:
        case EntityType.LENDER_INVESTMENT_MANAGER:
            return LENDERS_ROUTE;
        case EntityType.AGENT_TOP_LEVEL:
        case EntityType.AGENT:
            return AGENTS_ROUTE;
        case EntityType.SPONSOR:
        case EntityType.SPONSOR_TOP_LEVEL:
            return SPONSORS_ROUTE;
        case EntityType.FUNDADMIN_TOP_LEVEL:
        case EntityType.FUNDADMIN:
            return FUND_ADMINS_ROUTE;
        default:
            return null;
    }
};

export const getEntityViewRouteByType = (entityType: EntityType | undefined) => {
    switch (entityType) {
        case EntityType.BORROWER_TOP_LEVEL:
        case EntityType.BORROWER_SUBSIDIARY:
            return BORROWER_VIEW_ROUTE_FACTORY;
        case EntityType.LENDER_TOP_LEVEL:
        case EntityType.LENDER_INVESTMENT_BANK:
        case EntityType.LENDER_INVESTMENT_MANAGER:
            return LENDER_VIEW_ROUTE_FACTORY;
        case EntityType.AGENT_TOP_LEVEL:
        case EntityType.AGENT:
            return AGENT_VIEW_ROUTE_FACTORY;
        case EntityType.SPONSOR:
        case EntityType.SPONSOR_TOP_LEVEL:
            return SPONSOR_VIEW_ROUTE_FACTORY;
        case EntityType.FUNDADMIN_TOP_LEVEL:
        case EntityType.FUNDADMIN:
            return FUND_ADMIN_VIEW_ROUTE_FACTORY;
        default:
            return () => null;
    }
};

export function getEntityOrFundRoute({
    entity,
    loanFund,
}: {
    entity: Maybe<{ shortCode: Maybe<string> }> | undefined;
    loanFund: Maybe<{ shortCode: Maybe<string> }> | undefined;
}): Maybe<string> {
    if (entity) {
        return LENDER_VIEW_ROUTE_FACTORY(entity.shortCode!);
    }
    if (loanFund) {
        return FUND_VIEW_ROUTE_FACTORY(loanFund.shortCode!);
    }
    return null;
}

export const getEntityFundLink = (
    entity?: Maybe<{ name: Maybe<string>; shortCode: Maybe<string>; entityType: EntityType }>,
    loanFund?: Maybe<{ name: Maybe<string>; shortCode: Maybe<string> }>,
    LinkComponent: React.FC<Omit<LinkProps, 'color' | 'prefix'>> = Link,
    target?: HTMLAttributeAnchorTarget
) => {
    if (entity) {
        const routeFactory = getEntityViewRouteByType(entity.entityType);
        return (
            <LinkComponent target={target} onClick={(e) => e.stopPropagation()} to={routeFactory(entity.shortCode!)}>
                {entity.name}
            </LinkComponent>
        );
    }

    if (loanFund) {
        return (
            <LinkComponent onClick={(e) => e.stopPropagation()} to={FUND_VIEW_ROUTE_FACTORY(loanFund.shortCode!)}>
                {loanFund.name}
            </LinkComponent>
        );
    }
    return 'Unknown find / entity';
};

export const getModelViewRoute = (value: IPartialModel | undefined): ((sc: string) => string | null) => {
    switch (value?.idType) {
        case IdType.ENTITY:
            return getEntityViewRouteByType(value.entity?.entityType);
        case IdType.FACILITY:
            return FACILITY_VIEW_ROUTE_FACTORY;
        case IdType.LOAN_FUND:
            return FUND_VIEW_ROUTE_FACTORY;
        case IdType.ROLE:
            return ROLE_VIEW_ROUTE_FACTORY;
        default:
            return () => null;
    }
};
