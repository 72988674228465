import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Container, Dialog, DialogContent, DialogTitle, FormLabel, Grid } from '@mui/material';
import { IDirectorsListRowItem } from '../DirectorSelectDialog/DirectorSelectList/DirectorsListRow';
import { useAppSnackbar } from 'App/components/AppNotification';
import {
    getInitialValues,
    prepareRoleModelValue,
    useDirectorMinBirthday,
    validationSchemaFactory,
} from './DirectorDetailsDialog.const';
import { useSetRoleMutation } from 'interfaces/model';
import { DialogFormComponent, handleGqlFormMutationError, proxyAPIValidationError } from 'lib/forms';
import { CountrySelect } from 'lib/inputs/CountrySelect/CountrySelect';
import { DialogSubmitActions } from 'lib/modals/DialogSubmitActions';
import { makeDialogState } from 'store/dialogState/makeDialogState';
import { AddressFieldsGroup } from 'App/common/AddressFieldsGroup';
import { DialogCloseButton } from 'lib/modals/DialogCloseButton';
import { Datepicker } from 'lib/inputs/Datepicker';

interface IDirectorDetailsDialogState {
    role: IDirectorsListRowItem;
    updateData?: () => Promise<void>;
    onSaveSuccess?: () => Promise<void>;
}

export const useDirectorDetailsDialog = makeDialogState<IDirectorDetailsDialogState>();

export const DirectorDetailsDialog: React.FC = () => {
    const snackbar = useAppSnackbar();
    const [{ close }, isOpen, state] = useDirectorDetailsDialog();
    const { role, updateData, onSaveSuccess } = state || {};
    const [updateRole, { error }] = useSetRoleMutation();
    const directorMinBirthday = useDirectorMinBirthday();

    const initialValues = getInitialValues(role, directorMinBirthday);
    const [prevValues, setPrevValues] = useState<Partial<typeof initialValues>>({});
    const formik = useFormik({
        initialValues,
        validateOnMount: true,
        enableReinitialize: true,
        validate: proxyAPIValidationError(prevValues, error),
        validationSchema: validationSchemaFactory(directorMinBirthday),
        onSubmit: async (values, helpers) => {
            setPrevValues(values);
            try {
                await updateRole({
                    variables: {
                        role: prepareRoleModelValue(values),
                    },
                });
                if (updateData) {
                    await updateData();
                }
                if (onSaveSuccess) {
                    await onSaveSuccess();
                }
                helpers.resetForm();
                close();
            } catch (e) {
                if (!handleGqlFormMutationError(e, values, helpers)) {
                    snackbar.showError({ message: 'Failed to submit role' }, e);
                }
            }
        },
    });

    const handleClose = () => {
        formik.resetForm();
        close();
    };

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                PaperComponent={DialogFormComponent}
                data-testid={'DirectorDetailsDialog'}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle>
                    Add details for "{role?.name}"
                    <DialogCloseButton onClick={handleClose} />
                </DialogTitle>
                <DialogContent>
                    <Container maxWidth={'sm'} sx={{ marginBottom: '16px', marginTop: '16px' }}>
                        <p>We need more information for director positions</p>
                        <Grid spacing={2} container>
                            <Grid xs={6} item>
                                <FormLabel required>Date of birth</FormLabel>
                                <Datepicker name={'birthday'} placeholder={'Select date'} fullWidth />
                            </Grid>
                            <Grid xs={6} item>
                                <FormLabel required>Nationality</FormLabel>
                                <CountrySelect
                                    name={'nationality'}
                                    placeholder={'Select country'}
                                    disablePortal={false}
                                    clearable={true}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <FormLabel required>Residency address</FormLabel>
                                <AddressFieldsGroup />
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogSubmitActions onClose={handleClose} spin={formik.isSubmitting} />
            </Dialog>
        </FormikProvider>
    );
};
