import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface IAppEmbeddedListWrapProps extends Omit<BoxProps, 'title'> {
    title: React.ReactNode;
    description?: React.ReactNode;
    actions?: React.ReactNode;
    className?: string;
    testId?: string;
    size?: 'small';
}

export const AppEmbeddedListWrap: React.FC<IAppEmbeddedListWrapProps> = ({
    title,
    description,
    actions,
    testId,
    children,
    size,
    ...props
}) => {
    const TitleComponent = size === 'small' ? 'h5' : 'h3';

    return (
        <Box {...props} marginBottom={'24px'} data-testid={testId}>
            <Box marginBottom={'8px'}>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    minHeight={'32px'}
                    marginBottom={'8px'}
                >
                    <TitleComponent className={'nomargin'}>{title}</TitleComponent>
                    {actions}
                </Box>
                {description && (
                    <Box className={'body2 secondary'} maxWidth={'800px'}>
                        {description}
                    </Box>
                )}
            </Box>
            {children}
        </Box>
    );
};
