import React from 'react';
import Box from '@mui/material/Box';
import { Checkbox, TableCell, Theme } from '@mui/material';
import { ListCellItemWrapper } from '../ListCellItemWrapper';
import { SxProps } from '@mui/system';

interface ICheckboxCellProps {
    value: boolean | undefined;
    indeterminate?: boolean;
    onChange: (value: boolean) => void;
    children?: React.ReactNode;
    color?: Exclude<Colors, 'inherit'>;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    className?: string;
}

export const CheckboxCell: React.FC<ICheckboxCellProps> = ({
    value,
    indeterminate,
    color,
    onChange,
    disabled,
    children,
    className,
    sx,
}) => {
    return (
        <TableCell
            className={className}
            sx={{ paddingRight: 0, width: 0, ...sx }}
            onClick={(e) => e.stopPropagation()}
            width={0}
        >
            <ListCellItemWrapper>
                <Box whiteSpace={'nowrap'}>
                    <Checkbox
                        color={color || 'primary'}
                        checked={!!value}
                        indeterminate={indeterminate}
                        onChange={(e) => onChange(e.target.checked)}
                        disabled={disabled}
                    />
                    {children}
                </Box>
            </ListCellItemWrapper>
        </TableCell>
    );
};
