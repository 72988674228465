import React from 'react';
import Box from '@mui/material/Box';
import { ILinkWithIconProps, LinkWithIcon } from '../../../../../navigation/LinkWithIcon';
import { IValueCellProps, ValueCell } from '../ValueCell';

export interface ILinkCellProps extends IValueCellProps {
    to: Maybe<string> | undefined;
    linkProps?: Omit<ILinkWithIconProps, 'to'>;
    suffix?: React.ReactNode;
}

export const LinkCell: React.FC<ILinkCellProps> = ({ children, to, suffix, linkProps, ...props }) => {
    return (
        <ValueCell nowrap={false} {...props}>
            {to && children ? (
                <Box display={'flex'} alignItems={'center'}>
                    <div onClick={(e) => e.stopPropagation()}>
                        <LinkWithIcon to={to} color={'inherit'} {...linkProps}>
                            {children}
                        </LinkWithIcon>
                    </div>
                    {suffix}
                </Box>
            ) : (
                children
            )}
        </ValueCell>
    );
};
