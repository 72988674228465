import React from 'react';
import { makeDialogState } from '../../../../../store/dialogState/makeDialogState';
import { DocumentInInput, KYCDocType, NAReason, useDocumentMutation } from '../../../../../interfaces/model';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DialogCloseButton } from '../../../../../lib/modals/DialogCloseButton';
import { useAppSnackbar } from '../../../AppNotification';
import { FormikProvider, useFormik } from 'formik';
import { DialogFormComponent } from '../../../../../lib/forms';
import { DialogSubmitActions } from '../../../../../lib/modals/DialogSubmitActions';
import { CreateNonApplicableDocumentForm } from './CreateNonApplicableDocumentForm';
import { IEditForm, naReasonLabels, validationSchema } from './CreateNonApplicableDocumentDialog.const';
import { NOOP } from '../../../../../lib/utils/helpers';

interface INonApplicableDocumentDialogState {
    parentRef: UUID | undefined;
    docType: KYCDocType;
    value?: {
        reason: NAReason | undefined;
        comment: string | undefined;
    };
    onSubmit?: (document: DocumentInInput) => Promise<void>;
}

interface ICreateNonApplicableDocumentDialogProps {
    onSaveSuccess?: () => Promise<void>;
}

export const useNonApplicableDocumentDialog = makeDialogState<INonApplicableDocumentDialogState>();

export const NonApplicableDocumentDialog: React.FC<ICreateNonApplicableDocumentDialogProps> = ({
    onSaveSuccess = NOOP,
}) => {
    const snackbar = useAppSnackbar();
    const [saveDocument] = useDocumentMutation();
    const [{ close }, isOpen, state] = useNonApplicableDocumentDialog();
    const { parentRef, docType, onSubmit, value } = state || {};

    const formik = useFormik<IEditForm>({
        enableReinitialize: true,
        initialValues: {
            reason: NAReason.NOT_AVAILABLE,
            comment: '',
            ...value,
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const reasonText = values.comment || naReasonLabels[values.reason!];
                const document = {
                    docType,
                    parentRef,
                    naReason: values.reason,
                    comment: reasonText,
                };

                if (onSubmit) {
                    onSubmit(document);
                } else {
                    await saveDocument({ variables: { document } });
                    await onSaveSuccess();
                }

                resetForm();
                close();
                snackbar.show({ message: 'Document has been marked as non applicable' });
            } catch (e) {
                snackbar.showError({ message: 'Failed to mark document as non applicable' }, e);
            }
        },
    });

    const clearAndClose = () => {
        if (formik.isSubmitting) {
            return;
        }
        formik.resetForm();
        close();
    };

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={isOpen}
                maxWidth={'sm'}
                PaperComponent={DialogFormComponent}
                data-testid={'NonApplicableDocumentDialog'}
                onClose={clearAndClose}
                fullWidth
            >
                <DialogTitle>
                    Document is not available
                    <DialogCloseButton onClick={clearAndClose} />
                </DialogTitle>
                <DialogContent>
                    <CreateNonApplicableDocumentForm />
                </DialogContent>
                <DialogSubmitActions onClose={clearAndClose} />
            </Dialog>
        </FormikProvider>
    );
};
