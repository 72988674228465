import React from 'react';
import { Paper, PaperProps } from '@mui/material';

interface IFieldRowContainerProps extends PaperProps {
    children: React.ReactNode;
}

export const FieldRowContainer: React.FC<IFieldRowContainerProps> = ({ children, ...props }) => {
    return (
        <Paper elevation={0} sx={{ padding: '0 24px' }} {...props}>
            {children}
        </Paper>
    );
};
