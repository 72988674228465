import React, { forwardRef } from 'react';
import { Stack, StackProps } from '@mui/material';

export const RowStack: React.FC<StackProps> = forwardRef(({ spacing = 0.5, children, ...props }, ref) => {
    return (
        <Stack ref={ref} direction={'row'} spacing={spacing} display={'flex'} alignItems={'center'} {...props}>
            {children}
        </Stack>
    );
});
