import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Container, Dialog, DialogContent, DialogTitle } from '@mui/material';
import {
    getInitialValues,
    IDocumentEdit,
    ITaxIdEditDialogValue,
    prepareTaxIdModelValue,
    validationSchema,
} from './TaxIdEditDialog.const';
import { Country, TaxIdInput } from '../../../../../interfaces/model';
import { DialogSubmitActions } from '../../../../../lib/modals/DialogSubmitActions';
import { makeDialogState } from '../../../../../store/dialogState/makeDialogState';
import { DialogCloseButton } from '../../../../../lib/modals/DialogCloseButton';
import { DialogFormComponent, submitFormikAndScrollToInvalidField } from '../../../../../lib/forms';
import { NonFalsy } from '../../../../../lib/utils/helpers';
import { useAppSnackbar } from '../../../../components/AppNotification';
import { TaxIdEditForm } from './TaxIdEditForm';

interface ITaxIdEditDialogProps {
    onSubmit: (taxId: TaxIdInput, documentEdit: IDocumentEdit) => void;
    countriesBlacklist: Country[] | undefined;
}

interface ITaxIdEditDialogState {
    taxId?: ITaxIdEditDialogValue | null;
}

export const useTaxIdEditDialog = makeDialogState<ITaxIdEditDialogState>();

export const TaxIdEditDialog: React.FC<ITaxIdEditDialogProps> = ({ onSubmit, countriesBlacklist }) => {
    const snackbar = useAppSnackbar();
    const [{ close }, isOpen, state] = useTaxIdEditDialog();
    const { taxId } = state || {};
    const isEdit = !!taxId;

    const formik = useFormik({
        enableReinitialize: isOpen,
        initialValues: getInitialValues(taxId),
        validationSchema,
        onSubmit: async (values, helpers) => {
            try {
                const files = (!isEdit && formik.values.files?.filter(NonFalsy)) || [];
                const documentEdit: IDocumentEdit = {
                    name: formik.values.name || void 0,
                    comment: formik.values.name || void 0,
                    validFromDate: formik.values.validFromDate || void 0,
                    validToDate: formik.values.validToDate || void 0,
                    files,
                };
                await onSubmit(prepareTaxIdModelValue(values), documentEdit);
                snackbar.show({ message: 'Tax ID has been added' });
            } catch (e) {
                snackbar.showError({ message: 'Failed to add tax ID' }, e);
            }
            helpers.resetForm();
            clearAndClose();
        },
    });

    const clearAndClose = () => {
        formik.resetForm();
        close();
    };

    const countriesUsed = countriesBlacklist?.filter((country) => country !== formik.initialValues.country);

    return (
        <FormikProvider value={formik}>
            <Dialog
                data-testid={'TaxIDDialog'}
                open={isOpen}
                onClose={clearAndClose}
                maxWidth={'sm'}
                PaperComponent={DialogFormComponent}
                fullWidth
            >
                <DialogTitle>
                    {isEdit ? 'Edit' : 'Add'} Tax ID <DialogCloseButton onClick={clearAndClose} />
                </DialogTitle>
                <DialogContent>
                    <Container maxWidth={'sm'}>
                        <TaxIdEditForm countriesBlacklist={countriesUsed} />
                    </Container>
                </DialogContent>
                <DialogSubmitActions
                    onClose={clearAndClose}
                    spin={formik.isSubmitting}
                    onSubmit={() => submitFormikAndScrollToInvalidField(formik)}
                />
            </Dialog>
        </FormikProvider>
    );
};
