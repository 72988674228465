import React, { useContext, useEffect } from 'react';
import { Masonry } from '@mui/lab';
import { Link } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { EditEntityKeyInformationDialog, useEditKeyInformationDialog } from '../EditEntityKeyInformationDialog';
import { ListCellItemWrapper } from '../../../../../lib/dataDisplay/List/components/ListCellItemWrapper';
import { FieldRowContainer } from '../../../../../lib/dataDisplay/FieldRowContainer/FieldRowContainer';
import { useCounterpartiesAccessCheck } from '../../../Counterparties/useCounterpartiesAccessCheck';
import { AddressDisplay } from '../../../../../lib/dataDisplay/AddressDisplay/AddressDisplay';
import { getEntityViewRouteFactory, isBorrowerEntity, isLenderEntity } from '../../Entities.helpers';
import { CountryFlagAndName } from '../../../../../lib/dataDisplay/CountryFlagAndName';
import { EntityType } from '../../../../../interfaces/model';
import { FieldRow } from '../../../../../lib/dataDisplay/FieldRow/FieldRow';
import { LinkWithIcon } from '../../../../../lib/navigation/LinkWithIcon';
import { TrueFalseIcon } from '../../../../../lib/icons/TrueFalseIcon';
import { COUNTERPARTIES_ROUTE_FACTORY } from '../../../../App.routes';
import { useViewEntityUrlState } from './useViewEntityUrlState';
import { isTopCoModel } from '../../../../helpers/isTopCoModel';
import { NonFalsy } from '../../../../../lib/utils/helpers';
import { ENTITY_TYPE_LABELS } from '../../../../App.const';
import { IconWithTooltip } from '../../../../../lib/icons/IconWithTooltip';
import { useHistory, useLocation } from 'react-router';
import { joinQueryParams } from '../../../../../lib/utils/router/joinQueryParams';
import { ISplitButtonOption, SplitButton } from '../../../../../lib/buttons/SplitButton';
import { isOnboardingComplete } from '../../../Onboarding/ClientOnboarding/ClientOnboarding.const';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { RowStack } from '../../../../../lib/cdk/RowStack';
import { useUserDetails } from '../../../../hooks/useUserDetails';
import { useConfirmDialog } from '../../../../../lib/modals/ConfirmDialog';
import { useAppSnackbar } from '../../../../components/AppNotification';
import { ParentContext } from '../../../../../lib/cdk/ParentContext';
import { AgentsKYCStatus } from './AgentsKYCStatus';
import { ViewEntityFragment } from '../ViewEntity.query';
import { useSetEntityMutation } from '../../queries/SetEntity.mutation';

interface IViewEntityDetailsProps {
    entity: ViewEntityFragment;
    config?: { counterparties?: boolean };
    className?: string;
}

const OnboardingResetButton: React.FC<{ entity: ViewEntityFragment }> = ({ entity }) => {
    const snackBar = useAppSnackbar();
    const parent = useContext(ParentContext);
    const [confirmDialog] = useConfirmDialog();
    const [mutateEntity] = useSetEntityMutation();

    const handleReset = () => {
        confirmDialog.open({
            title: 'Reset client onboarding progress',
            body:
                `You about to clear onboarding progress of "${entity.topCo?.name}". ` +
                `This action resets the progress only. All saved data and uploaded KYC documents will remain.`,
            onConfirm: async () => {
                try {
                    await mutateEntity({
                        variables: {
                            entity: {
                                id: entity.topCoRef,
                                onboardingProgress: [],
                            },
                        },
                    });
                    await parent.update();
                    snackBar.show({ message: 'Client oboarding progress has been reset' });
                } catch (e) {
                    snackBar.showError({ message: 'Failed to reset oboarding progress' }, e);
                }
            },
        });
    };

    return (
        <IconWithTooltip
            color={'inherit'}
            Icon={RestartAltIcon}
            text={'Reset onboarding progress'}
            onClick={handleReset}
        />
    );
};

const OnboardingProgress: React.FC<{ entity: ViewEntityFragment }> = ({ entity }) => {
    const { entityType } = entity;
    const onboardingProgress = entity.onboardingProgress || [];
    const isCompleted = isOnboardingComplete(onboardingProgress, entityType);

    if (onboardingProgress.length === 0) {
        return <span>Not started</span>;
    }

    return (
        <RowStack
            className={isCompleted ? 'success' : 'warning'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            marginRight={'-16px'}
        >
            <span>{isCompleted ? 'Completed' : 'In progress'}</span>
            {entity.hasEditAccess && <OnboardingResetButton entity={entity} />}
        </RowStack>
    );
};

export const ViewEntityDetails: React.FC<IViewEntityDetailsProps> = ({ entity, config, className }) => {
    const history = useHistory();
    const location = useLocation();
    const { isAdmin } = useUserDetails();
    const isTopCo = isTopCoModel(entity);
    const showSponsors = isBorrowerEntity(entity);
    const [urlState, setUrlState] = useViewEntityUrlState();
    const hasCounterpartiesAccess = useCounterpartiesAccessCheck();
    const [editKeyInformationDialog, isEditKeyInformationDialogOpen] = useEditKeyInformationDialog();
    const showAgents = isBorrowerEntity(entity) || isLenderEntity(entity);
    const showCounterpartiesButton =
        hasCounterpartiesAccess &&
        entity.counterparties &&
        isLenderEntity(entity) &&
        config?.counterparties !== false &&
        entity.entityType !== EntityType.LENDER_TOP_LEVEL;

    useEffect(() => {
        if (urlState.edit && !isEditKeyInformationDialogOpen) {
            editKeyInformationDialog.open({
                entityId: entity.id,
            });
        }
    });

    const actionButtonOptions: ISplitButtonOption[] = [
        entity.hasEditAccess && {
            id: 'edit',
            testId: 'ViewEntity:editButton',
            name: 'Edit key information',
            onClick: () => history.push(`${location.pathname}${joinQueryParams({ edit: true })}`),
            primary: true,
        },
    ].filter(NonFalsy);

    return (
        <Box className={className}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} marginBottom={'16px'}>
                <h4>Key Information</h4>
                <Stack direction={'row'} spacing={1}>
                    {entity.shortCode && (
                        <>
                            {showCounterpartiesButton && (
                                <Button
                                    color="success"
                                    variant="outlined"
                                    component={Link}
                                    data-testid={'ViewEntity:counterpartiesButton'}
                                    to={COUNTERPARTIES_ROUTE_FACTORY({
                                        targets: [entity.id],
                                        topCoRef: entity.topCoRef,
                                    })}
                                >
                                    Counterparties
                                </Button>
                            )}
                            <SplitButton options={actionButtonOptions} color={'secondary'} variant={'outlined'} />
                        </>
                    )}
                </Stack>
            </Box>
            <Masonry columns={2} spacing={2} sx={{ width: 'auto' }}>
                {isTopCo && (
                    <>
                        <FieldRowContainer>
                            <FieldRow label="Headquarters">
                                <ListCellItemWrapper>
                                    <CountryFlagAndName country={entity.headquarters!} showFullName />
                                </ListCellItemWrapper>
                            </FieldRow>
                            {showAgents && entity.kycComplete !== null && (
                                <FieldRow label="Agent KYC">
                                    <AgentsKYCStatus
                                        value={entity.kycComplete}
                                        hasEditAccess={!!entity.hasEditAccess}
                                        hasAgents={!!entity?.agents?.length}
                                        config={{ agents: true }}
                                    />
                                </FieldRow>
                            )}
                        </FieldRowContainer>
                        {isAdmin && (
                            <FieldRowContainer>
                                <FieldRow label="Onboarding progress">
                                    <ListCellItemWrapper>
                                        <OnboardingProgress entity={entity} />
                                    </ListCellItemWrapper>
                                </FieldRow>
                            </FieldRowContainer>
                        )}
                        {entity?.emailDomains && (
                            <FieldRowContainer>
                                <FieldRow label={'E-Mail domains allowed'}>
                                    <ListCellItemWrapper>
                                        {entity?.emailDomains?.length && entity.emailDomains?.length > 0
                                            ? entity.emailDomains?.join(', ')
                                            : 'No allowed domains entered'}
                                    </ListCellItemWrapper>
                                </FieldRow>
                            </FieldRowContainer>
                        )}
                    </>
                )}

                {!isTopCo && (
                    <>
                        <FieldRowContainer>
                            <FieldRow label="TopCo">
                                <LinkWithIcon to={getEntityViewRouteFactory(entity)(entity.topCo?.shortCode!)}>
                                    {entity.topCo?.name}
                                </LinkWithIcon>
                            </FieldRow>
                            <FieldRow label="Jurisdiction">
                                <ListCellItemWrapper>
                                    <CountryFlagAndName country={entity.jurisdiction!} showFullName />
                                </ListCellItemWrapper>
                            </FieldRow>
                            <FieldRow label={'Incorporation Number'}>{entity.incorporationNumber}</FieldRow>
                            <FieldRow label="Headquarters">
                                <ListCellItemWrapper>
                                    <CountryFlagAndName country={entity.topCo?.headquarters!} showFullName />
                                </ListCellItemWrapper>
                            </FieldRow>
                            <FieldRow label="LEI">{entity.lei}</FieldRow>
                            {entity.gics?.name && (
                                <FieldRow
                                    label={
                                        <Box display={'flex'} alignItems={'center'}>
                                            GICS
                                            <IconWithTooltip
                                                text={'The Global Industry Classification Standard'}
                                                sx={{ marginTop: '-2px', marginLeft: '4px' }}
                                            />
                                        </Box>
                                    }
                                >
                                    {entity.gics?.name}
                                </FieldRow>
                            )}

                            {isLenderEntity(entity) && (
                                <FieldRow label="Type of lender">{ENTITY_TYPE_LABELS[entity.entityType]}</FieldRow>
                            )}
                            {showAgents && entity.kycComplete !== null && (
                                <FieldRow label="Agent KYC">
                                    <AgentsKYCStatus
                                        value={entity.kycComplete}
                                        hasEditAccess={!!entity.hasEditAccess}
                                        hasAgents={!!entity?.agents?.length}
                                        config={{ agents: true }}
                                    />
                                </FieldRow>
                            )}
                        </FieldRowContainer>
                        <FieldRowContainer>
                            <FieldRow label={'Registered Address'}>
                                <AddressDisplay address={entity.address} />
                            </FieldRow>
                            {entity.businessAddress && (
                                <FieldRow label={'Business Address'}>
                                    <AddressDisplay address={entity.businessAddress} />
                                </FieldRow>
                            )}
                        </FieldRowContainer>
                        {(entity.secRegistered !== null || entity.fcaRegistered !== null) && (
                            <FieldRowContainer>
                                {entity.secRegistered !== null && (
                                    <FieldRow label="SEC Registered">
                                        <TrueFalseIcon value={entity.secRegistered} />
                                    </FieldRow>
                                )}
                                {entity.fcaRegistered !== null && (
                                    <FieldRow label="FCA Registered">
                                        <TrueFalseIcon value={entity.fcaRegistered} />
                                    </FieldRow>
                                )}
                            </FieldRowContainer>
                        )}
                        {isLenderEntity(entity) && (
                            <FieldRowContainer>
                                <FieldRow label="DTTP (UK tax ID)">{entity.dttp}</FieldRow>
                                <FieldRow label="GIIN (US Tax ID)">{entity.giin}</FieldRow>
                                <FieldRow label="NIF (Spanish Tax ID)">{entity.nif}</FieldRow>
                            </FieldRowContainer>
                        )}
                    </>
                )}

                {showSponsors && (
                    <FieldRowContainer>
                        <FieldRow label="Sponsors">
                            {entity.sponsors?.length && (
                                <Box display={'flex'} flexDirection={'column'}>
                                    {entity.sponsors
                                        .map(({ name }) => name)
                                        .sort()
                                        .map((name) => (
                                            <span key={name}>{name}</span>
                                        ))}
                                </Box>
                            )}
                        </FieldRow>
                    </FieldRowContainer>
                )}
                {entity.entityType === EntityType.LENDER_INVESTMENT_BANK && (
                    <FieldRowContainer>
                        <FieldRow label="Settlement Lead Time (Business Days)">{entity.settlementLeadTime}</FieldRow>
                        <FieldRow label="Authorised Signatories">
                            {entity.authorizedSignatoryRoles
                                ?.map(({ name }) => name)
                                .filter(NonFalsy)
                                .sort()
                                .join(', ')}
                        </FieldRow>
                    </FieldRowContainer>
                )}
            </Masonry>
            <EditEntityKeyInformationDialog
                onClose={() => setUrlState({ ...urlState, edit: void 0 }, { replace: true })}
            />
        </Box>
    );
};
