import React from 'react';
import { useHistory } from 'react-router';
import { Replay } from '@mui/icons-material';
import { ListIconButton } from '../../../../../lib/dataDisplay/List/components/ListIconButton';
import { useCurrentDocumentRouteFactory } from '../../useCurrentDocumentRouteFactory';
import { KYCDocumentFragment, useRestoreDocumentMutation } from '../../../../../interfaces/model';
import { useAppSnackbar } from '../../../AppNotification';
import { Spinner } from '../../../../../lib/cdk/Spinner';
import { Box } from '@mui/material';

interface IDocumentRestoreIconButtonProps {
    document: Pick<KYCDocumentFragment, 'id' | 'deleted'>;
    updateData: () => Promise<void>;
}

export const DocumentRestoreIconButton: React.FC<IDocumentRestoreIconButtonProps> = ({ document, updateData }) => {
    const history = useHistory();
    const getRoute = useCurrentDocumentRouteFactory();
    const snackbar = useAppSnackbar();
    const [restoreDocument, { loading }] = useRestoreDocumentMutation();
    const restoreFile = async () => {
        try {
            const response = await restoreDocument({
                variables: {
                    documentId: document.id,
                },
            });
            await updateData();
            const restoredDocumentId = response.data?.restoreDocument.id;
            if (restoredDocumentId) {
                history.push(getRoute({ id: restoredDocumentId, deleted: void 0 }));
            }
            snackbar.show({ message: 'Document has been restored' });
        } catch (e) {
            snackbar.showError({ message: 'Failed to restore document' }, e);
        }
    };

    if (!document.deleted) {
        return null;
    }

    if (loading) {
        return (
            <Box width={'30px'} height={'30px'}>
                <Spinner size={18} />
            </Box>
        );
    }

    return <ListIconButton title={'Restore this version'} icon={Replay} onClick={restoreFile} />;
};
