import React from 'react';
import { alpha } from '@mui/material';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { extendTheme } from '../../../cdk/lib.const';

const TABLE_CELL_PADDING = '20px';

const embeddedTableTheme = (parentTheme: Theme) =>
    extendTheme(parentTheme)({
        components: {
            MuiTable: {
                styleOverrides: {
                    root: {
                        zIndex: 2,
                    },
                },
            },
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        '--row-min-height': '44px',
                        background: parentTheme.palette.background.default,
                        borderRadius: '4px',
                        padding: '14px 12px',

                        '&:before': {
                            content: '""',
                            display: 'flex',
                            width: '100%',
                            height: '16px',
                            position: 'sticky',
                            zIndex: 1,
                            top: '-16px',
                            marginBottom: '-16px',
                            background: parentTheme.palette.background.default,
                        },
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    hover: {
                        transition: '0.2s ease background-color',
                        '&:hover': {
                            backgroundColor: alpha(parentTheme.palette.text.secondary, 0.08),
                            '& > [class*=MuiTableCell-root]': {
                                borderColor: 'transparent',
                            },
                        },
                    },
                    root: {
                        '&:last-of-type [class*=MuiTableCell-root]': {
                            borderBottom: 0,
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: `1px solid ${alpha(parentTheme.palette.text.secondary, 0.05)}`,
                        background: 'transparent',
                        borderBottomWidth: 1,

                        '&:first-of-type': {
                            paddingLeft: TABLE_CELL_PADDING,
                        },

                        '&:last-of-type': {
                            paddingRight: TABLE_CELL_PADDING,
                        },
                    },
                    head: {
                        fontSize: '12px',
                        color: parentTheme.palette.text.primary,
                        lineHeight: 1.2,
                        height: 'var(--row-min-height)',
                        fontWeight: 600,
                        textTransform: 'capitalize',
                        paddingLeft: '14px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                    },
                    body: {
                        fontSize: '12px',
                        color: parentTheme.palette.text.primary,
                        height: 'var(--row-min-height)',
                        fontWeight: 600,
                        lineHeight: 1.2,
                        paddingLeft: '14px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        background: 'transparent',

                        '&:first-of-type': {
                            paddingLeft: TABLE_CELL_PADDING,
                        },
                        '&:last-of-type': {
                            paddingRight: TABLE_CELL_PADDING,
                        },
                    },
                },
            },
            MuiTableFooter: {
                styleOverrides: {
                    root: {
                        '& [class*=MuiTableRow-root]:first-of-type > [class*=MuiTableCell-root]': {
                            color: parentTheme.palette.text.primary,
                            fontSize: '12px',
                            textTransform: 'capitalize',
                            lineHeight: 1.2,
                            fontWeight: 600,
                            borderTop: `2px solid ${alpha(parentTheme.palette.text.primary, 0.05)}`,
                            background: 'transparent',
                            paddingLeft: '14px',
                            paddingTop: '20px',
                            paddingBottom: '4px',
                        },
                    },
                },
            },
        },
    });

export const EmbeddedTableTheme: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={embeddedTableTheme}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
};
