import React, { Suspense } from 'react';
import { Switch } from 'react-router';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { init as initSentry } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { AppTheme } from './App/components/AppTheme';
import { Login } from './App/components/Auth/Login';
import { setupStore } from './store';
import { App } from './App';
import { ErrorScreen } from './App/components/ErrorScreen';
import { AppNotification } from './App/components/AppNotification';
import { CLIENT_ONBOARDING_ROUTE, LOGIN_ROUTE } from './App/App.routes';
import { AppConfigResolver } from './App/components/AppConfigResolver';
import { ConfirmDialog } from './lib/modals/ConfirmDialog';
import { getMainDefinition } from '@apollo/client/utilities';
import { isDevMode } from './lib/utils/helpers';
import { AppProtectedRoute } from './App/components/AppProtectedRoute';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import omitDeep from 'omit-deep-lodash';
import { IntlProvider } from 'react-intl';
import messages from './lang/en-GB.json';
import { onError } from '@apollo/client/link/error';
import { isUnauthorizedServerError } from './App/API';
import { ErrorBoundary } from 'react-error-boundary';
import { AppSpinner } from './App/components/AppSpinner';
import { CustomerServiceWidget } from './App/components/CustomerServiceWidget';
import trackerGraphQL from '@openreplay/tracker-graphql';
import { getAppTracker } from './App/hooks/useAppTracker';
import { HelmetProvider } from 'react-helmet-async';
import { AppTitle } from './App/components/AppTitle';

const ClientOnboarding = React.lazy(() => import('./App/pages/Onboarding/ClientOnboarding'));

const isIgnoredError = (error: MaybeUndefined<Error | string>): boolean => {
    if (error instanceof Error) {
        const errorMessage = error.message || '';
        return (
            errorMessage.includes('ResizeObserver loop completed with undelivered notifications') ||
            errorMessage.includes('ResizeObserver loop limit exceeded')
        );
    }
    return false;
};

if (!isDevMode()) {
    initSentry({
        environment: process.env.REACT_APP_ENV,
        dsn: 'https://adba2a18d5ea49eab45717fe2e81e371@o1251728.ingest.sentry.io/6417427',
        release: process.env.REACT_APP_SENTRY_RELEASE,
        integrations: [new BrowserTracing()],
        beforeSend(event, hint) {
            if (isIgnoredError(hint?.originalException)) {
                return null;
            }
            return event;
        },
    });
}

const store = setupStore();

const cleanMutationLink = new ApolloLink((operation, forward) => {
    const keysToOmit = ['__typename'];
    const def = getMainDefinition(operation.query);
    if (def && (def as any).operation === 'mutation') {
        operation.variables = omitDeep(operation.variables, keysToOmit);
    }
    return forward ? forward(operation) : null;
});

const httpLink = new HttpLink({
    uri: '/graphql',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    const tracker = getAppTracker();

    if (tracker && graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
            const traceId = extensions?.traceId as string | null;
            const type = extensions?.type as string | null;
            tracker.event(type || 'graphql-error', { type, traceId });
        });
    }

    if (isUnauthorizedServerError(networkError)) {
        setTimeout(() => {
            if (!window.location.pathname.includes(LOGIN_ROUTE)) {
                window.location.assign(LOGIN_ROUTE);
            }
        });
    }
});

let recordGraphQL: Function;

const trackerApolloLink = new ApolloLink((operation, forward) => {
    const tracker = getAppTracker();
    if (!tracker) {
        return forward(operation);
    }

    if (!recordGraphQL) {
        recordGraphQL = tracker.use(trackerGraphQL());
    }

    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'X-OpenReplay-SessionToken': tracker.getSessionToken(),
        },
    }));

    return forward(operation).map((result) => {
        const operationDefinition = operation.query.definitions[0];
        return recordGraphQL(
            operationDefinition.kind === 'OperationDefinition' ? operationDefinition.operation : 'unknown?',
            operation.operationName,
            operation.variables,
            result
        );
    });
});

const client = new ApolloClient({
    uri: '/graphql',
    link: ApolloLink.from([cleanMutationLink, trackerApolloLink, errorLink, httpLink]),
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
        },
    },
    cache: new InMemoryCache({
        typePolicies: {
            Dashboard: {
                // Dashboard type doesn't contain id, so we tell that to Apollo
                // to stop it from throwing warning about cache invariant
                keyFields: [],
            },
        },
    }),
});

const includeCustomerServiceWidget = process.env.REACT_APP_INCLUDE_CUSTOMER_SERVICE_WIDGET;

createRoot(document.getElementById('app')!).render(
    <AppTheme>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <IntlProvider messages={messages} locale="en" defaultLocale="en">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <ErrorBoundary fallback={<ErrorScreen forceShow />}>
                            <HelmetProvider>
                                <Router>
                                    <AppTitle />
                                    <AppConfigResolver>
                                        <Switch>
                                            <Route path={LOGIN_ROUTE} exact>
                                                <Login />
                                            </Route>
                                            <Route path={CLIENT_ONBOARDING_ROUTE}>
                                                <Suspense
                                                    fallback={<AppSpinner backdropProps={{ invisible: true }} show />}
                                                >
                                                    <ClientOnboarding />
                                                </Suspense>
                                            </Route>
                                            <AppProtectedRoute path={'*'}>
                                                <App />
                                            </AppProtectedRoute>
                                        </Switch>
                                        <AppSpinner />
                                        <ErrorScreen />
                                        <ConfirmDialog />
                                        <AppNotification />
                                        {includeCustomerServiceWidget && <CustomerServiceWidget />}
                                    </AppConfigResolver>
                                </Router>
                            </HelmetProvider>
                        </ErrorBoundary>
                    </LocalizationProvider>
                </IntlProvider>
            </Provider>
        </ApolloProvider>
    </AppTheme>
);
export { getMessageFromApolloError } from './lib/utils/helpers';
