import { Theme } from '@mui/material';
import { extendTheme } from '../../../../lib/cdk/lib.const';

export const ledgercommMuiTableTheme = (theme: Theme) =>
    extendTheme(theme)({
        components: {
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        '--row-min-height': '42px',
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        lineHeight: 1,
                        padding: '20px 24px',
                        border: 0,

                        '&.spacer > *': {
                            height: '4px',
                            padding: 0,
                            borderBottomWidth: 0,
                            backgroundColor: 'transparent',
                        },

                        '&[class*=hover]': {
                            textDecoration: 'none',
                            '&:hover': {
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                            },
                            '&:hover > :not(.hidden)': {
                                backgroundColor: 'var(--bg-paper-light)',
                            },
                            '& > *': {
                                transition: '0.2s ease background-color',
                                cursor: 'pointer',
                            },
                        },
                    },
                },
            },
            MuiTableSortLabel: {
                styleOverrides: {
                    root: {
                        whiteSpace: 'nowrap',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottomWidth: 0,
                        paddingBottom: 14,
                        paddingTop: 14,
                        paddingLeft: 14,
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: 1.2,
                        verticalAlign: 'middle',
                        zIndex: 3,

                        '&:first-of-type': {
                            borderTopLeftRadius: 'var(--border-radius-xs)',
                            borderBottomLeftRadius: 'var(--border-radius-xs)',
                        },
                        '&.hidden:first-of-type + :not(.hidden)': {
                            borderTopLeftRadius: 'var(--border-radius-xs)',
                            borderBottomLeftRadius: 'var(--border-radius-xs)',
                        },
                        '&:last-child': {
                            borderTopRightRadius: 'var(--border-radius-xs)',
                            borderBottomRightRadius: 'var(--border-radius-xs)',
                            paddingRight: '14px',
                        },
                        '&.hidden': {
                            backgroundColor: 'transparent',
                            pointerEvents: 'none',
                        },

                        '&.sorted-cell': {
                            paddingRight: '26px',
                        },
                        '&.stickyColumn': {
                            position: 'sticky',
                            backgroundColor: '#2e3347',
                            left: 0,
                        },
                        '&.alignInitial': {
                            verticalAlign: 'initial',
                        },
                        '&.transparent': {
                            backgroundColor: 'transparent',
                            pointerEvents: 'none',
                        },
                    },
                    body: {
                        background: theme.palette.background.paper,
                    },
                    stickyHeader: {
                        backgroundColor: '#2e3347',
                    },
                    head: {
                        textTransform: 'uppercase',
                        color: '#8181A5',
                        paddingLeft: '14px',
                        paddingRight: 0,
                        border: 0,
                        borderBottomWidth: 0,
                        fontSize: '12px',
                        fontWeight: 600,
                        background: 'transparent',

                        '&.stickyColumn': {
                            zIndex: 3,
                        },

                        '&:first-of-type': {
                            borderTopLeftRadius: 'var(--border-radius-xs)',
                            borderBottomLeftRadius: 'var(--border-radius-xs)',
                        },
                        '&:last-child': {
                            borderTopRightRadius: 'var(--border-radius-xs)',
                            borderBottomRightRadius: 'var(--border-radius-xs)',
                        },
                    },
                    alignRight: {
                        flexDirection: 'row',
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    selectLabel: {
                        margin: 0,
                    },
                    displayedRows: {
                        margin: 0,
                    },
                    selectRoot: {
                        border: 0,
                        marginRight: 12,
                        marginLeft: 4,
                    },
                    select: {
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: 0,
                        textAlign: 'left',
                        textAlignLast: 'unset',
                        paddingRight: 20,
                        paddingLeft: 4,
                    },
                    selectIcon: {
                        right: 0,
                    },
                },
            },
        },
    });
