import React from 'react';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { extendTheme } from '../../../cdk/lib.const';

const compactTableTheme = (parentTheme: Theme) =>
    extendTheme(parentTheme)({
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        paddingRight: 0,
                    },
                },
            },
        },
    });

export const CompactTableTheme: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={compactTableTheme}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
};
