import cl from 'classnames';
import React from 'react';
import { VisibilityOff } from '@mui/icons-material';
import { IconButton, IconButtonProps, SvgIcon, Tooltip } from '@mui/material';
import { useConfirmDialog } from '../../../../../../../../lib/modals/ConfirmDialog';
import { PrivateFacilityMemberStatus, useRequestPrivateAccessMutation } from '../../../../../../../../interfaces/model';
import { useAppSnackbar } from '../../../../../../../components/AppNotification';
import { NOOP } from '../../../../../../../../lib/utils/helpers';

export interface IPrivateAccessProps extends IconButtonProps {
    isPrivateFacilityMember: PrivateFacilityMemberStatus | undefined;
    hasFeaturePrivateAccess: boolean | undefined;
    className?: string;
    facilityId: UUID | undefined;
    onRequestSent?: () => void;
    icon?: typeof SvgIcon;
}

export const PrivateAccessButton: React.FC<IPrivateAccessProps> = ({
    facilityId,
    className,
    isPrivateFacilityMember,
    hasFeaturePrivateAccess,
    children,
    onRequestSent = NOOP,
    icon: Icon = VisibilityOff,
    ...props
}) => {
    const appSnackbar = useAppSnackbar();
    const [confirmDialog] = useConfirmDialog();
    const [requestPrivateAccess] = useRequestPrivateAccessMutation();

    if (!facilityId || !hasFeaturePrivateAccess || isPrivateFacilityMember !== PrivateFacilityMemberStatus.PUBLIC) {
        return <>{children}</>;
    }

    const displayDialog = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        confirmDialog.open({
            title: 'Request private access',
            body: 'You are about to send a request to gain private access to this facility. Proceed?',
            onConfirm: async () => {
                try {
                    await requestPrivateAccess({
                        variables: {
                            facilityId,
                        },
                    });
                    confirmDialog.close();
                    appSnackbar.show({ message: 'Private access request has been sent' });
                    onRequestSent();
                } catch (e) {
                    appSnackbar.showError({ message: 'Failed to request private access' }, e);
                }
            },
        });
    };

    return (
        <Tooltip title={'Request private access'} placement={'bottom'} disableInteractive arrow>
            <IconButton
                {...props}
                size={'small'}
                color={'secondary'}
                onClick={displayDialog}
                className={cl('warning', className)}
            >
                <Icon fontSize={'inherit'} />
            </IconButton>
        </Tooltip>
    );
};
