import { Masonry } from '@mui/lab';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Box, Stack } from '@mui/material';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { useControlledUsersLog } from '../../../../components/AppHeader/ProfileButton/RecentUsersMenu/useControlledUsersLog';
import { useControlledUserSwitch } from '../../../../components/AppHeader/ProfileButton/useControlledUserSwitch';
import { totpRequiredLabels } from '../../RoleEditDialog/steps/RoleGeneralInfoEdit/RoleGeneralInfoEdit.const';
import { FieldRowContainer } from '../../../../../lib/dataDisplay/FieldRowContainer/FieldRowContainer';
import { ISplitButtonOption, SplitButton } from '../../../../../lib/buttons/SplitButton';
import { joinQueryParams } from '../../../../../lib/utils/router/joinQueryParams';
import { NoValuePlaceholder } from '../../../../../lib/cdk/NoValuePlaceholder';
import { FieldRow } from '../../../../../lib/dataDisplay/FieldRow/FieldRow';
import { LinkWithIcon } from '../../../../../lib/navigation/LinkWithIcon';
import { RoleEmailVerificationAlert } from './RoleEmailVerificationAlert';
import { getEntityViewRouteFactory } from '../../../Entities/Entities.helpers';
import { useUserDetails } from '../../../../hooks/useUserDetails';
import { RoleFragment } from '../../../../../interfaces/model';
import { ledgercommRoleLabels, timezonesLabels } from '../../../../App.const';
import { NonFalsy } from '../../../../../lib/utils/helpers';
import { TOTPSwitch } from '../../EditRole/TOTPSwitch';
import { RowStack } from '../../../../../lib/cdk/RowStack';
import { VerifiedRoleEmailIcon } from './VerifiedRoleEmailIcon';
import { dateFromISOToFormat, fromDateToTime } from '../../../../../lib/utils/date';
import { formatNumber } from '../../../../../lib/utils/formatting';
import { AddressDisplay } from '../../../../../lib/dataDisplay/AddressDisplay/AddressDisplay';
import { hasDirectorRelation } from '../../RoleEditDialog/useRoleEditFormik/useRoleEditFormik.const';
import { CountryFlagAndName } from '../../../../../lib/dataDisplay/CountryFlagAndName';

interface IViewRoleDetailsProps {
    role: RoleFragment | undefined;
    updateData: () => void;
    className?: string;
}

export const ViewRoleDetails: React.FC<IViewRoleDetailsProps> = ({ role, className, updateData }) => {
    const history = useHistory();
    const location = useLocation();
    const { isAdmin, role: currentUserRole } = useUserDetails();
    const isSelfEditing = currentUserRole?.id === role?.id;

    const [, logControlledUser] = useControlledUsersLog();
    const [switchUser] = useControlledUserSwitch();

    const [isLoading, setLoading] = useState(false);
    const isDirector = hasDirectorRelation(role?.entityRelations);

    const actionButtonOptions: ISplitButtonOption[] = [
        role?.hasEditAccess && {
            id: 'edit',
            name: 'Edit key information',
            onClick: () => {
                history.push(`${location.pathname}${joinQueryParams({ edit: true })}`);
            },
            primary: true,
        },
        role?.hasSwitchAccess && {
            id: 'switch',
            name: 'Switch to this user',
            Icon: SwitchAccountIcon,
            onClick: async () => {
                setLoading(true);
                await switchUser(role);
                await updateData();
                setLoading(false);
                logControlledUser(role);
            },
        },
    ].filter(NonFalsy);

    return (
        <Stack spacing={2} className={className}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <h4>Key Information</h4>
                <Stack direction={'row'} spacing={1}>
                    {!role?.deleted && (
                        <SplitButton
                            options={actionButtonOptions}
                            color={'secondary'}
                            variant={'outlined'}
                            className={'primaryAction'}
                            disabled={isLoading}
                        />
                    )}
                </Stack>
            </Box>

            <RoleEmailVerificationAlert role={role} updateData={updateData} />
            <Box>
                <Masonry columns={2} spacing={2} sx={{ width: 'auto' }}>
                    <FieldRowContainer>
                        {role?.topCo && (
                            <FieldRow label="TopCo">
                                <LinkWithIcon to={getEntityViewRouteFactory(role.topCo)(role?.topCo?.shortCode!)}>
                                    {role?.topCo?.name}
                                </LinkWithIcon>{' '}
                            </FieldRow>
                        )}
                    </FieldRowContainer>
                    <FieldRowContainer>
                        <FieldRow label="Job Title">
                            {role?.jobTitle && (ledgercommRoleLabels[role.jobTitle] || role.jobTitle)}
                        </FieldRow>
                    </FieldRowContainer>
                    <FieldRowContainer>
                        {role?.totpRequired && (
                            <FieldRow label="Two-Factor Auth Required">
                                {totpRequiredLabels[role.totpRequired]}
                            </FieldRow>
                        )}
                        {(isSelfEditing || isAdmin) && (
                            <FieldRow label="Two-factor authentication">
                                <TOTPSwitch
                                    role={role}
                                    disabled={isAdmin && !isSelfEditing && !role?.totpActivated}
                                    onConfirm={updateData}
                                />
                            </FieldRow>
                        )}
                        {role?.sessionTimeout && (
                            <FieldRow label={'Inactivity timeout'}>
                                {formatNumber(role.sessionTimeout / 60)} min
                            </FieldRow>
                        )}
                        {role?.autoLogoutAt && role?.timezone && (
                            <FieldRow label={'Automatically log out after specific time'}>
                                {fromDateToTime(role.autoLogoutAt)} {timezonesLabels[role.timezone]}
                            </FieldRow>
                        )}
                        {role?.singleSession !== null && (
                            <FieldRow label={'Log out from all sessions after login'}>
                                {role?.singleSession ? 'Yes' : 'No'}
                            </FieldRow>
                        )}
                    </FieldRowContainer>
                    <FieldRowContainer>
                        <FieldRow label="Login">{role?.login}</FieldRow>
                        <FieldRow label="E-mail">
                            <RowStack justifyContent={'flex-end'}>
                                <VerifiedRoleEmailIcon role={role} updateData={updateData} />
                                <span>{role?.email}</span>
                            </RowStack>
                        </FieldRow>
                        <FieldRow label="Phone">{role?.phone || <NoValuePlaceholder />}</FieldRow>
                    </FieldRowContainer>
                    {isDirector && (
                        <FieldRowContainer elevation={0} sx={{ padding: '0 14px' }}>
                            <FieldRow label={'Nationality'}>
                                <CountryFlagAndName country={role?.nationality || ''} showFullName />
                            </FieldRow>
                            <FieldRow label={'Date of birth'}>{dateFromISOToFormat(role?.birthday)}</FieldRow>
                            <FieldRow label={'Address'}>
                                <AddressDisplay address={role?.address} />
                            </FieldRow>
                        </FieldRowContainer>
                    )}
                </Masonry>
            </Box>
        </Stack>
    );
};
