import React from 'react';
import Box from '@mui/material/Box';
import { Breadcrumbs } from '@mui/material';
import { AppHeaderContainer } from '../../../../components/AppHeader/AppHeaderContainer';
import { AppHeaderSection } from '../../../../components/AppHeader/AppHeaderSection';
import { ProfileButton } from '../../../../components/AppHeader/ProfileButton';
import { AppContainer } from '../../../../../lib/cdk/AppContainer';
import { AppHeader } from '../../../../components/AppHeader';
import styles from './ClientOnboardingContainer.module.css';
import { CLIENT_ONBOARDING_ROUTES } from '../../../../App.routes';

interface IClientOnboardingContainerProps {
    title?: string;
    children?: React.ReactNode;
}

export const ClientOnboardingContainer: React.FC<IClientOnboardingContainerProps> = ({ title, children }) => {
    return (
        <>
            <AppHeaderContainer>
                <AppHeader menu={false}>
                    <ProfileButton
                        roleProfileRouteFactory={CLIENT_ONBOARDING_ROUTES.PEOPLE_PREVIEW_ROUTE_FACTORY}
                        logoutOnly
                    />
                </AppHeader>
                {title && (
                    <AppHeaderSection>
                        <Box padding={'24px 0'}>
                            <Breadcrumbs>
                                <span>{title}</span>
                            </Breadcrumbs>
                        </Box>
                    </AppHeaderSection>
                )}
            </AppHeaderContainer>
            <AppContainer className={styles.scrollableArea}>{children}</AppContainer>
        </>
    );
};
