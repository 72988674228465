import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { CalendarPickerView, DatePicker } from '@mui/x-date-pickers';
import { PopperProps as MuiPopperProps } from '@mui/material/Popper/Popper';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay';
import { StandardTextFieldProps, TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';
import { DATE_HOUR_VIEW_FORMAT, DATE_VIEW_FORMAT } from '../utils/date';
import { useFormikField } from '../forms/useFormikField';
import { VALIDATION_MESSAGES } from '../forms';
import { TextInput } from './TextInput';

export interface IDatepickerProps extends Omit<StandardTextFieldProps, 'onChange' | 'value'> {
    value?: Date | undefined;
    onChange?: (value: Date | undefined) => void;
    shouldDisableDate?: (date: Date) => boolean;
    showDaysOutsideCurrentMonth?: boolean;
    minDate?: Date;
    maxDate?: Date;
    renderDay?: (day: DateTime, selectedDays: DateTime[], pickersDayProps: PickersDayProps<DateTime>) => JSX.Element;
    views?: CalendarPickerView[];
    inputFormat?: string;
    PopperProps?: Partial<MuiPopperProps>;
    openTo?: CalendarPickerView;
    dateTime?: boolean;
}

const parseDate = (date: Date | undefined) => {
    if (date) {
        const dateParsed = DateTime.fromJSDate(date);
        return dateParsed.isValid ? dateParsed : void 0;
    }
};

export const Datepicker = ({
    name,
    fullWidth,
    placeholder = 'Select date',
    value: valueProp,
    onChange,
    minDate,
    maxDate,
    disabled,
    renderDay,
    views,
    shouldDisableDate = () => false,
    inputFormat,
    PopperProps,
    openTo = 'day',
    dateTime,
    ...props
}: IDatepickerProps) => {
    const { meta, formik, field, helpers } = useFormikField<Date | undefined>(name);
    const [errorMessage, setErrorMessage] = useState<string>();
    const value = !isNil(valueProp) ? valueProp : field?.value;
    const minDateParsed = parseDate(minDate);
    const maxDateParsed = parseDate(maxDate);

    const handleChange = (newValue: Maybe<DateTime>) => {
        if (newValue && !newValue.isValid) {
            return;
        }
        setErrorMessage(void 0);
        if (onChange) {
            onChange(newValue?.toJSDate());
        } else if (helpers) {
            helpers.setValue(newValue?.toJSDate());
        }
    };

    const renderInput = ({ label, ...params }: MuiTextFieldPropsType) => {
        const error = params.error || props.error || (meta?.touched && Boolean(meta?.error)) || !!errorMessage;
        return (
            <TextInput
                data-testid={`Datepicker${name ? `:${name}` : ''}`}
                {...params}
                {...props}
                name={name}
                onBlur={field?.onBlur}
                inputProps={{ ...params.inputProps, placeholder }}
                InputProps={{ ...props.InputProps, ...params.InputProps, error }}
                value={params.inputProps?.value || props.inputProps?.value}
                onValueChange={(date) => {
                    const dateParsed = parseDate(new Date(date));
                    if (dateParsed && minDateParsed && dateParsed < minDateParsed) {
                        handleChange(null);
                        setErrorMessage(VALIDATION_MESSAGES.MIN_DATE({ min: minDateParsed.toJSDate() }));
                        return;
                    }
                    if (dateParsed && maxDateParsed && dateParsed > maxDateParsed) {
                        handleChange(null);
                        setErrorMessage(VALIDATION_MESSAGES.MAX_DATE({ max: maxDateParsed.toJSDate() }));
                        return;
                    }
                    if (dateParsed && shouldDisableDate(dateParsed.toJSDate())) {
                        handleChange(null);
                        setErrorMessage('This date is unavailable to select');
                        return;
                    }
                    setErrorMessage(void 0);
                }}
                helperText={errorMessage || props.helperText}
                sx={{ minWidth: '140px', ...props.sx }}
                showValidationMessage={!errorMessage}
                fullWidth={fullWidth}
                autoComplete={'off'}
                error={error}
            />
        );
    };

    if (dateTime) {
        return (
            <DateTimePicker
                openTo={openTo}
                value={value || null}
                onChange={handleChange}
                inputFormat={inputFormat || DATE_HOUR_VIEW_FORMAT}
                minDate={minDateParsed}
                maxDate={maxDateParsed}
                OpenPickerButtonProps={{
                    color: 'secondary',
                    sx: { padding: '6px' },
                }}
                PopperProps={PopperProps}
                renderInput={renderInput}
                shouldDisableDate={(date) => shouldDisableDate(date instanceof DateTime ? date.toJSDate() : date)}
                disabled={disabled || formik?.isSubmitting}
                renderDay={renderDay}
                disableMaskedInput
                ampm={false}
            />
        );
    }

    return (
        <DatePicker
            views={views}
            openTo={openTo}
            value={value || null}
            onChange={handleChange}
            inputFormat={inputFormat || DATE_VIEW_FORMAT}
            minDate={minDateParsed}
            maxDate={maxDateParsed}
            OpenPickerButtonProps={{
                color: 'secondary',
                sx: { padding: '6px' },
            }}
            PopperProps={PopperProps}
            renderInput={renderInput}
            shouldDisableDate={(date) => shouldDisableDate(date instanceof DateTime ? date.toJSDate() : date)}
            disabled={disabled || formik?.isSubmitting}
            renderDay={renderDay}
            disableMaskedInput
        />
    );
};
