import React from 'react';
import { List } from '../../../../lib/dataDisplay/List/List';
import { IEmptyStatePlaceholderProps } from '../../EmptyStatePlaceholder';
import { IDocumentEdit, ITaxIdEditDialogValue } from './TaxIdEditDialog/TaxIdEditDialog.const';
import { TaxIdInput } from '../../../../interfaces/model';
import { ITaxIdsListItem, TaxIdsListRow } from './TaxIdsListRow';
import { IListColumn } from '../../../../lib/dataDisplay/List';
import { NonFalsy } from '../../../../lib/utils/helpers';
import { TaxIdEditDialog } from './TaxIdEditDialog';

export interface ITaxIdsListValue extends ITaxIdEditDialogValue {}

interface ITaxIdsListProps {
    testId?: string;
    editable: boolean;
    taxIds: ITaxIdsListValue[];
    onEditSubmit: (taxId: TaxIdInput, documentEdit: IDocumentEdit) => Promise<void>;
    emptyStatePlaceholderProps?: IEmptyStatePlaceholderProps;
    onDelete: (taxId: UUID) => void;
    showDateColumns?: boolean;
}

const prepareData = (value: ITaxIdEditDialogValue[]): ITaxIdsListItem[] => {
    return value.map((item) => ({
        ...item,
        documentId: item.kycId,
        validFromDate: item.document?.validFromDate,
        validToDate: item.document?.validToDate,
    }));
};

export const TaxIdsList: React.FC<ITaxIdsListProps> = ({
    editable,
    taxIds,
    onEditSubmit,
    onDelete,
    showDateColumns,
    emptyStatePlaceholderProps,
    testId,
}) => {
    const columns: IListColumn<ITaxIdsListItem>[] = (
        [
            { id: 'country', label: 'Tax Jurisdiction' },
            { id: 'taxIdValue', label: 'Tax ID Value' },
            showDateColumns && ({ id: 'validFromDate', label: 'Document date' } as const),
            showDateColumns && ({ id: 'validToDate', label: 'Valid to' } as const),
            { id: 'actions', label: 'Actions', shrink: true, sort: false },
        ] as const
    ).filter(NonFalsy);

    return (
        <div>
            <List
                data={prepareData(taxIds)}
                testId={testId}
                columns={columns}
                defaultSortBy={'country'}
                rowComponent={TaxIdsListRow}
                rowComponentProps={{ onDelete, editable }}
                emptyStatePlaceholderProps={emptyStatePlaceholderProps || { title: "No Tax ID's added yet" }}
            />
            <TaxIdEditDialog countriesBlacklist={taxIds?.map((taxId) => taxId.country)} onSubmit={onEditSubmit} />
        </div>
    );
};
