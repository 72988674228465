import { MutableRefObject, useState } from 'react';

import { useElementResizeLayoutEffect } from '../../../../lib/utils/useElementResizeLayoutEffect';

export const useContainerChildrenVisibilityEffect = (
    containerRef: MutableRefObject<HTMLElement | undefined>,
    props: { itemMarginRight?: number } = {}
) => {
    const [itemsVisible, setItemsVisible] = useState<boolean[]>([]);
    useElementResizeLayoutEffect(containerRef, () => {
        const containerEl = containerRef.current;
        const containerRects = containerEl!.getBoundingClientRect();
        const menuItemsXEndCoordinates = Array.prototype.map.call(containerEl?.childNodes, (child) => {
            const childRects = child.getBoundingClientRect();
            return childRects.left + childRects.width - containerRects.left;
        }) as number[];

        let updateState = false;
        const items = menuItemsXEndCoordinates.map((itemXEndCoordinate, index) => {
            const displayItem = itemXEndCoordinate + (props.itemMarginRight || 0) <= containerRects.width;
            if (displayItem !== itemsVisible[index]) {
                updateState = true;
            }
            return displayItem;
        });

        if (updateState) {
            setItemsVisible(items);
        }
    });
    return itemsVisible;
};
