import React from 'react';
import { BaseFormComponent, IBaseFormComponentProps } from './BaseFormComponent';
import { ISelectOption } from '../../inputs/Select.const';
import { Dropdown } from '../../inputs/Dropdown';

interface IFormDropDownProps<TValue> extends Omit<IBaseFormComponentProps, 'onChange'> {
    options: ISelectOption<string, TValue>[];
    onChange?: (value: ISelectOption<string, TValue>) => void;
    getOptionDisabled?: (option: ISelectOption<string, TValue>) => boolean;
    getDisabledOptionTooltipText?: (option: ISelectOption<string, TValue>) => React.ReactNode;
}

export const booleanOptions = [
    { id: 'true', name: 'Yes', value: true },
    { id: 'false', name: 'No', value: false },
];

export function FormDropDown<TValue>({
    name,
    options,
    placeholder,
    helperText,
    disabled,
    onChange,
    getOptionDisabled,
    getDisabledOptionTooltipText,
    ...props
}: IFormDropDownProps<TValue>) {
    return (
        <BaseFormComponent name={name} {...props}>
            <Dropdown
                name={name}
                placeholder={placeholder}
                options={options}
                onChange={onChange}
                helperText={helperText}
                disabled={disabled}
                getOptionDisabled={getOptionDisabled}
                getDisabledOptionTooltipText={getDisabledOptionTooltipText}
                fullWidth
            />
        </BaseFormComponent>
    );
}
