import React from 'react';
import Box from '@mui/material/Box';
import { List, ListItem, ListSubheader, Tooltip } from '@mui/material';
import { LinkWithIcon } from '../../../../lib/navigation/LinkWithIcon';
import { AGENT_VIEW_ROUTE_FACTORY } from '../../../App.routes';

const UsersList: React.FC<{ title: React.ReactNode; value: { name: string; shortCode: Maybe<string> }[] }> = ({
    title,
    value,
}) => (
    <List subheader={<ListSubheader>{title}</ListSubheader>} disablePadding>
        {value.map(({ name, shortCode }) => (
            <ListItem key={name + shortCode}>
                <LinkWithIcon to={AGENT_VIEW_ROUTE_FACTORY(shortCode!)}>{name}</LinkWithIcon>
            </ListItem>
        ))}
    </List>
);

export interface IDocumentApprovalCountProps {
    title: React.ReactNode;
    value: number;
    total: number;
    agents: { name: string; shortCode: Maybe<string> }[];
}

export const DocumentApprovalCount: React.FC<IDocumentApprovalCountProps> = ({ title, value, total, agents }) => {
    const content = `${value} of ${total}`;

    if (agents.length > 0) {
        return (
            <Tooltip title={<UsersList title={title} value={agents} />}>
                <Box sx={{ cursor: 'pointer' }}>{content}</Box>
            </Tooltip>
        );
    }

    return <span>{content}</span>;
};
