import React from 'react';
import cl from 'classnames';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { DASHBOARD_ROUTE } from '../../App.routes';
import styles from './AppHeader.module.css';
import { AppMenu } from './AppMenu';

interface IAppHeaderProps {
    className?: string;
    menu?: boolean;
    children?: React.ReactNode;
}

export const AppHeader: React.FC<IAppHeaderProps> = ({ children, menu = true, className }) => {
    return (
        <Box className={cl(styles.header, className)} display={'flex'}>
            <Link to={DASHBOARD_ROUTE} className={styles.logoLink}>
                <img className={styles.logo} src={'/img/ledgercomm-logo-white-text.svg'} alt={'app-logo'} />
                <img className={styles.logoSmall} src={'/img/ledgercomm-logo.svg '} alt={'app-logo'} />
            </Link>
            {menu && <AppMenu />}
            <Stack direction={'row'} spacing={1} alignItems={'center'} marginLeft={'auto'}>
                {children}
            </Stack>
        </Box>
    );
};
