import {
    accordionClasses,
    accordionSummaryClasses,
    buttonClasses,
    buttonGroupClasses,
    darken,
    dialogContentClasses,
    inputBaseClasses,
    inputClasses,
} from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import { extendTheme } from 'lib/cdk/lib.const';

export const ledgercommTheme = (theme: Theme) =>
    extendTheme(theme)({
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        background: 'var(--bg-paper)',
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        minWidth: '100px',
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        paddingTop: '12px',
                        paddingRight: '32px',
                        paddingBottom: '12px',
                        paddingLeft: '24px',

                        '&:hover': {
                            background: '#191d2e',
                            color: theme.palette.text.primary,
                        },
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '16px',
                        padding: '12px 24px',
                        borderBottom: '1px solid var(--bg-default)',
                        background: 'var(--bg-paper)',

                        '& > [class*=MuiTypography-root]': {
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            fontSize: '16px',
                        },

                        [`+ .${dialogContentClasses.root}.${dialogContentClasses.root}`]: {
                            paddingTop: '24px',
                        },
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        padding: '24px',
                        lineHeight: '22px',
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        background: '#24283f',
                        borderTop: '1px solid var(--bg-default)',
                        padding: '18px 24px 24px 24px',
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        backgroundImage: 'none',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        alignItems: 'center',
                        display: 'flex',
                        textTransform: 'initial',
                        transition: '0.2s ease color',

                        letterSpacing: 'unset',
                        padding: '12px 22px',
                        fontSize: '14px',
                        textDecoration: 'none',
                        lineHeight: 1,
                        '&:hover': {
                            color: theme.palette.text.primary,
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        [`&.${buttonClasses.outlined}`]: {
                            [`&.${buttonClasses.disabled}`]: {
                                cursor: 'not-allowed',
                                pointerEvents: 'all',
                                backgroundColor: 'transparent',
                            },
                            [`&.${buttonClasses.outlinedError}`]: {
                                borderColor: theme.palette.error.light,
                            },
                        },
                        [`&.${buttonClasses.disabled}`]: {
                            color: '#7a7d8b',
                            cursor: 'not-allowed',
                        },
                        'button&.success': {
                            backgroundColor: theme.palette.success.main,
                            color: theme.palette.success.contrastText,
                            '&:hover': {
                                backgroundColor: theme.palette.success.dark,
                            },
                            [`&.${buttonClasses.disabled}`]: {
                                backgroundColor: theme.palette.success.light,
                                color: theme.palette.success.contrastText,
                            },
                        },
                        'button&.error': {
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.error.contrastText,
                            '&:hover': {
                                backgroundColor: theme.palette.error.dark,
                            },
                            [`&.${buttonClasses.disabled}`]: {
                                backgroundColor: theme.palette.error.light,
                                color: theme.palette.error.contrastText,
                            },
                        },
                    },
                    containedPrimary: {
                        '&[class*=disabled]': {
                            backgroundColor: '#33498a',
                        },
                    },
                    text: {
                        padding: '12px 8px',
                    },
                    contained: {
                        '&[class*=Neutral]': {
                            background: '#e0e0e0',
                            borderColor: '#e0e0e0',
                            color: alpha('#000', 0.87),
                        },
                    },
                    outlined: {
                        '&[class*=Neutral]': {
                            borderColor: '#ffffff',
                            [`&.${buttonClasses.disabled}`]: {
                                borderColor: 'rgba(255,255,255,0.56)',
                            },
                        },
                        padding: '11px 22px',
                    },
                    sizeLarge: {
                        fontSize: '14px',
                        lineHeight: 1.2,
                    },
                    sizeSmall: {
                        fontSize: '14px',
                    },
                    textSizeSmall: {
                        padding: '8px 11px',
                    },
                    outlinedSizeSmall: {
                        padding: '7px 11px',
                    },
                    containedSizeSmall: {
                        padding: '8px 12px',
                    },
                    outlinedSizeLarge: {
                        padding: '14px 21px',
                    },
                    textSizeLarge: {
                        padding: '15px 21px',
                    },
                    containedSizeLarge: {
                        padding: '15px 21px',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 'var(--border-radius-sm)',
                    },
                    input: {
                        padding: '9px 11px',
                        color: 'white',
                    },
                    inputSizeSmall: {
                        padding: 8,
                    },
                    multiline: {
                        padding: 0,
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        display: 'flex',
                        alignItems: 'center',
                        padding: 9,
                        lineHeight: 1,
                        height: 'inherit',
                        color: 'white',
                        minWidth: 140,

                        '&:focus': {
                            backgroundColor: 'transparent',
                        },
                        '&.Mui-disabled': {
                            opacity: 0.4,
                            cursor: 'not-allowed',
                        },
                        '& .select-placeholder': {
                            color: theme.palette.secondary.main,
                            opacity: 0.5,
                        },
                    },
                    icon: {
                        right: 8,
                        color: theme.palette.secondary.main,
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '&.error': {
                            color: theme.palette.error.main,
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    underline: {
                        '&:before': {
                            borderBottom: 'none',
                        },
                        '&:after': {
                            borderBottom: 'none',
                        },
                        [`&:hover:not(.${inputClasses.disabled}):after`]: {
                            borderBottom: 'none',
                        },
                        [`&:hover:not(.${inputClasses.disabled}):before`]: {
                            borderBottom: 'none',
                        },
                        [`&.${inputClasses.focused}:before`]: {
                            borderBottom: 'none',
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        border: '1px solid var(--secondary)',
                        boxSizing: 'border-box',
                        borderRadius: 'var(--border-radius-sm)',
                        fontFamily: 'inherit',
                        lineHeight: 'inherit',
                        fontSize: 'inherit',
                        transition: '0.2s ease border-color, 0.2s ease box-shadow',
                        padding: 0,

                        '.MuiInputBase-input': {
                            lineHeight: '100%',
                        },

                        '&:hover:not(.Mui-disabled):not(.Mui-error)': {
                            borderColor: theme.palette.primary.dark,
                            '&:before': {
                                border: 0,
                            },
                        },

                        [`&.${inputBaseClasses.disabled}`]: {
                            opacity: 0.4,
                            cursor: 'not-allowed',
                            position: 'relative',

                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                bottom: 0,
                                borderBottom: '2px dotted',
                                pointerEvents: 'none',
                                transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                width: '100%',
                            },

                            '& > *': {
                                pointerEvents: 'none',
                            },
                        },

                        '& fieldset': {
                            border: 'none',
                        },

                        [`&.${inputBaseClasses.focused}`]: {
                            borderColor: theme.palette.primary.main,
                            boxShadow: `0px 0px 0px 2px ${alpha(theme.palette.primary.main, 0.2)}`,

                            '& fieldset': {
                                borderRadius: 'var(--border-radius-xs)',
                            },

                            [`'& .${inputBaseClasses.inputMultiline}'`]: {
                                borderRadius: 'var(--border-radius-xs)',
                            },
                        },
                        [`&.${inputBaseClasses.error}`]: {
                            borderColor: theme.palette.error.main,
                            '&:hover:not(.Mui-disabled)': {
                                borderColor: theme.palette.error.dark,
                            },

                            '&.Mui-focused': {
                                boxShadow: `0px 0px 0px 2px ${alpha(theme.palette.error.main, 0.2)}`,
                            },
                        },

                        '&.MuiInputBase-colorWarning': {
                            borderColor: 'var(--warning-light)',
                            '&.Mui-focused': {
                                boxShadow: '0 0 0 2px var(--warning-a20)',
                            },
                            '&:hover:not(.Mui-disabled):not(.Mui-error)': {
                                borderColor: 'var(--warning)',
                            },
                            '& + .MuiFormHelperText-root': {
                                color: 'var(--warning-light)',
                            },
                        },
                    },
                    input: {
                        padding: 10,
                        '&::placeholder': {
                            color: theme.palette.secondary.main,
                            opacity: 0.5,
                        },
                    },
                    inputMultiline: {
                        margin: '0',
                        fontFamily: 'inherit',
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        border: 'none',
                        color: 'var(--text-primary)',
                        width: '100%',
                        padding: '10px',
                    },
                    multiline: {
                        padding: '0',
                    },
                    sizeSmall: {
                        '& input': {
                            padding: '8px',
                            height: '16px',
                        },
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: 'var(--bg-paper)',
                        boxShadow: '0px 6px 40px 0px #090d0d33',
                        color: theme.palette.text.primary,
                        fontSize: '14px',
                        lineHeight: 1.2,
                        padding: '16px 24px',
                        fontWeight: 400,
                    },
                    arrow: {
                        color: 'var(--bg-paper)',
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        marginLeft: 4,
                        wordBreak: 'break-word',
                        '&:first-letter': {
                            textTransform: 'capitalize',
                        },
                        fontSize: '12px',
                    },
                    contained: {
                        marginLeft: 4,
                    },
                },
            },
            MuiBreadcrumbs: {
                styleOverrides: {
                    root: {
                        fontSize: '16px',
                        lineHeight: 1.2,
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        '& *': {
                            fontSize: '16px',
                            lineHeight: 1.2,
                            fontWeight: 600,
                            color: 'inherit',
                            transition: '0.2s ease color',
                            textDecoration: 'none',
                            '&:hover': {
                                textDecoration: 'none',
                            },
                        },
                        '& span:last-child': {
                            color: theme.palette.text.secondary,
                        },
                    },
                    separator: {
                        color: theme.palette.text.secondary,
                        marginLeft: '6px',
                        marginRight: '6px',
                        '&:first-of-type': {
                            marginLeft: 0,
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: 7,
                    },
                    sizeSmall: {
                        fontSize: 18,
                        padding: 6,
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        alignSelf: 'center',
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        padding: '16px 32px 16px 16px',

                        '& .MuiAlert-icon': {
                            color: 'currentcolor',
                        },
                    },
                    message: {
                        fontSize: '12px',
                        lineHeight: 1.667,
                        fontWeight: 500,
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                    },
                    icon: {
                        padding: 0,
                        marginRight: '16px',
                        marginBottom: 'auto',
                        marginTop: 'auto',
                    },
                    action: {
                        marginRight: '-16px',
                        padding: '0 0 0 16px',
                        alignItems: 'center',
                    },
                    filledError: {
                        background: theme.palette.error.main,
                        color: darken(theme.palette.error.main, 0.8),
                    },
                    filledSuccess: {
                        background: theme.palette.success.main,
                        color: darken(theme.palette.success.main, 0.8),
                    },
                    standardSuccess: {
                        background: alpha(theme.palette.success.main, 0.15),
                        color: theme.palette.text.primary,
                    },
                    standardWarning: {
                        backgroundColor: alpha(theme.palette.warning.main, 0.3),
                        color: theme.palette.warning.light,
                    },
                    standardInfo: {
                        backgroundColor: 'var(--primary-a20)',
                        color: 'var(--text-primary)',
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        position: 'relative',
                        marginBottom: 16,

                        '&:before': {
                            content: '""',
                            position: 'absolute',
                            display: 'flex',
                            height: 2,
                            background: 'var(--divider)',
                            width: '100%',
                            bottom: 0,
                            zIndex: 2,
                        },
                    },
                    scrollButtons: {
                        position: 'absolute',
                        top: 0,
                        zIndex: 1,
                        height: '100%',
                        background: 'var(--bg-app)',
                        opacity: 0.9,
                        '&:first-of-type': {
                            left: 0,
                        },
                        '&:last-of-type': {
                            right: 0,
                        },
                    },
                    indicator: {
                        zIndex: 3,
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        padding: '6px 12px',
                        transition: '0.2s ease color',
                        [theme.breakpoints.up('sm')]: {
                            minWidth: 100,
                        },

                        '&:hover': {
                            color: theme.palette.primary.dark,
                            textDecoration: 'none',
                        },
                    },
                },
            },
            MuiStepper: {
                styleOverrides: {
                    root: {
                        background: 'transparent',
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.primary,
                        lineHeight: '16px',
                        marginBottom: '8px',
                        fontWeight: 500,

                        '&.Mui-focused': {
                            color: theme.palette.text.primary,
                        },
                    },
                    asterisk: {
                        color: theme.palette.error.main,
                        fontSize: 16,
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        paddingBottom: 0,
                    },
                    inputRoot: {
                        '&[class*="MuiOutlinedInput-root"]': {
                            paddingTop: '11px',
                            paddingRight: '34px',
                            paddingBottom: '11px',
                            paddingLeft: '12px',

                            '&.MuiInputBase-sizeSmall': {
                                paddingTop: '8px',
                                paddingBottom: '8px',
                            },

                            '& :first-of-type[class*="MuiInputBase-input"]': {
                                height: '16px',
                                padding: 0,
                            },
                        },
                    },
                    option: {
                        paddingTop: '12px',
                        paddingRight: '32px',
                        paddingBottom: '12px',
                        paddingLeft: '24px',
                    },
                    endAdornment: {
                        '& [class*="MuiSvgIcon"]': {
                            color: theme.palette.secondary.main,
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginLeft: '-6px',
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        padding: '4px',
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        marginBottom: '4px',
                        background: theme.palette.background.paper,
                        transition: '0.2s ease background-color',

                        [`&.${accordionClasses.expanded}`]: {
                            marginTop: 0,
                            marginBottom: '4px',
                        },

                        [`&.${accordionClasses.rounded}`]: {
                            borderRadius: 'var(--border-radius-xs)',
                            '&:first-of-type': {
                                borderTopLeftRadius: 'var(--border-radius-xs)',
                                borderTopRightRadius: 'var(--border-radius-xs)',
                            },
                            '&:last-child': {
                                borderBottomLeftRadius: 'var(--border-radius-xs)',
                                borderBottomRightRadius: 'var(--border-radius-xs)',
                            },
                        },
                        '&:before': {
                            content: 'none',
                        },
                    },
                },
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        padding: '14px',
                        minHeight: '40px',
                        borderRadius: 'var(--border-radius-xs)',
                        transition: '0.2s ease background-color',

                        [`&:hover:not(.${accordionSummaryClasses.expanded})`]: {
                            background: 'var(--bg-paper-light)',
                        },

                        [`&.${accordionSummaryClasses.expanded}`]: {
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            background: 'var(--bg-paper-light)',
                            minHeight: '42px',
                        },

                        [`& .${accordionSummaryClasses.content}`]: {
                            [`&.${accordionSummaryClasses.expanded}`]: {
                                margin: 0,
                            },
                        },
                    },
                    content: {
                        margin: 0,
                        fontWeight: 500,
                    },
                },
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: '24px',
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    avatar: {
                        color: 'inherit',
                    },
                },
            },
            MuiTimelineItem: {
                styleOverrides: {
                    root: {
                        minHeight: 'unset',
                    },
                },
            },
            MuiTimelineContent: {
                styleOverrides: {
                    root: {
                        paddingTop: '8px',
                        fontWeight: 500,
                    },
                },
            },
            MuiTimelineDot: {
                styleOverrides: {
                    root: {
                        padding: '6px',
                        margin: 0,
                    },
                },
            },
            MuiTimelineSeparator: {
                styleOverrides: {
                    root: {
                        justifyContent: 'center',
                    },
                },
            },
            MuiTimelineConnector: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'var(--secondary)',
                    },
                },
            },
            MuiTreeItem: {
                styleOverrides: {
                    content: {
                        transition: '0.2s ease background-color',
                        '&:hover': {
                            backgroundColor: 'var(--primary-a20)',
                        },
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: '20px 24px',
                    },
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: '14px 24px',
                        background: '#191D2E4c',
                        borderTop: '1px solid var(--bg-default)',
                    },
                },
            },
            MuiLinearProgress: {
                styleOverrides: {
                    root: {
                        borderRadius: '4px',
                    },
                    bar: {
                        borderRadius: '4px',
                    },
                },
            },
            MuiStepLabel: {
                styleOverrides: {
                    label: {
                        fontSize: '14px',
                        fontWeight: 500,
                    },
                },
            },
            MuiStepContent: {
                styleOverrides: {
                    root: {
                        paddingLeft: '16px',
                    },
                },
            },
            MuiButtonGroup: {
                styleOverrides: {
                    root: {
                        [`& .${buttonGroupClasses.grouped}.${buttonClasses.outlinedSecondary}`]: {
                            borderColor: 'var(--secondary-a50)',
                            borderRightColor: 'var(--secondary-a50)',
                        },
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        fontWeight: 500,

                        '&.Mui-disabled': {
                            color: 'var(--text-secondary-dark)',
                        },
                    },
                },
            },
            MuiDayPicker: {
                styleOverrides: {
                    weekDayLabel: {
                        fontSize: '14px',
                        fontWeight: 500,
                    },
                },
            },
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    labelContainer: {
                        maxHeight: '40px',
                    },
                },
            },
            MuiCalendarPicker: {
                styleOverrides: {
                    viewTransitionContainer: {
                        overflow: 'hidden',
                    },
                },
            },
            MuiListSubheader: {
                styleOverrides: {
                    root: {
                        lineHeight: 1.2,
                        marginBottom: '16px',
                    },
                },
            },
        },
    });
