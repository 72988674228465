import Box from '@mui/material/Box';
import { FormikContext } from 'formik';
import Stack from '@mui/material/Stack';
import React, { useContext } from 'react';
import FormLabel from '@mui/material/FormLabel';
import { useFieldsetExpandOnErrorEffect } from '../../../../../../lib/forms/FieldsetCollapsible/useFieldsetExpandOnErrorEffect';
import { CountrySelect } from '../../../../../../lib/inputs/CountrySelect/CountrySelect';
import { ReadonlyValue } from '../../../../../../lib/dataDisplay/ReadonlyValue';
import { IBusinessProfile } from './businessProfileValidationSchema';

interface IBusinessProfileSetupFormProps {
    disabled: boolean;
    companyLabel: string;
}

export const BusinessProfileSetupForm: React.FC<IBusinessProfileSetupFormProps> = ({ disabled, companyLabel }) => {
    const formik = useContext(FormikContext);
    const { values } = formik;

    useFieldsetExpandOnErrorEffect<IBusinessProfile>(['name', 'headquarters']);

    return (
        <Stack spacing={2} maxWidth={'80%'}>
            <Stack direction={'row'} spacing={2}>
                <Box flex={1}>
                    <FormLabel>{companyLabel} Name</FormLabel>
                    <ReadonlyValue value={values.name} />
                </Box>
                <Box flex={1}>
                    <FormLabel htmlFor={'headquarters'} required>
                        Headquartered country
                    </FormLabel>
                    <CountrySelect name={'headquarters'} disabled={disabled} fullWidth />
                </Box>
            </Stack>
        </Stack>
    );
};
