import React from 'react';
import Box from '@mui/material/Box';
import { Button, Stack, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
    AGENTS_FIELD_ID,
    AUTHORISED_SIGNATURES_FIELD_ID,
    kycCompleteStatusColors,
    kycCompleteStatusLabels,
} from '../../../../App.const';
import { joinQueryParams } from '../../../../../lib/utils/router/joinQueryParams';
import { KYCCompleteStatus } from '../../../../../interfaces/model';
import { RowStack } from '../../../../../lib/cdk/RowStack';
import { IsCompleteIcon } from '../../../../../lib/icons/IsCompleteIcon';
import { Warning } from '@mui/icons-material';
import { NonFalsy } from '../../../../../lib/utils/helpers';

interface IAgentsKYCStatusProps {
    value: KYCCompleteStatus | undefined;
    hasAuthorisedSignatures?: boolean;
    hasAgents: boolean;
    hasEditAccess: boolean;
    config: {
        authorisedSignatures?: boolean;
        agents?: boolean;
    };
}

const Row: React.FC<{ label: string; id: string; hasEditAccess: boolean }> = ({ label, id, hasEditAccess }) => {
    const location = useLocation();
    return (
        <RowStack spacing={2}>
            <RowStack spacing={1}>
                <Warning fontSize={'small'} className={'error'} />
                <span>{label}</span>
            </RowStack>
            <Box display={'flex'} justifyContent={'flex-end'} flex={1}>
                {hasEditAccess && (
                    <Button
                        sx={{ marginLeft: 'auto' }}
                        component={Link}
                        to={`${location.pathname}${joinQueryParams({ edit: true, focus: id })}`}
                    >
                        Add
                    </Button>
                )}
            </Box>
        </RowStack>
    );
};

const TooltipContent: React.FC<IAgentsKYCStatusProps> = ({
    config,
    hasAgents,
    hasAuthorisedSignatures,
    hasEditAccess,
    value,
}) => {
    if ((!config.authorisedSignatures || hasAuthorisedSignatures) && (!config.agents || hasAgents)) {
        return <span>{kycCompleteStatusLabels[value!]}</span>;
    }

    const rows = [
        !hasAgents && config.agents && { id: AGENTS_FIELD_ID, label: 'No agents' },
        !hasAuthorisedSignatures &&
            config.authorisedSignatures && {
                id: AUTHORISED_SIGNATURES_FIELD_ID,
                label: 'No authorized signatures',
            },
    ].filter(NonFalsy);

    return (
        <Stack spacing={1}>
            {rows.map((item) => (
                <Row {...item} hasEditAccess={hasEditAccess} />
            ))}
        </Stack>
    );
};

export const AgentsKYCStatus: React.FC<IAgentsKYCStatusProps> = (props) => {
    const { value } = props;

    return (
        <Tooltip title={<TooltipContent {...props} />}>
            <RowStack alignItems={'center'} sx={{ cursor: 'pointer' }} data-testid={`AgentsKYCStatus`}>
                <IsCompleteIcon complete={value === KYCCompleteStatus.COMPLETE} tooltip={false} />
                <Box paddingTop={'2px'} className={kycCompleteStatusColors[value!]}>
                    {kycCompleteStatusLabels[value!]}
                </Box>
            </RowStack>
        </Tooltip>
    );
};
