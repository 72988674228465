import React, { useContext } from 'react';
import { DocumentUploadForm } from '../../../../components/Documents/components/DocumentUploadDialog/DocumentUploadForm';
import { TaxIdInformationAlert } from './TaxIdInformationAlert';
import { Fieldset } from '../../../../../lib/forms/Fieldset';
import { Country } from '../../../../../interfaces/model';
import { FormikContext } from 'formik';

interface ITaxIdEditFormProps {
    countriesBlacklist: Country[] | undefined;
}

export const TaxIdEditForm: React.FC<ITaxIdEditFormProps> = ({ countriesBlacklist }) => {
    const formik = useContext(FormikContext);

    return (
        <Fieldset
            title={'Withholding Tax ID Forms'}
            description={'Please add relevant Double Taxation Tax ID for this entity'}
            spacing={3}
        >
            <TaxIdInformationAlert />
            <DocumentUploadForm
                taxIdCountriesBlacklist={countriesBlacklist}
                config={{ fileChangingEnabled: !formik.values.id }}
            />
        </Fieldset>
    );
};
