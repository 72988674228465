import { CombinedState, combineReducers, StateFromReducersMapObject } from 'redux';
import {
    appConfig,
    appError,
    appInitialized,
    appSnackbar,
    appSpinner,
    menuItems,
    userConfig,
} from '../App/App.reducers';
import { dialogState } from './dialogState/dialogState.reducer';

export const appInitialState: IAppState = {
    appError: {},
    appInitialized: false,
    appSpinner: { show: false },
    appSnackbar: {},
    appConfig: {},
    userConfig: {},
    menuItems: [],
    dialogState: {},
};

const reducersMap = {
    appError,
    appInitialized,
    appSpinner,
    appSnackbar,
    appConfig,
    userConfig,
    menuItems,
    dialogState,
};

export const rootReducer = combineReducers(reducersMap);

export type IAppState = CombinedState<StateFromReducersMapObject<typeof reducersMap>>;
