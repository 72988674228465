import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { getEntityListRouteByType, getEntityViewRouteByType } from '../../../App.routes';
import { Breadcrumb } from 'lib/navigation/Breadcrumbs/Breadcrumb';
import { isTopCoModel } from '../../../helpers/isTopCoModel';
import { EntityType } from 'interfaces/model';

interface IEntityBreadcrumbsProps {
    entity:
        | {
              id: UUID;
              topCoRef: UUID;
              entityType: EntityType;
              name: string;
              topCo?: Maybe<{ name: string; shortCode: Maybe<string> }>;
          }
        | undefined;
    interactive: boolean | undefined;
    pageName?: React.ReactNode;
}

export const getEntityTitlePlural = (entityType: EntityType | undefined) => {
    switch (entityType) {
        case EntityType.AGENT:
        case EntityType.AGENT_TOP_LEVEL:
            return 'Agents';
        case EntityType.SPONSOR:
        case EntityType.SPONSOR_TOP_LEVEL:
            return 'Sponsors';
        case EntityType.BORROWER_SUBSIDIARY:
        case EntityType.BORROWER_TOP_LEVEL:
            return 'Borrowers';
        case EntityType.FUNDADMIN:
        case EntityType.FUNDADMIN_TOP_LEVEL:
            return 'Fund Administrators';
        default:
            return 'Lenders';
    }
};

export const EntityBreadcrumbs: React.FC<IEntityBreadcrumbsProps> = ({ entity, pageName, interactive = true }) => {
    const isTopCoEntity = isTopCoModel(entity);
    const listRoute = interactive ? getEntityListRouteByType(entity?.entityType) : void 0;
    const topCoRoute = interactive ? getEntityViewRouteByType(entity?.entityType)(entity?.topCo?.shortCode!) : void 0;
    return (
        <Breadcrumbs sx={{ marginTop: '8px' }}>
            <Breadcrumb to={listRoute}>{getEntityTitlePlural(entity?.entityType)}</Breadcrumb>
            {(!isTopCoEntity || pageName) && <Breadcrumb to={topCoRoute}>{entity?.topCo?.name}</Breadcrumb>}
            <Breadcrumb>{pageName || entity?.name}</Breadcrumb>
        </Breadcrumbs>
    );
};
