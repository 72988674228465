import React from 'react';
import Button from '@mui/material/Button';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { IDocumentUploadDialogConfig, useDocumentUploadDialog } from '../DocumentUploadDialog';
import { DocPurpose, KYCDocSubcategory, KYCDocType, KYCDocumentFragment } from '../../../../../interfaces/model';
import { IDocumentViewDialogParent } from './interfaces';

interface IDocumentUploadButtonProps {
    docType?: Maybe<KYCDocType>;
    docPurpose: Maybe<DocPurpose> | undefined;
    docSubcategory?: Maybe<KYCDocSubcategory>;
    onUploadSuccess?: (documentId: UUID, purpose: DocPurpose) => Promise<void>;
    onDocumentAssign?: (document: KYCDocumentFragment, purpose: DocPurpose) => Promise<void>;
    parent: IDocumentViewDialogParent;
    config?: IDocumentUploadDialogConfig;
}

export const DocumentUploadButton: React.FC<IDocumentUploadButtonProps> = ({
    docType,
    docSubcategory,
    docPurpose,
    parent,
    onUploadSuccess,
    onDocumentAssign,
    config,
}) => {
    const [documentUploadDialog] = useDocumentUploadDialog();

    return (
        <Button
            color={'primary'}
            variant={'contained'}
            data-testid={'AddDocumentsDialog:uploadNewButton'}
            onClick={() => {
                if (docPurpose) {
                    documentUploadDialog.open({
                        value: { docPurpose, docType, docSubcategory },
                        config,
                        parentRef: parent.id,
                        parentName: parent.name,
                        purpose: docPurpose,
                        onDocumentAssign,
                        onUploadSuccess,
                    });
                }
            }}
        >
            <CloudUploadOutlinedIcon sx={{ margin: '-4px 4px -4px 0' }} />
            Upload new
        </Button>
    );
};
