import React from 'react';
import { Button } from '@mui/material';
import { RoleListRowFragment, ViewEntityFragment } from '../../../../../interfaces/model';
import { AppEmbeddedListWrap } from '../../../../components/AppEmbeddedListWrap';
import { useRoleEditDialog } from '../../../Roles/RoleEditDialog';
import { IListColumn } from '../../../../../lib/dataDisplay/List';
import { IRoleListItem, IRoleListRowConfig } from './RoleList/RoleListRow';
import { RoleList } from './RoleList';
import { isTopCoEntityType } from '../../Entities.helpers';
import { useAddExistingRolesDialog } from '../AddExistingRolesDialog/AddExistingRolesDialog';

interface IEntityRolesProps {
    data: RoleListRowFragment[];
    columns?: IListColumn<IRoleListItem>[];
    entity: Pick<ViewEntityFragment, 'id' | 'caps' | 'topCoRef' | 'entityType'>;
    updateData: () => Promise<void>;
    config?: IRoleListRowConfig;
    actions?: React.ReactNode;
    className?: string;
    testId?: string;
    title?: string;
}

export const EntityRoles: React.FC<IEntityRolesProps> = ({
    entity,
    updateData,
    className,
    actions,
    columns,
    config,
    testId,
    title,
    data,
}) => {
    const isTopCo = isTopCoEntityType(entity.entityType);
    const [{ open: openRoleEditDialog }] = useRoleEditDialog();
    const [addExistingRolesDialog] = useAddExistingRolesDialog();
    const actionButton = entity.caps.createRole && (
        <Button
            color={'primary'}
            variant={'contained'}
            data-testid={`${testId}:addButton`}
            onClick={() => {
                if (isTopCo) {
                    openRoleEditDialog({
                        topCoRef: entity.topCoRef,
                        entityType: entity.entityType,
                        entityRef: entity.id,
                    });
                } else {
                    addExistingRolesDialog.open();
                }
            }}
        >
            Add People
        </Button>
    );

    return (
        <AppEmbeddedListWrap title={title || 'People'} actions={actions || actionButton} className={className}>
            <RoleList testId={testId} columns={columns} config={config} reloadData={updateData} roles={data} />
        </AppEmbeddedListWrap>
    );
};
