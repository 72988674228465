import { CounterpartiesKYCEditMode } from '../../../useCounterpartiesKYCEditUrlState';
import { CounterpartyStatus } from '../../../../../../../interfaces/model';
import { NonFalsy } from '../../../../../../../lib/utils/helpers';

const MODES: Record<CounterpartiesKYCEditMode, (item: { status: Maybe<CounterpartyStatus> }) => boolean> = {
    REVOKE: ({ status }) => status === CounterpartyStatus.APPROVED || status === CounterpartyStatus.IN_PROGRESS,
    APPROVE: ({ status }) => status === CounterpartyStatus.UNAPPROVED || status === CounterpartyStatus.ACTION_REQUIRED,
};

export const isCounterpartyKYCActionAvailable = (
    item: {
        counterpartyKYCStatus?: Maybe<{ status: Maybe<CounterpartyStatus> }[]>;
        children?: Maybe<{ counterpartyKYCStatus: Maybe<{ status: Maybe<CounterpartyStatus> }[]> }[]>;
    },
    mode: CounterpartiesKYCEditMode | undefined
) => {
    return [
        ...(item.counterpartyKYCStatus || []),
        ...(item.children?.flatMap(({ counterpartyKYCStatus }) => counterpartyKYCStatus) || []),
    ]
        .filter(NonFalsy)
        .some(MODES[mode!] || (() => false));
};
