import React from 'react';
import { IEmptyStatePlaceholderProps } from '../../../common/EmptyStatePlaceholder';
import { BankAccountsListRow, IBankAccountsListItem } from './BankAccountsListRow';
import { BankAccountInput, BankAccountPurpose, Currency } from 'interfaces/model';
import { BankAccountEditDialog } from '../BankAccountEditDialog';
import { IListColumn } from 'lib/dataDisplay/List';
import { List } from 'lib/dataDisplay/List/List';
import { NOOP_ASYNC } from 'lib/utils/helpers';

interface IBankAccountsListProps {
    bankAccounts: BankAccountInput[];
    updateData?: () => Promise<void>;
    hasEditAccess?: boolean;
    hasDeleteAccess?: boolean;
    testId?: string;
    emptyStatePlaceholderProps?: IEmptyStatePlaceholderProps;
    onBankAccountSubmit?: (account: BankAccountInput) => Promise<void>;
    onDeleteConfirm?: (bankAccountId: UUID) => Promise<void>;
    availablePurposes?: BankAccountPurpose[];
    availableCurrencies?: Currency[];
    displayEmpty?: boolean;
    embedded?: boolean;
}

const COLUMNS: IListColumn<IBankAccountsListItem>[] = [
    { id: 'accountName', label: 'Name' },
    { id: 'purpose', label: 'Type' },
    { id: 'currency', label: 'Currency' },
    { id: 'actions', label: 'Actions', sort: false, shrink: true },
];

export const BankAccountsList: React.FC<IBankAccountsListProps> = ({
    bankAccounts,
    availablePurposes,
    availableCurrencies,
    emptyStatePlaceholderProps,
    onBankAccountSubmit = NOOP_ASYNC,
    updateData = NOOP_ASYNC,
    onDeleteConfirm = NOOP_ASYNC,
    displayEmpty = true,
    hasDeleteAccess,
    hasEditAccess,
    embedded,
    testId,
}) => {
    return (
        <>
            {(displayEmpty || bankAccounts.length > 0) && (
                <List
                    testId={testId}
                    data={bankAccounts}
                    columns={COLUMNS}
                    rowComponent={BankAccountsListRow}
                    defaultSortBy={'accountName'}
                    emptyStatePlaceholderProps={
                        emptyStatePlaceholderProps || {
                            title: 'No bank accounts added yet',
                        }
                    }
                    rowComponentProps={{
                        updateData,
                        onDeleteConfirm,
                        hasEditAccess: !!hasEditAccess,
                        hasDeleteAccess: !!hasDeleteAccess,
                    }}
                    embedded={embedded}
                />
            )}
            <BankAccountEditDialog
                availablePurposes={availablePurposes}
                availableCurrencies={availableCurrencies}
                onBankAccountSubmit={onBankAccountSubmit}
                existingBankAccounts={bankAccounts}
                onSaveSuccess={updateData}
            />
        </>
    );
};
