import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { MENU_ITEMS_SELECTOR } from '../App.selectors';
import { isMenuItemPathMatchUrl } from './AppHeader';

interface IAppTitleProps {
    value?: string;
    overwritePageTitle?: boolean;
}

const DELIMETER = ' - ';

const useCurrentPageName = () => {
    const location = useLocation();
    const menuItems = useSelector(MENU_ITEMS_SELECTOR);
    return menuItems.find(({ url }) => isMenuItemPathMatchUrl(url, location.pathname))?.label;
};

export const AppTitle: React.FC<IAppTitleProps> = ({ value, overwritePageTitle }) => {
    const pageTitle = useCurrentPageName();
    const getSection = (sectionName: string | undefined) => (sectionName ? `${sectionName}${DELIMETER}` : '');
    return (
        <Helmet>
            <title>{`${getSection(value)}${overwritePageTitle ? '' : getSection(pageTitle)}LedgerComm`}</title>
        </Helmet>
    );
};
