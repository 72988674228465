import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { reviewStatusColors, reviewStatusIcons, reviewStatusLabels } from '../../Documents.const';
import { ReviewStatus } from '../../../../../interfaces/model';
import styles from './KYCReviewStatusChip.module.css';

interface IKYCReviewActionChipProps extends ChipProps {
    value: Maybe<ReviewStatus> | undefined;
}

export const KYCReviewStatusChip: React.FC<IKYCReviewActionChipProps> = ({ value, ...props }) => {
    const Icon = reviewStatusIcons[value!];
    const color = reviewStatusColors[value!];

    return value ? (
        <Chip
            size={'small'}
            variant={'outlined'}
            data-testid={'KYCReviewStatus'}
            {...props}
            avatar={Icon && <Icon className={styles.avatar} />}
            label={reviewStatusLabels[value!]}
            color={color}
        />
    ) : null;
};
