import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeDialogState } from '../../../../../../../../../store/dialogState/makeDialogState';
import { stringIncludesCaseInsensitive } from '../../../../../../../../../lib/utils/helpers';
import {
    KYCDocumentFragment,
    ReviewAction,
    useDocumentReviewsQuery,
} from '../../../../../../../../../interfaces/model';
import { LoadingContentWrap } from '../../../../../../../../../lib/dataDisplay/LoadingContentWrap';
import { DialogCloseButton } from '../../../../../../../../../lib/modals/DialogCloseButton';
import { selectOptionFactory } from '../../../../../../../../../lib/inputs/Select.const';
import { SearchComponent } from '../../../../../../../../../lib/inputs/SearchComponent';
import { DocumentReviewButton } from '../../../../components/DocumentReviewButton';
import { Dropdown } from '../../../../../../../../../lib/inputs/Dropdown';
import { DocumentReviewHistoryList } from '../DocumentReviewHistoryList';
import { reviewActionLabels } from '../../../../../../Documents.const';
import { IDocumentViewDialogParent } from '../../../../interfaces';

interface IReviewHistoryDialogProps {
    updateData: () => Promise<void>;
    kycMode: boolean;
}

interface IReviewHistoryDialogState {
    value: KYCDocumentFragment;
    parent: IDocumentViewDialogParent;
}

export const useReviewHistoryDialog = makeDialogState<IReviewHistoryDialogState>();

const statusFilterOptions = [
    ReviewAction.RED,
    ReviewAction.AMBER,
    ReviewAction.GREEN,
    ReviewAction.DELETED,
    ReviewAction.UPLOADED,
    ReviewAction.RESTORED,
]
    .map(selectOptionFactory(ReviewAction, reviewActionLabels))
    .sort(({ name: nameA }, { name: nameB }) => nameA!.localeCompare(nameB!));

export const ReviewHistoryDialog: React.FC<IReviewHistoryDialogProps> = ({ updateData: updateParentData, kycMode }) => {
    const [{ close }, isOpen, state] = useReviewHistoryDialog();
    const { value, parent } = state || {};

    const { data, error, loading, refetch } = useDocumentReviewsQuery({
        fetchPolicy: 'cache-and-network',
        skip: !isOpen || !value?.id || !parent,
        variables: {
            id: value?.id!,
            kycMode,
        },
    });

    const [searchFilter, setSearchFilter] = useState<string>();
    const [statusFilter, setStatusFilter] = useState<ReviewAction>();

    const clearAndClose = () => {
        setSearchFilter(void 0);
        setStatusFilter(void 0);
        close();
    };

    const updateData = async () => {
        await refetch();
        await updateParentData();
    };

    const reviews =
        data?.document.reviews.filter(({ status, role }) => {
            const userName = role?.name || '';
            const entityName = role?.topCo?.name || '';
            const searchText = searchFilter?.trim();
            return (
                (!statusFilter || status === statusFilter) &&
                (!searchText ||
                    stringIncludesCaseInsensitive(userName, searchText) ||
                    stringIncludesCaseInsensitive(entityName, searchText))
            );
        }) || [];

    return (
        <Dialog maxWidth={'md'} fullWidth open={isOpen} data-testid={'ReviewHistoryDialog'} onClose={clearAndClose}>
            <DialogTitle>
                Review history
                <DialogCloseButton onClick={clearAndClose} />
            </DialogTitle>
            <DialogContent>
                <LoadingContentWrap loading={loading} error={error} size={40} top={'40%'}>
                    <Stack spacing={3}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <SearchComponent
                                size={'medium'}
                                placeholder={'Search'}
                                value={searchFilter}
                                onChange={setSearchFilter}
                            />
                            <Dropdown
                                value={statusFilter}
                                placeholder={'Status'}
                                minWidth={'200px'}
                                onChange={(option) => setStatusFilter(option?.value)}
                                options={statusFilterOptions}
                                clearable
                            />
                        </Box>
                        <DocumentReviewHistoryList
                            value={reviews}
                            highlightText={searchFilter}
                            filtered={Boolean(searchFilter || statusFilter)}
                            parentRef={parent?.id}
                            updateData={updateData}
                        />
                    </Stack>
                </LoadingContentWrap>
            </DialogContent>
            {value && parent?.id && parent?.hasDocumentApproveAccess && (
                <DialogActions>
                    <DocumentReviewButton value={value} parentRef={parent.id} updateData={updateData} />
                </DialogActions>
            )}
        </Dialog>
    );
};
