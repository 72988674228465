import React, { useState } from 'react';
import { IListRow } from '../../../lib/dataDisplay/List';
import { ActionsCell, BooleanCell, LinkCell, ValueCell } from '../../../lib/dataDisplay/List/components/ListCells';
import { AGENT_VIEW_ROUTE_FACTORY } from '../../App.routes';
import { AgentCapabilities } from '../../../interfaces/model';
import { ListIconButton } from '../../../lib/dataDisplay/List/components/ListIconButton';
import Edit from '@mui/icons-material/Edit';
import { useAgentEditDialog } from './AgentEditDialog/AgentEditDialog';
import { CollapsibleRow } from '../../../lib/legacy/CollapsibleRow';
import { CollapseTrigger } from '../../../lib/buttons/CollapseTrigger';
import { ListRow } from '../../../lib/dataDisplay/List/components/ListRow';
import { EntityFundAgentDeleteButton } from './EntityFundAgentDeleteButton';
import { AgentCapabilitiesList } from '../../pages/Entities/ViewEntity/AgentCapabilitiesList/AgentCapabilitiesList';

export interface IEntityFundAgentListItem {
    id: UUID;
    shortCode: string;
    name: string;
    totalPermissions: number;
    manageStructure: boolean;
    uploadKyc: boolean;
    uploadDataroom: boolean;
    capabilities: AgentCapabilities[];
    deleteable: boolean;
    actions?: never;
}

interface IComponentProps {
    onDeleteConfirm: (agentRef: UUID) => void;
    canEditAgents: boolean;
}

type IEntityFundAgentListRowProps = IListRow<IEntityFundAgentListItem, IComponentProps>;

export const EntityFundAgentListRow: React.FC<IEntityFundAgentListRowProps> = ({ data, columns, props }) => {
    const [agentEditDialog] = useAgentEditDialog();
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <>
            <ListRow focused={isExpanded}>
                {columns.map(({ id }) => (
                    <React.Fragment key={id}>
                        {id === 'name' && (
                            <LinkCell to={AGENT_VIEW_ROUTE_FACTORY(data.shortCode)}>{data.name}</LinkCell>
                        )}
                        {id === 'totalPermissions' && (
                            <ValueCell>
                                {data.totalPermissions} of {Object.keys(AgentCapabilities).length}
                            </ValueCell>
                        )}
                        {id === 'manageStructure' && <BooleanCell value={data.manageStructure} />}
                        {id === 'uploadKyc' && <BooleanCell value={data.uploadKyc} />}
                        {id === 'uploadDataroom' && <BooleanCell value={data.uploadDataroom} />}
                        {id === 'actions' && (
                            <ActionsCell align={'right'}>
                                {props.canEditAgents && (
                                    <ListIconButton
                                        icon={Edit}
                                        onClick={() => {
                                            agentEditDialog.open({
                                                agentRef: data.id,
                                                capabilities: data.capabilities,
                                            });
                                        }}
                                    />
                                )}
                                {data.deleteable && props.canEditAgents && (
                                    <EntityFundAgentDeleteButton
                                        agentRef={data.id}
                                        onDeleteConfirm={props.onDeleteConfirm}
                                        agentName={data.name}
                                    />
                                )}
                                <CollapseTrigger isActive={isExpanded} onClick={() => setIsExpanded(!isExpanded)} />
                            </ActionsCell>
                        )}
                    </React.Fragment>
                ))}
            </ListRow>
            <CollapsibleRow expand={isExpanded} colspan={columns.length}>
                <AgentCapabilitiesList capabilities={data.capabilities} />
            </CollapsibleRow>
        </>
    );
};
