import { useState } from 'react';
import { useTOTPConfirmMutation } from '../../../interfaces/model';
import { TOTP_RESPONSE_CODE_LENGTH } from './Login/Login.const';
import { useAppSnackbar } from '../AppNotification';

export const useTotpCodeEnable = (props: { onConfirm: () => void }) => {
    const snackbar = useAppSnackbar();
    const [codeInvalid, setCodeInvalid] = useState(false);
    const [confirmCode, totpConfirmMutation] = useTOTPConfirmMutation();

    const reset = () => {
        setCodeInvalid(false);
        totpConfirmMutation.reset();
    };

    const submitCode = async (code: string | undefined) => {
        if (!code || code?.length !== TOTP_RESPONSE_CODE_LENGTH) {
            setCodeInvalid(true);
            return;
        }

        try {
            const result = await confirmCode({
                variables: {
                    code,
                },
            });
            if (result.data?.totpConfirm) {
                reset();
                props.onConfirm();
                snackbar.show({ message: 'Two-factor authentication has been set' });
            } else {
                snackbar.show({
                    message: 'Response code is incorrect. Please try again',
                    severity: 'error',
                });
                setCodeInvalid(true);
            }
        } catch (e) {
            snackbar.showError({ message: 'Failed to set up two-factor authentication' }, e);
        }
    };

    return {
        submitCode,
        codeInvalid,
        setCodeInvalid,
        loading: totpConfirmMutation.loading,
        error: totpConfirmMutation.error,
        reset,
    };
};
