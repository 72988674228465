import cl from 'classnames';
import isNil from 'lodash/isNil';
import React, { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { InputBaseComponentProps, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { ValidationMessage } from '../forms/ValidationMessage';
import { useFormikField } from '../forms/useFormikField';

import { Currency } from '../../interfaces/model';
import { currencySymbols } from '../../App/App.const';

const NumberFormattingInput = React.forwardRef<Element, InputBaseComponentProps>(
    ({ inputRef, onValueChange, defaultValue, ...rest }, ref) => {
        if (Array.isArray(defaultValue)) {
            // eslint-disable-next-line no-console
            console.warn('NumberInput doesn\'t support "defaultValue" property as an array');
        }

        return (
            <NumericFormat
                {...rest}
                getInputRef={ref}
                defaultValue={Array.isArray(defaultValue) ? undefined : (defaultValue as string | number | undefined)}
                onValueChange={(values) => onValueChange(values.value)}
                valueIsNumericString
                thousandSeparator
            />
        );
    }
);

type INumberFormatProps = TextFieldProps & {
    onValueChange?: (value: number | null) => void;
    currency?: Currency;
    percentage?: boolean;
    showUnits?: boolean;
    name?: string;
    testId?: string;
};

export const NumberInput: React.FC<INumberFormatProps> = forwardRef((props, ref) => {
    const {
        id,
        name,
        currency,
        percentage,
        onValueChange,
        showUnits = true,
        value: valueProp,
        testId = '',
        ...restProps
    } = props;

    const {
        field,
        meta: { error, touched },
        helpers,
        formik,
    } = useFormikField<number | null>(name);
    const value = !isNil(valueProp) ? valueProp : field?.value;
    const getValueUnitsSymbol = () => {
        if (!showUnits) {
            return '';
        }
        return percentage ? '% ' : currencySymbols[currency!];
    };

    const handleChange = (newValue: number | null) => {
        if (onValueChange) {
            onValueChange(newValue);
        } else if (helpers) {
            helpers.setValue(newValue);
        }
    };

    return (
        <div className={cl(props.fullWidth && 'fullWidth')}>
            <TextField
                ref={ref}
                data-testid={testId}
                id={id || name}
                variant={'outlined'}
                value={!isNil(value) ? value : ''}
                error={touched && Boolean(error)}
                onBlur={(e) => {
                    if (field && name) {
                        e.target.name = name;
                        field.onBlur(e);
                    }
                }}
                disabled={formik?.isSubmitting}
                {...restProps}
                InputProps={{
                    ...props.InputProps,
                    inputComponent: NumberFormattingInput,
                    inputProps: {
                        ...props.InputProps?.inputProps,
                        prefix: getValueUnitsSymbol(),
                        onValueChange: (newValue: string) => {
                            // preventing multiple updates in programmatic value change from parent
                            if (String(value) !== String(newValue)) {
                                const newValueParsed = parseFloat(newValue);
                                handleChange(isNaN(newValueParsed) ? null : newValueParsed);
                            }
                        },
                    },
                }}
            />
            {name && <ValidationMessage field={name} />}
        </div>
    );
});
