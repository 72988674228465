import React from 'react';
import { IListColumn } from '../../../lib/dataDisplay/List';
import { List } from '../../../lib/dataDisplay/List/List';
import { AgentCapabilities, AgentFragment } from '../../../interfaces/model';
import { EntityFundAgentListRow, IEntityFundAgentListItem } from './EntityFundAgentListRow';

const COLUMNS: IListColumn<IEntityFundAgentListItem>[] = [
    {
        id: 'name',
        label: 'Agent',
    },
    {
        id: 'totalPermissions',
        label: 'Total Permissions',
    },
    { id: 'manageStructure', label: 'Manage Structure' },
    { id: 'uploadKyc', label: 'Upload KYC' },
    { id: 'uploadDataroom', label: 'Upload dataroom' },
    { id: 'actions', label: 'Actions', sort: false, shrink: true },
];

const prepareData = (agents: AgentFragment[]): IEntityFundAgentListItem[] => {
    return agents.map((item) => {
        return {
            id: item.entity?.id || '',
            name: item.entity?.name || '',
            shortCode: item.entity?.shortCode || '',
            totalPermissions: item.capabilities?.length || 0,
            manageStructure: !!item.capabilities?.includes(AgentCapabilities.MANAGE_STRUCTURE),
            uploadKyc: !!item.capabilities?.includes(AgentCapabilities.UPLOAD_KYC),
            capabilities: item.capabilities || [],
            deleteable: !item.isDerived,
            uploadDataroom: !!item.capabilities?.includes(AgentCapabilities.UPLOAD_DATA_ROOM),
        };
    });
};

interface IEntityFundAgentListProps {
    agents: AgentFragment[];
    canEditAgents: boolean;
    onDeleteConfirm: (agentRef: UUID) => void;
}

export const EntityFundAgentList: React.FC<IEntityFundAgentListProps> = ({
    agents,
    onDeleteConfirm,
    canEditAgents,
}) => {
    const data = prepareData(agents);
    return (
        <List
            data={data}
            columns={COLUMNS}
            rowComponent={EntityFundAgentListRow}
            rowComponentProps={{ onDeleteConfirm, canEditAgents }}
            emptyStatePlaceholderProps={{ title: 'No assigned agents' }}
        />
    );
};
