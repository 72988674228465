import React from 'react';
import { IconWithTooltip } from '../../../../../lib/icons/IconWithTooltip';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Capabilities, RoleFragment, useSetRoleMutation } from '../../../../../interfaces/model';
import { useAppSnackbar } from '../../../../components/AppNotification';
import { RowStack } from '../../../../../lib/cdk/RowStack';
import { Button } from '@mui/material';
import { useUserDetails } from '../../../../hooks/useUserDetails';
import { useCapability } from '../../../../hooks/useCapability';
import Stack from '@mui/material/Stack';

interface IVerifiedRoleEmailIconProps {
    role: RoleFragment | undefined;
    updateData: () => void;
}

export const VerifiedRoleEmailIcon: React.FC<IVerifiedRoleEmailIconProps> = ({ role, updateData }) => {
    const snackbar = useAppSnackbar();
    const [updateRole] = useSetRoleMutation();
    const { isAdmin, role: currentUserRole } = useUserDetails();
    const canManageStructure = useCapability(Capabilities.MANAGE_STRUCTURE);

    const markEmailAsNonVerified = async () => {
        if (role) {
            try {
                await updateRole({
                    variables: {
                        role: {
                            id: role.id,
                            emailVerified: false,
                        },
                    },
                });
                await updateData();
                snackbar.show({ message: 'Email verification has been removed' });
            } catch (e) {
                snackbar.showError({ message: `Failed to mark email as non-verified for the user "${role.name}"` }, e);
            }
        }
    };

    const allowVerificationSkip =
        role?.email &&
        canManageStructure &&
        (isAdmin || (role?.topCoRef && currentUserRole?.topCoRef === role.topCoRef));

    return (
        <div>
            {role?.emailVerified && (
                <IconWithTooltip
                    text={
                        <Stack spacing={2}>
                            <RowStack display={'flex'} alignItems={'center'}>
                                <VerifiedIcon fontSize={'small'} color={'success'} />
                                <span>Email verified</span>
                            </RowStack>
                            {allowVerificationSkip && (
                                <Button
                                    size={'small'}
                                    color={'success'}
                                    variant={'outlined'}
                                    onClick={markEmailAsNonVerified}
                                >
                                    Mark as non-verified
                                </Button>
                            )}
                        </Stack>
                    }
                    Icon={VerifiedIcon}
                    color={'success'}
                />
            )}
        </div>
    );
};
