import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { FormikProvider } from 'formik';
import { FormikWizard, IFormikWizardConfig, IFormWizardStep } from '../../../../lib/cdk/Wizard/FormikWizard';
import { useDirtyFormikConfirmation } from '../../../../lib/forms/useDirtyFormikConfirmation';
import { ClientType, EntityType, useRoleCapabilitiesQuery, useRoleQuery } from '../../../../interfaces/model';
import { makeDialogState } from '../../../../store/dialogState/makeDialogState';
import { DialogCloseButton } from '../../../../lib/modals/DialogCloseButton';
import { RoleNotificationsEdit } from './steps/RoleNotificationsEdit';
import { EntitiesRelationsEdit } from './steps/EntitiesRelationsEdit';
import { CLIENT_TYPE_BY_ENTITY_TYPE_MAP } from '../Roles.const';
import { RoleGeneralInfoEdit } from './steps/RoleGeneralInfoEdit';
import { useUserDetails } from '../../../hooks/useUserDetails';
import { UserPermissionsEdit } from './steps/UserPermissionsEdit';
import { NonFalsy, NOOP } from '../../../../lib/utils/helpers';
import { DialogFormComponent } from '../../../../lib/forms';
import { useRoleEditFormik } from './useRoleEditFormik';
import { hasDirectorRelation } from './useRoleEditFormik/useRoleEditFormik.const';
import { SystemAdminPermissionsEdit } from './steps/SystemAdminPermissionsEdit/SystemAdminPermissionsEdit';

interface IRoleEditDialogProps {
    onSaveSuccess: () => void;
    onClose?: () => void;
    showCredentialsInputs?: boolean;
}

interface IRoleEditDialogState {
    topCoRef?: string;
    entityType?: EntityType | undefined | null;
    roleId?: UUID;
    roleName?: string;
    /* used when creating a new role from a sub-entity page,
       make only that entity enabled by default
    */
    entityRef?: string;
    directorRole?: boolean;
    onSaveSuccess?: () => void;
}

export const useRoleEditDialog = makeDialogState<IRoleEditDialogState>();

const getTitle = (state: IRoleEditDialogState | undefined) => {
    const isCreating = !state?.roleId;
    if (isCreating) {
        return `Create new ${state?.directorRole ? 'director' : 'user'}`;
    }
    return `Edit ${state?.roleName ? `"${state?.roleName}"` : 'user'}`;
};

export const RoleEditDialog: React.FC<IRoleEditDialogProps> = ({
    onSaveSuccess,
    onClose,
    showCredentialsInputs = true,
}) => {
    const { isAdmin, canManageStructure } = useUserDetails();
    const [{ close }, isOpen, state] = useRoleEditDialog();
    const { directorRole, entityRef, roleId } = state || {};

    const { data, error } = useRoleQuery({
        fetchPolicy: 'no-cache',
        skip: !state?.roleId || !isOpen,
        variables: { id: state?.roleId! },
    });

    const role = data?.role;
    const topCoRef = role?.topCoRef || state?.topCoRef;
    const entityType = role?.topCo?.entityType || state?.entityType;
    const clientType = role?.clientType || CLIENT_TYPE_BY_ENTITY_TYPE_MAP[entityType!] || ClientType.UNDEFINED;

    const { data: capabilitiesData, error: capabilitiesError } = useRoleCapabilitiesQuery({
        fetchPolicy: 'cache-and-network',
        skip: !clientType,
        variables: {
            type: clientType!,
        },
    });

    const formik = useRoleEditFormik({
        topCoRef,
        entityRef,
        entityType,
        roleData: data?.role,
        capabilitiesData: capabilitiesData?.capabilities,
        enableReinitialize: true,
        isDirectorRole: directorRole || hasDirectorRelation(data?.role?.entityRelations),
        onSaveSuccess() {
            (state?.onSaveSuccess || NOOP)();
            onSaveSuccess();
            close();
            if (onClose) {
                onClose();
            }
        },
    });

    const clearAndClose = useDirtyFormikConfirmation(formik, {
        title: `You about to cancel ${formik.values.name || 'user'} ${!!formik.values.id ? 'edit' : 'create'}`,
        body: 'All edits will be lost after close. Are you sure?',
        onConfirm: async () => {
            close();
            if (onClose) {
                onClose();
            }
        },
    });

    const isAdminRole = formik.values.isAdmin;

    const hasNoErrorsExcept = (fieldsExclusions: string[]) =>
        Object.keys(formik.errors).filter((field) => !fieldsExclusions.includes(field)).length === 0;

    const steps: IFormWizardStep[] = [
        {
            name: 'General Info',
            renderContent: () => (
                <RoleGeneralInfoEdit
                    showCredentialsInputs={showCredentialsInputs}
                    showTotpRequiredSelect={roleId ? !!data?.role?.hasTotpRequiredEditAccess : isAdmin}
                    entityType={entityType}
                    disableDirectorCheckbox={directorRole}
                />
            ),
            valid: hasNoErrorsExcept(['directorRelations']),
        },
        canManageStructure &&
            !isAdminRole && {
                name: 'User Permissions',
                renderContent: () => <UserPermissionsEdit clientType={clientType} />,
                valid: true,
            },
        isAdminRole && {
            name: 'User Permissions',
            renderContent: () => <SystemAdminPermissionsEdit />,
            valid: true,
        },
        !directorRole && {
            name: 'Entities Relations',
            renderContent: (config: IFormikWizardConfig) => (
                <EntitiesRelationsEdit entityType={entityType} config={config} />
            ),
            valid: !formik.errors.directorRelations,
        },
        !!formik.values?.email && {
            name: 'Notifications',
            renderContent: () => <RoleNotificationsEdit clientType={clientType} />,
            valid: true,
        },
    ].filter(NonFalsy);

    return (
        <FormikProvider value={formik}>
            <Dialog
                data-testid={'RoleDialog'}
                open={isOpen}
                maxWidth={'md'}
                onClose={clearAndClose}
                PaperComponent={DialogFormComponent}
                fullWidth
            >
                <DialogTitle>
                    {getTitle({ ...state, roleName: state?.roleName || role?.name || '' })}
                    <DialogCloseButton onClick={clearAndClose} />
                </DialogTitle>
                <FormikWizard
                    steps={steps}
                    error={error || capabilitiesError}
                    onCancel={clearAndClose}
                    maxWidth={'sm'}
                    disableGutters
                />
            </Dialog>
        </FormikProvider>
    );
};
