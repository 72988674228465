import React from 'react';
import { makeDialogState } from '../../../../store/dialogState/makeDialogState';
import { Container, Dialog, DialogContent, DialogTitle, Paper } from '@mui/material';
import { DialogCloseButton } from '../../../../lib/modals/DialogCloseButton';
import { AgentAclInInput, AgentCapabilities, EntityType } from '../../../../interfaces/model';
import { EntitiesSelect } from '../../EntitiesSelect';
import { DialogFormComponent } from '../../../../lib/forms';
import { FormikProvider, useFormik } from 'formik';
import { agentCapabilitiesOptions, agentEditValidationSchema, getInitialValues } from './AgentEditDialog.helpers';
import { DialogSubmitActions } from '../../../../lib/modals/DialogSubmitActions';
import { useAppSnackbar } from '../../../components/AppNotification';
import { MultiSelectList } from '../../../pages/Roles/EditRole/RoleCapabilitiesList/MultiSelectList';
import Box from '@mui/material/Box';
import { useParentContext } from '../../../../lib/cdk/ParentContext';

interface IAgentEditDialogProps {
    unavailableAgentsRefs: UUID[];
    onSave: (editedAgent: AgentAclInInput) => void;
}

interface IAgentEditDialogState {
    agentRef?: UUID;
    capabilities?: AgentCapabilities[];
}

export const useAgentEditDialog = makeDialogState<IAgentEditDialogState>();

export const AgentEditDialog: React.FC<IAgentEditDialogProps> = ({ unavailableAgentsRefs, onSave }) => {
    const parent = useParentContext();
    const snackbar = useAppSnackbar();
    const [{ close }, isOpen, state] = useAgentEditDialog();

    const formik = useFormik({
        enableReinitialize: isOpen,
        initialValues: getInitialValues(state?.agentRef, state?.capabilities),
        validationSchema: agentEditValidationSchema,
        onSubmit: async (values, helpers) => {
            helpers.setSubmitting(true);
            try {
                await onSave({ entityRef: values.entityRef || '', capabilities: formik.values.capabilities });
                await parent.update();
                snackbar.show({ message: 'Agent saved successfully' });
            } catch (e) {
                snackbar.showError({ message: 'Failed to save agent' }, e);
            }
            helpers.resetForm();
            clearAndClose();
        },
    });

    const clearAndClose = () => {
        formik.resetForm();
        close();
    };

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={isOpen}
                maxWidth={'sm'}
                onClose={clearAndClose}
                PaperComponent={DialogFormComponent}
                fullWidth
            >
                <DialogTitle>
                    {state?.agentRef ? 'Edit' : 'Add'} Agent
                    <DialogCloseButton onClick={clearAndClose} />
                </DialogTitle>
                <DialogContent>
                    <Container maxWidth={'sm'}>
                        <EntitiesSelect
                            name={'entityRef'}
                            entityType={EntityType.AGENT}
                            placeholder={'Select agent'}
                            disabled={formik.isSubmitting || !!state?.agentRef}
                            unavailableRefs={unavailableAgentsRefs}
                            includeWithoutViewAccess={true}
                        />
                        <Box marginTop={'16px'} marginBottom={'16px'}>
                            Select permissions you want the agent to have
                        </Box>
                        <Paper elevation={0} sx={{ background: 'var(--bg-default)' }}>
                            <MultiSelectList
                                editable={!!formik.values.entityRef}
                                options={agentCapabilitiesOptions}
                                value={formik.values.capabilities}
                                onChange={(value) => formik.setFieldValue('capabilities', value)}
                            />
                        </Paper>
                    </Container>
                </DialogContent>
                <DialogSubmitActions
                    onClose={clearAndClose}
                    spin={formik.isSubmitting}
                    submitButtonLabel={state?.agentRef ? 'Save' : 'Add'}
                />
            </Dialog>
        </FormikProvider>
    );
};
