import React from 'react';
import { Link } from 'react-router-dom';
import { TableRow } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { IListRow } from '../../../../../lib/dataDisplay/List';
import { ListIconButton } from '../../../../../lib/dataDisplay/List/components/ListIconButton';
import { FUND_VIEW_ROUTE_FACTORY } from '../../../../App.routes';
import { FundsEmbeddedListRowFragment, KYCCompleteStatus } from '../../../../../interfaces/model';
import { FundDeleteIconButton } from './FundDeleteIconButton';
import { ActionsCell, JurisdictionCell, ValueCell } from '../../../../../lib/dataDisplay/List/components/ListCells';
import { FUND_TYPE_LABELS } from '../../../../App.const';
import { useEditFundKeyInformationDialog } from '../../EditFundKeyInformationDialog';
import { ListCellItemWrapper } from '../../../../../lib/dataDisplay/List/components/ListCellItemWrapper';
import { IsCompleteIcon } from '../../../../../lib/icons/IsCompleteIcon';

export interface IFundsEmbeddedListItem extends FundsEmbeddedListRowFragment {
    fund: FundsEmbeddedListRowFragment;
    actions?: never;
}

type IComponentProps = {
    reloadData: () => void;
};

type IFundListRowProps = IListRow<IFundsEmbeddedListItem, IComponentProps>;

export const FundsEmbeddedListRow: React.FC<IFundListRowProps> = ({ data, columns, props }) => {
    const [{ open: openFundEditDialog }] = useEditFundKeyInformationDialog();
    return (
        <TableRow component={Link} to={FUND_VIEW_ROUTE_FACTORY(data.fund.shortCode!)} hover>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'name' && <ValueCell nowrap={false}>{data.name}</ValueCell>}
                    {id === 'type' && <ValueCell>{FUND_TYPE_LABELS[data.type!] || data.type}</ValueCell>}
                    {id === 'jurisdiction' && <JurisdictionCell value={data.jurisdiction} />}
                    {id === 'parLoansOnly' && (
                        <ValueCell>{data.parLoansOnly ? 'PAR Loans' : 'NON-PAR Loans'}</ValueCell>
                    )}
                    {id === 'kycComplete' && (
                        <ValueCell align={'center'}>
                            <ListCellItemWrapper>
                                {data.kycComplete !== null && (
                                    <IsCompleteIcon complete={data.kycComplete === KYCCompleteStatus.COMPLETE} />
                                )}
                            </ListCellItemWrapper>
                        </ValueCell>
                    )}
                    {id === 'actions' && (
                        <ActionsCell>
                            {data.fund.hasEditAccess && (
                                <>
                                    <ListIconButton
                                        icon={Edit}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            openFundEditDialog({
                                                fundId: data.id,
                                            });
                                        }}
                                    />
                                    <FundDeleteIconButton fund={data.fund} onSuccess={props.reloadData} />
                                </>
                            )}
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
