import React from 'react';
import {
    KYCDocCategory,
    KYCDocSubcategory,
    KYCDocType,
    KYCDocumentFragment,
} from '../../../../../../../interfaces/model';
import { getGroupedDocumentsTree, IDocumentsTree, Year } from '../getGroupedDocumentsTree';
import { NonFalsy } from '../../../../../../../lib/utils/helpers';
import { Subcategory } from './components/Subcategory';
import { Categories } from './components/Categories';
import { DocTypes } from './components/DocTypes';
import { Tree } from './components/Tree';

export interface IDocumentGroupTreeItem {
    category?: KYCDocCategory;
    docType?: KYCDocType;
    subcategory?: KYCDocSubcategory;
    year?: Year;
    deleted?: boolean;
}

interface IDocumentsUploadHistoryViewTreeProps {
    value: KYCDocumentFragment[];
    selected: IDocumentGroupTreeItem;
    onSelect: (item: IDocumentGroupTreeItem) => void;
    hideSelection: boolean;
}

const getDefaultExpanded = (groups: IDocumentsTree, selected: IDocumentGroupTreeItem) => {
    const { category, docType, subcategory, year } = selected;
    return [category, docType, subcategory, year].filter(NonFalsy).map(String);
};

export const DocumentsGroupsTree: React.FC<IDocumentsUploadHistoryViewTreeProps> = ({
    value,
    selected,
    hideSelection,
    onSelect,
}) => {
    const groups = getGroupedDocumentsTree(value);

    return (
        <Tree defaultExpanded={getDefaultExpanded(groups, selected)}>
            <Categories value={groups}>
                {(category, categoryItems, offset) => (
                    <DocTypes value={categoryItems} offset={offset}>
                        {(docType, docTypeItems, docTypeOffset) => (
                            <Subcategory
                                category={category}
                                docType={docType}
                                value={docTypeItems}
                                selection={selected}
                                offset={docTypeOffset}
                                onSelect={onSelect}
                                hideSelection={hideSelection}
                            />
                        )}
                    </DocTypes>
                )}
            </Categories>
        </Tree>
    );
};
