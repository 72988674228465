import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { ApolloError } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { getValidationObjectArgs } from '../../common/Validation/getValidationObjectArgs';
import { getMessageFromApolloError, getTraceIdFromApolloError } from 'lib/utils/helpers';
import { IAppSnackbarConfig } from 'store/interfaces/IAppSnackbarConfig';
import { useValidationErrorReportLazyQuery } from 'interfaces/model';
import { useAppTracker } from '../../hooks/useAppTracker';
import { appShowSnackbar } from '../../App.actions';
import { getInnerText } from './getInnerText';

const formatSnackbarMessage = (defaultMessage: ReactNode, error: unknown) => {
    const validationFragment = getMessageFromApolloError(error);
    if (!validationFragment) {
        return defaultMessage;
    }
    return (
        <FormattedMessage
            id={validationFragment.key}
            defaultMessage={validationFragment.comment || ''}
            values={getValidationObjectArgs(validationFragment)}
        />
    );
};

const cropTraceIdForJaeger = (traceId: string | undefined) => traceId?.split(':')[0];

export const useAppSnackbar = () => {
    const dispatch = useDispatch();
    const tracker = useAppTracker();
    const [reportError] = useValidationErrorReportLazyQuery();

    return {
        show: (config: IAppSnackbarConfig) => dispatch(appShowSnackbar(config)),
        showError: (config: IAppSnackbarConfig, error: unknown) => {
            const traceId =
                config.traceId || (error instanceof ApolloError ? getTraceIdFromApolloError(error) : void 0);
            const action = appShowSnackbar({
                traceId: cropTraceIdForJaeger(traceId),
                ...config,
                message: <div key={'snackbar-message'}>{formatSnackbarMessage(config.message, error)}</div>,
                severity: 'error',
            });

            dispatch(action);

            const message = getInnerText(action.payload.message);
            tracker?.event('snackbar-error', { message, traceId: action.payload.traceId });

            reportError({ variables: { message, traceId } }).catch((e: Error) => {
                dispatch(
                    appShowSnackbar({
                        message: `Failed to report error: ${e.message}`,
                        traceId: action.payload.traceId,
                        severity: 'error',
                    })
                );
            });
        },
    };
};
