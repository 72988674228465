import React from 'react';
import * as yup from 'yup';
import { AnySchema } from 'yup';
import { Form, yupToFormErrors } from 'formik';
import { Paper } from '@mui/material';
import isString from 'lodash/isString';
import BaseSchema, { SchemaDescription } from 'yup/lib/schema';
import { dateFromISOToFormat, dateFromJSToFormat } from '../utils/date';
import { ErrorCrit, ValidationAlertFragment } from '../../interfaces/model';
import identity from 'lodash/identity';
import { FormikErrors } from 'formik/dist/types';
import { ApolloError } from '@apollo/client';
import { IGqlErrorExtension } from '../../interfaces/API';
import isEmpty from 'lodash/isEmpty';
import { flattenObject } from '../utils/helpers';
import { useFormik } from 'formik/dist/Formik';
import { PaperDark } from 'lib/surfaces/PaperDark';
import isEqual from 'lodash/isEqual';
import { MixedSchema } from 'yup/lib/mixed';

/* eslint-disable no-template-curly-in-string */
export const VALIDATION_MESSAGES = {
    REQUIRED: 'This field is required',
    REQUIRED_CHECKED: 'Please check this field',
    FILE_REQUIRED: 'Please select file',
    EMAIL_INVALID: 'Please enter correct e-mail address',
    INVALID_TIME: 'Please enter a valid time',
    MIN:
        (formatter: (value: number) => string = identity) =>
        (params: { min: number }) =>
            `Value should be greater than or equal to ${formatter(params.min)}`,
    MAX:
        (formatter: (value: number) => string = identity) =>
        (params: { max: number }) =>
            `Value should be less than or equal to ${formatter(params.max)}`,
    MORE_THAN: 'Value should be greater than ${more}',
    MIN_DATE: (params: { min: string | Date }) => {
        const dateFormatted = isString(params.min) ? dateFromISOToFormat(params.min) : dateFromJSToFormat(params.min);
        return `Value should be greater than or equal to ${dateFormatted || 'min date'}`;
    },
    MAX_DATE: (params: { max: string | Date }) => {
        const dateFormatted = isString(params.max) ? dateFromISOToFormat(params.max) : dateFromJSToFormat(params.max);
        return `Value should be less than or equal to ${dateFormatted || 'max date'}`;
    },
    PASSWORDS_DONT_MATCH: "Passwords don't match",
};

export const isRequiredSchemaField = <TSchema extends yup.AnyObjectSchema>(schema: TSchema) => {
    const { fields } = schema.describe();
    const isRequired = (field?: { name?: string | undefined }) => Boolean(field?.name === 'required');
    return (name: keyof yup.TypeOf<typeof schema>) => {
        if (schema.fields[name].deps.length > 0) {
            throw new Error(
                `Failed to determine "required" status of "${String(
                    name
                )}" field. Fields with dependencies are not supported`
            );
        }

        const field = (fields[name as string] as SchemaDescription)?.tests.find(isRequired);
        return isRequired(field);
    };
};

export const DialogFormComponent = (props: object) => <Paper component={Form} {...props} />;
export const DarkDialogFormComponent = (props: object) => <PaperDark component={Form} {...props} />;

export function getErrorsFromValidation<T extends object>(
    validation: ValidationAlertFragment[] | null | undefined,
    keysToInclude: string[],
    target: string
): Partial<Record<keyof T, string>> {
    const includeKeys = keysToInclude.map((key) => `${target}.${key}`);
    const entries = (validation || [])
        .filter(({ name, errorCrit }) => errorCrit === ErrorCrit.ERROR && includeKeys.includes(name))
        .map(({ name, comment }) => {
            const [, field] = name.split('.');
            return [field, comment];
        });
    return Object.fromEntries(entries);
}

export const combineSchemaWithErrors = <TValues extends object, TErrors extends Partial<Record<keyof TValues, string>>>(
    validationSchema: BaseSchema,
    errors: TErrors,
    initialValues: TValues
) => {
    return async (values: TValues) => {
        const errorsRemaining = Object.fromEntries(
            Object.entries(errors).filter(([key]) => {
                const field = key as keyof TValues;
                return values[field] === initialValues[field];
            })
        );

        try {
            await validationSchema.validate(values, { abortEarly: false });
        } catch (e) {
            return {
                ...yupToFormErrors(e),
                ...errorsRemaining,
            };
        }
        return errorsRemaining;
    };
};

export const INVALID_CONTROL_DEFAULT_SELECTOR = 'Mui-error';

export const scrollToFirstInvalidControl = (query: string = `.${INVALID_CONTROL_DEFAULT_SELECTOR}`) =>
    // We are allowing element to render in case it was hidden before scrolling attempt
    setTimeout(() => {
        const element = document.querySelector(query);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);

export const submitFormikAndScrollToInvalidField = async (
    formik: Pick<ReturnType<typeof useFormik>, 'errors' | 'submitForm' | 'setFieldTouched'>
) => {
    const result = await formik.submitForm();
    const invalidFields = Object.keys(flattenObject(formik.errors));
    invalidFields.forEach((key) => formik.setFieldTouched(key, true));
    scrollToFirstInvalidControl();

    return result;
};

export const getGQLValidationErrors = (error: unknown) => {
    if (error instanceof ApolloError) {
        return (error as ApolloError).graphQLErrors.flatMap((gqlError) => {
            const extensions = gqlError.extensions as unknown as IGqlErrorExtension | undefined;
            if (extensions?.type === 'ValidationError') {
                return extensions.errors;
            }
            return [];
        });
    }
    return [];
};

export function handleGqlFormMutationError<T>(
    e: unknown,
    values: T,
    {
        setErrors,
    }: {
        setErrors: (errors: FormikErrors<T>) => void;
    }
): boolean {
    const fields = Object.keys(values);
    const errors = Object.fromEntries(
        getGQLValidationErrors(e)
            .filter(
                ({ errorCrit, name, comment }) =>
                    errorCrit === ErrorCrit.ERROR && name && comment && fields.includes(name)
            )
            .map(({ name, comment }) => [name, comment!])
    );
    if (!isEmpty(errors)) {
        // Make errors added after any potential form re-renders
        setTimeout(() => setErrors(errors as FormikErrors<T>));
        return true;
    }
    return false;
}

export function proxyAPIValidationError<T>(prevValues: T, error: ApolloError | undefined) {
    return (values: T) => {
        if (error) {
            const fields = Object.keys(values);
            const errors = Object.fromEntries(
                getGQLValidationErrors(error)
                    .filter(({ errorCrit, name, comment }) => {
                        return errorCrit === ErrorCrit.ERROR && name && comment && fields.includes(name);
                    })
                    .map(({ name, comment }) => [name, comment!])
            );

            return Object.fromEntries(
                Object.entries(errors).filter(([field]) =>
                    isEqual(prevValues[field as keyof T], values[field as keyof T])
                )
            );
        }
    };
}

/**
 * Fixes "undefined" issue https://github.com/jquense/yup/issues/1367
 */
export function yupArrayTypeFix<T extends AnySchema>(schema: AnySchema) {
    return schema as T;
}

/**
 * Emulates enum schema
 */
export const yupEnum = <T extends string>(enumObj: StandardEnum<T>) =>
    yup.mixed<T>().oneOf(Object.values(enumObj) as T[]) as MixedSchema<T>;
