import cl from 'classnames';
import isString from 'lodash/isString';
import React, { forwardRef } from 'react';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import styles from './SingleSymbolInput.module.css';

interface ISingleSymbolInputProps extends Omit<StandardTextFieldProps, 'size'> {
    onValueChange: (value: string | undefined) => void;
    size: 'medium' | 'large';
}

const CLASSES: Record<ISingleSymbolInputProps['size'], string> = {
    medium: styles.inputMedium,
    large: styles.inputLarge,
};

export const SingleSymbolInput: React.FC<ISingleSymbolInputProps> = forwardRef(
    ({ value, onValueChange, size, ...props }, ref) => {
        const getFirstSymbol = (val: unknown) => (isString(val) ? val[0] : void 0);
        return (
            <TextField
                {...props}
                ref={ref}
                value={getFirstSymbol(value) || ''}
                onChange={(e) => onValueChange(getFirstSymbol(e.target.value))}
                InputProps={{
                    className: cl(styles.input, CLASSES[size]),
                }}
                fullWidth
            />
        );
    }
);
