import Box from '@mui/material/Box';
import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import { FormHelperText, FormLabel, Stack, Switch } from '@mui/material';
import {
    docPurposeLabels,
    DOCUMENTS_WITH_EXPIRY_DATE,
    DOCUMENTS_WITH_PRIVATE_ACCESS_CONFIGURABLE,
    documentsSubcategoryOptionsFactory,
    SUBCATEGORIES_MAP,
} from '../../../Documents.const';
import { DocPurpose, KYCDocType, KYCDocTypeFragment } from '../../../../../../interfaces/model';
import { CountrySelect } from '../../../../../../lib/inputs/CountrySelect/CountrySelect';
import { SignatureArea } from '../../../../../../lib/inputs/SignatureArea';
import { Datepicker } from '../../../../../../lib/inputs/Datepicker';
import { TextInput } from '../../../../../../lib/inputs/TextInput';
import { FileDrop } from '../../../../../../lib/inputs/FileDrop';
import { Dropdown } from '../../../../../../lib/inputs/Dropdown';
import { selectOptionFactory } from 'lib/inputs/Select.const';
import { RowStack } from '../../../../../../lib/cdk/RowStack';
import { kycDocTypeLabels } from '../../../../../App.const';
import styles from './DocumentUploadForm.module.css';

export interface IDocumentUploadFormConfig {
    multipleFilesUpload?: boolean;
    fileChangingEnabled?: boolean;
    commentBoxEnabled?: boolean;
    docTypeChangeEnabled?: boolean;
    docPurposeChangeEnabled?: boolean;
}

interface IDocumentUploadFormProps {
    config: IDocumentUploadFormConfig;
    taxIdCountriesBlacklist?: string[];
    docTypes?: KYCDocTypeFragment[];
}

export const DocumentUploadForm: React.FC<IDocumentUploadFormProps> = ({
    taxIdCountriesBlacklist,
    config,
    docTypes = [],
}) => {
    const formik = useContext(FormikContext);
    const docType = formik.values.docType;
    const subcategories = documentsSubcategoryOptionsFactory(SUBCATEGORIES_MAP[docType as KYCDocType] || []);
    const { fileChangingEnabled, commentBoxEnabled, docTypeChangeEnabled, docPurposeChangeEnabled } = config;
    const privateAccessConfigurable = DOCUMENTS_WITH_PRIVATE_ACCESS_CONFIGURABLE.includes(docType);
    const displayExpiryDate = !!docType && DOCUMENTS_WITH_EXPIRY_DATE.includes(docType);
    const isTaxIdFormDocument = docType === KYCDocType.TAX_ID_FORM;
    const isESignature = docType === KYCDocType.ESIG;

    const docTypeOptions = docTypes
        .filter(({ purpose }) => !formik.values.docPurpose || purpose === formik.values.docPurpose)
        .map(({ type }) => type)
        .map(selectOptionFactory(KYCDocType, kycDocTypeLabels));

    const docPurposeOptions = Array.from(new Set(docTypes.map(({ purpose }) => purpose))).map(
        selectOptionFactory(DocPurpose, docPurposeLabels)
    );

    return (
        <Stack spacing={3} marginTop={'-16px'}>
            {isESignature ? (
                <SignatureArea name={'files'} />
            ) : (
                <FileDrop name={'files'} multiple={config.multipleFilesUpload} disabled={!fileChangingEnabled} />
            )}
            {privateAccessConfigurable && (
                <Stack spacing={0.5}>
                    <FormLabel htmlFor={'privatePublicSwitch'} className={styles.privateAccessLabel}>
                        <span>Private Document</span>
                        <Switch
                            id={'privatePublicSwitch'}
                            size={'small'}
                            color={'primary'}
                            onChange={() => formik.setFieldValue('isPrivate', !formik.values.isPrivate)}
                            checked={!!formik.values.isPrivate}
                        />
                    </FormLabel>
                    <FormHelperText>Private documents are available only for approved users</FormHelperText>
                </Stack>
            )}
            {docPurposeChangeEnabled && (
                <Box>
                    <FormLabel required htmlFor={'docPurpose'}>
                        Document Type
                    </FormLabel>
                    <Dropdown name={'docPurpose'} data-testid={'docPurpose'} options={docPurposeOptions} fullWidth />
                </Box>
            )}
            {docTypeChangeEnabled && (
                <Box>
                    <FormLabel required htmlFor={'docType'}>
                        Category
                    </FormLabel>
                    <Dropdown name={'docType'} data-testid={'docCategory'} options={docTypeOptions} fullWidth />
                </Box>
            )}
            {subcategories.length > 0 && (
                <Box>
                    <FormLabel required htmlFor={'docSubcategory'}>
                        Sub category
                    </FormLabel>
                    <Dropdown
                        name={'docSubcategory'}
                        data-testid={'docSubcategory'}
                        options={subcategories}
                        fullWidth
                    />
                </Box>
            )}
            {!isTaxIdFormDocument && (
                <Box>
                    <FormLabel htmlFor={'name'}>Document name</FormLabel>
                    <TextInput name={'name'} placeholder={'Enter document name'} fullWidth />
                </Box>
            )}

            {isTaxIdFormDocument && (
                <>
                    <Box>
                        <FormLabel required>Tax Jurisdiction</FormLabel>
                        <CountrySelect
                            name={'country'}
                            placeholder={'Select jurisdiction country'}
                            blacklist={taxIdCountriesBlacklist || []}
                            disablePortal={false}
                            fullWidth
                        />
                    </Box>
                    <Box>
                        <FormLabel required>Tax ID</FormLabel>
                        <TextInput name={'taxIdValue'} placeholder={'Enter tax ID'} fullWidth />
                    </Box>
                </>
            )}

            <Box>
                <RowStack spacing={3} alignItems={'flex-start'} marginBottom={'4px'}>
                    <Box flex={1}>
                        <FormLabel required htmlFor={'validFromDate'}>
                            Valid From
                        </FormLabel>
                        <Datepicker
                            name={'validFromDate'}
                            placeholder={'Select date'}
                            value={formik.values.validFromDate}
                            onChange={(date) => formik.setFieldValue('validFromDate', date)}
                            maxDate={formik.values.validToDate}
                            fullWidth
                        />
                    </Box>
                    {displayExpiryDate && (
                        <Box flex={1}>
                            <FormLabel required htmlFor={'validToDate'}>
                                Valid To
                            </FormLabel>
                            <Datepicker
                                name={'validToDate'}
                                placeholder={'Select date'}
                                value={formik.values.validToDate}
                                minDate={formik.values.validFromDate}
                                onChange={(date) => formik.setFieldValue('validToDate', date)}
                                fullWidth
                            />
                        </Box>
                    )}
                </RowStack>
                {displayExpiryDate && !formik.errors.validFromDate && !formik.errors.validToDate && (
                    <FormHelperText>Select the date range for the period to which the document relates</FormHelperText>
                )}
            </Box>

            {commentBoxEnabled && !isTaxIdFormDocument && (
                <Box>
                    <FormLabel htmlFor={'comment'}>Comment</FormLabel>
                    <TextInput name={'comment'} placeholder={'Review comment'} multiline fullWidth />
                </Box>
            )}
        </Stack>
    );
};
