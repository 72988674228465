import { COUNTERPARTIES_ROUTE_FACTORY, COUNTERPARTY_KYC_ROUTE_FACTORY } from '../../../App.routes';

export const isMenuItemPathMatchUrl = (url: string, pathname: string) => {
    if (pathname.startsWith(COUNTERPARTIES_ROUTE_FACTORY())) {
        return url.startsWith(COUNTERPARTIES_ROUTE_FACTORY());
    } else if (pathname.startsWith(COUNTERPARTY_KYC_ROUTE_FACTORY())) {
        return url.startsWith(COUNTERPARTY_KYC_ROUTE_FACTORY());
    }
    return !!url && pathname.startsWith(url);
};
