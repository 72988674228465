import React from 'react';
import { ILegalEntitiesSetupListRowItem, LegalEntitiesSetupListRow } from './LegalEntitiesSetupListRow';
import { ClientOnboardingFragment, EntityType, useLegalEntitiesSetupListQuery } from 'interfaces/model';
import { DivTableTheme } from 'lib/dataDisplay/List/themes/DivTableTheme';
import { IListColumn } from 'lib/dataDisplay/List';
import { List } from 'lib/dataDisplay/List/List';
import { voidWrapper } from 'lib/utils/helpers';

interface ILegalEntitiesSetupListProps {
    query: ReturnType<typeof useLegalEntitiesSetupListQuery>;
    entityType: EntityType;
    topCo: Maybe<ClientOnboardingFragment> | undefined;
    noPreview?: boolean;
}

const COLUMNS: IListColumn<ILegalEntitiesSetupListRowItem>[] = [
    { id: 'name', label: 'name' },
    { id: 'entityType', label: 'Type' },
    { id: 'jurisdiction', label: 'Jurisdiction' },
    { id: 'actions', label: 'Actions', shrink: true, sort: false },
];

export const getLegalEntitiesDescriptionText = (parentEntityType: EntityType) => {
    const hasFundsSetupStep = [EntityType.LENDER_TOP_LEVEL].includes(parentEntityType);
    return hasFundsSetupStep
        ? 'Please add the investment managers that will be managing the funds you want to onboard'
        : 'Please add the legal entities you want to onboard here';
};

export const LegalEntitiesSetupList: React.FC<ILegalEntitiesSetupListProps> = ({
    query,
    entityType,
    noPreview,
    topCo,
}) => {
    const { data, loading, error, refetch } = query;
    return (
        <DivTableTheme>
            <List
                columns={COLUMNS}
                rowComponent={LegalEntitiesSetupListRow}
                rowComponentProps={{ updateData: voidWrapper(refetch), noPreview, topCo }}
                emptyStatePlaceholderProps={{
                    title: getLegalEntitiesDescriptionText(entityType),
                    elevation: 0,
                }}
                data={data?.entities.data || []}
                onRetry={voidWrapper(refetch)}
                loading={loading}
                error={error}
            />
        </DivTableTheme>
    );
};
