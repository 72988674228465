import { Box } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { FundEntityCounterpartyKYC } from '../../Entities/ViewEntity/FundEntityCounterpartyKYC/FundEntityCounterpartyKYC';
import { EditFundKeyInformationDialog, useEditFundKeyInformationDialog } from '../EditFundKeyInformationDialog';
import { useViewEntityUrlState } from '../../Entities/ViewEntity/ViewEntityDetails/useViewEntityUrlState';
import { IDocumentsListParent } from '../../../components/Documents/interfaces/IDocumentsListParent';
import { useElementFocusByQueryParamEffect } from 'lib/hooks/useElementFocusByQueryParamEffect';
import { isPrivate } from '../../Facilities/tabs/FacilitiesTab/ViewFacility/helpers/isPrivate';
import { AgentAclInInput, DocPurpose, DocumentStorage, IdType } from 'interfaces/types';
import { EntityFundAgents } from '../../../common/EntityFundAgents/EntityFundAgents';
import { DocumentsList } from '../../../components/Documents/DocumentsList';
import { DataErrorPlaceholder } from 'lib/dataDisplay/DataErrorPlaceholder';
import { useSetLoanFundMutation } from '../queries/SetLoanFund.mutation';
import { CreateFundWizard } from '../CreateFundWizard/CreateFundWizard';
import { ViewFundHeading } from './ViewFundHeading/ViewFundHeading';
import { ViewFundDetails } from './ViewFundDetails/ViewFundDetails';
import { ParentContextProvider } from 'lib/cdk/ParentContext';
import { useLoanFundQuery } from '../queries/LoanFund.query';
import { FundBankAccounts } from './FundBankAccounts';
import { FundTaxIdsList } from './FundTaxIdsList';

interface IViewFundProps {
    config?: {
        counterparties?: boolean;
        breadcrumbsInteractive?: boolean;
    };
}

export const ViewFund: React.FC<IViewFundProps> = ({ config }) => {
    const history = useHistory();
    const location = useLocation();
    const [urlParams] = useViewEntityUrlState();
    const [mutateFund] = useSetLoanFundMutation();
    useElementFocusByQueryParamEffect();
    const params = useParams<{ sc?: string }>();
    const shortCode = (params.sc || '').toLowerCase();
    const [{ open: openFundEditDialog }] = useEditFundKeyInformationDialog();
    const { data, error, refetch } = useLoanFundQuery({
        fetchPolicy: 'no-cache',
        variables: { id: shortCode },
        skip: !shortCode,
    });

    useEffect(() => {
        if (urlParams.edit) {
            openFundEditDialog({ fundId: shortCode });
        }
    }, [openFundEditDialog, shortCode, urlParams.edit]);

    const fund = data?.loanfund || void 0;

    const handleAgentSubmit = async (editedAgent: AgentAclInInput) => {
        await mutateFund({
            variables: {
                loanFund: {
                    id: fund?.id,
                    assignedAgents: [
                        ...(fund?.assignedAgentAcls || []).map((item) => {
                            return {
                                entityRef: item.entity?.id || '',
                                capabilities: item.capabilities || [],
                            };
                        }),
                        editedAgent,
                    ],
                },
            },
        });
    };

    const handleAgentDelete = async (agentRef: UUID) => {
        const newAgents = (fund?.assignedAgentAcls || [])
            .filter((item) => item.entity?.id !== agentRef)
            .map((item) => {
                return {
                    entityRef: item.entity?.id || '',
                    capabilities: item.capabilities,
                };
            });
        await mutateFund({
            variables: {
                loanFund: {
                    id: fund?.id,
                    assignedAgents: newAgents,
                },
            },
        });
    };

    const updateData = useCallback(async () => {
        await refetch();
    }, [refetch]);

    if (error) {
        return <DataErrorPlaceholder error={error} onRetry={updateData} />;
    }

    if (fund?.deleted) {
        return <ViewFundHeading fund={fund} />;
    }

    const kycListParent: IDocumentsListParent = {
        name: fund?.name,
        id: fund?.id,
        type: IdType.LOAN_FUND,
        topCoRef: fund?.topCoRef,
        hasDocumentEditAccess: !!fund?.hasDocumentEditAccess,
        hasDocumentApproveAccess: !!fund?.hasDocumentApproveAccess,
        update: updateData,
    };

    return (
        <ParentContextProvider onUpdate={updateData}>
            <ViewFundHeading fund={fund} config={config} />
            <ViewFundDetails fund={fund} className={'mb-32 mt-16'} config={config} />
            {fund && <FundEntityCounterpartyKYC value={fund} />}
            {fund?.agentAcls && (
                <EntityFundAgents
                    agents={fund?.agentAcls}
                    canEditAgents={!!fund?.hasAgentsEditAccess}
                    onDeleteConfirm={handleAgentDelete}
                    onSave={handleAgentSubmit}
                />
            )}

            {fund?.id && (
                <DocumentsList
                    value={fund.kycs || []}
                    parent={kycListParent}
                    config={{
                        storage: DocumentStorage.KYC,
                        purpose: DocPurpose.KYC,
                    }}
                />
            )}

            {fund?.id && fund.taxIds && <FundTaxIdsList fund={fund} updateData={updateData} />}

            {!isPrivate(fund?.bankAccounts) && (
                <Box marginBottom={'20px'} data-testid={'Fund:bankAccounts'}>
                    <FundBankAccounts fund={fund} />
                </Box>
            )}
            <EditFundKeyInformationDialog onClose={() => history.replace(location.pathname)} />
            <CreateFundWizard onSaveSuccess={updateData} />
        </ParentContextProvider>
    );
};
