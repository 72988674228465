import React, { useState } from 'react';
import { Highlight } from 'react-highlighter-ts';
import { TableCell, TableRow } from '@mui/material';
import {
    ActionsCell,
    DateValueCell,
    LinkCell,
    ValueCell,
} from '../../../../../../../../../lib/dataDisplay/List/components/ListCells';
import {
    EntityType,
    ReviewAction,
    ReviewedDocumentFragment,
    ReviewType,
} from '../../../../../../../../../interfaces/model';
import { DocumentDownloadIconButton } from '../../../../../DocumentDownloadIconButton';
import { DocumentRestoreIconButton } from '../../../../../DocumentRestoreIconButton/DocumentRestoreIconButton';
import { KYCLedgerCommReviewMark } from '../../../../../KYCLedgerCommReviewMark';
import { getEntityViewRouteByType, ROLE_VIEW_ROUTE_FACTORY } from '../../../../../../../../App.routes';
import { IListRow } from '../../../../../../../../../lib/dataDisplay/List';
import { KYCReviewActionChip } from '../../../../../KYCReviewActionChip';
import { CollapseTrigger } from '../../../../../../../../../lib/buttons/CollapseTrigger';
import { ValueBox } from '../../../../../../../../../lib/cdk/ValueBox';
import { getDocumentFileName } from '../../../../../../Documents.const';
import { dateFromISOToFormat } from '../../../../../../../../../lib/utils/date';
import { LinkWithIcon } from '../../../../../../../../../lib/navigation/LinkWithIcon';

export interface IDocumentReviewHistoryListRowItem {
    id: string;
    date: number;
    userName: string;
    userShortCode: string;
    entityName: string;
    entityShortCode: string;
    entityType: EntityType | undefined;
    status: Maybe<ReviewAction>;
    reviewType: ReviewType;
    document: Maybe<ReviewedDocumentFragment>;
    comment: string;
    actions?: never;
}

type IDocumentReviewHistoryListRowProps = IListRow<
    IDocumentReviewHistoryListRowItem,
    {
        parentRef: UUID | undefined;
        updateData: () => Promise<void>;
    }
>;

export const DocumentReviewHistoryListRow: React.FC<IDocumentReviewHistoryListRowProps> = ({
    data,
    columns,
    highlightText,
    props,
    isFirst,
}) => {
    const [isExpanded, setExpanded] = useState(false);
    const documents = data.document?.documents || [];

    return (
        <>
            <TableRow>
                {columns.map(({ id }) => (
                    <React.Fragment key={id}>
                        {id === 'date' && (
                            <DateValueCell value={data.date} sx={{ paddingTop: '20px', paddingBottom: '20px' }} />
                        )}
                        {id === 'userName' && (
                            <LinkCell to={ROLE_VIEW_ROUTE_FACTORY(data.userShortCode)} sx={{ minWidth: '180px' }}>
                                <Highlight search={highlightText?.trim()}>{data.userName}</Highlight>
                            </LinkCell>
                        )}
                        {id === 'entityName' && (
                            <LinkCell
                                to={getEntityViewRouteByType(data.entityType)(data.entityShortCode)}
                                sx={{ minWidth: '180px' }}
                            >
                                <Highlight search={highlightText?.trim()}>{data.entityName}</Highlight>
                            </LinkCell>
                        )}
                        {id === 'status' && (
                            <TableCell>
                                <KYCReviewActionChip value={data.status} />
                            </TableCell>
                        )}
                        {id === 'reviewType' && (
                            <TableCell sx={{ paddingLeft: 0 }}>
                                {data.reviewType === ReviewType.LEDGERCOMM && <KYCLedgerCommReviewMark />}
                            </TableCell>
                        )}
                        {id === 'comment' && <ValueCell nowrap={false}>{data.comment}</ValueCell>}
                        {id === 'actions' && (
                            <ActionsCell align={'right'}>
                                {(data.status === ReviewAction.UPLOADED || data.status === ReviewAction.RESTORED) &&
                                    data.document && (
                                        <>
                                            {!isFirst && (
                                                <DocumentRestoreIconButton
                                                    document={data.document}
                                                    updateData={props.updateData}
                                                />
                                            )}
                                            <DocumentDownloadIconButton
                                                title={'Download this version'}
                                                documentId={data.document.id}
                                            />
                                            <CollapseTrigger
                                                isActive={isExpanded}
                                                onClick={() => setExpanded(!isExpanded)}
                                            />
                                        </>
                                    )}
                            </ActionsCell>
                        )}
                    </React.Fragment>
                ))}
            </TableRow>
            {isExpanded && (
                <TableRow>
                    <TableCell sx={{ paddingTop: '14px', paddingBottom: '14px' }}>
                        <ValueBox label={'Name'}>{data.document?.name || getDocumentFileName(documents)}</ValueBox>
                    </TableCell>
                    <TableCell>
                        <ValueBox label={'Filed by'}>
                            <LinkWithIcon to={ROLE_VIEW_ROUTE_FACTORY(data.document?.role?.shortCode!)}>
                                {data.document?.role?.name}
                            </LinkWithIcon>
                        </ValueBox>
                    </TableCell>
                    <TableCell>
                        <ValueBox label={'Valid From'}>{dateFromISOToFormat(data.document?.validFromDate)}</ValueBox>
                    </TableCell>
                    <TableCell>
                        <ValueBox label={'Valid To'}>{dateFromISOToFormat(data.document?.validToDate)}</ValueBox>
                    </TableCell>
                    <TableCell colSpan={3} />
                </TableRow>
            )}
        </>
    );
};
