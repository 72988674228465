import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { isCounterpartyKYCActionAvailable } from '../../CounterpartyKYCEdit/CounterpartiesKYCEditWizard/steps/CounterpartiesKYCSelectStep/isCounterpartyKYCActionAvailable';
import { CounterpartiesKYCEditMode } from '../../CounterpartyKYCEdit/useCounterpartiesKYCEditUrlState';
import { COUNTERPARTY_KYC_EDIT_ROUTE_FACTORY } from '../../../../App.routes';
import { joinQueryParams } from 'lib/utils/router/joinQueryParams';
import { CounterpartyStatus } from 'interfaces/model';

interface ICounterpartyKYCEntity {
    id: UUID;
    counterpartyKYCStatus: Maybe<{ targetRef: UUID; status: Maybe<CounterpartyStatus> }[]>;
}

interface ICounterpartiesListActionButtonProps {
    status: Maybe<CounterpartyStatus>;
    entities: Maybe<ICounterpartyKYCEntity[]>;
    targets: UUID[];
    topCoRef: UUID | null;
}

const isApprovalAvailable = (status: Maybe<CounterpartyStatus>) =>
    status === CounterpartyStatus.UNAPPROVED || status === CounterpartyStatus.ACTION_REQUIRED;
const isRejectAvailable = (status: Maybe<CounterpartyStatus>) =>
    status === CounterpartyStatus.APPROVED || status === CounterpartyStatus.IN_PROGRESS;

export const CounterpartyKYCListActionButton: React.FC<ICounterpartiesListActionButtonProps> = ({
    status,
    entities,
    targets,
    topCoRef,
}) => {
    const location = useLocation();

    if (!entities) {
        return null;
    }

    const getQueryParams = (mode: CounterpartiesKYCEditMode) => {
        return {
            counterparties: entities.filter((item) => isCounterpartyKYCActionAvailable(item, mode)).map(({ id }) => id),
            from: `${location.pathname}${location.search}`,
            topCoRef,
            targets,
            mode,
            step: 2,
        };
    };

    return (
        <>
            {isRejectAvailable(status) && (
                <Button
                    size={'small'}
                    variant={'outlined'}
                    sx={{ minWidth: '80px' }}
                    data-testid={'CounterpartiesKYC:revokeButton'}
                    component={Link}
                    to={{
                        pathname: COUNTERPARTY_KYC_EDIT_ROUTE_FACTORY(),
                        search: joinQueryParams(getQueryParams(CounterpartiesKYCEditMode.REVOKE)),
                    }}
                >
                    Revoke
                </Button>
            )}
            {isApprovalAvailable(status) && (
                <Button
                    size={'small'}
                    variant={'contained'}
                    data-testid={'CounterpartiesKYC:approveButton'}
                    sx={{ minWidth: '80px' }}
                    component={Link}
                    to={{
                        pathname: COUNTERPARTY_KYC_EDIT_ROUTE_FACTORY(),
                        search: joinQueryParams(getQueryParams(CounterpartiesKYCEditMode.APPROVE)),
                    }}
                >
                    Approve
                </Button>
            )}
        </>
    );
};
