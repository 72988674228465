import cl from 'classnames';
import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Dialog, DialogActions, Tooltip } from '@mui/material';
import { validationSchema } from './TermsAndConditionsDialog.const';
import { makeDialogState } from 'store/dialogState/makeDialogState';
import { TermsAndConditions } from './TermsAndConditions/TermsAndConditions';
import { DialogFormComponent, proxyAPIValidationError } from 'lib/forms';
import styles from './TermsAndConditionsDialog.module.css';
import { DialogCloseButton } from '../../../../../lib/modals/DialogCloseButton';
import { LoadingSpinnerButton } from 'lib/buttons/LoadingSpinnerButton';
import { FormCheckbox } from '../../../../../lib/forms/components/FormCheckbox';
import { useAcceptTermsAndConditionsMutation } from './AcceptTermsAndConditions.mutation';
import { useAppSnackbar } from '../../../AppNotification';
import { NOOP } from '../../../../../lib/utils/helpers';

interface ITermsAndConditionsDialogProps {}

interface ITermsAndConditionsDialogState {
    mode: 'readonly' | 'submit';
    onSubmitSuccess?: () => void;
}

export const useTermsAndConditionsDialog = makeDialogState<ITermsAndConditionsDialogState>();

const ACKNOWLEDGE_TEXT =
    'I acknowledge that I (i) have read, understand, and agree to the above Terms and Conditions ' +
    'and (ii) am authorised to legally bind the entity that employs me and whom I am accessing ' +
    'the platform and its Confidential Information on behalf of.';

export const TermsAndConditionsDialog: React.FC<ITermsAndConditionsDialogProps> = () => {
    const snackbar = useAppSnackbar();
    const [{ close }, isOpen, state] = useTermsAndConditionsDialog();
    const [isFrameScrolledDown, setFrameScrolledDown] = useState(false);
    const [acceptTermsAndConditions, { error }] = useAcceptTermsAndConditionsMutation();
    const { mode, onSubmitSuccess = NOOP } = state || {};

    const initialValues = { isChecked: false };
    const [prevValues, setPrevValues] = useState<Partial<typeof initialValues>>({});
    const formik = useFormik({
        initialValues,
        validationSchema,
        validate: proxyAPIValidationError(prevValues, error),
        onSubmit: async (values, { resetForm }) => {
            setPrevValues(values);
            try {
                await acceptTermsAndConditions();
                setFrameScrolledDown(false);
                onSubmitSuccess();
                resetForm();
                setTimeout(() => close);
            } catch (e) {
                snackbar.showError({ message: 'Failed to accept terms and conditions' }, e);
            }
        },
    });

    const isSubmitMode = mode === 'submit';

    const clearAndClose = () => {
        if (isSubmitMode) {
            return;
        }
        setFrameScrolledDown(false);
        formik.resetForm();
        close();
    };

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={isOpen}
                maxWidth={'md'}
                data-testid={'TermsAndConditionsDialog'}
                PaperComponent={DialogFormComponent}
                PaperProps={{ className: styles.dialog }}
                onClose={clearAndClose}
                fullWidth
            >
                {!isSubmitMode && <DialogCloseButton className={styles.closeButton} onClick={clearAndClose} />}
                <TermsAndConditions
                    onScrolledDown={setFrameScrolledDown}
                    className={cl(mode === 'submit' && styles.submitModeFrame)}
                />
                {mode === 'submit' && (
                    <DialogActions>
                        <Tooltip
                            placement={'top'}
                            title={'Please read Terms and Conditions first'}
                            disableHoverListener={isFrameScrolledDown}
                            arrow
                        >
                            <div>
                                <FormCheckbox
                                    name={'isChecked'}
                                    label={
                                        <span
                                            className={cl(styles.acknowledgeText, !isFrameScrolledDown && 'secondary')}
                                        >
                                            {ACKNOWLEDGE_TEXT}
                                        </span>
                                    }
                                    disabled={!isFrameScrolledDown}
                                />
                            </div>
                        </Tooltip>
                        <LoadingSpinnerButton
                            type={'submit'}
                            spin={formik.isSubmitting}
                            disabled={!formik.values.isChecked}
                            className={styles.submitButton}
                        />
                    </DialogActions>
                )}
            </Dialog>
        </FormikProvider>
    );
};
