import React from 'react';
import Box from '@mui/material/Box';
import { GraphQLError } from 'graphql/error';
import { Launch, SvgIconComponent } from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { ERROR_TRACE_UI_URL_FACTORY } from '../../utils/helpers';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { GenericMessage } from '../GenericMessageCard';

interface IGQLErrorScreenProps {
    errors: GraphQLError[];
}

enum GqlErrorType {
    NotFoundException = 'NotFoundException',
    NoAccessException = 'NoAccessException',
}

const ErrorScreen: React.FC<{ Icon: SvgIconComponent; errors: GraphQLError[]; text: string }> = ({
    Icon,
    errors,
    text,
}) => {
    return (
        <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
            <GenericMessage Icon={Icon} IconProps={{ color: 'error' }} text={text} />
            {errors.map(({ message, extensions }) => (
                <p key={message} className={'text-align-center'}>
                    <span>{message}</span>
                    {(extensions?.traceId as string) && (
                        <a
                            href={ERROR_TRACE_UI_URL_FACTORY(extensions.traceId as string)}
                            className={'inline-flex flex-nowrap flex-align-center'}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            <span className={'ml-4 mr-4'}>{extensions.traceId as string}</span>
                            <Launch fontSize={'inherit'} />
                        </a>
                    )}
                </p>
            ))}
        </Box>
    );
};

export const GQLErrorScreen: React.FC<IGQLErrorScreenProps> = ({ errors = [] }) => {
    if (errors[0].extensions?.type === GqlErrorType.NotFoundException) {
        return (
            <>
                <ErrorScreen
                    Icon={SearchOffIcon}
                    text={'Element is not found. Please contact the administrator.'}
                    errors={[]}
                />
                <Button variant={'outlined'} component={Link} to={'..'}>
                    Go back
                </Button>
            </>
        );
    }

    if (errors[0].extensions?.type === GqlErrorType.NoAccessException) {
        return (
            <>
                <ErrorScreen
                    Icon={DoNotDisturbAltIcon}
                    text={
                        'Access denied. If you think you should be able to view this page please contact the administrator.'
                    }
                    errors={[]}
                />
                <Button variant={'outlined'} component={Link} to={'..'}>
                    Go back
                </Button>
            </>
        );
    }

    return (
        <ErrorScreen
            text={'Data request is invalid. Please contact the administrator.'}
            Icon={WarningAmberIcon}
            errors={errors}
        />
    );
};
