import { useFormik } from 'formik';
import { useContext, useMemo } from 'react';
import { DateTime } from 'luxon';
import { AppConfigResolverContext } from '../../../../components/AppConfigResolver';
import { IRoleForm, ONE_WEEK_IN_MINUTES, validationSchemaFactory } from './useRoleEditFormik.const';
import { useAppSnackbar } from '../../../../components/AppNotification';
import { handleGqlFormMutationError, scrollToFirstInvalidControl } from '../../../../../lib/forms';
import { prepareRoleModelValue } from '../../prepareRoleModelValue';
import { useUserDetails } from '../../../../hooks/useUserDetails';
import { fromEntries, NonFalsy, sortCompare } from '../../../../../lib/utils/helpers';
import { useRelatedEntitiesList } from '../useRelatedEntitiesList';
import { fromDateISO, fromISOToDateTime } from '../../../../../lib/utils/date';
import {
    Capabilities,
    CapabilityType,
    EmailNotificationType,
    EntityType,
    RelatedEntitiesFragment,
    RoleFragment,
    TOTPRequired,
    useSetRoleMutation,
} from '../../../../../interfaces/model';
import { useAppConfig } from '../../../../hooks/useAppConfig';
import { GMT_TIMEZONE } from '../../../../App.const';
import { useDirectorMinBirthday } from '../../../Entities/ViewEntity/DirectorDetailsDialog/DirectorDetailsDialog.const';
import { UserType } from '../steps/RoleGeneralInfoEdit/RoleGeneralInfoEdit.const';
import { CapabilitiesInfoFragment } from '../../EditRole/RoleCapabilitiesList/RoleCapabilities.query';

interface IRoleEditFormikProps {
    enableReinitialize: boolean;
    topCoRef: UUID | undefined;
    entityType: Maybe<EntityType> | undefined;
    roleData: Maybe<RoleFragment> | undefined;
    capabilitiesData: CapabilitiesInfoFragment[] | undefined;
    entityRef: UUID | undefined;
    onSaveSuccess: () => void;
    isDirectorRole?: boolean;
    onSubmit?: (values: IRoleForm) => void | Promise<void>;
}

const getInitialValues = (props: {
    role: Maybe<RoleFragment> | undefined;
    capabilitiesData: CapabilitiesInfoFragment[];
    entities: RelatedEntitiesFragment[];
    topCoRef: string | undefined;
    entityRef: string | undefined;
    isDirectorRole: boolean | undefined;
    directorMinBirthday: Date;
}): IRoleForm => {
    const { role, capabilitiesData, entities, topCoRef, entityRef, isDirectorRole, directorMinBirthday } = props;
    const existingRelationsMap = fromEntries(
        (role?.entityRelations || []).map((relation) => [relation.entityId, relation])
    );
    const memberCapabilitiesList = capabilitiesData.filter((item) => item.type === CapabilityType.MEMBER) || [];
    const additionalCapabilitiesList =
        capabilitiesData.filter(
            (item) => item.type !== CapabilityType.MEMBER && item.capability !== Capabilities.MANAGE_STRUCTURE
        ) || [];

    const entityRelations = entities
        .map(({ id, name }, index) => {
            const existingRelation = existingRelationsMap[id];
            const enabledRelation = entityRef && entityRef !== topCoRef ? entityRef === id : true;
            const isNewDirector = enabledRelation && isDirectorRole === true;
            const { enabled = false, director = false, psc = false, ubo = false } = existingRelation || {};

            return {
                id,
                name,
                index,
                enabled: role?.id ? enabled : enabled || enabledRelation,
                director: role?.id ? director : director || isNewDirector,
                psc,
                ubo,
            };
        })
        .sort((a, b) => sortCompare(a.name, b.name));

    const DEFAULT_LOGOUT_TIME = DateTime.local().set({ hour: 18, minute: 0 });
    const isLogoutAfterSpecificTimeEnabled = !!role?.timezone && !!role?.autoLogoutAt;

    const initialCapabilities = role?.capabilities?.filter(NonFalsy) || [];

    return {
        id: role?.id,
        isDirector: isDirectorRole,
        name: role?.name || void 0,
        email: role?.email || void 0,
        phone: role?.phone || void 0,
        login: role?.login || void 0,
        password: void 0,
        currentPassword: null,
        confirmPassword: null,
        allMemberCapabilities: role?.allMemberCapabilities,
        allEmailNotifications: role?.allEmailNotifications,
        totpRequired: role?.totpRequired || TOTPRequired.SERVER_DEFAULT,
        jobTitle: role?.jobTitle || void 0,
        capabilities: initialCapabilities,
        memberCapabilities: initialCapabilities.filter((item) =>
            memberCapabilitiesList.some((capability) => capability.capability === item)
        ),
        additionalCapabilities: initialCapabilities.filter((item) =>
            additionalCapabilitiesList.some((capability) => capability.capability === item)
        ),
        superadminCapabilities: role?.superadminCapabilities || [],
        emailNotifications:
            role?.emailNotifications ||
            Object.keys(EmailNotificationType).map((notification) => notification as EmailNotificationType),
        isAdmin: role?.isAdmin,
        birthday: fromDateISO(role?.birthday) || (isDirectorRole ? directorMinBirthday : void 0),
        nationality: role?.nationality,
        country: role?.address?.country,
        streetName: role?.address?.streetName,
        postcode: role?.address?.postcode,
        suite: role?.address?.suite,
        city: role?.address?.city,
        entityRelations,
        currentStep: 0,
        sessionTimeout: !!role?.sessionTimeout ? role.sessionTimeout / 60 : ONE_WEEK_IN_MINUTES,
        logoutAfterSpecificTime: isLogoutAfterSpecificTimeEnabled,
        autoLogoutAt: fromISOToDateTime(role?.autoLogoutAt) || DEFAULT_LOGOUT_TIME,
        timezone: role?.timezone ? role.timezone : GMT_TIMEZONE,
        singleSession: role?.singleSession,
        /**
         * In order for the formikWizardNavigation validation to work properly
         * on EntityRelationsEdit step, we create a 'fake' field 'directorRelations'
         * that is always valid initially - we then update it with actual entityRelations in
         * EntityRelationsEdit
         */
        directorRelations: [{ director: true }],
        userType: role?.capabilities?.includes(Capabilities.MANAGE_STRUCTURE) ? UserType.ADMIN : UserType.MEMBER,
    };
};

export const useRoleEditFormik = ({
    topCoRef,
    entityType,
    roleData,
    capabilitiesData,
    onSubmit,
    entityRef,
    onSaveSuccess,
    isDirectorRole,
    enableReinitialize,
}: IRoleEditFormikProps) => {
    const { role } = useUserDetails();
    const { securePasswordRequired } = useAppConfig();
    const snackbar = useAppSnackbar();
    const [updateRole] = useSetRoleMutation();
    const { authorizeUser } = useContext(AppConfigResolverContext);
    const relatedEntities = useRelatedEntitiesList(topCoRef, entityType);
    const directorMinBirthday = useDirectorMinBirthday();
    const validationSchema = useMemo(
        () => validationSchemaFactory(role?.id, securePasswordRequired),
        [role?.id, securePasswordRequired]
    );
    const initialValues = useMemo(
        () =>
            getInitialValues({
                entities: relatedEntities.data?.entities.data || [],
                isDirectorRole,
                role: roleData,
                capabilitiesData: capabilitiesData || [],
                entityRef,
                topCoRef,
                directorMinBirthday,
            }),
        [
            relatedEntities.data?.entities.data,
            isDirectorRole,
            roleData,
            capabilitiesData,
            entityRef,
            topCoRef,
            directorMinBirthday,
        ]
    );

    return useFormik({
        initialValues,
        validationSchema,
        enableReinitialize,
        validateOnMount: enableReinitialize,
        onSubmit: async (values, helpers) => {
            const { resetForm, setFieldValue } = helpers;
            if (!topCoRef) {
                return;
            }

            if (onSubmit) {
                onSubmit(values);
                resetForm();
                onSaveSuccess();
            } else {
                try {
                    await updateRole({
                        variables: {
                            role: prepareRoleModelValue(topCoRef, values),
                        },
                    });
                    if (role?.id === values.id) {
                        await authorizeUser();
                    }
                    resetForm();
                    setFieldValue('currentStep', 0);
                    onSaveSuccess();
                    snackbar.show({ message: 'Role has been submitted' });
                } catch (e) {
                    scrollToFirstInvalidControl();
                    setFieldValue('currentStep', 0);
                    if (!handleGqlFormMutationError(e, values, helpers)) {
                        snackbar.showError({ message: 'Failed to submit role' }, e);
                    }
                }
            }
        },
    });
};
