import React from 'react';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { GenericMessage } from '../GenericMessageCard';

interface INetworkErrorScreenProps {
    text: string | undefined;
}

export const NetworkErrorScreen: React.FC<INetworkErrorScreenProps> = ({ text }) => {
    return (
        <>
            <GenericMessage
                Icon={CloudOffIcon}
                IconProps={{ color: 'primary' }}
                text={'Failed to connect to server. Please check your network status and try again'}
            />
            {text && <p>{text}</p>}
        </>
    );
};
