import { useSelector } from 'react-redux';
import { Capabilities, ClientType } from '../../interfaces/model';
import { USER_CONFIG_SELECTOR } from '../App.selectors';
import { useCapability } from './useCapability';

export const useUserDetails = () => {
    const canManageStructure = useCapability(Capabilities.MANAGE_STRUCTURE);
    const { role, totpRequired, authRole, onboardingProgress = [] } = useSelector(USER_CONFIG_SELECTOR) || {};
    const { isAdmin, isSre, clientType } = role || {};
    const isBorrower = clientType === ClientType.BORROWER;
    const isLender = clientType === ClientType.LENDER;
    const isSponsor = clientType === ClientType.SPONSOR;
    const isAgent = clientType === ClientType.AGENT;
    const isFundAdmin = clientType === ClientType.FUND_ADMIN;

    return {
        role,
        isBorrower,
        isLender,
        isAdmin: Boolean(isAdmin),
        isSre: Boolean(isSre),
        isAgent,
        isSponsor,
        isFundAdmin,
        onboardingProgress,
        canManageStructure,
        authRole,
        clientType,
        totpRequired,
    };
};
