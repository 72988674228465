import React from 'react';
import { useAppSnackbar } from '../../components/AppNotification';
import { useConfirmDialog } from '../../../lib/modals/ConfirmDialog';
import { IListIconButtonProps, ListIconButton } from '../../../lib/dataDisplay/List/components/ListIconButton';
import Delete from '@mui/icons-material/Delete';
import { useParentContext } from '../../../lib/cdk/ParentContext';

interface IEntityFundAgentDeleteButtonProps extends Omit<IListIconButtonProps, 'icon'> {
    agentRef: UUID;
    agentName: string;
    onDeleteConfirm: (agentRef: UUID) => void;
}

export const EntityFundAgentDeleteButton: React.FC<IEntityFundAgentDeleteButtonProps> = ({
    agentRef,
    agentName,
    onDeleteConfirm,
    ...props
}) => {
    const parent = useParentContext();
    const snackBar = useAppSnackbar();
    const [confirmDialog] = useConfirmDialog();

    const onConfirm = async () => {
        try {
            await onDeleteConfirm(agentRef);
            await parent.update();
            snackBar.show({ message: 'Agent has been removed from the list' });
        } catch (e) {
            snackBar.showError({ message: 'Failed to remove the agent' }, e);
        }
    };

    const openDialog = (e: React.MouseEvent) => {
        e.preventDefault();
        confirmDialog.open({
            body: `You are about to remove ${agentName} from the list of agents. Are you sure?`,
            title: `Remove "${agentName}" from the list of agents`,
            onConfirm,
        });
    };

    return <ListIconButton icon={Delete} onClick={openDialog} {...props} />;
};
