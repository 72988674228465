import React from 'react';
import Input, { InputProps } from '@mui/material/Input';
import { IMaskInput } from 'react-imask';

export interface IMaskedInputProps extends Omit<InputProps, 'onChange'> {
    id: string;
    className?: string;
    placeholder?: string;
    inputProps: Partial<IMask.AnyMaskedOptions>;
    value: string | undefined;
    onChange: (value: string) => void;
    size?: 'small' | 'medium';
    disabled?: boolean;
    fullWidth?: boolean;
    error?: boolean;
}

const TextMaskCustom = React.forwardRef((props, ref) => {
    return (
        <IMaskInput
            {...props}
            // @ts-ignore
            inputRef={ref}
        />
    );
});

export const MaskedStringInput: React.FC<IMaskedInputProps> = ({ onChange, ...props }) => {
    return (
        <Input
            // "any" comes from the docs https://mui.com/components/text-fields/#integration-with-3rd-party-input-libraries
            inputComponent={TextMaskCustom as any}
            onChange={(e) => onChange(e.target.value)}
            {...props}
        />
    );
};
