import { useSelector } from 'react-redux';
import { Capabilities, SuperadminCapabilities } from '../../interfaces/model';
import { IAppState } from '../../store/rootReducer';

const hasCapability =
    (capability: Capabilities | SuperadminCapabilities) =>
    (state: IAppState): boolean => {
        return Boolean(
            state.userConfig?.userCapabilities?.includes(capability as Capabilities) ||
                state.userConfig?.role?.superadminCapabilities?.includes(capability as SuperadminCapabilities)
        );
    };

export const useCapability = (capability: Capabilities | SuperadminCapabilities) => {
    return useSelector(hasCapability(capability));
};
