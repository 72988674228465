import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { Divider, List, ListItem } from '@mui/material';
import { counterpartyStatusColors, counterpartyStatusLabels } from '../Counterparties.const';
import { IValueCellProps, ValueCell } from '../../../../lib/dataDisplay/List/components/ListCells';
import { IconWithTooltip } from '../../../../lib/icons/IconWithTooltip';
import { CounterpartyStatus } from '../../../../interfaces/model';
import { dateFromJSToFormat } from '../../../../lib/utils/date';
import { ROLE_VIEW_ROUTE_FACTORY } from '../../../App.routes';
import { RowStack } from '../../../../lib/cdk/RowStack';

export interface ICounterpartyStatusCellDetails {
    authorizedAt: Maybe<LocalDate> | undefined;
    authorizedBy: Maybe<{ name: Maybe<string>; shortCode: Maybe<string> }> | undefined;
    cpAuthorizedAt: Maybe<LocalDate> | undefined;
    cpAuthorizedBy: Maybe<{ name: Maybe<string>; shortCode: Maybe<string> }> | undefined;
    submittedAt: Maybe<LocalDate> | undefined;
    submittedBy: Maybe<{ name: Maybe<string>; shortCode: Maybe<string> }> | undefined;
    cpSubmittedAt: Maybe<LocalDate> | undefined;
    cpSubmittedBy: Maybe<{ name: Maybe<string>; shortCode: Maybe<string> }> | undefined;
}

interface ICounterpartyStatusCellProps extends IValueCellProps {
    value: Maybe<CounterpartyStatus>;
    details?: Maybe<ICounterpartyStatusCellDetails>;
}

const TooltipContentListRow: React.FC<{
    label: string;
    role: { name: Maybe<string>; shortCode: Maybe<string> } | null | undefined;
    time: string | null | undefined;
}> = ({ role, time, label }) => {
    if (!(time && role?.name && role?.shortCode)) {
        return null;
    }

    return (
        <ListItem>
            <Stack>
                <Box className={'secondary'} fontWeight={600} whiteSpace={'nowrap'}>
                    {label}:
                </Box>
                <div>
                    <Link to={ROLE_VIEW_ROUTE_FACTORY(role.shortCode!)}>{role.name}</Link>,{' '}
                    <span className={'white-space-nowrap'}>{dateFromJSToFormat(new Date(time))}</span>
                </div>
            </Stack>
        </ListItem>
    );
};

const isNotEmptyDetails = (
    value: Maybe<ICounterpartyStatusCellDetails> | undefined
): value is ICounterpartyStatusCellDetails => {
    return Boolean(
        (!!value && value.submittedAt && value.submittedBy && value.authorizedAt && value.authorizedBy) ||
            (!!value && value.cpAuthorizedAt && value.cpAuthorizedBy && value.cpSubmittedAt && value.cpSubmittedBy)
    );
};

const TooltipContent: React.FC<{ value: ICounterpartyStatusCellDetails }> = ({ value }) => {
    const { cpAuthorizedBy, cpAuthorizedAt, cpSubmittedAt, cpSubmittedBy, authorizedBy, submittedBy } = value;

    return (
        <List disablePadding>
            <TooltipContentListRow label={'Authorized by them'} time={cpAuthorizedAt} role={cpAuthorizedBy} />
            <TooltipContentListRow label={'Submitted by them'} role={cpSubmittedBy} time={cpSubmittedAt} />

            {(cpAuthorizedBy || cpSubmittedBy) && (authorizedBy || submittedBy) && <Divider />}

            <TooltipContentListRow label={'Authorized by us'} time={value.authorizedAt} role={authorizedBy} />
            <TooltipContentListRow label={'Submitted by us'} time={value.submittedAt} role={submittedBy} />
        </List>
    );
};

export const CounterpartyStatusCell: React.FC<ICounterpartyStatusCellProps> = ({ value, details, ...props }) => {
    return (
        <ValueCell color={counterpartyStatusColors[value!]} {...props}>
            <RowStack>
                <span>{counterpartyStatusLabels[value!]}</span>
                {value !== CounterpartyStatus.UNAPPROVED && isNotEmptyDetails(details) && (
                    <IconWithTooltip Icon={InfoIcon} text={<TooltipContent value={details} />} />
                )}
            </RowStack>
        </ValueCell>
    );
};
