import { useLocation } from 'react-router';
import { IDocumentsUrlState, useDocumentsUrlState } from './useDocumentsUrlState';
import { joinQueryParams } from 'lib/utils/router/joinQueryParams';

export const useCurrentDocumentRouteFactory = () => {
    const location = useLocation();
    const [existingParams] = useDocumentsUrlState();
    return (params: IDocumentsUrlState) =>
        `${location.pathname}${joinQueryParams<IDocumentsUrlState>({ ...existingParams, ...params })}`;
};
