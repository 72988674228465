import React from 'react';
import Box from '@mui/material/Box';
import { Launch } from '@mui/icons-material';
import { ERROR_TRACE_UI_URL_FACTORY } from '../../../../lib/utils/helpers';
import styles from './MessageWithTraceI.module.css';

export const MessageWithTraceID: React.FC<{ message: React.ReactNode; traceId: string }> = ({ message, traceId }) => {
    const traceUrl = ERROR_TRACE_UI_URL_FACTORY(traceId);
    return (
        <Box display={'flex'} flexDirection={'column'}>
            <span>{message}</span>
            {traceUrl && (
                <Box display={'flex'}>
                    <Box marginRight={'2px'}>Error ID:</Box>
                    <a href={traceUrl} target={'_blank'} className={styles.link} rel="noreferrer">
                        {traceId}
                        <Launch fontSize={'inherit'} className={styles.linkIcon} />
                    </a>
                </Box>
            )}
        </Box>
    );
};
