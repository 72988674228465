import React, { HTMLAttributeAnchorTarget } from 'react';
import cl from 'classnames';
import isString from 'lodash/isString';
import { Link, LinkProps } from 'react-router-dom';
import { Link as LinkIcon } from '@mui/icons-material';
import styles from './LinkWithIcon.module.css';

export interface ILinkWithIconProps extends Omit<LinkProps, 'prefix'> {
    color?: 'inherit' | 'default';
    suffix?: React.ReactNode;
    prefix?: React.ReactNode;
    target?: HTMLAttributeAnchorTarget;
}

export const LinkWithIcon: React.FC<ILinkWithIconProps> = ({
    to,
    color = 'inherit',
    className,
    children,
    prefix,
    suffix,
    target,
}) => {
    if (isString(children)) {
        const parts = children.trim().split(' ');
        const lastWord = parts.pop();

        return (
            <Link
                to={to}
                target={target}
                className={cl(className, styles.link, color === 'inherit' && styles.inheritColor)}
            >
                {prefix && <>{prefix}&nbsp;</>}
                {parts.join(' ')}
                {parts.length > 0 && <>&nbsp;</>}
                <span className={styles.lastWordWrap}>
                    {lastWord}&nbsp;
                    {suffix || <LinkIcon color={'inherit'} fontSize={'small'} />}
                </span>
            </Link>
        );
    }

    return (
        <Link to={to} className={cl(className, styles.link, color === 'inherit' && styles.inheritColor)}>
            {prefix}
            {children}&nbsp;
            {suffix || <LinkIcon color={'inherit'} fontSize={'small'} />}
        </Link>
    );
};
