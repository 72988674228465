import React from 'react';
import { Grid } from '@mui/material';
import { TextInput } from '../../lib/inputs/TextInput';
import { CountrySelect } from '../../lib/inputs/CountrySelect/CountrySelect';

interface IAddressFieldsGroupProps {
    name?: string;
    disabled?: boolean;
}

export const AddressFieldsGroup: React.FC<IAddressFieldsGroupProps> = ({ disabled, name = '' }) => {
    const getPath = (field: string) => `${name ? `${name}.` : ''}${field}`;

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextInput
                    name={getPath('streetName')}
                    disabled={disabled}
                    placeholder={'Enter Street Name'}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    name={getPath('suite')}
                    disabled={disabled}
                    placeholder={'Suite/Apt # (optional)'}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput name={getPath('city')} disabled={disabled} placeholder={'Enter City'} fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextInput name={getPath('postcode')} disabled={disabled} placeholder={'Enter Postcode'} fullWidth />
            </Grid>
            <Grid item xs={6}>
                <CountrySelect
                    name={getPath('country')}
                    placeholder={'Select Country'}
                    disablePortal={false}
                    disabled={disabled}
                    clearable={true}
                />
            </Grid>
        </Grid>
    );
};
