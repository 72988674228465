import React from 'react';
import { IValueCellProps, ValueCell } from './ValueCell';
import { CountryFlagAndName } from '../../../CountryFlagAndName';

export interface IJurisdictionCellProps extends IValueCellProps {
    value: Maybe<string> | undefined;
    showFullName?: boolean;
}

export const JurisdictionCell: React.FC<IJurisdictionCellProps> = ({ value, showFullName = true, ...props }) => {
    return (
        <ValueCell {...props}>
            {value && <CountryFlagAndName country={value} showFullName={showFullName} nowrap={props.nowrap} />}
        </ValueCell>
    );
};
