import React from 'react';
import { DocumentReviewHistoryListRow, IDocumentReviewHistoryListRowItem } from './DocumentReviewHistoryListRow';
import { IListColumn, List } from '../../../../../../../../../lib/dataDisplay/List';
import { DocumentReviewFragment } from '../../../../../../../../../interfaces/model';

interface IDocumentLatestReviewListProps {
    value: DocumentReviewFragment[];
    highlightText?: string;
    filtered?: boolean;
    parentRef: UUID | undefined;
    updateData: () => Promise<void>;
}

const prepareData = (data: DocumentReviewFragment[]): IDocumentReviewHistoryListRowItem[] => {
    return data
        .map((item) => {
            const role = item.role;
            const entity = item.role?.topCo;

            return {
                id: String(item.timestamp),
                date: item.timestamp,
                userName: role?.name || '',
                userShortCode: role?.shortCode!,
                entityName: entity?.name || '',
                entityShortCode: entity?.shortCode!,
                entityType: entity?.entityType,
                status: item.status,
                reviewType: item.type,
                comment: item.comment || '',
                document: item.document,
            };
        })
        .sort((a, b) => b.date - a.date);
};

const COLUMNS: IListColumn<IDocumentReviewHistoryListRowItem>[] = [
    { id: 'date', label: 'Date', sort: false },
    { id: 'userName', label: 'User', sort: false },
    { id: 'entityName', label: 'Entity', sort: false },
    { id: 'status', label: 'Review status', shrink: true, sort: false },
    { id: 'reviewType', shrink: true, sort: false },
    { id: 'comment', label: 'Comment', sort: false },
    { id: 'actions', shrink: true },
];

const PAPER_BG = 'var(--bg-default)';

export const DocumentReviewHistoryList: React.FC<IDocumentLatestReviewListProps> = ({
    value,
    highlightText,
    filtered,
    parentRef,
    updateData,
}) => {
    return (
        <List
            columns={COLUMNS}
            data={prepareData(value)}
            rowComponent={DocumentReviewHistoryListRow}
            rowComponentProps={{ parentRef, updateData }}
            emptyStatePlaceholderProps={{
                title: 'No reviews',
                imageName: 'validation',
                description: filtered
                    ? 'No records found, try to change filters'
                    : 'This document has not been reviewed yet',
                sx: { background: PAPER_BG },
            }}
            highlightText={highlightText}
            maxHeight={'60vh'}
            stickyHeader
            embedded
        />
    );
};
