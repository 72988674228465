import React from 'react';
import Stack from '@mui/material/Stack';
import { FormikProvider, useFormik } from 'formik';
import {
    Alert,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    FormLabel,
    Switch,
} from '@mui/material';
import { LoadingSpinnerButton } from 'lib/buttons/LoadingSpinnerButton';
import { makeDialogState } from 'store/dialogState/makeDialogState';
import { DialogCloseButton } from 'lib/modals/DialogCloseButton';
import styles from './DocumentAdditionalDetailsDialog.module.css';
import { DialogFormComponent } from 'lib/forms';
import { NOOP } from 'lib/utils/helpers';
import { DocumentInInput } from 'interfaces/model';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

interface IDocumentAdditionalDetailsDialogProps {}

interface IDocumentAdditionalDetailsDialogState {
    value?: DocumentInInput;
    onSubmit: (value: DocumentInInput) => Promise<void>;
}

export const useDocumentAdditionalDetailsDialog = makeDialogState<IDocumentAdditionalDetailsDialogState>();

export const DocumentAdditionalDetailsDialog: React.FC<IDocumentAdditionalDetailsDialogProps> = () => {
    const [{ close }, isOpen, state] = useDocumentAdditionalDetailsDialog();
    const { value = {}, onSubmit = NOOP } = state || {};

    const formik = useFormik({
        enableReinitialize: isOpen,
        initialValues: { isPrivate: true, ...value },
        onSubmit: (values, { resetForm }) => {
            onSubmit(values);
            resetForm();
            close();
        },
    });

    const clearAndClose = () => {
        formik.resetForm();
        close();
    };

    const isPrivate = !!formik.values.isPrivate;

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={isOpen}
                maxWidth={'sm'}
                data-testid={'Dialog'}
                PaperComponent={DialogFormComponent}
                onClose={clearAndClose}
                fullWidth
            >
                <DialogTitle>
                    Select Accessibility Level
                    <DialogCloseButton onClick={clearAndClose} />
                </DialogTitle>
                <DialogContent>
                    <Container maxWidth={'sm'}>
                        <Stack spacing={3}>
                            <Alert
                                severity={'success'}
                                icon={<TipsAndUpdatesIcon color={'action'} sx={{ fontSize: '24px' }} />}
                            >
                                Make sure to set up right Accessibility level. If you turn off “Private Document”, then
                                the document will be available for every user in the system.
                            </Alert>
                            <Stack spacing={0.5}>
                                <FormLabel htmlFor={'privatePublicSwitch'} className={styles.privateAccessLabel}>
                                    <span>Private Document</span>
                                    <Switch
                                        id={'privatePublicSwitch'}
                                        size={'small'}
                                        color={'primary'}
                                        onChange={() => formik.setFieldValue('isPrivate', !isPrivate)}
                                        checked={isPrivate}
                                    />
                                </FormLabel>
                                <FormHelperText>Private documents are available only for approved users</FormHelperText>
                            </Stack>
                        </Stack>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <LoadingSpinnerButton label={'Done'} type={'submit'} spin={formik.isSubmitting} />
                </DialogActions>
            </Dialog>
        </FormikProvider>
    );
};
