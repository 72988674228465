import { EntityType, ViewEntityFragment } from '../../../interfaces/model';
import {
    AGENT_VIEW_ROUTE_FACTORY,
    BORROWER_VIEW_ROUTE_FACTORY,
    FUND_ADMIN_VIEW_ROUTE_FACTORY,
    LENDER_VIEW_ROUTE_FACTORY,
    SPONSOR_VIEW_ROUTE_FACTORY,
} from '../../App.routes';
import { NonFalsy } from '../../../lib/utils/helpers';
import { ParentEntity } from './ViewEntity/LegalEntities/LegalEntities.helpers';

export const canCreateEntity = ({ entityType, caps }: ParentEntity): boolean => {
    switch (entityType) {
        case EntityType.AGENT_TOP_LEVEL:
            return caps.createAgent;
        case EntityType.BORROWER_TOP_LEVEL:
            return caps.createSubsidiary;
        case EntityType.AGENT:
        case EntityType.BORROWER_SUBSIDIARY:
        case EntityType.LENDER_INVESTMENT_BANK:
        case EntityType.LENDER_INVESTMENT_MANAGER:
            return caps.createUBO;
        case EntityType.LENDER_TOP_LEVEL:
            return caps.createInvestmentBank || caps.createInvestmentManager;
        case EntityType.SPONSOR_TOP_LEVEL:
        case EntityType.SPONSOR:
            return caps.createSponsor;
        case EntityType.FUNDADMIN_TOP_LEVEL:
            return caps.createFundadmin;
    }
    return false;
};

export const prepareEmailDomainsModelValue = (domains: string[] | null | undefined) => {
    return domains?.map((domain) => domain?.replace(/\s|[,]+/g, '')).filter(NonFalsy);
};

export const isTopCoEntityType = (type: EntityType | undefined) => {
    return (
        !!type &&
        [
            EntityType.LENDER_TOP_LEVEL,
            EntityType.BORROWER_TOP_LEVEL,
            EntityType.AGENT_TOP_LEVEL,
            EntityType.SPONSOR_TOP_LEVEL,
            EntityType.FUNDADMIN_TOP_LEVEL,
        ].includes(type)
    );
};

export const isLenderEntityType = (entityType: EntityType | undefined): boolean =>
    !!entityType &&
    [EntityType.LENDER_TOP_LEVEL, EntityType.LENDER_INVESTMENT_MANAGER, EntityType.LENDER_INVESTMENT_BANK].includes(
        entityType
    );

export type PartialEntity = {
    id?: UUID;
    topCoRef?: UUID;
    entityType?: EntityType;
    parent?: Maybe<{
        entityType?: EntityType;
    }>;
};

export const isLenderEntity = (entity: PartialEntity): boolean => {
    if (entity.entityType === EntityType.UBO) {
        return isLenderEntityType(entity.parent?.entityType!);
    }

    return isLenderEntityType(entity.entityType);
};

const isAgentEntityType = (entityType: EntityType | undefined): boolean =>
    !!entityType && [EntityType.AGENT_TOP_LEVEL, EntityType.AGENT].includes(entityType);

export const isAgentEntity = (entity: PartialEntity): boolean => {
    if (entity.entityType === EntityType.UBO) {
        return isAgentEntityType(entity.parent?.entityType!);
    }

    return isAgentEntityType(entity.entityType);
};

const isFundAdminEntityType = (entityType: EntityType | undefined): boolean =>
    !!entityType && [EntityType.FUNDADMIN_TOP_LEVEL, EntityType.FUNDADMIN].includes(entityType);
export const isFundAdminEntity = (entity: PartialEntity): boolean => {
    if (entity.entityType === EntityType.UBO) {
        return isFundAdminEntityType(entity.parent?.entityType!);
    }

    return isFundAdminEntityType(entity.entityType);
};

const isBorrowerEntityType = (entityType: EntityType | undefined): boolean =>
    !!entityType && [EntityType.BORROWER_TOP_LEVEL, EntityType.BORROWER_SUBSIDIARY].includes(entityType);

export const isBorrowerEntity = (entity: PartialEntity): boolean => {
    if (entity.entityType === EntityType.UBO) {
        return isBorrowerEntityType(entity.parent?.entityType!);
    }

    return isBorrowerEntityType(entity.entityType);
};

export const isSponsorEntity = (entity: PartialEntity): boolean => {
    return !!entity.entityType && [EntityType.SPONSOR_TOP_LEVEL, EntityType.SPONSOR].includes(entity.entityType);
};

export const getEntityViewRouteFactory = (entity: ViewEntityFragment): ((sc: string) => string) => {
    if (isAgentEntity(entity)) {
        return AGENT_VIEW_ROUTE_FACTORY;
    }

    if (isBorrowerEntity(entity)) {
        return BORROWER_VIEW_ROUTE_FACTORY;
    }

    if (isLenderEntity(entity)) {
        return LENDER_VIEW_ROUTE_FACTORY;
    }

    if (isSponsorEntity(entity)) {
        return SPONSOR_VIEW_ROUTE_FACTORY;
    }

    if (isFundAdminEntity(entity)) {
        return FUND_ADMIN_VIEW_ROUTE_FACTORY;
    }

    throw new Error(`Unhandled entity type: ${entity.entityType}`);
};

export const isEntityWithBankAccounts = (entity: { entityType: EntityType | undefined }): boolean =>
    !!entity.entityType &&
    [EntityType.BORROWER_SUBSIDIARY, EntityType.AGENT, EntityType.LENDER_INVESTMENT_BANK].includes(entity.entityType);

export const isEntityWithTaxId = (entity: { entityType: EntityType | undefined }): boolean =>
    !isTopCoEntityType(entity.entityType) && isLenderEntity(entity);
