import React, { useMemo, useState } from 'react';

interface ITreeContext {
    isExpanded: (nodeId: string) => boolean;
    toggleExpand: (nodeId: string) => void;
}

const errorImplementation = (method: string) => () => {
    throw new Error(`${method} method is not implemented`);
};

export const TreeContext = React.createContext<ITreeContext>({
    isExpanded: errorImplementation('isExpanded'),
    toggleExpand: errorImplementation('toggleExpand'),
});

interface ITreeProps {
    defaultExpanded: string[];
    children: React.ReactNode;
}

export const Tree: React.FC<ITreeProps> = ({ defaultExpanded, children }) => {
    const [expandedNodes = defaultExpanded, setExpandedNodes] = useState<string[]>();

    const contextValue: ITreeContext = useMemo(
        () => ({
            isExpanded: (nodeId: string) => expandedNodes.includes(nodeId),
            toggleExpand(nodeId: string) {
                if (this.isExpanded(nodeId)) {
                    setExpandedNodes(expandedNodes.filter((id) => id !== nodeId));
                } else {
                    setExpandedNodes([...expandedNodes, nodeId]);
                }
            },
        }),
        [expandedNodes]
    );

    return (
        <TreeContext.Provider value={contextValue}>
            <div>{children}</div>
        </TreeContext.Provider>
    );
};
