import React from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

interface ISpinnerProps {
    size?: number;
}

export const Spinner: React.FC<ISpinnerProps> = ({ size = 32 }) => {
    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'}>
            <CircularProgress color={'primary'} size={size} />
        </Box>
    );
};
