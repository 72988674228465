import React from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { ReviewHistoryDialog, useReviewHistoryDialog } from './ReviewHistoryDialog';
import { DocumentReviewButton } from '../../../components/DocumentReviewButton';
import { KYCDocCategory, KYCDocumentFragment } from 'interfaces/model';
import { IDocumentViewDialogParent } from '../../../interfaces';
import { DocumentReviewersList } from './DocumentReviewersList';
import { RowStack } from 'lib/cdk/RowStack';

interface IDocumentReviewsProps {
    value: KYCDocumentFragment;
    parent: IDocumentViewDialogParent | undefined;
    updateData: () => Promise<void>;
    kycMode: boolean;
}

export const DocumentReviews: React.FC<IDocumentReviewsProps> = ({ value, parent, updateData, kycMode }) => {
    const [reviewHistoryDialog] = useReviewHistoryDialog();
    const hasReviewers = !!value.statusV2?.length;

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} alignItems={'center'}>
                {hasReviewers && (
                    <h6>
                        {value.docCategory === KYCDocCategory.FACILITY_DOCUMENTS
                            ? 'Last review'
                            : 'Agent review status'}
                    </h6>
                )}
                {parent && (
                    <RowStack spacing={2} className={'ml-auto'}>
                        <Button
                            data-testid={'DataroomDialog:viewReviewHistoryButton'}
                            size={'small'}
                            variant={'outlined'}
                            onClick={() => reviewHistoryDialog.open({ value, parent })}
                        >
                            View review history
                        </Button>
                        {parent.hasDocumentApproveAccess && (
                            <DocumentReviewButton
                                size={'small'}
                                value={value}
                                parentRef={parent.id}
                                updateData={updateData}
                            />
                        )}
                    </RowStack>
                )}
            </Box>
            {hasReviewers && (
                <Box marginTop={'12px'}>
                    <DocumentReviewersList value={value.statusV2 || []} />
                </Box>
            )}
            <ReviewHistoryDialog updateData={updateData} kycMode={kycMode} />
        </Box>
    );
};
