import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { GenericMessage } from '../GenericMessageCard';

interface IGenericErrorProps {
    text: string | undefined;
}

export const GenericErrorScreen: React.FC<IGenericErrorProps> = ({ text }) => {
    return (
        <>
            <GenericMessage
                Icon={CancelIcon}
                IconProps={{ color: 'error' }}
                text={'Failed to retrieve data. Please try again or contact the administrator'}
            />
            {text && <p>{text}</p>}
        </>
    );
};
