import React from 'react';
import styles from './FloatingPanelCell.module.css';
import { TableCell, TableCellProps } from '@mui/material';

/**
 * Parent Row component needs "rowWithFloatingPanelCell" class to be defined to make this panel visible on hover
 * @param children
 * @constructor
 */
export const FloatingPanelCell: React.FC<TableCellProps> = ({ children, ...props }) => {
    return (
        <TableCell className={styles.cell} {...props}>
            <div className={styles.panelWrap}>{children}</div>
        </TableCell>
    );
};
