import React from 'react';
import styles from './CurrentStepLabel.module.css';

interface ICurrentStepLabelProps {
    currentStep: number;
    maxSteps: number;
}

export const CurrentStepLabel: React.FC<ICurrentStepLabelProps> = ({ currentStep, maxSteps }) => {
    return (
        <span className={styles.text} data-testid={'CounterpartiesEdit:currentStep'}>
            Step {currentStep + 1} of {maxSteps}
        </span>
    );
};
