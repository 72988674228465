import React from 'react';
import { Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer } from '@mui/material';
import { makeDialogState } from '../../../store/dialogState/makeDialogState';
import { DialogCloseButton } from '../../../lib/modals/DialogCloseButton';
import { BuildVersion } from '../../../BuildVersion';
import { useAppConfig } from '../../hooks/useAppConfig';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { EmbeddedTableTheme } from '../../../lib/dataDisplay/List/themes/EmbeddedTableTheme';
import styles from './VersionDialog.module.css';
import Button from '@mui/material/Button';
import { useClipboard } from '../../../lib/hooks/useClipboard';
import { useAppSnackbar } from '../AppNotification';
import Box from '@mui/material/Box';

export const useVersionDialog = makeDialogState();

interface IVersionTableProps {
    branch: string;
    message: string;
    commit: string;
}
const VersionTable: React.FC<IVersionTableProps> = ({ branch, message, commit }) => {
    return (
        <TableContainer>
            <Table>
                <TableHead className={styles.tableHeader}>
                    <TableRow>
                        <TableCell>Branch</TableCell>
                        <TableCell width={'65%'}>Commit title</TableCell>
                        <TableCell>Commit hash</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{branch}</TableCell>
                        <TableCell>{message}</TableCell>
                        <TableCell>{commit}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export const VersionDialog: React.FC = () => {
    const snackBar = useAppSnackbar();
    const [{ close }, isOpen] = useVersionDialog();
    const { version: backendVersion } = useAppConfig();
    const copyToClipboard = useClipboard();
    const frontendVersion = BuildVersion;
    const showTwoVersions = backendVersion.commit !== frontendVersion.commit;

    const handleCopyToClipboard = (branch: string, message: string, commit: string) => {
        const text = `branch: ${branch}\ncommit hash: ${commit}\ncommit title: ${message}`;
        copyToClipboard(text, {
            onSuccess: () => snackBar.show({ message: 'Version info copied to clipboard' }),
            onError: () => snackBar.show({ message: 'Failed to copy version info to clipboard' }),
        });
    };

    return (
        <Dialog open={isOpen} maxWidth={'sm'} onClose={close} data-testid={'VersionDialog'} fullWidth>
            <DialogTitle>
                Current version
                <DialogCloseButton onClick={close} />
            </DialogTitle>
            <DialogContent>
                <EmbeddedTableTheme>
                    {showTwoVersions && (
                        <>
                            <p>Backend version</p>
                            <VersionTable
                                branch={backendVersion.branch}
                                message={backendVersion.message}
                                commit={backendVersion.commit}
                            />
                            <Box display={'flex'} justifyContent={'flex-end'} marginTop={'16px'}>
                                <Button
                                    variant={'contained'}
                                    onClick={() =>
                                        handleCopyToClipboard(
                                            backendVersion.branch,
                                            backendVersion.message,
                                            backendVersion.commit
                                        )
                                    }
                                >
                                    Copy
                                </Button>
                            </Box>
                            <p>Frontend version</p>
                            <VersionTable
                                branch={frontendVersion.branch}
                                message={frontendVersion.message}
                                commit={frontendVersion.commit}
                            />
                            <Box display={'flex'} justifyContent={'flex-end'} marginTop={'16px'}>
                                <Button
                                    variant={'contained'}
                                    onClick={() =>
                                        handleCopyToClipboard(
                                            frontendVersion.branch,
                                            frontendVersion.message,
                                            frontendVersion.commit
                                        )
                                    }
                                >
                                    Copy
                                </Button>
                            </Box>
                        </>
                    )}
                    {!showTwoVersions && (
                        <>
                            <VersionTable
                                branch={backendVersion.branch}
                                message={backendVersion.message}
                                commit={backendVersion.commit}
                            />
                            <Box display={'flex'} justifyContent={'flex-end'} marginTop={'16px'}>
                                <Button
                                    variant={'contained'}
                                    onClick={() =>
                                        handleCopyToClipboard(
                                            backendVersion.branch,
                                            backendVersion.message,
                                            backendVersion.commit
                                        )
                                    }
                                >
                                    Copy
                                </Button>
                            </Box>
                        </>
                    )}
                </EmbeddedTableTheme>
            </DialogContent>
        </Dialog>
    );
};
