import React from 'react';
import { Dialog, DialogActions } from '@mui/material';
import { FeedbackMessageCard, FeedbackSeverity } from '../../dataDisplay/FeedbackMessageCard/FeedbackMessageCard';
import { makeDialogState } from '../../../store/dialogState/makeDialogState';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogCloseButton } from '../DialogCloseButton';

interface IFeedbackMessageDialogProps {
    id?: string;
}

interface IFeedbackMessageDialogState {
    title: React.ReactNode;
    message: React.ReactNode;
    actions?: React.ReactNode;
    severity?: FeedbackSeverity;
}

export const useFeedbackMessageDialog = makeDialogState<IFeedbackMessageDialogState>();

export const FeedbackMessageDialog: React.FC<IFeedbackMessageDialogProps> = ({ id }) => {
    const [{ close }, isOpen, state] = useFeedbackMessageDialog({ id });
    const { title, message, actions, severity = 'success' } = state || {};

    return (
        <Dialog open={isOpen} maxWidth={'sm'} onClose={close} data-testid={'FeedbackMessageDialog'} fullWidth>
            <FeedbackMessageCard
                title={title}
                severity={severity}
                heading={
                    <DialogTitle sx={{ borderBottom: 0 }}>
                        <DialogCloseButton onClick={close} />
                    </DialogTitle>
                }
            >
                {message}
            </FeedbackMessageCard>
            {actions && <DialogActions>{actions}</DialogActions>}
        </Dialog>
    );
};
