import React from 'react';
import styles from './ColoredTextCell.module.css';
import { IValueCellProps, ValueCell } from '../ValueCell';

interface IColoredTextCellProps<T extends string = string> extends IValueCellProps {
    value: T;
    textMap: Record<T, React.ReactNode>;
    colorsMap: Record<T, Colors>;
    hideDot?: boolean;
}

export const ColoredTextCell: React.FC<IColoredTextCellProps> = ({ value, textMap, colorsMap, hideDot, ...props }) => {
    return (
        <ValueCell className={!hideDot ? styles.cell : ''} color={colorsMap[value]} {...props}>
            {textMap[value]}
        </ValueCell>
    );
};
