import React from 'react';
import cl from 'classnames';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './WizardNavigation.module.css';

export interface IWizardNavigationStep {
    name: string;
    valid: boolean;
}

interface IWizardNavigationProps extends BoxProps {
    activeStepIndex: number;
    steps: IWizardNavigationStep[];
    onStepChange: (stepIndex: number) => void;
}

const StepIcon: React.FC<{ pastStep: boolean; valid: boolean; stepIndex: number }> = ({
    valid,
    pastStep,
    stepIndex,
}) => {
    if (pastStep && valid) {
        return <CheckCircleIcon className={styles.completeStepIcon} />;
    }
    if (pastStep && !valid) {
        return <CancelIcon className={styles.incompleteStepIcon} />;
    }
    return <span className={styles.stepIcon}>{stepIndex + 1}</span>;
};

export const WizardNavigation: React.FC<IWizardNavigationProps> = ({
    activeStepIndex,
    steps,
    onStepChange,
    ...props
}) => {
    return (
        <Box className={styles.wrap} {...props}>
            {steps.map((step, index) => {
                const isPastStep = index < activeStepIndex;
                const isComplete = isPastStep && step.valid;
                const isIncomplete = isPastStep && !step.valid;
                const isActive = index === activeStepIndex;
                return (
                    <div
                        key={index}
                        className={cl(
                            styles.step,
                            isActive && styles.active,
                            isComplete && styles.completeStep,
                            isIncomplete && styles.incompleteStep
                        )}
                        onClick={() => onStepChange(index)}
                    >
                        <StepIcon pastStep={isPastStep} valid={step.valid} stepIndex={index} />
                        {step.name}
                    </div>
                );
            })}
        </Box>
    );
};
