import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { appThemeDark } from './appThemeDark';
import { ledgercommTheme } from './overrides/ledgercommTheme';
import { ledgercommMuiTableTheme } from './overrides/ledgercommMuiTableTheme';
import '../../../styles/index.css';

// Support Mui Lab components style overrides
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';

export const AppTheme: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appThemeDark}>
                <ThemeProvider theme={ledgercommTheme}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={ledgercommMuiTableTheme}>{children}</ThemeProvider>
                    </StyledEngineProvider>
                </ThemeProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
