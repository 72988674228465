import React from 'react';
import { NonFalsy } from '../../../../../lib/utils/helpers';
import { RowStack } from '../../../../../lib/cdk/RowStack';
import Button from '@mui/material/Button';
import { NoValuePlaceholder } from '../../../../../lib/cdk/NoValuePlaceholder';
import { Link } from 'react-router-dom';
import { joinQueryParams } from '../../../../../lib/utils/router/joinQueryParams';
import { AUTHORISED_SIGNATURES_FIELD_ID } from '../../../../App.const';
import { useLocation } from 'react-router';

interface IFundAuthorizedSignaturesViewProps {
    value: { name: Maybe<string> }[];
    hasEditAccess: boolean;
}

export const FundAuthorizedSignaturesView: React.FC<IFundAuthorizedSignaturesViewProps> = ({
    value,
    hasEditAccess,
}) => {
    const location = useLocation();

    if (value.length === 0 && hasEditAccess) {
        return (
            <RowStack alignItems={'center'} justifyContent={'flex-end'}>
                <Button
                    component={Link}
                    to={`${location.pathname}${joinQueryParams({ edit: true, focus: AUTHORISED_SIGNATURES_FIELD_ID })}`}
                >
                    Add signatories
                </Button>
            </RowStack>
        );
    }

    return (
        <div>
            {value
                .map(({ name }) => name)
                .filter(NonFalsy)
                .sort()
                .join(', ') || <NoValuePlaceholder />}
        </div>
    );
};
