import cl from 'classnames';
import Table from '@mui/material/Table';
import { ApolloError } from '@apollo/client';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import React, { useMemo, useRef, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { TableCell, TablePagination, TableRow } from '@mui/material';
import { EmptyStatePlaceholder, IEmptyStatePlaceholderProps } from '../../../App/common/EmptyStatePlaceholder';
import { IListColumn, IListColumnType, IListRow, IListSortComparatorFactory } from './interfaces';
import { NoDataPlaceholderRow } from './components/NoDataPlaceholderRow';
import { SortableCell, SortOrder } from './components/SortableCell';
import { LoadingOverlayRow } from './components/LoadingOverlayRow';
import { EmbeddedTableTheme } from './themes/EmbeddedTableTheme';
import { DataErrorPlaceholder } from '../DataErrorPlaceholder';
import { ListRowSpacer } from './components/ListRowSpacer';
import { listDefaultSort } from './listDefaultSort';
import styles from './List.module.css';

export interface IListProps<TData extends { id: string }, TColumn, TProps extends object> {
    data: TData[] | undefined;
    columns: TColumn[];
    rowComponent: React.FC<IListRow<TData, TProps>>;
    rowComponentProps?: TProps;
    noDataPlaceholder?: React.ReactNode;
    RowSpacerProps?: { height?: string | null };
    footer?: React.ReactNode;
    highlightText?: string;
    defaultSortBy?: PrimitiveTypeKeys<TData>;
    defaultSortOrder?: SortOrder;
    sortComparatorFactory?: IListSortComparatorFactory<TData>;
    className?: string;
    error?: ApolloError;
    onRetry?: () => void;
    loading?: boolean;
    stickyHeader?: boolean;
    embedded?: boolean;
    size?: number;
    maxHeight?: string;
    listSizes?: number[];
    pagination?: boolean;
    emptyListPlaceholder?: string;
    hideHeader?: boolean;
    testId?: string;
    emptyStatePlaceholderProps?: IEmptyStatePlaceholderProps;
}

const INITIAL_SORT_ORDER: SortOrder = 'asc';
const DEFAULT_LIST_SIZE = 10;
const DEFAULT_LIST_SIZES = [5, 10, 20, 50, 100];
const LOADING_MESSAGE = 'Please wait';

const toggleOrder = (order: SortOrder) => (order === 'asc' ? 'desc' : 'asc');

export function List<TData extends { id: string }, TColumn extends IListColumn<TData>, TProps extends object>({
    data = [],
    columns = [],
    rowComponent: Row,
    rowComponentProps = {} as TProps,
    noDataPlaceholder,
    RowSpacerProps,
    footer,
    highlightText,
    defaultSortBy,
    defaultSortOrder = INITIAL_SORT_ORDER,
    sortComparatorFactory = listDefaultSort,
    className,
    loading,
    error,
    onRetry,
    stickyHeader,
    embedded,
    maxHeight,
    pagination,
    size = DEFAULT_LIST_SIZE,
    listSizes = DEFAULT_LIST_SIZES,
    emptyListPlaceholder,
    hideHeader,
    testId,
    emptyStatePlaceholderProps,
}: IListProps<TData, TColumn, TProps>) {
    type ColumnIds = PrimitiveTypeKeys<TData>;
    const columnTypeMap = useMemo(() => {
        return columns.reduce((acc, column) => {
            acc[column.id] = column.type || 'text';
            return acc;
        }, {} as Record<PrimitiveTypeKeys<TData>, IListColumnType>);
    }, [columns]);

    const [sortBy, setSortBy] = useState<ColumnIds | undefined>(defaultSortBy);
    const [rowsPerPage, setRowsPerPage] = useState<number>(size);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [sortOrder, setSortOrder] = useState<SortOrder>(defaultSortOrder);
    const rowData = sortBy ? sortComparatorFactory(sortBy, sortOrder, columnTypeMap)(data) : data;
    const tableRef = useRef(null);
    const showEmptyStatePlaceholder = !loading && (data?.length ? data.length <= 0 : true);

    if (!error && showEmptyStatePlaceholder) {
        return (
            <EmptyStatePlaceholder
                elevation={0}
                {...emptyStatePlaceholderProps}
                data-testid={`${testId}:emptyState`}
                dark={embedded}
            />
        );
    }

    const getPlaceholder = () => {
        if (loading) {
            return LOADING_MESSAGE;
        } else if (emptyListPlaceholder) {
            return emptyListPlaceholder;
        } else {
            return highlightText ? `No elements matching "${highlightText}" found` : void 0;
        }
    };

    const getSortHandler = (id: ColumnIds) => () => {
        if (sortBy !== id) {
            setSortBy(id);
            setSortOrder(defaultSortOrder);
        } else {
            setSortOrder(toggleOrder(sortOrder));
            if (sortOrder === 'desc') {
                setSortBy(void 0);
            }
        }
    };

    const getDataSlice = () => {
        if (pagination && rowsPerPage > 0) {
            return rowData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
        }
        return rowData;
    };

    const Theme = embedded ? EmbeddedTableTheme : React.Fragment;
    const rowDataSlice = getDataSlice();

    return (
        <Theme>
            <TableContainer
                className={cl(styles.wrap, className, loading && styles.wrapMinHeight)}
                sx={{ maxHeight }}
                data-testid={testId}
            >
                <Table ref={tableRef} className={'position-relative'} stickyHeader={stickyHeader}>
                    <TableHead>
                        {!hideHeader && (
                            <TableRow>
                                {columns.map(({ id, label = '', align, sort, shrink, nowrap, headerClassName }) => {
                                    if (sort === false || !label) {
                                        return (
                                            <TableCell
                                                key={String(id)}
                                                align={align}
                                                className={cl(
                                                    shrink && styles.shrinkWidth,
                                                    nowrap && 'white-space-nowrap',
                                                    headerClassName
                                                )}
                                            >
                                                {label}
                                            </TableCell>
                                        );
                                    }

                                    return (
                                        <SortableCell
                                            key={String(id)}
                                            order={sortOrder}
                                            align={align}
                                            className={cl(
                                                shrink && styles.shrinkWidth,
                                                nowrap && 'white-space-nowrap',
                                                headerClassName
                                            )}
                                            isActive={sortBy === id}
                                            onSort={getSortHandler(id)}
                                        >
                                            {label}
                                        </SortableCell>
                                    );
                                })}
                            </TableRow>
                        )}
                    </TableHead>
                    <TableBody>
                        {rowDataSlice.map((item, index) => {
                            const rowIndex = currentPage * 10 + index;
                            const isLast = rowIndex === rowDataSlice.length - 1;

                            return (
                                <React.Fragment key={item.id}>
                                    <Row
                                        data={item}
                                        highlightText={highlightText}
                                        props={rowComponentProps}
                                        columns={columns}
                                        index={rowIndex}
                                        isLast={rowIndex === rowDataSlice.length - 1}
                                        isFirst={rowIndex === 0}
                                    />
                                    {!isLast && <ListRowSpacer colspan={columns.length} {...RowSpacerProps} />}
                                </React.Fragment>
                            );
                        })}
                        {rowData.length === 0 &&
                            (noDataPlaceholder || (
                                <NoDataPlaceholderRow
                                    className={styles.noElementsMessage}
                                    colspan={columns.length}
                                    text={getPlaceholder()}
                                >
                                    {error && <DataErrorPlaceholder onRetry={onRetry} error={error} />}
                                </NoDataPlaceholderRow>
                            ))}
                        {loading && <LoadingOverlayRow tableRef={tableRef} />}
                    </TableBody>
                    {footer}
                </Table>
                {pagination && rowData?.length >= Math.min(...listSizes) && (
                    <TablePagination
                        component="div"
                        count={rowData.length}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={listSizes}
                        page={currentPage}
                        onPageChange={(e, page) => setCurrentPage(page)}
                        onRowsPerPageChange={(e) =>
                            setRowsPerPage((e.target.value as unknown as number) || DEFAULT_LIST_SIZE)
                        }
                    />
                )}
            </TableContainer>
        </Theme>
    );
}
