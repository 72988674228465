import React from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { Backdrop } from '@mui/material';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import { Launch, ReportProblemOutlined } from '@mui/icons-material';
import { APP_ERROR_SELECTOR } from '../../App.selectors';
import { ERROR_TRACE_UI_URL_FACTORY } from '../../../lib/utils/helpers';
import styles from './ErrorScreen.module.css';

const GENERAL_ERROR_MESSAGE = 'There was an issue accessing the site. Please contact the administrator';

interface IErrorScreenProps {
    forceShow?: true;
}

export const ErrorScreen: React.FC<IErrorScreenProps> = ({ forceShow }) => {
    const error = useSelector(APP_ERROR_SELECTOR);
    const isError = !!error.status || !!forceShow;

    if (!isError) {
        return null;
    }

    return (
        <Backdrop
            open
            sx={{
                zIndex: (theme) => theme.zIndex.modal + 1,
                background: (theme) => alpha(theme.palette.background.default, 0.9),
            }}
        >
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <ReportProblemOutlined className={styles.icon} />
                <p className={'text-align-center'}>{GENERAL_ERROR_MESSAGE}</p>
                {error?.traceId && (
                    <Box marginBottom={'8px'} display={'flex'}>
                        <span className={styles.errorLabel}>Error ID:</span>
                        <a
                            href={ERROR_TRACE_UI_URL_FACTORY(error.traceId)}
                            className={'flex flex-nowrap flex-align-center'}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            <Box marginRight={'2px'}>{error.traceId}</Box>
                            <Launch fontSize={'inherit'} />
                        </a>
                    </Box>
                )}
                <Button color="secondary" variant="outlined" onClick={() => window.location.reload()}>
                    Refresh the page
                </Button>
            </Box>
        </Backdrop>
    );
};
