import React from 'react';
import { DivTableTheme } from '../../../../../../lib/dataDisplay/List/themes/DivTableTheme';
import { RoleListRowFragment } from '../../../../../../interfaces/model';
import { List } from '../../../../../../lib/dataDisplay/List/List';
import { IRoleListItem, IRoleListRowConfig, RoleListRow } from './RoleListRow';
import { IListColumn } from '../../../../../../lib/dataDisplay/List';
import { NOOP } from '../../../../../../lib/utils/helpers';

export interface IRoleListProps {
    roles: RoleListRowFragment[];
    reloadData?: () => void;
    columns?: IListColumn<IRoleListItem>[];
    config?: IRoleListRowConfig;
    testId?: string;
}

const DEFAULT_COLUMNS: IListColumn<IRoleListItem>[] = [
    { id: 'name', label: 'User' },
    { id: 'jobTitle', label: 'Job Title' },
    { id: 'email', label: 'Email' },
    { id: 'lastLoggedIn', label: 'Last Login' },
    { id: 'deactivated', label: 'Status' },
    { id: 'actions', label: 'Actions', sort: false, shrink: true },
];

const prepareData = (roles: RoleListRowFragment[]): IRoleListItem[] => {
    return roles.map((role) => {
        return {
            ...role,
            name: role.name || '',
        };
    });
};

export const RoleList: React.FC<IRoleListProps> = ({
    roles,
    columns = DEFAULT_COLUMNS,
    reloadData = NOOP,
    config,
    testId,
}) => {
    return (
        <DivTableTheme>
            <List
                testId={testId}
                columns={columns}
                data={prepareData(roles)}
                rowComponent={RoleListRow}
                rowComponentProps={{ reloadData, config }}
                defaultSortBy={'name'}
                pagination
                emptyStatePlaceholderProps={{
                    title: 'No roles added',
                }}
            />
        </DivTableTheme>
    );
};
