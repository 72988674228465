import React from 'react';
import { TableCell } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useDocumentsViewDialog } from '../../../../components/Documents/components/DocumentsViewDialog';
import { useDocumentsUrlState } from '../../../../components/Documents/useDocumentsUrlState';
import { ListIconButton } from 'lib/dataDisplay/List/components/ListIconButton';
import styles from './CounterpartyDocsButtonListCell.module.css';

interface ILenderDocsButtonListCellProps {
    width?: string;
    parentRef: UUID;
}

export const CounterpartyDocsButtonListCell: React.FC<ILenderDocsButtonListCellProps> = ({ width, parentRef }) => {
    const [urlState, setUrlState] = useDocumentsUrlState();
    const [documentsViewDialog] = useDocumentsViewDialog();

    return (
        <TableCell width={width}>
            <ListIconButton
                color={'warning'}
                className={styles.button}
                icon={DescriptionOutlinedIcon}
                data-testid={'CounterpartiesList:docsLink'}
                onClick={() => {
                    documentsViewDialog.open({
                        parentRef,
                        onClose() {
                            setUrlState(
                                {
                                    ...urlState,
                                    upload: void 0,
                                    category: void 0,
                                    docType: void 0,
                                    docSubcategory: void 0,
                                    year: void 0,
                                    deleted: void 0,
                                    id: void 0,
                                },
                                { replace: true }
                            );
                        },
                    });
                }}
            />
        </TableCell>
    );
};
