import cl from 'classnames';
import { FormikContext } from 'formik';
import Stack from '@mui/material/Stack';
import React, { useContext } from 'react';
import { Button, CircularProgress, FormHelperText } from '@mui/material';
import Dropzone, { DropzoneProps, ErrorCode, FileRejection } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useAppSnackbar } from '../../../App/components/AppNotification';
import styles from './FileDropArea.module.css';
import { useAppConfig } from '../../../App/hooks/useAppConfig';
import { ValidationMessage } from '../../forms/ValidationMessage';

export interface IFileDropAreaProps extends DropzoneProps {
    name?: string;
    value?: File[];
    onValueChange?: (value: File[]) => void;
    validationMessage?: boolean;
    className?: string;
    loading?: boolean;
}

const DEFAULT_MAX_API_FILE_SIZE = 20 * 1000 * 1000; // 20M

const formatBytes = Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
});

const getMessageByCode = (error: { code: string; message: string }, props: IFileDropAreaProps) => {
    if (error.code === ErrorCode.FileTooLarge) {
        return `File size exceeds ${formatBytes.format(props.maxSize || DEFAULT_MAX_API_FILE_SIZE)}`;
    }
    return error.message;
};

const getErrorMessage = (fileRejections: FileRejection[], props: IFileDropAreaProps) => (
    <div>
        {fileRejections.map(({ file, errors }) => (
            <div key={file.name + file.size}>
                <span className={styles.errorMessageTitle}>Failed to select {file.name}:</span>
                {errors.length === 1 ? (
                    `${getMessageByCode(errors[0], props)}`
                ) : (
                    <ul className={styles.errorMessageList}>
                        {errors.map((error) => {
                            return <li key={error.code}>{getMessageByCode(error, props)}</li>;
                        })}
                    </ul>
                )}
            </div>
        ))}
    </div>
);

export const FileDropArea: React.FC<IFileDropAreaProps> = ({
    name,
    className,
    value: valueProp,
    validationMessage = true,
    onValueChange,
    loading,
    disabled,
    ...props
}) => {
    const snackBar = useAppSnackbar();
    const { uploadFileExtensions } = useAppConfig();
    const formik = useContext(FormikContext);
    const value = valueProp || (formik?.values[name!] as File[]) || [];
    const onChange = onValueChange || ((newValue: File[]) => name && formik?.setFieldValue(name, newValue));
    const maxSize = props.maxSize || DEFAULT_MAX_API_FILE_SIZE;

    return (
        <div className={className || ''}>
            <Dropzone
                onDrop={(acceptedFiles) => {
                    const getFileName = (file: File) => file.name + file.size;
                    const existingFilesNames = value.map(getFileName);
                    onChange([
                        ...value,
                        ...acceptedFiles.filter((file) => !existingFilesNames.includes(getFileName(file))),
                    ]);
                }}
                disabled={loading || disabled}
                onDropRejected={(fileRejections) => {
                    snackBar.show({
                        message: getErrorMessage(fileRejections, props),
                        autoHideDuration: 10000,
                        severity: 'error',
                    });
                }}
                {...props}
                accept={props.accept || uploadFileExtensions.map((ext) => `.${ext}`)}
                maxSize={maxSize}
            >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <Stack
                            {...getRootProps()}
                            className={cl(styles.area, styles.areaSmall)}
                            direction={'row'}
                            spacing={2}
                        >
                            <CloudUploadOutlinedIcon className={styles.icon} color={'secondary'} />
                            <input {...getInputProps()} />
                            <Stack spacing={0.5} direction={'row'} className={styles.areaTextWrap}>
                                <p>Drag and Drop {!props.multiple || props.maxFiles === 1 ? 'file' : 'files'}</p>
                                <p>or</p>
                            </Stack>
                            <Button color={'primary'} variant={'outlined'} size={'small'} className={styles.button}>
                                Browse
                            </Button>
                            {loading && <CircularProgress color={'primary'} size={32} className={styles.spinner} />}
                        </Stack>
                        <FormHelperText>
                            Maximum file size: {formatBytes.format(maxSize)}
                            {props.multiple ? ' each' : ''}
                        </FormHelperText>
                    </section>
                )}
            </Dropzone>
            {name && validationMessage && <ValidationMessage field={name} />}
        </div>
    );
};
