import React from 'react';
import { Chip } from '@mui/material';
import styles from '../pages/Entities/ViewEntity/ViewEntity.module.css';
import { SupervisorAccount } from '@mui/icons-material';
import cl from 'classnames';

interface IAgentManagementLabelProps {
    compact?: boolean;
}

export const AgentManagementLabel: React.FC<IAgentManagementLabelProps> = ({ compact }) => {
    return (
        <Chip
            className={cl(styles.managedChip, compact && styles.compact)}
            color={'primary'}
            label={'Managed by me'}
            icon={<SupervisorAccount />}
        />
    );
};
