import React, { useRef } from 'react';
import isString from 'lodash/isString';
import { useDispatch } from 'react-redux';
import { Box, Slide } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router';
import {
    findErrorOfType,
    ILoginForm,
    LOGIN_NOT_FOUND,
    LoginFormMode,
    loginFormValidationSchema as validationSchema,
    PASSWORD_INCORRECT,
    TOTP_CODE_INCORRECT,
} from '../Login.const';
import { TwoFactorCodeLoginScreen } from './screens/TwoFactorCodeLoginScreen/TwoFactorCodeLoginScreen';
import { TermsAndConditionsDialog, useTermsAndConditionsDialog } from '../TermsAndConditionsDialog';
import { TOTPDialog, useTOTPDialog } from '../../../../pages/Roles/EditRole/TOTPSwitch/TOTPDialog';
import { CredentialsLoginScreen } from './screens/CredentialsLoginScreen';
import { ErrorCode, LoginQueryVariables } from 'interfaces/model';
import { useAppSnackbar } from '../../../AppNotification';
import { DASHBOARD_ROUTE } from '../../../../App.routes';
import { userLogin } from '../../../../App.actions';
import { useAuthLazyQuery } from '../../Auth.query';
import { LoginResultFragment, useLoginLazyQuery } from '../Login.query';
import styles from './LoginForm.module.css';

const getInitialValues = (): ILoginForm => {
    return { login: '', password: '', mode: LoginFormMode.LOGIN_PASSWORD, totpCode: void 0 };
};

interface ILoginFormScreen {
    mode: LoginFormMode;
    direction?: 'right' | 'left';
    screen: React.ReactElement;
}

export const LoginForm: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const snackBar = useAppSnackbar();
    const containerRef = useRef<HTMLDivElement>();
    const [totpSetupDialog] = useTOTPDialog();
    const [termsAndConditionsDialog] = useTermsAndConditionsDialog();

    const handleLogin = (data: LoginResultFragment) => {
        const userNeedsToSetupTOTP = data.user.totpRequired && !data.user.role.totpActivated;
        if (userNeedsToSetupTOTP) {
            totpSetupDialog.open();
        } else {
            dispatch(userLogin(data));
            history.push(location.state?.from || DASHBOARD_ROUTE);
        }
    };

    const loginUser = (data: LoginResultFragment | undefined) => {
        const userNeedsToSetupTOTP = data?.user.totpRequired && !data?.user.role.totpActivated;
        if (data) {
            if (!data?.authRole?.termsAcceptedAt && !userNeedsToSetupTOTP) {
                termsAndConditionsDialog.open({
                    mode: 'submit',
                    onSubmitSuccess: () => handleLogin(data),
                });
            } else {
                handleLogin(data);
            }
        }
    };

    const [auth] = useAuthLazyQuery({ fetchPolicy: 'no-cache' });
    const [login, loginMutation] = useLoginLazyQuery({
        fetchPolicy: 'no-cache',
        onError(e) {
            formik.setSubmitting(false);
            if (findErrorOfType(e, ErrorCode.LOGIN_NOT_FOUND)) {
                formik.setFieldError('login', LOGIN_NOT_FOUND);
            } else if (findErrorOfType(e, ErrorCode.PASSWORD_INCORRECT)) {
                formik.setFieldError('password', PASSWORD_INCORRECT);
            } else if (findErrorOfType(e, ErrorCode.TOTP_REQUIRED)) {
                formik.setFieldValue('mode', LoginFormMode.TWO_FACTOR_CODE);
            } else if (findErrorOfType(e, ErrorCode.TOTP_INCORRECT)) {
                formik.setFieldError('totpCode', TOTP_CODE_INCORRECT);
            } else {
                const message = `Failed to login: ${e?.message || 'Unhandled error'}`;
                snackBar.showError({ message }, e);
            }
        },
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: getInitialValues(),
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            const variables: LoginQueryVariables = {
                login: values.login!.trim(),
                password: values.password!.trim(),
            };

            if (isString(values.totpCode)) {
                variables.totpCode = values.totpCode?.trim();
            }

            try {
                const response = await login({ variables });
                loginUser(response.data?.login);
            } catch (e) {
                setSubmitting(false);
                if (findErrorOfType(e, ErrorCode.TOTP_REQUIRED)) {
                    formik.setFieldValue('mode', LoginFormMode.TWO_FACTOR_CODE);
                    formik.setFieldTouched('totpCode', false);
                }
            }
        },
    });

    const mode = formik.values.mode;
    const setMode = (value: LoginFormMode) => formik.setFieldValue('mode', value);

    const screens: ILoginFormScreen[] = [
        {
            direction: 'right',
            mode: LoginFormMode.LOGIN_PASSWORD,
            screen: <CredentialsLoginScreen spin={loginMutation.loading} />,
        },
        {
            mode: LoginFormMode.TWO_FACTOR_CODE,
            screen: (
                <TwoFactorCodeLoginScreen
                    spin={loginMutation.loading}
                    onBackClick={() => {
                        setMode(LoginFormMode.LOGIN_PASSWORD);
                        formik.setFieldValue('totpCode', void 0);
                    }}
                />
            ),
        },
    ];

    return (
        <Box ref={containerRef} className={styles.formWrap}>
            <FormikProvider value={formik}>
                {screens.map((item, index) => (
                    <Slide
                        key={item.mode}
                        in={mode === item.mode}
                        direction={item.direction || 'left'}
                        timeout={{ appear: 0, enter: 200, exit: 200 }}
                        container={containerRef.current}
                        appear={index !== 0}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className={styles.screenWrap}>{item.screen}</div>
                    </Slide>
                ))}
            </FormikProvider>
            <TOTPDialog
                onConfirm={async () => {
                    try {
                        const { data } = await auth();
                        loginUser(data!.auth);
                    } catch (e) {
                        snackBar.showError({ message: 'Failed to login. Try again or contact administrator' }, e);
                    }
                }}
            />
            <TermsAndConditionsDialog />
        </Box>
    );
};
