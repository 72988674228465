import React from 'react';
import { IListIconButtonProps, ListIconButton } from '../../../../lib/dataDisplay/List/components/ListIconButton';
import { ITaxIdsListItem } from './TaxIdsListRow';
import { useAppSnackbar } from '../../../components/AppNotification';
import { useConfirmDialog } from '../../../../lib/modals/ConfirmDialog';
import { Delete } from '@mui/icons-material';

interface ITaxIdDeleteIconButtonProps extends Omit<IListIconButtonProps, 'icon'> {
    taxId: ITaxIdsListItem;
    onDelete: (taxId: UUID) => void;
}

export const TaxIdDeleteIconButton: React.FC<ITaxIdDeleteIconButtonProps> = ({ taxId, onDelete, ...props }) => {
    const snackbar = useAppSnackbar();
    const [{ open }] = useConfirmDialog();

    const openDialog = () => {
        open({
            body: 'You are about to delete a tax ID. Are you sure?',
            title: `Delete tax ID - "${taxId.country}"`,
            onConfirm: async () => {
                try {
                    await onDelete(taxId.id);
                    snackbar.show({
                        message: 'Tax ID deleted successfully',
                    });
                } catch (e) {
                    snackbar.showError({ message: 'Failed to delete tax ID' }, e);
                }
            },
        });
    };

    return <ListIconButton onClick={openDialog} icon={Delete} {...props} />;
};
