import * as yup from 'yup';
import { CounterpartiesListMode } from '../Counterparties.const';
import { useUrlState } from 'lib/hooks/router/useUrlState';
import { CounterpartyStatus } from 'interfaces/types';
import { yupEnum } from 'lib/forms';

export const useCounterpartyKYCUrlState = () => {
    return useUrlState({
        initialState: {
            mode: CounterpartiesListMode.DEFAULT,
            targets: [],
        },
        validationSchema: {
            search: yup.string(),
            topCoRef: yup.string(),
            status: yup.array().of(yupEnum(CounterpartyStatus)),
            mode: yupEnum(CounterpartiesListMode),
            targets: yup.array().of(yup.string()),
            tradedep: yup.boolean(),
        },
    });
};

export type ICounterpartyKYCUrlState = ReturnType<typeof useCounterpartyKYCUrlState>[0];
