import React, { forwardRef } from 'react';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';

export interface ILGCLogoProps extends BoxProps {
    alt?: string;
}

export const LGCLogo: React.FC<ILGCLogoProps> = forwardRef((props, ref) => {
    return (
        <Box
            alt={'LedgerComm logo'}
            ref={ref}
            {...props}
            sx={{ ...props.sx, cursor: 'pointer' }}
            component={'img'}
            src={'/img/ledgercomm-logo.svg '}
        />
    );
});
