import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { PeopleList } from './PeopleList';
import { RoleEditDialog, useRoleEditDialog } from '../../../../../Roles/RoleEditDialog';
import { FieldsetCollapsible } from '../../../../../../../lib/forms/FieldsetCollapsible';
import { ClientOnboardingFragment, usePeopleSetupQuery } from '../../../../../../../interfaces/model';
import { ClientOnboardingContext } from '../../../ClientOnboarding.const';

interface IPeopleSetupFormProps {
    query: ReturnType<typeof usePeopleSetupQuery>;
    topCo: Maybe<ClientOnboardingFragment> | undefined;
    noPreview?: boolean;
}

export const PeopleSetupForm: React.FC<IPeopleSetupFormProps> = ({ query, topCo, noPreview }) => {
    const [roleEditDialog] = useRoleEditDialog();
    const { updateData } = useContext(ClientOnboardingContext);
    const handleAdd = () => roleEditDialog.open({ topCoRef: topCo?.id, entityType: topCo?.entityType });
    const hasRoles = (query.data?.roles.total || 0) > 0;

    return (
        <Stack spacing={4}>
            <FieldsetCollapsible
                title={'User(s)'}
                description={'Please add at least one user with credentials and assign their roles'}
                complete={hasRoles}
                defaultExpand
            >
                <Stack spacing={1}>
                    <Box>
                        <Button
                            data-testid={'NewCompanyView:addNewUserButton'}
                            size={'large'}
                            variant={'contained'}
                            sx={{ minWidth: '120px' }}
                            onClick={handleAdd}
                        >
                            Add user
                        </Button>
                    </Box>
                    {hasRoles && <PeopleList query={query} noPreview={noPreview} />}
                </Stack>
            </FieldsetCollapsible>
            <div onClick={(e) => e.stopPropagation()}>
                <RoleEditDialog
                    showCredentialsInputs={false}
                    onSaveSuccess={() => {
                        query.refetch();
                        updateData();
                    }}
                />
            </div>
        </Stack>
    );
};
