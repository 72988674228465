import { IConfirmDialogState, useConfirmDialog } from '../modals/ConfirmDialog';

export const useDirtyFormikConfirmation = (
    formik: { isSubmitting: boolean; resetForm: () => void; touched: object },
    props: IConfirmDialogState
) => {
    const [confirmDialog] = useConfirmDialog();

    return () => {
        if (formik.isSubmitting) {
            return;
        }

        const onConfirm = () => {
            formik.resetForm();
            if (props.onConfirm) {
                props.onConfirm();
            }
        };

        if (Object.keys(formik.touched).length > 0) {
            confirmDialog.open({ ...props, onConfirm: async () => onConfirm() });
        } else {
            onConfirm();
        }
    };
};
