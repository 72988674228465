import { useLocation } from 'react-router';
import { useMemo } from 'react';

/**
 * Custom hook which parses query params from location
 * @returns {URLSearchParams}
 */
export function useURLSearchParams(): URLSearchParams {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}
