import React from 'react';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import { Collapse, FormHelperText, FormLabel, TextField } from '@mui/material';
import {
    Capabilities,
    EntityType,
    useDTTPSuggestLazyQuery,
    useGIINSuggestLazyQuery,
    useLEISuggestLazyQuery,
} from '../../../../../interfaces/model';
import { CountrySelect } from '../../../../../lib/inputs/CountrySelect/CountrySelect';
import { NifTaxNumberInput } from '../../../../../lib/inputs/NifTaxNumberInput';
import { entityTypeOptions } from '../CreateEntityWizardDialog.const';
import { EntitiesSelect } from '../../../../common/EntitiesSelect';
import { useUserDetails } from '../../../../hooks/useUserDetails';
import { TextInput } from '../../../../../lib/inputs/TextInput';
import {
    isBorrowerEntity,
    isFundAdminEntity,
    isLenderEntity,
    isSponsorEntity,
    isTopCoEntityType,
    PartialEntity,
} from '../../Entities.helpers';
import { AddressFieldsGroup } from '../../../../common/AddressFieldsGroup';
import { EditEntityDirectors } from '../steps/EntityKeyInformationStep/EditEntityDirectors';
import { IEntityEditForm } from '../useEntityEditFormik/useEntityEditFormik.const';
import { RolesSelect } from '../../../../common/RolesSelect';
import { ReadonlyValue } from '../../../../../lib/dataDisplay/ReadonlyValue';
import { DomainsInput } from '../../../../../lib/inputs/DomainsInput/DomainsInput';
import { isTopCoModel } from '../../../../helpers/isTopCoModel';
import { FormCheckbox } from '../../../../../lib/forms/components/FormCheckbox';
import { Fieldset } from '../../../../../lib/forms/Fieldset';
import { ENTITY_TYPE_LABELS } from '../../../../App.const';
import { EntityNameSuggestInput } from './EntitySuggestInput';
import { TaxIdSuggestInput } from './TaxIdSuggestInput';
import { GICSSelect } from './GICSSelect';
import styles from './EditEntityForm.module.css';
import { Dropdown } from '../../../../../lib/inputs/Dropdown';

export interface IEditEntityFormProps {
    disabled?: boolean;
    config?: {
        authorisedSignatorySelect?: boolean;
    };
}

const getViewConfig = (entity: PartialEntity, isAdmin: boolean, isNewEntity: boolean, isTopCo: boolean) => {
    const { entityType } = entity;
    const isLender = isLenderEntity(entity);
    const isSponsor = isSponsorEntity(entity);
    const isBorrower = isBorrowerEntity(entity);
    const isFundAdmin = isFundAdminEntity(entity);
    const isBank = entityType === EntityType.LENDER_INVESTMENT_BANK;
    const isUBO = entityType === EntityType.UBO;
    const showAgents = isBorrowerEntity(entity) || (isLender && !isNewEntity);

    return {
        agents: showAgents,
        sponsors: !isTopCo && isBorrower,
        headquarters: isTopCo,
        emailDomains: isTopCo,
        jurisdiction: !isTopCo,
        incorporationNumber: !isTopCo,
        lei: !isTopCo && !isSponsor && !isFundAdmin,
        dttp: !isTopCo && isLender,
        giin: !isTopCo && isLender,
        nif: !isTopCo && isLender,
        address: !isTopCo,
        entityType: !isTopCo && isLender && !isUBO,
        secRegistered: !isTopCo && !isSponsor && !isFundAdmin,
        fcaRegistered: !isTopCo && !isSponsor && !isFundAdmin,
        otot: !isTopCo && isLender,
        settlementLeadTime: !isTopCo && isBank,
        authorizedSignatory: !isTopCo && isBank,
        gics: !isTopCo && (isLender || isBorrower),
        directors: !isTopCo,
    };
};

const BOOLEAN_OPTIONS = [
    { id: 'true', name: 'Yes', value: true },
    { id: 'false', name: 'No', value: false },
];

export const EditEntityForm: React.FC<IEditEntityFormProps> = ({ config: formConfig, disabled }) => {
    const { isAdmin } = useUserDetails();
    const formik = useFormikContext<IEntityEditForm>();
    const { isSubmitting, values, setFieldValue } = formik;
    const { authorisedSignatorySelect = true } = formConfig || {};

    const isNewEntity = !values.id;
    const isNewTopCo = isNewEntity && isTopCoEntityType(values.entityType);
    const isDisabled = Boolean(isSubmitting || disabled);
    const isTopCo = isNewTopCo || isTopCoModel(values);

    const config = getViewConfig(values, isAdmin, isNewEntity, !!isTopCo);
    const showBusinessAddress = !!formik.values.showBusinessAddress;

    return (
        <div>
            <Fieldset title={'General details'}>
                <Grid spacing={2} container>
                    <Grid xs={6} item>
                        <FormLabel required htmlFor={'name'}>
                            Registered entity name
                        </FormLabel>
                        {isTopCo ? (
                            <TextInput name={'name'} placeholder={'Enter name'} fullWidth autoFocus />
                        ) : (
                            <EntityNameSuggestInput disabled={isDisabled} />
                        )}
                    </Grid>
                    {config.headquarters && (
                        <Grid xs={6} item>
                            <FormLabel>Headquarters</FormLabel>
                            <CountrySelect name={'headquarters'} placeholder={'Enter country'} disabled={isDisabled} />
                        </Grid>
                    )}
                    {config.jurisdiction && (
                        <Grid xs={6} item>
                            <FormLabel>Jurisdiction</FormLabel>
                            <CountrySelect name={'jurisdiction'} placeholder={'Enter country'} disabled={isDisabled} />
                        </Grid>
                    )}
                    {config.incorporationNumber && (
                        <Grid xs={6} item>
                            <FormLabel>Incorporation Number</FormLabel>
                            <TextInput
                                name={'incorporationNumber'}
                                placeholder={'Incorporation Number'}
                                disabled={isDisabled}
                                fullWidth
                            />
                        </Grid>
                    )}
                    {config.lei && (
                        <Grid xs={6} item>
                            <FormLabel>LEI</FormLabel>
                            <TaxIdSuggestInput
                                query={useLEISuggestLazyQuery}
                                getOptions={(data) => data?.lei}
                                name={'lei'}
                                placeholder={'Enter LEI'}
                                disabled={isDisabled}
                            />
                        </Grid>
                    )}
                    {config.emailDomains && (
                        <Grid xs={6} item>
                            <FormLabel htmlFor={'emailDomains'}>E-Mail domains allowed</FormLabel>
                            <DomainsInput
                                name={'emailDomains'}
                                value={formik.values.emailDomains}
                                onChange={(domains) => formik.setFieldValue('emailDomains', domains)}
                            />
                        </Grid>
                    )}
                    {config.entityType && (
                        <Grid xs={6} item>
                            <FormLabel>Type of lender</FormLabel>
                            {isNewEntity ? (
                                <Dropdown
                                    name={'entityType'}
                                    options={entityTypeOptions}
                                    placeholder={'Please select'}
                                    disabled={!isNewEntity || isSubmitting}
                                    fullWidth
                                />
                            ) : (
                                <ReadonlyValue value={ENTITY_TYPE_LABELS[values.entityType!]} />
                            )}
                        </Grid>
                    )}
                    {config.secRegistered && (
                        <Grid xs={6} item>
                            <FormLabel>SEC Registered</FormLabel>
                            <Dropdown
                                name={'secRegistered'}
                                placeholder={'Please select'}
                                options={BOOLEAN_OPTIONS}
                                fullWidth
                                disabled={isDisabled}
                            />
                        </Grid>
                    )}
                    {config.fcaRegistered && (
                        <Grid xs={6} item>
                            <FormLabel>FCA Registered</FormLabel>
                            <Dropdown
                                name={'fcaRegistered'}
                                placeholder={'Please select'}
                                options={BOOLEAN_OPTIONS}
                                fullWidth
                                disabled={isDisabled}
                            />
                        </Grid>
                    )}
                </Grid>
            </Fieldset>
            <Fieldset title={'Tax IDs'} hide={!config.dttp && !config.giin && !config.nif}>
                <Grid spacing={2} container>
                    {config.dttp && (
                        <Grid xs={6} item>
                            <FormLabel>DTTP (UK tax ID)</FormLabel>
                            <TaxIdSuggestInput
                                query={useDTTPSuggestLazyQuery}
                                getOptions={(data) => data?.dttp}
                                name={'dttp'}
                                id={'dttp'}
                                placeholder={'00/A/123456/DTTP'}
                                disabled={isDisabled}
                                transformValue={(value) => value.replace(/[^a-zA-Z0-9/]/g, '').toUpperCase()}
                            />
                        </Grid>
                    )}
                    {config.giin && (
                        <Grid xs={6} item>
                            <FormLabel>GIIN (US Tax ID)</FormLabel>
                            <TaxIdSuggestInput
                                query={useGIINSuggestLazyQuery}
                                getOptions={(data) => data?.giin}
                                name={'giin'}
                                id={'giin'}
                                placeholder={'0A0A0A.0A0A0.AA.000'}
                                disabled={isDisabled}
                                transformValue={(value) => value.replace(/[^a-zA-Z0-9.]/g, '').toUpperCase()}
                            />
                        </Grid>
                    )}
                    {config.nif && (
                        <Grid xs={6} item>
                            <FormLabel>NIF (Spanish Tax ID)</FormLabel>
                            <NifTaxNumberInput
                                id={'nif'}
                                value={values.nif!}
                                onChange={(value) => setFieldValue('nif', value)}
                                disabled={isDisabled}
                            />
                        </Grid>
                    )}
                </Grid>
            </Fieldset>
            <Fieldset title={'Related entities'} hide={!config.sponsors}>
                <Grid spacing={2} container>
                    {config.sponsors && (
                        <Grid xs={12} item>
                            <FormLabel>Sponsors</FormLabel>
                            <EntitiesSelect
                                name={'sponsorRefs'}
                                entityType={EntityType.SPONSOR}
                                includeWithoutViewAccess={true}
                                placeholder={'Select sponsors'}
                                disabled={isDisabled}
                                multiple
                            />
                        </Grid>
                    )}
                </Grid>
            </Fieldset>

            <Fieldset title={'Registered Address'} required hide={!config.address}>
                <Grid spacing={2} container>
                    <Grid xs={12} item>
                        <AddressFieldsGroup name={'address'} disabled={isDisabled} />
                    </Grid>
                    <Grid xs={12} item>
                        <FormCheckbox
                            name={'showBusinessAddress'}
                            label={'Business Address is different than Registered Address'}
                        />
                    </Grid>
                </Grid>
            </Fieldset>
            <Collapse in={showBusinessAddress}>
                <Fieldset title={'Business Address'} required hide={!config.address}>
                    <Grid container>
                        <Grid xs={12} item>
                            <AddressFieldsGroup name={'businessAddress'} disabled={isDisabled} />
                        </Grid>
                    </Grid>
                </Fieldset>
            </Collapse>

            <Fieldset
                title={'Additional information'}
                hide={
                    !(config.authorizedSignatory && authorisedSignatorySelect) &&
                    !config.settlementLeadTime &&
                    !config.otot
                }
            >
                <Grid spacing={2} container>
                    {config.otot && (
                        <Grid xs={12} item>
                            <FormLabel>OTOT</FormLabel>
                            <TextInput
                                name={'otot'}
                                placeholder={'Enter OTOT'}
                                disabled={isDisabled}
                                multiline
                                fullWidth
                            />
                            <FormHelperText className={styles.helperText}>
                                Please enter the Other Terms Of Trade (OTOT) you want to apply for all trades completed
                                with this {values.entityType ? ENTITY_TYPE_LABELS[values.entityType] : 'entity'}.
                                (Optional)
                            </FormHelperText>
                        </Grid>
                    )}
                    {config.settlementLeadTime && (
                        <Grid xs={12} item>
                            <FormLabel>Settlement Lead Time (Business Days)</FormLabel>
                            <TextField
                                fullWidth
                                placeholder={'Enter value'}
                                disabled={isDisabled}
                                value={values.settlementLeadTime || ''}
                                onChange={(e) => {
                                    const number = Number(e.target.value.replace(/\D/, ''));
                                    if (number >= 0 && number <= 10 && !isNaN(number)) {
                                        setFieldValue('settlementLeadTime', number);
                                    }
                                }}
                            />
                        </Grid>
                    )}

                    {config.authorizedSignatory && authorisedSignatorySelect && (
                        <Grid xs={12} item>
                            <FormLabel>Authorised Signatory</FormLabel>
                            <RolesSelect
                                id={'authorisedSignatory'}
                                value={values.authorizedSignatory}
                                multiple
                                topCoRef={values.parentRef!}
                                capability={Capabilities.ESIG}
                                disabled={isDisabled}
                                onChange={(value) => setFieldValue('authorizedSignatory', value)}
                            />
                        </Grid>
                    )}
                </Grid>
            </Fieldset>
            <Fieldset title={'Global Industry Classification Standard (GICS)'} hide={!config.gics}>
                <GICSSelect />
            </Fieldset>
            <Fieldset title={'Company Directors'} hide={!config.directors || !values.topCoRef}>
                <div className={'mb-16'}>
                    {values.topCoRef && (
                        <EditEntityDirectors
                            entityRef={values.id}
                            topCoRef={values.topCoRef}
                            entityType={values.entityType}
                            value={values.directors || []}
                            onChange={(directors) => formik.setFieldValue('directors', directors)}
                        />
                    )}
                </div>
            </Fieldset>
        </div>
    );
};
