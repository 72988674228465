import React from 'react';
import { useDelayedElementState } from './useDelayedElementState';
import cl from 'classnames';
import styles from './MenuGroup/MenuGroup.module.css';
import Menu from '@mui/material/Menu';

interface IHoverMenuProps {
    triggerElement: React.ReactNode;
    className?: string;
    children?: React.ReactNode;
}

export const HoverMenu: React.FC<IHoverMenuProps> = ({ triggerElement, className, children }) => {
    const [anchorEl, menu] = useDelayedElementState();

    if (!children) {
        return <>{triggerElement}</>;
    }

    return (
        <>
            <span className={cl(className)} onMouseOver={menu.scheduleOpen} onMouseLeave={menu.scheduleClose}>
                {triggerElement}
            </span>
            <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={menu.scheduleClose}
                PopoverClasses={{ paper: styles.menuGroupContainer, root: styles.menuGroup }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                MenuListProps={{ onMouseLeave: menu.scheduleClose, onMouseEnter: menu.clearScheduledClose }}
                onClick={menu.closeImmediately}
                disableEnforceFocus
                disableAutoFocus
                hideBackdrop
                keepMounted
            >
                {children}
            </Menu>
        </>
    );
};
