import React from 'react';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import { clientOnboardingProgressLabels } from './ClientOnboardingProgressItem.const';
import styles from './ClientOnboardingProgressItem.module.css';
import { ColoredArea } from '../../../../../../lib/cdk/ColoredArea';
import { ClientOnboardingStep } from '../../ClientOnboarding.const';

interface IClientOnboardingProgressItemProps {
    value: ClientOnboardingStep;
    complete: boolean;
}

export const ClientOnboardingProgressItem: React.FC<IClientOnboardingProgressItemProps> = ({ value, complete }) => {
    return (
        <Box display={'flex'} alignItems={'center'}>
            <span className={styles.label}>{clientOnboardingProgressLabels[value]}</span>
            <ColoredArea color={complete ? 'success' : 'secondary'} marginLeft={'auto'}>
                <CheckIcon fontSize={'small'} color={'inherit'} />
            </ColoredArea>
        </Box>
    );
};
