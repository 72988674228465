import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Switch, TableCell, TableRow } from '@mui/material';
import { ListCellItemWrapper } from '../../../../../lib/dataDisplay/List/components/ListCellItemWrapper';
import { ValueCell } from '../../../../../lib/dataDisplay/List/components/ListCells';
import { useCapability } from '../../../../hooks/useCapability';
import { Capabilities, RoleEntityRelation } from '../../../../../interfaces/model';
import { IListRow } from '../../../../../lib/dataDisplay/List';
import { hasDirectorRelation } from '../useRoleEditFormik/useRoleEditFormik.const';

export interface IRoleRelationsEditFormListRowItem extends RoleEntityRelation {
    id: string;
    index: number;
    name: string;
    actions?: never;
}

type IRoleRelationsEditFormListRowProps = IListRow<IRoleRelationsEditFormListRowItem>;

const CheckboxCell: React.FC<{ value: boolean; onChange: (value: boolean) => void; disabled: boolean }> = ({
    value,
    onChange,
    disabled,
}) => (
    <TableCell>
        <ListCellItemWrapper display={'flex'} alignItems={'center'}>
            <Checkbox
                disabled={disabled}
                size={'small'}
                checked={value}
                sx={{ marginLeft: '-8px' }}
                onChange={() => onChange(!value)}
            />
        </ListCellItemWrapper>
    </TableCell>
);

const RelationEnabledCell: React.FC<{ value: boolean; onChange: (value: boolean) => void; disabled: boolean }> = ({
    value,
    onChange,
    disabled,
}) => (
    <TableCell>
        <ListCellItemWrapper display={'flex'} alignItems={'center'}>
            <Switch
                size={'small'}
                color={'success'}
                checked={value}
                onChange={() => onChange(!value)}
                disabled={disabled}
            />
        </ListCellItemWrapper>
    </TableCell>
);

export const RoleRelationsEditListRow: React.FC<IRoleRelationsEditFormListRowProps> = ({ data, columns }) => {
    const formik = useContext(FormikContext);
    const canManageStructure = useCapability(Capabilities.MANAGE_STRUCTURE);
    const setFieldValue = (field: string) => (value: unknown) => {
        const unchangedValues = formik.values.entityRelations.filter(
            (item: IRoleRelationsEditFormListRowItem) => item.id !== data.id
        );
        const newEntityRelations = [...unchangedValues, { ...data, [field]: value }];
        formik.setFieldValue('entityRelations', newEntityRelations);
        return newEntityRelations;
    };

    const SwitchCell: React.FC<{ field: 'ubo' | 'director' | 'psc' }> = ({ field }) => (
        <CheckboxCell
            disabled={!canManageStructure}
            value={data[field]}
            onChange={(newValue) => {
                const newEntityRelations = setFieldValue(field)(newValue);
                if (field === 'director') {
                    if (newValue && !formik.values.isDirector) {
                        formik.setFieldValue('isDirector', true);
                    }
                    if (!newValue && hasDirectorRelation(newEntityRelations) && !formik.touched.isDirector) {
                        formik.setFieldValue('isDirector', false);
                    }
                }
            }}
        />
    );

    return (
        <TableRow>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'enabled' && (
                        <RelationEnabledCell
                            disabled={!canManageStructure}
                            value={data.enabled}
                            onChange={() => setFieldValue('enabled')(!data.enabled)}
                        />
                    )}
                    {id === 'name' && <ValueCell nowrap={false}>{data.name}</ValueCell>}
                    {id === 'director' && <SwitchCell field={'director'} />}
                    {id === 'ubo' && <SwitchCell field={'ubo'} />}
                    {id === 'psc' && <SwitchCell field={'psc'} />}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
