import React from 'react';
import Box from '@mui/material/Box';
import { Launch } from '@mui/icons-material';
import { BoxProps, Tooltip } from '@mui/material';

interface ITextEllipsisProps extends Omit<BoxProps, 'title'> {
    title?: React.ReactNode;
    showIcon?: boolean;
    nowrap?: boolean;
}

export const TextEllipsis: React.FC<ITextEllipsisProps> = ({
    children,
    title,
    maxWidth,
    nowrap,
    showIcon,
    ...props
}) => (
    <Tooltip title={title || children} arrow>
        <Box display={'flex'} alignItems={'center'}>
            <Box
                maxWidth={maxWidth || '124px'}
                sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: nowrap ? 'nowrap' : 'break-spaces',
                }}
                {...props}
            >
                {children}
            </Box>
            {showIcon && <Launch sx={{ cursor: 'pointer' }} fontSize={'small'} color={'secondary'} />}
        </Box>
    </Tooltip>
);
