import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDocumentsViewDialog } from '../components/DocumentsViewDialog';
import { IDocumentsListParent } from '../interfaces/IDocumentsListParent';
import { useDocumentsUrlState } from '../useDocumentsUrlState';

export const useDocumentViewDialogOpenEffect = (parent: IDocumentsListParent) => {
    const history = useHistory();
    const location = useLocation();
    const [urlState] = useDocumentsUrlState();
    const [documentsViewDialog, documentsViewDialogOpened] = useDocumentsViewDialog();

    const openDocumentsViewDialog = documentsViewDialog.open;
    useEffect(() => {
        if (
            parent.id &&
            (urlState.category || urlState.docType || urlState.id || urlState.deleted) &&
            !documentsViewDialogOpened
        ) {
            openDocumentsViewDialog({
                parentRef: parent.id,
                docType: urlState.docType,
                category: urlState.category,
                subcategory: urlState.docSubcategory,
                year: urlState.year,
                onClose: () => history.replace(location.pathname),
            });
        }
    });
};
