import React from 'react';
import cl from 'classnames';
import { TableRow, TableRowProps } from '@mui/material';
import styles from './ListRow.module.css';

interface IListRowProps extends TableRowProps {
    focused?: boolean;
    testId?: string;
}

export const ListRow: React.FC<IListRowProps> = ({ testId = 'ListRow', className, focused, children, ...props }) => {
    return (
        <TableRow data-testid={testId} className={cl(className, focused && styles.focusedState)} {...props}>
            {children}
        </TableRow>
    );
};
