import React from 'react';
import { Masonry } from '@mui/lab';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router';
import { LinkWithIcon } from 'lib/navigation/LinkWithIcon';
import { COUNTERPARTY_KYC_ROUTE_FACTORY, LENDER_VIEW_ROUTE_FACTORY } from '../../../../App.routes';
import { ListCellItemWrapper } from 'lib/dataDisplay/List/components/ListCellItemWrapper';
import { CountryFlagAndName } from 'lib/dataDisplay/CountryFlagAndName';
import { useCounterpartiesAccessCheck } from '../../../Counterparties/useCounterpartiesAccessCheck';
import { isLenderEntity } from '../../../Entities/Entities.helpers';
import { FieldRow } from 'lib/dataDisplay/FieldRow/FieldRow';
import { AddressDisplay } from 'lib/dataDisplay/AddressDisplay/AddressDisplay';
import { FieldRowContainer } from 'lib/dataDisplay/FieldRowContainer/FieldRowContainer';
import { joinQueryParams } from 'lib/utils/router/joinQueryParams';
import { AgentsKYCStatus } from '../../../Entities/ViewEntity/ViewEntityDetails/AgentsKYCStatus';
import { FundAuthorizedSignaturesView } from './FundAuthorizedSignaturesView';
import { EntityType, FundType, LoanFundFragment } from 'interfaces/model';
import { NoValuePlaceholder } from '../../../../../lib/cdk/NoValuePlaceholder';
import { dateFromISOToFormat } from '../../../../../lib/utils/date';

interface IViewFundDetailsProps {
    fund: LoanFundFragment | undefined;
    config?: {
        counterparties?: boolean;
    };
    className?: string;
}

const getPermissionedLenderFilterText = (parLoansOnly: boolean | undefined) => {
    if (parLoansOnly) {
        return 'PAR Loans';
    }
    return 'Non-PAR Loans';
};

const fundTypeLabels: Record<FundType, string> = {
    CLO: 'CLO (Collateralized Loan Obligation)',
    SMA: 'SMA (Separate Managed Account)',
    CO_MINGLED: 'Co-Mingled Fund',
};

const NullableDate: React.FC<{ value: MaybeUndefined<LocalDate> }> = ({ value }) => (
    <span>{value === null ? <NoValuePlaceholder text={'Not available'} /> : dateFromISOToFormat(value)}</span>
);

export const ViewFundDetails: React.FC<IViewFundDetailsProps> = ({ fund, config, className }) => {
    const { entity } = fund || {};
    const location = useLocation();
    const hasCounterpartiesAccess = useCounterpartiesAccessCheck();
    const showCounterpartyKYC =
        hasCounterpartiesAccess &&
        entity?.shortCode &&
        isLenderEntity(entity) &&
        config?.counterparties !== false &&
        entity.entityType !== EntityType.LENDER_TOP_LEVEL;

    return (
        <Box className={className}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} marginBottom={'16px'}>
                <h4>Key Information</h4>
                <Stack direction={'row'} spacing={1}>
                    {fund?.shortCode && showCounterpartyKYC && (
                        <Button
                            color="success"
                            variant="outlined"
                            component={Link}
                            className={'white-space-nowrap'}
                            data-testid={'ViewFund:counterpartiyKYCutton'}
                            to={COUNTERPARTY_KYC_ROUTE_FACTORY({
                                targets: [fund.id],
                                topCoRef: fund.topCoRef,
                            })}
                        >
                            Counterparty KYC
                        </Button>
                    )}
                    {fund?.hasEditAccess && (
                        <Button
                            color="secondary"
                            variant="outlined"
                            component={Link}
                            data-testid={'ViewFund:editButton'}
                            to={`${location.pathname}${joinQueryParams({ edit: true })}`}
                        >
                            Edit Key Information
                        </Button>
                    )}
                </Stack>
            </Box>
            <Masonry columns={2} spacing={2} sx={{ width: 'auto' }}>
                <FieldRowContainer>
                    <FieldRow label={'TopCo'}>
                        {fund?.topCo?.shortCode && (
                            <LinkWithIcon to={LENDER_VIEW_ROUTE_FACTORY(fund.topCo?.shortCode)}>
                                {fund?.topCo?.name}
                            </LinkWithIcon>
                        )}
                    </FieldRow>
                    <FieldRow label={'Investment Manager'}>
                        {fund?.entity?.shortCode && (
                            <LinkWithIcon to={LENDER_VIEW_ROUTE_FACTORY(fund.entity?.shortCode)}>
                                {fund.entity?.name}
                            </LinkWithIcon>
                        )}
                    </FieldRow>
                    <FieldRow label={'Fund Administrator'}>
                        {fund?.fundAdministrator?.shortCode && (
                            <LinkWithIcon to={LENDER_VIEW_ROUTE_FACTORY(fund.fundAdministrator?.shortCode)}>
                                {fund.fundAdministrator?.name}
                            </LinkWithIcon>
                        )}
                    </FieldRow>
                    <FieldRow label="Jurisdiction">
                        <ListCellItemWrapper>
                            <CountryFlagAndName showFullName country={fund?.jurisdiction!} />
                        </ListCellItemWrapper>
                    </FieldRow>
                    <FieldRow label={'Incorporation Number'}>{fund?.incorporationNumber}</FieldRow>
                    <FieldRow label={'Fund type'}>{fund?.type && fundTypeLabels[fund.type]}</FieldRow>
                    <FieldRow label={'LEI'}>{fund?.lei}</FieldRow>
                    {fund?.kycComplete !== null && (
                        <FieldRow label="Agent KYC">
                            <AgentsKYCStatus
                                value={fund?.kycComplete}
                                hasEditAccess={!!fund?.hasEditAccess}
                                hasAuthorisedSignatures={!!fund?.authorizedSignatoryRoles?.length}
                                hasAgents={!!fund?.agents?.length}
                                config={{ agents: true, authorisedSignatures: true }}
                            />
                        </FieldRow>
                    )}
                    <FieldRow label={'Reinvestment period end date'}>
                        <NullableDate value={fund?.reinvestmentPeriodEndDate} />
                    </FieldRow>
                    <FieldRow label={'Legal maturity date'}>
                        <NullableDate value={fund?.legalMaturityDate} />
                    </FieldRow>
                </FieldRowContainer>
                <FieldRowContainer>
                    <FieldRow label={'Registered Address'}>
                        <AddressDisplay address={fund?.address} />
                    </FieldRow>
                    {fund?.businessAddress && (
                        <FieldRow label={'Business Address'}>
                            <AddressDisplay address={fund?.businessAddress} />
                        </FieldRow>
                    )}
                </FieldRowContainer>
                <FieldRowContainer>
                    <FieldRow label={'DTTP (UK tax ID)'}>{fund?.dttp}</FieldRow>
                    <FieldRow label={'GIIN (US Tax ID)'}>{fund?.giin}</FieldRow>
                    <FieldRow label={'NIF (Spanish Tax ID)'}>{fund?.nif}</FieldRow>
                </FieldRowContainer>
                <FieldRowContainer>
                    <FieldRow label={'Permissioned Lender Filter'}>
                        {getPermissionedLenderFilterText(fund?.parLoansOnly)}
                    </FieldRow>
                    <FieldRow label={'Authorised Signatories'}>
                        <FundAuthorizedSignaturesView
                            value={fund?.authorizedSignatoryRoles || []}
                            hasEditAccess={!!fund?.hasEditAccess}
                        />
                    </FieldRow>
                    <FieldRow label={'Settlement Lead Time (Business Days)'}>{fund?.settlementLeadTime}</FieldRow>
                </FieldRowContainer>
            </Masonry>
        </Box>
    );
};
