import React from 'react';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export interface ITrueFalseIconProps {
    value: boolean;
    trueLabel?: React.ReactNode;
    falseLabel?: React.ReactNode;
    tooltipProps?: TooltipProps;
}

export const TrueFalseIcon: React.FC<ITrueFalseIconProps> = ({
    value,
    trueLabel = 'Yes',
    falseLabel = 'No',
    tooltipProps = {},
}) => {
    return (
        <Tooltip placement={'bottom'} {...tooltipProps} title={value ? trueLabel : falseLabel} arrow>
            <span>
                {!value && <DoDisturbIcon fontSize={'small'} color={'error'} />}
                {value && <CheckCircleOutlineIcon fontSize={'small'} color={'success'} />}
            </span>
        </Tooltip>
    );
};
