import { useParentContext } from 'lib/cdk/ParentContext';
import { useAppSnackbar } from '../../../../components/AppNotification';
import {
    DocumentInInput,
    DocumentStorage,
    KYCDocType,
    useSetDocumentsMutation,
    useSetLoanFundMutation,
} from 'interfaces/model';
import { handleGqlFormMutationError, scrollToFirstInvalidControl } from 'lib/forms';
import { useFormik } from 'formik';
import {
    getFundInitialValues,
    IFundEdit,
    IFundInitialForm,
    prepareFundModelValue,
    validationSchemaFactory,
} from './useFundEditFormik.const';
import { useTaxIdsDocumentsUpload } from '../../../../common/TaxIds/useTaxIdsDocumentsUpload';

interface IFundEditFormikProps {
    fund?: IFundEdit;
    topCoRef?: string;
    entityRef?: string | undefined | null;
    enableReinitialize?: boolean;
    onSaveSuccess: () => void;
}

export const useFundEditFormik = (props: IFundEditFormikProps) => {
    const parent = useParentContext();
    const snackbar = useAppSnackbar();
    const getTaxIdsWithKycId = useTaxIdsDocumentsUpload();
    const [saveDocuments] = useSetDocumentsMutation();
    const [mutateFund] = useSetLoanFundMutation();

    const submitTaxIdFiles = async (fundId: UUID, values: IFundInitialForm) => {
        const taxIds = values.taxIds || [];
        const taxIdsWithKycId = await getTaxIdsWithKycId(taxIds, fundId);
        await mutateFund({
            variables: {
                loanFund: {
                    id: fundId,
                    taxIds: taxIdsWithKycId,
                },
            },
        });
    };

    const submitFiles = async (fundId: UUID, values: IFundInitialForm) => {
        const documents = Object.entries(values.documents).map(
            ([docType, document]): DocumentInInput => ({
                ...document,
                parentRef: fundId,
                docType: docType as KYCDocType,
                storage: DocumentStorage.KYC,
            })
        );
        await saveDocuments({ variables: { documents } });
    };

    const submitFund = async (values: IFundInitialForm) => {
        const fund = prepareFundModelValue(values);
        const { data, errors } = await mutateFund({ variables: { loanFund: fund } });
        if (errors) {
            throw errors[0];
        }
        return data?.loanfund;
    };

    return useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: getFundInitialValues({
            fund: props.fund,
            topCoRef: props.topCoRef,
            entityRef: props.entityRef,
        }),
        validationSchema: validationSchemaFactory(),
        onSubmit: async (values, helpers) => {
            try {
                const fund = await submitFund(values);
                if (fund?.id) {
                    await submitFiles(fund.id, values);
                    await submitTaxIdFiles(fund.id, values);
                }
                await parent.update();
                helpers.resetForm();
                props.onSaveSuccess();
                snackbar.show({ message: 'Loan fund has been saved' });
            } catch (e) {
                scrollToFirstInvalidControl();
                helpers.setFieldValue('currentStep', 0);
                if (!handleGqlFormMutationError(e, values, helpers)) {
                    snackbar.showError({ message: 'Failed to save the fund' }, e);
                }
            }
        },
    });
};
