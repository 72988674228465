import cl from 'classnames';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingSpinnerButton } from '../../../buttons/LoadingSpinnerButton';
import { CurrentStepLabel } from '../CurrentStepLabel';
import { NOOP_ASYNC } from '../../../utils/helpers';
import { AppContainer } from '../../AppContainer';
import styles from './WizardFooter.module.css';
import { RowStack } from '../../RowStack';

interface IWizardFooterProps {
    currentStep: number;
    numberOfSteps?: number;
    onNextStep: (nextStepIndex: number) => Promise<void> | void;
    onPrevStep?: (stepIndex: number) => Promise<void> | void;
    onFinalStep?: (stepIndex: number) => Promise<void>;
    onCancel?: () => void;
    fixed?: boolean;
    continueMode?: boolean;
    disableBackNavigation?: boolean;
    bgColor?: 'light' | 'dark';
}

export const WizardFooter: React.FC<IWizardFooterProps> = ({
    currentStep,
    numberOfSteps = 0,
    onNextStep,
    onPrevStep = NOOP_ASYNC,
    disableBackNavigation,
    continueMode,
    onFinalStep,
    onCancel,
    bgColor = 'dark',
    fixed,
}) => {
    const maxSteps = (numberOfSteps || 1) - 1;
    const finalStepEnabled = !!onFinalStep;
    const isFirstStep = currentStep === 0;
    const isLastStep = currentStep === maxSteps;
    const isFinalStep = finalStepEnabled && currentStep > maxSteps;
    const progress = (isFinalStep ? 1 : (currentStep + 1) / (maxSteps + 1)) * 100;
    const [isLoading, setIsLoading] = useState(false);

    const handleNextStep = async () => {
        setIsLoading(true);
        await onNextStep(currentStep + 1);
        setIsLoading(false);
    };

    const handlePrevStep = async () => {
        const prevStep = currentStep - 1;
        await onPrevStep(prevStep < 0 ? 0 : prevStep);
    };

    return (
        <div
            className={cl(
                bgColor === 'light' && styles.bgLight,
                bgColor === 'dark' && styles.bgLight,
                fixed && styles.fixed
            )}
        >
            <LinearProgress variant={'determinate'} color={'success'} className={styles.progress} value={progress} />
            <AppContainer className={styles.container} sx={{ display: 'flex', alignItems: 'center' }}>
                {!isFinalStep && (
                    <>
                        {!disableBackNavigation && numberOfSteps > 1 && (
                            <CurrentStepLabel currentStep={currentStep} maxSteps={numberOfSteps} />
                        )}
                        <RowStack spacing={1} marginLeft={'auto'}>
                            {isFirstStep && onCancel && (
                                <Button color={'secondary'} onClick={onCancel}>
                                    Cancel
                                </Button>
                            )}
                            {!isFirstStep && !disableBackNavigation && (
                                <Button color={'secondary'} onClick={handlePrevStep}>
                                    <ArrowBackIos fontSize={'inherit'} /> Previous Step
                                </Button>
                            )}

                            <LoadingSpinnerButton
                                spin={isLoading}
                                className={styles.actionButton}
                                testId={`CounterpartiesEdit:${isLastStep ? 'submitButton' : 'nextButton'}`}
                                onClick={handleNextStep}
                            >
                                {isLastStep ? 'Submit' : 'Next Step'}{' '}
                                {!isLastStep && <ArrowForwardIosIcon fontSize={'inherit'} />}
                            </LoadingSpinnerButton>
                        </RowStack>
                    </>
                )}
                {isFinalStep && (
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        className={cl('ml-auto', styles.actionButton)}
                        onClick={async () => await onFinalStep(currentStep + 1)}
                        data-testid={'CounterpartiesEdit:finishButton'}
                    >
                        {continueMode ? 'Continue' : 'Finish'}
                    </Button>
                )}
            </AppContainer>
        </div>
    );
};
