import React from 'react';
import { Card, CardHeader, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ISelectOption } from '../../../../../lib/inputs/Select.const';
import Box from '@mui/material/Box';
import styles from './RoleCapabilitiesSelectList.module.css';

interface ISelectListProps<TKeys, TValue, TOption extends ISelectOption<TKeys, TValue>> {
    options: TOption[];
    value: TValue[];
    onChange: (value: TValue[]) => void;
    editable?: boolean;
    headerLabel: string;
}

export function IndentedMultiSelectList<TKeys extends string, TValue, TOption extends ISelectOption<TKeys, TValue>>({
    options,
    value = [],
    onChange,
    editable,
    headerLabel,
}: ISelectListProps<TKeys, TValue, TOption>) {
    const isAllSelected = options.length > 0 && options.every((option) => value.includes(option.value));
    const isSelected = (option: TOption) => value.includes(option.value);

    const toggleOption = (option: TOption) => {
        if (!editable) {
            return;
        }

        if (isSelected(option)) {
            onChange(value.filter((item) => item !== option.value));
        } else {
            onChange([...value, option.value]);
        }
    };

    const toggleAll = () => {
        if (isAllSelected) {
            onChange([]);
        } else {
            onChange(options.map((option) => option.value));
        }
    };

    const toggleAllDisabled = !editable || options.length === 0 || !!options.find((item) => item.disabled);

    return (
        <Card sx={{ background: 'transparent' }} elevation={0}>
            <CardHeader
                sx={{ px: 2, py: 1, paddingBottom: 0 }}
                avatar={
                    <Box display={'flex'} alignItems={'center'}>
                        <Checkbox
                            sx={{ cursor: toggleAllDisabled ? 'not-allowed' : 'pointer' }}
                            checked={isAllSelected}
                            indeterminate={!isAllSelected && value.length > 0}
                            disabled={toggleAllDisabled}
                            onClick={toggleAll}
                        />
                        <span className={styles.headerLabel}>{headerLabel}</span>
                    </Box>
                }
                subheader={
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <span>
                            {value.length}/{options.length} selected
                        </span>
                    </Box>
                }
            />
            <List sx={{ overflowY: 'auto', marginLeft: '24px', paddingTop: 0 }} dense>
                {options.map((option) => {
                    const isDisabled = !editable || option.disabled;
                    return (
                        <ListItemButton
                            component={'div'}
                            sx={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                            key={option.id}
                            onClick={() => {
                                if (!isDisabled) {
                                    toggleOption(option);
                                }
                            }}
                            disableRipple={isDisabled}
                        >
                            <ListItemIcon>
                                <Checkbox checked={value.includes(option.value)} disabled={isDisabled} />
                            </ListItemIcon>
                            <ListItemText primary={option.name} />
                        </ListItemButton>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );
}
