import React from 'react';
import { List, ListItem, ListSubheader } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ListCellItemWrapper } from '../../../../lib/dataDisplay/List/components/ListCellItemWrapper';
import { CounterpartyTradeDependencyFragment } from '../../../../interfaces/model';
import { ValueCell } from '../../../../lib/dataDisplay/List/components/ListCells';
import { getTradeTitle } from '../../Trades/ViewTrade/getTradeTitle';
import { LinkWithIcon } from '../../../../lib/navigation/LinkWithIcon';
import { TRADE_VIEW_ROUTE_FACTORY } from '../../../App.routes';
import { IconWithTooltip } from '../../../../lib/icons/IconWithTooltip';

interface ICounterpartyTradeDependencyCellProps {
    value: Maybe<CounterpartyTradeDependencyFragment[]> | undefined;
}

const TradeDependenciesList: React.FC<ICounterpartyTradeDependencyCellProps> = ({ value }) => {
    if (!value) {
        return null;
    }

    return (
        <List sx={{ maxHeight: '400px', overflow: 'auto' }} disablePadding>
            <ListSubheader>Dependent trades:</ListSubheader>
            {value.flatMap(({ trades }) =>
                trades?.map((trade) => (
                    <ListItem key={trade.sharedShortCode}>
                        <LinkWithIcon
                            to={TRADE_VIEW_ROUTE_FACTORY(trade.sharedShortCode!, null, { direction: trade.direction })}
                        >
                            {getTradeTitle(trade)}
                        </LinkWithIcon>
                    </ListItem>
                ))
            )}
        </List>
    );
};

export const CounterpartyTradeDependencyCell: React.FC<ICounterpartyTradeDependencyCellProps> = ({ value }) => {
    const hasDependencies = !!value?.flatMap(({ trades }) => trades).length;
    const title = hasDependencies ? <TradeDependenciesList value={value} /> : 'Counterparty has no trade dependencies';

    return (
        <ValueCell>
            <ListCellItemWrapper>
                <IconWithTooltip
                    text={title}
                    color={hasDependencies ? 'error' : 'success'}
                    Icon={hasDependencies ? WarningIcon : CheckCircleIcon}
                />
            </ListCellItemWrapper>
        </ValueCell>
    );
};
