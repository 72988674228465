import React, { useRef } from 'react';
import { FormikProvider } from 'formik';
import { Dialog, DialogTitle } from '@mui/material';
import {
    KYCCategoriesTypesFragment,
    useEntityDocumentsTypesQuery,
    useEntityKeyInformationQuery,
} from 'interfaces/model';
import { isEntityWithBankAccounts, isEntityWithTaxId } from '../Entities.helpers';
import { useDirtyFormikConfirmation } from 'lib/forms/useDirtyFormikConfirmation';
import { FormikWizard, IFormWizardStep } from 'lib/cdk/Wizard/FormikWizard';
import { EntityTaxInformationStep } from './steps/EntityTaxInformationStep';
import { EntityKeyInformationStep } from './steps/EntityKeyInformationStep';
import { EntityBankAccountsStep } from './steps/EntityBankAccountsStep';
import { makeDialogState } from 'store/dialogState/makeDialogState';
import { getEntityTitle } from './CreateEntityWizardDialog.const';
import { EntityDocumentsStep } from './steps/EntityDocumentsStep';
import { DialogCloseButton } from 'lib/modals/DialogCloseButton';
import { useEntityEditFormik } from './useEntityEditFormik';
import { IEditEntityDialogState } from './interfaces';
import { NonFalsy, NOOP } from 'lib/utils/helpers';
import { DialogFormComponent } from 'lib/forms';

interface IEntityCreateWizardProps {
    config?: {
        authorisedSignatorySelect?: boolean;
    };
    onSaveSuccess?: () => void;
    onClose?: () => void;
}

export const useEntityWizardDialog = makeDialogState<IEditEntityDialogState>();

const getSteps = (
    formik: ReturnType<typeof useEntityEditFormik>,
    config: IEntityCreateWizardProps['config'],
    kycRequirements: KYCCategoriesTypesFragment[] | undefined = [],
    topCoRef: UUID | undefined
): IFormWizardStep[] => {
    const hasNoErrorsExcept = (fieldsExclusions: string[]) =>
        Object.keys(formik.errors).filter((field) => !fieldsExclusions.includes(field)).length === 0;

    const hasNoErrorsOnFirstStep = hasNoErrorsExcept(['taxIdsDisclaimerChecked', 'taxIds', 'bankAccounts']);
    const hasNoErrorsOnSecondStep = hasNoErrorsExcept(['taxIds', 'taxIdsDisclaimerChecked']);
    const hasNoErrorsOnThirdStep = hasNoErrorsExcept(['bankAccounts']);
    const hasNoErrorsOnFourthStep = hasNoErrorsExcept([]);
    const hasKycRequirements = kycRequirements.length > 0;

    return [
        {
            name: 'Key information',
            renderContent: () => <EntityKeyInformationStep config={config} />,
            valid: hasNoErrorsOnFirstStep,
        },
        hasKycRequirements && {
            name: 'Documents',
            renderContent: () => <EntityDocumentsStep requirements={kycRequirements} topCoRef={topCoRef} />,
            valid: hasNoErrorsOnSecondStep,
        },
        isEntityWithTaxId(formik.values) && {
            name: 'Tax ID Forms',
            renderContent: () => <EntityTaxInformationStep />,
            valid: hasNoErrorsOnThirdStep,
        },
        isEntityWithBankAccounts(formik.values) && {
            name: 'Bank accounts',
            renderContent: () => <EntityBankAccountsStep />,
            valid: hasNoErrorsOnFourthStep,
        },
    ].filter(NonFalsy);
};

export const CreateEntityWizardDialog: React.FC<IEntityCreateWizardProps> = ({
    onSaveSuccess = NOOP,
    onClose = NOOP,
    config,
}) => {
    const [{ close }, isOpened, state] = useEntityWizardDialog();
    const kycRequirements = useRef<KYCCategoriesTypesFragment[]>([]);

    const { data, error, loading } = useEntityKeyInformationQuery({
        fetchPolicy: 'no-cache',
        skip: !state?.shortCode || !isOpened,
        variables: {
            entityId: state?.shortCode!,
        },
    });

    const formik = useEntityEditFormik({
        ...state,
        entity: data?.entity,
        validateOnMount: isOpened && !loading,
        kycRequirements: kycRequirements.current,
        onSaveSuccess() {
            onSaveSuccess();
            close();
        },
    });

    const entityTitle = getEntityTitle(formik.values);
    const steps = getSteps(formik, config, kycRequirements.current, state?.topCoRef);

    const clearAndClose = useDirtyFormikConfirmation(formik, {
        title: `You about to cancel ${entityTitle} ${data?.entity.id ? 'edit' : 'create'}`,
        body: 'All edits will be lost after close. Are you sure?',
        onConfirm: async () => {
            close();
            onClose();
        },
    });

    const entityType = formik.values.entityType;
    useEntityDocumentsTypesQuery({
        fetchPolicy: 'cache-and-network',
        skip: !entityType || !isOpened,
        variables: { type: entityType! },
        onCompleted({ entityKYC }) {
            kycRequirements.current = entityKYC;
        },
    });

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={isOpened}
                maxWidth={'md'}
                onClose={clearAndClose}
                PaperComponent={DialogFormComponent}
                fullWidth
            >
                <DialogTitle>
                    {state?.shortCode ? 'Edit' : 'New'} {getEntityTitle(formik.values)} {formik.values.name}
                    <DialogCloseButton onClick={clearAndClose} />
                </DialogTitle>
                <FormikWizard maxWidth={'sm'} steps={steps} onCancel={clearAndClose} error={error} disableGutters />
            </Dialog>
        </FormikProvider>
    );
};
