import React from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import styles from './GenericMessage.module.css';
import { SvgIconProps } from '@mui/material';

interface IGenericMessageCardProps {
    Icon: SvgIconComponent;
    IconProps?: SvgIconProps;
    text: string;
}

export const GenericMessage: React.FC<IGenericMessageCardProps> = ({ text, Icon, IconProps }) => {
    return (
        <>
            <Icon className={styles.icon} {...IconProps} />
            <p className={styles.text}>{text}</p>
        </>
    );
};
