import React from 'react';
import isBoolean from 'lodash/isBoolean';
import { IValueCellProps, ValueCell } from './ValueCell';
import { ITrueFalseIconProps, TrueFalseIcon } from '../../../../icons/TrueFalseIcon';
import { ListCellItemWrapper } from '../ListCellItemWrapper';

interface IBooleanCellProps extends IValueCellProps {
    value: Maybe<boolean> | undefined;
    trueLabel?: React.ReactNode;
    falseLabel?: React.ReactNode;
    trueFalseIconProps?: Omit<Partial<ITrueFalseIconProps>, 'value'>;
    mode?: 'text' | 'icon';
}

export const BooleanCell: React.FC<IBooleanCellProps> = ({
    mode = 'icon',
    value,
    trueFalseIconProps,
    trueLabel = 'Yes',
    falseLabel = 'No',
    ...props
}) => {
    return (
        <ValueCell color={value ? 'success' : 'error'} {...props}>
            {isBoolean(value) && (
                <>
                    {mode === 'icon' && (
                        <ListCellItemWrapper>
                            <TrueFalseIcon
                                value={value}
                                trueLabel={trueLabel}
                                falseLabel={falseLabel}
                                {...trueFalseIconProps}
                            />
                        </ListCellItemWrapper>
                    )}
                    {mode === 'text' && (value ? trueLabel : falseLabel)}
                </>
            )}
        </ValueCell>
    );
};
