import React from 'react';
import { Delete } from '@mui/icons-material';
import { IListIconButtonProps, ListIconButton } from 'lib/dataDisplay/List/components/ListIconButton';
import { IBankAccountsListItem } from './BankAccountsListRow';
import { useConfirmDialog } from 'lib/modals/ConfirmDialog';
import { useAppSnackbar } from '../../AppNotification';

interface IBankAccountDeleteIconButtonProps extends Omit<IListIconButtonProps, 'icon'> {
    bankAccount: IBankAccountsListItem;
    onDeleteConfirm: (bankAccountId: UUID) => Promise<void>;
    onDeleteSuccess: () => Promise<void>;
}

export const BankAccountDeleteIconButton: React.FC<IBankAccountDeleteIconButtonProps> = ({
    bankAccount,
    onDeleteConfirm,
    onDeleteSuccess,
    ...props
}) => {
    const snackbar = useAppSnackbar();
    const [{ open }] = useConfirmDialog();

    const openDialog = () => {
        open({
            body: 'You are about to delete a bank account. Are you sure?',
            title: `Delete bank account "${bankAccount.accountName}"`,
            onConfirm: async () => {
                try {
                    await onDeleteConfirm(bankAccount.id);
                    await onDeleteSuccess();
                    snackbar.show({
                        message: 'Bank account deleted successfully',
                    });
                } catch (e) {
                    snackbar.showError({ message: 'Failed to delete bank account' }, e);
                }
            },
        });
    };

    return <ListIconButton onClick={openDialog} icon={Delete} {...props} />;
};
