import { RoleListRowFragment } from '../../../../../../../../interfaces/model';
import { IPeopleListItem } from './PeopleListRow';
import { sortCompare } from '../../../../../../../../lib/utils/helpers';
import { ledgercommRoleLabels } from '../../../../../../../App.const';
import { PeopleListStatus } from './PeopleList.const';

export const preparePeopleListData = (data: RoleListRowFragment[]): IPeopleListItem[] => {
    return data
        .map((role) => ({
            id: role.id,
            topCoRef: role.topCoRef,
            name: role.name || '',
            jobTitle: ledgercommRoleLabels[role.jobTitle!] || role.jobTitle || '',
            // TODO Always green because there is no criteria of role completeness
            status: PeopleListStatus.COMPLETE,
            role,
        }))
        .sort((a, b) => sortCompare(a.name, b.name));
};
