import React from 'react';
import { Link } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import { IListRow } from '../../../../../../../lib/dataDisplay/List';
import { ChildEntity, ParentEntity } from '../../LegalEntities.helpers';
import { IsCompleteIcon } from '../../../../../../../lib/icons/IsCompleteIcon';
import { KYCCompleteStatus } from '../../../../../../../interfaces/model';
import {
    ActionsCell,
    BooleanCell,
    JurisdictionCell,
    ValueCell,
} from '../../../../../../../lib/dataDisplay/List/components/ListCells';
import { EntityDeleteIconButton } from './EntityDeleteIconButton';
import { ListIconButton } from '../../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { ListCellItemWrapper } from '../../../../../../../lib/dataDisplay/List/components/ListCellItemWrapper';
import { useEditKeyInformationDialog } from '../../../EditEntityKeyInformationDialog';
import { useParentContext } from '../../../../../../../lib/cdk/ParentContext';
import { getEntityViewRouteByType } from '../../../../../../App.routes';

export interface ILegalEntitiesListItem {
    id: string;
    name: string;
    jurisdiction: string;
    lenderType: string;
    sec: boolean | null;
    fca: boolean | null;
    kyc: Maybe<KYCCompleteStatus>;
    actions?: never;
    entity: ChildEntity;
}

type IComponentProps = {
    parentEntity: ParentEntity;
};

type ILegalEntitiesListRowProps = IListRow<ILegalEntitiesListItem, IComponentProps>;

export const LegalEntitiesListRow: React.FC<ILegalEntitiesListRowProps> = ({ data, columns, props }) => {
    const parent = useParentContext();
    const [editKeyInformationDialog] = useEditKeyInformationDialog();
    const routeFactory = getEntityViewRouteByType(props!.parentEntity.entityType);

    return (
        <TableRow component={Link} to={routeFactory(data.entity.shortCode!)} hover>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'name' && <ValueCell nowrap={false}>{data.name}</ValueCell>}
                    {id === 'jurisdiction' && <JurisdictionCell value={data.jurisdiction} />}
                    {id === 'lenderType' && <ValueCell>{data.lenderType}</ValueCell>}
                    {id === 'sec' && <BooleanCell value={data.sec} />}
                    {id === 'fca' && <BooleanCell value={data.fca} />}
                    {id === 'kyc' && (
                        <TableCell>
                            <ListCellItemWrapper>
                                {data.kyc !== null && (
                                    <IsCompleteIcon complete={data.kyc === KYCCompleteStatus.COMPLETE} />
                                )}
                            </ListCellItemWrapper>
                        </TableCell>
                    )}
                    {id === 'actions' && (
                        <ActionsCell>
                            {data.entity.hasEditAccess && (
                                <>
                                    <ListIconButton
                                        icon={Edit}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            editKeyInformationDialog.open({
                                                entityId: data.entity.id,
                                            });
                                        }}
                                    />
                                    <EntityDeleteIconButton
                                        id={data.entity.id}
                                        name={data.entity.name}
                                        onSuccess={parent.update}
                                    />
                                </>
                            )}
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
