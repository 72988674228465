import React from 'react';
import { Address } from '../../../interfaces/model';
import { Box } from '@mui/material';
import { CountryFlagAndName } from '../CountryFlagAndName';

interface IAddressDisplayProps {
    address: Address | null | undefined;
}

export const AddressDisplay: React.FC<IAddressDisplayProps> = ({ address }) => {
    if (!address) {
        return <span>No address on record</span>;
    }
    const { streetName, suite, country, city, postcode } = address;
    return (
        <Box display={'flex'} flexDirection={'column'}>
            {streetName && <span>{streetName}</span>}
            {(city || suite || postcode) && <span>{`${city} ${suite || ''} ${postcode || ''}`}</span>}
            {country && <CountryFlagAndName country={country} showFullName />}
        </Box>
    );
};
