import React from 'react';
import { Button } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { useCurrentDocumentRouteFactory } from '../useCurrentDocumentRouteFactory';
import {
    DocPurpose,
    DocumentStorage,
    KYCDocCategory,
    KYCDocSubcategory,
    KYCDocType,
} from '../../../../interfaces/model';
import { IDocumentsListParent } from '../interfaces/IDocumentsListParent';
import { ISplitButtonOption, SplitButton } from '../../../../lib/buttons/SplitButton';
import { useNonApplicableDocumentDialog } from './CreateNonApplicableDocumentDialog';
import { mapListParentToDialogParent } from '../Documents.const';
import { useAddDocumentsDialog } from './AddDocumentsDialog';
import { NonFalsy } from '../../../../lib/utils/helpers';
import { useUserDetails } from '../../../hooks/useUserDetails';

interface IDocumentsAddViewButtonProps {
    category: KYCDocCategory;
    docType: KYCDocType;
    subcategory?: KYCDocSubcategory;
    year?: string;
    parent: IDocumentsListParent;
    className?: string;
    kycMode: boolean;
    upload: boolean;
}

export const DocumentsAddViewButton: React.FC<IDocumentsAddViewButtonProps> = ({
    category,
    docType,
    subcategory,
    year,
    parent,
    kycMode,
    upload,
    className,
}) => {
    const history = useHistory();
    const { role } = useUserDetails();
    const getRoute = useCurrentDocumentRouteFactory();
    const [documentAddDialog] = useAddDocumentsDialog();
    const [nonApplicableDocumentDialog] = useNonApplicableDocumentDialog();

    if (upload && parent.hasDocumentEditAccess) {
        const options: ISplitButtonOption[] = [
            {
                id: 'add',
                name: 'Add',
                testId: 'KYCItemButton:add',
                primary: true,
                onClick: () => {
                    if (parent.id && parent.name) {
                        documentAddDialog.open({
                            docType,
                            docSubcategory: subcategory,
                            parent: mapListParentToDialogParent({ ...parent, topCoRef: role?.topCoRef }),
                            purpose: kycMode ? DocPurpose.KYC : DocPurpose.FACILITY,
                            storage: kycMode ? DocumentStorage.KYC : DocumentStorage.DATA_ROOM,
                            onSaveSuccess: async (id) => history.push(getRoute({ id })),
                            disableCategoryChange: !!docType,
                        });
                    }
                },
            },
            kycMode && {
                id: 'markAsNotApplicable',
                name: 'Not applicable',
                onClick: () => {
                    if (parent.id) {
                        nonApplicableDocumentDialog.open({
                            docType,
                            parentRef: parent.id,
                        });
                    }
                },
            },
        ].filter(NonFalsy);

        return <SplitButton className={className} size={'small'} options={options} />;
    }

    return (
        <Button
            size={'small'}
            color={'primary'}
            component={Link}
            to={getRoute({ category, docType, docSubcategory: subcategory, year })}
            data-testid={'KYCItemButton:view'}
            variant={'outlined'}
            sx={{ minWidth: '72px' }}
        >
            View
        </Button>
    );
};
