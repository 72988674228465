import { FormikContext } from 'formik';
import { getData } from 'country-list';
import TextField from '@mui/material/TextField';
import React, { useContext, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { fromEntries } from '../../utils/helpers';
import styles from './CountrySelect.module.css';
import { Country } from '../../../interfaces/model';

interface IOption {
    flag: React.ReactNode;
    name: string;
    code: string;
}

export const countryCodeToFlagImage = (isoCode: string | null | undefined) => {
    if (!isoCode) {
        return null;
    }
    return <img src={`/img/flags/${isoCode.toLowerCase()}.png`} width={20} alt={isoCode} />;
};

const COUNTRY_LIST: IOption[] = getData().map((item) => {
    return {
        ...item,
        flag: countryCodeToFlagImage(item.code),
    };
});
const COUNTRY_LIST_MAP = fromEntries(COUNTRY_LIST.map((item) => [item.code, item]));

interface ICountrySelectProps {
    name?: string;
    placeholder?: string;
    onChange?: (countryCode: string | null) => void;
    value?: Maybe<Country> | undefined;
    error?: boolean;
    helperText?: React.ReactNode;
    disabled?: boolean;
    fullWidth?: boolean;
    blacklist?: string[] | undefined;
    clearable?: boolean;
    minWidth?: string;
    disablePortal?: boolean;
}

export const CountrySelect: React.FC<ICountrySelectProps> = ({
    name,
    placeholder = 'Select country',
    error: errorProp,
    helperText: helperTextProp,
    value: valueProp,
    onChange: onChangeProp,
    disabled,
    fullWidth,
    blacklist,
    minWidth,
    clearable,
    disablePortal = true,
}) => {
    const formik = useContext(FormikContext);
    const formField = formik?.getFieldProps(name);
    const formFieldMeta = formik?.getFieldMeta(name || '');
    const error = errorProp || formFieldMeta?.error;
    const helperText = helperTextProp || formFieldMeta?.error;
    const touched = formFieldMeta?.touched;

    const value = valueProp || formField?.value;
    const countryCodeObj = COUNTRY_LIST_MAP[value?.toUpperCase()!];
    const isNonStandardValue = value && !countryCodeObj;
    const persistentValue = useMemo(() => {
        return isNonStandardValue ? { code: value!, name: value!, flag: null } : countryCodeObj;
    }, [countryCodeObj, isNonStandardValue, value]);
    const getOptionLabel = (option: IOption) => option.name;
    const onChange = onChangeProp || ((newValue: string | null) => name && formik?.setFieldValue(name, newValue));
    const unavailableCountryCodes = blacklist?.map((country) => country.toLowerCase()) || [];
    const countryOptions = (isNonStandardValue ? [persistentValue, ...COUNTRY_LIST] : COUNTRY_LIST).filter(
        (option) => !unavailableCountryCodes.includes(option.code.toLowerCase())
    );

    const handleOnBlur = () => {
        if (name) {
            formik.setTouched({ ...formik.touched, [name]: true }, true);
        }
    };

    return (
        <Autocomplete
            disablePortal={disablePortal}
            onBlur={handleOnBlur}
            disableClearable={!clearable}
            value={persistentValue || null}
            options={countryOptions}
            disabled={formik?.isSubmitting || disabled}
            filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();
                return options.filter(
                    (option) =>
                        option.code.toLowerCase().includes(inputValue) || option.name.toLowerCase().includes(inputValue)
                );
            }}
            renderOption={(props, option) => (
                <li {...props}>
                    {option.flag && <span className={'mr-8'}>{option.flag}</span>}
                    {getOptionLabel(option)}
                </li>
            )}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: countryCodeObj && <span className={styles.flag}>{countryCodeObj?.flag}</span>,
                    }}
                    sx={{ minWidth }}
                    error={(touched || formik?.submitCount > 0) && Boolean(error)}
                    helperText={(touched || formik?.submitCount > 0) && helperText}
                    fullWidth
                />
            )}
            onChange={(e, option) => onChange(option?.code || null)}
            fullWidth={fullWidth}
        />
    );
};
