import React from 'react';
import { ClientOnboardingNextStepButton } from '../../ClientOnboardingFrame/ClientOnboardingNextStepButton';
import { IClientOnboardingStepComponent } from '../../interfaces/IClientOnboardingStepComponent';
import { ClientOnboardingContentFrame } from '../../ClientOnboardingContentFrame';
import { usePeopleSetupQuery } from '../../../../../../interfaces/model';
import { CLIENT_ONBOARDING_ROUTES } from '../../../../../App.routes';
import { PeopleSetupForm } from './PeopleSetupForm';

interface IPeopleSetupProps extends IClientOnboardingStepComponent {
    noPreview?: boolean;
    onFooterButtonClick?: (e: React.MouseEvent) => void;
    finalStep?: boolean;
}

export const PeopleSetup: React.FC<IPeopleSetupProps> = ({
    stepIndex,
    disabled,
    topCo,
    noPreview,
    onFooterButtonClick,
    finalStep,
}) => {
    const topCoRef = topCo?.id!;

    const query = usePeopleSetupQuery({
        fetchPolicy: 'cache-and-network',
        skip: !topCoRef,
        variables: {
            topCoRef,
        },
    });

    const handleFooterClick = (e: React.MouseEvent) => {
        if (onFooterButtonClick) {
            onFooterButtonClick(e);
        }
        return Promise.resolve(true);
    };

    return (
        <ClientOnboardingContentFrame
            label={'People'}
            description={'Please set up all user credentials and assign a role'}
            error={query.error}
            action={
                <ClientOnboardingNextStepButton
                    stepIndex={stepIndex}
                    spin={query.loading}
                    disabled={!!query.error || disabled}
                    route={CLIENT_ONBOARDING_ROUTES.END}
                    onClick={handleFooterClick}
                    finalStep={finalStep}
                />
            }
        >
            <PeopleSetupForm query={query} topCo={topCo} noPreview={noPreview} />
        </ClientOnboardingContentFrame>
    );
};
