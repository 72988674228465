import React from 'react';
import { Currency } from '../../../../../interfaces/model';
import { IValueCellProps, ValueCell } from './ValueCell';
import { formatCurrency, formatPercentage } from '../../../../utils/formatting';
import { Box, TableCell } from '@mui/material';
import { NoValuePlaceholder } from '../../../../cdk/NoValuePlaceholder';

export interface INumberValueCellProps extends IValueCellProps {
    value: number | null | undefined;
    maxFractionDigits?: number;
    minimumFractionDigits?: number;
    percentage?: boolean;
    currency?: Currency;
    prefix?: string;
    amountContainerClassName?: string;
}

export const NumberValueCell: React.FC<INumberValueCellProps> = ({
    value,
    currency,
    percentage,
    maxFractionDigits = 2,
    minimumFractionDigits = 2,
    children,
    prefix,
    amountContainerClassName,
    ...props
}) => {
    if (!value && value !== 0) {
        return (
            <TableCell>
                <NoValuePlaceholder />
            </TableCell>
        );
    }

    const amountContainer = (
        <span className={amountContainerClassName}>
            {!percentage &&
                formatCurrency(value, currency, { maximumFractionDigits: maxFractionDigits, minimumFractionDigits })}
            {percentage && formatPercentage(value, { maximumFractionDigits: maxFractionDigits, minimumFractionDigits })}
        </span>
    );
    return (
        <ValueCell {...props} nowrap>
            <Box display={'flex'} alignItems={'center'} justifyContent={props.align === 'center' ? 'center' : ''}>
                {prefix}
                {amountContainer}
                {children}
            </Box>
        </ValueCell>
    );
};
