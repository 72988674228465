import { useEffect } from 'react';
import { useDialogUrlState } from '../../store/dialogState/useDialogUrlState';
import { NOOP } from '../utils/helpers';

export const useElementFocusByQueryParamEffect = (options?: { shouldPerformFocus?: boolean; onFocus?: () => void }) => {
    const [params] = useDialogUrlState();
    const { shouldPerformFocus = true, onFocus = NOOP } = options || {};
    const id = params.focus;
    useElementFocusEffect(id, shouldPerformFocus, onFocus);
};

export const useElementFocusEffect = (
    id: string | undefined,
    shouldPerformFocus: boolean,
    onFocus: () => void = NOOP
) => {
    useEffect(() => {
        if (id && shouldPerformFocus) {
            setTimeout(() => {
                const element = document.getElementById(id);
                element?.focus();
                element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                onFocus();
            }, 500);
        }
    }, [shouldPerformFocus, id, onFocus]);
};
