import React from 'react';
import Box from '@mui/material/Box';
import { Alert } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useFormikContext } from 'formik';
import FormLabel from '@mui/material/FormLabel';
import { Dropdown } from '../../../../../lib/inputs/Dropdown';
import { IEditForm, reasonDropdownOptions } from './CreateNonApplicableDocumentDialog.const';
import { TextInput } from '../../../../../lib/inputs/TextInput';
import { NAReason } from '../../../../../interfaces/model';

interface ICreateNonApplicableDocumentFormProps {}

export const CreateNonApplicableDocumentForm: React.FC<ICreateNonApplicableDocumentFormProps> = () => {
    const formik = useFormikContext<IEditForm>();

    return (
        <Stack spacing={3}>
            <Alert icon={false} severity={'info'}>
                Agent can however require you to upload the document
            </Alert>

            <Box>
                <FormLabel htmlFor={'reason'} required>
                    Reason
                </FormLabel>
                <Dropdown
                    name={'reason'}
                    options={reasonDropdownOptions}
                    placeholder={'Select reason'}
                    helperText={'Please explain the reason why the document is not available'}
                />
            </Box>
            {formik.values.reason === NAReason.OTHER && (
                <Box>
                    <FormLabel htmlFor={'comment'} required>
                        Comment
                    </FormLabel>
                    <TextInput name={'comment'} placeholder={'Add comment'} fullWidth />
                </Box>
            )}
        </Stack>
    );
};
