import React from 'react';
import { Button } from '@mui/material';
import { getChildEntityType, getEntityTitleByType, ParentEntity } from './LegalEntities.helpers';
import { AppEmbeddedListWrap } from '../../../../components/AppEmbeddedListWrap';
import { CreateEntityWizardDialog, useEntityWizardDialog } from '../../CreateEntityWizardDialog';
import { LegalEntitiesList } from './LegalEntitiesList';
import { canCreateEntity } from '../../Entities.helpers';

interface ILegalEntitiesProps {
    parentEntity: ParentEntity;
    className?: string;
    testId?: string;
}

export const LegalEntities: React.FC<ILegalEntitiesProps> = ({ parentEntity, className, testId }) => {
    const [createEntityDialog] = useEntityWizardDialog();
    const parentEntityType = parentEntity.entityType;

    const actions = canCreateEntity(parentEntity) && (
        <Button
            color={'primary'}
            variant={'contained'}
            data-testid={`${testId}:addButton`}
            onClick={() =>
                createEntityDialog.open({
                    parentRef: parentEntity.id,
                    topCoRef: parentEntity.topCoRef,
                    entityType: getChildEntityType(parentEntityType),
                    parentEntityType,
                })
            }
        >
            Add {getEntityTitleByType(parentEntityType)}
        </Button>
    );

    return (
        <AppEmbeddedListWrap
            title={getEntityTitleByType(parentEntityType, { plural: true })}
            actions={actions}
            className={className}
        >
            <LegalEntitiesList testId={testId} parentEntity={parentEntity} />
            <CreateEntityWizardDialog />
        </AppEmbeddedListWrap>
    );
};
