import {
    BankAccountPurpose,
    Currency,
    FacilityBusinessDayConvention,
    FacilityCouponScheduleType,
    FacilityDayCount,
    FacilityDebtTypeGoverningLaw,
    FacilityFrequency,
    FacilityRank,
    FacilityRateFixingPeriod,
    FacilityRepaymentFrequency,
    FacilityStatus,
    FacilityType,
    FeeAppliedTo,
    FeeClassification,
    FeeDayCount,
    FeeRecordDate,
    FeeType,
    FloorApplied,
    FloorAppliedTo,
    InterestType,
    IUPeriod,
    NoticePeriod,
    ObservationFrequency,
    OvernightMethod,
    PaymentDate,
    PrepaymentAppliedTo,
    PrivateAccessStatus,
    PrivateFacilityMemberStatus,
    RecordDatePeriod,
    RFRType,
    RiskFreeRate,
    ToggleElection,
    ToggleOption,
    UnscheduledRepaymentsConditions,
} from '../../../interfaces/model';
import React from 'react';
import { DateTime } from 'luxon';
import { DATE_VIEW_FORMAT_SHORT } from '../../../lib/utils/date';
import { SvgIconComponent } from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { currencySymbols } from '../../App.const';
import { EndDateAdjuster } from '../../../interfaces/types';

export const couponRate = [
    { value: true, label: 'Fixed' },
    { value: false, label: 'Floating' },
];
export const getCouponRateLabel = (value: MaybeUndefined<boolean>) =>
    couponRate.find((option) => option.value === value)?.label;

export const formatFacilityName = (
    facility:
        | {
              name: Maybe<string>;
              currency: Currency;
              maturityDate: Maybe<LocalDate>;
          }
        | null
        | undefined
) => {
    const { currency, name = '' } = facility || {};
    const maturityDate = DateTime.fromISO(facility?.maturityDate || '');
    const maturity = maturityDate.isValid ? maturityDate.toFormat(DATE_VIEW_FORMAT_SHORT) : '';
    return `${name} ${currencySymbols[currency as Currency] || currency || ''} ${maturity || ''}`;
};

export const getWhitelistOptionLabel = (value: Maybe<boolean> | undefined) => {
    if (value) {
        return 'PAR Funds';
    }
    if (value === false) {
        return 'NON-PAR Funds';
    }
};

export const FACILITY_TRANCHES = ['A', 'B', 'C', 'D'] as const;

export const facilityDebtTypeGoverningLawLabels: Record<FacilityDebtTypeGoverningLaw, string> = {
    INVESTMENT_GRADE_ENGLISH_LAW: 'Investment Grade - English Law',
    LEVERAGED_ENGLISH_LAW: 'Leveraged - English Law',
    INVESTMENT_GRADE_FRENCH_LAW: 'Investment Grade - French Law',
    LEVERAGED_FRENCH_LAW: 'Leveraged - French Law',
    INVESTMENT_GRADE_SPANISH_LAW: 'Investment Grade - Spanish Law',
    LEVERAGED_SPANISH_LAW: 'Leveraged - Spanish Law',
    INVESTMENT_GRADE_US_LAW: 'Investment Grade - US Law',
    LEVERAGED_US_LAW: 'Leveraged - US Law',

    INVESTMENT_GRADE_HK_LAW: 'Investment grade - Hong Kong Law',
    LEVERAGED_HK_LAW: 'Leverage - Hong Kong Law',

    INVESTMENT_GRADE_SGP_LAW: 'Investment grade - Singapore  Law',
    LEVERAGED_SGP_LAW: 'Leverage  - Singapore  Law',

    INVESTMENT_GRADE_IDN_LAW: 'Investment grade - Indonesian  Law',
    LEVERAGED_IDN_LAW: 'Leverage  - Indonesian  Law',

    INVESTMENT_GRADE_PRC_LAW: 'Investment grade - PRC Law',
    LEVERAGED_PRC_LAW: 'Leverage - PRC Law',

    LEVERAGED_AU_LAW: 'Leverage - Australia Law',
};

export const FACILITY_CURRENCIES: Currency[] = [
    Currency.USD,
    Currency.EUR,
    Currency.GBP,
    Currency.JPY,
    Currency.AUD,
    Currency.NZD,
    Currency.CAD,
    Currency.CHF,
    Currency.NOK,
    Currency.SEK,
    Currency.DKK,
    Currency.HKD,
    Currency.IDR,
    Currency.AED,
    Currency.CNY,
    Currency.SGD,
];

export const isLeveragedDebtType = (type: Maybe<FacilityDebtTypeGoverningLaw> | undefined) => {
    return !!type?.startsWith('LEVERAGED_');
};

export const facilityTypeLabels: Record<FacilityType, string> = {
    TERM_LOAN: 'Term Loan',
    MEZZANINE: 'Mezzanine',
    RCF: 'RCF',
    DELAYED_DRAW: 'Delayed Draw',
};

export const facilityTypeShortLabels: Record<FacilityType, string> = {
    TERM_LOAN: 'TL',
    MEZZANINE: 'Mezz',
    RCF: 'RCF',
    DELAYED_DRAW: 'DDTL',
};

export const rateFixingPeriodLabels: Record<FacilityRateFixingPeriod, string> = {
    T_0: 'T0',
    T_MINUS_1: 'T-1',
    T_MINUS_2: 'T-2',
    T_MINUS_3: 'T-3',
    T_MINUS_4: 'T-4',
    T_MINUS_5: 'T-5',
    T_MINUS_6: 'T-6',
    T_MINUS_7: 'T-7',
    T_MINUS_8: 'T-8',
    T_MINUS_9: 'T-9',
    T_MINUS_10: 'T-10',
    T_PLUS_1: 'T+1',
    T_PLUS_2: 'T+2',
};

export const recordDatePeriodLabels: Record<RecordDatePeriod, string> = {
    T_0: 'T0',
    T_MINUS_1: 'T-1',
    T_MINUS_2: 'T-2',
    T_MINUS_3: 'T-3',
};

export const businessDayConventionLabels: Record<FacilityBusinessDayConvention, string> = {
    MODIFIED_FOLLOWING: 'Modified following',
    PRECEDING_BUSINESS_DAY: 'Preceding business day',
};

export const businessDayConventionTooltipText: Record<FacilityBusinessDayConvention, string> = {
    MODIFIED_FOLLOWING:
        'A business day convention whereby payment days that fall on a holiday or a Saturday or a Sunday roll ' +
        'forward to the next business day. If that day falls in the next calendar month, the payment day rolls ' +
        'backward to the immediately preceding business day.',
    PRECEDING_BUSINESS_DAY:
        'A business day convention whereby payment days that fall on a holiday or a Saturday or a Sunday roll ' +
        'back to the next business day.',
};

export const facilityCouponFrequencyLabels: Record<FacilityFrequency, string> = {
    MONTH_1: '1M',
    MONTH_3: '3M',
    MONTH_6: '6M',
    MONTH_12: '12M',
};

export const facilityCouponToggleElectionLabels: Record<ToggleElection, string> = {
    T_1: 'End date -1',
    T_2: 'End date -2',
    T_3: 'End date -3',
    T_4: 'End date -4',
    T_5: 'End date -5',
    T_6: 'End date -6',
    T_7: 'End date -7',
};

export const facilityRepaymentFrequencyLabels: Record<FacilityRepaymentFrequency, string> = {
    MONTH_1: '1M',
    MONTH_3: '3M',
    MONTH_6: '6M',
    MONTH_12: '12M',
};

export const facilityPrepaymentAppliedToLabels: Record<PrepaymentAppliedTo, string> = {
    ORIGINAL: 'Original Facility Size',
    UTILISED: 'Utilised Facility Size',
    CURRENT: 'Current Facility Size',
};

export const soniaRfrs = [
    RiskFreeRate.SONIA_ON,
    RiskFreeRate.SONIA_1W,
    RiskFreeRate.SONIA_1M,
    RiskFreeRate.SONIA_3M,
    RiskFreeRate.SONIA_6M,
    RiskFreeRate.SONIA_12M,
];

export const facilityRiskFreeRateLabels: Record<RiskFreeRate, string> = {
    EONIA_ON: 'Eonia ON',
    EURIBOR_1W: 'Euribor 1W',
    EURIBOR_1M: 'Euribor 1M',
    EURIBOR_3M: 'Euribor 3M',
    EURIBOR_6M: 'Euribor 6M',
    EURIBOR_12M: 'Euribor 12M',
    GBP_LIBOR_ON: 'GBP Libor ON',
    GBP_LIBOR_1W: 'GBP Libor 1W',
    GBP_LIBOR_1M: 'GBP Libor 1M',
    GBP_LIBOR_2M: 'GBP Libor 2M',
    GBP_LIBOR_3M: 'GBP Libor 3M',
    GBP_LIBOR_6M: 'GBP Libor 6M',
    GBP_LIBOR_12M: 'GBP Libor 12M',
    EURO_LIBOR_ON: 'EURO Libor ON',
    EURO_LIBOR_1W: 'EURO Libor 1W',
    EURO_LIBOR_1M: 'EURO Libor 1M',
    EURO_LIBOR_2M: 'EURO Libor 2M',
    EURO_LIBOR_3M: 'EURO Libor 3M',
    EURO_LIBOR_6M: 'EURO Libor 6M',
    USD_LIBOR_ON: 'USD Libor ON',
    USD_LIBOR_1M: 'USD Libor 1M',
    USD_LIBOR_3M: 'USD Libor 3M',
    USD_LIBOR_6M: 'USD Libor 6M',
    USD_LIBOR_12M: 'USD Libor 12M',
    SONIA_ON: 'SONIA',
    SONIA_1W: 'SONIA 1W',
    SONIA_1M: 'SONIA 1M',
    SONIA_3M: 'SONIA 3M',
    SONIA_6M: 'SONIA 6M',
    SONIA_12M: 'SONIA 12M',
    SOFR_ON: 'SOFR',
    SOFR_1M: 'SOFR 1M',
    SOFR_3M: 'SOFR 3M',
    SOFR_6M: 'SOFR 6M',
    SOFR_12M: 'SOFR 12M',
    AVG_SOFR_1M: 'SOFR 30-DAY AVG',
    AVG_SOFR_3M: 'SOFR 90-DAY AVG',
    AVG_SOFR_6M: 'SOFR 180-DAY AVG',
    ESTR_ON: 'ESTR',
    ESTR_1W: 'ESTR 1W',
    ESTR_1M: 'ESTR 1M',
    ESTR_3M: 'ESTR 3M',
    ESTR_6M: 'ESTR 6M',
    ESTR_12M: 'ESTR 12M',
    SARON_ON: 'SARON',
    SARON_1W: 'SARON 1W',
    SARON_1M: 'SARON 1M',
    SARON_3M: 'SARON 3M',
    SARON_6M: 'SARON 6M',
    SARON_12M: 'SARON 12M',
    AONIA_ON: 'AONIA ON',
};

export const facilityInterestTypeLabels: Record<InterestType, string> = {
    CASH: 'Cash',
    PIK: 'PIK',
};

export const facilityCouponScheduleTypeLabels: Record<FacilityCouponScheduleType, string> = {
    REGULAR: 'Regular',
    PIK_CASH: 'Split',
    TOGGLE: 'Toggleable',
};

export const unscheduledRepaymentsConditionsLabels: Record<UnscheduledRepaymentsConditions, string> = {
    DISABLED: 'Disabled',
    ENABLED: 'Enabled',
    ENABLED_APPROVABLE: 'With approval',
};

export const facilityRankLabels: Record<FacilityRank, string> = {
    SECURED: 'Secured',
    SENIOR_SECURED: 'Senior Secured',
    UNSECURED: 'Unsecured',
    FIRST_LIEN: 'First lien',
    SECOND_LIEN: 'Second lien',
};

export const facilityDayCountLabels: Record<FacilityDayCount, string> = {
    THIRTY_360: '30/360',
    THIRTY_365: '30/365',
    ACT_360: 'ACT/360',
    ACT_365: 'ACT/365',
    ACT_ACT: 'ACT/ACT',
};

export const feeDayCountLabels: Record<FeeDayCount, string> = {
    THIRTY_360: '30/360',
    THIRTY_365: '30/365',
    ACT_360: 'ACT/360',
    ACT_365: 'ACT/365',
    ACT_ACT: 'ACT/ACT',
    NONE: 'NONE',
};

export const feeClassificationLabels: Record<FeeClassification, string> = {
    UTILISATION: 'Utilisation',
    COMMITMENT: 'Commitment',
    PREPAYMENT: 'Prepayment',
    FACILITY: 'Facility',
    AMENDMENT: 'Amendment',
    ORIGINATION: 'Origination',
    TICKING: 'Ticking',
    VOTING: 'Corporate Actions',
};

export const facilityFeeTypeLabels: Record<FeeType, string> = {
    CASH: 'Cash',
    PIK: 'Pik',
};

export enum FeeRecType {
    NON_RECURRING = 'NON_RECURRING',
    RECURRING = 'RECURRING',
}

export const facilityFeeRecTypeLabels: Record<FeeRecType, string> = {
    RECURRING: 'Recurring',
    NON_RECURRING: 'Non-Recurring',
};

export const facilityFeeCalculationReferenceLabels: Record<FeeAppliedTo, string> = {
    AGGREGATE_DRAWN_AMOUNT: 'Aggregate drawn amount',
    FACILITY_SIZE: 'Current facility size',
    FACILITY_SIZE_OBSERVATION_PERIOD_END: 'Facility size at end of payment period',
    FACILITY_SIZE_OBSERVATION_PERIOD_START: 'Facility size at start of payment period',
    NON_UTILISED: 'Non-utilised facility amount',
    NON_UTILISED_OBSERVATION_PERIOD_END: 'Non-utilised facility amount at end of payment period',
    NON_UTILISED_OBSERVATION_PERIOD_START: 'Non-utilised facility amount at start of payment period',
    ORIGINAL_FACILITY_SIZE: 'Original facility size',
    PREPAYMENT_AMOUNT: 'Prepayment amount',
    UTILISED: 'Utilised Facility amount',
    UTILISED_OBSERVATION_PERIOD_END: 'Utilised facility amount at end of payment period',
    UTILISED_OBSERVATION_PERIOD_START: 'Utilised facility amount at start of payment period',
};

export const facilityCalculationFrequencyLabels: Record<ObservationFrequency, string> = {
    MONTH_1: '1M',
    MONTH_3: '3M',
    MONTH_6: '6M',
    MONTH_12: '12M',
};

export const feeRecordDateLabels: Record<FeeRecordDate, string> = {
    OBSV_PERIOD_END: 'Payment period end',
    OBSV_PERIOD_MINUS_DAY_1: 'Payment period end - 1',
    OBSV_PERIOD_MINUS_DAY_2: 'Payment period end - 2',
    OBSV_PERIOD_MINUS_DAY_3: 'Payment period end - 3',
    OBSV_PERIOD_MINUS_DAY_4: 'Payment period end - 4',
};

export const alternativeFeeRecordDateLabels: Record<FeeRecordDate, string> = {
    OBSV_PERIOD_END: 'Fee date',
    OBSV_PERIOD_MINUS_DAY_1: 'Fee date - 1',
    OBSV_PERIOD_MINUS_DAY_2: 'Fee date - 2',
    OBSV_PERIOD_MINUS_DAY_3: 'Fee date - 3',
    OBSV_PERIOD_MINUS_DAY_4: 'Fee date - 4',
};

export const feePaymentDateLabels: Record<PaymentDate, string> = {
    OBSV_PERIOD_END: 'Payment period end',
    OBSV_PERIOD_PLUS_DAY_1: 'Payment period end + 1',
    OBSV_PERIOD_PLUS_DAY_2: 'Payment period end + 2',
    OBSV_PERIOD_PLUS_DAY_3: 'Payment period end + 3',
    OBSV_PERIOD_PLUS_DAY_4: 'Payment period end + 4',
    OBSV_PERIOD_PLUS_DAY_5: 'Payment period end + 5',
    OBSV_PERIOD_PLUS_DAY_6: 'Payment period end + 6',
    OBSV_PERIOD_PLUS_DAY_7: 'Payment period end + 7',
};

export const alternativeFeePaymentDateLabels: Record<PaymentDate, string> = {
    OBSV_PERIOD_END: 'Fee date',
    OBSV_PERIOD_PLUS_DAY_1: 'Fee date + 1',
    OBSV_PERIOD_PLUS_DAY_2: 'Fee date + 2',
    OBSV_PERIOD_PLUS_DAY_3: 'Fee date + 3',
    OBSV_PERIOD_PLUS_DAY_4: 'Fee date + 4',
    OBSV_PERIOD_PLUS_DAY_5: 'Fee date + 5',
    OBSV_PERIOD_PLUS_DAY_6: 'Fee date + 6',
    OBSV_PERIOD_PLUS_DAY_7: 'Fee date + 7',
};

export const showAlternativePaymentLabels = (feeClassification: FeeClassification): boolean =>
    [
        FeeClassification.AMENDMENT,
        FeeClassification.ORIGINATION,
        FeeClassification.PREPAYMENT,
        FeeClassification.TICKING,
    ].includes(feeClassification);

export const facilityStatusLabels: Record<FacilityStatus, string> = {
    TRADING: 'Trading',
    IN_PROGRESS: 'In progress',
    MATURED: 'Matured',
    REQUEST_TRADING: 'Pending Trading Approval',
    ACTIVE: 'Active',
};

export const facilityRFRTypeLabels: Record<RFRType, string> = {
    TERM: 'Term',
    OVERNIGHT: 'Overnight',
};

export const facilityOvernightMethodLabels: Record<OvernightMethod, string> = {
    NCCR: 'NCCR',
};

export const floorAppliedToLabels: Record<FloorAppliedTo, string> = {
    RFR: 'Reference rate',
    RFR_CAS: 'Reference + CAS',
};

export const floorAppliedLabels: Record<FloorApplied, string> = {
    DAILY: 'Daily',
};

export const privateAccessStatusLabels: Record<PrivateAccessStatus, string> = {
    APPROVED: 'Approved',
    REQUEST: 'Requested',
};

export const privateAccessStatusColors: Record<PrivateAccessStatus, Colors> = {
    APPROVED: 'success',
    REQUEST: 'warning',
};

export const iuPeriodLabels: Record<IUPeriod, string> = {
    MONTH_1: 'Monthly',
    MONTH_3: 'Quarterly',
    MONTH_6: 'Semi-Annually',
    MONTH_12: 'Annually',
    CUSTOM: 'Select days',
};

export const iuPeriodViewLabels: Record<IUPeriod, string> = {
    MONTH_1: 'Monthly',
    MONTH_3: 'Quarterly',
    MONTH_6: 'Semi-Annually',
    MONTH_12: 'Annually',
    CUSTOM: 'Custom',
};

export const noticePeriodLabels: Record<NoticePeriod, string> = {
    T_1: '1',
    T_2: '2',
    T_3: '3',
    T_4: '4',
    T_5: '5',
    T_6: '6',
    T_7: '7',
    T_8: '8',
    T_9: '9',
    T_10: '10',
    T_11: '11',
    T_12: '12',
    T_13: '13',
    T_14: '14',
    T_15: '15',
    T_16: '16',
    T_17: '17',
    T_18: '18',
    T_19: '19',
    T_20: '20',
    T_21: '21',
    T_22: '22',
    T_23: '23',
    T_24: '24',
    T_25: '25',
    T_26: '26',
    T_27: '27',
    T_28: '28',
    T_29: '29',
    T_30: '30',
};

export const getNoticePeriodLabel = (
    unscheduledRepaymentsConditions: Maybe<UnscheduledRepaymentsConditions> | undefined,
    type: FacilityType | undefined
) => {
    const repaymentEnabled = unscheduledRepaymentsConditions !== UnscheduledRepaymentsConditions.DISABLED;
    const isUtilizationAllowed = type === FacilityType.RCF || type === FacilityType.DELAYED_DRAW;
    if (isUtilizationAllowed) {
        return repaymentEnabled ? 'Utilisation & Repayment conditions' : 'Utilisation conditions';
    } else if (repaymentEnabled) {
        return 'Repayment conditions';
    }
};

export const FacilityContext = React.createContext<{ updateFacility: () => Promise<void> }>({
    updateFacility: () => Promise.resolve(),
});

export const toggleOptionsLabels: Record<ToggleOption, string> = {
    ANSWERED: 'Answered',
    NO: 'No',
    PENDING: 'Pending',
};

export const toggleOptionsColors: Record<ToggleOption, Colors> = {
    ANSWERED: 'success',
    NO: 'error',
    PENDING: 'warning',
};

export const hasPrivateAccess = (
    facility: Maybe<{ isPrivateFacilityMember: Maybe<PrivateFacilityMemberStatus> | undefined }> | undefined
) => {
    return facility?.isPrivateFacilityMember === PrivateFacilityMemberStatus.PRIVATE;
};

export const COUPON_SCHEDULE_INVALID_START_DATE_ERROR_KEY = 'FacilityCouponSchedule-select-startDate';

export const VALIDATION_HANDLED_ERROR_KEYS = [COUPON_SCHEDULE_INVALID_START_DATE_ERROR_KEY];

export const hasUtilisationRepaymentConditions = (
    privateUtilisationAndPayment: boolean,
    isPrivateFacilityMember: PrivateFacilityMemberStatus | undefined,
    facilityType: FacilityType | undefined,
    unscheduledRepaymentsConditions: UnscheduledRepaymentsConditions | undefined | null
) => {
    return (
        (!privateUtilisationAndPayment || isPrivateFacilityMember === PrivateFacilityMemberStatus.PRIVATE) &&
        (facilityType === FacilityType.RCF ||
            facilityType === FacilityType.DELAYED_DRAW ||
            unscheduledRepaymentsConditions !== UnscheduledRepaymentsConditions.DISABLED)
    );
};

export const privateFacilityMemberStatusIcons: Record<PrivateFacilityMemberStatus, SvgIconComponent> = {
    PUBLIC: LockOpenIcon,
    PRIVATE: LockIcon,
    PENDING: LockIcon,
};

export const privateFacilityMemberStatusLabels: Record<PrivateFacilityMemberStatus, string> = {
    PUBLIC: 'Public',
    PRIVATE: 'Private',
    PENDING: 'Pending',
};

export const privateFacilityMemberStatusColors: Record<PrivateFacilityMemberStatus, string> = {
    PUBLIC: 'warning',
    PRIVATE: 'success',
    PENDING: 'primary',
};

export enum facilitiesTabs {
    DEFAULT = 'main',
    CORPORATE_ACTIONS = 'corporate-actions',
}

export const facilityCurrencyOptions = FACILITY_CURRENCIES.map((value) => ({ id: value, name: value, value }));

export const FACILITY_PURPOSES = [BankAccountPurpose.PRINCIPLE_INTEREST_FEE];

export const endDateAdjusterLabels: Record<EndDateAdjuster, string> = {
    FOLLOWING: 'Following',
    NO: 'No',
    PRECEDING: 'Preceding',
};
