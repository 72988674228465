import { useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { joinQueryParams } from '../../utils/router/joinQueryParams';
import { IUrlParams, IUrlParamsProps, IUrlParamsPropsValidationSchema, useUrlParams } from './useUrlParams';

export interface IUseUrlStateProps<TKeys extends string, T extends IUrlParamsPropsValidationSchema>
    extends IUrlParamsProps<T> {
    initialState?: Partial<IUrlParams<TKeys, T>>;
}

export const useUrlState = <TKeys extends string, T extends IUrlParamsPropsValidationSchema>(
    props: IUseUrlStateProps<TKeys, T>
) => {
    const history = useHistory();
    const location = useLocation();
    const queryParamsMap = useUrlParams(props);
    const initialStateSet = useRef(false);

    const syncURLParams = useCallback(
        (newState: Partial<IUrlParams<TKeys, T>>, { replace }: { replace?: boolean } = {}) => {
            const newLocation = `${location.pathname}${joinQueryParams(newState)}`;
            replace ? history.replace(newLocation) : history.push(newLocation);
        },
        [history, location.pathname]
    );

    useEffect(() => {
        if (props?.initialState && !initialStateSet.current) {
            initialStateSet.current = true;
            syncURLParams(
                {
                    ...props?.initialState,
                    ...queryParamsMap,
                },
                { replace: true }
            );
        }
    });

    return [queryParamsMap, syncURLParams] as const;
};
