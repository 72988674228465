import React from 'react';
import { List } from '../../../../../../../lib/dataDisplay/List/List';
import { IListColumn } from '../../../../../../../lib/dataDisplay/List';
import { IRoleEntitiesListItem, RoleEntitiesListRow } from './RoleEntitiesListRow';
import { EntityType } from '../../../../../../../interfaces/model';
import { getEntityTitleByType } from '../../../LegalEntities/LegalEntities.helpers';
import { ITopCoRolesListItem } from '../TopCoRolesListRow';

interface IRoleEntitiesListProps {
    role: ITopCoRolesListItem;
    topCoType: EntityType | undefined;
}

const getColumns = (topCoType: EntityType | undefined): IListColumn<IRoleEntitiesListItem>[] => [
    { id: 'name', label: getEntityTitleByType(topCoType) },
];

const prepareData = (role: ITopCoRolesListItem): IRoleEntitiesListItem[] => {
    return role.entityRelations
        .filter((item) => item.entityId !== role.topCoRef)
        .map((item) => {
            return {
                id: item.entityId,
                name: item.entity?.name || '',
                capabilities: role.capabilities || [],
                entityRelations: role.entityRelations || [],
            };
        });
};

export const RoleEntitiesList: React.FC<IRoleEntitiesListProps> = ({ role, topCoType }) => {
    const columns = getColumns(topCoType);
    const entityLabel = getEntityTitleByType(topCoType, { plural: true });

    return (
        <List
            emptyStatePlaceholderProps={{ title: `This user is not enabled in any ${entityLabel}` }}
            data={prepareData(role)}
            columns={columns}
            rowComponent={RoleEntitiesListRow}
        />
    );
};
