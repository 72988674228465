import React from 'react';
import cl from 'classnames';
import Box from '@mui/material/Box';
import { BoxProps, Stack } from '@mui/material';
import { NoValuePlaceholder } from 'lib/cdk/NoValuePlaceholder';

interface IValueBoxProps extends BoxProps {
    label?: React.ReactNode;
    className?: string;
    noValueText?: string;
    size?: 'large';
}

export const ValueBox: React.FC<IValueBoxProps> = ({ label, className, noValueText, children, size, ...props }) => {
    if (size === 'large') {
        return (
            <Stack spacing={0.5} {...props}>
                <span className={'nomargin MuiTypography-h3'}>
                    {children ?? <NoValuePlaceholder text={noValueText} />}
                </span>
                <Box className={'subtitle2 text-transform-uppercase secondary'}>{label}</Box>
            </Stack>
        );
    }

    return (
        <Box display={'flex'} flexDirection={'column'} {...props}>
            <Box className={cl('secondary mb-4 body2')}>{label}</Box>
            <Box className={'body2'}>{children ?? <NoValuePlaceholder text={noValueText} />}</Box>
        </Box>
    );
};
