import React from 'react';
import { Alert, Paper } from '@mui/material';
import { useFormikContext } from 'formik';
import Button from '@mui/material/Button';
import { IEntityEditForm } from '../../useEntityEditFormik/useEntityEditFormik.const';
import { TaxIdsList } from '../../../../../common/TaxIds/TaxIdsList/TaxIdsList';
import { useTaxIdEditDialog } from '../../../../../common/TaxIds/TaxIdsList/TaxIdEditDialog';
import { useFormikTaxIdEditHelpers } from '../../../../../common/TaxIds/useFormikTaxIdEditHelpers';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { FormCheckbox } from '../../../../../../lib/forms/components/FormCheckbox';
import { Fieldset } from '../../../../../../lib/forms/Fieldset';
import styles from './EntityTaxInformationStep.module.css';

interface IEntityTaxInformationStep {}

export const EntityTaxInformationStep: React.FC<IEntityTaxInformationStep> = () => {
    const [taxIdEditDialog] = useTaxIdEditDialog();
    const formik = useFormikContext<IEntityEditForm>();
    const { handleSubmit, handleDelete, mapTaxIdEditToListData } = useFormikTaxIdEditHelpers();

    return (
        <Fieldset
            title={'Withholding Tax ID Forms (Optional)'}
            description={'Please add all relevant Double Taxation Tax IDs for this entity'}
            paddingBottom={'32px'}
        >
            <Alert severity={'success'} icon={<TipsAndUpdatesIcon sx={{ fontSize: '24px' }} />}>
                You can come amend tax information at any time. If you do not maintain tax ID properly the entity may
                not be to get the appropriate tax relief.
            </Alert>
            <div className={styles.buttonPanel}>
                <Button className={'mb-16'} variant={'contained'} onClick={() => taxIdEditDialog.open()}>
                    Add Tax ID
                </Button>
                {!!formik.values.taxIds?.length && (
                    <FormCheckbox
                        name={'taxIdsDisclaimerChecked'}
                        label={'I confirm that tax information is up to date'}
                    />
                )}
            </div>

            <Paper sx={{ background: 'var(--bg-default)', padding: '14px' }} elevation={0}>
                <TaxIdsList
                    taxIds={mapTaxIdEditToListData(formik.values.taxIds || [])}
                    emptyStatePlaceholderProps={{ title: "No Tax ID's added yet", sx: { background: 'transparent' } }}
                    onEditSubmit={handleSubmit}
                    onDelete={handleDelete}
                    editable
                />
            </Paper>
        </Fieldset>
    );
};
