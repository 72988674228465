import React from 'react';
import { TableCell, TableRow } from '@mui/material';

interface IListRowSpacerProps {
    height?: string | null;
    colspan: number;
}

export const ListRowSpacer: React.FC<IListRowSpacerProps> = ({ height = '4px', colspan }) => {
    return height ? (
        <TableRow className="spacer">
            <TableCell colSpan={colspan} sx={{ height }} />
        </TableRow>
    ) : null;
};
