import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import {
    KYCDocSubcategory,
    KYCDocType,
    KYCDocumentShareFragment,
    ReviewStatus,
} from '../../../../../../interfaces/model';
import {
    ActionsCell,
    DateValueCell,
    LinkCell,
    ValueCell,
} from '../../../../../../lib/dataDisplay/List/components/ListCells';
import { DoubleValueCell } from '../../../../../../lib/dataDisplay/List/components/ListCells/DoubleValueCell';
import { kycDocSubcategoryLabels, kycDocTypeLabels } from '../../../../../App.const';
import { DocumentDeleteIconButton } from '../../DocumentDeleteIconButton';
import { ROLE_VIEW_ROUTE_FACTORY } from '../../../../../App.routes';
import { IListRow } from '../../../../../../lib/dataDisplay/List';
import { KYCReviewStatusChip } from '../../KYCReviewStatusChip';

export interface IDocumentSharesListRowItem {
    id: string;
    locationType: string;
    parentName: string;
    parentRoute: string;
    docType: KYCDocType;
    docSubcategory: Maybe<KYCDocSubcategory>;
    createdAt: Timestamp;
    filledByName: string;
    filledBySc: string;
    status: Maybe<ReviewStatus> | undefined;
    document: KYCDocumentShareFragment;
    actions?: never;
}

type IDocumentSharesListRowProps = IListRow<
    IDocumentSharesListRowItem,
    {
        updateData: () => Promise<void>;
        disabled?: boolean;
    }
>;

export const DocumentSharesListRow: React.FC<IDocumentSharesListRowProps> = ({ data, props, columns }) => {
    return (
        <TableRow>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'locationType' && <ValueCell>{data.locationType}</ValueCell>}
                    {id === 'parentName' && (
                        <LinkCell to={data.parentRoute} width={'25%'}>
                            {data.parentName}
                        </LinkCell>
                    )}
                    {id === 'docType' && (
                        <DoubleValueCell
                            main={kycDocTypeLabels[data.docType]}
                            alternative={
                                data.docSubcategory && <span>{`/${kycDocSubcategoryLabels[data.docSubcategory]}`}</span>
                            }
                            width={'25%'}
                            nowrap={false}
                            breakAll
                        />
                    )}
                    {id === 'createdAt' && <DateValueCell value={data.createdAt} />}
                    {id === 'filledByName' && (
                        <LinkCell to={ROLE_VIEW_ROUTE_FACTORY(data.filledBySc)}>{data.filledByName}</LinkCell>
                    )}
                    {id === 'status' && (
                        <TableCell>
                            <KYCReviewStatusChip value={data.status} />
                        </TableCell>
                    )}
                    {id === 'actions' && (
                        <ActionsCell>
                            <DocumentDeleteIconButton
                                document={data.document}
                                parentName={data.parentName}
                                onDeleteSuccess={props.updateData}
                                disabled={props.disabled}
                            />
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
