import React from 'react';
import { SuperadminCapabilities } from '../../../../interfaces/model';
import { AppEmbeddedListWrap } from '../../../components/AppEmbeddedListWrap';
import { superAdminCapabilitiesLabels } from '../../../App.const';
import TableRow from '@mui/material/TableRow';
import { Table, TableBody, TableCell } from '@mui/material';
import { ListRowSpacer } from '../../../../lib/dataDisplay/List/components/ListRowSpacer';

interface IViewSystemAdminCapabilities {
    capabilities: SuperadminCapabilities[] | undefined;
}

export const ViewSystemAdminCapabilities: React.FC<IViewSystemAdminCapabilities> = ({ capabilities }) => {
    return (
        <AppEmbeddedListWrap title={'System Admin Capabilities'}>
            {(!capabilities || capabilities.length === 0) && 'No capabilities'}
            {capabilities && capabilities.length > 0 && (
                <Table>
                    <TableBody>
                        {capabilities.map((capability) => {
                            return (
                                <React.Fragment key={capability}>
                                    <TableRow className={'mb-16'}>
                                        <TableCell>{superAdminCapabilitiesLabels[capability]}</TableCell>
                                    </TableRow>
                                    <ListRowSpacer colspan={6} />
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
        </AppEmbeddedListWrap>
    );
};
