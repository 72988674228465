import React from 'react';
import { ITextInputProps, TextInput } from '../TextInput';
import { IconWithTooltip } from '../../icons/IconWithTooltip';
import InfoIcon from '@mui/icons-material/Info';
import styles from './PhoneNumberInput.module.css';

export const PhoneNumberInput: React.FC<ITextInputProps> = ({ name, placeholder, onChange, fullWidth, ...props }) => {
    return (
        <TextInput
            name={name || 'phone'}
            placeholder={placeholder || 'Enter phone number'}
            onChange={onChange}
            fullWidth={fullWidth}
            InputProps={{
                endAdornment: (
                    <IconWithTooltip
                        className={styles.infoButton}
                        Icon={InfoIcon}
                        text={'Please enter a valid phone number, including the country code, eg. "+440123456789"'}
                    />
                ),
            }}
            {...props}
        />
    );
};
