import React from 'react';
import Edit from '@mui/icons-material/Edit';
import { ListIconButton } from '../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { ActionsCell } from '../../../../../../lib/dataDisplay/List/components/ListCells';
import { DocPurpose, DocumentInInput, IdType, KYCDocumentFragment } from '../../../../../../interfaces/model';
import { useNonApplicableDocumentDialog } from '../../CreateNonApplicableDocumentDialog';
import { IDocumentsListParent } from '../../../interfaces/IDocumentsListParent';
import { DocumentDownloadIconButton } from '../../DocumentDownloadIconButton';
import { DocumentDeleteIconButton } from '../../DocumentDeleteIconButton';
import { useDocumentUploadDialog } from '../../DocumentUploadDialog';
import { isDocumentNotApplicable } from '../../../Documents.const';

interface IDocumentActionsCellProps {
    document: KYCDocumentFragment;
    children?: React.ReactNode;
    parent: Omit<IDocumentsListParent, 'update'> | undefined;
    onEditSubmit?: (document: DocumentInInput) => Promise<void>;
    onSaveSuccess: () => Promise<void>;
    config?: { deletion?: boolean };
    disabled?: boolean;
}

export const DocumentActionsCell: React.FC<IDocumentActionsCellProps> = ({
    children,
    parent,
    document,
    onSaveSuccess,
    onEditSubmit: onSubmit,
    config,
    disabled,
}) => {
    const [documentUploadDialog] = useDocumentUploadDialog();
    const [nonApplicableDocumentDialog] = useNonApplicableDocumentDialog();
    const isNotApplicable = isDocumentNotApplicable(document);

    const editDocument = () => {
        if (document.naReason) {
            nonApplicableDocumentDialog.open({
                parentRef: parent?.id,
                docType: document.docType,
                onSubmit,
                value: {
                    reason: document.naReason,
                    comment: document.comment || '',
                },
            });
        } else {
            documentUploadDialog.open({
                parentRef: parent?.id,
                parentName: parent?.name,
                onUploadSuccess: onSaveSuccess,
                value: {
                    docPurpose: parent?.type === IdType.FACILITY ? DocPurpose.FACILITY : DocPurpose.KYC,
                    ...document,
                },
                onSubmit,
            });
        }
    };

    return (
        <ActionsCell align={'right'}>
            {document.id && !isNotApplicable && <DocumentDownloadIconButton documentId={document.id} />}
            {parent?.hasDocumentEditAccess && (
                <>
                    <ListIconButton title={'Edit'} icon={Edit} onClick={editDocument} disabled={disabled} />
                    {config?.deletion !== false && (
                        <DocumentDeleteIconButton
                            parentName={parent.name}
                            document={document}
                            onDeleteSuccess={onSaveSuccess}
                            disabled={disabled}
                        />
                    )}
                </>
            )}
            {children}
        </ActionsCell>
    );
};
