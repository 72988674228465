import React from 'react';
import cl from 'classnames';
import Stack from '@mui/material/Stack';
import { TableCell } from '@mui/material';
import { DoubleValueCell } from '../../../../../lib/dataDisplay/List/components/ListCells/DoubleValueCell';
import { NoValuePlaceholder } from '../../../../../lib/cdk/NoValuePlaceholder';
import { KYCDocumentFragment } from '../../../../../interfaces/model';
import { isDocumentNotApplicable } from '../../Documents.const';
import { kycDocTypeLabels } from '../../../../App.const';
import styles from './DocumentNameCell.module.css';
import { Highlight } from 'react-highlighter-ts';

interface IDocumentNameCellProps {
    document: KYCDocumentFragment;
    highlightText?: string;
}

export const DocumentNameCell: React.FC<IDocumentNameCellProps> = ({ document, highlightText }) => {
    const isNotApplicable = isDocumentNotApplicable(document);
    const isDeleted = document.deleted;

    const firstDocumentName = document.documents[0]?.displayName || '';
    const documentsCount = document.documents.length;
    const multipleFiles = documentsCount > 1;

    const name = document.name || firstDocumentName;

    return (
        <>
            {isNotApplicable ? (
                <DoubleValueCell
                    main={kycDocTypeLabels[document.docType]}
                    alternative={<NoValuePlaceholder notApplicable />}
                    nowrap={false}
                />
            ) : (
                <TableCell width={'20%'}>
                    <Highlight search={highlightText}>
                        <Stack spacing={0.5}>
                            <span className={cl(styles.name, isDeleted && 'secondary')}>{name}</span>
                            {(document.name || multipleFiles) && (
                                <span className={styles.fileNameWrap}>
                                    <span className={styles.fileNameText}>{firstDocumentName}</span>
                                    <span>{multipleFiles ? ` · ${documentsCount} files` : ''}</span>
                                </span>
                            )}
                        </Stack>
                    </Highlight>
                </TableCell>
            )}
        </>
    );
};
