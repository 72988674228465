import React from 'react';
import {
    AgentCapabilities,
    ApprovalStatus,
    Capabilities,
    Currency,
    EntityType,
    ErrorCode,
    FacilityCouponSchedule,
    FundType,
    IdType,
    KYCCompleteStatus,
    KYCDocCategory,
    KYCDocSubcategory,
    KYCDocType,
    SuperadminCapabilities,
} from '../interfaces/model';
import { facilityRiskFreeRateLabels } from './pages/Facilities/Facilities.const';
import { formatPercentage } from '../lib/utils/formatting';
import { ISelectOption } from '../lib/inputs/Select.const';
import { IUStatus } from '../interfaces/types';

export const AppScrollableAreaRefContext = React.createContext<React.RefObject<Pick<HTMLDivElement, 'scrollTo'>>>(
    React.createRef()
);

export const ENTITY_TYPE_LABELS: Record<EntityType, string> = {
    AGENT_TOP_LEVEL: 'Agent',
    AGENT: 'Agent',
    BORROWER_SUBSIDIARY: 'Borrower Subsidiary',
    BORROWER_TOP_LEVEL: 'Borrower',
    LENDER_INVESTMENT_BANK: 'Investment Bank',
    LENDER_INVESTMENT_MANAGER: 'Investment Manager',
    LENDER_TOP_LEVEL: 'Lender',
    SPONSOR: 'Sponsor',
    SPONSOR_TOP_LEVEL: 'Sponsor',
    UBO: 'UBO',
    UNKNOWN: 'unknown',
    FUNDADMIN_TOP_LEVEL: 'Fund Administrator',
    FUNDADMIN: 'Fund Administrator',
};

export const FUND_TYPE_LABELS: Record<FundType, string> = {
    CLO: 'CLO',
    SMA: 'SMA',
    CO_MINGLED: 'Co-Mingled',
};

export const ledgercommRoleLabels: Record<string, string> = {
    AGENT_ADMINISTRATOR: 'Administrator (Facility Agent)',
    AGENT_SALES_MANAGER: 'Sales Manager (Facility Agent)',
    AGENT_TRANSACTION_MANAGER: 'Transaction Manager (Facility Agent)',
    FACILITY_AGENT: 'Facility Agent',
    SECURITY_AGENT: 'Security Agent',
    PORTFOLIO_MANAGER: 'Portfolio Manager',
    CLOSER: 'Closer',
    TRADER: 'Trader',
    TRADER_SALES_ASSISTANT: 'Trader Sales Assistant',
    COMPLIANCE_OFFICER: 'Compliance Officer',
    CREDIT_ANALYST: 'Credit Analyst',
    MANDATED_LEAD_ARRANGER: 'Mandated Lead Arranger',
    SYNDICATED_BANK_LEAD: 'Syndicated Bank Lead',
    BANK_AUTHORISED_SIGNATORY: 'Bank Authorised Signatory',
    SPONSOR: 'Sponsor',
    FUND_ADMINISTRATOR: 'Fund Administrator',
    GENERAL_LEGAL_COUNSEL: 'General Legal Counsel',
    COMPANY_SECRETARY: 'Company Secretary',
    CHIEF_FINANCIAL_OFFICER: 'Chief Financial Officer',
    CHIEF_EXECUTIVE_OFFICER: 'Chief Executive Officer',
    BORROWER: 'Borrower',
    TREASURY: 'Treasury',
    TREASURY_TEAM: 'Treasury Team',
    MASTER_ONBOARDER: 'Account Administrator',
};

export const idTypeLabels: Record<IdType, string> = {
    ACCRUAL_COUPON: 'Accrual Coupon',
    ACCRUAL_FEE: 'Accrual Fee',
    ACCRUAL_PAYMENT: 'Accrual Payment',
    ALLOCATION: 'Allocation',
    CONTENT_FILE: 'Content File',
    COMPLIANCE_CERTIFICATE: 'Information undertakings',
    INFO_UNDERTAKINGS_ACCRUAL: 'Information undertakings accrual',
    ENTITY: 'Entity',
    EVENT_LOG: 'Event Log',
    FACILITY: 'Facility',
    HOLIDAY: 'Holiday',
    INTEREST_RATE: 'Interest Rate',
    DOCUMENT: 'DOCUMENT',
    LOAN_FUND: 'Loan Fund',
    NOTIFICATION: 'Notification',
    PAYMENT_EVENT: 'Payment Event',
    PAYMENT_INTENT: 'Payment Intent',
    ROLE: 'Role',
    TRADE_TICKET: 'Trade Ticket',
    UNKNOWN: 'Unknown',
    WHT: 'WHT',
    WHT_RATE: 'WHT Rate',
    LOCALISE: 'LOCALISE',
    RECONCILE: 'RECONCILE',
    TRADE_AUDIT: 'TRADE_AUDIT',
    COUNTERPARTY: 'COUNTERPARTY',
    TAX_ID: 'Tax ID',
    EMAIL_NOTIFICATION: 'Email Notification',
    INVOICE_EVENT: 'Invoice Event',
    PAYMENT_INVOICE: 'Payment Invoice',
    VOTING: 'Voting',
    SETTINGS: 'SETTINGS',
};

export const kycStatusLabels: Record<KYCCompleteStatus, string> = {
    COMPLETE: 'Complete',
    NOT_COMPLETE: 'Incomplete',
};

export const LEDGERCOMM_ADMIN_ROLE_NAME = 'Administrator';

export const kycDocTypeLabels: Record<KYCDocType, string> = {
    CERTIFICATE_OF_INCORP_AOA_MOA: 'Constitution Documents/AoA/MoA',
    ARTICLES_OF_ASSOCIATION: 'Articles of Association',
    CLO_COLLATERAL_MANAGEMENT_AGREEMENT_OR_WAREHOUSE_DEEDS: 'CLO Collateral Management Agreement / Warehouse Deeds',
    CLO_PROSPECTUS: 'CLO Prospectus',
    INVESTMENT_MANAGEMENT_AGREEMENT: 'Investment Management Agreement and/or Sub-Investment Management agreement',
    CERTIFICATE_OF_RESIDENCY: 'Certificate of Residency',
    TAX_ID_FORM: 'Tax ID Form',
    W8_W9_BEN_FORM: 'W8 BEN / W9 BEN',
    STRUCTURE_CHART: 'Structure Chart',
    DIRECTORS_ID_DOCS: "Director's ID docs",
    SIGNATORY_LIST: 'Signatory List',
    UBO: 'UBO Report',
    PSC_PASSPORTS: 'Passport',
    PEP: 'PEP',
    SANCTIONS: 'Sanctions',
    MEDIA_CHECKS: 'Media checks',
    ESIG: 'E-Signature',
    CREDIT_DOCUMENTS: 'Loan documents',
    FINANCIALS: 'Financials',
    RATINGS: 'Ratings reports',
    MARKETING: 'Marketing',
    AML_COMFORT_LETTER: 'AML comfort letter',
    AUTHORISED_SIGNATORY_ID: 'Authorised signatory ID',
    EVIDENCE_OF_REGULATORY_STATUS: 'Evidence of regulatory status',
    PROOF_OF_ADDRESS: 'Proof of address',
    SOURCE_OF_FUNDS_DECLARATION: 'Source of funds declaration',
    OTHER: 'Other',
};

export const kycCompleteStatusLabels: Record<KYCCompleteStatus, string> = {
    COMPLETE: 'Complete',
    NOT_COMPLETE: 'Incomplete',
};

export const kycCompleteStatusColors: Record<KYCCompleteStatus, Colors> = {
    COMPLETE: 'success',
    NOT_COMPLETE: 'error',
};

export const kycDocCategoryLabels: Record<KYCDocCategory, string> = {
    LEGAL_DOCUMENTS: 'Legal Documents',
    TAX_FORMS: 'Tax Forms',
    TAX_ID_FORM: 'Tax Forms',
    STRUCTURE_AND_OWNERSHIP: 'Structure and Ownership',
    IDENTITY_CHECKS: 'Identity Checks',
    FACILITY_DOCUMENTS: 'Facility Documents',
};

export const kycDocSubcategoryLabels: Record<KYCDocSubcategory, string> = {
    CREDIT_AGREEMENT: 'Credit Agreement',
    AMENDED_CREDIT_AGREEMENT: 'Amended Credit Agreement',
    AMENDMENT_DOCUMENTATION: 'Amendment documentation',
    SENIOR_FACILITIES_AGREEMENT: 'Senior facilities agreement (SFA)',
    LETTER_OF_CREDIT: 'Letter of Credit',
    COLLATERAL_AGREEMENTS: 'Collateral agreements',
    SECURITY_AGREEMENT: 'Security agreement',
    DEPOSIT_ACCOUNT_CONTROL_AGREEMENT: 'Deposit account control agreement',
    UCC_1_FILING: 'UCC-1 filing',
    GUARANTEE_AND_COLLATERAL_AGREEMENT: 'Guarantee and collateral agreement',
    SECURITY_ACCOUNT_CONTROL_AGREEMENT: 'Security account control agreement',
    MORTGAGE_DOCUMENTATION: 'Mortgage documentation',
    AUDITED_ANNUAL_FINANCIALS: 'Audited annual financials',
    SEMI_ANNUAL_FINANCIALS: 'Semi-annual Financials',
    MONTHLY_FINANCIALS: 'Monthly financials',
    QUARTERLY_FINANCIALS: 'Quarterly financials',
    COMPLIANCE_CERTIFICATE: 'Compliance certificate',
    INFORMATION_UNDERTAKINGS: 'Information undertakings',
    TERMSHEET: 'Termsheet',
    UTILISATION_REQUEST: 'Utilisation request',
    MANAGEMENT_PRESENTATION: 'Management presentation',
    FITCH: 'Fitch',
    MOODYS: 'Moodys',
    SANDP: 'S&P',
    BUDGET: 'Budget',
    CONDITIONS_PRECEDENT: 'Conditions precedent',
    CONDITIONS_SUBSEQUENT: 'Conditions subsequent',
    AMENDED_AND_RESTATED_AGREEMENT: 'Amended and restated credit agreement',
    AMENDMENT_AND_RESTATEMENT_AGREEMENT: 'Amendment and restatement agreement',
    CONSENT_REQUEST_LETTER: 'Consent request letter',
    SUMMARY_OF_AMENDMENTS: 'Summary of amendments',
    VOTING_SUMMARY_DOCUMENTS: 'Voting summary documents',
    OTHER: 'Other',
};

export const approvalStatusLabels: Record<ApprovalStatus, string> = {
    DONE: 'Done',
    APPROVED: 'Approved',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
};
export const approvalStatusColors: Record<ApprovalStatus, Colors> = {
    DONE: 'primary',
    APPROVED: 'success',
    PENDING: 'warning',
    REJECTED: 'error',
};

export const errorCodeMessages: Record<ErrorCode, string> = {
    LOGIN_NOT_FOUND: 'Login is not found',
    PASSWORD_INCORRECT: 'Password is incorrect',
    SESSION_NOT_FOUND: 'Session is not found',
    SESSION_EXPIRED: 'Session is expired',
    UNAUTHORISED: 'User is unauthorised',
    FILE_NOT_FOUND: 'File is not found',
    VALIDATION: 'Validation is failed',
    FACILITY_STATUS: 'Failed to perform the action with current facility status',
    TOTP_ACTIVATED: 'TOTP already activated',
    TOTP_INCORRECT: 'TOTP code is invalid',
    TOTP_REQUIRED: 'TOTP code required',
};

export const capabilitiesLabels: Record<Capabilities, string> = {
    UPLOAD_KYC: 'Can upload/edit KYC documents',
    UPLOAD_DATA_ROOM: 'Can upload/edit Data Room documents',
    APPROVE_KYC: 'Can approve KYC',
    ESIG: 'E-Signature can be used in documents',
    MANAGE_STRUCTURE: 'Can create and edit legal entities, funds, roles (TopCo Admin)',
    MANAGE_FACILITIES: 'Can create and edit facilities',

    REQUEST_FACILITY_PRIVATE: 'Can request private facility access',
    APPROVE_FACILITY_PRIVATE: 'Can approve private facility access',

    REQUEST_FACILITY_WHITELIST: 'Can request to become permissioned lender',
    APPROVE_FACILITY_WHITELIST: 'Can approve requests to become permissioned lenders',
    TRADER: 'Can create, edit, allocate, confirm, reject trades',
    SECURITY_AGENT: 'Can act as Security Agent for trade documents',
    FACILITY_AGENT: 'Can act as Facility Agent for trade documents',
    PAYMENT_EVENTS: 'Can create, edit, reconcile recorded payments',
    APPROVE_TRADE_DOCUMENTS: 'Can approve trade documents',
    EDIT_WHT: 'Can edit WHT records',
    APPROVE_WHT: 'Can approve WHT records',
    CREATE_ACCRUAL_PAYMENTS: 'Can create repayments and utilisation requests',
    APPROVE_ACCRUAL_PAYMENTS: 'Can approve accrual payments',
    ACCRUAL_FEE_DISPERSION: 'Can manage dispersion for fee',
    ANSWER_COMPLIANCE_CERTIFICATES: 'Can answer information undertakings',
    APPROVE_COMPLIANCE_CERTIFICATES: 'Can approve information undertakings',
    SELECT_TOGGLE_COUPON: 'Can select TOGGLE option for coupons',
    CREATE_PAST_EVENTS: 'Can create events (trades, utilisation / repayment requests) in the past',
    BANK_ACCOUNTS: 'Change bank accounts details',
    SWITCH_LOGIN: 'Switch to another user',
    CORP_ACTIONS: 'Voting on corporate actions',
};

export const agentCapabilitiesLabels: Record<AgentCapabilities, string> = {
    MANAGE_STRUCTURE: 'Manage structure (create, edit and delete legal entities)',
    UPLOAD_KYC: 'Upload KYC (edit and delete)',
    VIEW_DATA_ROOM: 'View data room',
    UPLOAD_DATA_ROOM: 'Upload data room (edit and delete)',
    AS_BORROWER: 'Act on behalf of the Borrower',
};

export const superAdminCapabilitiesLabels: Record<SuperadminCapabilities, string> = {
    CREATE_TOP_LEVEL_ENTITIES: 'Create Top-Level Entities',
    DELETE_FACILITIES: 'Delete Facilities',
    DELETE_TOP_LEVEL_ENTITIES: 'Delete Top-Level Entities',
    EDIT_CALENDAR: 'Edit Calendar',
    EDIT_INTEREST_RATES: 'Edit Interest Rates',
    FULL_EDIT_ACCESS: 'Full Edit Access',
    VIEW_EVENTLOG: 'View Eventlog',
    VIEW_EMAIL_NOTIFICATION: "View Email Notifications' body",
};

export const composeCouponRateLabelFromCoupon = (
    coupon: Pick<FacilityCouponSchedule, 'endDate' | 'riskFreeRate' | 'spread'>
): string => {
    const riskFreeRate = coupon.riskFreeRate;
    const spread = coupon.spread;
    if (riskFreeRate || spread) {
        const rfrLabel = facilityRiskFreeRateLabels[riskFreeRate!] || '';
        const spreadLabel = formatPercentage(spread);
        return `${rfrLabel}${riskFreeRate && spread ? ' +' : ''} ${spreadLabel}`;
    }
    return '';
};

export const AGENTS_FIELD_ID = 'agents';
export const AUTHORISED_SIGNATURES_FIELD_ID = 'authorisedSignatory';

export const GMT_TIMEZONE = 'Europe/London';

export const timezonesLabels: Dict<string> = {
    '-12:00': '(GMT -12:00) Eniwetok, Kwajalein',
    '-11:00': '(GMT -11:00) Midway Island, Samoa',
    '-10:00': '(GMT -10:00) Hawaii',
    '-09:30': '(GMT -9:30) Taiohae',
    '-09:00': '(GMT -9:00) Alaska',
    '-08:00': '(GMT -8:00) Pacific Time (US & Canada)',
    '-07:00': '(GMT -7:00) Mountain Time (US & Canada)',
    '-06:00': '(GMT -6:00) Central Time (US & Canada), Mexico City',
    '-05:00': '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
    '-04:30': '(GMT -4:30) Caracas',
    '-04:00': '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
    '-03:30': '(GMT -3:30) Newfoundland',
    '-03:00': '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
    '-02:00': '(GMT -2:00) Mid-Atlantic',
    '-01:00': '(GMT -1:00) Azores, Cape Verde Islands',
    [GMT_TIMEZONE]: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
    '+01:00': '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
    '+02:00': '(GMT +2:00) Kaliningrad, South Africa',
    '+03:00': '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    '+03:30': '(GMT +3:30) Tehran',
    '+04:00': '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    '+04:30': '(GMT +4:30) Kabul',
    '+05:00': '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    '+05:30': '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
    '+05:45': '(GMT +5:45) Kathmandu, Pokhara',
    '+06:00': '(GMT +6:00) Almaty, Dhaka, Colombo',
    '+06:30': '(GMT +6:30) Yangon, Mandalay',
    '+07:00': '(GMT +7:00) Bangkok, Hanoi, Jakarta',
    '+08:00': '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
    '+08:45': '(GMT +8:45) Eucla',
    '+09:00': '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    '+09:30': '(GMT +9:30) Adelaide, Darwin',
    '+10:00': '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
    '+10:30': '(GMT +10:30) Lord Howe Island',
    '+11:00': '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
    '+11:30': '(GMT +11:30) Norfolk Island',
    '+12:00': '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
    '+12:45': '(GMT +12:45) Chatham Islands',
    '+13:00': '(GMT +13:00) Apia, Nukualofa',
    '+14:00': '(GMT +14:00) Line Islands, Tokelau',
};

export enum FileDownloadType {
    PDF = 'PDF',
}
export enum ManagedByFilter {
    MANAGED_BY_ME = 'managedByMe',
    NOT_MANAGED_BY_ME = 'notManagedByMe',
}

export const managementOptions: ISelectOption<ManagedByFilter, ManagedByFilter>[] = [
    { id: ManagedByFilter.MANAGED_BY_ME, name: 'Managed by me', value: ManagedByFilter.MANAGED_BY_ME },
    { id: ManagedByFilter.NOT_MANAGED_BY_ME, name: 'Not managed by me', value: ManagedByFilter.NOT_MANAGED_BY_ME },
];
export const iuStatusLabels: Record<IUStatus, string> = {
    APPROVED: 'Approved',
    INCOMPLETE: 'Incomplete',
    PENDING: 'Pending review',
    REJECTED: 'Rejected',
};

export const currencySymbols: Record<Currency, string> = {
    EUR: '€ ',
    GBP: '£ ',
    USD: '$ ',
    CAD: 'CAD',
    CHF: 'CHF ',
    JPY: '¥ ',
    AUD: 'AUD',
    NZD: 'NZD',
    DKK: 'DKK',
    NOK: 'NOK',
    SEK: 'SEK',
    HKD: 'HK$',
    IDR: 'Rp',
    AED: 'د.إ',
    CNY: 'CNY',
    SGD: 'S$',
};

export const NEW_ITEM_ID_PREFIX = 'new_';
