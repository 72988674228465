import { LoginResultFragment, OnboardingStep } from '../interfaces/model';
import { IAppSnackbarConfig } from '../store/interfaces/IAppSnackbarConfig';

export const APP_ERROR = 'APP_ERROR';
export const appError = (payload: { status: boolean; traceId?: string }) => {
    return {
        type: APP_ERROR,
        payload,
    };
};

export const USER_LOGIN = '[User] login';
export const userLogin = (payload: LoginResultFragment) => {
    return {
        type: USER_LOGIN,
        payload,
    };
};

export const USER_LOGOUT = '[User] logout';
export const userLogout = () => {
    return {
        type: USER_LOGOUT,
    };
};

export const ONBOADRING_PROGRESS = '[Onboarding] set progress';
export const setOnboardingProgress = (payload: OnboardingStep[]) => {
    return {
        type: ONBOADRING_PROGRESS,
        payload,
    };
};

export const APP_SHOW_SPINNER = '[App] Show spinner';
export const appShowSpinner = () => {
    return {
        type: APP_SHOW_SPINNER,
    };
};

export const APP_HIDE_SPINNER = '[App] Hide spinner';
export const appHideSpinner = () => {
    return {
        type: APP_HIDE_SPINNER,
    };
};

export const APP_SHOW_SNACKBAR = '[App] Show snackbar';
export const appShowSnackbar = (payload: IAppSnackbarConfig) => {
    return {
        type: APP_SHOW_SNACKBAR,
        payload,
    };
};

export const APP_HIDE_SNACKBAR = '[App] Hide snackbar';
export const appHideSnackbar = () => {
    return {
        type: APP_HIDE_SNACKBAR,
    };
};

export type IAppAction =
    | ReturnType<typeof appError>
    | ReturnType<typeof userLogin>
    | ReturnType<typeof userLogout>
    | ReturnType<typeof setOnboardingProgress>
    | ReturnType<typeof appShowSpinner>
    | ReturnType<typeof appHideSpinner>
    | ReturnType<typeof appShowSnackbar>;
