import React from 'react';
import { CollapseTrigger } from '../../../../buttons/CollapseTrigger';
import { TableCell } from '@mui/material';

interface ICollapseTriggerCellProps {
    isActive: boolean;
    onClick: (isActive: boolean) => void;
    showRedDot?: boolean;
    testId?: string;
}

export const CollapseTriggerCell: React.FC<ICollapseTriggerCellProps> = ({
    isActive,
    testId = '',
    onClick,
    showRedDot,
}) => {
    return (
        <TableCell width={'1px'}>
            <CollapseTrigger
                testId={testId}
                isActive={isActive}
                onClick={() => onClick(!isActive)}
                showRedDot={showRedDot}
            />
        </TableCell>
    );
};
