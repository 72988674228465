import cl from 'classnames';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { DataErrorPlaceholder } from '../../../../lib/dataDisplay/DataErrorPlaceholder';
import { BoxProps, CircularProgress, Paper } from '@mui/material';
import { ErrorCode, useTOTPEnableMutation } from '../../../../interfaces/model';
import styles from './TotpQRCodeImage.module.css';
import { findErrorOfType } from '../Login/Login.const';
import { useAppSnackbar } from '../../AppNotification';

interface ITotpQRCodeImageProps extends BoxProps {
    skipLoad: boolean;
}

const useTOTPQRCodeImageData = (props: { skip: boolean }) => {
    const snackbar = useAppSnackbar();
    const [requestQrCode, qrCode] = useTOTPEnableMutation();
    const { skip } = props;

    useEffect(() => {
        if (!skip && !qrCode.called) {
            requestQrCode().catch((e) => {
                if (!findErrorOfType(e, ErrorCode.TOTP_ACTIVATED)) {
                    snackbar.showError({ message: 'Failed to enable 2FA' }, e);
                }
            });
        } else if (skip && qrCode.called) {
            qrCode.reset();
        }
    }, [qrCode, requestQrCode, skip, snackbar]);

    return {
        ...qrCode,
        data: qrCode.data?.totp,
    } as const;
};

export const TotpQRCodeImage: React.FC<ITotpQRCodeImageProps> = ({ skipLoad, className, ...props }) => {
    const { data, loading, error } = useTOTPQRCodeImageData({ skip: skipLoad });

    if (findErrorOfType(error, ErrorCode.TOTP_ACTIVATED)) {
        return null;
    }

    if (error) {
        return (
            <Paper elevation={0} sx={{ background: 'var(--bg-paper-dark)' }}>
                <DataErrorPlaceholder error={error} />
            </Paper>
        );
    }

    return (
        <Box className={cl(styles.wrap, className)} {...props}>
            {data && <img src={data} className={styles.codeImage} alt={'QR code'} />}
            {loading && <CircularProgress size={40} sx={{ color: 'var(--border-dark)' }} />}
        </Box>
    );
};
