import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import styles from './TreeLeaf.module.css';
import { TreeItem } from '../TreeItem';

interface ITreeLeafProps {
    offset: number;
    active?: boolean;
    onClick?: () => void;
    interactive?: boolean;
    children: React.ReactNode;
}

export const TreeLeaf: React.FC<ITreeLeafProps> = ({ children, ...props }) => {
    return (
        <TreeItem className={styles.wrap} {...props}>
            <CircleIcon className={styles.circle} />
            {children}
        </TreeItem>
    );
};
