import React from 'react';
import { EntityRelationsFragment } from '../../../../../interfaces/model';
import { AppEmbeddedListWrap } from '../../../../components/AppEmbeddedListWrap';
import { EntitiesEmbeddedList } from './EntitiesEmbeddedList/EntitiesEmbeddedList';

interface IEntitiesEmbeddedProps {
    title: string;
    reloadData: () => void;
    entityRelations: EntityRelationsFragment[];
}

export const EntitiesEmbedded: React.FC<IEntitiesEmbeddedProps> = ({ reloadData, entityRelations, title }) => {
    return (
        <AppEmbeddedListWrap title={title}>
            <EntitiesEmbeddedList entityRelations={entityRelations} reloadData={reloadData} />
        </AppEmbeddedListWrap>
    );
};
