import React from 'react';
import { NoValuePlaceholder } from '../../cdk/NoValuePlaceholder';
import styles from './FieldRow.module.css';
import { Box, BoxProps } from '@mui/material';

interface IFieldRowProps extends BoxProps {
    label: React.ReactNode;
    children?: React.ReactNode;
}

export const FieldRow: React.FC<IFieldRowProps> = ({ label, children, ...props }) => {
    return (
        <Box className={styles.row} {...props}>
            <div className={styles.nameCol}>{label}</div>
            <div className={styles.valueCol}>{children || <NoValuePlaceholder />}</div>
        </Box>
    );
};
