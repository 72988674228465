import React, { useContext } from 'react';
import { Box, Paper } from '@mui/material';
import { Capabilities } from '../../../../../interfaces/model';
import { ApolloError } from '@apollo/client';
import { IndentedMultiSelectList } from './IndentedMultiSelectList';
import { CapabilitiesInfoFragment } from './RoleCapabilities.query';
import { capabilitiesLabels } from '../../../../App.const';
import { CapabilityType } from '../../../../../interfaces/types';
import { useUserDetails } from '../../../../hooks/useUserDetails';
import { FormikContext, FormikContextType } from 'formik';
import { IRoleForm } from '../../RoleEditDialog/useRoleEditFormik/useRoleEditFormik.const';
import { UserType } from '../../RoleEditDialog/steps/RoleGeneralInfoEdit/RoleGeneralInfoEdit.const';
import { LoadingContentWrap } from '../../../../../lib/dataDisplay/LoadingContentWrap';

interface IRoleCapabilitiesListProps {
    value: Capabilities[];
    onChange: (value: Capabilities[]) => void;
    headerLabel: string;
    error: ApolloError | undefined;
    loading: boolean;
    capabilitiesList: CapabilitiesInfoFragment[];
}

export const RoleCapabilitiesList: React.FC<IRoleCapabilitiesListProps> = ({
    value,
    onChange,
    headerLabel,
    error,
    loading,
    capabilitiesList,
}) => {
    const formik = useContext<FormikContextType<IRoleForm>>(FormikContext);
    const { isAdmin, canManageStructure } = useUserDetails();

    const options = capabilitiesList.map((capabilityInfo) => {
        return {
            id: capabilityInfo.capability,
            name: capabilitiesLabels[capabilityInfo.capability],
            value: capabilityInfo.capability,
            disabled:
                (capabilityInfo.type === CapabilityType.SUPER_ADMIN && !isAdmin) ||
                (capabilityInfo.type === CapabilityType.ADMIN && formik.values.userType === UserType.ADMIN) ||
                !canManageStructure,
        };
    });

    return (
        <Paper elevation={0} sx={{ background: (theme) => theme.palette.background.default }}>
            <LoadingContentWrap loading={loading} error={error}>
                <IndentedMultiSelectList
                    options={options}
                    value={value}
                    onChange={onChange}
                    editable
                    headerLabel={headerLabel}
                />
            </LoadingContentWrap>
            {options.length === 0 && (
                <Box padding={'12px'} textAlign={'center'} className={'secondary'} fontSize={12}>
                    No options are available for this user
                </Box>
            )}
        </Paper>
    );
};
