import { useFormikContext } from 'formik';
import { useContext, useEffect, useRef } from 'react';
import { FieldsetCollapsibleContext } from './index';

export const useFieldsetExpandOnErrorEffect = <T>(keys: (keyof T)[]) => {
    const formik = useFormikContext<T>();
    const prevRunValid = useRef<boolean>();
    const fieldset = useContext(FieldsetCollapsibleContext);
    const { touched, errors } = formik;
    const everyTouched = keys.length > 0 && keys.every((key) => touched[key]);
    const isValid = keys.length > 0 && keys.every((key) => !errors[key]);

    useEffect(() => {
        if (prevRunValid.current !== isValid) {
            prevRunValid.current = isValid;
            fieldset.setComplete(isValid);
        }

        if (everyTouched && !isValid) {
            fieldset.expand();
        }
    }, [everyTouched, isValid, fieldset]);
};
