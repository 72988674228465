import React from 'react';
import { TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { Delete, Edit } from '@mui/icons-material';
import { ActionsCell, ValueCell } from 'lib/dataDisplay/List/components/ListCells';
import { ListIconButton } from 'lib/dataDisplay/List/components/ListIconButton';
import { useRoleEditDialog } from '../../../../Roles/RoleEditDialog';
import { DirectorRoleFragment } from 'interfaces/model';
import { IListRow } from 'lib/dataDisplay/List';
import { NOOP_ASYNC } from 'lib/utils/helpers';
import { IsCompleteIcon } from 'lib/icons/IsCompleteIcon';
import { useDirectorDetailsDialog } from '../../DirectorDetailsDialog';
import styles from './DirectorsList.module.css';

export interface IDirectorsListRowItem extends DirectorRoleFragment {
    actions?: never;
}

type IDirectorsListRowProps = IListRow<
    IDirectorsListRowItem,
    {
        onSelect?: (id: UUID) => void;
        onRemove?: (id: UUID) => void;
        updateData?: () => Promise<void>;
        editable: boolean;
    }
>;

export const hasAllDirectorFieldsFilled = (role: IDirectorsListRowItem) => {
    return (
        !!role.birthday &&
        !!role.nationality &&
        !!role.address?.country &&
        !!role.address?.streetName &&
        !!role.address?.postcode &&
        !!role.address?.city
    );
};

export const DirectorsListRow: React.FC<IDirectorsListRowProps> = ({ data, columns, props }) => {
    const [roleEditDialog] = useRoleEditDialog();
    const [directorDetailsDialog] = useDirectorDetailsDialog();
    const isSuitableDirector = hasAllDirectorFieldsFilled(data);
    const { onSelect, onRemove, editable, updateData = NOOP_ASYNC } = props;
    return (
        <TableRow
            onClick={() => {
                if (!isSuitableDirector) {
                    directorDetailsDialog.open({
                        role: data,
                        updateData,
                        onSaveSuccess: async () => {
                            if (onSelect) {
                                onSelect(data.id);
                            }
                        },
                    });
                } else if (onSelect) {
                    onSelect(data.id);
                }
            }}
            hover={!!onSelect}
        >
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'name' && (
                        <ValueCell>
                            <span className={styles.nameContainer}>{data.name}</span>
                            {!isSuitableDirector && (
                                <IsCompleteIcon
                                    complete={false}
                                    incompleteLabel={'We require additional information for the position of director'}
                                />
                            )}
                        </ValueCell>
                    )}
                    {id === 'actions' && (
                        <ActionsCell>
                            {onSelect && (
                                <Button size={'large'} variant={'contained'}>
                                    Select
                                </Button>
                            )}
                            {editable && (
                                <>
                                    <ListIconButton
                                        icon={Edit}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            roleEditDialog.open({
                                                roleId: data.id,
                                                directorRole: true,
                                                onSaveSuccess: updateData,
                                            });
                                        }}
                                    />
                                    {onRemove && <ListIconButton icon={Delete} onClick={() => onRemove(data.id)} />}
                                </>
                            )}
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
