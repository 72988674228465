import React from 'react';
import Button from '@mui/material/Button';
import {
    IDocumentEdit,
    prepareTaxIdModelValue,
    removeExtraTaxIdModelFields,
} from '../../../common/TaxIds/TaxIdsList/TaxIdEditDialog/TaxIdEditDialog.const';
import {
    DocumentInInput,
    DocumentStorage,
    KYCDocType,
    TaxIdFragment,
    TaxIdInput,
    useSetDocumentsMutation,
    useSetLoanFundMutation,
} from '../../../../interfaces/model';
import { useTaxIdEditDialog } from '../../../common/TaxIds/TaxIdsList/TaxIdEditDialog';
import { AppEmbeddedListWrap } from '../../../components/AppEmbeddedListWrap';
import { TaxIdsList } from '../../../common/TaxIds/TaxIdsList/TaxIdsList';
import { filesToContentFileInInput } from '../../../hooks/useDocumentUpload';
import { dateToModelValue } from '../../../../lib/utils/date';

interface ITaxIdsViewProps {
    fund: {
        id: Maybe<UUID>;
        hasEditAccess: Maybe<boolean>;
        taxIds: Maybe<TaxIdFragment[]>;
    };
    updateData: () => Promise<void>;
}

export const FundTaxIdsList: React.FC<ITaxIdsViewProps> = ({ fund, updateData }) => {
    const [mutateFund] = useSetLoanFundMutation();
    const [saveDocuments] = useSetDocumentsMutation();
    const [taxIdEditDialog] = useTaxIdEditDialog();

    const handleSubmit = async (editedTaxId: TaxIdInput, documentEdit: IDocumentEdit) => {
        const otherTaxIds = (fund?.taxIds?.filter((taxId) => taxId.id !== editedTaxId.id) || []).map(
            removeExtraTaxIdModelFields
        );
        const contentFiles = await filesToContentFileInInput(documentEdit.files);
        const document: DocumentInInput = {
            parentRef: fund.id || void 0,
            contentFiles,
            name: documentEdit.name,
            comment: documentEdit.comment,
            docType: KYCDocType.TAX_ID_FORM,
            validFromDate: dateToModelValue(documentEdit.validFromDate),
            validToDate: dateToModelValue(documentEdit.validToDate),
            storage: DocumentStorage.DATA_ROOM,
        };
        const response = await saveDocuments({
            variables: { documents: [document] },
        });
        const kycId = response?.data?.documents?.[0].id;

        await mutateFund({
            variables: {
                loanFund: {
                    id: fund?.id,
                    taxIds: [...otherTaxIds, { ...editedTaxId, kycId }],
                },
            },
        });
        await updateData();
    };

    const handleDelete = async (taxIdRef: UUID) => {
        await mutateFund({
            variables: {
                loanFund: {
                    id: fund?.id,
                    taxIds: fund?.taxIds
                        ?.filter((taxId) => taxId.id !== taxIdRef)
                        .map((taxId) => {
                            return {
                                ...prepareTaxIdModelValue(taxId),
                                kyc: void 0,
                            };
                        }),
                },
            },
        });
        await updateData();
    };

    return (
        <AppEmbeddedListWrap
            title={'Withholding Tax ID Forms'}
            actions={
                fund.hasEditAccess && (
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        data-testid={`ViewFund:taxIdsList:addButton`}
                        onClick={() => taxIdEditDialog.open()}
                    >
                        Add Tax ID
                    </Button>
                )
            }
        >
            <TaxIdsList
                testId={'ViewFund:taxIdList'}
                editable={!!fund?.hasEditAccess}
                taxIds={fund?.taxIds || []}
                onDelete={handleDelete}
                onEditSubmit={handleSubmit}
                showDateColumns
            />
        </AppEmbeddedListWrap>
    );
};
