import React from 'react';
import { Stack } from '@mui/material';
import { SearchComponent } from '../../../../../lib/inputs/SearchComponent';
import { EntitiesSelect } from '../../../../common/EntitiesSelect';
import { EntityType, LedgerCommRole } from '../../../../../interfaces/model';
import { useLedgercommRoleOptions } from '../../../Roles/useLedgercommRoleOptions/useLedgercommRoleOptions';
import { Dropdown } from '../../../../../lib/inputs/Dropdown';
import { getEntityTitleByType } from '../LegalEntities/LegalEntities.helpers';

export interface IRolesFilters {
    name?: string;
    entityId?: string | undefined | null;
    ledgerCommRole?: LedgerCommRole | undefined | null;
}

interface IRolesFilterPanelProps {
    value: IRolesFilters;
    onChange: (value: IRolesFilters) => void;
    entityId: string;
    topCoRef: string;
    topCoEntityType: EntityType | undefined;
}

const getEntityTypesForFilter = (topCoEntityType: EntityType | undefined) => {
    switch (topCoEntityType) {
        case EntityType.LENDER_TOP_LEVEL:
            return [EntityType.LENDER_INVESTMENT_MANAGER, EntityType.LENDER_INVESTMENT_BANK];
        case EntityType.BORROWER_TOP_LEVEL:
            return [EntityType.BORROWER_SUBSIDIARY];
        case EntityType.FUNDADMIN_TOP_LEVEL:
            return [EntityType.FUNDADMIN];
        case EntityType.SPONSOR_TOP_LEVEL:
            return [EntityType.SPONSOR];
        case EntityType.AGENT_TOP_LEVEL:
            return [EntityType.AGENT];
        default:
            return [EntityType.LENDER_INVESTMENT_BANK];
    }
};

export const RolesFilterPanel: React.FC<IRolesFilterPanelProps> = ({
    value,
    onChange,
    topCoRef,
    topCoEntityType,
    entityId,
}) => {
    const ledgerCommRoleOptions = useLedgercommRoleOptions(topCoEntityType);

    return (
        <Stack direction={'row'} spacing={1} marginBottom={'32px'}>
            <SearchComponent
                placeholder={'Search by name'}
                value={value.name}
                onChange={(name) => onChange({ ...value, name })}
                fullWidth
            />
            <EntitiesSelect
                placeholder={topCoEntityType ? getEntityTitleByType(topCoEntityType) : ''}
                topCoRef={topCoRef}
                entityTypes={getEntityTypesForFilter(topCoEntityType)}
                onChange={(id) => onChange({ ...value, entityId: id })}
                value={value.entityId || void 0}
                size={'small'}
                unavailableRefs={[entityId]}
                fullWidth
            />
            <Dropdown
                value={value.ledgerCommRole}
                options={ledgerCommRoleOptions}
                placeholder={'Job title'}
                onChange={(option) => onChange({ ...value, ledgerCommRole: option?.value })}
                fullWidth
                size={'small'}
                clearable
            />
        </Stack>
    );
};
