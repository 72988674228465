import React, { useState } from 'react';
import { CounterpartyKYCListActionButton } from 'App/pages/Counterparties/CounterpartyKYC/CounterpartyKYCList/CounterpartyKYCListActionButton';
import { CounterpartyKYCChildrenList } from '../../../../Counterparties/CounterpartyKYC/CounterpartyKYCList/CounterpartyKYCChildrenList';
import { ICounterpartyKYCChild } from '../../../../Counterparties/CounterpartyKYC/CounterpartyKYCList/flattenCounterpartyKYCChildren';
import { CounterpartyTradeDependencyCell } from '../../../../Counterparties/components/CounterpartyTradeDependencyCell';
import { ActionsCell, DateValueCell, JurisdictionCell, LinkCell } from 'lib/dataDisplay/List/components/ListCells';
import { CounterpartyStatusCell } from '../../../../Counterparties/components/CounterpartyStatusCell';
import { CounterpartyTargetFragment, CounterpartyTradeDependencyFragment } from 'interfaces/model';
import { ListRow } from 'lib/dataDisplay/List/components/ListRow';
import { CollapseTrigger } from 'lib/buttons/CollapseTrigger';
import { CollapsibleRow } from 'lib/legacy/CollapsibleRow';
import { CounterpartyStatus } from 'interfaces/types';
import { IListRow } from 'lib/dataDisplay/List';

export interface IEntityCounterpartyKYCListRowItem {
    id: string;
    topCoName: string;
    topCoRoute: string;
    headquarters: Maybe<string>;
    targetName: string;
    targetRoute: string;
    changedAt: MaybeUndefined<Timestamp>;
    hasBlockingTradeDependency: boolean;
    counterpartyTradeDep: CounterpartyTradeDependencyFragment[];
    status: Maybe<CounterpartyStatus>;
    children: ICounterpartyKYCChild[];
    target: CounterpartyTargetFragment;
    actions?: never;
}

type IEntityCounterpartyKYCListRowProps = IListRow<
    IEntityCounterpartyKYCListRowItem,
    {
        targets: UUID[];
        topCoRef: UUID | null;
        collapsible?: boolean;
    }
>;

export const FundEntityCounterpartyKYCListRow: React.FC<IEntityCounterpartyKYCListRowProps> = ({
    data,
    columns,
    props,
}) => {
    const [isExpanded, setExpanded] = useState(false);
    const collapsible = props.collapsible !== false;

    return (
        <>
            <ListRow focused={isExpanded}>
                {columns.map(({ id }) => (
                    <React.Fragment key={id}>
                        {id === 'topCoName' && <LinkCell to={data.topCoRoute}>{data.topCoName}</LinkCell>}
                        {id === 'headquarters' && <JurisdictionCell value={data.headquarters} />}
                        {id === 'targetName' && <LinkCell to={data.targetRoute}>{data.targetName}</LinkCell>}
                        {id === 'changedAt' && (
                            <DateValueCell value={data.changedAt} placeholderProps={{ text: 'Unchanged' }} />
                        )}
                        {id === 'status' && <CounterpartyStatusCell value={data.status} />}
                        {id === 'hasBlockingTradeDependency' && (
                            <CounterpartyTradeDependencyCell value={data.counterpartyTradeDep || []} />
                        )}
                        {id === 'actions' && (
                            <ActionsCell>
                                {props.topCoRef && (
                                    <CounterpartyKYCListActionButton
                                        status={data.status}
                                        entities={data.children}
                                        targets={[data.target.id!]}
                                        topCoRef={props.topCoRef}
                                    />
                                )}
                                {collapsible && (
                                    <CollapseTrigger isActive={isExpanded} onClick={() => setExpanded(!isExpanded)} />
                                )}
                            </ActionsCell>
                        )}
                    </React.Fragment>
                ))}
            </ListRow>
            {collapsible && (
                <CollapsibleRow expand={isExpanded} colspan={columns.length}>
                    <CounterpartyKYCChildrenList
                        value={data.children || []}
                        targets={[data.target]}
                        topCoRef={props.topCoRef}
                    />
                </CollapsibleRow>
            )}
        </>
    );
};
