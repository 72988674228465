import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import styles from './FileList.module.css';

interface IFileListProps {
    value: File[];
    onChange: (value: File[]) => void;
    disabled?: boolean;
}

export const FileListItem: React.FC<{ value: File; onDelete: () => void; disabled: boolean }> = ({
    value,
    disabled,
    onDelete,
}) => {
    const extension = value.name.split('.').pop();
    const name = value.name;

    return (
        <div className={styles.fileItem}>
            <div className={styles.iconBox}>
                <FilePresentOutlinedIcon fontSize={'small'} />
            </div>
            <div className={styles.fileNameContainer} title={name}>
                <span className={styles.fileName}>{name}</span>
                <span className={styles.fileExtension}>{extension}</span>
            </div>
            {!disabled && (
                <IconButton color={'secondary'} title={'Remove file'} onClick={onDelete}>
                    <Delete />
                </IconButton>
            )}
        </div>
    );
};

export const FileList: React.FC<IFileListProps> = ({ value, onChange, disabled }) => (
    <Box>
        {value.map((file, index) => (
            <FileListItem
                value={file}
                onDelete={() => {
                    const newValue = [...value];
                    newValue.splice(index, 1);
                    onChange(newValue);
                }}
                disabled={!!disabled}
                key={file.name + index}
            />
        ))}
    </Box>
);
