import React from 'react';
import { AppEmbeddedListWrap } from '../../components/AppEmbeddedListWrap';
import { EntityFundAgentList } from './EntityFundAgentList';
import { AgentAclInInput, AgentFragment } from '../../../interfaces/model';
import { Button } from '@mui/material';
import { AgentEditDialog, useAgentEditDialog } from './AgentEditDialog/AgentEditDialog';
import { NonFalsy } from '../../../lib/utils/helpers';

interface IEntityFundAgentsProps {
    agents: AgentFragment[];
    canEditAgents: boolean;
    onDeleteConfirm: (agentRef: UUID) => void;
    onSave: (editedAgent: AgentAclInInput) => void;
}

export const EntityFundAgents: React.FC<IEntityFundAgentsProps> = ({
    agents,
    canEditAgents,
    onDeleteConfirm,
    onSave,
}) => {
    const [agentEditDialog] = useAgentEditDialog();
    const unavailableAgentsRefs = agents.map((item) => item.entity?.id).filter(NonFalsy);
    return (
        <AppEmbeddedListWrap
            title={'Agents'}
            actions={
                canEditAgents && (
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => {
                            agentEditDialog.open();
                        }}
                    >
                        Add Agent
                    </Button>
                )
            }
        >
            <EntityFundAgentList agents={agents} canEditAgents={canEditAgents} onDeleteConfirm={onDeleteConfirm} />
            <AgentEditDialog unavailableAgentsRefs={unavailableAgentsRefs} onSave={onSave} />
        </AppEmbeddedListWrap>
    );
};
