import React from 'react';
import { NOOP } from '../../../lib/utils/helpers';
import { useElementFocusByQueryParamEffect } from '../../../lib/hooks/useElementFocusByQueryParamEffect';
import { FormikProvider } from 'formik';
import { Container, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DialogFormComponent } from '../../../lib/forms';
import { DialogCloseButton } from '../../../lib/modals/DialogCloseButton';
import { LoadingSpinnerButton } from '../../../lib/buttons/LoadingSpinnerButton';
import { EditFundForm } from './CreateFundWizard/EditFundForm/EditFundForm';
import { DataErrorPlaceholder } from '../../../lib/dataDisplay/DataErrorPlaceholder';
import { useFundEditFormik } from './CreateFundWizard/useFundEditFormik/useFundEditFormik';
import { makeDialogState } from '../../../store/dialogState/makeDialogState';
import { useFundKeyInformationQuery } from './queries/FundKeyInformation.query';

interface IEditFundKeyInformationDialogState {
    fundId: UUID;
}

interface IEditFundKeyInformationDialogProps {
    onClose?: () => void;
    onSaveSuccess?: () => void;
}

export const useEditFundKeyInformationDialog = makeDialogState<IEditFundKeyInformationDialogState>();

export const EditFundKeyInformationDialog: React.FC<IEditFundKeyInformationDialogProps> = ({
    onClose = NOOP,
    onSaveSuccess = NOOP,
}) => {
    const [{ close }, open, state] = useEditFundKeyInformationDialog();
    const { fundId } = state || {};

    const { data, loading, error } = useFundKeyInformationQuery({
        skip: !fundId || !open,
        variables: { fundId: fundId! },
        fetchPolicy: 'no-cache',
    });

    useElementFocusByQueryParamEffect({ shouldPerformFocus: open && !loading });

    const formik = useFundEditFormik({
        fund: data?.loanfund || void 0,
        onSaveSuccess() {
            clearAndClose();
            onSaveSuccess();
        },
    });

    const { isSubmitting } = formik;

    const clearAndClose = () => {
        formik.resetForm();
        onClose();
        close();
    };

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={open}
                onClose={clearAndClose}
                maxWidth={'md'}
                PaperComponent={DialogFormComponent}
                PaperProps={{ sx: { position: 'unset' } }}
                fullWidth
            >
                <DialogTitle>
                    Edit key information
                    <DialogCloseButton onClick={clearAndClose} />
                </DialogTitle>
                <DialogContent>
                    {open && (
                        <Container maxWidth={'sm'}>
                            {error && <DataErrorPlaceholder error={error} />}
                            {!error && <EditFundForm disabled={loading || isSubmitting} />}
                        </Container>
                    )}
                </DialogContent>
                <DialogActions>
                    <LoadingSpinnerButton type="submit" spin={isSubmitting} testId={'FundDialog:submitButton'} />
                </DialogActions>
            </Dialog>
        </FormikProvider>
    );
};
