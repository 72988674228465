import * as yup from 'yup';
import { useUrlState } from 'lib/hooks/router/useUrlState';
import { KYCDocType } from 'interfaces/types';
import { yupEnum } from 'lib/forms';

export const useViewRoleUrlState = () => {
    return useUrlState({
        validationSchema: {
            edit: yup.boolean(),
            kyc: yupEnum(KYCDocType),
            kycId: yup.string(),
        },
    });
};

export type IViewRoleUrlState = ReturnType<typeof useViewRoleUrlState>[0];
