import React, { useContext } from 'react';
import { Autocomplete, CircularProgress, FormHelperText, TextField } from '@mui/material';
import { ISelectOption } from '../../../lib/inputs/Select.const';
import { EntityType, useEntitiesSelectQuery } from '../../../interfaces/model';
import isArray from 'lodash/isArray';
import { NonFalsy } from '../../../lib/utils/helpers';
import styles from './EntitiesSelect.module.css';
import { FormikContext } from 'formik';
import { ValidationMessage } from '../../../lib/forms/ValidationMessage';

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
    {
        [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
    }[Keys];

interface IEntitiesSingleSelectProps {
    id?: string;
    name?: string;
    value?: UUID | undefined;
    onChange?: (entityId: Maybe<UUID>) => void;
    entityType?: EntityType;
    entityTypes?: EntityType[];
    topCoRef?: UUID;
    includeWithoutViewAccess?: boolean;
    placeholder?: string;
    disabled?: boolean;
    multiple?: false;
    helperText?: string;
    disableClearable?: boolean;
    selectAllOptionEnabled?: boolean;
    unavailableRefs?: UUID[];
    size?: 'small' | 'medium';
    fullWidth?: boolean;
    testId?: string;
}

type IEntitiesSingle = RequireAtLeastOne<IEntitiesSingleSelectProps, 'entityType' | 'entityTypes'>;

interface IEntitiesMultiSelectProps {
    id?: string;
    name?: string;
    value?: UUID[];
    onChange?: (entityId: Maybe<UUID[]>) => void;
    placeholder?: string;
    entityType?: EntityType;
    entityTypes?: EntityType[];
    helperText?: string;
    topCoRef?: UUID;
    includeWithoutViewAccess?: boolean;
    disabled?: boolean;
    multiple: true;
    disableClearable?: boolean;
    unavailableRefs?: UUID[];
    size?: 'small' | 'medium';
    fullWidth?: boolean;
    testId?: string;
}

type IEntitiesMulti = RequireAtLeastOne<IEntitiesMultiSelectProps, 'entityType' | 'entityTypes'>;

type IEntitiesSelectProps = IEntitiesSingle | IEntitiesMulti;

const optionFactory =
    (entities: { id: UUID; name: string }[] = []) =>
    (id: UUID | undefined) => {
        const entity = entities.find((item) => id && item.id === id);
        return id ? { id, name: entity?.name, value: id } : null;
    };

// Note: don't use props destructuring as typescript loses the context
// and cannot distinguish single and multiple mode
export const EntitiesSelect: React.FC<IEntitiesSelectProps> = (props) => {
    const { name, entityType, entityTypes, topCoRef, helperText, disableClearable, includeWithoutViewAccess } = props;
    const formik = useContext(FormikContext);
    const formField = name ? formik?.getFieldProps(name) : void 0;
    const formFieldMeta = name ? formik?.getFieldMeta(name) : void 0;

    const entityTypeFilter = entityTypes ? { entityTypes } : { entityType };

    const { data, loading } = useEntitiesSelectQuery({
        fetchPolicy: 'cache-and-network',
        variables: { filter: { ...entityTypeFilter, topCoRef, includeWithoutViewAccess } },
    });
    const getOption = optionFactory(data?.entities.data);
    const getOptions = (values: UUID[] = []) => values.map(getOption).filter(NonFalsy);
    const options: ISelectOption<string, string>[] = (
        data?.entities.data?.map(({ id, name: entityName }) => ({
            id,
            name: entityName,
            value: id,
        })) || []
    ).filter((item) => !props.unavailableRefs?.includes(item.id));
    const value = props.value || formField?.value;

    return (
        <>
            <Autocomplete
                id={props.id}
                data-testid={props.testId}
                disableClearable={disableClearable}
                size={props.size}
                fullWidth={props.fullWidth}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
                onChange={(_, newValue) => {
                    if (isArray(newValue) && props.multiple) {
                        const val = newValue?.map((option) => option.value);
                        props.onChange ? props.onChange(val) : formik?.setFieldValue(name!, val);
                    } else if (!isArray(newValue) && !props.multiple) {
                        const val = newValue?.value || null;
                        props.onChange ? props.onChange(val) : formik?.setFieldValue(name!, val);
                    }
                }}
                value={props.multiple ? getOptions(value) : getOption(value)}
                options={options}
                loading={loading}
                multiple={props.multiple}
                disabled={props.disabled || loading || formik?.isSubmitting}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={formFieldMeta?.touched && !!formFieldMeta?.error}
                        InputProps={{
                            ...params.InputProps,
                            placeholder: props.placeholder,
                            endAdornment: (
                                <>
                                    {loading && (
                                        <CircularProgress color="inherit" size={20} className={styles.progress} />
                                    )}
                                    {!loading && params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {name && <ValidationMessage field={name} />}
        </>
    );
};
