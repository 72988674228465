import React from 'react';
import Stack from '@mui/material/Stack';
import { KYCDocType, KYCDocumentFragment } from '../../../../../../interfaces/model';
import { FieldsetCollapsible } from '../../../../../../lib/forms/FieldsetCollapsible';
import { SingleDocumentList } from '../../../../../components/Documents/components/SingleDocumentList';

export const FIELDSETS: { title: string; description: string; docType: KYCDocType }[] = [
    {
        title: 'Signatory List',
        description: 'Please provide a signatory list for this company in PDF format',
        docType: KYCDocType.SIGNATORY_LIST,
    },
    {
        title: 'Structure Chart',
        description:
            'Please provide a structure of your business presented in a chart (family tree) ' +
            'in PDF format, establishing Beneficial Owners of 25% or more',
        docType: KYCDocType.STRUCTURE_CHART,
    },
    {
        title: 'UBO Report (Ultimate Beneficial Ownership)',
        description: 'This helps us move through KYC quickly by identifying who owns the company',
        docType: KYCDocType.UBO,
    },
];

export const StructureAndOwnershipSetupForm: React.FC<{
    loading: boolean;
    parent:
        | { id: UUID; name: string; topCoRef: UUID | undefined; shortCode: string | undefined; docTypes: KYCDocType[] }
        | undefined;
    data: KYCDocumentFragment[];
    updateData: () => Promise<void>;
    required?: boolean;
}> = ({ parent, loading, data, required, updateData }) => {
    const uploadedDocumentsMap = Object.fromEntries(
        data.filter(({ uploaded, deleted }) => uploaded && !deleted).map((item) => [item.docType, item])
    );

    return (
        <Stack spacing={4}>
            {FIELDSETS.map(({ title, description, docType }) => {
                const document = uploadedDocumentsMap[docType];

                return (
                    <FieldsetCollapsible
                        key={title}
                        title={title}
                        description={description}
                        complete={!!document}
                        required={required}
                        defaultExpand
                    >
                        <SingleDocumentList
                            parent={parent}
                            docType={docType}
                            value={document}
                            disabled={loading}
                            updateData={updateData}
                        />
                    </FieldsetCollapsible>
                );
            })}
        </Stack>
    );
};
