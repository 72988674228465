import React from 'react';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { useConfirmDialog } from '../../../../../lib/modals/ConfirmDialog';
import { RoleFragment, useTOTPResetMutation } from '../../../../../interfaces/model';
import { useAppSnackbar } from '../../../../components/AppNotification';
import { useUserDetails } from '../../../../hooks/useUserDetails';
import { TOTPDialog, useTOTPDialog } from './TOTPDialog';

interface ITOTPSwitchProps {
    role: Pick<RoleFragment, 'id' | 'name' | 'totpActivated' | 'hasTotpResetAccess'> | undefined;
    onConfirm: () => void;
    disabled?: boolean;
}

export const TOTPSwitch: React.FC<ITOTPSwitchProps> = ({ role, onConfirm, disabled }) => {
    const snackBar = useAppSnackbar();
    const currentUser = useUserDetails();
    const [totpDialog] = useTOTPDialog();
    const [confirmDialog] = useConfirmDialog();
    const [resetTOTP] = useTOTPResetMutation();
    const selfEditing = role?.id && currentUser.role?.id === role?.id;
    const totpActivated = !!role?.totpActivated;

    const handleClick = () => {
        if (role?.totpActivated) {
            if (!role.hasTotpResetAccess) {
                confirmDialog.open({
                    title: 'Two-factor authentication cannot be disabled',
                    body: 'Two-factor authentication is mandatory for this user and cannot be switched off',
                });
                return;
            }

            const roleName = !selfEditing ? ` for "${role.name}"` : '';
            confirmDialog.open({
                title: 'Disable two-factor authentication',
                body: `You are about to switch off two-factor auth${roleName}, are you sure?`,
                onConfirm: async () => {
                    try {
                        const response = await resetTOTP({
                            variables: {
                                roleId: role.id,
                            },
                        });
                        if (response.data?.totpReset) {
                            snackBar.show({ message: `Two-factor auth has been switched off` });
                            onConfirm();
                        } else {
                            throw new Error('Unknown');
                        }
                    } catch (e) {
                        snackBar.showError({ message: `Failed to switch off two-factor auth${roleName}` }, e);
                    }
                },
            });
        } else {
            totpDialog.open();
        }
    };

    return (
        <Box>
            <Switch size={'small'} checked={totpActivated} onClick={handleClick} disabled={disabled} />
            <span className={'vertical-align-middle'}>{totpActivated ? 'Enabled' : 'Disabled'}</span>
            <TOTPDialog onConfirm={onConfirm} />
        </Box>
    );
};
