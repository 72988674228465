import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { reviewActionColors, reviewActionIcons, reviewActionLabels } from '../../Documents.const';
import { ReviewAction } from '../../../../../interfaces/model';
import styles from './KYCReviewActionChip.module.css';

interface IKYCReviewActionChipProps extends ChipProps {
    value: Maybe<ReviewAction>;
}

export const KYCReviewActionChip: React.FC<IKYCReviewActionChipProps> = ({ value, ...props }) => {
    const Icon = reviewActionIcons[value!];
    const color = reviewActionColors[value!];

    return value ? (
        <Chip
            size={'small'}
            variant={'outlined'}
            data-testid={'KYCReviewStatus'}
            {...props}
            avatar={Icon && <Icon className={styles.avatar} />}
            label={reviewActionLabels[value!]}
            color={color}
        />
    ) : null;
};
