import { TOTPRequired } from '../../../../../../interfaces/model';
import { selectOptionFactory } from '../../../../../../lib/inputs/Select.const';

export const totpRequiredLabels: Record<TOTPRequired, string> = {
    SERVER_DEFAULT: 'Default',
    YES: 'Yes',
    NO: 'No',
};

export const totpRequiredOptions = [TOTPRequired.SERVER_DEFAULT, TOTPRequired.YES, TOTPRequired.NO].map(
    selectOptionFactory(TOTPRequired, totpRequiredLabels)
);

export enum UserType {
    MEMBER = 'MEMBER',
    ADMIN = 'ADMIN',
}

export const userTypeLabels: Record<UserType, string> = {
    MEMBER: 'Member',
    ADMIN: 'Admin',
};

export const userTypeOptions = [UserType.MEMBER, UserType.ADMIN].map(selectOptionFactory(UserType, userTypeLabels));
