import React from 'react';
import Box from '@mui/material/Box';

export const AppHeaderSection: React.FC<{ className?: string; children?: React.ReactNode }> = ({
    children,
    className,
}) => {
    return (
        <Box className={className || ''} sx={{ borderTop: 'var(--border-dark) solid 1px' }}>
            {children}
        </Box>
    );
};
