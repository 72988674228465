import React from 'react';
import { prepareLegalEntitiesListItem } from './LegalEntitiesListRow/prepareLegalEntitiesListItem';
import { DivTableTheme } from '../../../../../../lib/dataDisplay/List/themes/DivTableTheme';
import { ILegalEntitiesListItem, LegalEntitiesListRow } from './LegalEntitiesListRow';
import { getEntityTypeTitle, ParentEntity } from '../LegalEntities.helpers';
import { IListColumn, List } from '../../../../../../lib/dataDisplay/List';
import { EntityType } from '../../../../../../interfaces/model';
import { NonFalsy } from '../../../../../../lib/utils/helpers';

interface ILegalEntitiesListProps {
    parentEntity: ParentEntity;
    testId?: string;
}

export const LegalEntitiesList: React.FC<ILegalEntitiesListProps> = ({ parentEntity, testId }) => {
    const rowsData = (parentEntity.childs || []).map(prepareLegalEntitiesListItem);
    const showKYCStatus = [
        EntityType.AGENT_TOP_LEVEL,
        EntityType.BORROWER_TOP_LEVEL,
        EntityType.LENDER_TOP_LEVEL,
        EntityType.LENDER_INVESTMENT_BANK,
        EntityType.LENDER_INVESTMENT_MANAGER,
    ].includes(parentEntity.entityType);
    const showLenderCols = [
        EntityType.LENDER_TOP_LEVEL,
        EntityType.LENDER_INVESTMENT_BANK,
        EntityType.LENDER_INVESTMENT_MANAGER,
    ].includes(parentEntity.entityType);
    const showLenderType = parentEntity.entityType === EntityType.LENDER_TOP_LEVEL;

    const COLUMNS: IListColumn<ILegalEntitiesListItem>[] = (
        [
            { id: 'name', label: getEntityTypeTitle(parentEntity) },
            { id: 'jurisdiction', label: 'Jurisdiction' },
            showLenderType && ({ id: 'lenderType', label: 'Type' } as const),
            showLenderCols && ({ id: 'sec', label: 'SEC' } as const),
            showLenderCols && ({ id: 'fca', label: 'FCA' } as const),
            showKYCStatus && ({ id: 'kyc', label: 'Agent KYC', nowrap: true } as const),
            { id: 'actions', label: 'Actions', sort: false, shrink: true },
        ] as const
    ).filter(NonFalsy);

    return (
        <DivTableTheme>
            <List
                testId={testId}
                data={rowsData}
                columns={COLUMNS}
                rowComponent={LegalEntitiesListRow}
                rowComponentProps={{ parentEntity }}
                pagination
                emptyStatePlaceholderProps={{
                    title: 'No legal entities added',
                }}
            />
        </DivTableTheme>
    );
};
