import * as yup from 'yup';
import { IDirectorsListRowItem } from '../DirectorSelectDialog/DirectorSelectList/DirectorsListRow';
import { Country, RoleInInput } from '../../../../../interfaces/model';
import { VALIDATION_MESSAGES } from '../../../../../lib/forms';
import { dateToModelValue, fromDateISO } from '../../../../../lib/utils/date';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

/**
 * Memoization is required to keep the same reference to date object. Don't remove
 */
export const useDirectorMinBirthday = () =>
    useMemo(() => {
        return DateTime.now().minus({ year: 18 }).toJSDate();
    }, []);

export const getInitialValues = (role: IDirectorsListRowItem | undefined, directorMinBirthday: Date) => {
    return {
        id: role?.id,
        birthday: fromDateISO(role?.birthday) || directorMinBirthday,
        nationality: role?.nationality,
        streetName: role?.address?.streetName,
        suite: role?.address?.suite,
        city: role?.address?.city,
        postcode: role?.address?.postcode,
        country: role?.address?.country,
    };
};

export const validationSchemaFactory = (minDate: Date) =>
    yup.object({
        id: yup.string(),
        birthday: yup
            .date()
            .min(minDate, VALIDATION_MESSAGES.MIN_DATE)
            .nullable()
            .required(VALIDATION_MESSAGES.REQUIRED),
        nationality: yup.mixed<Country>().nullable().required(VALIDATION_MESSAGES.REQUIRED),
        suite: yup.string().nullable(),
        country: yup.mixed<Country>().nullable().required(VALIDATION_MESSAGES.REQUIRED),
        city: yup.string().nullable().required(VALIDATION_MESSAGES.REQUIRED),
        streetName: yup.string().nullable().required(VALIDATION_MESSAGES.REQUIRED),
        postcode: yup.string().nullable().required(VALIDATION_MESSAGES.REQUIRED),
    });

export type IDirectorDetailsForm = yup.TypeOf<ReturnType<typeof validationSchemaFactory>>;

export const prepareRoleModelValue = (values: IDirectorDetailsForm): RoleInInput => {
    return {
        id: values.id,
        birthday: dateToModelValue(values.birthday || void 0),
        nationality: values.nationality,
        address: {
            suite: values.suite,
            country: values.country!,
            city: values.country!,
            streetName: values.streetName,
            postcode: values.postcode,
        },
    };
};
