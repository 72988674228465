import { Entity, EntityType } from '../../../../../interfaces/model';

export type ChildEntity = Pick<
    Entity,
    | 'id'
    | 'name'
    | 'shortCode'
    | 'jurisdiction'
    | 'kycComplete'
    | 'hasEditAccess'
    | 'entityType'
    | 'secRegistered'
    | 'fcaRegistered'
>;
export type ParentEntity = Pick<Entity, 'id' | 'topCoRef' | 'entityType' | 'shortCode'> & {
    childs: Maybe<ChildEntity[]>;
    caps: {
        createSubsidiary: boolean;
        createUBO: boolean;
        createAgent: boolean;
        createInvestmentBank: boolean;
        createInvestmentManager: boolean;
        createSponsor: boolean;
        createFundadmin: boolean;
    };
};

export const getEntityTitleByType = (entityType: EntityType | undefined, props?: { plural?: boolean }): string => {
    switch (entityType) {
        case EntityType.AGENT_TOP_LEVEL:
        case EntityType.BORROWER_TOP_LEVEL:
        case EntityType.SPONSOR_TOP_LEVEL:
        case EntityType.FUNDADMIN_TOP_LEVEL:
            return props?.plural ? 'Subsidiaries' : 'Subsidiary';
        case EntityType.AGENT:
        case EntityType.BORROWER_SUBSIDIARY:
        case EntityType.LENDER_INVESTMENT_BANK:
        case EntityType.LENDER_INVESTMENT_MANAGER:
            return props?.plural ? "UBO's" : 'UBO';
        case EntityType.LENDER_TOP_LEVEL:
            return props?.plural ? 'Legal entities' : 'Entity';
    }
    return '';
};

export const getEntityTypeTitle = (entity: ParentEntity): string => {
    switch (entity.entityType) {
        case EntityType.AGENT_TOP_LEVEL:
        case EntityType.SPONSOR_TOP_LEVEL:
        case EntityType.BORROWER_TOP_LEVEL:
        case EntityType.FUNDADMIN_TOP_LEVEL:
            return 'Subsidiary name';
        case EntityType.LENDER_TOP_LEVEL:
            return 'Legal Entity name';
    }
    return '';
};

export const getChildEntityType = (parentEntityType: EntityType | undefined): EntityType => {
    switch (parentEntityType) {
        case EntityType.AGENT_TOP_LEVEL:
            return EntityType.AGENT;
        case EntityType.BORROWER_TOP_LEVEL:
            return EntityType.BORROWER_SUBSIDIARY;
        case EntityType.AGENT:
        case EntityType.BORROWER_SUBSIDIARY:
        case EntityType.LENDER_INVESTMENT_BANK:
        case EntityType.LENDER_INVESTMENT_MANAGER:
            return EntityType.UBO;
        case EntityType.LENDER_TOP_LEVEL:
            return EntityType.LENDER_INVESTMENT_MANAGER;
        case EntityType.SPONSOR_TOP_LEVEL:
            return EntityType.SPONSOR;
        case EntityType.FUNDADMIN_TOP_LEVEL:
            return EntityType.FUNDADMIN;
    }
    throw new Error(`Unhandled entity type ${parentEntityType}`);
};
