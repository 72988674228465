import React from 'react';
import { Button } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { prepareFundsEmbeddedListItem } from './FundsEmbeddedListRow/prepareFundsEmbeddedListItem';
import { CreateFundWizard, useFundWizardDialog } from '../CreateFundWizard/CreateFundWizard';
import { FundsEmbeddedListRow, IFundsEmbeddedListItem } from './FundsEmbeddedListRow';
import { EditFundKeyInformationDialog } from '../EditFundKeyInformationDialog';
import { AppEmbeddedListWrap } from '../../../components/AppEmbeddedListWrap';
import { DivTableTheme } from 'lib/dataDisplay/List/themes/DivTableTheme';
import { FundsEmbeddedListRowFragment } from 'interfaces/model';
import { IListColumn, List } from 'lib/dataDisplay/List';

interface IFundsEmbeddedProps {
    funds: FundsEmbeddedListRowFragment[];
    reloadData: () => void;
    canCreate?: boolean;
    topCoRef: UUID;
    entityRef: UUID | null;
    className?: string;
    testId?: string;
}

const COLUMNS: IListColumn<IFundsEmbeddedListItem>[] = [
    { id: 'name', label: 'Fund name' },
    { id: 'jurisdiction', label: 'Jurisdiction' },
    { id: 'type', label: 'Fund type' },
    { id: 'parLoansOnly', label: 'Permissioned lender Filter' },
    { id: 'kycComplete', label: 'Agent KYC', shrink: true, nowrap: true },
    { id: 'actions', label: 'Actions', sort: false, shrink: true },
];

export const FundsEmbeddedList: React.FC<IFundsEmbeddedProps> = ({
    funds,
    canCreate,
    reloadData,
    topCoRef,
    entityRef,
    className,
    testId,
}) => {
    const history = useHistory();
    const location = useLocation();
    const [{ open: openFundEditDialog }] = useFundWizardDialog();
    const actions = canCreate && (
        <Button
            color={'primary'}
            variant={'contained'}
            onClick={() =>
                openFundEditDialog({
                    topCoRef,
                    entityRef,
                })
            }
            data-testid={`${testId}:addButton`}
        >
            Add Fund
        </Button>
    );

    return (
        <AppEmbeddedListWrap title={'Funds'} actions={actions} className={className}>
            <DivTableTheme>
                <List
                    testId={testId}
                    data={funds.map(prepareFundsEmbeddedListItem)}
                    columns={COLUMNS}
                    rowComponent={FundsEmbeddedListRow}
                    rowComponentProps={{ reloadData }}
                    pagination
                    defaultSortBy={'name'}
                    emptyStatePlaceholderProps={{
                        title: 'No funds added',
                    }}
                />
            </DivTableTheme>
            <CreateFundWizard onSaveSuccess={reloadData} />
            <EditFundKeyInformationDialog onClose={() => history.replace(location.pathname)} />
        </AppEmbeddedListWrap>
    );
};
