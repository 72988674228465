import React from 'react';
import cl from 'classnames';
import { getName } from 'country-list';
import { JurisdictionIcon } from './JurisdictionIcon';
import styles from './CountryFlagAndName.module.css';

interface ICountryFlagAndNameProps {
    country: string;
    showFullName?: boolean;
    className?: string;
    nowrap?: boolean;
}

const CUSTOM_COUNTRY_NAMES: Record<string, string> = {
    GB: 'United Kingdom',
    US: 'United States',
};

export const getCountryNameByCode = (countryCode: string) => {
    return CUSTOM_COUNTRY_NAMES[countryCode] || getName(countryCode);
};

export const CountryFlagAndName: React.FC<ICountryFlagAndNameProps> = ({
    country,
    nowrap,
    showFullName,
    className,
}) => {
    const countryName = country && getCountryNameByCode(country);
    const text = showFullName && countryName ? countryName : country;

    return (
        <span title={countryName} className={cl(nowrap ? 'white-space-nowrap' : 'white-space-break-spaces', className)}>
            <JurisdictionIcon value={country} />
            <span className={styles.countryNameText}>{text}</span>
        </span>
    );
};
