import React from 'react';
import cl from 'classnames';
import IconButton from '@mui/material/IconButton';
import { SvgIconComponent } from '@mui/icons-material';
import { IconButtonProps, Tooltip } from '@mui/material';
import { NOOP } from '../../../utils/helpers';

export interface IListIconButtonProps extends Omit<IconButtonProps, 'title'> {
    icon: SvgIconComponent;
    title?: React.ReactNode;
    component?: React.ElementType;
    disableHoverListener?: boolean;
    to?: string;
}

export const ListIconButton: React.FC<IListIconButtonProps> = ({
    className,
    icon: Icon,
    title,
    disableHoverListener,
    onClick = NOOP,
    ...props
}) => {
    return (
        <Tooltip
            title={title || ''}
            placement={'bottom'}
            disableHoverListener={disableHoverListener}
            disableInteractive
            arrow
        >
            <div>
                <IconButton size={'small'} color={'secondary'} onClick={onClick} className={cl(className)} {...props}>
                    <Icon fontSize={'inherit'} />
                </IconButton>
            </div>
        </Tooltip>
    );
};
