export enum PeopleListStatus {
    COMPLETE = 'COMPLETE',
    INCOMPLETE = 'INCOMPLETE',
}

export const peopleListLabels: Record<PeopleListStatus, string> = {
    COMPLETE: 'Complete',
    INCOMPLETE: 'Incomplete',
};

export const peopleListColors: Record<PeopleListStatus, Colors> = {
    COMPLETE: 'success',
    INCOMPLETE: 'error',
};
