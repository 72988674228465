import React from 'react';
import Paper from '@mui/material/Paper';
import { DocumentReviewersListRow, IDocumentReviewersListRowItem } from './DocumentReviewersListRow';
import { DocumentReviewStatusV2Fragment } from '../../../../../../../../../interfaces/model';
import { IListColumn, List } from '../../../../../../../../../lib/dataDisplay/List';

interface IDocumentReviewersListProps {
    value: DocumentReviewStatusV2Fragment[];
}

const COLUMNS: IListColumn<IDocumentReviewersListRowItem>[] = [
    { id: 'agentName', label: 'Agent' },
    { id: 'userName', label: 'User' },
    { id: 'status', label: 'Status', shrink: true },
    { id: 'reviewType', shrink: true },
    { id: 'timestamp', label: 'Last updated' },
    { id: 'comment', label: 'Comment' },
];

const PAPER_BG = 'var(--bg-default)';

const prepareData = (value: DocumentReviewStatusV2Fragment[]): IDocumentReviewersListRowItem[] => {
    return value
        .map((item) => {
            return {
                ...item,
                agentName: item.entity?.name || 'Unknown',
                agentSc: item.entity?.shortCode || '',
                userName: item.review?.role?.name || 'Unknown',
                userSc: item.review?.role?.shortCode || '',
                timestamp: item.review?.timestamp,
                comment: item.review?.comment || '',
                reviewType: item.review?.type,
            };
        })
        .sort((a, b) => {
            if (a.timestamp && b.timestamp) {
                return b.timestamp - a.timestamp;
            }
            return 0;
        });
};

export const DocumentReviewersList: React.FC<IDocumentReviewersListProps> = ({ value }) => {
    return (
        <Paper elevation={0} sx={{ background: PAPER_BG, padding: '16px' }}>
            <List
                data={prepareData(value)}
                columns={COLUMNS}
                rowComponent={DocumentReviewersListRow}
                emptyStatePlaceholderProps={{
                    title: 'No agents',
                    imageName: 'validation',
                    sx: { background: PAPER_BG },
                }}
            />
        </Paper>
    );
};
