import { Switch } from 'react-router';
import Box from '@mui/material/Box';
import React, { createRef, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { NotificationsBell } from './components/NotificationsBell/NotificationsBell';
import { AppHeaderContainer } from './components/AppHeader/AppHeaderContainer';
import { ProfileButton } from './components/AppHeader/ProfileButton';
import { PageHeadingPortal } from './components/PageHeading';
import { AppScrollableAreaRefContext } from './App.const';
import { APP_INITIALIZED_SELECTOR } from './App.selectors';
import { DataRoomLink } from './components/DataRoomLink';
import { AppContainer } from '../lib/cdk/AppContainer';
import { AppHeader } from './components/AppHeader';
import styles from './App.module.css';
import { Body } from './Body';
import { useAppTracker } from './hooks/useAppTracker';
import { Route } from 'react-router-dom';
import * as ROUTES from 'App/App.routes';
import { AppSpinner } from 'App/components/AppSpinner';

const SupportBlogFrame = React.lazy(() => import('./pages/Support'));

export const SCROLLABLE_AREA_ID = 'LC_APP_SCROLLABLE_AREA';

export const App = () => {
    const isAppLoaded = useSelector(APP_INITIALIZED_SELECTOR);
    const scrollableAreaRef = createRef<HTMLDivElement>();
    useAppTracker();

    if (!isAppLoaded) {
        // TODO show loading state
        return null;
    }

    return (
        <>
            <AppHeaderContainer className={styles.header}>
                <AppHeader>
                    <DataRoomLink />
                    <NotificationsBell />
                    <Box paddingLeft={'8px'}>
                        <ProfileButton />
                    </Box>
                </AppHeader>
            </AppHeaderContainer>
            <Suspense fallback={<AppSpinner backdropProps={{ invisible: true }} show />}>
                <Switch>
                    <Route path={ROUTES.SUPPORT_ROUTE} component={SupportBlogFrame} />
                    <Route path={'*'}>
                        <AppContainer className={styles.scrollableArea} ref={scrollableAreaRef} id={SCROLLABLE_AREA_ID}>
                            <AppScrollableAreaRefContext.Provider value={scrollableAreaRef}>
                                <Box className={styles.pageHeadingContainer}>
                                    <PageHeadingPortal className={styles.pageHeading} />
                                </Box>
                                <Body />
                            </AppScrollableAreaRefContext.Provider>
                        </AppContainer>
                    </Route>
                </Switch>
            </Suspense>
        </>
    );
};
