import * as yup from 'yup';
import { VALIDATION_MESSAGES } from '../../../../../lib/forms';
import { selectOptionFactory } from '../../../../../lib/inputs/Select.const';
import { NAReason } from '../../../../../interfaces/model';

export const naReasonLabels: Record<NAReason, string> = {
    NOT_AVAILABLE: 'Document is not available',
    NOT_RELEVANT: 'Document is not relevant for the company',
    OTHER: 'Other reason',
};

export const reasonDropdownOptions = [NAReason.NOT_AVAILABLE, NAReason.NOT_RELEVANT, NAReason.OTHER].map(
    selectOptionFactory(NAReason, naReasonLabels)
);

export const validationSchema = yup.object({
    reason: yup.mixed<NAReason>().required(VALIDATION_MESSAGES.REQUIRED),
    comment: yup.string().when('reason', {
        is: NAReason.OTHER,
        then: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    }),
});

export type IEditForm = yup.TypeOf<typeof validationSchema>;
