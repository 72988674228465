import React from 'react';
import { List } from '../../../../../../../../lib/dataDisplay/List/List';
import { FundsSetupListRow, IFundsSetupListRowItem } from './FundsSetupListRow';
import { useFundsListQuery } from '../../../../../../../../interfaces/model';
import { IListColumn } from '../../../../../../../../lib/dataDisplay/List';
import { useFundWizardDialog } from '../../../../../../Funds/CreateFundWizard/CreateFundWizard';
import { DivTableTheme } from '../../../../../../../../lib/dataDisplay/List/themes/DivTableTheme';
import { voidWrapper } from '../../../../../../../../lib/utils/helpers';

const COLUMNS: IListColumn<IFundsSetupListRowItem>[] = [
    { id: 'name', label: 'Name' },
    { id: 'type', label: 'Type' },
    { id: 'jurisdiction', label: 'Jurisdiction' },
    { id: 'actions', label: 'Actions', shrink: true, align: 'center' },
];

interface IFundsSetupListProps {
    query: ReturnType<typeof useFundsListQuery>;
    noPreview?: boolean;
}

export const FundsSetupList: React.FC<IFundsSetupListProps> = ({ query, noPreview }) => {
    const [{ open: openFundEditDialog }] = useFundWizardDialog();
    const { data, loading, error, refetch } = query;

    return (
        <DivTableTheme>
            <List
                data={data?.loanfunds.data || []}
                columns={COLUMNS}
                defaultSortBy={'name'}
                rowComponent={FundsSetupListRow}
                rowComponentProps={{ openFundEditDialog, noPreview }}
                emptyStatePlaceholderProps={{
                    title: 'Please add the funds you want to onboard',
                }}
                onRetry={voidWrapper(refetch)}
                loading={loading}
                error={error}
            />
        </DivTableTheme>
    );
};
