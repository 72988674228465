import React from 'react';
import groupBy from 'lodash/groupBy';
import { DocumentInInput, KYCCompleteStatus, KYCDocumentFragment, ReviewStatus } from '../../../../../interfaces/model';
import { DocumentCategoryListRow, IDocumentCategoryListRowItem } from './DocumentCategoryListRow';
import { getDocumentApprovalCounters, isDocumentNotApplicable } from '../../Documents.const';
import { IDocumentsListParent } from '../../interfaces/IDocumentsListParent';
import { IEmptyStatePlaceholderProps } from '../../../../common/EmptyStatePlaceholder';
import { IListColumn, List } from '../../../../../lib/dataDisplay/List';
import { getLatestDocument } from '../../components/getLatestDocument';
import { NonFalsy } from '../../../../../lib/utils/helpers';
import { KYCStatus } from './DocumentCategoryList.const';

interface IDocumentCategoryListProps {
    data: KYCDocumentFragment[];
    parent: IDocumentsListParent;
    columns?: IListColumn<IDocumentCategoryListRowItem>[];
    emptyStatePlaceholderProps?: IEmptyStatePlaceholderProps;
    onEditSubmit?: (document: DocumentInInput) => Promise<void>;
    showKycColumns?: boolean;
    embedded?: boolean;
}

const getKycStatus = (
    status: {
        status: ReviewStatus;
    } | null,
    uploaded: boolean
): KYCStatus | undefined => {
    if (!uploaded) {
        return KYCStatus.PENDING_UPLOAD;
    }

    if (status?.status === ReviewStatus.NO_REVIEWERS) {
        return KYCStatus.NO_REVIEWERS;
    }

    if (status?.status === ReviewStatus.UNDER_REVIEW) {
        return KYCStatus.PENDING_REVIEW;
    }

    if (status?.status === ReviewStatus.RED) {
        return KYCStatus.REJECTED;
    }

    if (status?.status === ReviewStatus.GREEN) {
        return KYCStatus.COMPLETE;
    }
};

const prepareData = (data: KYCDocumentFragment[]): IDocumentCategoryListRowItem[] => {
    return Object.values(groupBy(data, 'docType')).map((group) => {
        const document = getLatestDocument(group);
        const status = document.status;
        const user = document.role;

        return {
            ...getDocumentApprovalCounters(status),
            id: document.id + document.docType,
            docType: document.docType,
            status: getKycStatus(status, document.uploaded),
            kycComplete: document.kycComplete === KYCCompleteStatus.COMPLETE,
            isNotApplicable: isDocumentNotApplicable(document),
            userShortCode: user?.shortCode,
            createdAt: document.createdAt,
            uploaded: document.uploaded,
            userName: user?.name,
            document,
        };
    });
};

const getColumns = (config: { showKycColumns: boolean; hasReviewers: boolean; displayCounters: boolean }) => {
    const { showKycColumns, displayCounters, hasReviewers } = config;
    return (
        [
            { id: 'docType', label: 'Document' },
            { id: 'createdAt', label: 'Uploaded' },
            { id: 'userName', label: 'User' },
            showKycColumns && hasReviewers && ({ id: 'kycComplete', label: 'Agent KYC', sort: false } as const),
            showKycColumns && ({ id: 'status', label: 'Review status', shrink: true } as const),
            showKycColumns && displayCounters && ({ id: 'agentsApproved', label: 'Approved', shrink: true } as const),
            showKycColumns && displayCounters && ({ id: 'agentsPending', label: 'Pending', shrink: true } as const),
            showKycColumns && displayCounters && ({ id: 'agentsRejected', label: 'Rejected', shrink: true } as const),
            { id: 'addViewAction', label: 'Actions', shrink: true, sort: false },
        ] as const
    ).filter(NonFalsy);
};

export const DocumentCategoryList: React.FC<IDocumentCategoryListProps> = ({
    data,
    parent,
    columns: externalColumns,
    emptyStatePlaceholderProps,
    showKycColumns,
    onEditSubmit,
    embedded,
}) => {
    const isKycAvailable = data[0]?.kycComplete !== null && data[0]?.status !== null;
    const dataPrepared = prepareData(data);
    const firstItemStatus = dataPrepared[0]?.document.status;
    const counters = getDocumentApprovalCounters(firstItemStatus);
    const hasReviewers = firstItemStatus?.status !== ReviewStatus.NO_REVIEWERS;
    const displayCounters = counters.total > 0;

    const columns: IListColumn<IDocumentCategoryListRowItem>[] =
        externalColumns ||
        getColumns({
            hasReviewers,
            displayCounters,
            showKycColumns: !!showKycColumns && isKycAvailable,
        });
    return (
        <List
            columns={columns}
            rowComponent={DocumentCategoryListRow}
            emptyStatePlaceholderProps={emptyStatePlaceholderProps}
            rowComponentProps={{ parent, updateData: parent.update, kycMode: !!showKycColumns, onEditSubmit }}
            data={dataPrepared}
            embedded={embedded}
        />
    );
};
