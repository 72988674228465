import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

const serializeQueryParameter = <T>(key: keyof T, value: T[keyof T]): string => {
    if (Array.isArray(value)) {
        return value.map((param) => `${String(key)}=${encodeURIComponent(String(param))}`).join('&');
    }
    return `${String(key)}=${encodeURIComponent(String(value))}`;
};

export const joinQueryParams = <T extends object = Dict<number | boolean | string | string[] | undefined>>(
    queryParams: T = {} as T
) => {
    if (isEmpty(queryParams)) {
        return '';
    }

    return Object.keys(queryParams).reduce((acc, key) => {
        if (isNil(queryParams[key as keyof T])) {
            return acc;
        }
        const paramSerialized = serializeQueryParameter(key as keyof T, queryParams[key as keyof T]);
        return `${acc}${acc ? '&' : '?'}${paramSerialized}`;
    }, '');
};
