import React, { useState } from 'react';
import { ActionsCell, DateValueCell, LinkCell } from '../../../../../../../lib/dataDisplay/List/components/ListCells';
import { DoubleValueCell } from '../../../../../../../lib/dataDisplay/List/components/ListCells/DoubleValueCell';
import { DocumentRestoreIconButton } from '../../../DocumentRestoreIconButton/DocumentRestoreIconButton';
import { DeleteType, KYCDocumentFragment } from '../../../../../../../interfaces/model';
import { kycDocSubcategoryLabels, kycDocTypeLabels } from '../../../../../../App.const';
import { ListRow } from '../../../../../../../lib/dataDisplay/List/components/ListRow';
import { CollapseTrigger } from '../../../../../../../lib/buttons/CollapseTrigger';
import { DocumentDownloadIconButton } from '../../../DocumentDownloadIconButton';
import { CollapsibleRow } from '../../../../../../../lib/legacy/CollapsibleRow';
import { DocumentDeleteIconButton } from '../../../DocumentDeleteIconButton';
import { IGqlListRow } from '../../../../../../../lib/dataDisplay/List';
import { getModelViewRoute, ROLE_VIEW_ROUTE_FACTORY } from '../../../../../../App.routes';
import { DocumentFileIconCell } from '../../../DocumentFileIconCell';
import { DocumentNameCell } from '../../../DocumentNameCell';
import { IDocumentViewDialogParent } from '../../interfaces';
import { useParentContext } from '../../../../../../../lib/cdk/ParentContext';
import { getModelByIdType } from '../../../../../../helpers/getModelByIdType';

export interface IDeletedDocumentsListRowItem extends KYCDocumentFragment {
    actions?: never;
}

export type IDeletedDocumentsListRowDetailsComponent = React.FC<{
    value: KYCDocumentFragment;
    updateData: () => Promise<void>;
    parent: IDocumentViewDialogParent | undefined;
}>;

type IDeletedDocumentsListRowProps = IGqlListRow<
    IDeletedDocumentsListRowItem,
    {
        updateData: () => Promise<void>;
        parent: IDocumentViewDialogParent | undefined;
        onDeleteSuccess: () => Promise<void>;
        onRestoreSuccess: () => Promise<void>;
        RowDetailsComponent: IDeletedDocumentsListRowDetailsComponent;
        focusedItemId: UUID | undefined;
    }
>;

export const DeletedDocumentsListRow: React.FC<IDeletedDocumentsListRowProps> = ({
    item,
    columns,
    props,
    highlightText,
    isFirst,
    isLast,
}) => {
    const singleFileMode = isFirst && isLast;
    const [isExpanded, setExpanded] = useState(singleFileMode || item.id === props.focusedItemId);
    const toggleExpanded = () => setExpanded(!isExpanded);
    const parent = getModelByIdType(item.parent) || void 0;
    const { RowDetailsComponent } = props;
    const parentContext = useParentContext();

    return (
        <>
            <ListRow focused={isExpanded}>
                {columns.map(({ id }) => (
                    <React.Fragment key={id}>
                        {id === 'id' && <DocumentFileIconCell />}
                        {id === 'name' && <DocumentNameCell document={item} highlightText={highlightText} />}
                        {id === 'docType' && (
                            <DoubleValueCell
                                main={kycDocTypeLabels[item.docType!]}
                                alternative={kycDocSubcategoryLabels[item.docSubcategory!]}
                                nowrap={false}
                                width={'25%'}
                            />
                        )}
                        {id === 'parent' && (
                            <LinkCell to={getModelViewRoute(item.parent)(parent?.shortCode!)} width={'25%'}>
                                {parent?.name}
                            </LinkCell>
                        )}
                        {id === 'deletedAt' && <DateValueCell value={item.deletedAt} />}
                        {id === 'deletedBy' && (
                            <LinkCell to={ROLE_VIEW_ROUTE_FACTORY(item.deletedBy?.shortCode!)}>
                                {item.deletedBy?.name}
                            </LinkCell>
                        )}
                        {id === 'actions' && (
                            <ActionsCell>
                                <DocumentRestoreIconButton
                                    document={item}
                                    updateData={async () => {
                                        await props.onRestoreSuccess();
                                        await parentContext.update();
                                        setExpanded(false);
                                    }}
                                />
                                <DocumentDownloadIconButton documentId={item.id} />
                                <DocumentDeleteIconButton
                                    document={item}
                                    mode={DeleteType.PERMANENT}
                                    onDeleteSuccess={async () => {
                                        await props.onDeleteSuccess();
                                        await parentContext.update();
                                        setExpanded(false);
                                    }}
                                />
                                {!singleFileMode && (
                                    <CollapseTrigger size={'small'} isActive={isExpanded} onClick={toggleExpanded} />
                                )}
                            </ActionsCell>
                        )}
                    </React.Fragment>
                ))}
            </ListRow>
            <CollapsibleRow expand={isExpanded} colspan={columns.length} dark>
                <RowDetailsComponent value={item} parent={props.parent} updateData={props.updateData} />
            </CollapsibleRow>
        </>
    );
};
