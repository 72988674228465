import React from 'react';
import cl from 'classnames';
import { ApolloError } from '@apollo/client';
import { GenericErrorScreen } from './GenericErrorScreen';
import { NetworkErrorScreen } from './NetworkErrorScreen';
import { GQLErrorScreen } from './GQLErrorScreen';
import { BoxProps, Button } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import styles from './DataErrorPlaceholder.module.css';
import Box from '@mui/material/Box';
import { APIError } from '../../hooks/useLazyFetch';

export interface IDataErrorPlaceholderRowProps extends BoxProps {
    error: ApolloError | APIError | undefined;
    onRetry?: () => void;
}

const isApolloError = (error: Error | undefined): error is ApolloError => error instanceof ApolloError;

export const DataErrorPlaceholder: React.FC<IDataErrorPlaceholderRowProps> = ({
    onRetry,
    error,
    className,
    ...props
}) => {
    const isNetworkError = isApolloError(error) && !!error.networkError?.message;
    const isGQLError = isApolloError(error) && (error.graphQLErrors || []).length > 0;
    const isGenericError = !isNetworkError && !isGQLError && error?.message;

    if (!error) {
        return null;
    }

    return (
        <Box className={cl(className, styles.wrap)} {...props}>
            {isGenericError && <GenericErrorScreen text={error?.message} />}
            {isNetworkError && <NetworkErrorScreen text={error?.networkError?.message} />}
            {isGQLError && <GQLErrorScreen errors={error?.graphQLErrors.concat() || []} />}
            {(isGenericError || isNetworkError) && onRetry && (
                <Button variant={'outlined'} onClick={onRetry} className={styles.retryButton}>
                    <span className={'mr-8'}>Retry</span>
                    <ReplayIcon className={styles.retryButtonIcon} />
                </Button>
            )}
        </Box>
    );
};
