import * as yup from 'yup';
import {
    BankAccountInput,
    Country,
    Document,
    DocumentInInput,
    EntityInInput,
    EntityKeyInformationKYCFragment,
    EntityType,
    KYCCompleteStatus,
    KYCDocType,
    TaxIdFragment,
} from '../../../../../interfaces/model';
import { VALIDATION_MESSAGES, yupArrayTypeFix } from '../../../../../lib/forms';
import { addressSchema } from '../../../../../lib/forms/addressSchema';
import { prepareEmailDomainsModelValue } from '../../Entities.helpers';
import {
    ITaxIdEntityFundEdit,
    removeExtraTaxIdModelFields,
} from '../../../../common/TaxIds/TaxIdsList/TaxIdEditDialog/TaxIdEditDialog.const';
import { EntityKeyInformationFragment } from '../../ViewEntity/EditEntityKeyInformationDialog/EntityKeyInformation.query';

export type IEntityEdit = Pick<
    EntityKeyInformationFragment,
    | 'id'
    | 'shortCode'
    | 'dttp'
    | 'giin'
    | 'nif'
    | 'lei'
    | 'name'
    | 'otot'
    | 'sponsorRefs'
    | 'emailDomains'
    | 'authorizedSignatory'
    | 'incorporationNumber'
    | 'settlementLeadTime'
    | 'headquarters'
    | 'jurisdiction'
    | 'entityType'
    | 'parentRef'
    | 'topCoRef'
    | 'secRegistered'
    | 'fcaRegistered'
    | 'address'
    | 'bankAccounts'
    | 'businessAddress'
> & {
    taxIds?: Maybe<TaxIdFragment[]>;
    gics?: Maybe<{ level: number; code: string; parents: { level: number; code: string }[] }>;
    kycs?: Maybe<EntityKeyInformationKYCFragment[]> | null;
    roles?: Maybe<{ id: UUID; entityRelations: Maybe<{ entityId: UUID; director: boolean; enabled: boolean }[]> }[]>;
};

const GICS_INDUSTRY_LEVEL = 3;

const getEntityKycsMap = (entity: IEntityEdit | undefined) => {
    return Object.fromEntries(
        entity?.kycs?.filter(({ uploaded }) => uploaded).map((document) => [document.docType, document.documents]) || []
    );
};

export const getEntityFilesFromRequirements = (
    entity: IEntityEdit | undefined,
    kycRequirements: { type: KYCDocType }[]
): Partial<Record<KYCDocType, File[]>> => {
    const entityKycMap = getEntityKycsMap(entity);
    const entityFilesEntries = kycRequirements
        .map(({ type }) => {
            const documents = entityKycMap[type] || [];
            return [
                type,
                documents.map(({ displayName }) => {
                    return new File([], displayName || 'unknown file');
                }) || [],
            ];
        })
        .filter(({ length }) => length > 0);

    return Object.fromEntries(entityFilesEntries);
};

export const getEntityInitialValues = ({
    entity,
    entityType,
    parentRef,
    topCoRef,
}: {
    entity?: IEntityEdit;
    parentRef?: string;
    topCoRef?: string;
    entityType?: EntityType;
}) => {
    const GICSIndustry = entity?.gics?.parents.find((item) => item.level === GICS_INDUSTRY_LEVEL);
    return {
        ...entity,
        sponsorRefs: entity?.sponsorRefs || [],
        emailDomains: entity?.emailDomains || [],
        authorizedSignatory: entity?.authorizedSignatory || [],
        settlementLeadTime: entity?.settlementLeadTime || void 0,
        headquarters: entity?.headquarters || void 0,
        incorporationNumber: entity?.incorporationNumber || void 0,
        jurisdiction: entity?.jurisdiction || void 0,
        entityType: entity?.entityType || entityType!,
        parentRef: entity?.parentRef || parentRef!,
        topCoRef: entity?.topCoRef || topCoRef!,
        secRegistered: entity?.secRegistered || false,
        fcaRegistered: entity?.fcaRegistered || false,
        address: entity?.address || void 0,
        businessAddress: entity?.businessAddress || void 0,
        taxIds: entity?.taxIds as ITaxIdEntityFundEdit[],
        bankAccounts: entity?.bankAccounts,
        showBusinessAddress: !!entity?.businessAddress,
        subIndustry: entity?.gics?.code,
        mainIndustry: GICSIndustry?.code,
        documents: {} as Partial<Record<KYCDocType, DocumentInInput>>,
        directors:
            entity?.roles
                ?.filter((role) => {
                    const relations =
                        role.entityRelations?.filter(
                            ({ entityId, director, enabled }) => entityId === entity.id && director && enabled
                        ) || [];
                    return relations.length > 0;
                })
                .map(({ id }) => id) || ([] as UUID[]),
        taxIdsDisclaimerChecked: !!entity?.id,
        agentsConsent: false,
        currentStep: 0,
    };
};

export type IEntityInitialForm = Omit<ReturnType<typeof getEntityInitialValues>, 'currentStep'>;

export const prepareEntityModelValue = (entity: IEntityInitialForm): EntityInInput => ({
    id: entity.id,
    sponsorRefs: entity.sponsorRefs,
    authorizedSignatory: entity.authorizedSignatory,
    dttp: entity.dttp,
    entityType: entity.entityType,
    incorporationNumber: entity.incorporationNumber,
    fcaRegistered: entity.fcaRegistered,
    giin: entity.giin,
    headquarters: entity.headquarters,
    jurisdiction: entity.jurisdiction,
    lei: entity.lei,
    name: entity.name,
    emailDomains: prepareEmailDomainsModelValue(entity?.emailDomains),
    nif: entity.nif,
    otot: entity.otot,
    parentRef: entity.parentRef,
    bankAccounts: entity.bankAccounts,
    secRegistered: entity.secRegistered,
    settlementLeadTime: entity.settlementLeadTime,
    address: entity.address?.country && entity.address?.city ? entity.address : null,
    businessAddress: entity.businessAddress?.country && entity.businessAddress?.city ? entity.businessAddress : null,
    taxIds: entity.taxIds
        ?.filter(({ id, country, taxIdValue }) => country && taxIdValue && id)
        .map(removeExtraTaxIdModelFields),
    gicsCode: entity?.subIndustry,
});

const ENTITY_TYPES_WITH_ADDRESSES = [
    EntityType.LENDER_INVESTMENT_BANK,
    EntityType.LENDER_INVESTMENT_MANAGER,
    EntityType.SPONSOR,
    EntityType.FUNDADMIN,
    EntityType.AGENT,
    EntityType.BORROWER_SUBSIDIARY,
];

export const addressValidationSchema = yup.object().when('entityType', {
    is: (type: EntityType) => ENTITY_TYPES_WITH_ADDRESSES.includes(type),
    then: addressSchema.nullable().required(VALIDATION_MESSAGES.REQUIRED),
});

export const validationSchemaFactory = () => {
    return yup.object({
        id: yup.string(),
        shortCode: yup.string(),
        name: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
        lei: yup.string().nullable(),
        nif: yup.string().nullable(),
        dttp: yup.string().nullable(),
        giin: yup.string().nullable(),
        otot: yup.string().nullable(),
        parentRef: yup.string(),
        topCoRef: yup.string(),
        headquarters: yup.string().nullable(),
        jurisdiction: yup.string(),
        incorporationNumber: yup.string().nullable(),
        settlementLeadTime: yup.number(),
        entityType: yup.mixed<EntityType>().required(VALIDATION_MESSAGES.REQUIRED),
        fcaRegistered: yup.boolean(),
        secRegistered: yup.boolean(),
        showBusinessAddress: yup.boolean(),
        address: addressValidationSchema,
        businessAddress: yup
            .object()
            .nullable()
            .when(['showBusinessAddress', 'entityType'], {
                is: (showBusinessAddress: boolean, entityType: EntityType) =>
                    showBusinessAddress && ENTITY_TYPES_WITH_ADDRESSES.includes(entityType),
                then: addressSchema.nullable().required(VALIDATION_MESSAGES.REQUIRED),
            }),
        directors: yup.array().of(yupArrayTypeFix(yup.string())),
        emailDomains: yup.array().of(yupArrayTypeFix(yup.string())),
        sponsorRefs: yup.array().of(yupArrayTypeFix(yup.string())),
        authorizedSignatory: yup.array().of(yupArrayTypeFix(yup.string())),
        mainIndustry: yup.string().nullable(),
        subIndustry: yup.string().nullable(),
        taxIds: yup.array().when('currentStep', {
            is: (currentStep: number) => currentStep > 0,
            then: yup.array().of(
                yup.object({
                    country: yup.mixed<Country>().required(VALIDATION_MESSAGES.REQUIRED),
                    taxIdValue: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
                    taxIdFiles: yup.array(yupArrayTypeFix(yup.mixed<File>())).when('kyc', {
                        is: (kyc: Document) => !kyc,
                        then: yup
                            .array(yupArrayTypeFix(yup.mixed<File>()))
                            .min(1, VALIDATION_MESSAGES.FILE_REQUIRED)
                            .required(VALIDATION_MESSAGES.FILE_REQUIRED),
                    }),
                    validFromDate: yup
                        .date()
                        .nullable()
                        .when('kyc', {
                            is: (kyc: Document) => !kyc,
                            then: yup.date().nullable().required(VALIDATION_MESSAGES.REQUIRED),
                        }),
                    validToDate: yup
                        .date()
                        .nullable()
                        .when('kyc', {
                            is: (kyc: Document) => !kyc,
                            then: yup.date().nullable().required(VALIDATION_MESSAGES.REQUIRED),
                        }),
                })
            ),
        }),
        taxIdsDisclaimerChecked: yup.boolean().when(['currentStep', 'taxIds'], {
            is: (currentStep: number, taxIds: object[]) => {
                return currentStep > 0 && (taxIds?.length || 0) > 0;
            },
            then: yup.boolean().oneOf([true], VALIDATION_MESSAGES.REQUIRED_CHECKED),
        }),
        bankAccounts: yup.array().of(yupArrayTypeFix(yup.mixed<BankAccountInput>())),
        documents: yup.mixed<DocumentInInput>(),
        currentStep: yup.number(),
        hasDocumentEditAccess: yup.boolean().nullable(),
        hasDocumentApproveAccess: yup.boolean().nullable(),
        kycComplete: yup.mixed<KYCCompleteStatus>().nullable(),
    });
};

export type IEntityEditForm = yup.TypeOf<ReturnType<typeof validationSchemaFactory>>;
