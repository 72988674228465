import React from 'react';
import Button from '@mui/material/Button';
import { ApolloError } from '@apollo/client';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DirectorRoleFragment, EntityType } from '../../../../../interfaces/model';
import { makeDialogState } from '../../../../../store/dialogState/makeDialogState';
import { DialogCloseButton } from '../../../../../lib/modals/DialogCloseButton';
import { useRoleEditDialog } from '../../../Roles/RoleEditDialog';
import { Fieldset } from '../../../../../lib/forms/Fieldset';
import { DirectorsList } from './DirectorSelectList';

interface IDirectorSelectDialogProps {
    onSelect: (roleId: UUID) => void;
    data: DirectorRoleFragment[];
    error: ApolloError | undefined;
    updateData: () => Promise<void>;
    loading: boolean;
}

interface IDirectorSelectDialogState {
    topCoRef: UUID;
    entityRef: UUID | undefined;
    entityType: EntityType | undefined;
    selected: UUID[];
}

export const useDirectorSelectDialog = makeDialogState<IDirectorSelectDialogState>();

export const DirectorSelectDialog: React.FC<IDirectorSelectDialogProps> = ({
    data,
    error,
    loading,
    updateData,
    onSelect,
}) => {
    const [roleEditDialog] = useRoleEditDialog();
    const [{ close }, isOpen, state] = useDirectorSelectDialog();
    const { entityRef, topCoRef, entityType, selected = [] } = state || {};
    const roles = data.filter(({ id }) => !selected.includes(id));

    return (
        <Dialog open={isOpen} onClose={close} maxWidth={'sm'} data-testid={'DirectorSelectDialog'} fullWidth>
            <DialogTitle>
                Add Director
                <DialogCloseButton onClick={close} />
            </DialogTitle>
            <DialogContent>
                <Fieldset
                    spacing={2}
                    title={'Select user'}
                    description={
                        'In order to add a director, select a user from the list below or add a new user entirely'
                    }
                >
                    <div>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            onClick={() => {
                                roleEditDialog.open({
                                    topCoRef,
                                    entityRef,
                                    entityType,
                                    directorRole: true,
                                    onSaveSuccess: updateData,
                                });
                            }}
                        >
                            Add user
                        </Button>
                    </div>
                    {roles.length > 0 && (
                        <DirectorsList
                            data={roles}
                            onSelect={(roleId) => {
                                onSelect(roleId);
                                close();
                            }}
                            loading={loading}
                            updateData={updateData}
                            error={error}
                        />
                    )}
                </Fieldset>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
