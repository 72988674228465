import { IAppState } from '../store/rootReducer';

export const API_ROOT_SELECTOR = (state: IAppState) => state.appConfig?.apiRoot || '';
export const SECURE_PASSWORD_SELECTOR = (state: IAppState) => state.appConfig?.securePasswordRequired;
export const UPLOAD_FILE_EXTENSIONS_SELECTOR = (state: IAppState) => state.appConfig?.uploadFileExtensions;
export const VERSION_SELECTOR = (state: IAppState) => state.appConfig?.version;
export const FEATURES_SELECTOR = (state: IAppState) => state.appConfig?.features;
export const OPENREPLAY_SELECTOR = (state: IAppState) => state.appConfig?.openreplay;
export const WORDPRESS_SELECTOR = (state: IAppState) => state.appConfig?.wordpress;
export const USER_CONFIG_SELECTOR = (state: IAppState) => state.userConfig;
export const APP_ERROR_SELECTOR = (state: IAppState) => state.appError;
export const MENU_ITEMS_SELECTOR = (state: IAppState) => state.menuItems;
export const APP_INITIALIZED_SELECTOR = (state: IAppState) => state.appInitialized;
export const APP_SNACKBAR_CONFIG_SELECTOR = (state: IAppState) => state.appSnackbar;
export const APP_SPINNER_CONFIG_SELECTOR = (state: IAppState) => state.appSpinner;
