import * as actions from './App.actions';
import {
    APP_ERROR,
    APP_HIDE_SNACKBAR,
    APP_HIDE_SPINNER,
    APP_SHOW_SNACKBAR,
    APP_SHOW_SPINNER,
    IAppAction,
    ONBOADRING_PROGRESS,
    USER_LOGIN,
} from './App.actions';
import { IAppConfig } from '../store/interfaces/IAppConfig';
import { IAppUserConfig } from '../store/interfaces/IAppUserConfig';
import { IAppSpinnerConfig } from '../store/interfaces/IAppSpinnerConfig';
import { IAppSnackbarConfig } from '../store/interfaces/IAppSnackbarConfig';
import { UserMenuItem } from '../interfaces/model';
import { IAppError } from '../store/interfaces/IAppError';

export const appError = (state: IAppError = {}, action: IAppAction): IAppError => {
    switch (action.type) {
        case APP_ERROR:
            return (action as ReturnType<typeof actions.appError>).payload;
        default:
            return state;
    }
};

export const appConfig = (state: IAppConfig = {}, action: IAppAction): IAppConfig => {
    switch (action.type) {
        case USER_LOGIN:
            return (action as ReturnType<typeof actions.userLogin>).payload.config;
        default:
            return state;
    }
};

export const userConfig = (state: IAppUserConfig = {}, action: IAppAction): IAppUserConfig => {
    switch (action.type) {
        case USER_LOGIN: {
            const loginAction = action as ReturnType<typeof actions.userLogin>;
            return {
                ...loginAction.payload.user,
                onboardingProgress: loginAction.payload.user.role.topCo?.onboardingProgress || [],
                authRole: loginAction.payload.authRole,
            };
        }
        case ONBOADRING_PROGRESS: {
            const loginAction = action as ReturnType<typeof actions.setOnboardingProgress>;
            return (
                state && {
                    ...state,
                    onboardingProgress: loginAction.payload,
                }
            );
        }
        default:
            return state;
    }
};

export const appSpinner = (state: IAppSpinnerConfig = { show: false }, action: IAppAction): IAppSpinnerConfig => {
    switch (action.type) {
        case APP_SHOW_SPINNER: {
            return {
                show: true,
            };
        }
        case APP_HIDE_SPINNER: {
            return {
                show: false,
            };
        }
        default:
            return state;
    }
};

export const appSnackbar = (state: IAppSnackbarConfig = {}, action: IAppAction): IAppSnackbarConfig => {
    switch (action.type) {
        case APP_SHOW_SNACKBAR:
            return {
                ...(action as ReturnType<typeof actions.appShowSnackbar>).payload,
                open: true,
            };
        case APP_HIDE_SNACKBAR:
            return {
                open: false,
            };
        default:
            return state;
    }
};

export const appInitialized = (state = false, action: IAppAction): boolean => {
    switch (action.type) {
        case USER_LOGIN:
            return true;
        default:
            return state;
    }
};

export const menuItems = (state: UserMenuItem[] = [], action: IAppAction): UserMenuItem[] => {
    switch (action.type) {
        case USER_LOGIN:
            return (action as ReturnType<typeof actions.userLogin>).payload.user.menuItems;
        default:
            return state;
    }
};
