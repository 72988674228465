import React from 'react';
import Button from '@mui/material/Button';
import { BankAccountsList } from '../../../components/BankAccounts/BankAccountsList/BankAccountsList';
import { useBankAccountEditDialog } from '../../../components/BankAccounts/BankAccountEditDialog';
import { AppEmbeddedListWrap } from '../../../components/AppEmbeddedListWrap';
import { useSetEntityMutation } from '../queries/SetEntity.mutation';
import { BankAccountInput, Capabilities } from 'interfaces/model';
import { useCapability } from '../../../hooks/useCapability';
import { useParentContext } from 'lib/cdk/ParentContext';
import { ViewEntityFragment } from './ViewEntity.query';

interface IEntityBankAccountsProps {
    entity: ViewEntityFragment;
}

export const EntityBankAccounts: React.FC<IEntityBankAccountsProps> = ({ entity }) => {
    const parent = useParentContext();
    const [mutateEntity] = useSetEntityMutation();
    const hasBankAccountEditCapability = useCapability(Capabilities.BANK_ACCOUNTS);
    const canEditBankAccounts = !!entity.hasEditAccess && hasBankAccountEditCapability;
    const [bankAccountEditDialog] = useBankAccountEditDialog();

    const handleBankAccountSubmit = async (bankAccount: BankAccountInput) => {
        const otherBankAccounts = entity.bankAccounts?.filter((account) => account.id !== bankAccount.id) || [];
        await mutateEntity({
            variables: {
                entity: {
                    id: entity.id,
                    bankAccounts: [...otherBankAccounts, bankAccount],
                },
            },
        });
    };

    const handleBankAccountDelete = async (bankAccountId: UUID) => {
        await mutateEntity({
            variables: {
                entity: {
                    id: entity.id,
                    bankAccounts: entity.bankAccounts?.filter((account) => account.id !== bankAccountId),
                },
            },
        });
    };

    return (
        <AppEmbeddedListWrap
            title={'Bank Accounts'}
            actions={
                canEditBankAccounts && (
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        data-testid={`ViewEntity:bankAccountsList:addButton`}
                        onClick={() => bankAccountEditDialog.open()}
                    >
                        Add Account
                    </Button>
                )
            }
        >
            <BankAccountsList
                testId={'ViewEntity:bankAccountsList'}
                bankAccounts={entity.bankAccounts || []}
                hasEditAccess={canEditBankAccounts}
                hasDeleteAccess={canEditBankAccounts}
                onBankAccountSubmit={handleBankAccountSubmit}
                onDeleteConfirm={handleBankAccountDelete}
                updateData={parent.update}
            />
        </AppEmbeddedListWrap>
    );
};
