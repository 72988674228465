import React from 'react';
import { Alert } from '@mui/material';
import { ViewEntityFragment } from '../../../../interfaces/model';
import { PageHeading, PageTitle } from '../../../components/PageHeading';
import { AgentManagementLabel } from '../../../common/AgentManagementLabel';
import { EntityBreadcrumbs } from './EntityBreadcrumbs';

interface IViewEntityHeadingProps {
    entity: ViewEntityFragment;
    config?: {
        breadcrumbsInteractive?: boolean;
    };
}

export const ViewEntityHeading: React.FC<IViewEntityHeadingProps> = ({ entity, config }) => {
    return (
        <PageHeading>
            <PageTitle
                title={
                    <span>
                        {entity.name}
                        {entity.isAgentExt && <AgentManagementLabel />}
                    </span>
                }
            />
            <EntityBreadcrumbs entity={entity} interactive={config?.breadcrumbsInteractive} />
            {entity.deleted && (
                <Alert severity="warning" sx={{ marginTop: '8px' }}>
                    This entity has been removed from the system and cannot be used
                </Alert>
            )}
        </PageHeading>
    );
};
