import cl from 'classnames';
import { FormikContext, setIn } from 'formik';
import React, { forwardRef, useContext } from 'react';
import { FormHelperText, TextField, TextFieldProps } from '@mui/material';
import { ValidationMessage } from '../forms/ValidationMessage';

export type ITextInputProps = TextFieldProps & {
    touched?: boolean;
    onValueChange?: (value: string) => void;
    showValidationMessage?: boolean;
};

export const TextInput: React.FC<ITextInputProps> = forwardRef(
    (
        {
            id,
            name,
            onValueChange,
            error: errorProp,
            value: valueProp,
            touched: touchedProp,
            helperText: helperTextProp,
            showValidationMessage = true,
            ...props
        },
        ref
    ) => {
        const formik = useContext(FormikContext);
        const formFieldMeta = formik && name ? formik.getFieldMeta(name) : void 0;
        const error = !!errorProp || formFieldMeta?.error;
        const touched = touchedProp || formFieldMeta?.touched || formik?.submitCount > 0;
        const value = valueProp || formFieldMeta?.value;
        const handleChange = (newValue: string) => {
            if (onValueChange) {
                onValueChange(newValue);
            } else if (formik && name) {
                formik.setValues(setIn(formik.values, name, newValue));
            }
        };

        return (
            <div className={cl(props.fullWidth && 'fullWidth')} ref={ref}>
                <TextField
                    id={id || name}
                    value={value || ''}
                    error={touched && Boolean(error)}
                    onChange={(e) => handleChange(e.target.value)}
                    disabled={formik?.isSubmitting}
                    onBlur={() => {
                        if (formik && name) {
                            formik.setFieldTouched(name);
                        }
                    }}
                    {...props}
                />
                {helperTextProp && <FormHelperText sx={{ wordBreak: 'break-word' }}>{helperTextProp}</FormHelperText>}
                {name && showValidationMessage && <ValidationMessage field={name} />}
            </div>
        );
    }
);
