import React from 'react';
import {
    AgentCapabilities,
    EntitiesListRowFragment,
    EntityType,
    useEntitiesListQuery,
    ViewEntityFragment,
} from '../../../../../interfaces/model';
import { AppEmbeddedListWrap } from '../../../../components/AppEmbeddedListWrap';
import { GqlQueryList, prepareGQLListData } from '../../../../../lib/dataDisplay/List/GqlQueryList';
import { IGqlListColumn } from '../../../../../lib/dataDisplay/List';
import { AgentEntitiesListRow, IAgentEntitiesListItem } from './AgentEntitiesListRow';
import { useUserCapabilities } from '../../../../hooks/useUserCapabilities';
import { useUserDetails } from '../../../../hooks/useUserDetails';
import Button from '@mui/material/Button';
import { BORROWERS_CREATE_ROUTE, LENDERS_CREATE_ROUTE } from '../../../../App.routes';
import { Link } from 'react-router-dom';

interface IAgentEntitiesListProps {
    entity: ViewEntityFragment;
}

const getColumns = (nameColumnTitle: string): IGqlListColumn<IAgentEntitiesListItem>[] => [
    { id: 'name', label: nameColumnTitle },
    { id: 'headquarters', label: 'Headquarters' },
    {
        id: 'totalPermissions',
        label: 'Total Permissions',
        sort: false, // TODO add API support
    },
    {
        id: 'manageStructure',
        label: 'Manage Structure',
        sort: false, // TODO add API support
    },
    {
        id: 'uploadKyc',
        label: 'Upload KYC',
        sort: false, // TODO add API support
    },
    {
        id: 'uploadDataroom',
        label: 'Upload dataroom',
        sort: false, // TODO add API support
    },
    { id: 'actions', label: 'Actions', shrink: true },
];

const prepareData = (item: EntitiesListRowFragment, agentRef: UUID): IAgentEntitiesListItem => {
    const agentData = item.agentAcls?.find((agent) => agent.entity?.id === agentRef);
    const agentCapabilities = agentData?.capabilities || [];
    return {
        id: item.id,
        entityType: item.entityType,
        name: item.name,
        shortCode: item.shortCode || '',
        headquarters: item.headquarters || '',
        totalPermissions: agentCapabilities.length,
        manageStructure: agentCapabilities.includes(AgentCapabilities.MANAGE_STRUCTURE),
        uploadKyc: agentCapabilities.includes(AgentCapabilities.UPLOAD_KYC),
        uploadDataroom: agentCapabilities.includes(AgentCapabilities.UPLOAD_DATA_ROOM),
        capabilities: agentCapabilities,
        deleteable: !agentData?.isDerived,
        assignedAgents: item.assignedAgentAcls || [],
        isAgentExt: item.isAgentExt,
    };
};

export const AgentEntitiesList: React.FC<IAgentEntitiesListProps> = ({ entity }) => {
    const { createLenderTopCo, createBorrowerTopCo } = useUserCapabilities();
    const { isAgent } = useUserDetails();

    const rowProps = {
        agentRef: entity.id,
        agentName: entity.name,
    };

    return (
        <>
            <AppEmbeddedListWrap
                title={'Borrowers'}
                actions={
                    createBorrowerTopCo &&
                    isAgent && (
                        <Button
                            data-testid={'AgentsEntitiesList:addNewBorrowerButton'}
                            color="primary"
                            to={BORROWERS_CREATE_ROUTE}
                            component={Link}
                            variant="contained"
                            className={'primaryAction'}
                        >
                            Add New Borrower
                        </Button>
                    )
                }
            >
                <GqlQueryList
                    columns={getColumns('Borrower')}
                    query={useEntitiesListQuery}
                    variables={{ filter: { agentRefs: [entity.id], entityTypes: [EntityType.BORROWER_TOP_LEVEL] } }}
                    getResult={(data) => prepareGQLListData(data?.entities, (item) => prepareData(item, entity.id))}
                    defaultSortBy={'name'}
                    rowComponent={AgentEntitiesListRow}
                    rowComponentProps={rowProps}
                />
            </AppEmbeddedListWrap>
            <AppEmbeddedListWrap
                title={'Lenders'}
                actions={
                    createLenderTopCo &&
                    isAgent && (
                        <Button
                            data-testid={'AgentsEntitiesList:addNewFirmButton'}
                            color="primary"
                            to={LENDERS_CREATE_ROUTE}
                            component={Link}
                            variant="contained"
                            className={'primaryAction'}
                        >
                            Add New Lender
                        </Button>
                    )
                }
            >
                <GqlQueryList
                    columns={getColumns('Lender')}
                    query={useEntitiesListQuery}
                    variables={{ filter: { agentRefs: [entity.id], entityTypes: [EntityType.LENDER_TOP_LEVEL] } }}
                    getResult={(data) => prepareGQLListData(data?.entities, (item) => prepareData(item, entity.id))}
                    defaultSortBy={'name'}
                    rowComponent={AgentEntitiesListRow}
                    rowComponentProps={rowProps}
                />
            </AppEmbeddedListWrap>
        </>
    );
};
