import React from 'react';
import { getCode, getName } from 'country-list';
import styles from './JurisdictionIcon.module.css';

const getCountryCode = (jurisdiction: string = '') => {
    if (jurisdiction === 'USA') {
        return 'US';
    }
    return getCode(jurisdiction) || getCode(getName(jurisdiction) || '');
};

export const JurisdictionIcon: React.FC<{ value?: string | null }> = ({ value }) => {
    const countryCode = value && getCountryCode(value);

    if (!countryCode) {
        return null;
    }

    return <img className={styles.img} src={`/img/flags/${countryCode.toLowerCase()}.png`} alt={countryCode} />;
};
