import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { TableCell } from '@mui/material';
import { DateValueCell } from '../../../../../../../lib/dataDisplay/List/components/ListCells';
import { DocumentInInput, KYCDocumentFragment, NAReason, ReviewStatus } from '../../../../../../../interfaces/model';
import { ListRow } from '../../../../../../../lib/dataDisplay/List/components/ListRow';
import { CollapseTrigger } from '../../../../../../../lib/buttons/CollapseTrigger';
import { CollapsibleRow } from '../../../../../../../lib/legacy/CollapsibleRow';
import { IListRow } from '../../../../../../../lib/dataDisplay/List';
import { KYCReviewStatusChip } from '../../../KYCReviewStatusChip';
import { IDocumentViewDialogParent } from '../../interfaces';
import { ValueBox } from '../../../../../../../lib/cdk/ValueBox';
import { dateFromISOToFormat } from '../../../../../../../lib/utils/date';
import { LinkWithIcon } from '../../../../../../../lib/navigation/LinkWithIcon';
import { ROLE_VIEW_ROUTE_FACTORY } from '../../../../../../App.routes';
import { RowStack } from '../../../../../../../lib/cdk/RowStack';
import { DocumentReviews } from './components/DocumentReviews';
import { getDocumentApprovalCounters, isDocumentNotApplicable } from '../../../../Documents.const';
import { DocumentApprovalCount, IDocumentApprovalCountProps } from '../../../DocumentApprovalCount';
import { useElementFocusEffect } from '../../../../../../../lib/hooks/useElementFocusByQueryParamEffect';
import { DocumentActionsCell } from '../../components/DocumentActionsCell';
import { DocumentFileIconCell } from '../../../DocumentFileIconCell';
import { DocumentNameCell } from '../../../DocumentNameCell';
import { TextEllipsis } from '../../../../../../../lib/cdk/TextEllipsis';
import { naReasonLabels } from '../../../CreateNonApplicableDocumentDialog/CreateNonApplicableDocumentDialog.const';
import { PublicityCell } from '../../../PublicityCell';

export interface IDocumentUploadHistoryListRowItem {
    id: string;
    name: string | undefined;
    date: LocalDate | undefined;
    status: ReviewStatus | undefined;
    isPrivate: boolean;
    document: KYCDocumentFragment;
    actions?: never;
}

type IDocumentUploadHistoryListRowProps = IListRow<
    IDocumentUploadHistoryListRowItem,
    {
        parent: IDocumentViewDialogParent | undefined;
        updateData: () => Promise<void>;
        focusedItemId: UUID | undefined;
        onEditSubmit?: (document: DocumentInInput) => Promise<void>;
        kycMode: boolean;
    }
>;

const ApprovalCountValueBox: React.FC<IDocumentApprovalCountProps & { color: Colors }> = ({ color, ...props }) => (
    <ValueBox label={props.title}>
        <div className={color}>
            <DocumentApprovalCount {...props} />
        </div>
    </ValueBox>
);

export const DocumentsUploadHistoryListRow: React.FC<IDocumentUploadHistoryListRowProps> = ({
    data,
    props,
    columns,
}) => {
    const [isExpanded = props.kycMode || data.id === props.focusedItemId, setExpanded] = useState<boolean>();
    const counters = getDocumentApprovalCounters(data.document.status);
    const agentsApproved = data.document.status?.approvedAgents || [];
    const agentsPending = data.document.status?.underReviewAgents || [];
    const agentsRejected = data.document.status?.rejectedAgents || [];
    const isNotApplicable = isDocumentNotApplicable(data.document);
    const parent = props.parent;

    const toggleExpanded = () => setExpanded(!isExpanded);

    useElementFocusEffect(data.id, data.id === props.focusedItemId);

    return (
        <>
            <ListRow focused={isExpanded} id={data.id}>
                {columns.map(({ id }) => (
                    <React.Fragment key={id}>
                        {id === 'id' && <DocumentFileIconCell />}
                        {id === 'name' && <DocumentNameCell document={data.document} />}
                        {id === 'date' && <DateValueCell value={data.date} />}
                        {id === 'status' && (
                            <TableCell>
                                <KYCReviewStatusChip value={data.status} />
                            </TableCell>
                        )}
                        {id === 'isPrivate' && <PublicityCell value={data.isPrivate} />}
                        {id === 'actions' && (
                            <DocumentActionsCell
                                parent={props.parent}
                                document={data.document}
                                onSaveSuccess={props.updateData}
                                onEditSubmit={props.onEditSubmit}
                            >
                                {!props.kycMode && (
                                    <CollapseTrigger size={'small'} isActive={isExpanded} onClick={toggleExpanded} />
                                )}
                            </DocumentActionsCell>
                        )}
                    </React.Fragment>
                ))}
            </ListRow>
            <CollapsibleRow expand={isExpanded} colspan={columns.length}>
                <Stack spacing={5}>
                    <RowStack spacing={5} alignItems={'flex-start'}>
                        <ValueBox label={'Valid From'}>
                            {dateFromISOToFormat(data.document.validFromDate) || (isNotApplicable ? 'N/A' : 'Unknown')}
                        </ValueBox>
                        {data.document.validToDate && (
                            <ValueBox label={'Valid To'}>{dateFromISOToFormat(data.document.validToDate)}</ValueBox>
                        )}
                        <ValueBox label={'Filed by'}>
                            {data.document.role && (
                                <LinkWithIcon to={ROLE_VIEW_ROUTE_FACTORY(data.document.role.shortCode!)}>
                                    {data.document.role.name}
                                </LinkWithIcon>
                            )}
                        </ValueBox>
                        {data.document.naReason && (
                            <ValueBox label={'N/A Reason'}>{naReasonLabels[data.document.naReason]}</ValueBox>
                        )}

                        {data.document.naReason === NAReason.OTHER && data.document.comment && (
                            <ValueBox label={'Comment'}>
                                <TextEllipsis title={data.document.comment}>{data.document.comment}</TextEllipsis>
                            </ValueBox>
                        )}
                        {props.kycMode && counters.total > 0 && (
                            <>
                                <ApprovalCountValueBox
                                    title={'Approved'}
                                    color={'success'}
                                    value={counters.agentsApproved}
                                    total={counters.total}
                                    agents={agentsApproved}
                                />
                                <ApprovalCountValueBox
                                    title={'Under review'}
                                    color={'warning'}
                                    value={counters.agentsPending}
                                    total={counters.total}
                                    agents={agentsPending}
                                />
                                <ApprovalCountValueBox
                                    title={'Rejected'}
                                    color={'error'}
                                    value={counters.agentsRejected}
                                    total={counters.total}
                                    agents={agentsRejected}
                                />
                            </>
                        )}
                    </RowStack>
                    <DocumentReviews
                        value={data.document}
                        parent={parent}
                        updateData={props.updateData}
                        kycMode={props.kycMode}
                    />
                </Stack>
            </CollapsibleRow>
        </>
    );
};
