import { EntityType, LedgerCommRole, useLedgercommRolesTypesQuery } from '../../../../interfaces/model';
import { ISelectOption } from '../../../../lib/inputs/Select.const';
import { rolesByEntityType } from './rolesByEntityType';
import { ledgercommRoleLabels } from '../../../App.const';

export const useLedgercommRoleOptions = (
    entityType?: EntityType | null
): ISelectOption<LedgerCommRole, LedgerCommRole>[] => {
    const { data } = useLedgercommRolesTypesQuery({
        fetchPolicy: 'cache-and-network',
    });
    const filteredRoles = rolesByEntityType(data?.ledgercommRoles || [], entityType);
    return filteredRoles.map(({ role }) => ({
        id: role,
        name: ledgercommRoleLabels[role],
        value: role,
    }));
};
