import cl from 'classnames';
import Box from '@mui/material/Box';
import { FormikContext } from 'formik';
import Stack from '@mui/material/Stack';
import React, { useContext } from 'react';
import { FormLabel, Link } from '@mui/material';
import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LoadingSpinnerButton } from 'lib/buttons/LoadingSpinnerButton';
import { SeparateSymbolInput } from 'lib/inputs/SeparateSymbolInput';
import { TOTP_RESPONSE_CODE_LENGTH } from '../../../Login.const';
import { IconWithTooltip } from 'lib/icons/IconWithTooltip';
import { LoginScreenFrame } from '../../LoginScreenFrame';
import styles from './TwoFactorCodeLoginScreen.module.css';
import { useTermsAndConditionsDialog } from '../../../TermsAndConditionsDialog';

interface ITwoFactorCodeLoginScreenProps {
    spin: boolean;
    onBackClick: () => void;
}

const INFO_TEXT = 'e.g. 1Password, Authy, LastPass Authenticator, Google Authenticator or Microsoft Authenticator';

export const TwoFactorCodeLoginScreen: React.FC<ITwoFactorCodeLoginScreenProps> = ({ spin, onBackClick }) => {
    const formik = useContext(FormikContext);
    const [termsAndConditionsDialog] = useTermsAndConditionsDialog();

    return (
        <LoginScreenFrame
            title={'Authentication verification'}
            description={
                <div>
                    <p>
                        As part of our two-factor authentication (2FA) security measure, you'll need to use a code from
                        the authenticator app you've set up during the first log in.
                        <IconWithTooltip
                            color={'primary'}
                            Icon={HelpOutlineIcon}
                            text={INFO_TEXT}
                            tooltipProps={{ sx: { margin: '-4px 0' } }}
                        />
                    </p>
                    <p>Here's how to proceed:</p>
                    <ol className={cl('input-label', styles.list)}>
                        <li>Open your authenticator app where you'll find a unique 6-digit code</li>
                        <li>Input this code into the 2FA field below</li>
                    </ol>
                    <p className={'input-label'}>
                        Remember, these codes are temporary and refresh periodically. If your code expires, simply
                        retrieve a new one from your app.
                    </p>
                </div>
            }
        >
            <Box>
                <FormLabel required>Authentication code</FormLabel>
                <SeparateSymbolInput
                    name={'totpCode'}
                    value={formik.values.totpCode}
                    length={TOTP_RESPONSE_CODE_LENGTH}
                    autoSubmit
                />
            </Box>
            <Stack spacing={1}>
                <LoadingSpinnerButton type={'submit'} spin={spin} fullWidth />
                <Button onClick={onBackClick} color={'secondary'}>
                    Back
                </Button>
                <Link
                    underline={'none'}
                    component={'button'}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        termsAndConditionsDialog.open({ mode: 'readonly' });
                    }}
                    sx={{ fontWeight: 500 }}
                >
                    Terms and Conditions
                </Link>
            </Stack>
        </LoginScreenFrame>
    );
};
