import React from 'react';
import { EntityType, TopCoRoleFragment, useTopCoRolesQuery, ViewEntityFragment } from 'interfaces/model';
import { getEntityTitleByType } from '../../LegalEntities/LegalEntities.helpers';
import { ITopCoRolesListItem, TopCoRolesListRow } from './TopCoRolesListRow';
import { IRolesFilters } from '../RolesFilterPanel';
import { IListColumn } from 'lib/dataDisplay/List';
import { List } from 'lib/dataDisplay/List/List';
import { voidWrapper } from 'lib/utils/helpers';

interface ITopCoRolesListProps {
    entity: ViewEntityFragment;
    selectedRoles: UUID[];
    onRoleSelect: (id: UUID) => void;
    disabled: boolean;
    filters: IRolesFilters;
}

const getColumns = (entityType: EntityType | undefined): IListColumn<ITopCoRolesListItem>[] => [
    { id: 'checkbox', label: '' },
    { id: 'name', label: 'User' },
    { id: 'numberOfEnabledEntities', label: `${getEntityTitleByType(entityType, { plural: true })}` },
    { id: 'jobTitle', label: 'Job Title' },
    { id: 'email', label: 'Email' },
    { id: 'deactivated', label: 'Status' },
    { id: 'actions', label: 'Actions' },
];

const prepareData = (
    roles: TopCoRoleFragment[],
    selectedRoles: UUID[],
    filters: IRolesFilters
): ITopCoRolesListItem[] => {
    const nameFilter = (item: ITopCoRolesListItem) => {
        if (filters.name) {
            return item.name.toLowerCase().includes(filters.name.toLowerCase());
        }
        return true;
    };
    const jobTitleFilter = (item: ITopCoRolesListItem) => {
        if (filters.ledgerCommRole) {
            return item.jobTitle === filters.ledgerCommRole;
        }
        return true;
    };
    const entityFilter = (item: ITopCoRolesListItem) => {
        if (filters.entityId) {
            return item.enabledEntities?.includes(filters.entityId);
        }
        return true;
    };

    const filterFunctions = [nameFilter, jobTitleFilter, entityFilter];

    return roles
        .map((role) => {
            const selected = selectedRoles.includes(role.id);
            return {
                ...role,
                name: role.name || '',
                numberOfEnabledEntities:
                    (role.enabledEntities || []).filter((entityId) => entityId !== role.topCoRef).length || 0,
                emailVerified: !!role.emailVerified,
                entityRelations: role.entityRelations || [],
                selected,
            };
        })
        .filter((item) => filterFunctions.every((filter) => filter(item)));
};

export const TopCoRolesList: React.FC<ITopCoRolesListProps> = ({
    entity,
    selectedRoles,
    onRoleSelect,
    disabled,
    filters,
}) => {
    const { data, loading, error, refetch } = useTopCoRolesQuery({
        fetchPolicy: 'no-cache',
        variables: {
            topCoRef: entity.topCoRef,
        },
    });

    const columns = getColumns(entity.topCo?.entityType);

    const roles = (data?.entity?.roles || []).filter(
        (role: TopCoRoleFragment) => !role.enabledEntities?.includes(entity.id)
    );

    const reloadData = voidWrapper(refetch);

    return (
        <List
            data={prepareData(roles, selectedRoles, filters)}
            columns={columns}
            rowComponent={TopCoRolesListRow}
            rowComponentProps={{
                topCoType: entity.topCo?.entityType,
                reloadData,
                onRoleSelect,
                disabled,
                filters,
                selectedRoles,
            }}
            defaultSortBy={'name'}
            error={error}
            loading={loading}
            onRetry={reloadData}
            emptyStatePlaceholderProps={{
                title: 'There are no unassigned roles',
            }}
        />
    );
};
