import { useNotificationsCountQuery } from './queries/NotificationsCount.query';

export const useNotificationsCount = (fetch?: boolean) => {
    const { data, loading, error } = useNotificationsCountQuery({
        fetchPolicy: fetch ? 'cache-and-network' : 'cache-only',
    });
    const counters = {
        notifications: data?.notifications.total || 0,
        documents: data?.kycs.total || 0,
        pendingAccrualPayments: data?.dashboard.pendingAccrualPayments || 0,
        incompleteIUAccruals: data?.dashboard.incompleteIUAccruals.value || 0,
        pendingIUAccruals: data?.dashboard.pendingIUAccruals.value || 0,
        privateAccessRequests: data?.dashboard.privateAccessRequests || 0,
        whitelistRequests: data?.dashboard.whitelistRequests || 0,
        pendingAccrualCoupons: data?.dashboard.pendingAccrualCoupons || 0,
        votings: (data?.dashboard.pendingVotings || 0) + (data?.dashboard.inProgressVotings || 0),
    };
    const total = Object.values(counters).reduce((sum, value) => sum + value);
    return { data, loading, error, counters, total };
};
