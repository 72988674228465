import React from 'react';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import { FormLabel } from '@mui/material';
import { GICSRecordFragment, GICSRecordWithParentFragment, useGICSDataQuery } from '../../../../../interfaces/model';
import { IEntityEditForm } from '../useEntityEditFormik/useEntityEditFormik.const';
import { Dropdown } from '../../../../../lib/inputs/Dropdown';
import { ISelectOption } from '../../../../../lib/inputs/Select.const';
import { fromEntries } from '../../../../../lib/utils/helpers';

interface IGICSSelectProps {}

export const getRecordParent = (record: GICSRecordWithParentFragment | undefined) => {
    return record?.parents.find((item) => item.level === record.level - 1);
};

export const groupByParent =
    (map: Record<string, GICSRecordWithParentFragment>) => (option: ISelectOption<string, string> | null) => {
        return getRecordParent(map[option?.value!])?.name || '';
    };

export const GICSSelect: React.FC<IGICSSelectProps> = () => {
    const formik = useFormikContext<IEntityEditForm>();
    const { data, loading } = useGICSDataQuery({
        fetchPolicy: 'cache-and-network',
    });

    const mainIndustryCode = formik.values.mainIndustry;
    const subIndustryCode = formik.values.subIndustry;

    const industries = data?.gics.flatMap(({ children }) => children).flatMap(({ children }) => children) || [];
    const subIndustries = industries.flatMap(({ children }) => children);

    const industriesMap = fromEntries(industries.map((item) => [item.code, item]));
    const subIndustriesMap = fromEntries(subIndustries.map((item) => [item.code, item]));

    const mapRecordToOption = ({ code, name }: GICSRecordFragment) => ({ id: code, name, value: code });
    const industriesOptions = industries.map(mapRecordToOption);
    const subIndustriesOptions = subIndustries.map(mapRecordToOption).filter(({ value }) => {
        if (mainIndustryCode && !subIndustryCode) {
            return value.startsWith(mainIndustryCode);
        }
        return true;
    });

    return (
        <Grid spacing={2} container>
            <Grid xs={6} item>
                <FormLabel>Main industry type</FormLabel>
                <Dropdown
                    name={'mainIndustry'}
                    options={industriesOptions}
                    data-testid={'GICSIndustrySelect'}
                    placeholder={'Select industry'}
                    onChange={(option) => {
                        formik.setValues({
                            ...formik.values,
                            mainIndustry: option?.value,
                            subIndustry: void 0,
                        });
                    }}
                    groupBy={(option) => groupByParent(industriesMap)(option)}
                    value={mainIndustryCode}
                    disabled={loading}
                    clearable
                    fullWidth
                />
            </Grid>
            <Grid xs={6} item>
                <FormLabel>Sub Industry Type</FormLabel>
                <Dropdown
                    name={'subIndustry'}
                    options={subIndustriesOptions}
                    placeholder={'Select sub industry'}
                    data-testid={'GICSSubIndustrySelect'}
                    onChange={(option) => {
                        const parentIndustryCode = getRecordParent(subIndustriesMap[option?.value!])?.code;
                        formik.setValues({
                            ...formik.values,
                            mainIndustry: parentIndustryCode || mainIndustryCode,
                            subIndustry: option?.value,
                        });
                    }}
                    groupBy={(option) => groupByParent(subIndustriesMap)(option)}
                    value={subIndustryCode}
                    disabled={loading}
                    clearable
                    fullWidth
                />
            </Grid>
        </Grid>
    );
};
