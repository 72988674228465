import React, { useRef, useState } from 'react';

export const useDelayedElementState = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const closeTimer = useRef<number>();
    const openTimer = useRef<number>();
    const clearScheduledClose = () => clearTimeout(closeTimer.current);
    const clearScheduledOpen = () => clearTimeout(openTimer.current);

    const closeImmediately = () => {
        setAnchorEl(null);
    };

    const scheduleClose = () => {
        clearScheduledOpen();
        clearScheduledClose();
        closeTimer.current = setTimeout(closeImmediately, 300) as unknown as number;
    };

    const scheduleOpen = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
        clearScheduledOpen();
        clearScheduledClose();
        // Preventing the menu from instant opening - in case the cursor is moving over the target
        openTimer.current = setTimeout(() => setAnchorEl(currentTarget), 100) as unknown as number;
    };

    return [
        anchorEl,
        {
            scheduleOpen,
            scheduleClose,
            closeImmediately,
            clearScheduledOpen,
            clearScheduledClose,
        },
    ] as const;
};
