import * as yup from 'yup';
import { useEffect } from 'react';
import { useUserDetails } from '../../../hooks/useUserDetails';
import { useUrlState } from 'lib/hooks/router/useUrlState';
import { yupEnum } from 'lib/forms';

export enum CounterpartiesKYCEditMode {
    APPROVE = 'APPROVE',
    REVOKE = 'REVOKE',
}

export const useCounterpartiesKYCEditUrlState = () => {
    const { isAdmin, isAgent, role } = useUserDetails();
    const [urlState, setUrlState] = useUrlState({
        validationSchema: {
            step: yup.number(),
            from: yup.string(),
            search: yup.string(),
            topCoRef: yup.string(),
            authorizedAt: yup.string(),
            authorizedByRef: yup.string(),
            targets: yup.array().of(yup.string()),
            counterparties: yup.array().of(yup.string()),
            mode: yupEnum(CounterpartiesKYCEditMode),
        },
    });
    const isAdminOrAgent = isAdmin || isAgent;

    useEffect(() => {
        if (!isAdminOrAgent && urlState.topCoRef && urlState.topCoRef !== role?.topCoRef) {
            setUrlState(
                {
                    ...urlState,
                    topCoRef: role?.topCoRef,
                },
                { replace: true }
            );
        }
    });

    return [
        {
            mode: CounterpartiesKYCEditMode.APPROVE,
            ...urlState,
        },
        setUrlState,
    ] as const;
};
