import React from 'react';
import cl from 'classnames';
import { CheckCircle, Warning } from '@mui/icons-material';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

interface ICompleteIconProps {
    complete: boolean;
    completeLabel?: string;
    incompleteLabel?: string;
    tooltipProps?: Partial<TooltipProps>;
    tooltip?: boolean;
    className?: string;
}

export const IsCompleteIcon: React.FC<ICompleteIconProps> = ({
    complete,
    incompleteLabel = 'Incomplete',
    completeLabel = 'Complete',
    tooltipProps = {},
    className,
    tooltip = true,
}) => {
    const label = complete ? completeLabel : incompleteLabel;
    const content = (
        <span className={cl(className, complete ? 'success' : 'error')}>
            {!complete && <Warning fontSize={'small'} className={'error'} />}
            {complete && <CheckCircle fontSize={'small'} className={'success'} />}
        </span>
    );

    if (!tooltip) {
        return content;
    }

    return (
        <Tooltip placement={'bottom'} {...tooltipProps} title={label} arrow>
            {content}
        </Tooltip>
    );
};
