import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';

export const formatNumber = (
    value: number | undefined | null,
    options?: Intl.NumberFormatOptions
): string | undefined => {
    if (isNumber(value)) {
        return new Intl.NumberFormat('en-GB', {
            minimumFractionDigits: 0,
            ...options,
        }).format(value);
    }
    return !isNil(value) ? String(value) : value || void 0;
};

export const formatCurrency = (
    value: number | undefined | null,
    currency: string | null | undefined,
    options?: Intl.NumberFormatOptions
): string | undefined => {
    const defaultFormatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const optionsValue = {
        style: currency ? 'currency' : void 0,
        currencyDisplay: currency ? 'narrowSymbol' : void 0,
        currency: currency || void 0,
        ...defaultFormatOptions,
        ...options,
    };

    return formatNumber(value, optionsValue);
};

export const formatPercentage = (value: number | undefined | null, options?: Intl.NumberFormatOptions) => {
    const defaultFormatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 4 };
    return formatNumber(value, {
        style: 'percent',
        ...defaultFormatOptions,
        ...options,
    });
};
