import * as yup from 'yup';
import { AgentCapabilities } from '../../../../interfaces/model';
import { VALIDATION_MESSAGES } from '../../../../lib/forms';
import { selectOptionFactory } from '../../../../lib/inputs/Select.const';
import { agentCapabilitiesLabels } from '../../../App.const';

export const getInitialValues = (
    agentRef: UUID | undefined | null,
    capabilities: AgentCapabilities[] | undefined | null
) => {
    return {
        entityRef: agentRef,
        capabilities: capabilities || [],
    };
};

export const agentEditValidationSchema = yup.object({
    entityRef: yup.string().required(VALIDATION_MESSAGES.REQUIRED).nullable(),
    capabilities: yup.array().of(yup.mixed<AgentCapabilities>()).nullable(),
});

export const agentCapabilitiesOptionFactory = selectOptionFactory(AgentCapabilities, agentCapabilitiesLabels);
export const agentCapabilitiesOptions = Object.keys(AgentCapabilities)
    .map((item) => item as AgentCapabilities)
    .map(agentCapabilitiesOptionFactory);
