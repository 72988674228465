import {
    Card,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import React from 'react';
import { ISelectOption } from '../../../../../lib/inputs/Select.const';

interface ISelectListProps<TKeys, TValue, TOption extends ISelectOption<TKeys, TValue>> {
    options: TOption[];
    value: TValue[];
    onChange: (value: TValue[]) => void;
    editable?: boolean;
    elevation?: number;
    className?: string;
    maxHeight?: string;
    loading?: boolean;
}

export function MultiSelectList<TKeys extends string, TValue, TOption extends ISelectOption<TKeys, TValue>>({
    options,
    value = [],
    onChange,
    editable,
    elevation,
    className,
    maxHeight,
    loading,
}: ISelectListProps<TKeys, TValue, TOption>) {
    const isAllSelected = options.length > 0 && options.every((option) => value.includes(option.value));
    const isSelected = (option: TOption) => value.includes(option.value);

    const toggleOption = (option: TOption) => {
        if (!editable) {
            return;
        }

        if (isSelected(option)) {
            onChange(value.filter((item) => item !== option.value));
        } else {
            onChange([...value, option.value]);
        }
    };

    const toggleAll = () => {
        if (isAllSelected) {
            onChange([]);
        } else {
            onChange(options.map((option) => option.value));
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Card className={className} sx={{ background: 'transparent' }} elevation={elevation}>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        checked={isAllSelected}
                        indeterminate={!isAllSelected && value.length > 0}
                        disabled={!editable || options.length === 0}
                        onClick={toggleAll}
                    />
                }
                subheader={`${value.length}/${options.length} selected`}
            />
            <Divider />
            <List sx={{ overflowY: 'auto', maxHeight }} dense>
                {options.map((option) => {
                    const isDisabled = !editable || option.disabled;
                    return (
                        <ListItemButton
                            component={'div'}
                            sx={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                            key={option.id}
                            onClick={() => {
                                if (!isDisabled) {
                                    toggleOption(option);
                                }
                            }}
                            disableRipple={isDisabled}
                        >
                            <ListItemIcon>
                                <Checkbox checked={value.includes(option.value)} disabled={isDisabled} />
                            </ListItemIcon>
                            <ListItemText primary={option.name} />
                        </ListItemButton>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );
}
