import { FormikContext, FormikContextType } from 'formik';
import React, { useContext } from 'react';
import { RoleCapabilitiesList } from '../../../EditRole/RoleCapabilitiesList/RoleCapabilitiesList';
import { Capabilities, CapabilityType, ClientType, useRoleCapabilitiesQuery } from '../../../../../../interfaces/model';
import { NonFalsy } from '../../../../../../lib/utils/helpers';
import FormLabel from '@mui/material/FormLabel';
import { Dropdown } from '../../../../../../lib/inputs/Dropdown';
import { UserType, userTypeOptions } from '../RoleGeneralInfoEdit/RoleGeneralInfoEdit.const';
import { IRoleForm } from '../../useRoleEditFormik/useRoleEditFormik.const';
import { useUserDetails } from '../../../../../hooks/useUserDetails';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Paper, Switch } from '@mui/material';

interface IUserPermissionsEditProps {
    clientType: ClientType | undefined;
}

export const UserPermissionsEdit: React.FC<IUserPermissionsEditProps> = ({ clientType = ClientType.UNDEFINED }) => {
    const formik = useContext<FormikContextType<IRoleForm>>(FormikContext);
    const { canManageStructure } = useUserDetails();

    const { data, error, loading } = useRoleCapabilitiesQuery({
        fetchPolicy: 'cache-and-network',
        skip: !clientType,
        variables: {
            type: clientType!,
        },
    });

    const memberCapabilitiesList = data?.capabilities?.filter((item) => item.type === CapabilityType.MEMBER) || [];
    const additionalCapabilitiesList =
        data?.capabilities?.filter(
            (item) => item.type !== CapabilityType.MEMBER && item.capability !== Capabilities.MANAGE_STRUCTURE
        ) || [];
    const adminCapabilities = additionalCapabilitiesList
        .filter((item) => item.type === CapabilityType.ADMIN)
        .map((item) => item.capability);

    const userLabel = (formik.values.capabilities || []).includes(Capabilities.MANAGE_STRUCTURE) ? 'Admin' : 'Member';

    return (
        <Stack spacing={3} sx={{ paddingBottom: '32px' }}>
            <Box>
                <FormLabel htmlFor={'userType'} required>
                    User role
                </FormLabel>
                <Dropdown
                    name={'userType'}
                    onChange={(option) => {
                        if (option.value === UserType.ADMIN) {
                            formik.setFieldValue('allMemberCapabilities', true);
                            formik.setFieldValue('additionalCapabilities', adminCapabilities);
                        }
                        formik.setFieldValue('userType', option.value);
                    }}
                    options={userTypeOptions}
                    disabled={!canManageStructure}
                    fullWidth
                />
            </Box>
            <p>Select permissions you want the {userLabel} to have</p>
            <Box>
                <Paper elevation={0} sx={{ background: (theme) => theme.palette.background.default }}>
                    <Box margin="0 16px 0 auto" padding={'12px 16px'} display={'flex'} alignItems={'center'}>
                        <Switch
                            color={'primary'}
                            onChange={() =>
                                formik.setFieldValue('allMemberCapabilities', !formik.values.allMemberCapabilities)
                            }
                            checked={!!formik.values.allMemberCapabilities}
                            disabled={formik.values.userType === UserType.ADMIN}
                            size={'small'}
                        />
                        <span className={'ml-12'}>All General Permissions</span>
                    </Box>
                </Paper>
                {!formik.values.allMemberCapabilities && (
                    <RoleCapabilitiesList
                        value={(formik.values.memberCapabilities || []).filter(NonFalsy)}
                        onChange={(newCapabilities) => formik.setFieldValue('memberCapabilities', newCapabilities)}
                        headerLabel={'General Permissions'}
                        error={error}
                        loading={loading}
                        capabilitiesList={memberCapabilitiesList}
                    />
                )}
            </Box>
            <RoleCapabilitiesList
                value={(formik.values.additionalCapabilities || []).filter(NonFalsy)}
                onChange={(newCapabilities) => formik.setFieldValue('additionalCapabilities', newCapabilities)}
                headerLabel={'Additional Permissions'}
                error={error}
                loading={loading}
                capabilitiesList={additionalCapabilitiesList}
            />
        </Stack>
    );
};
