import cl from 'classnames';
import { Box } from '@mui/material';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useContainerChildrenVisibilityEffect } from './useMenuItemsVisibilityEffect';
import { AppMenuExtraItemsGroup } from './AppMenuExtraItemsGroup';
import { IMenuGroup, MenuGroup } from './MenuGroup/MenuGroup';
import { MENU_ITEMS_SELECTOR } from '../../../App.selectors';
import { UserMenuItem } from '../../../../interfaces/model';
import styles from './AppMenu.module.css';
import { isMenuItemPathMatchUrl } from './IsMenuItemPathMatchUrl';

const groupItemsByMenuGroup = (items: UserMenuItem[], pathName: string) =>
    items
        .reduce((acc: IMenuGroup[], { label, url, menuGroup }) => {
            const item: IMenuGroup = {
                active: isMenuItemPathMatchUrl(url, pathName),
                subItems: [],
                label,
                url,
            };

            if (menuGroup) {
                const existingItem = acc.find((groupItem) => groupItem.menuGroup === menuGroup);
                if (existingItem) {
                    existingItem.subItems.push(item);
                    return acc;
                }
                return [...acc, { active: item.active, menuGroup, url: void 0, label: menuGroup, subItems: [item] }];
            }
            return [...acc, item];
        }, [])
        .map((item) => {
            if (item.subItems.length === 1) {
                return item.subItems[0];
            }
            return item;
        });

export const AppMenu: React.FC = () => {
    const location = useLocation();
    const menuItemsContainerRef = useRef<HTMLSpanElement>();
    const menuItems = useSelector(MENU_ITEMS_SELECTOR);
    const groupedItems = groupItemsByMenuGroup(menuItems, location.pathname);
    const menuItemsVisibility = useContainerChildrenVisibilityEffect(menuItemsContainerRef, {
        itemMarginRight: 32,
    }).filter((visible, index) => {
        return (index + 1) % 2 !== 0; // removing each 2nd element as we don't care about slot items visibility
    });

    return (
        <Box className={styles.container}>
            <Box className={styles.menuItemsContainer} ref={menuItemsContainerRef}>
                {groupedItems.map((menuItem, index) => {
                    const { url, label, active, subItems } = menuItem;
                    const isVisible = menuItemsVisibility[index];
                    const isLast = index === groupedItems.length - 1;
                    const displayExtraMenu = isVisible && !menuItemsVisibility[index + 1];
                    const itemVisibilityClass = !isVisible ? styles.hidden : '';

                    return (
                        <React.Fragment key={label}>
                            {subItems.length > 0 && (
                                <MenuGroup key={label} className={itemVisibilityClass} value={menuItem} />
                            )}
                            {subItems.length === 0 && (
                                <Link
                                    key={url}
                                    className={cl('item', active && 'active', itemVisibilityClass)}
                                    to={url}
                                >
                                    {label}
                                </Link>
                            )}
                            <Box display={'flex'} width={'0'} justifyContent={'center'}>
                                {displayExtraMenu && (
                                    <AppMenuExtraItemsGroup
                                        items={groupedItems}
                                        containerRef={menuItemsContainerRef}
                                        itemsVisibility={menuItemsVisibility}
                                        className={cl(isLast && styles.hidden)}
                                    />
                                )}
                            </Box>
                        </React.Fragment>
                    );
                })}
            </Box>
        </Box>
    );
};
