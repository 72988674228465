import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { Action, applyMiddleware, createStore } from 'redux';
import { USER_LOGOUT } from '../App/App.actions';
import { isDevMode, NonFalsy } from '../lib/utils/helpers';
import { appInitialState, IAppState, rootReducer } from './rootReducer';

const loggerMiddleware = createLogger({ collapsed: true });

const stateResetter = (state: IAppState | undefined, action: Action) => {
    if (action.type === USER_LOGOUT) {
        return rootReducer(appInitialState, action);
    }
    return rootReducer(state, action);
};

export const setupStore = () => {
    return createStore(stateResetter, applyMiddleware(...[thunk, isDevMode() && loggerMiddleware].filter(NonFalsy)));
};
