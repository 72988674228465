import React from 'react';
import { DocumentsUploadHistoryListRow, IDocumentUploadHistoryListRowItem } from './DocumentsUploadHistoryListRow';
import { DOCUMENTS_WITH_PRIVATE_ACCESS_CONFIGURABLE, getYearFromDate } from '../../../../Documents.const';
import { IEmptyStatePlaceholderProps } from '../../../../../../common/EmptyStatePlaceholder';
import { IListColumn, List } from '../../../../../../../lib/dataDisplay/List';
import { DocumentInInput, KYCDocumentFragment } from '../../../../../../../interfaces/model';
import { useDocumentsUrlState } from '../../../../useDocumentsUrlState';
import { IDocumentGroupTreeItem } from '../DocumentsSubsetGroupsTree';
import { NonFalsy } from '../../../../../../../lib/utils/helpers';
import { kycDocTypeLabels } from '../../../../../../App.const';
import { IDocumentViewDialogParent } from '../../interfaces';

export interface IDocumentsUploadHistoryListProps {
    value: KYCDocumentFragment[];
    parent: IDocumentViewDialogParent | undefined;
    selection: IDocumentGroupTreeItem;
    kycMode: boolean;
    updateData: () => Promise<void>;
    focusedItemId: UUID | undefined;
    onEditSubmit?: (document: DocumentInInput) => Promise<void>;
}

const prepareData = (
    data: KYCDocumentFragment[],
    selection: IDocumentGroupTreeItem
): IDocumentUploadHistoryListRowItem[] => {
    return data
        .filter(({ validFromDate, docType, docSubcategory, deleted }) => {
            const { subcategory, year } = selection;
            if (selection.deleted) {
                return deleted;
            }

            return (
                !deleted &&
                selection.docType === docType &&
                (!subcategory || subcategory === docSubcategory) &&
                (!validFromDate || getYearFromDate(validFromDate) === String(year))
            );
        })
        .map((item) => {
            return {
                id: item.id,
                name: item.name || void 0,
                status: item.status?.status || void 0,
                date: item.createdAt || void 0,
                isPrivate: item.isPrivate,
                document: item,
            };
        })
        .sort((a, b) => {
            const dateA = a.date ? Date.parse(a.date) : 0;
            const dateB = b.date ? Date.parse(b.date) : 0;
            return dateB - dateA;
        });
};

const useEmptyStatePlaceholderProps = (
    selection: IDocumentGroupTreeItem,
    hasDocuments: boolean
): IEmptyStatePlaceholderProps => {
    const [urlState] = useDocumentsUrlState();
    if (urlState.id) {
        return {
            title: 'Nothing found',
            description: 'No document found by document id',
        };
    }

    const docTypeLabel = kycDocTypeLabels[selection.docType!];
    return {
        title: 'No documents',
        description: `The document list is currently empty${docTypeLabel ? ` for ${docTypeLabel}` : ''}${
            hasDocuments ? '. Try to change category' : ''
        }`.trim(),
    };
};

export const DocumentsUploadHistoryList: React.FC<IDocumentsUploadHistoryListProps> = ({
    value,
    kycMode,
    updateData,
    selection,
    focusedItemId,
    onEditSubmit,
    parent,
}) => {
    const hasDocuments = value.length > 0;
    const emptyStatePlaceholderProps = useEmptyStatePlaceholderProps(selection, hasDocuments);
    const showPublicity =
        hasDocuments && value.some(({ docType }) => DOCUMENTS_WITH_PRIVATE_ACCESS_CONFIGURABLE.includes(docType));

    const columns: IListColumn<IDocumentUploadHistoryListRowItem>[] = (
        [
            { id: 'id', shrink: true },
            { id: 'name', label: 'File Name' },
            { id: 'date', label: 'Filing Date', shrink: true },
            { id: 'status', label: 'Review status', shrink: true },
            showPublicity && ({ id: 'isPrivate', label: 'Access', shrink: true } as const),
            { id: 'actions', label: 'Actions', sort: false, shrink: true },
        ] as const
    ).filter(NonFalsy);

    return (
        <List
            columns={columns}
            data={prepareData(value, selection)}
            rowComponent={DocumentsUploadHistoryListRow}
            rowComponentProps={{ parent, kycMode, updateData, focusedItemId, onEditSubmit }}
            emptyStatePlaceholderProps={emptyStatePlaceholderProps}
        />
    );
};
