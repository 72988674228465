import React from 'react';
import { Alert, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import { useFormikContext } from 'formik';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import styles from '../../../Entities/CreateEntityWizardDialog/steps/EntityTaxInformationStep/EntityTaxInformationStep.module.css';
import { IFundEditForm } from '../useFundEditFormik/useFundEditFormik.const';
import { Fieldset } from '../../../../../lib/forms/Fieldset';
import { TaxIdsList } from '../../../../common/TaxIds/TaxIdsList/TaxIdsList';
import { useTaxIdEditDialog } from '../../../../common/TaxIds/TaxIdsList/TaxIdEditDialog';
import { useFormikTaxIdEditHelpers } from '../../../../common/TaxIds/useFormikTaxIdEditHelpers';

interface IFundTaxInformationStep {}

export const FundTaxInformationStep: React.FC<IFundTaxInformationStep> = () => {
    const [taxIdEditDialog] = useTaxIdEditDialog();
    const formik = useFormikContext<IFundEditForm>();
    const { handleSubmit, handleDelete, mapTaxIdEditToListData } = useFormikTaxIdEditHelpers();

    return (
        <Fieldset
            title={'Withholding Tax ID Forms (Optional)'}
            description={'Please add all relevant Double Taxation Tax IDs for this fund'}
            paddingBottom={'32px'}
            spacing={2}
        >
            <Alert severity={'success'} icon={<TipsAndUpdatesIcon sx={{ fontSize: '24px' }} />}>
                You can come amend tax information at any time. If you do not maintain tax ID properly the fund may not
                be to get the appropriate tax relief.
            </Alert>
            <div className={styles.buttonPanel}>
                <Button variant={'contained'} onClick={() => taxIdEditDialog.open()}>
                    Add Tax ID
                </Button>
            </div>
            <Paper sx={{ background: 'var(--bg-default)', padding: '14px' }} elevation={0}>
                <TaxIdsList
                    taxIds={mapTaxIdEditToListData(formik.values.taxIds || [])}
                    emptyStatePlaceholderProps={{ title: "No Tax ID's added yet", sx: { background: 'transparent' } }}
                    onEditSubmit={handleSubmit}
                    onDelete={handleDelete}
                    editable
                />
            </Paper>
        </Fieldset>
    );
};
