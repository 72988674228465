import { ClientType, EntityType, LedgercommRolesTypesFragment } from '../../../../interfaces/model';

const LENDER_ENTITY_TYPES = [
    EntityType.LENDER_TOP_LEVEL,
    EntityType.LENDER_INVESTMENT_MANAGER,
    EntityType.LENDER_INVESTMENT_BANK,
];
const BORROWER_ENTITY_TYPES = [EntityType.BORROWER_TOP_LEVEL, EntityType.BORROWER_SUBSIDIARY];
const AGENT_ENTITY_TYPES = [EntityType.AGENT_TOP_LEVEL, EntityType.AGENT];
const SPONSOR_ENTITY_TYPES = [EntityType.SPONSOR_TOP_LEVEL, EntityType.SPONSOR];
const FUNDADMIN_ENTITY_TYPES = [EntityType.FUNDADMIN_TOP_LEVEL, EntityType.FUNDADMIN];

export const rolesByEntityType = (roles: LedgercommRolesTypesFragment[], entityType: EntityType | undefined | null) => {
    const availableRoles = roles.filter((role) => role.clientType !== ClientType.UNDEFINED) || [];
    if (!entityType) {
        return availableRoles;
    }
    const isBorrower = BORROWER_ENTITY_TYPES.includes(entityType);
    const isLender = LENDER_ENTITY_TYPES.includes(entityType);
    const isAgent = AGENT_ENTITY_TYPES.includes(entityType);
    const isSponsor = SPONSOR_ENTITY_TYPES.includes(entityType);
    const isFundAdmin = FUNDADMIN_ENTITY_TYPES.includes(entityType);
    const filteredRoles = [];

    if (isLender) {
        const lenderRoles = availableRoles.filter((role) => role.clientType === ClientType.LENDER);
        filteredRoles.push(...lenderRoles);
    }
    if (isBorrower) {
        const borrowerRoles = availableRoles.filter((role) => role.clientType === ClientType.BORROWER);
        filteredRoles.push(...borrowerRoles);
    }
    if (isAgent) {
        const agentRoles = availableRoles.filter((role) => role.clientType === ClientType.AGENT);
        filteredRoles.push(...agentRoles);
    }
    if (isSponsor) {
        const sponsorRoles = availableRoles.filter((role) => role.clientType === ClientType.SPONSOR);
        filteredRoles.push(...sponsorRoles);
    }
    if (isFundAdmin) {
        const sponsorRoles = availableRoles.filter((role) => role.clientType === ClientType.FUND_ADMIN);
        filteredRoles.push(...sponsorRoles);
    }

    return filteredRoles;
};
