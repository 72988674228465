import React from 'react';
import isNumber from 'lodash/isNumber';
import { dateFromISOToFormat, dateFromJSToFormat, dateFromTimestampToFormat } from '../../../../utils/date';
import { IValueCellProps, ValueCell } from './ValueCell';
import { RowStack } from 'lib/cdk/RowStack';

export interface IDateValueCellProps extends IValueCellProps {
    value: Maybe<LocalDate> | Maybe<Timestamp> | number | undefined;
    format?: string;
}

export const DateValueCell: React.FC<IDateValueCellProps> = ({ value, format, children, ...props }) => {
    if (!value) {
        return <ValueCell {...props} />;
    }
    return (
        <ValueCell nowrap {...props}>
            <RowStack>
                <span>
                    {isNumber(value)
                        ? dateFromJSToFormat(new Date(value))
                        : dateFromISOToFormat(value, format) || dateFromTimestampToFormat(value, format)}
                </span>
                {children}
            </RowStack>
        </ValueCell>
    );
};
