import * as yup from 'yup';
import { AnySchema } from 'yup';
import { v4 as uuid } from 'uuid';
import { bankAccountPurposeLabels } from '../BankAccountEditDialog.const';
import {
    BankAccountField,
    BankAccountFieldType,
    BankAccountInput,
    BankAccountPurpose,
    Currency,
} from 'interfaces/model';
import { selectOptionFactory } from 'lib/inputs/Select.const';
import { VALIDATION_MESSAGES } from 'lib/forms';

const fieldsToMap = (value: BankAccountField[]) =>
    Object.fromEntries(value.map(({ fieldValue, fieldType }) => [fieldType, fieldValue])) as Record<
        BankAccountFieldType,
        string
    >;

const mapToFields = (value: Record<BankAccountFieldType, string>) =>
    Object.entries(value).map(([fieldType, fieldValue]) => ({
        fieldType,
        fieldValue,
    })) as BankAccountField[];

export const prepareBankAccountModelValue = (values: IBankAccountForm): BankAccountInput => {
    return {
        id: values.id || uuid(),
        accountName: values.accountName || '',
        purpose: values.purpose,
        currency: values.currency,
        accountFields: mapToFields(values.accountFields),
        correspondingFields: mapToFields(values.correspondingFields),
        intermediaryFields: mapToFields(values.intermediaryFields),
    };
};

export type IBankAccountForm = ReturnType<typeof getInitialValues>;

type IPurposeCurrencyCombinationTuple = [BankAccountPurpose | undefined, Currency | undefined];

const getInitialPurposeCurrency = (
    bankAccount: MaybeUndefined<BankAccountInput>,
    availableCurrencies: Currency[],
    isExistingCombination: (purpose: BankAccountPurpose | undefined, currency: Currency | undefined) => boolean
): IPurposeCurrencyCombinationTuple => {
    if (bankAccount) {
        return [bankAccount.purpose, bankAccount.currency] as IPurposeCurrencyCombinationTuple;
    }

    return Object.values(BankAccountPurpose).reduce((acc, purpose) => {
        if (!acc[0] || !acc[1]) {
            const currency = (availableCurrencies || Object.values(Currency)).find(
                (item) => !isExistingCombination(purpose, item)
            ) as Currency | undefined;
            return [purpose, currency] as IPurposeCurrencyCombinationTuple;
        }
        return acc;
    }, [] as unknown as [BankAccountPurpose | undefined, Currency | undefined]);
};

export const getInitialValues = (
    bankAccount: MaybeUndefined<BankAccountInput>,
    availableCurrencies: Currency[],
    isExistingCombination: (purpose: BankAccountPurpose | undefined, currency: Currency | undefined) => boolean
) => {
    const [purpose, currency] = getInitialPurposeCurrency(bankAccount, availableCurrencies, isExistingCombination);

    return {
        id: bankAccount?.id,
        accountName: bankAccount?.accountName,
        purpose,
        currency,
        accountFields: fieldsToMap(bankAccount?.accountFields || []),

        intermediaryFields: fieldsToMap(bankAccount?.intermediaryFields || []),
        intermediary: !!bankAccount?.intermediaryFields?.length,

        correspondingFields: fieldsToMap(bankAccount?.correspondingFields || []),
        corresponding: !!bankAccount?.correspondingFields?.length,
    };
};

const purposeOptionFactory = selectOptionFactory(BankAccountPurpose, bankAccountPurposeLabels);
export const getPurposeOptions = (availablePurposes: BankAccountPurpose[]) =>
    availablePurposes.map(purposeOptionFactory);

const currencyOptionFactory = selectOptionFactory(Currency, Currency);
export const getCurrencyOptions = (availableCurrencies: Currency[]) => availableCurrencies.map(currencyOptionFactory);

export const ACCOUNT_FIELDS: Record<string, Record<string, BankAccountFieldType[]>> = {
    BENEFICIARY: {
        GBP: [
            BankAccountFieldType.ACCOUNT_NAME,
            BankAccountFieldType.BANK_NAME,
            BankAccountFieldType.ADDRESS,
            BankAccountFieldType.BIC,
            BankAccountFieldType.IBAN,
            BankAccountFieldType.ABA,
            BankAccountFieldType.ACCOUNT_NUMBER,
            BankAccountFieldType.SORT_CODE,
            BankAccountFieldType.FFC_ACCOUNT_NUMBER,
            BankAccountFieldType.FFC_NAME,
        ],
        USD: [
            BankAccountFieldType.ACCOUNT_NAME,
            BankAccountFieldType.BANK_NAME,
            BankAccountFieldType.ADDRESS,
            BankAccountFieldType.BIC,
            BankAccountFieldType.IBAN,
            BankAccountFieldType.ABA,
            BankAccountFieldType.FFC_ACCOUNT_NUMBER,
            BankAccountFieldType.FFC_NAME,
        ],
        AUD: [
            BankAccountFieldType.ACCOUNT_NAME,
            BankAccountFieldType.BANK_NAME,
            BankAccountFieldType.ADDRESS,
            BankAccountFieldType.BIC,
            BankAccountFieldType.IBAN,
            BankAccountFieldType.ABA,
            BankAccountFieldType.ACCOUNT_NUMBER,
            BankAccountFieldType.BSB,
            BankAccountFieldType.FFC_ACCOUNT_NUMBER,
            BankAccountFieldType.FFC_NAME,
        ],
        OTHER: [
            BankAccountFieldType.ACCOUNT_NAME,
            BankAccountFieldType.BANK_NAME,
            BankAccountFieldType.ADDRESS,
            BankAccountFieldType.BIC,
            BankAccountFieldType.IBAN,
            BankAccountFieldType.FFC_ACCOUNT_NUMBER,
            BankAccountFieldType.FFC_NAME,
        ],
    },
    INTERMEDIARY: {
        GBP: [BankAccountFieldType.BANK_NAME, BankAccountFieldType.ABA, BankAccountFieldType.BIC],
        USD: [BankAccountFieldType.BANK_NAME, BankAccountFieldType.ABA, BankAccountFieldType.BIC],
        AUD: [BankAccountFieldType.BANK_NAME, BankAccountFieldType.ABA, BankAccountFieldType.BIC],
        OTHER: [BankAccountFieldType.BANK_NAME, BankAccountFieldType.IBAN, BankAccountFieldType.BIC],
    },
    CORRESONDING: {
        GBP: [
            BankAccountFieldType.BANK_NAME,
            BankAccountFieldType.BIC,
            BankAccountFieldType.IBAN,
            BankAccountFieldType.SORT_CODE,
        ],
        USD: [
            BankAccountFieldType.BANK_NAME,
            BankAccountFieldType.BIC,
            BankAccountFieldType.IBAN,
            BankAccountFieldType.ABA,
        ],
        AUD: [
            BankAccountFieldType.BANK_NAME,
            BankAccountFieldType.BIC,
            BankAccountFieldType.IBAN,
            BankAccountFieldType.BSB,
        ],
        OTHER: [BankAccountFieldType.BANK_NAME, BankAccountFieldType.BIC, BankAccountFieldType.IBAN],
    },
};

const accountFields: Record<BankAccountFieldType, AnySchema> = {
    BIC: yup.string(),
    SWIFT: yup.string(),
    IBAN: yup.string(),
    SORT_CODE: yup.string(),
    BSB: yup.string(),
    ABA: yup.string(),
    BANK_NAME: yup.string(),
    ADDRESS: yup.string(),
    ACCOUNT_NAME: yup.string(),
    ACCOUNT_NUMBER: yup.string(),
    FFC_ACCOUNT_NUMBER: yup.string(),
    FFC_NAME: yup.string(),
};

export const validationSchema = yup.object({
    id: yup.string(),
    accountName: yup.string(),
    currency: yup.mixed<Currency>().required(VALIDATION_MESSAGES.REQUIRED),
    purpose: yup.mixed<BankAccountPurpose>().required(VALIDATION_MESSAGES.REQUIRED),
    accountFields: yup.object(accountFields),
    correspondingFields: yup.object(accountFields),
    intermediaryFields: yup.object(accountFields),
});
