import { useParams } from 'react-router';
import React, { useCallback } from 'react';
import { Box, CircularProgress } from '@mui/material';
import {
    AgentAclInInput,
    DocPurpose,
    DocumentStorage,
    EntityType,
    IdType,
    useSetEntityMutation,
    useViewEntityQuery,
    ViewEntityFragment,
} from '../../../../interfaces/model';
import { DataErrorPlaceholder } from 'lib/dataDisplay/DataErrorPlaceholder';
import { EntityFacilities } from './EntityFacilities';
import { EntityRoles } from './EntityRoles';
import { LegalEntities } from './LegalEntities';
import {
    isEntityWithBankAccounts,
    isFundAdminEntity,
    isLenderEntity,
    isLenderEntityType,
    isSponsorEntity,
} from '../Entities.helpers';
import { useElementFocusByQueryParamEffect } from 'lib/hooks/useElementFocusByQueryParamEffect';
import { ParentContextProvider } from 'lib/cdk/ParentContext';
import { FundsEmbeddedList } from '../../Funds/FundsEmbeddedList';
import { SponsoredEntitiesList } from './ViewEntityDetails/SponsoredEntitiesList';
import { isTopCoModel } from '../../../helpers/isTopCoModel';
import { EntityBankAccounts } from './EntityBankAccounts';
import { ViewEntityHeading } from './ViewEntityHeading';
import { ViewEntityDetails } from './ViewEntityDetails';
import { isPrivate } from '../../Facilities/tabs/FacilitiesTab/ViewFacility/helpers/isPrivate';
import { RoleEditDialog } from '../../Roles/RoleEditDialog';
import { AgentEntitiesList } from './AgentEntitiesList/AgentEntitiesList';
import { EntityFundAgents } from '../../../common/EntityFundAgents/EntityFundAgents';
import { IDocumentsListParent } from '../../../components/Documents/interfaces/IDocumentsListParent';
import { FundEntityCounterpartyKYC } from './FundEntityCounterpartyKYC/FundEntityCounterpartyKYC';
import { AddExistingRolesDialog } from './AddExistingRolesDialog/AddExistingRolesDialog';
import { DocumentsList } from '../../../components/Documents/DocumentsList';
import { EntityTaxIdsList } from './EntityTaxIdsList';
import { OtotDisplay } from './OtotDisplay';

const showEntityKYC = (entity: ViewEntityFragment): boolean => {
    return (entity.kycRequirements?.length || 0) > 0;
};

const showEntityAgents = (entity: ViewEntityFragment): boolean => {
    return [
        EntityType.BORROWER_TOP_LEVEL,
        EntityType.BORROWER_SUBSIDIARY,
        EntityType.LENDER_TOP_LEVEL,
        EntityType.LENDER_INVESTMENT_BANK,
        EntityType.LENDER_INVESTMENT_MANAGER,
    ].includes(entity.entityType);
};

const showFacilities = (entity: ViewEntityFragment): boolean =>
    [
        EntityType.AGENT_TOP_LEVEL,
        EntityType.AGENT,
        EntityType.BORROWER_TOP_LEVEL,
        EntityType.BORROWER_SUBSIDIARY,
        EntityType.SPONSOR_TOP_LEVEL,
        EntityType.SPONSOR,
    ].includes(entity.entityType);

const getSponsorsRefs = (entity: ViewEntityFragment) => {
    if (entity.id) {
        return [entity.id, ...(entity.childs?.map(({ id }) => id) || [])];
    }
    return [];
};

const ENTITIES_WITH_SUB_ENTITIES = [
    EntityType.FUNDADMIN_TOP_LEVEL,
    EntityType.BORROWER_TOP_LEVEL,
    EntityType.LENDER_TOP_LEVEL,
    EntityType.SPONSOR_TOP_LEVEL,
    EntityType.AGENT_TOP_LEVEL,
];

interface IViewEntityProps {
    config?: {
        counterparties?: boolean;
        breadcrumbsInteractive?: boolean;
    };
}

export const ViewEntity: React.FC<IViewEntityProps> = ({ config }) => {
    const [mutateEntity] = useSetEntityMutation();
    const params = useParams<{ sc?: string }>();
    const shortCode = (params.sc || '').toLowerCase();
    const { data, loading, error, refetch } = useViewEntityQuery({
        fetchPolicy: 'no-cache',
        variables: { id: shortCode },
        skip: !shortCode,
    });

    useElementFocusByQueryParamEffect();

    const updateData = useCallback(async () => {
        await refetch();
    }, [refetch]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" marginTop="30vh">
                <CircularProgress color="primary" />
            </Box>
        );
    }

    if (error) {
        return <DataErrorPlaceholder error={error} onRetry={updateData} />;
    }

    const entity = data!.entity!; // TODO get rid of type assertion here
    const isSponsor = isSponsorEntity(entity);
    const showTaxNumbers = ![
        EntityType.SPONSOR_TOP_LEVEL,
        EntityType.SPONSOR,
        EntityType.FUNDADMIN_TOP_LEVEL,
        EntityType.FUNDADMIN,
    ].includes(entity.entityType);
    const isTopCo = isTopCoModel(entity);

    const displayCounterpartyKYC =
        entity.entityType === EntityType.LENDER_TOP_LEVEL || entity.entityType === EntityType.LENDER_INVESTMENT_BANK;

    const handleAgentSubmit = async (editedAgent: AgentAclInInput) => {
        await mutateEntity({
            variables: {
                entity: {
                    id: entity.id,
                    assignedAgents: [
                        ...(entity.assignedAgentAcls || []).map((item) => {
                            return {
                                entityRef: item.entity?.id || '',
                                capabilities: item.capabilities || [],
                            };
                        }),
                        editedAgent,
                    ],
                },
            },
        });
    };

    const handleAgentDelete = async (agentRef: UUID) => {
        const newAgents = (entity.assignedAgentAcls || [])
            .filter((item) => item.entity?.id !== agentRef)
            .map((item) => {
                return {
                    entityRef: item.entity?.id || '',
                    capabilities: item.capabilities,
                };
            });
        await mutateEntity({
            variables: {
                entity: {
                    id: entity.id,
                    assignedAgents: newAgents,
                },
            },
        });
    };

    if (entity.deleted) {
        return <ViewEntityHeading entity={entity} />;
    }

    const kycListParent: IDocumentsListParent = {
        name: entity.name,
        id: entity.id,
        type: IdType.ENTITY,
        topCoRef: entity?.topCoRef,
        hasDocumentEditAccess: entity.hasDocumentEditAccess,
        hasDocumentApproveAccess: entity.hasDocumentApproveAccess,
        update: updateData,
    };

    return (
        <ParentContextProvider onUpdate={updateData}>
            <ViewEntityHeading entity={entity} config={config} />
            <ViewEntityDetails entity={entity} className={'mb-32 mt-16'} config={config} />
            {displayCounterpartyKYC && <FundEntityCounterpartyKYC value={entity} />}
            {entity.agentAcls && showEntityAgents(entity) && (
                <EntityFundAgents
                    agents={entity.agentAcls}
                    canEditAgents={entity.hasAgentsEditAccess}
                    onDeleteConfirm={handleAgentDelete}
                    onSave={handleAgentSubmit}
                />
            )}
            {!isPrivate(entity.kycs) && showEntityKYC(entity) && (
                <DocumentsList
                    value={entity.kycs || []}
                    parent={kycListParent}
                    config={{
                        storage: DocumentStorage.KYC,
                        purpose: DocPurpose.KYC,
                    }}
                />
            )}
            {!isTopCo && showTaxNumbers && entity.taxIds && (
                <EntityTaxIdsList entity={entity} updateData={updateData} />
            )}

            {!isPrivate(entity.bankAccounts) && isEntityWithBankAccounts(entity) && (
                <Box marginBottom={'20px'}>
                    <EntityBankAccounts entity={entity} />
                </Box>
            )}

            {isLenderEntity(entity) && !!entity.otot && <OtotDisplay content={entity.otot!} />}

            {!isPrivate(entity.childs) && ENTITIES_WITH_SUB_ENTITIES.includes(entity.entityType) && (
                <LegalEntities testId={'ViewEntity:subEntitiesList'} className={'mb-16'} parentEntity={entity} />
            )}

            {!isPrivate(entity.roles) && entity.entityType !== EntityType.UBO && (
                <EntityRoles
                    className={'mb-16'}
                    entity={entity}
                    updateData={updateData}
                    data={entity.roles || []}
                    testId={'ViewEntity:rolesList'}
                />
            )}

            {entity.entityType === EntityType.AGENT && <AgentEntitiesList entity={entity} />}

            {!isPrivate(entity.childs) && isSponsor && (
                <SponsoredEntitiesList sponsorRefs={getSponsorsRefs(entity)} testId={'ViewEntity:sponsorsList'} />
            )}

            {!isPrivate(entity.facilities) && showFacilities(entity) && (
                <EntityFacilities parentEntity={entity} reloadData={updateData} testId={'ViewEntity:facilitiesList'} />
            )}

            {!isPrivate(entity.loanFunds) &&
                ((isLenderEntityType(entity.entityType) && entity.entityType !== EntityType.LENDER_INVESTMENT_BANK) ||
                    isFundAdminEntity(entity)) && (
                    <FundsEmbeddedList
                        testId={'ViewEntity:fundsList'}
                        funds={[
                            ...entity.loanFunds,
                            ...(entity.childs || []).flatMap((child) => child.loanFunds || []),
                        ]}
                        entityRef={entity.entityType === EntityType.LENDER_INVESTMENT_MANAGER ? entity.id : null}
                        canCreate={entity.caps.createFund}
                        topCoRef={entity.topCoRef}
                        reloadData={updateData}
                    />
                )}
            <RoleEditDialog onSaveSuccess={updateData} />
            <AddExistingRolesDialog entity={entity} />
        </ParentContextProvider>
    );
};
