import { KYCDocCategory, KYCDocSubcategory, KYCDocType, KYCDocumentFragment } from '../../../../../../interfaces/model';
import set from 'lodash/set';
import { fromDateISO } from '../../../../../../lib/utils/date';

export type Year = string;

export const DEFAULT_SUBCATEGORY = 'defaultSubcategory';
export const DEFAULT_YEAR = 'Unknown';

export type IDocSubcategory = KYCDocSubcategory | typeof DEFAULT_SUBCATEGORY;

export type IDocumentsTree = Record<
    KYCDocCategory,
    Record<KYCDocType, Record<IDocSubcategory, Record<Year, KYCDocumentFragment[]>>>
>;

export const getGroupedDocumentsTree = (value: KYCDocumentFragment[]): IDocumentsTree => {
    return value.reduce((acc, item) => {
        const { docCategory, validFromDate, docSubcategory, docType, createdAt } = item;
        const year = (fromDateISO(validFromDate) || new Date(createdAt))?.getFullYear();
        set(acc, [docCategory, docType, docSubcategory || DEFAULT_SUBCATEGORY, year || DEFAULT_YEAR], item);
        return acc;
    }, {} as IDocumentsTree);
};
