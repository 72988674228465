import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ListCellItemWrapper } from '../../../../../../../lib/dataDisplay/List/components/ListCellItemWrapper';
import { useControlledUsersLog } from '../../../../../../components/AppHeader/ProfileButton/RecentUsersMenu/useControlledUsersLog';
import { useControlledUserSwitch } from '../../../../../../components/AppHeader/ProfileButton/useControlledUserSwitch';
import { Role, useSetRoleMutation } from '../../../../../../../interfaces/model';
import { useConfirmDialog } from '../../../../../../../lib/modals/ConfirmDialog';
import { useAppSnackbar } from '../../../../../../components/AppNotification';
import { useUserDetails } from '../../../../../../hooks/useUserDetails';
import { NonFalsy } from '../../../../../../../lib/utils/helpers';

interface IRoleListRowActionsMenuProps {
    role: Pick<Role, 'id' | 'shortCode' | 'name' | 'deactivated' | 'hasSwitchAccess'>;
    updateData: Awaited<() => void>;
}

export const RoleListRowActionsMenu: React.FC<IRoleListRowActionsMenuProps> = ({ role, updateData }) => {
    const anchorEl = useRef(null);
    const snackBar = useAppSnackbar();
    const { isAdmin } = useUserDetails();
    const [mutateRole] = useSetRoleMutation();
    const [confirmDialog] = useConfirmDialog();
    const [isOpen, setIsOpen] = useState(false);
    const [switchUser] = useControlledUserSwitch();
    const [, logControlledUser] = useControlledUsersLog();

    const isActive = !role.deactivated;
    const activeActionLabel = isActive ? 'Deactivate' : 'Activate';

    const toggleUserActiveness = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        confirmDialog.open({
            body: `You are about to ${activeActionLabel.toLowerCase()} a role. Are you sure?`,
            title: `${activeActionLabel} "${role.name}" role`,
            onConfirm: async () => {
                try {
                    await mutateRole({
                        variables: {
                            role: {
                                id: role.id,
                                deactivated: !role.deactivated,
                            },
                        },
                    });
                    setIsOpen(false);
                    updateData();
                    snackBar.show({ message: `Role has been ${activeActionLabel.toLowerCase()}d` });
                } catch (e) {
                    snackBar.showError({ message: `Failed to ${activeActionLabel.toLowerCase()} the role` }, e);
                }
            },
        });
    };

    const switchToUser = async (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        await switchUser(role);
        setIsOpen(false);
        await updateData();
        logControlledUser(role);
    };

    const items = [
        isAdmin && (
            <MenuItem key={'toggleActive'} onClick={toggleUserActiveness}>
                {activeActionLabel} user
            </MenuItem>
        ),
        role?.hasSwitchAccess && (
            <MenuItem key={'switchToUser'} onClick={switchToUser}>
                Switch to this user
            </MenuItem>
        ),
    ].filter(NonFalsy);

    if (items.length === 0) {
        return null;
    }

    return (
        <ListCellItemWrapper>
            <IconButton
                ref={anchorEl}
                size={'small'}
                color={'secondary'}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsOpen(true);
                }}
            >
                <MoreHorizIcon fontSize={'inherit'} />
            </IconButton>
            <Menu
                open={isOpen}
                anchorEl={anchorEl.current}
                sx={{ width: '280px' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: -4, horizontal: 'right' }}
                onClose={(e: MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsOpen(!isOpen);
                }}
            >
                {items}
            </Menu>
        </ListCellItemWrapper>
    );
};
