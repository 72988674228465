import React from 'react';
import { KYCDocType, KYCDocumentFragment } from '../../../../../../../../interfaces/model';
import { IDocSubcategory, Year } from '../../getGroupedDocumentsTree';
import { kycDocTypeLabels } from '../../../../../../../App.const';
import { getEntries } from '../DocumentsGroupsTree.helpers';
import { TreeFolder } from './Tree/TreeFolder';

export const DocTypes: React.FC<{
    value: Record<KYCDocType, Record<IDocSubcategory, Record<Year, KYCDocumentFragment[]>>>;
    children: (
        docType: KYCDocType,
        docTypeItems: Record<IDocSubcategory, Record<Year, KYCDocumentFragment[]>>,
        offset: number
    ) => React.ReactNode;
    offset: number;
}> = ({ value, offset, children }) => (
    <>
        {getEntries(value).map(([docType, docTypeItems]) => (
            <TreeFolder key={docType} id={docType} label={kycDocTypeLabels[docType]} offset={offset}>
                {children(docType, docTypeItems, offset + 1)}
            </TreeFolder>
        ))}
    </>
);
