import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Tooltip } from '@mui/material';
import { DOCUMENTS_ROUTE } from '../App.routes';

interface IDataRoomLinkProps {}

export const DataRoomLink: React.FC<IDataRoomLinkProps> = () => {
    return (
        <Tooltip title={'Documents'} arrow>
            <IconButton color={'primary'} size={'large'} component={Link} to={DOCUMENTS_ROUTE}>
                <FolderOutlinedIcon fontSize={'inherit'} />
            </IconButton>
        </Tooltip>
    );
};
