import React from 'react';
import { AppEmbeddedListWrap } from '../../../../components/AppEmbeddedListWrap';
import {
    FundEntityCounterpartyKYCList,
    IEntityCounterpartyKYCListChild,
    IEntityCounterpartyKYCListProps,
    IFundEntityCounterpartyKYCListValue,
} from './FundEntityCounterpartyKYCList';
import { useUserDetails } from '../../../../hooks/useUserDetails';
import { EntityType } from '../../../../../interfaces/model';
import { NonFalsy } from '../../../../../lib/utils/helpers';
import {
    FundEntityCounterpartyKYCTargetsFragment,
    useFundEntityCounterpartyKYCTargetsQuery,
} from './FundEntityCounterpartyKYCTargets.query';

interface IFundEntityCounterpartyKYCProps {
    value: IFundEntityCounterpartyKYCListValue;
}

const getValueTargetsIds = (fundEntity: IEntityCounterpartyKYCListProps['value']): UUID[] => {
    const isTopCo = fundEntity.id === fundEntity.topCoRef;
    const isFund = !fundEntity.entityType;
    const getTargetRefs = ({ id, entityType, loanFunds }: IEntityCounterpartyKYCListChild) =>
        entityType === EntityType.LENDER_INVESTMENT_BANK || isFund
            ? [id]
            : loanFunds?.map((fund) => fund.id).filter(NonFalsy) || [];

    if (isTopCo) {
        return fundEntity.childs?.flatMap(getTargetRefs) || [];
    }
    return getTargetRefs(fundEntity);
};

const getTopCoTargetsIds = (topCo: FundEntityCounterpartyKYCTargetsFragment | undefined): UUID[] => {
    return (
        topCo?.childs?.flatMap((child) => {
            return child.entityType === EntityType.LENDER_INVESTMENT_BANK
                ? child.id
                : child.loanFunds?.map(({ id }) => id).filter(NonFalsy) || [];
        }) || []
    );
};

export const FundEntityCounterpartyKYC: React.FC<IFundEntityCounterpartyKYCProps> = ({ value }) => {
    const { role } = useUserDetails();
    const isMyCompany = value.topCoRef === role?.topCoRef;
    const isLenderUser = role?.topCo?.entityType === EntityType.LENDER_TOP_LEVEL;

    const { data } = useFundEntityCounterpartyKYCTargetsQuery({
        fetchPolicy: 'cache-and-network',
        skip: !role?.topCoRef || isMyCompany || !isLenderUser,
        variables: {
            topCoRef: role?.topCoRef!,
        },
    });

    if (!isMyCompany && !isLenderUser) {
        return null;
    }

    const targets = !isMyCompany && isLenderUser ? getTopCoTargetsIds(data?.entity) : getValueTargetsIds(value);

    return (
        <AppEmbeddedListWrap title={'Counterparty KYC'}>
            <FundEntityCounterpartyKYCList value={value} targets={targets} otherCompanyViewMode={!isMyCompany} />
        </AppEmbeddedListWrap>
    );
};
