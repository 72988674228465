import React from 'react';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import { useFormikContext } from 'formik';
import { List, ListItem } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IRoleForm } from '../../useRoleEditFormik/useRoleEditFormik.const';
import CircleIcon from '@mui/icons-material/Circle';

const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 20;

export const PASSWORD_REQUIREMENTS_VALIDATORS = [
    {
        id: 'digits',
        message: 'Password must contain at least one digit [0-9]',
        test: (password: string | undefined) => isString(password) && !!password.match(/[0-9]/),
    },
    {
        id: 'lowerCaseLatin',
        message: 'Password must contain at least one lowercase Latin character [a-z]',
        test: (password: string | undefined) => isString(password) && !!password.match(/[a-z]/),
    },
    {
        id: 'upperCaseLatin',
        message: 'Password must contain at least one uppercase Latin character [A-Z]',
        test: (password: string | undefined) => isString(password) && !!password.match(/[A-Z]/),
    },
    {
        id: 'specialCharacter',
        message: 'Password must contain at least one special character like ! @ # & ( )',
        test: (password: string | undefined) => isString(password) && !!password.match(/[!@#&()]/),
    },
    {
        id: 'minLength',
        message: `Password must contain a length of at least ${MIN_PASSWORD_LENGTH} characters`,
        test: (password: string | undefined) => isString(password) && password.length >= MIN_PASSWORD_LENGTH,
    },
    {
        id: 'maxLength',
        message: `Password must contain a length of maximum ${MAX_PASSWORD_LENGTH} characters`,
        test: (password: string | undefined) => isString(password) && password.length <= MAX_PASSWORD_LENGTH,
    },
];

export const PasswordRequirements: React.FC = () => {
    const formik = useFormikContext<IRoleForm>();
    const errors = formik.errors.password;
    const errorsKeys = isObject(errors) ? Object.keys(errors) : [];

    return (
        <List disablePadding>
            {PASSWORD_REQUIREMENTS_VALIDATORS.map(({ id, message }) => (
                <ListItem key={id} sx={{ paddingLeft: 0 }}>
                    {errorsKeys.includes(id) ? (
                        <CircleIcon color={'secondary'} fontSize={'small'} />
                    ) : (
                        <CheckCircleIcon color={'success'} fontSize={'small'} />
                    )}
                    <span className={'ml-8'}>{message}</span>
                </ListItem>
            ))}
        </List>
    );
};
