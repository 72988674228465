import React from 'react';
import { IClientOnboardingStepComponent } from '../../interfaces/IClientOnboardingStepComponent';
import { ClientOnboardingContentFrame } from '../../ClientOnboardingContentFrame';
import { LegalEntitiesSetupNextButton } from './LegalEntitiesSetupNextButton';
import { useLegalEntitiesList } from '../../ClientOnboarding.const';
import { LegalEntitiesSetupForm } from './LegalEntitiesSetupForm';
import { RoleEditDialog } from '../../../../Roles/RoleEditDialog';
import { voidWrapper } from '../../../../../../lib/utils/helpers';
import { useEntityWizardDialog } from '../../../../Entities/CreateEntityWizardDialog';
import { getChildEntityType } from '../../../../Entities/ViewEntity/LegalEntities/LegalEntities.helpers';
import { DocumentStorage, EntityType } from '../../../../../../interfaces/model';
import { DocumentUploadDialog } from '../../../../../components/Documents/components/DocumentUploadDialog';
import { NonApplicableDocumentDialog } from '../../../../../components/Documents/components/CreateNonApplicableDocumentDialog';
import { AddDocumentsDialog } from '../../../../../components/Documents/components/AddDocumentsDialog';

const getHintText = (parentEntityType: EntityType) => {
    switch (parentEntityType) {
        case EntityType.BORROWER_TOP_LEVEL:
        case EntityType.AGENT_TOP_LEVEL:
        case EntityType.FUNDADMIN_TOP_LEVEL:
        case EntityType.SPONSOR_TOP_LEVEL:
            return 'You need to add at least one legal entity of the parent company';
        case EntityType.LENDER_TOP_LEVEL:
            return (
                'First we need to add either the bank or the fund management company you want to onboard, then ' +
                "we'll add the details of the funds you want to trade for"
            );
    }
};

interface ILegalEntitiesSetupProps extends IClientOnboardingStepComponent {
    onFooterButtonClick?: () => void;
    noPreview?: boolean;
}

export const LegalEntitiesSetup: React.FC<ILegalEntitiesSetupProps> = ({
    stepIndex,
    complete,
    disabled,
    topCo,
    onFooterButtonClick,
    noPreview,
}) => {
    const topCoRef = topCo?.id || '';
    const query = useLegalEntitiesList(topCoRef, topCo?.entityType || EntityType.BORROWER_TOP_LEVEL);
    const [editDialog] = useEntityWizardDialog();
    const handleAdd = () =>
        editDialog.open({
            topCoRef,
            parentRef: topCoRef,
            parentEntityType: topCo?.entityType,
            entityType: getChildEntityType(topCo?.entityType),
        });
    const dataLength = query.data?.entities.total || 0;
    const updateData = voidWrapper(query.refetch);

    return (
        <ClientOnboardingContentFrame
            label={'Legal Entities'}
            description={getHintText(topCo?.entityType || EntityType.BORROWER_TOP_LEVEL)}
            error={query.error}
            action={
                <LegalEntitiesSetupNextButton
                    stepIndex={stepIndex}
                    disabled={!!query.error || disabled}
                    dataLength={dataLength}
                    complete={complete}
                    onDialogConfirm={handleAdd}
                    onClick={onFooterButtonClick}
                />
            }
        >
            <LegalEntitiesSetupForm
                topCo={topCo}
                entityType={topCo?.entityType || EntityType.BORROWER_TOP_LEVEL}
                query={query}
                noPreview={noPreview}
            />
            <RoleEditDialog onSaveSuccess={updateData} />
            <DocumentUploadDialog config={{ storage: DocumentStorage.KYC }} updateData={updateData} />
            <NonApplicableDocumentDialog onSaveSuccess={updateData} />
            <AddDocumentsDialog updateData={updateData} />
        </ClientOnboardingContentFrame>
    );
};
