import React from 'react';
import { EditEntityForm } from '../../EditEntityForm';

interface IEntityKeyInformationStepProps {
    config?: {
        authorisedSignatorySelect?: boolean;
    };
}

export const EntityKeyInformationStep: React.FC<IEntityKeyInformationStepProps> = ({ config }) => {
    return <EditEntityForm config={config} />;
};
