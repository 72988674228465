import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Form, FormikProvider, useFormik } from 'formik';
import { PopoverProps } from '@mui/material/Popover/Popover';
import { DialogActions, DialogContent, DialogTitle, Popover } from '@mui/material';
import { DocumentKYCDetailsForm, documentKycDetailsFormValidationSchema } from './DocumentKYCDetailsForm';
import { LoadingSpinnerButton } from '../../../../../../../../lib/buttons/LoadingSpinnerButton';
import { DialogCloseButton } from '../../../../../../../../lib/modals/DialogCloseButton';
import {
    KYCDocSubcategory,
    KYCDocType,
    KYCDocumentFragment,
    ReviewAction,
    useReviewDocumentMutation,
} from '../../../../../../../../interfaces/model';
import { NOOP } from '../../../../../../../../lib/utils/helpers';
import { useAppSnackbar } from '../../../../../../AppNotification';
import { proxyAPIValidationError } from '../../../../../../../../lib/forms';

interface IDocumentKYCPopupProps {
    anchorEl: PopoverProps['anchorEl'];
    open: boolean;
    docType: KYCDocType | undefined;
    value: Pick<
        KYCDocumentFragment,
        'id' | 'docType' | 'validFromDate' | 'validToDate' | 'docSubcategory' | 'isPrivate'
    >;
    parentRef: UUID;
    docSubcategory?: Maybe<KYCDocSubcategory>;
    onSaveSuccess: () => void;
    onClose: () => void;
}

const getInitialValues = (document: IDocumentKYCPopupProps['value']) => {
    return {
        docType: document.docType,
        id: document.id,
        docSubcategory: document.docSubcategory,
        isPrivate: document.isPrivate,
        status: ReviewAction.AMBER,
        validToDate: document.validToDate || void 0,
        asAdmin: void 0,
        comment: '',
    };
};

export const DocumentKYCPopup: React.FC<IDocumentKYCPopupProps> = ({
    open,
    docType,
    value,
    anchorEl,
    parentRef,
    onSaveSuccess,
    onClose,
}) => {
    const snackbar = useAppSnackbar();
    const [reviewDocument, { error }] = useReviewDocumentMutation();

    const [prevValues, setPrevValues] = useState<Partial<typeof initialValues>>({});
    const initialValues = getInitialValues(value);
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validate: proxyAPIValidationError(prevValues, error),
        validationSchema: documentKycDetailsFormValidationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setPrevValues(values);
            setSubmitting(true);
            try {
                await reviewDocument({
                    variables: {
                        id: formik.values.id,
                        status: formik.values.status,
                        comment: formik.values.comment,
                        asAdmin: formik.values.asAdmin,
                    },
                });
                snackbar.show({ message: 'Review has been updated' });
                formik.resetForm();
                onSaveSuccess();
            } catch (e) {
                setSubmitting(false);
                snackbar.showError({ message: 'Failed to submit KYC' }, e);
            }
        },
    });

    const clearAndClose = () => {
        formik.resetForm();
        onClose();
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            BackdropProps={{ invisible: false }}
            onClose={formik.isSubmitting ? NOOP : clearAndClose}
        >
            <FormikProvider value={formik}>
                <Box component={Form}>
                    <DialogTitle>
                        Submit review
                        <DialogCloseButton onClick={clearAndClose} />
                    </DialogTitle>
                    <DialogContent>
                        <DocumentKYCDetailsForm />
                    </DialogContent>
                    <DialogActions>
                        <LoadingSpinnerButton type={'submit'} label={'Submit'} spin={formik.isSubmitting} />
                    </DialogActions>
                </Box>
            </FormikProvider>
        </Popover>
    );
};
