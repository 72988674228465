import { ClientType, EmailNotificationType, EntityType } from '../../../interfaces/model';

export const CLIENT_TYPE_BY_ENTITY_TYPE_MAP: Record<EntityType, ClientType> = {
    AGENT: ClientType.AGENT,
    AGENT_TOP_LEVEL: ClientType.AGENT,
    BORROWER_SUBSIDIARY: ClientType.BORROWER,
    BORROWER_TOP_LEVEL: ClientType.BORROWER,
    LENDER_INVESTMENT_BANK: ClientType.LENDER,
    LENDER_INVESTMENT_MANAGER: ClientType.LENDER,
    LENDER_TOP_LEVEL: ClientType.LENDER,
    SPONSOR: ClientType.SPONSOR,
    SPONSOR_TOP_LEVEL: ClientType.SPONSOR,
    UBO: ClientType.UNDEFINED,
    UNKNOWN: ClientType.UNDEFINED,
    FUNDADMIN_TOP_LEVEL: ClientType.FUND_ADMIN,
    FUNDADMIN: ClientType.FUND_ADMIN,
};

export const emailNotificationTypeLabels: Record<EmailNotificationType, string> = {
    TRADE_DOCS: 'Trade documents',
    PIK_ELECTION: 'PIK/CASH election',
    PIK_ELECTION_DONE: 'PIK/Cash election done',
    PIK_INTEREST: 'PIK interest',
    CASH_INTEREST: 'Cash interest',
    BANK_ACCOUNTS: 'Bank account details',
    RISK_FREE_RATE_FIX_DATE: 'Rate fixing',
    TRADE_SETTLED: 'Trade settled',
    PAYMENT_SEND: 'Send payment',
    PAYMENT_RECONCILE: 'Reconcile payment',
    ACCRUAL_REQUEST_CREATED: 'Utilisation/repayment request created',
    ACCRUAL_PAYMENT_CREATED: 'Utilisation/repayment created',
    ACCRUAL_PAYMENT_APPROVED: 'Utilisation/repayment approved',
    IU_AGENT: "Agents' Information Undertakings notifications",
    IU_BORROWER: "Borrowers' Information Undertakings notifications",
    IU_LENDER: "Lenders' Information Undertakings notifications",
    VOTING_START: 'Start of Corporate Action',
};

// represents which entity types can be in the 'entity relations' section of the role edit page
export const ROLE_ENTITY_TYPES_MAP: Partial<Record<EntityType, EntityType[]>> = {
    LENDER_TOP_LEVEL: [EntityType.LENDER_INVESTMENT_BANK, EntityType.LENDER_INVESTMENT_MANAGER],
    LENDER_INVESTMENT_BANK: [EntityType.LENDER_INVESTMENT_BANK, EntityType.LENDER_INVESTMENT_MANAGER],
    LENDER_INVESTMENT_MANAGER: [EntityType.LENDER_INVESTMENT_BANK, EntityType.LENDER_INVESTMENT_MANAGER],
    BORROWER_TOP_LEVEL: [EntityType.BORROWER_SUBSIDIARY],
    BORROWER_SUBSIDIARY: [EntityType.BORROWER_SUBSIDIARY],
    SPONSOR_TOP_LEVEL: [EntityType.SPONSOR],
    SPONSOR: [EntityType.SPONSOR],
    AGENT_TOP_LEVEL: [EntityType.AGENT],
    AGENT: [EntityType.AGENT],
    FUNDADMIN_TOP_LEVEL: [EntityType.FUNDADMIN],
    FUNDADMIN: [EntityType.FUNDADMIN],
};
