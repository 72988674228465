import React, { useEffect } from 'react';
import cl from 'classnames';
import Stack from '@mui/material/Stack';
import { useFormikContext } from 'formik';
import Button from '@mui/material/Button';
import { Alert, AlertTitle, Collapse } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Warning } from '@mui/icons-material';
import { useFormikWizardNavigation } from '../../../../../../lib/cdk/Wizard/useFormikWizardNavigation';
import {
    hasAllDirectorFieldsFilled,
    hasDirectorRelation,
    IRoleForm,
} from '../../useRoleEditFormik/useRoleEditFormik.const';
import { IFormikWizardConfig } from '../../../../../../lib/cdk/Wizard/FormikWizard';
import { RoleRelationsEditList } from '../../RoleRelationsEditList';
import { EntityType } from '../../../../../../interfaces/model';
import { Fieldset } from '../../../../../../lib/forms/Fieldset';
import styles from './EntitiesRelationsEdit.module.css';
import { INVALID_CONTROL_DEFAULT_SELECTOR } from '../../../../../../lib/forms';

interface IEntitiesRelationsEditProps {
    entityType: Maybe<EntityType> | undefined;
    config: IFormikWizardConfig;
}

export const EntitiesRelationsEdit: React.FC<IEntitiesRelationsEditProps> = ({ entityType, config }) => {
    const formik = useFormikContext<IRoleForm>();
    const { setFieldValue, values } = formik;
    const isDirectorOfSomeEntity = hasDirectorRelation(formik.values.entityRelations);
    const showMissingDirectorDetailsAlert = isDirectorOfSomeEntity && !hasAllDirectorFieldsFilled(formik.values);
    const showMarkAtLeastOneDirectorAlert = formik.values.isDirector && !isDirectorOfSomeEntity;
    const wizardNavigation = useFormikWizardNavigation(config);

    useEffect(() => {
        /**
         * In order for the formikWizardNavigation validation to work properly,
         * we create a 'fake' field 'directorRelations' that is always valid
         * initially - we then update it with actual entityRelations here
         */
        const realDirectors = values?.entityRelations?.filter((item) => item.director) || [];
        const fakeDirectors = values?.directorRelations?.filter((item) => item.director) || [];
        if (realDirectors.length !== fakeDirectors.length) {
            setFieldValue('directorRelations', values.entityRelations);
        }
    }, [setFieldValue, values.entityRelations, values.directorRelations]);

    return (
        <Fieldset
            title={'Entities Relations'}
            description={
                <Stack spacing={2}>
                    <span>Select entities the user has access to</span>
                    <Collapse in={showMissingDirectorDetailsAlert} unmountOnExit>
                        <Alert
                            severity={'success'}
                            icon={<TipsAndUpdatesIcon sx={{ fontSize: '24px' }} />}
                            action={
                                <Button
                                    color={'inherit'}
                                    variant={'outlined'}
                                    sx={{ marginRight: '8px' }}
                                    onClick={async () => {
                                        await wizardNavigation.nextStep(0);
                                    }}
                                >
                                    Provide
                                </Button>
                            }
                        >
                            <AlertTitle>The user is marked as a director if at least one entity</AlertTitle>
                            We require additional information for the position of director
                        </Alert>
                    </Collapse>
                    <Collapse in={showMarkAtLeastOneDirectorAlert} unmountOnExit>
                        <Alert severity={'success'} icon={<TipsAndUpdatesIcon sx={{ fontSize: '24px' }} />}>
                            <Stack>
                                <AlertTitle>The user is marked as a director</AlertTitle>
                                <span>You should add them as a director of at least one entity</span>
                            </Stack>
                        </Alert>
                    </Collapse>
                </Stack>
            }
            spacing={2}
        >
            <RoleRelationsEditList entityType={entityType} />
            {!!formik.errors.directorRelations && formik.touched.directorRelations && (
                <Alert
                    className={cl(INVALID_CONTROL_DEFAULT_SELECTOR, styles.directorsAlert)}
                    sx={{ marginBottom: '16px' }}
                    severity={'warning'}
                    icon={<Warning color={'error'} />}
                >
                    {formik.errors.directorRelations}
                </Alert>
            )}
        </Fieldset>
    );
};
