import {
    BankAccountInput,
    Country,
    Document,
    DocumentInInput,
    FundType,
    KYCCompleteStatus,
    KYCDocType,
    LoanFundInInput,
} from 'interfaces/types';
import * as yup from 'yup';
import { VALIDATION_MESSAGES, yupArrayTypeFix } from 'lib/forms';
import { addressSchema } from 'lib/forms/addressSchema';
import {
    ITaxIdEntityFundEdit,
    removeExtraTaxIdModelFields,
} from '../../../../common/TaxIds/TaxIdsList/TaxIdEditDialog/TaxIdEditDialog.const';
import { FundKeyInformationFragment, FundKeyInformationKYCFragment } from '../../queries/FundKeyInformation.query';
import { TaxIdFragment } from 'App/common/TaxIds/TaxId.fragment';
import { dateToModelValue, fromDateISO } from '../../../../../lib/utils/date';

export type IFundEdit = FundKeyInformationFragment & {
    taxIds?: Maybe<TaxIdFragment[]>;
    kycs?: Maybe<FundKeyInformationKYCFragment[]>;
};

export const getFundInitialValues = ({
    fund,
    entityRef,
    topCoRef,
}: {
    fund?: IFundEdit;
    entityRef?: string | null;
    topCoRef?: string;
}) => {
    return {
        ...fund,
        topCoRef: fund?.topCoRef || topCoRef,
        jurisdiction: fund?.jurisdiction,
        incorporationNumber: fund?.incorporationNumber,
        giin: fund?.giin,
        lei: fund?.lei,
        nif: fund?.nif,
        dttp: fund?.dttp,
        name: fund?.name,
        parLoansOnly: fund?.parLoansOnly || false,
        type: fund?.type,
        entityRef: fund?.entityRef || entityRef,
        fundAdministratorRef: fund?.fundAdministratorRef,
        authorizedSignatory: fund?.authorizedSignatory || [],
        settlementLeadTime: fund?.settlementLeadTime,
        address: fund?.address || void 0,
        businessAddress: fund?.businessAddress || void 0,
        taxIds: fund?.taxIds as ITaxIdEntityFundEdit[],
        documents: {} as Partial<Record<KYCDocType, DocumentInInput>>,
        showBusinessAddress: !!fund?.businessAddress,
        reinvestmentPeriodEndDateNotAvailable: fund?.reinvestmentPeriodEndDate === null,
        reinvestmentPeriodEndDate: fromDateISO(fund?.reinvestmentPeriodEndDate),
        legalMaturityDateNotAvailable: fund?.legalMaturityDate === null,
        legalMaturityDate: fromDateISO(fund?.legalMaturityDate),
    };
};

export type IFundInitialForm = Omit<ReturnType<typeof getFundInitialValues>, 'currentStep'>;

export const prepareFundModelValue = (fund: IFundInitialForm): LoanFundInInput => {
    return {
        id: fund.id,
        entityRef: fund.entityRef,
        fundAdministrator: fund.fundAdministratorRef,
        name: fund.name,
        jurisdiction: fund.jurisdiction,
        incorporationNumber: fund.incorporationNumber,
        dttp: fund.dttp,
        lei: fund.lei,
        type: fund.type,
        authorizedSignatory: fund.authorizedSignatory,
        settlementLeadTime: fund.settlementLeadTime,
        parLoansOnly: fund.parLoansOnly,
        giin: fund.giin,
        nif: fund.nif,
        address: fund.address?.country && fund.address?.city ? fund.address : null,
        businessAddress: fund.businessAddress?.country && fund.businessAddress?.city ? fund.businessAddress : null,
        taxIds: fund.taxIds
            ?.filter(({ id, country, taxIdValue }) => country && taxIdValue && id)
            .map(removeExtraTaxIdModelFields),
        bankAccounts: fund.bankAccounts,
        reinvestmentPeriodEndDate: fund.reinvestmentPeriodEndDateNotAvailable
            ? null
            : dateToModelValue(fund.reinvestmentPeriodEndDate),
        legalMaturityDate: fund.legalMaturityDateNotAvailable ? null : dateToModelValue(fund.legalMaturityDate),
    };
};

export const validationSchemaFactory = () => {
    return yup.object({
        id: yup.string(),
        name: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
        shortCode: yup.string(),
        jurisdiction: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
        incorporationNumber: yup.string().nullable(),
        entityRef: yup.string().nullable().required(VALIDATION_MESSAGES.REQUIRED),
        type: yup.mixed<FundType>().required(VALIDATION_MESSAGES.REQUIRED),
        nif: yup.string().nullable(),
        dttp: yup.string().nullable(),
        giin: yup.string().nullable(),
        address: addressSchema.nullable().required(VALIDATION_MESSAGES.REQUIRED),
        businessAddress: yup
            .object()
            .nullable()
            .when('showBusinessAddress', {
                is: (showBusinessAddress: boolean) => showBusinessAddress,
                then: addressSchema.nullable().required(VALIDATION_MESSAGES.REQUIRED),
            }),
        settlementLeadTime: yup.number().nullable(),
        topCoRef: yup.string(),
        authorizedSignatory: yup.array().of(yupArrayTypeFix(yup.string())),
        taxIds: yup.array().when('currentStep', {
            is: (currentStep: number) => currentStep > 0,
            then: yup.array().of(
                yup.object({
                    country: yup.mixed<Country>().required(VALIDATION_MESSAGES.REQUIRED),
                    taxIdValue: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
                    taxIdFiles: yup.array(yupArrayTypeFix(yup.mixed<File>())).when('kyc', {
                        is: (kyc: Document) => !kyc,
                        then: yup
                            .array(yupArrayTypeFix(yup.mixed<File>()))
                            .min(1, VALIDATION_MESSAGES.FILE_REQUIRED)
                            .required(VALIDATION_MESSAGES.FILE_REQUIRED),
                    }),
                    validFromDate: yup
                        .date()
                        .nullable()
                        .when('kyc', {
                            is: (kyc: Document) => !kyc,
                            then: yup.date().nullable().required(VALIDATION_MESSAGES.REQUIRED),
                        }),
                    validToDate: yup
                        .date()
                        .nullable()
                        .when('kyc', {
                            is: (kyc: Document) => !kyc,
                            then: yup.date().nullable().required(VALIDATION_MESSAGES.REQUIRED),
                        }),
                })
            ),
        }),
        taxIdsDisclaimerChecked: yup.boolean().when(['currentStep', 'taxIds'], {
            is: (currentStep: number, taxIds: object[]) => {
                return currentStep > 0 && (taxIds?.length || 0) > 0;
            },
            then: yup.boolean().oneOf([true], VALIDATION_MESSAGES.REQUIRED_CHECKED),
        }),
        bankAccounts: yup.array().of(yupArrayTypeFix(yup.mixed<BankAccountInput>())),
        documents: yup.mixed<DocumentInInput>(),
        currentStep: yup.number(),
        showBusinessAddress: yup.boolean(),
        hasDocumentEditAccess: yup.boolean().nullable(),
        hasDocumentApproveAccess: yup.boolean().nullable(),
        kycComplete: yup.mixed<KYCCompleteStatus>().nullable(),
        reinvestmentPeriodEndDateNotAvailable: yup.boolean(),
        reinvestmentPeriodEndDate: yup.date().when('reinvestmentPeriodEndDateNotAvailable', {
            is: false,
            then: yup.date().required(VALIDATION_MESSAGES.REQUIRED),
        }),
        legalMaturityDateNotAvailable: yup.boolean(),
        legalMaturityDate: yup.date().when('legalMaturityDateNotAvailable', {
            is: false,
            then: yup.date().required(VALIDATION_MESSAGES.REQUIRED),
        }),
    });
};

export type IFundEditForm = yup.TypeOf<ReturnType<typeof validationSchemaFactory>>;
