import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { InfoOutlined, SvgIconComponent } from '@mui/icons-material';
import { IconButtonProps } from '@mui/material';
import { LinkProps } from 'react-router-dom';

interface IIconWithTooltipProps extends IconButtonProps {
    text: React.ReactNode;
    tooltipProps?: Partial<TooltipProps>;
    Icon?: SvgIconComponent;
    component?: React.FC<LinkProps>;
    to?: string;
}

export const IconWithTooltip: React.FC<IIconWithTooltipProps> = ({
    text,
    tooltipProps,
    Icon = InfoOutlined,
    ...props
}) => {
    return (
        <Tooltip title={<>{text}</>} {...tooltipProps} arrow>
            <IconButton color={'secondary'} {...props}>
                <Icon fontSize={'small'} />
            </IconButton>
        </Tooltip>
    );
};
