import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DocumentsSelectListFilterPanel } from '../DocumentsSelectList/DocumentsSelectListFilterPanel';
import { makeDialogState } from 'store/dialogState/makeDialogState';
import { DocumentUploadButton } from '../DocumentsViewDialog/DocumentUploadButton';
import { DialogCloseButton } from 'lib/modals/DialogCloseButton';
import { IDocumentViewDialogParent } from '../DocumentsViewDialog/interfaces';
import { DocumentsSelectList } from '../DocumentsSelectList';
import { useCurrentDocumentRouteFactory } from '../../useCurrentDocumentRouteFactory';
import { IDocumentsUrlState } from '../../useDocumentsUrlState';
import { AddDocumentsButton } from './AddDocumentsButton';
import { idTypeLabels } from 'App/App.const';
import {
    DocPurpose,
    DocumentStorage,
    KYCDocSubcategory,
    KYCDocType,
    KYCDocTypeFragment,
    KYCDocumentFragment,
} from 'interfaces/model';
import { PaperDark } from 'lib/surfaces/PaperDark';
import { NOOP_ASYNC } from 'lib/utils/helpers';

interface IAddDocumentsDialogProps {
    updateData?: () => Promise<void>;
}

interface IAddDocumentsDialogState {
    purpose: DocPurpose;
    storage: DocumentStorage;
    docType?: KYCDocType;
    docSubcategory?: KYCDocSubcategory;
    parent: IDocumentViewDialogParent | undefined;
    onSaveSuccess?: (documentId: UUID) => Promise<void>;
    disableCategoryChange?: boolean;
}

export const useAddDocumentsDialog = makeDialogState<IAddDocumentsDialogState>();

export const AddDocumentsDialog: React.FC<IAddDocumentsDialogProps> = ({
    updateData: updateParentData = NOOP_ASYNC,
}) => {
    const history = useHistory();
    const getRoute = useCurrentDocumentRouteFactory();
    const [{ close }, isOpen, state] = useAddDocumentsDialog();
    const { purpose, docType, storage, docSubcategory, parent, onSaveSuccess, disableCategoryChange } = state || {};

    const [docTypes, setDocTypes] = useState<KYCDocTypeFragment[]>([]);
    const [filter = { purpose, docType, docSubcategory }, setFilters] = useState<IDocumentsUrlState>();
    const [selectedDocuments, setSelectedDocuments] = useState<KYCDocumentFragment[]>([]);

    const count = selectedDocuments.length;
    const multipleFiles = count > 1;
    const documentsLabel = `Document${multipleFiles ? 's' : ''}`;

    const updateData = async () => {
        await updateParentData();
    };

    const clearAndClose = () => {
        setSelectedDocuments([]);
        setFilters({});
        close();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={clearAndClose}
            data-testid={'Dialog'}
            PaperComponent={PaperDark}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle>
                Add documents {parent && `to ${idTypeLabels[parent?.type]}`}
                <DialogCloseButton onClick={clearAndClose} />
            </DialogTitle>
            <DialogContent sx={{ height: '65vh' }}>
                <Stack spacing={3}>
                    <DocumentsSelectListFilterPanel
                        value={filter}
                        onChange={setFilters}
                        docTypes={docTypes}
                        disableCategoryChange={docType && disableCategoryChange}
                    />
                    <DocumentsSelectList
                        filter={{ ...filter, uploadToStorage: storage, uploadToParentRef: parent?.id }}
                        skipFetch={!isOpen}
                        onDocTypesChange={setDocTypes}
                        selected={selectedDocuments}
                        onSelect={setSelectedDocuments}
                        multiselect={purpose === DocPurpose.FACILITY}
                        updateData={updateData}
                        parent={parent}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ background: 'var(--bg-paper)' }}>
                {purpose &&
                    storage &&
                    parent?.hasDocumentEditAccess &&
                    (count === 0 ? (
                        <DocumentUploadButton
                            docType={filter.docType}
                            docPurpose={filter.purpose}
                            docSubcategory={filter.docSubcategory}
                            config={{ docTypeChangeEnabled: !filter.docType, storage }}
                            onUploadSuccess={async (id) => {
                                await updateData();
                                history.push(getRoute({ id }));
                                close();
                                if (onSaveSuccess && id) {
                                    await onSaveSuccess(id);
                                }
                            }}
                            parent={parent}
                        />
                    ) : (
                        <>
                            <h5 className={'mr-auto mb-0 mt-4'}>
                                {count} {documentsLabel.toLowerCase()} selected
                            </h5>
                            <AddDocumentsButton
                                purpose={purpose}
                                parentRefs={parent ? [parent.id] : []}
                                selectedDocuments={selectedDocuments.map(({ id }) => id)}
                                onSaveSuccess={async (id) => {
                                    await updateData();
                                    clearAndClose();
                                    if (onSaveSuccess && id) {
                                        await onSaveSuccess(id);
                                    }
                                }}
                            />
                        </>
                    ))}
            </DialogActions>
        </Dialog>
    );
};
