import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DirectorSelectDialog, useDirectorSelectDialog } from '../../../ViewEntity/DirectorSelectDialog';
import { DirectorsList } from '../../../ViewEntity/DirectorSelectDialog/DirectorSelectList';
import { EntityType, useDirectorRolesQuery } from '../../../../../../interfaces/model';
import { voidWrapper } from '../../../../../../lib/utils/helpers';

interface IEditEntityDirectors {
    topCoRef: UUID;
    entityRef: UUID | undefined;
    entityType: EntityType | undefined;
    value: UUID[];
    onChange: (value: UUID[]) => void;
}

export const EditEntityDirectors: React.FC<IEditEntityDirectors> = ({
    entityRef,
    entityType,
    topCoRef,
    value,
    onChange,
}) => {
    const [directorSelectDialog] = useDirectorSelectDialog();
    const { data, error, loading, refetch } = useDirectorRolesQuery({
        fetchPolicy: 'no-cache',
        skip: !topCoRef,
        variables: {
            entityRef: topCoRef!,
        },
    });

    const handleSelect = (roleId: UUID) => {
        onChange([...value, roleId]);
    };

    const handleRemove = (roleId: UUID) => {
        onChange(value.filter((id) => id !== roleId));
    };

    const roles = data?.entity.roles || [];
    const hasRelationEnabled = (relation: { entityId: UUID; enabled: boolean; director: boolean }) => {
        const { entityId, enabled, director } = relation;
        return entityId === entityRef && enabled && director;
    };
    const selectedRoles = roles.filter(({ id, entityRelations }) => {
        return entityRelations?.find(hasRelationEnabled) || value.includes(id);
    });
    const notSelectedRoles = roles.filter(
        ({ id, entityRelations }) => !entityRelations?.find(hasRelationEnabled) || !value.includes(id)
    );

    return (
        <Stack spacing={2}>
            <div>
                <Button
                    size={'large'}
                    variant={'contained'}
                    onClick={() =>
                        directorSelectDialog.open({
                            entityRef,
                            topCoRef,
                            entityType,
                            selected: value,
                        })
                    }
                >
                    Add director
                </Button>
                <DirectorSelectDialog
                    onSelect={handleSelect}
                    data={notSelectedRoles}
                    updateData={voidWrapper(refetch)}
                    loading={loading}
                    error={error}
                />
            </div>
            {value.length > 0 && (
                <DirectorsList
                    data={selectedRoles}
                    updateData={voidWrapper(refetch)}
                    onRemove={handleRemove}
                    loading={loading}
                    error={error}
                />
            )}
        </Stack>
    );
};
