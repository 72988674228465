import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import { SortOrder } from './components/SortableCell';
import { sortCompare } from '../../utils/helpers';
import { IListColumnType } from './interfaces';
import isNil from 'lodash/isNil';

const numberCompare = (a: number, b: number) => {
    if (isNil(a) || isNil(b)) {
        return 0;
    }
    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    return 0;
};

function getAlphanumericSortComparator<T>(sortBy: PrimitiveTypeKeys<T>, sortOrder: SortOrder) {
    return (a: T, b: T) => {
        const valA = (sortBy && a[sortBy]) || '';
        const valB = (sortBy && b[sortBy]) || '';
        const order = sortOrder === 'desc' ? -1 : 1;

        if (isString(valA) && isString(valB)) {
            return sortCompare(valA, valB) * order;
        }

        if (isNumber(valA) && isNumber(valB)) {
            return numberCompare(valA, valB) * order;
        }

        return 0;
    };
}

function getBooleanSortComparator<T>(sortBy: PrimitiveTypeKeys<T>, sortOrder: SortOrder) {
    return (a: T, b: T) => {
        const valA = (sortBy && a[sortBy]) || false;
        const valB = (sortBy && b[sortBy]) || false;
        if (isBoolean(valA) && isBoolean(valB)) {
            const order = sortOrder === 'desc' ? -1 : 1;
            const orderReverse = -order;
            if (valA === valB) {
                return 0;
            }
            return valA ? order : orderReverse;
        }
        return 0;
    };
}

export function listDefaultSort<TData>(
    sortBy: PrimitiveTypeKeys<TData>,
    sortOrder: SortOrder,
    typeMap: Record<PrimitiveTypeKeys<TData>, IListColumnType>
): (data: TData[]) => TData[] {
    return (data: TData[]) =>
        [...data].sort((a: TData, b: TData) => {
            const type = typeMap[sortBy];
            if (type === 'boolean') {
                return getBooleanSortComparator(sortBy, sortOrder)(a, b);
            }
            return getAlphanumericSortComparator(sortBy, sortOrder)(a, b);
        });
}
