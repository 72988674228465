import React, { useEffect } from 'react';
import { useUserDetails } from '../../hooks/useUserDetails';
import { useSelector } from 'react-redux';
import { APP_INITIALIZED_SELECTOR } from '../../App.selectors';

const CUSTOMER_SERVICE_ELEMENT_ID = 'customerService';
const WIDGET_URL = 'https://eu.fw-cdn.com/11023161/399349.js';

const injectScript = () => {
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = WIDGET_URL;
    script.id = CUSTOMER_SERVICE_ELEMENT_ID;
    document.head.appendChild(script);
};

/**
 * Note: this way of initialization widget doesn't support switching between users on the same browser
 */
const initializeWidget = (user: {
    id: string;
    name: string | undefined;
    email: string | undefined;
    topCoName: string;
}) => {
    const ref = setInterval(() => {
        if (window.fcWidget) {
            clearInterval(ref);
            window.fcWidget.setExternalId(user.id);
            window.fcWidget.user.setFirstName(user.name);
            window.fcWidget.user.setEmail(user.email);
            window.fcWidget.user.setProperties({
                plan: 'Pro',
                status: 'Active',
                cf_topco: user.topCoName,
            });
        }
    }, 1000);
};

export const CustomerServiceWidget: React.FC = () => {
    const { authRole } = useUserDetails();
    const isAppLoaded = useSelector(APP_INITIALIZED_SELECTOR);

    useEffect(() => {
        const isWidgetInitialized = window.fcWidget?.isInitialized();
        const id = authRole?.id;
        const email = authRole?.email || void 0;
        const name = authRole?.name || void 0;
        const topCoName = authRole?.topCo?.name || '';

        if (!isWidgetInitialized && id && isAppLoaded) {
            injectScript();
            initializeWidget({ id, name, email, topCoName });
        }

        if (isWidgetInitialized && !isAppLoaded) {
            window.fcWidget?.destroy();
        }
    }, [authRole, isAppLoaded]);

    return null;
};
