import { EntityFacilitiesListRowFragment } from '../../../../../../../interfaces/model';
import { IFacilitiesListRowItem } from './EntityFacilitiesListRow';
import { nonNull } from '../../../../../../../lib/utils/helpers';
import { facilityRankLabels } from '../../../../../Facilities/Facilities.const';

export const prepareEntityFacilitiesListItem = (facility: EntityFacilitiesListRowFragment): IFacilitiesListRowItem => {
    return {
        id: facility.id,
        shortCode: facility.shortCode || '',
        topCoName: facility.topCo?.name || '',
        issuerName: facility.issuer?.name || '',
        type: facility.type,
        tranche: facility.tranche || '',
        maturityDate: nonNull(facility.maturityDate),
        rank: facilityRankLabels[facility.rank!] || '',
        currency: facility.currency,
        facilitySize: nonNull(facility.originalGlobalFacilitySize),
        facility,
    };
};
