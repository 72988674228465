import React from 'react';
import { Datepicker, IDatepickerProps } from '../../inputs/Datepicker';
import { BaseFormComponent, IBaseFormComponentProps } from './BaseFormComponent';
import { BusinessCalendarDatePicker } from '../../inputs/BusinessDatePicker';
import { NonWorkingDayDataFragment } from '../../inputs/BusinessDatePicker/Holidays.query';
import { ApolloError } from '@apollo/client';

interface IFormDatepickerProps extends Omit<IBaseFormComponentProps, 'onChange'> {
    minDate?: Date;
    maxDate?: Date;
    datepickerProps?: Omit<IDatepickerProps, 'error'>;
    onChange?: (value: Date | undefined) => void;
    calendarType?: Maybe<string[]>;
    nonWorkingDayData?: Maybe<NonWorkingDayDataFragment>;
    loading?: boolean;
    error?: ApolloError;
    dateTime?: boolean;
}

export const FormDatepicker: React.FC<IFormDatepickerProps> = ({
    name,
    placeholder,
    datepickerProps,
    helperText,
    minDate,
    maxDate,
    onChange,
    calendarType,
    nonWorkingDayData,
    disabled,
    loading,
    error,
    dateTime,
    ...props
}) => (
    <BaseFormComponent name={name} {...props}>
        {calendarType || nonWorkingDayData || error ? (
            <BusinessCalendarDatePicker
                name={name}
                placeholder={placeholder}
                {...datepickerProps}
                calendarType={calendarType}
                nonWorkingDayData={nonWorkingDayData}
                helperText={helperText}
                dateTime={dateTime}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                loading={loading}
                error={error}
                fullWidth
            />
        ) : (
            <Datepicker
                name={name}
                placeholder={placeholder}
                dateTime={dateTime}
                {...datepickerProps}
                minDate={minDate}
                maxDate={maxDate}
                helperText={helperText}
                onChange={onChange}
                disabled={disabled}
                fullWidth
            />
        )}
    </BaseFormComponent>
);
