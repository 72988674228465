import React from 'react';
import Stack from '@mui/material/Stack';
import { Button, Fade } from '@mui/material';

interface ISignatureAreaPanelProps {
    mode: SignatureAreaMode;
    onModeChange: (mode: SignatureAreaMode) => void;
    displayClear: boolean;
    onClear: () => void;
}

export enum SignatureAreaMode {
    DRAW = 'DRAW',
    UPLOAD = 'UPLOAD',
}

export const SignatureAreaPanel: React.FC<ISignatureAreaPanelProps> = ({
    mode,
    onModeChange,
    displayClear,
    onClear,
}) => {
    return (
        <Stack direction={'row'} spacing={1}>
            <Fade in={displayClear}>
                <Button size={'small'} color={'secondary'} onClick={onClear}>
                    Clear
                </Button>
            </Fade>
            {mode === SignatureAreaMode.DRAW && (
                <Button size={'small'} color={'secondary'} onClick={() => onModeChange(SignatureAreaMode.UPLOAD)}>
                    Upload
                </Button>
            )}
            {mode === SignatureAreaMode.UPLOAD && (
                <Button size={'small'} color={'secondary'} onClick={() => onModeChange(SignatureAreaMode.DRAW)}>
                    Draw
                </Button>
            )}
        </Stack>
    );
};
