import * as yup from 'yup';
import { useRef } from 'react';
import { AnySchema } from 'yup';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { MixedSchema } from 'yup/lib/mixed';
import { useURLSearchParams } from './useURLSearchParams';
import { NonFalsy } from 'lib/utils/helpers';
import isString from 'lodash/isString';

type IBasicValuesSchema = yup.BooleanSchema | yup.StringSchema | yup.NumberSchema;
type IUrlParamsSchema<T = unknown> =
    | IBasicValuesSchema
    | yup.ArraySchema<IBasicValuesSchema | MixedSchema<T>>
    | MixedSchema<T>;

export type IUrlParamsPropsValidationSchema = Record<string, IUrlParamsSchema>;

export interface IUrlParamsProps<T extends IUrlParamsPropsValidationSchema> {
    validationSchema: T;
}

type ResolvedTypeOfSchema<T> = T extends yup.StringSchema
    ? string
    : T extends yup.NumberSchema
    ? number
    : T extends yup.BooleanSchema
    ? boolean
    : T extends yup.ArraySchema<infer U>
    ? Array<ResolvedTypeOfSchema<U>>
    : T extends MixedSchema<infer U>
    ? U
    : never;

export type IUrlParams<TKeys extends string, T extends Record<TKeys, IUrlParamsSchema>> = Partial<{
    [K in keyof T]: ResolvedTypeOfSchema<T[K]>;
}>;

const primitiveTypeParser = (value: string): string | boolean | number => {
    if (value?.toLowerCase() === 'true' || value?.toLowerCase() === 'false') {
        return value === 'true';
    }
    const parsedValue = value && parseFloat(value);
    if (isNumber(parsedValue) && value === String(parsedValue)) {
        return parsedValue;
    }
    return value;
};

const validateAttempt = (funcs: (() => void)[]) => {
    for (const func of funcs) {
        try {
            return func();
        } catch (e) {
            // Do nothing, and proceed to the next function
        }
    }
    throw new Error('All functions failed.');
};

const parseValue = <TKeys extends string>(key: TKeys, value: string | string[], schema: AnySchema) => {
    const valueParsed = (Array.isArray(value) ? value.map(primitiveTypeParser) : primitiveTypeParser(value)) as unknown;
    try {
        validateAttempt(
            [
                () => schema.validateSync(valueParsed),
                isString(valueParsed) && (() => schema.validateSync(valueParsed.toUpperCase())),
            ].filter(NonFalsy)
        );
        return [key, valueParsed];
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Failed to parse "${key}" query parameter: The value is of an unrecognized type`);
    }
};

export const useUrlParams = <TKeys extends string, TSchema extends Record<TKeys, IUrlParamsSchema>>(
    props: IUrlParamsProps<TSchema>
): IUrlParams<TKeys, TSchema> => {
    const queryParams = useURLSearchParams();
    const prevValue = useRef<IUrlParams<TKeys, TSchema>>({});

    const queryParamsMap = Object.fromEntries(
        Object.entries(props.validationSchema)
            .map(([key, schema]) => {
                const isArrayType = schema instanceof yup.ArraySchema;
                const value = isArrayType ? queryParams.getAll(key as string) : queryParams.get(key as string);
                if (!isNil(value) && !isEmpty(value)) {
                    return parseValue(key, value, schema as AnySchema);
                }
                return null;
            })
            .filter(NonFalsy)
    );

    if (!isEqual(prevValue.current, queryParamsMap)) {
        prevValue.current = queryParamsMap;
        return queryParamsMap;
    }

    return prevValue.current;
};
