import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getTraceIdFromApolloError } from '../../lib/utils/helpers';
import { ErrorCode } from '../../interfaces/model';
import { appError, userLogin } from '../App.actions';
import { APP_INITIALIZED_SELECTOR } from '../App.selectors';
import { LOGIN_ROUTE } from '../App.routes';
import { ApolloError } from '@apollo/client';
import { isUnauthorizedServerError } from '../API';
import { useAuthQuery } from './Auth/Auth.query';
import { joinQueryParams } from '../../lib/utils/router/joinQueryParams';

export const AppConfigResolverContext = React.createContext<{ authorizeUser: () => Promise<void> }>({
    authorizeUser: () => Promise.resolve(),
});

const isUnauthorized = ({ networkError, graphQLErrors }: ApolloError) => {
    return (
        isUnauthorizedServerError(networkError) ||
        graphQLErrors.find(({ extensions }) => extensions?.code === ErrorCode.UNAUTHORISED)
    );
};

export const AppConfigResolver: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const isAppLoaded = useSelector(APP_INITIALIZED_SELECTOR);
    const { loading, refetch } = useAuthQuery({
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { authRole, user } = data.auth;
            const isControlledUser = authRole?.id && authRole.id !== user.role.id;
            if (!authRole?.termsAcceptedAt && !user.totpRequired) {
                history.push(`${LOGIN_ROUTE}${joinQueryParams({ terms: true })}`, { from: location });
                return;
            }

            if (user.totpRequired && !isControlledUser) {
                history.push(LOGIN_ROUTE, { from: location });
            } else {
                dispatch(userLogin(data.auth));
            }
        },
        onError: (error) => {
            if (!isUnauthorized(error)) {
                dispatch(appError({ status: true, traceId: getTraceIdFromApolloError(error) }));
            } else if (location.pathname !== LOGIN_ROUTE) {
                history.push(LOGIN_ROUTE, { from: location });
            }
        },
        skip: isAppLoaded,
    });

    const appConfigResolverContextValue = useMemo(
        () => ({
            authorizeUser: async () => {
                await refetch();
            },
        }),
        [refetch]
    );

    if (loading) {
        return (
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
                <CircularProgress color={'primary'} size={48} />
            </Box>
        );
    }

    return (
        <AppConfigResolverContext.Provider value={appConfigResolverContextValue}>
            {children}
        </AppConfigResolverContext.Provider>
    );
};
