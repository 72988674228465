import { Button, ButtonProps } from '@mui/material';
import React, { useRef, useState } from 'react';
import { KYCDocumentFragment } from '../../../../../../../interfaces/model';
import { DocumentKYCPopup } from './DocumentKYCPopup';

interface IKYCAddReviewButtonProps extends Omit<ButtonProps, 'value'> {
    value: KYCDocumentFragment;
    parentRef: UUID;
    updateData: () => Promise<void>;
}

export const DocumentReviewButton: React.FC<IKYCAddReviewButtonProps> = ({
    value,
    parentRef,
    updateData,
    ...props
}) => {
    const popupRef = useRef(null);
    const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
    const { docType, docSubcategory } = value;

    return (
        <>
            <Button
                data-testid={'DataroomDialog:addReviewButton'}
                color="primary"
                variant={'contained'}
                onClick={() => setSubmitDialogOpen(true)}
                ref={popupRef}
                {...props}
            >
                Add review
            </Button>
            <DocumentKYCPopup
                value={value}
                anchorEl={popupRef.current}
                parentRef={parentRef}
                docType={docType}
                docSubcategory={docSubcategory}
                open={submitDialogOpen}
                onClose={() => setSubmitDialogOpen(false)}
                onSaveSuccess={async () => {
                    setSubmitDialogOpen(false);
                    await updateData();
                }}
            />
        </>
    );
};
