import React from 'react';
import cl from 'classnames';
import styles from './LoadingOverlayRow.module.css';
import { CircularProgress, TableCell, TableRow } from '@mui/material';

interface ILoadingOverlayRowProps {
    tableRef: React.RefObject<HTMLTableElement>;
    className?: string;
}

const DEFAULT_ROW_HEIGHT = 40;

export const LoadingOverlayRow: React.FC<ILoadingOverlayRowProps> = ({ tableRef, className }) => {
    const firstRowHeight = tableRef.current?.tBodies?.[0]?.children?.[0]?.clientHeight || DEFAULT_ROW_HEIGHT;

    return (
        <TableRow className={cl(styles.wrap, 'non-interactive', className)} style={{ height: `${firstRowHeight}px` }}>
            <TableCell className={styles.cell}>
                <CircularProgress />
            </TableCell>
        </TableRow>
    );
};
