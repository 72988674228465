import { FormikContext } from 'formik';
import SignatureCanvas from 'react-signature-canvas';
import ReactSignatureCanvas from 'react-signature-canvas';
import React, { useContext, useRef, useState } from 'react';
import { FileDropArea } from '../FileDropArea';
import styles from './SignatureArea.module.css';
import { SignatureAreaMode, SignatureAreaPanel } from './SignatureAreaPanel';
import isFunction from 'lodash/isFunction';
import { ValidationMessage } from '../../forms/ValidationMessage';

interface ISignatureAreaProps {
    name?: string;
    value?: File[];
    onValueChange?: (value: File[]) => void;
}

export const SignatureArea: React.FC<ISignatureAreaProps> = ({ name, value: valueProp, onValueChange }) => {
    const canvasRef = useRef<ReactSignatureCanvas>(null);
    const formik = useContext(FormikContext);
    const value = valueProp || (formik.values[name!] as File[]);
    const onChange = onValueChange || ((newValue: File[]) => name && formik.setFieldValue(name, newValue));
    const [mode, setMode] = useState(SignatureAreaMode.DRAW);
    const canvas = canvasRef.current;

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <SignatureAreaPanel
                    mode={mode}
                    onModeChange={setMode}
                    displayClear={value.length > 0}
                    onClear={() => {
                        canvas?.clear();
                        onChange([]);
                    }}
                />
            </div>
            <div className={styles.scratchPadWrap}>
                {mode === SignatureAreaMode.DRAW && (
                    <div className={styles.scratchPad}>
                        <SignatureCanvas
                            ref={canvasRef}
                            penColor="black"
                            canvasProps={{ className: styles.signatureCanvas }}
                            onEnd={async (e) => {
                                let canvasEl = e.target as unknown as HTMLCanvasElement | null;
                                if (canvasEl && !isFunction(canvasEl.toBlob)) {
                                    canvasEl = canvasEl.querySelector('canvas');
                                }

                                if (canvasEl?.toBlob) {
                                    const blob = await new Promise((res) => canvasEl!.toBlob(res));
                                    const file = new File([blob as Blob], 'signature.png');
                                    onChange([file]);
                                }
                            }}
                        />
                    </div>
                )}
                {mode === SignatureAreaMode.UPLOAD && (
                    <div className={styles.droparea}>
                        {value.length === 0 && (
                            <FileDropArea
                                name={name}
                                accept={['.png', '.jpeg', '.jpg', '.tiff', '.tif', '.gif']}
                                validationMessage={false}
                                maxFiles={1}
                            />
                        )}
                        {value.length !== 0 && (
                            <div className={styles.preview}>
                                <img src={URL.createObjectURL(value[0])} alt={'signature preview'} />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {name && <ValidationMessage field={name} />}
        </div>
    );
};
