import React from 'react';
import { IListRow } from '../../../../../../../lib/dataDisplay/List';
import { ListRow } from '../../../../../../../lib/dataDisplay/List/components/ListRow';
import { ValueCell } from '../../../../../../../lib/dataDisplay/List/components/ListCells';

export interface IRoleEntitiesListItem {
    id: UUID;
    name: string;
}

type IRoleEntitiesListRowProps = IListRow<IRoleEntitiesListItem>;

export const RoleEntitiesListRow: React.FC<IRoleEntitiesListRowProps> = ({ data, columns }) => {
    return (
        <ListRow>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>{id === 'name' && <ValueCell>{data.name}</ValueCell>}</React.Fragment>
            ))}
        </ListRow>
    );
};
