import React from 'react';
import { StandardTextFieldProps } from '@mui/material/TextField/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTime } from 'luxon';
import { useFormikField } from '../forms/useFormikField';
import isNil from 'lodash/isNil';
import { TextInput } from './TextInput';
import { ValidationMessage } from '../forms/ValidationMessage';

export interface ITimepickerProps extends Omit<StandardTextFieldProps, 'onChange' | 'value'> {
    value?: Date | undefined;
    onChange?: (value: DateTime | null) => void;
    ampm?: boolean;
}

export const Timepicker: React.FC<ITimepickerProps> = ({
    name,
    fullWidth,
    disabled,
    placeholder = '',
    value: valueProp,
    onChange,
    ampm = false,
    ...props
}) => {
    const { meta, formik, field, helpers } = useFormikField<DateTime | null>(name);
    const value = !isNil(valueProp) ? valueProp : field?.value;

    const handleChange = (newValue: Maybe<DateTime>) => {
        if (onChange) {
            onChange(newValue);
        } else if (helpers) {
            helpers.setValue(newValue);
        }
    };

    return (
        <div>
            <TimePicker
                className={props.className}
                onChange={(newValue) => handleChange(newValue as any)}
                value={value}
                disabled={disabled || formik?.isSubmitting}
                ampm={ampm}
                renderInput={({ label, ...params }) => (
                    <TextInput
                        {...params}
                        {...props}
                        data-testid={`Timepicker${name ? `:${name}` : ''}`}
                        onBlur={field?.onBlur}
                        inputProps={{ ...params.inputProps, placeholder }}
                        error={meta.touched && Boolean(meta.error)}
                        sx={{ minWidth: '140px', ...props.sx }}
                        fullWidth={fullWidth}
                        autoComplete={'off'}
                    />
                )}
            />
            {name && <ValidationMessage field={name} />}
        </div>
    );
};
