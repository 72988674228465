import React, { useState } from 'react';
import { Button } from '@mui/material';
import { DocumentAdditionalDetailsDialog, useDocumentAdditionalDetailsDialog } from './DocumentAdditionalDetailsDialog';
import { useAppSnackbar } from '../../../AppNotification';
import {
    DocPurpose,
    DocumentInInput,
    DocumentSharesDocument,
    DocumentStorage,
    useSetDocumentsMutation,
} from 'interfaces/model';

interface IAddDocumentsButtonProps {
    purpose: DocPurpose;
    parentRefs: UUID[];
    selectedDocuments: UUID[];
    onSaveSuccess: (documentId: UUID) => Promise<void>;
}

export const AddDocumentsButton: React.FC<IAddDocumentsButtonProps> = ({
    purpose,
    parentRefs = [],
    selectedDocuments,
    onSaveSuccess,
}) => {
    const snackbar = useAppSnackbar();
    const [isSaving, setSaving] = useState(false);
    const [saveDocuments] = useSetDocumentsMutation();
    const [documentAdditionalDetailsDialog] = useDocumentAdditionalDetailsDialog();
    const storage = purpose === DocPurpose.FACILITY ? DocumentStorage.DATA_ROOM : DocumentStorage.KYC;
    const count = selectedDocuments.length;
    const multipleFiles = count > 1;
    const documentsLabel = `Document${multipleFiles ? 's' : ''}`;
    const isFacility = purpose === DocPurpose.FACILITY;

    const handleSubmit = async () => {
        if (count === 0) {
            return;
        }

        const submitDocuments = async (additionalDetails: DocumentInInput = {}) => {
            setSaving(true);
            try {
                const response = await saveDocuments({
                    refetchQueries: [DocumentSharesDocument],
                    variables: {
                        documents: parentRefs.flatMap((parentRef) =>
                            selectedDocuments.map((originRef) => ({
                                ...additionalDetails,
                                storage,
                                originRef,
                                parentRef,
                            }))
                        ),
                    },
                });
                const firstAddedDocumentId = response.data?.documents?.[0].id;
                await onSaveSuccess(firstAddedDocumentId!);
                setSaving(false);
                const message =
                    selectedDocuments.length > 1 ? 'Documents have been assigned' : 'Document has been assigned';
                snackbar.show({ message });
            } catch (e) {
                const message =
                    selectedDocuments.length > 1 ? 'Failed to assign documents' : 'Failed to assign document';
                setSaving(false);
                snackbar.showError({ message }, e);
            }
        };

        if (isFacility) {
            documentAdditionalDetailsDialog.open({
                onSubmit: submitDocuments,
            });
        } else {
            await submitDocuments();
        }
    };

    return (
        <>
            <Button
                variant={'contained'}
                disabled={selectedDocuments.length === 0 || parentRefs.length === 0 || isSaving}
                onClick={handleSubmit}
            >
                Add {multipleFiles && count} {documentsLabel} to {isFacility ? 'Facilities' : 'KYC'}
            </Button>
            <DocumentAdditionalDetailsDialog />
        </>
    );
};
