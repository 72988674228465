import React from 'react';
import { Delete } from '@mui/icons-material';
import {
    IListIconButtonProps,
    ListIconButton,
} from '../../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { useConfirmDialog } from '../../../../../../../lib/modals/ConfirmDialog';
import { useAppSnackbar } from '../../../../../../components/AppNotification';
import { useSetEntityMutation } from '../../../../queries/SetEntity.mutation';

interface IEntityDeleteIconButtonProps extends Omit<IListIconButtonProps, 'icon'> {
    id: string;
    name: string | undefined;
    onSuccess: () => void;
}

export const EntityDeleteIconButton: React.FC<IEntityDeleteIconButtonProps> = ({ id, name, onSuccess, ...props }) => {
    const snackbar = useAppSnackbar();
    const [{ open }] = useConfirmDialog();
    const [mutateEntity] = useSetEntityMutation();
    const handleDelete = async () => {
        try {
            await mutateEntity({
                variables: {
                    entity: {
                        id,
                        deleted: true,
                    },
                },
            });
            onSuccess();
            snackbar.show({ message: `${name} has been deleted` });
        } catch (e) {
            snackbar.showError({ message: `Failed to delete "${name}"` }, e);
        }
    };

    const openDialog = (e: React.MouseEvent) => {
        e.preventDefault();
        open({
            title: `Please confirm deletion`,
            body: `You are about to delete "${name}" and all of its subsidiaries. Roles will not be deleted. Are you sure?`,
            onConfirm: handleDelete,
        });
    };

    return <ListIconButton {...props} onClick={openDialog} icon={Delete} />;
};
