import React, { useEffect, useState } from 'react';
import { CircularProgress, Collapse, Divider, List, ListSubheader, Tooltip } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Warning } from '@mui/icons-material';
import { IControlledUserRecord, useControlledUsersLog } from './useControlledUsersLog';
import styles from './RecentUsersMenu.module.css';
import { RecentUserFragment, useRecentUsersLazyQuery } from '../../../../../interfaces/model';
import { useControlledUserSwitch } from '../useControlledUserSwitch';
import { fromEntries } from '../../../../../lib/utils/helpers';
import { UserProfileMenuItem } from '../UserProfileMenuItem';
import { IconWithTooltip } from '../../../../../lib/icons/IconWithTooltip';
import { useLinkNavigationBehavior } from '../../../../../lib/hooks/router/useLinkNavigationBehavior';
import { ROLE_VIEW_ROUTE_FACTORY } from '../../../../App.routes';

const MenuItem: React.FC<{ value: RecentUserFragment; onClick: () => void }> = ({ value, onClick }) => {
    const { name, isAdmin, topCo } = value;
    const navigateByRoute = useLinkNavigationBehavior();
    const [switchUser] = useControlledUserSwitch();

    return (
        <UserProfileMenuItem
            userName={name || 'Unknown user'}
            isAdmin={isAdmin}
            userCompany={topCo?.name}
            onClick={async (e) => {
                if (e.ctrlKey) {
                    navigateByRoute(e, ROLE_VIEW_ROUTE_FACTORY(value.shortCode));
                } else {
                    await switchUser(value);
                    onClick();
                }
            }}
        />
    );
};

const TOP_USERS_LIST_LENGTH = 3;
const sortUsers =
    (recentUsersMap: Record<string, IControlledUserRecord>) => (a: RecentUserFragment, b: RecentUserFragment) => {
        const getValue = ({ shortCode }: RecentUserFragment) => recentUsersMap[shortCode!].value || 0;
        return getValue(b) - getValue(a);
    };

export const RecentUsersMenu: React.FC<{ onItemClick: () => void }> = ({ onItemClick }) => {
    const [open, setOpen] = React.useState(false);
    const [recentUsers, setRecentUsers] = useControlledUsersLog();
    const [users, setUsers] = useState<RecentUserFragment[]>([]);
    const [fetchRecentUsers, { loading, error, called }] = useRecentUsersLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (recentUsers.length && !called) {
            fetchRecentUsers({
                variables: {
                    ids: recentUsers.map(({ id }) => id),
                },
            }).then(({ data }) => {
                const recentUsersMap = fromEntries(recentUsers.map((user) => [user.id, user]));
                setUsers([...(data?.roles.data || [])].sort(sortUsers(recentUsersMap)) || []);
            });
        }
    });

    if (!recentUsers.length) {
        return null;
    }

    const topUsers = users.slice(0, TOP_USERS_LIST_LENGTH);
    const restUsers = users.slice(TOP_USERS_LIST_LENGTH);

    const onUserSwitch = (user: RecentUserFragment) => {
        onItemClick();
        setRecentUsers(user);
    };

    return (
        <>
            <Divider className={styles.divider} />
            <ListSubheader className={styles.subheader}>
                Recent users
                {loading && <CircularProgress color={'primary'} size={16} />}
                {error && (
                    <Tooltip title={'Failed to load recent users'} arrow>
                        <Warning fontSize={'small'} color={'error'} />
                    </Tooltip>
                )}
                {!error && !loading && restUsers.length > 0 && (
                    <IconWithTooltip
                        text={`Show ${open ? 'less' : 'more'}`}
                        Icon={open ? ExpandLess : ExpandMore}
                        tooltipProps={{ disableInteractive: true }}
                        onClick={() => setOpen(!open)}
                    />
                )}
            </ListSubheader>
            <div className={styles.scrollContainer}>
                {topUsers.map((user) => (
                    <MenuItem key={user.id} value={user} onClick={() => onUserSwitch(user)} />
                ))}
                <Collapse in={open} timeout="auto">
                    <List component={'div'} className={styles.list}>
                        {restUsers.map((user) => (
                            <MenuItem key={user.id} value={user} onClick={() => onUserSwitch(user)} />
                        ))}
                    </List>
                </Collapse>
            </div>
            <Divider />
        </>
    );
};
