import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useFormikContext } from 'formik';
import { FormLabel } from '@mui/material';
import { EntityNameSuggestInput } from '../../../Entities/CreateEntityWizardDialog/EditEntityForm/EntitySuggestInput';
import { TaxIdSuggestInput } from '../../../Entities/CreateEntityWizardDialog/EditEntityForm/TaxIdSuggestInput';
import { CountrySelect } from 'lib/inputs/CountrySelect/CountrySelect';
import {
    Capabilities,
    EntityType,
    FundType,
    useDTTPSuggestLazyQuery,
    useGIINSuggestLazyQuery,
    useLEISuggestLazyQuery,
} from 'interfaces/model';
import { AUTHORISED_SIGNATURES_FIELD_ID, FUND_TYPE_LABELS } from '../../../../App.const';
import { IFundInitialForm } from '../useFundEditFormik/useFundEditFormik.const';
import { AddressFieldsGroup } from '../../../../common/AddressFieldsGroup';
import { FormDatepicker } from 'lib/forms/components/FormDatepicker';
import { EntitiesSelect } from '../../../../common/EntitiesSelect';
import { FormCheckbox } from 'lib/forms/components/FormCheckbox';
import { NifTaxNumberInput } from 'lib/inputs/NifTaxNumberInput';
import { selectOptionFactory } from 'lib/inputs/Select.const';
import { RolesSelect } from '../../../../common/RolesSelect';
import { NumberInput } from 'lib/inputs/NumberInput';
import { TextInput } from 'lib/inputs/TextInput';
import { Dropdown } from 'lib/inputs/Dropdown';
import { Fieldset } from 'lib/forms/Fieldset';

export interface IEditFundFormProps {
    disabled: boolean;
}

const PERMISSIONED_LENDER_OPTIONS = [
    { id: 'true', name: 'PAR Loans', value: true },
    { id: 'false', name: 'Non-PAR Loans', value: false },
];

const fundTypeOptionsFactory = selectOptionFactory(FundType, FUND_TYPE_LABELS);
const fundTypeOptions = [
    fundTypeOptionsFactory(FundType.SMA),
    fundTypeOptionsFactory(FundType.CLO),
    fundTypeOptionsFactory(FundType.CO_MINGLED),
];

export const EditFundForm: React.FC<IEditFundFormProps> = ({ disabled }) => {
    const formik = useFormikContext<IFundInitialForm>();
    const { isSubmitting, values, setFieldValue } = formik;
    const isDisabled = isSubmitting || disabled;

    const showBusinessAddress = formik.values.showBusinessAddress;
    const isReinvestmentPeriodEndDateDisabled = formik.values.reinvestmentPeriodEndDateNotAvailable;
    const isLegalMaturityDateDisabled = formik.values.legalMaturityDateNotAvailable;

    return (
        <Stack spacing={4}>
            <Fieldset title={'General details'}>
                <Grid spacing={2} container>
                    <Grid xs={6} item>
                        <FormLabel required>Fund Name</FormLabel>
                        <EntityNameSuggestInput disabled={isDisabled} />
                    </Grid>
                    <Grid xs={6} item>
                        <FormLabel required>Jurisdiction</FormLabel>
                        <CountrySelect name={'jurisdiction'} placeholder={'Enter country'} disabled={isDisabled} />
                    </Grid>
                    <Grid xs={6} item>
                        <FormLabel>Incorporation Number</FormLabel>
                        <TextInput
                            name={'incorporationNumber'}
                            placeholder={'Incorporation Number'}
                            disabled={isDisabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <FormLabel>LEI</FormLabel>
                        <TaxIdSuggestInput
                            query={useLEISuggestLazyQuery}
                            getOptions={(data) => data?.lei}
                            name={'lei'}
                            placeholder={'Enter LEI'}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <FormLabel required>Fund type</FormLabel>
                        <Dropdown
                            name={'type'}
                            options={fundTypeOptions}
                            placeholder={'Select fund type'}
                            fullWidth
                            disabled={isSubmitting}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <FormLabel required>Investment Manager</FormLabel>
                        <EntitiesSelect
                            name={'entityRef'}
                            entityType={EntityType.LENDER_INVESTMENT_MANAGER}
                            topCoRef={values.topCoRef}
                            disabled={isDisabled}
                            disableClearable
                            placeholder={'Select investment manager'}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <FormLabel>Fund Administrator</FormLabel>
                        <EntitiesSelect
                            name={'fundAdministratorRef'}
                            entityType={EntityType.FUNDADMIN}
                            disabled={isDisabled}
                            placeholder={'Select fund administrator'}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <FormLabel>Permissioned Lender Filter</FormLabel>
                        <Dropdown
                            name={'parLoansOnly'}
                            placeholder={'Please select'}
                            options={PERMISSIONED_LENDER_OPTIONS}
                            fullWidth
                            disabled={isDisabled}
                        />
                    </Grid>
                    <FormDatepicker
                        name={'reinvestmentPeriodEndDate'}
                        label={'Reinvestment period end date'}
                        xs={6}
                        required={!isReinvestmentPeriodEndDateDisabled}
                        disabled={isReinvestmentPeriodEndDateDisabled}
                        maxDate={formik.values.legalMaturityDate}
                    />
                    <FormDatepicker
                        name={'legalMaturityDate'}
                        label={'Legal maturity date'}
                        xs={6}
                        required={!isLegalMaturityDateDisabled}
                        disabled={isLegalMaturityDateDisabled}
                    />
                    <FormCheckbox xs={6} name={'reinvestmentPeriodEndDateNotAvailable'} label={'Not available'} />
                    <FormCheckbox name={'legalMaturityDateNotAvailable'} label={'Not available'} xs={6} />
                </Grid>
            </Fieldset>
            <Fieldset title={'Tax IDs'}>
                <Grid spacing={2} container>
                    <Grid xs={6} item>
                        <FormLabel>DTTP (UK tax ID)</FormLabel>
                        <TaxIdSuggestInput
                            query={useDTTPSuggestLazyQuery}
                            getOptions={(data) => data?.dttp}
                            name={'dttp'}
                            id={'dttp'}
                            placeholder={'00/A/123456/DTTP'}
                            disabled={isDisabled}
                            transformValue={(value) => value.replace(/[^a-zA-Z0-9/]/g, '').toUpperCase()}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <FormLabel>GIIN (US Tax ID)</FormLabel>
                        <TaxIdSuggestInput
                            query={useGIINSuggestLazyQuery}
                            getOptions={(data) => data?.giin}
                            name={'giin'}
                            id={'giin'}
                            placeholder={'0A0A0A.0A0A0.AA.000'}
                            disabled={isDisabled}
                            transformValue={(value) => value.replace(/[^a-zA-Z0-9.]/g, '').toUpperCase()}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <FormLabel>NIF (Spanish Tax ID)</FormLabel>
                        <NifTaxNumberInput
                            id={'nif'}
                            value={values.nif!}
                            onChange={(value) => setFieldValue('nif', value)}
                            disabled={isDisabled}
                        />
                    </Grid>
                </Grid>
            </Fieldset>
            <Fieldset title={'Registered Address'} required>
                <Grid spacing={2} container>
                    <Grid xs={12} item>
                        <AddressFieldsGroup name={'address'} disabled={isDisabled} />
                    </Grid>
                    <Grid xs={12} item>
                        <FormCheckbox
                            name={'showBusinessAddress'}
                            label={'Business Address is different than Registered Address'}
                        />
                    </Grid>
                </Grid>
            </Fieldset>
            {showBusinessAddress && (
                <Fieldset title={'Business Address'} required>
                    <Grid container>
                        <Grid xs={12} item>
                            <AddressFieldsGroup name={'businessAddress'} disabled={isDisabled} />
                        </Grid>
                    </Grid>
                </Fieldset>
            )}
            <Fieldset title={'Additional information'}>
                <Grid spacing={2} container>
                    <Grid xs={12} item>
                        <FormLabel>Settlement Lead Time (Business Days)</FormLabel>
                        <NumberInput
                            name={'settlementLeadTime'}
                            placeholder={'Number of days'}
                            InputProps={{ inputProps: { size: 8 } }}
                            disabled={isDisabled}
                            autoComplete={'off'}
                            value={values.settlementLeadTime}
                            onValueChange={(value) => setFieldValue('settlementLeadTime', value)}
                            fullWidth
                            variant={'outlined'}
                        />
                    </Grid>
                    <Grid xs={12} item sx={{ marginBottom: !formik.values.id ? '32px' : 'inherit' }}>
                        <FormLabel>Authorised Signatories</FormLabel>
                        <RolesSelect
                            id={AUTHORISED_SIGNATURES_FIELD_ID}
                            value={values.authorizedSignatory || void 0}
                            capability={Capabilities.ESIG}
                            multiple
                            topCoRef={values.topCoRef || ''}
                            onChange={(value) => setFieldValue('authorizedSignatory', value)}
                            disabled={isDisabled}
                        />
                    </Grid>
                </Grid>
            </Fieldset>
        </Stack>
    );
};
