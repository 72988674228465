import React from 'react';
import { RowStack } from '../../../lib/cdk/RowStack';
import { Tag } from '../../../lib/dataDisplay/Tag';
import { Capabilities } from '../../../interfaces/model';
import { hasDirectorRelation } from './RoleEditDialog/useRoleEditFormik/useRoleEditFormik.const';

interface IRoleNameProps {
    value: {
        name: Maybe<string>;
        capabilities: Maybe<Capabilities[]>;
        entityRelations: Maybe<{ director?: boolean }[]> | undefined;
    };
    config?: {
        directorBadge?: boolean;
        adminBadge?: boolean;
    };
}

export const RoleName: React.FC<IRoleNameProps> = ({ value, config }) => {
    const canManageStructure = !!value.capabilities?.includes(Capabilities.MANAGE_STRUCTURE);
    const isDirector = hasDirectorRelation(value.entityRelations);
    const { adminBadge = true, directorBadge = true } = config || {};

    if (!value.name) {
        return null;
    }

    return (
        <RowStack>
            <span>{value.name}</span>
            {canManageStructure && adminBadge && <Tag color={'purple'}>Admin</Tag>}
            {isDirector && directorBadge && <Tag color={'yellow'}>Director</Tag>}
        </RowStack>
    );
};
