import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { IListRow } from '../../../../../../../../../lib/dataDisplay/List';
import { ReviewStatus, ReviewType } from '../../../../../../../../../interfaces/model';
import {
    DateValueCell,
    LinkCell,
    ValueCell,
} from '../../../../../../../../../lib/dataDisplay/List/components/ListCells';
import { AGENT_VIEW_ROUTE_FACTORY, ROLE_VIEW_ROUTE_FACTORY } from '../../../../../../../../App.routes';
import { KYCReviewStatusChip } from '../../../../../KYCReviewStatusChip';
import { KYCLedgerCommReviewMark } from '../../../../../KYCLedgerCommReviewMark';

export interface IDocumentReviewersListRowItem {
    id: string;
    agentName: string;
    agentSc: string;
    userName: string;
    userSc: string;
    status: ReviewStatus;
    reviewType: ReviewType | undefined;
    timestamp: number | undefined;
    comment: string;
}

type IDocumentReviewersListRowProps = IListRow<IDocumentReviewersListRowItem>;

export const DocumentReviewersListRow: React.FC<IDocumentReviewersListRowProps> = ({ data, columns }) => {
    return (
        <TableRow>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'agentName' && (
                        <LinkCell
                            to={data.agentSc && AGENT_VIEW_ROUTE_FACTORY(data.agentSc)}
                            sx={{ minWidth: '140px' }}
                        >
                            {data.agentName}
                        </LinkCell>
                    )}
                    {id === 'userName' && (
                        <LinkCell to={data.userSc && ROLE_VIEW_ROUTE_FACTORY(data.userSc)} sx={{ minWidth: '140px' }}>
                            {data.userName}
                        </LinkCell>
                    )}
                    {id === 'status' && (
                        <TableCell>
                            <KYCReviewStatusChip value={data.status} />
                        </TableCell>
                    )}
                    {id === 'reviewType' && (
                        <TableCell sx={{ paddingLeft: 0 }}>
                            {data.reviewType === ReviewType.LEDGERCOMM && <KYCLedgerCommReviewMark />}
                        </TableCell>
                    )}
                    {id === 'timestamp' && <DateValueCell value={data.timestamp} />}
                    {id === 'comment' && (
                        <ValueCell nowrap={false} breakAll>
                            {data.comment}
                        </ValueCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
