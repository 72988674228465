import React from 'react';
import { TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { EntitiesListRowFragment, KYCCompleteStatus } from '../../../../interfaces/model';
import { ActionsCell, JurisdictionCell, ValueCell } from '../../../../lib/dataDisplay/List/components/ListCells';
import { NoValuePlaceholder } from '../../../../lib/cdk/NoValuePlaceholder';
import { IsCompleteIcon } from '../../../../lib/icons/IsCompleteIcon';
import { IGqlListRow } from '../../../../lib/dataDisplay/List';
import { ENTITY_TYPE_LABELS } from '../../../App.const';
import { ListCellItemWrapper } from '../../../../lib/dataDisplay/List/components/ListCellItemWrapper';
import { EntityDeleteIconButton } from '../ViewEntity/LegalEntities/LegalEntitiesList/LegalEntitiesListRow/EntityDeleteIconButton';
import { useParentContext } from '../../../../lib/cdk/ParentContext';
import Box from '@mui/material/Box';
import { AgentManagementLabel } from '../../../common/AgentManagementLabel';
import { getEntityViewRouteByType } from '../../../App.routes';

type IEntitiesListRowProps = IGqlListRow<EntitiesListRowFragment>;

export const EntitiesListRow: React.FC<IEntitiesListRowProps> = ({ item, columns, highlightText }) => {
    const parent = useParentContext();
    return (
        <TableRow component={Link} to={getEntityViewRouteByType(item.entityType)(item.shortCode!)} hover>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'name' && (
                        <ValueCell nowrap={false} highlight={highlightText}>
                            <Box display={'flex'} alignItems={'center'}>
                                {item.name || <NoValuePlaceholder />}
                                {item.isAgentExt && <AgentManagementLabel compact />}
                            </Box>
                        </ValueCell>
                    )}
                    {id === 'entityType' && <ValueCell>{ENTITY_TYPE_LABELS[item.entityType]}</ValueCell>}
                    {id === 'headquarters' && (
                        <JurisdictionCell sx={{ width: '250px' }} value={item.headquarters} nowrap />
                    )}
                    {id === 'kycComplete' && (
                        <ValueCell align={'center'}>
                            <ListCellItemWrapper>
                                {item.kycComplete !== null && (
                                    <IsCompleteIcon complete={item.kycComplete === KYCCompleteStatus.COMPLETE} />
                                )}
                            </ListCellItemWrapper>
                        </ValueCell>
                    )}
                    {id === 'actions' && (
                        <ActionsCell>
                            <EntityDeleteIconButton id={item.id} name={item.name} onSuccess={parent.update} />
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
