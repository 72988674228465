import cl from 'classnames';
import React, { ReactNode } from 'react';
import { Paper, PaperProps } from '@mui/material';
import styles from './EmptyStatePlaceholder.module.css';
import { PaperDark } from '../../../lib/surfaces/PaperDark';

export interface IEmptyStatePlaceholderProps extends PaperProps {
    title?: string;
    description?: string;
    dark?: boolean;
    imageName?:
        | 'pack'
        | 'premium'
        | 'bulk_upload'
        | 'add_task'
        | 'validation'
        | 'invoices'
        | 'analitics'
        | 'locked_files';
    action?: ReactNode;
}

export const EmptyStatePlaceholder: React.FC<IEmptyStatePlaceholderProps> = ({
    title = 'There is nothing here',
    description,
    imageName = 'pack',
    dark,
    action,
    ...props
}) => {
    const Wrapper = dark ? PaperDark : Paper;
    return (
        <Wrapper className={styles.container} {...props}>
            <div className={cl(dark ? styles.backgroundDark : styles.background)}>
                <img className={styles.image} src={`/img/emptyState/${imageName}.svg`} alt="empty" />
            </div>
            <div className={styles.contentContainer}>
                <h4 className={styles.title}>{title}</h4>
                {description && <p className={styles.description}>{description}</p>}
                {action && <div className={styles.actionContainer}>{action}</div>}
            </div>
        </Wrapper>
    );
};
