import * as yup from 'yup';
import React, { useEffect } from 'react';
import styles from './Login.module.css';
import { LoginForm } from './LoginForm';
import { AppHeader } from '../../AppHeader';
import { useDispatch, useSelector } from 'react-redux';
import { APP_INITIALIZED_SELECTOR } from '../../../App.selectors';
import { Redirect, useHistory, useLocation } from 'react-router';
import { DASHBOARD_ROUTE } from '../../../App.routes';
import { AppHeaderContainer } from '../../AppHeader/AppHeaderContainer';
import { useTermsAndConditionsDialog } from './TermsAndConditionsDialog';
import { useUserDetails } from '../../../hooks/useUserDetails';
import { useUrlParams } from 'lib/hooks/router/useUrlParams';
import { useAuthLazyQuery } from '../Auth.query';
import { userLogin } from '../../../App.actions';

export const Login: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { totpRequired, authRole } = useUserDetails();
    const urlParams = useUrlParams({
        validationSchema: {
            terms: yup.boolean(),
        },
    });

    const isAppLoaded = useSelector(APP_INITIALIZED_SELECTOR);
    const [termsAndConditionsDialog, isTermsAndConditionsDialogOpened] = useTermsAndConditionsDialog();
    const [loadUser] = useAuthLazyQuery();

    useEffect(() => {
        if (!authRole?.termsAcceptedAt && !totpRequired && urlParams.terms && !isTermsAndConditionsDialogOpened) {
            termsAndConditionsDialog.open({
                mode: 'submit',
                onSubmitSuccess: async () => {
                    const { data } = await loadUser();
                    if (data) {
                        dispatch(userLogin(data.auth));
                        history.replace(location.state?.from || DASHBOARD_ROUTE);
                    } else {
                        window.location.reload();
                    }
                },
            });
        }
    });

    if (isAppLoaded) {
        const previousRoute = location.state?.from.pathname !== location.pathname && location?.state?.from;
        return <Redirect to={previousRoute || DASHBOARD_ROUTE} />;
    }

    return (
        <div className={styles.wrap}>
            <AppHeaderContainer className={styles.header} maxWidth={false} disableGutters>
                <AppHeader />
            </AppHeaderContainer>
            <div className={styles.content}>
                <div className={styles.contentLeft}>
                    <LoginForm />
                </div>
                <div className={styles.contentRight}>
                    <div className={styles.promo}>
                        <img className={styles.promoImg} src={'/img/ledgercomm-logo.svg'} alt="LedgerComm logo" />
                        <p className={styles.promoText}>
                            <span>Ledger</span>
                            <span>Comm</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
