import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import { IRoleRelationsEditFormListRowItem, RoleRelationsEditListRow } from './RoleRelationsEditListRow';
import { IListColumn } from '../../../../../lib/dataDisplay/List';
import { EntityType } from '../../../../../interfaces/model';
import { List } from '../../../../../lib/dataDisplay/List/List';
import styles from './RoleRelationsEditList.module.css';

interface IRoleRelationsEditListProps {
    entityType: EntityType | undefined | null;
}

const CHILDREN_ENTITY_NAME_BY_TOPCO_TYPE_MAP: Partial<Record<EntityType, string>> = {
    [EntityType.BORROWER_TOP_LEVEL]: 'Subsidiary',
    [EntityType.LENDER_TOP_LEVEL]: 'Lender',
    [EntityType.AGENT_TOP_LEVEL]: 'Legal entity',
    [EntityType.FUNDADMIN_TOP_LEVEL]: 'Subsidiary',
};

export const RoleRelationsEditList: React.FC<IRoleRelationsEditListProps> = ({ entityType }) => {
    const formik = useContext(FormikContext);
    const columns: IListColumn<IRoleRelationsEditFormListRowItem>[] = [
        { id: 'enabled', label: 'Access', sort: false },
        { id: 'name', label: CHILDREN_ENTITY_NAME_BY_TOPCO_TYPE_MAP[entityType!] || '' },
        { id: 'director', label: 'Director', shrink: true, sort: false },
        { id: 'ubo', label: 'UBO', shrink: true, sort: false },
        { id: 'psc', label: 'PSC', shrink: true, sort: false },
    ];

    return (
        <List
            data={formik.values.entityRelations}
            rowComponent={RoleRelationsEditListRow}
            emptyStatePlaceholderProps={{
                title: 'No legal entities have been created',
                description: 'You need to create legal entities to be able to trade or become a lender of record',
            }}
            className={styles.list}
            defaultSortBy={'name'}
            columns={columns}
            stickyHeader
            embedded
        />
    );
};
