import React from 'react';
import {
    DocPurpose,
    DocumentFilterInput,
    KYCDocTypeFragment,
    useDocumentsQuery,
} from '../../../../../../../interfaces/model';
import {
    DeletedDocumentsListRow,
    IDeletedDocumentsListRowDetailsComponent,
    IDeletedDocumentsListRowItem,
} from './DeletedDocumentsListRow';
import { GqlQueryList } from '../../../../../../../lib/dataDisplay/List/GqlQueryList';
import { IGqlListColumn } from '../../../../../../../lib/dataDisplay/List';
import { IDocumentViewDialogParent } from '../../interfaces';
import { NOOP } from 'lib/utils/helpers';

interface IDeletedDocumentsListProps {
    focusedItemId: UUID | undefined;
    parent: IDocumentViewDialogParent | undefined;
    updateData: () => Promise<void>;
    onDeleteSuccess: () => Promise<void>;
    onRestoreSuccess: () => Promise<void>;
    RowDetailsComponent: IDeletedDocumentsListRowDetailsComponent;
    columns?: IGqlListColumn<IDeletedDocumentsListRowItem>[];
    onDocTypesChange?: (docTypes: KYCDocTypeFragment[]) => void;
    filter: DocumentFilterInput;
}

const getColumns = (filter: DocumentFilterInput): IGqlListColumn<IDeletedDocumentsListRowItem>[] => [
    { id: 'id', shrink: true },
    { id: 'name', label: 'Name' },
    {
        id: 'docType',
        label: !filter.purpose || filter.purpose === DocPurpose.FACILITY ? 'Category/Subcategory' : 'Category',
    },
    { id: 'deletedAt', label: 'Deleted date' },
    { id: 'deletedBy', label: 'Deleted by' },
    { id: 'actions', shrink: true },
];

export const DeletedDocumentsList: React.FC<IDeletedDocumentsListProps> = ({
    parent,
    columns,
    updateData,
    focusedItemId,
    onDeleteSuccess,
    onRestoreSuccess,
    RowDetailsComponent,
    onDocTypesChange = NOOP,
    filter,
}) => {
    return (
        <GqlQueryList
            fetchPolicy={'no-cache'}
            query={useDocumentsQuery}
            getResult={(data) => data?.documents}
            variables={{ filter, parentRef: parent?.id }}
            skipFetch={!filter.id && !filter.topCoRef}
            columns={columns || getColumns(filter)}
            rowComponent={DeletedDocumentsListRow}
            rowComponentProps={{
                parent,
                updateData,
                focusedItemId,
                RowDetailsComponent,
                onRestoreSuccess,
                onDeleteSuccess,
            }}
            onCompleted={(data) => onDocTypesChange(data.documents.docTypes || [])}
            emptyStatePlaceholderProps={{
                title: 'No documents',
                description: 'There are no deleted documents in the system',
                sx: { background: 'transparent' },
            }}
            highlightText={filter.name || ''}
            defaultSortBy={'deletedAt'}
        />
    );
};
