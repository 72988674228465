import React from 'react';
import {
    DocPurpose,
    DocumentStorage,
    IdType,
    KYCDocumentShareFragment,
    KYCDocumentShareListFragment,
    useDocumentSharesQuery,
} from '../../../../../../interfaces/model';
import { DocumentSharesListRow, IDocumentSharesListRowItem } from './DocumentSharesListRow';
import { List } from '../../../../../../lib/dataDisplay/List/List';
import { IListColumn } from '../../../../../../lib/dataDisplay/List';
import { getViewRouteFactoryByIdType } from '../../../Documents.const';

interface IDocumentSharesListProps {
    documentId: UUID;
    updateData: () => Promise<void>;
    columns?: IListColumn<IDocumentSharesListRowItem>[];
    disabled?: boolean;
}

const getSharesCombinedWithParent = (value: KYCDocumentShareListFragment | undefined): KYCDocumentShareFragment[] => {
    const shares = value?.shares || [];
    const parentShare = value?.parent;
    return parentShare && shares.length === 0 ? [...shares, value] : shares;
};

const getLocationType = (item: KYCDocumentShareFragment) => {
    if (item.storage === DocumentStorage.KYC) {
        return 'KYC';
    }

    if (item.storage === DocumentStorage.DATA_ROOM && item.type === IdType.FACILITY) {
        return 'Facility';
    }

    return 'Dataroom';
};

const prepareData = (value: KYCDocumentShareListFragment | undefined): IDocumentSharesListRowItem[] => {
    const shares = getSharesCombinedWithParent(value);
    return shares.map((item) => {
        const parent = item.parent;
        const parentModel = parent.entity || parent.role || parent.loanFund || parent.facility;

        return {
            ...item,
            locationType: getLocationType(item),
            filledByName: item.role?.name || '',
            filledBySc: item.role?.shortCode || '',
            purpose: item.parent.idType === IdType.FACILITY ? DocPurpose.FACILITY : DocPurpose.KYC,
            parentName: parentModel?.name || '',
            parentRoute: getViewRouteFactoryByIdType(parent)(parentModel?.shortCode || '') || '',
            status: item.status?.status,
            document: item,
        };
    });
};

const COLUMNS: IListColumn<IDocumentSharesListRowItem>[] = [
    {
        id: 'locationType',
        label: (
            <span>
                Location
                <br />
                type
            </span>
        ),
    },
    { id: 'parentName', label: 'Location' },
    {
        id: 'docType',
        label: (
            <span>
                Category
                <br />/ Subcategory
            </span>
        ),
    },
    { id: 'createdAt', label: 'Filing date' },
    { id: 'filledByName', label: 'Filed by' },
    { id: 'status', label: 'Status' },
    { id: 'actions', shrink: true },
];

export const DocumentSharesList: React.FC<IDocumentSharesListProps> = ({
    documentId,
    disabled,
    updateData: updateParentData,
    columns,
}) => {
    const { data, error, loading, refetch } = useDocumentSharesQuery({
        fetchPolicy: 'no-cache',
        variables: { documentId },
    });

    const updateData = async () => {
        await updateParentData();
        await refetch();
    };

    return (
        <List
            columns={columns || COLUMNS}
            data={prepareData(data?.document)}
            rowComponent={DocumentSharesListRow}
            rowComponentProps={{ updateData, disabled }}
            defaultSortBy={'createdAt'}
            loading={loading}
            error={error}
            pagination
        />
    );
};
