import { DocumentStorage, KYCDocType, TaxIdInput } from '../../../interfaces/model';
import { ITaxIdEntityFundEdit } from './TaxIdsList/TaxIdEditDialog/TaxIdEditDialog.const';
import { useDocumentUpload } from '../../hooks/useDocumentUpload';
import { dateToModelValue } from '../../../lib/utils/date';

export const useTaxIdsDocumentsUpload = () => {
    const uploadDocuments = useDocumentUpload();
    return async (taxIds: ITaxIdEntityFundEdit[], entityFundId: UUID | undefined): Promise<TaxIdInput[]> => {
        const ids = await Promise.all(
            taxIds
                .filter((item) => item.taxIdFiles?.length && item.taxIdFiles?.length > 0)
                .map((item) => {
                    return uploadDocuments({
                        parentRef: entityFundId,
                        files: item.taxIdFiles!,
                        value: {
                            docType: KYCDocType.TAX_ID_FORM,
                            validFromDate: dateToModelValue(item.validFromDate),
                            validToDate: dateToModelValue(item.validToDate),
                            storage: DocumentStorage.KYC,
                        },
                    });
                })
        );
        return ids.map((item, index) => {
            const kycId = item?.data?.document?.id;
            const taxId = taxIds[index];
            return {
                id: taxId.id,
                taxIdValue: taxId.taxIdValue,
                country: taxId.country,
                kycId,
            };
        });
    };
};
