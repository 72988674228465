import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { DocPurpose, DocumentStorage, KYCDocCategory, KYCDocSubcategory, KYCDocType } from 'interfaces/types';
import { IDocumentGroupTreeItem } from './DocumentsUploadHistoryView/DocumentsSubsetGroupsTree';
import { KYCDocumentFragment, KYCDocumentParentFragment } from '../../KYCDocument.fragment';
import { useCurrentDocumentRouteFactory } from '../../useCurrentDocumentRouteFactory';
import { DocumentsUploadsHistoryView } from './DocumentsUploadHistoryView';
import { getModelByIdType } from '../../../../helpers/getModelByIdType';
import { LoadingContentWrap } from 'lib/dataDisplay/LoadingContentWrap';
import { makeDialogState } from 'store/dialogState/makeDialogState';
import { useDocumentsUrlState } from '../../useDocumentsUrlState';
import { DialogCloseButton } from 'lib/modals/DialogCloseButton';
import { NonFalsy, NOOP, NOOP_ASYNC } from 'lib/utils/helpers';
import { useAddDocumentsDialog } from '../AddDocumentsDialog';
import { kycDocCategoryLabels } from '../../../../App.const';
import { IDocumentViewDialogParent } from './interfaces';
import { getYearFromDate } from '../../Documents.const';
import { useDocumentsQuery } from './Documents.query';

interface IDocumentsViewDialogState {
    category?: KYCDocCategory;
    docType?: KYCDocType;
    subcategory?: KYCDocSubcategory;
    year?: string;
    parentRef: UUID;
    onClose?: () => void;
}

export interface IDocumentsViewDialogConfig {
    storage: DocumentStorage;
    purpose: DocPurpose;
}

interface IDocumentsViewDialogProps {
    onNewFileUpload?: () => void;
    config: IDocumentsViewDialogConfig;
    updateData?: () => Promise<void>;
}

const getParent = (documentsParent: KYCDocumentParentFragment | undefined): IDocumentViewDialogParent | undefined => {
    const parentModel = getModelByIdType(documentsParent);
    if (documentsParent && parentModel) {
        return {
            id: documentsParent.id,
            topCoRef: parentModel.topCoRef,
            name: parentModel.name || '',
            type: documentsParent.idType,
            hasDocumentEditAccess: !!parentModel.hasDocumentEditAccess,
            hasDocumentApproveAccess: !!parentModel.hasDocumentApproveAccess,
        };
    }
};

const useFirstDocumentSelectIfNoSelection = (documents: KYCDocumentFragment[], selection: IDocumentGroupTreeItem) => {
    const [, setUrlState] = useDocumentsUrlState();
    useEffect(() => {
        if (documents.length && Object.values(selection).filter(NonFalsy).length === 0) {
            const [firstDocument] = documents;
            setUrlState(
                {
                    category: firstDocument.docCategory,
                    docType: firstDocument.docType,
                    docSubcategory: firstDocument.docSubcategory || void 0,
                    year: getYearFromDate(firstDocument.validFromDate),
                },
                { replace: true }
            );
        }
    });
};

export const useDocumentsViewDialog = makeDialogState<IDocumentsViewDialogState>();

export const DocumentsViewDialog: React.FC<IDocumentsViewDialogProps> = ({
    config,
    updateData: updateParentData = NOOP_ASYNC,
}) => {
    const history = useHistory();
    const getRoute = useCurrentDocumentRouteFactory();
    const [isUpdating, setIsUpdating] = useState(false);
    const [addDocumentsDialog] = useAddDocumentsDialog();
    const [urlState, setUrlState] = useDocumentsUrlState();
    const [{ close }, isOpen, state] = useDocumentsViewDialog();
    const { category, docType, subcategory, year, parentRef, onClose = NOOP } = state || {};
    const { data, error, loading, refetch } = useDocumentsQuery({
        fetchPolicy: 'cache-and-network',
        skip: !isOpen || !parentRef,
        variables: {
            parentRef: parentRef!,
            filter: {
                id: parentRef!,
                storage: config.storage,
            },
        },
    });

    const documentId = urlState.id;
    const document = documentId ? data?.documents.data.find(({ id }) => id === documentId) : void 0;
    const documentYear = getYearFromDate(document?.validFromDate) || '';
    const parent = getParent(data?.model);

    const selection = {
        category: document?.docCategory || urlState.category || category,
        docType: document?.docType || urlState.docType || docType,
        subcategory: document?.docSubcategory || urlState.docSubcategory || subcategory,
        year: documentYear || urlState.year || year,
        deleted: urlState.deleted,
    };

    useFirstDocumentSelectIfNoSelection(data?.documents.data || [], selection);

    const handleClose = () => {
        onClose();
        close();
    };

    const parentName = parent?.name;
    const updateData = async () => {
        setIsUpdating(true);
        await refetch();
        await updateParentData();
        setIsUpdating(false);
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth={'lg'} data-testid={'DocumentsViewDialog'} fullWidth>
            <DialogTitle>
                {`${kycDocCategoryLabels[category!] || 'Dataroom'}${parentName ? `: ${parentName}` : ''}`}
                <DialogCloseButton onClick={handleClose} />
            </DialogTitle>
            <DialogContent>
                <LoadingContentWrap loading={loading || isUpdating} error={error} size={40} left={'65%'} top={'45%'}>
                    <DocumentsUploadsHistoryView
                        parent={parent}
                        value={data?.documents.data || []}
                        selection={selection}
                        onSelect={(newSelection) => setUrlState({ id: void 0, ...newSelection }, { replace: true })}
                        kycMode={config.purpose === DocPurpose.KYC}
                        focusedItemId={urlState.id}
                        updateData={updateData}
                    />
                </LoadingContentWrap>
            </DialogContent>
            <DialogActions sx={{ background: 'var(--bg-paper)', minHeight: '81px' }}>
                {parent?.hasDocumentEditAccess && (
                    <Button
                        color={'primary'}
                        data-testid={'DataroomDialog:addDocumentsButton'}
                        variant={'contained'}
                        onClick={() =>
                            addDocumentsDialog.open({
                                purpose: config.purpose,
                                storage: config.storage,
                                docType: selection.docType,
                                docSubcategory: subcategory,
                                onSaveSuccess: async (id) => {
                                    await updateData();
                                    history.push(getRoute({ id }));
                                },
                                parent,
                            })
                        }
                    >
                        Add documents
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
