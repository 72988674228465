export const SHOW_DIALOG = '[Dialog] The dialog has been opened';
export const showDialog = (payload: { id: string; value: object; config?: object }) => {
    return {
        type: SHOW_DIALOG,
        payload,
    } as const;
};

export const HIDE_DIALOG = '[Dialog] The dialog has been closed';
export const hideDialog = (payload: { id: string }) => {
    return {
        type: HIDE_DIALOG,
        payload,
    } as const;
};

export type IDialogStateAction = ReturnType<typeof showDialog> | ReturnType<typeof hideDialog>;
