import isNumber from 'lodash/isNumber';
import { ServerError } from '@apollo/client/link/utils/throwServerError';
import { joinQueryParams } from '../lib/utils/router/joinQueryParams';
import { FacilityTradeDocumentTemplate, TradeDocumentType } from '../interfaces/model';
import { NetworkError } from '@apollo/client/errors';
import { FileDownloadType } from './App.const';

export const API_UNAUTHORIZED_STATUS_CODE = 401;

export const isServerError = (error: NetworkError | undefined): error is ServerError => {
    return isNumber((error as ServerError)?.statusCode);
};

export const isUnauthorizedServerError = (error: NetworkError | undefined) => {
    return isServerError(error) && error.statusCode === API_UNAUTHORIZED_STATUS_CODE;
};

export const documentDownloadUrl = (apiRoot: string, fileId: UUID) => {
    return `${apiRoot}/document/${fileId}`;
};

export const paymentSentEventFileDownloadUrl = (apiRoot: string, intentId: UUID, contentFileId: UUID) => {
    return `${apiRoot}/file/sendevent/${intentId}/${contentFileId}`;
};

export const interestNoticeSummaryDownloadUrl = (
    apiRoot: string,
    couponId: string,
    lenderId: string,
    downloadType: FileDownloadType
) => {
    return apiRoot + `/facility/coupon?id=${couponId}&lenderId=${lenderId}&type=${downloadType}`;
};

export const facilityTransferAgreementTemplatePreviewUrl = (
    apiRoot: string,
    facilityRef: UUID,
    queryParams: {
        templateName: FacilityTradeDocumentTemplate;
    }
) => {
    return `${apiRoot}/facility/${facilityRef}/templates/preview${joinQueryParams(queryParams)}`;
};

export const tradeConfGenerateUrl = (
    apiRoot: string,
    queryParams: {
        allocationTradeTicketRef: string;
        allocationId: string;
        matchEntityFundId: string;
        documentType: TradeDocumentType;
    }
) => {
    return apiRoot + `/trade/documents/preview${joinQueryParams(queryParams)}`;
};
