export enum KYCStatus {
    PENDING_UPLOAD = 'PENDING_UPLOAD',
    PENDING_REVIEW = 'PENDING_REVIEW',
    REJECTED = 'REJECTED',
    COMPLETE = 'COMPLETE',
    NO_REVIEWERS = 'NO_REVIEWERS',
}

export const kycStatusLabels: Record<KYCStatus, string> = {
    NO_REVIEWERS: 'No agents',
    PENDING_UPLOAD: 'Not uploaded',
    PENDING_REVIEW: 'Waiting for review',
    REJECTED: 'Rejected',
    COMPLETE: 'Approved',
};

export const kycStatusColors: Record<KYCStatus, Colors> = {
    NO_REVIEWERS: 'secondary',
    PENDING_UPLOAD: 'secondary',
    PENDING_REVIEW: 'warning',
    REJECTED: 'error',
    COMPLETE: 'success',
};
