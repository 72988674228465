import React from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { Grid, Link, Paper, Stack, Tooltip } from '@mui/material';
import { ListRowSpacer } from 'lib/dataDisplay/List/components/ListRowSpacer';
import { bankAccountFieldTypeLabels } from './BankAccountEditDialog.const';
import { NoValuePlaceholder } from 'lib/cdk/NoValuePlaceholder';
import { useClipboard } from 'lib/hooks/useClipboard';
import { BankAccountField } from 'interfaces/model';
import { EmptyStatePlaceholder } from '../../common/EmptyStatePlaceholder';

interface IBankAccountFieldsViewProps {
    accountFields: BankAccountField[];
    correspondingFields: Maybe<BankAccountField[]>;
    intermediaryFields: Maybe<BankAccountField[]>;
}

const AccountFieldsView: React.FC<{ title: string; value: BankAccountField[] }> = ({ title, value }) => {
    const copyToClipboard = useClipboard();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>{title}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {value.map(({ fieldType, fieldValue }, index) => (
                    <React.Fragment key={fieldType}>
                        <TableRow>
                            <TableCell width={'30%'}>{bankAccountFieldTypeLabels[fieldType]}</TableCell>
                            <TableCell align={'right'}>
                                {fieldValue ? (
                                    <Tooltip title={'Click to copy to clipboard'} disableInteractive arrow>
                                        <span onClick={() => copyToClipboard(fieldValue)}>
                                            <Link underline={'none'} color={'inherit'} sx={{ wordBreak: 'break-all' }}>
                                                {fieldValue}
                                            </Link>
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <NoValuePlaceholder />
                                )}
                            </TableCell>
                        </TableRow>
                        {index !== value.length - 1 && <ListRowSpacer colspan={2} height={'4px'} />}
                    </React.Fragment>
                ))}
                {value.length === 0 && (
                    <TableRow>
                        <TableCell sx={{ padding: 0 }}>
                            <EmptyStatePlaceholder title={'No bank details'} elevation={0} />
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export const BankAccountFieldsView: React.FC<IBankAccountFieldsViewProps> = ({
    accountFields,
    intermediaryFields,
    correspondingFields,
}) => {
    const hasOtherBankDetails = Boolean(intermediaryFields?.length || correspondingFields?.length);

    return (
        <Paper sx={{ background: 'var(--bg-default)', padding: '14px' }} elevation={0}>
            <Grid spacing={2} container>
                <Grid item xs={12} md={hasOtherBankDetails ? 6 : 12}>
                    <AccountFieldsView title={'Beneficiary bank details'} value={accountFields} />
                </Grid>
                {hasOtherBankDetails && (
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            {!!intermediaryFields?.length && (
                                <AccountFieldsView title={'Intermediary bank details'} value={intermediaryFields} />
                            )}
                            {!!correspondingFields?.length && (
                                <AccountFieldsView title={'Corresponding bank details'} value={correspondingFields} />
                            )}
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};
