import isString from 'lodash/isString';
import { FormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import React, { useContext, useRef } from 'react';
import { FormHelperText, FormHelperTextProps, Slide } from '@mui/material';
import styles from './ValidationMessage.module.css';

interface ISlideInHelperTextProps extends FormHelperTextProps {
    field: string;
}

export const ValidationMessage: React.FC<ISlideInHelperTextProps> = ({ field, children, ...props }) => {
    const containerRef = useRef(null);
    const formik = useContext(FormikContext);
    const formFieldMeta = formik?.getFieldMeta(field);
    const error = formFieldMeta?.error;
    const touched = formFieldMeta?.touched;
    const helperText = formFieldMeta?.error;

    return (
        <div ref={containerRef} className={styles.wrap}>
            {(touched || formik?.submitCount > 0) && error && (
                <Slide direction={'down'} in={true} container={containerRef.current}>
                    <FormHelperText data-testid={'ValidationMessage:FormHelperText'} error {...props}>
                        {isString(helperText) && <FormattedMessage id={helperText} defaultMessage={helperText} />}
                        {!isString(helperText) && children}
                    </FormHelperText>
                </Slide>
            )}
        </div>
    );
};
