import React from 'react';
import { useVerifyEmailMutation } from '../../../../../interfaces/model';
import Button from '@mui/material/Button';
import { useAppSnackbar } from '../../../../components/AppNotification';

interface IVerifyEmailButtonProps {
    roleId: UUID;
    roleEmail: string;
    updateData: () => void;
}

export const VerifyEmailButton: React.FC<IVerifyEmailButtonProps> = ({ roleId, roleEmail, updateData }) => {
    const snackbar = useAppSnackbar();
    const [verifyEmailRequest] = useVerifyEmailMutation();

    const sendVerifyEmailRequest = async () => {
        try {
            await verifyEmailRequest({ variables: { roleId } });
            snackbar.show({ message: `Verification e-mail has been sent to ${roleEmail}` });
            updateData();
        } catch (e) {
            snackbar.showError({ message: 'Failed to send verification e-mail. Please contact the administrator.' }, e);
        }
    };

    return (
        <Button variant={'outlined'} onClick={sendVerifyEmailRequest} size={'small'} color={'inherit'}>
            Verify Email
        </Button>
    );
};
