import React from 'react';
import { v4 as uuid } from 'uuid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import { FeedbackMessageDialog, useFeedbackMessageDialog } from 'lib/modals/FeedbackMessageDialog';
import { CreateFundWizard, useFundWizardDialog } from '../../../../../Funds/CreateFundWizard/CreateFundWizard';
import { EditFundKeyInformationDialog } from '../../../../../Funds/EditFundKeyInformationDialog';
import { ClientOnboardingFragment, useFundsListQuery } from 'interfaces/model';
import { FieldsetCollapsible } from 'lib/forms/FieldsetCollapsible';
import { CLIENT_ONBOARDING_ROUTES } from 'App/App.routes';
import { FundsSetupList } from './FundsSetupList';

interface IFundsSetupListFormProps {
    hasInvestmentManagers: boolean;
    disabled: boolean;
    updateData: () => Awaited<void>;
    topCo: Maybe<ClientOnboardingFragment> | undefined;
    noPreview?: boolean;
    onGoToLegalEntitiesClick?: () => void;
}

const FEEDBACK_DIALOG_ID = uuid();

export const FundsSetupListForm: React.FC<IFundsSetupListFormProps> = ({
    hasInvestmentManagers,
    updateData,
    disabled,
    topCo,
    noPreview,
    onGoToLegalEntitiesClick,
}) => {
    const topCoRef = topCo?.id;
    const history = useHistory();
    const [fundEditDialog] = useFundWizardDialog();
    const [feedbackDialog] = useFeedbackMessageDialog({ id: FEEDBACK_DIALOG_ID });

    const fundsQuery = useFundsListQuery({
        fetchPolicy: 'cache-and-network',
        skip: !topCoRef,
        variables: {
            filter: {
                topCoRef,
            },
        },
    });

    const hasFunds = (fundsQuery.data?.loanfunds.total || 0) > 0;
    const handleAdd = () => {
        if (hasInvestmentManagers) {
            fundEditDialog.open({ topCoRef });
        } else {
            feedbackDialog.open({
                severity: 'error',
                title: 'Missing investment manager',
                message: (
                    <Stack spacing={4} alignItems={'center'}>
                        <span>
                            Before starting fund onboarding you must create all the legal entities/investment managers
                            that are responsible for managing the funds you want to set up.
                        </span>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            onClick={() => {
                                feedbackDialog.close();
                                if (onGoToLegalEntitiesClick) {
                                    onGoToLegalEntitiesClick();
                                } else {
                                    history.push(CLIENT_ONBOARDING_ROUTES.LEGAL_ENTITIES);
                                }
                            }}
                        >
                            Set up legal entities
                        </Button>
                    </Stack>
                ),
            });
        }
    };
    const reloadData = async () => {
        await fundsQuery.refetch();
        await updateData();
    };

    return (
        <Stack spacing={4}>
            <FieldsetCollapsible
                title={'Funds'}
                description={'Please add the funds you would like to onboard and categorise the type of fund'}
                complete={hasFunds}
                defaultExpand
                required
            >
                <Stack spacing={1}>
                    <Box>
                        <Button
                            data-testid={'NewCompanyView:addNewFundButton'}
                            size={'large'}
                            variant={'contained'}
                            sx={{ minWidth: '120px' }}
                            onClick={handleAdd}
                            disabled={disabled || fundsQuery.loading}
                        >
                            Add fund
                        </Button>
                    </Box>
                    {hasFunds && <FundsSetupList query={fundsQuery} noPreview={noPreview} />}
                </Stack>
            </FieldsetCollapsible>
            <CreateFundWizard onSaveSuccess={reloadData} />
            <EditFundKeyInformationDialog onSaveSuccess={reloadData} />
            <FeedbackMessageDialog id={FEEDBACK_DIALOG_ID} />
        </Stack>
    );
};
