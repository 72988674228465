import React from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
import { DocumentActionsCell } from '../../components/DocumentsViewDialog/components/DocumentActionsCell';
import { DateValueCell, LinkCell, ValueCell } from 'lib/dataDisplay/List/components/ListCells';
import { DoubleValueCell } from 'lib/dataDisplay/List/components/ListCells/DoubleValueCell';
import { KYCStatus, kycStatusColors, kycStatusLabels } from './DocumentCategoryList.const';
import { DocumentInInput, KYCDocType, KYCDocumentFragment } from 'interfaces/model';
import { KYCLedgerCommReviewMark } from '../../components/KYCLedgerCommReviewMark';
import { DocumentsAddViewButton } from '../../components/DocumentsAddViewButton';
import { DocumentApprovalCount } from '../../components/DocumentApprovalCount';
import { IDocumentsListParent } from '../../interfaces/IDocumentsListParent';
import { ROLE_VIEW_ROUTE_FACTORY } from '../../../../App.routes';
import { NoValuePlaceholder } from 'lib/cdk/NoValuePlaceholder';
import { IsCompleteIcon } from 'lib/icons/IsCompleteIcon';
import { kycDocTypeLabels } from '../../../../App.const';
import { getYearFromDate } from '../../Documents.const';
import { IListRow } from 'lib/dataDisplay/List';
import { RowStack } from 'lib/cdk/RowStack';

export interface IDocumentCategoryListRowItem {
    id: string;
    docType: KYCDocType;
    userName: Maybe<string> | undefined;
    userShortCode: Maybe<string> | undefined;
    status?: KYCStatus;
    agentsApproved: number;
    agentsRejected: number;
    agentsPending: number;
    document: KYCDocumentFragment;
    kycComplete: boolean;
    isNotApplicable: boolean;
    createdAt: Timestamp;
    uploaded: boolean;
    addViewAction?: never;
    actions?: never;
}

type IKYCCategoryListRowProps = IListRow<
    IDocumentCategoryListRowItem,
    {
        parent: IDocumentsListParent;
        updateData: () => Promise<void>;
        kycMode: boolean;
        onEditSubmit?: (document: DocumentInInput) => Promise<void>;
    }
>;

const CounterCell: React.FC<{
    id: string;
    value: number;
    total: number;
    color: Colors;
    title: React.ReactNode;
    agents: { name: string; shortCode: Maybe<string> }[];
}> = ({ id, color, ...props }) => (
    <ValueCell color={color} testId={id} notApplicable>
        {props.value > 0 && <DocumentApprovalCount {...props} />}
    </ValueCell>
);

export const DocumentCategoryListRow: React.FC<IKYCCategoryListRowProps> = ({ data, columns, props }) => {
    const agentsTotal = data.agentsRejected + data.agentsApproved + data.agentsPending;
    const submittedByAgent = !!data.document?.status?.byAdmin;
    const agentsApproved = data.document.status?.approvedAgents || [];
    const agentsPending = data.document.status?.underReviewAgents || [];
    const agentsRejected = data.document.status?.rejectedAgents || [];

    return (
        <TableRow data-testid={'KYCCategoryListItem'}>
            {columns.map(({ id, label }) => (
                <React.Fragment key={id}>
                    {id === 'docType' && (
                        <DoubleValueCell
                            data-testid={id}
                            main={
                                <Box
                                    sx={{
                                        maxWidth: '220px',
                                        whiteSpace: 'break-spaces',
                                    }}
                                >
                                    {kycDocTypeLabels[data.docType]}
                                </Box>
                            }
                            alternative={data.isNotApplicable && <NoValuePlaceholder notApplicable />}
                        />
                    )}
                    {id === 'createdAt' && (
                        <DateValueCell value={data.uploaded ? data.createdAt : void 0} testId={id} notApplicable />
                    )}
                    {id === 'kycComplete' && (
                        <TableCell>
                            <IsCompleteIcon complete={data.kycComplete} />
                        </TableCell>
                    )}
                    {id === 'status' && (
                        <ValueCell testId={'status'} color={kycStatusColors[data.status!]} sx={{ width: '134px' }}>
                            <RowStack spacing={1}>
                                <span>{kycStatusLabels[data.status!]}</span>
                                {submittedByAgent && <KYCLedgerCommReviewMark />}
                            </RowStack>
                        </ValueCell>
                    )}
                    {id === 'agentsApproved' && (
                        <CounterCell
                            id={id}
                            title={<span>Agents {label}</span>}
                            value={data.agentsApproved}
                            color={'success'}
                            total={agentsTotal}
                            agents={agentsApproved}
                        />
                    )}
                    {id === 'agentsPending' && (
                        <CounterCell
                            id={id}
                            title={<span>Agents {label}</span>}
                            value={data.agentsPending}
                            color={'warning'}
                            total={agentsTotal}
                            agents={agentsPending}
                        />
                    )}
                    {id === 'agentsRejected' && (
                        <CounterCell
                            id={id}
                            title={<span>Agents {label}</span>}
                            value={data.agentsRejected}
                            color={'error'}
                            total={agentsTotal}
                            agents={agentsRejected}
                        />
                    )}
                    {id === 'userName' && (
                        <LinkCell to={ROLE_VIEW_ROUTE_FACTORY(data.userShortCode)} testId={id} notApplicable>
                            {data.userName}
                        </LinkCell>
                    )}
                    {id === 'addViewAction' && (
                        <TableCell data-testid={id} sx={{ width: '100px' }}>
                            <DocumentsAddViewButton
                                category={data.document.docCategory}
                                docType={data.docType}
                                className={'fullWidth'}
                                subcategory={data.document.docSubcategory || void 0}
                                year={getYearFromDate(data.document.validFromDate)}
                                upload={!data.document.uploaded}
                                kycMode={props.kycMode}
                                parent={props.parent}
                            />
                        </TableCell>
                    )}
                    {id === 'actions' && (
                        <DocumentActionsCell
                            parent={props.parent}
                            document={data.document}
                            onSaveSuccess={props.updateData}
                            onEditSubmit={props.onEditSubmit}
                        />
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
