import * as yup from 'yup';
import { VALIDATION_MESSAGES } from '../../../../../../lib/forms';
import { Country } from '../../../../../../interfaces/model';

export const businessProfileValidationSchema = yup.object({
    name: yup.string(),
    headquarters: yup.mixed<Country>().required(VALIDATION_MESSAGES.REQUIRED),
});

export type IBusinessProfile = yup.TypeOf<typeof businessProfileValidationSchema>;
