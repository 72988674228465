import React from 'react';
import { ListCellItemWrapper } from '../ListCellItemWrapper';
import { IValueCellProps, ValueCell } from './ValueCell';
import { RowStack } from '../../../../cdk/RowStack';

interface IActionsCellProps extends IValueCellProps {
    spacing?: number;
}

export const ActionsCell: React.FC<IActionsCellProps> = ({ children, align, spacing, ...props }) => {
    return (
        <ValueCell {...props}>
            <ListCellItemWrapper>
                <RowStack justifyContent={align} spacing={spacing}>
                    {children}
                </RowStack>
            </ListCellItemWrapper>
        </ValueCell>
    );
};
