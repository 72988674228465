import { facilityTypeShortLabels } from '../../Facilities/Facilities.const';
import { DATE_VIEW_FORMAT_SHORT, dateFromISOToFormat } from '../../../../lib/utils/date';
import { nonNull } from '../../../../lib/utils/helpers';
import { Facility } from '../../../../interfaces/model';
import { formatCurrency } from '../../../../lib/utils/formatting';
import { fromPercentage } from '../../../../lib/utils/bigInt';
import { TradeTitleFragment } from './TradeView.query';

interface IGetTradeTitleParams {
    amount?: Maybe<number>;
    borrowerName?: string;
    price?: Maybe<number>;
}

function prepareTradeTitle(
    { amount, borrowerName, price }: IGetTradeTitleParams,
    facility: Maybe<Pick<Facility, 'type' | 'maturityDate' | 'tranche' | 'currency'>> | undefined
) {
    const facilityTypeAbbr = (facility?.type && facilityTypeShortLabels[facility.type]) || '';
    const maturity = dateFromISOToFormat(nonNull(facility?.maturityDate), DATE_VIEW_FORMAT_SHORT);
    const priceStr = price ? `@ ${price}` : '';
    const tranche = facility?.tranche || '';
    const amountFormatted = formatCurrency(amount, facility?.currency) || '';
    return `${amountFormatted} ${borrowerName || ''} ${facilityTypeAbbr} ${tranche} ${maturity || ''} ${priceStr}`;
}

export const getTradeTitle = (trade: Maybe<TradeTitleFragment> | undefined): string =>
    prepareTradeTitle(
        {
            amount: trade?.totalAmount!,
            borrowerName: trade?.facility?.issuer?.topCo?.name || '',
            price: fromPercentage(trade?.price),
        },
        trade?.facility
    );
