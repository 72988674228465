import { batch, useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useAppSnackbar } from '../../AppNotification';
import { useRoleSwitchLazyQuery } from '../../../../interfaces/model';
import { userLogin, userLogout } from '../../../App.actions';
import { useAppSpinner } from '../../../hooks/useAppSpinner';

export const useControlledUserSwitch = () => {
    const dispatch = useDispatch();
    const client = useApolloClient();
    const snackbar = useAppSnackbar();
    const spinner = useAppSpinner();
    const [switchUser] = useRoleSwitchLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'standby',
    });

    const handleSwitch = async (role: { id: UUID; name: Maybe<string> }) => {
        if (!role) {
            return;
        }

        try {
            spinner.show();
            const result = await switchUser({
                variables: {
                    targetId: role.id,
                },
            });

            if (result.error) {
                throw result.error;
            }

            if (result.data) {
                await client.resetStore();
                batch(() => {
                    dispatch(userLogout());
                    dispatch(userLogin(result.data!.switch));
                });
                snackbar.show({ message: `Signed in as "${role.name}"` });
            }
            spinner.hide();
        } catch (e) {
            spinner.hide();
            snackbar.showError({ message: `Failed to switch as "${role.name}"` }, e);
        }
    };
    return [handleSwitch];
};
