import { ContentFileInInput, DocumentInInput, useDocumentMutation } from '../../interfaces/model';
import { fileToBase64 } from '../../lib/utils/helpers';

export const filesToContentFileInInput = async (files: File[]) => {
    return await Promise.all(
        files.map(async (file): Promise<ContentFileInInput> => {
            const data = await fileToBase64(file);
            return {
                fileName: file.name,
                contentType: file.type,
                data,
            };
        })
    );
};

export const useDocumentUpload = () => {
    const [saveDocument] = useDocumentMutation();
    return async (props: { parentRef: UUID | undefined; files: File[]; value: DocumentInInput }) => {
        const contentFiles = await filesToContentFileInInput(props.files);
        return await saveDocument({
            variables: {
                document: { ...props.value, parentRef: props.parentRef, contentFiles },
            },
        });
    };
};
