import React from 'react';
import { EmailNotificationType } from '../../../../interfaces/model';
import { AppEmbeddedListWrap } from '../../../components/AppEmbeddedListWrap';
import { Table, TableBody, TableCell } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { emailNotificationTypeLabels } from '../Roles.const';

interface IViewRoleNotificationsProps {
    value: EmailNotificationType[] | null | undefined;
}

export const ViewRoleNotifications: React.FC<IViewRoleNotificationsProps> = ({ value }) => {
    return (
        <AppEmbeddedListWrap title={'Selected email notifications'}>
            {(!value || value.length === 0) && 'No notifications selected'}
            {value && value.length > 0 && (
                <Table>
                    <TableBody>
                        {value.map((item) => {
                            return (
                                <React.Fragment key={item}>
                                    <TableRow className={'mb-16'}>
                                        <TableCell>{emailNotificationTypeLabels[item]}</TableCell>
                                    </TableRow>
                                    <TableRow className="spacer">
                                        <TableCell colSpan={6} sx={{ height: 4 }} />
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
        </AppEmbeddedListWrap>
    );
};
