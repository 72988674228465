import React from 'react';
import { Edit } from '@mui/icons-material';
import TableRow from '@mui/material/TableRow';
import { FundDeleteIconButton } from '../../../../../../Funds/FundsEmbeddedList/FundsEmbeddedListRow/FundDeleteIconButton';
import {
    ActionsCell,
    JurisdictionCell,
    ValueCell,
} from '../../../../../../../../lib/dataDisplay/List/components/ListCells';
import { IEditFundDialogState } from '../../../../../../Funds/CreateFundWizard/interfaces/IEditFundDialogState';
import { ListIconButton } from '../../../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { FundsListRowItemFragment } from '../../../../../../../../interfaces/model';
import { useParentContext } from '../../../../../../../../lib/cdk/ParentContext';
import { IListRow } from '../../../../../../../../lib/dataDisplay/List';
import { FUND_TYPE_LABELS } from '../../../../../../../App.const';
import { Link } from 'react-router-dom';
import { CLIENT_ONBOARDING_ROUTES, FUND_VIEW_ROUTE_FACTORY } from '../../../../../../../App.routes';

export interface IFundsSetupListRowItem extends FundsListRowItemFragment {
    actions?: never;
}

interface IProps {
    openFundEditDialog: (state: IEditFundDialogState) => void;
    noPreview?: boolean;
}

type IFundsSetupListRowProps = IListRow<IFundsSetupListRowItem, IProps>;

export const FundsSetupListRow: React.FC<IFundsSetupListRowProps> = ({ data, columns, props }) => {
    const parent = useParentContext();
    const previewRoute = props.noPreview
        ? FUND_VIEW_ROUTE_FACTORY(data.shortCode)
        : CLIENT_ONBOARDING_ROUTES.FUNDS_PREVIEW_ROUTE_FACTORY(data.shortCode!);
    return (
        <TableRow component={Link} to={previewRoute} hover>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'name' && <ValueCell>{data.name}</ValueCell>}
                    {id === 'jurisdiction' && <JurisdictionCell value={data.jurisdiction} />}
                    {id === 'type' && <ValueCell>{FUND_TYPE_LABELS[data.type!] || data.type}</ValueCell>}
                    {id === 'actions' && (
                        <ActionsCell
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            {data.hasEditAccess && (
                                <>
                                    <ListIconButton
                                        icon={Edit}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.openFundEditDialog({
                                                shortCode: data.id,
                                            });
                                        }}
                                    />
                                    <FundDeleteIconButton fund={data} onSuccess={parent.update} />
                                </>
                            )}
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
