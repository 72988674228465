import React, { useState } from 'react';
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeDialogState } from '../../store/dialogState/makeDialogState';
import { LoadingSpinnerButton } from '../buttons/LoadingSpinnerButton';
import { DialogCloseButton } from './DialogCloseButton';
import { NOOP, NOOP_ASYNC } from '../utils/helpers';
import { DialogSubmitActions } from './DialogSubmitActions';

export type IConfirmDialogAction = {
    id: string;
    label: string;
    onConfirm: () => Promise<unknown>;
} & ButtonProps;

export interface IConfirmDialogState {
    body: React.ReactNode;
    title: React.ReactNode;
    actions?: IConfirmDialogAction[];
    onCancel?: () => void;
    disabled?: boolean;
    submitButtonLabel?: string;
    onConfirm?: () => Promise<unknown>;
    confirmButtonColor?: Colors;
}

export const useConfirmDialog = makeDialogState<IConfirmDialogState>();

export const ConfirmDialog: React.FC = () => {
    const [{ close }, open, state] = useConfirmDialog();
    const [submittingActionId, setSubmittingActionId] = useState<string>();
    const { title, body, actions, onCancel = NOOP, disabled } = state || {};
    const { submitButtonLabel, confirmButtonColor, onConfirm } = state || {};

    const isSubmitting = !!submittingActionId;
    const isDisabled = isSubmitting || disabled;

    const handleClose = () => {
        if (isSubmitting) {
            return null;
        }
        close();
    };

    const handleConfirm =
        ({ id, onConfirm: onActionConfirm }: Pick<IConfirmDialogAction, 'id' | 'onConfirm'>) =>
        async () => {
            setSubmittingActionId(id);
            try {
                await onActionConfirm();
                setSubmittingActionId(void 0);
                close();
            } catch (e) {
                setSubmittingActionId(void 0);
                throw new Error(`Failed to confirm "${id}" action. ${(e as Error)?.message}`);
            }
        };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'} data-testid={'ConfirmationDialog'} fullWidth>
            <DialogTitle>
                {title}
                <DialogCloseButton onClick={handleClose} disabled={isSubmitting} />
            </DialogTitle>
            <DialogContent>{body}</DialogContent>
            {actions ? (
                <DialogActions>
                    <Button
                        color={'secondary'}
                        onClick={() => {
                            setSubmittingActionId(void 0);
                            handleClose();
                            onCancel();
                        }}
                        disabled={disabled}
                    >
                        Cancel
                    </Button>
                    {actions.map(({ id, label, onConfirm: onActionConfirm, ...props }) => (
                        <LoadingSpinnerButton
                            key={id}
                            {...props}
                            spin={submittingActionId === id}
                            onClick={handleConfirm({ id, onConfirm: onActionConfirm })}
                            disabled={isDisabled}
                        >
                            {label}
                        </LoadingSpinnerButton>
                    ))}
                </DialogActions>
            ) : (
                <DialogSubmitActions
                    submitButtonLabel={submitButtonLabel || 'Confirm'}
                    canSubmit={!!onConfirm}
                    onSubmit={handleConfirm({ id: 'main', onConfirm: onConfirm || NOOP_ASYNC })}
                    onClose={handleClose}
                    color={confirmButtonColor}
                    spin={!!submittingActionId}
                />
            )}
        </Dialog>
    );
};
