import React from 'react';
import { EntitiesListRowFragment, EntityType, useEntitiesListQuery } from 'interfaces/model';
import { AppEmbeddedListWrap } from '../../../../components/AppEmbeddedListWrap';
import { DivTableTheme } from 'lib/dataDisplay/List/themes/DivTableTheme';
import { EntitiesListRow } from '../../EntitiesList/EntitiesListRow';
import { GqlQueryList } from 'lib/dataDisplay/List/GqlQueryList';
import { IGqlListColumn } from 'lib/dataDisplay/List';

interface ISponsoredEntitiesListProps {
    sponsorRefs: UUID[];
    testId?: string;
}

const COLUMNS: IGqlListColumn<EntitiesListRowFragment>[] = [
    { id: 'name', label: 'Name' },
    { id: 'entityType', label: 'Type' },
    {
        id: 'kycComplete',
        label: 'Agent KYC',
        sort: false, // TODO add API support
        shrink: true,
        nowrap: true,
    },
];

const entityTypes: EntityType[] = [
    EntityType.BORROWER_SUBSIDIARY,
    EntityType.LENDER_INVESTMENT_BANK,
    EntityType.LENDER_INVESTMENT_MANAGER,
];

export const SponsoredEntitiesList: React.FC<ISponsoredEntitiesListProps> = ({ sponsorRefs, testId }) => {
    return (
        <AppEmbeddedListWrap title={'Sponsored entities'}>
            <DivTableTheme>
                <GqlQueryList
                    testId={testId}
                    columns={COLUMNS}
                    query={useEntitiesListQuery}
                    variables={{
                        filter: { entityTypes, sponsorRefs },
                    }}
                    getResult={(data) => data?.entities}
                    rowComponent={EntitiesListRow}
                    defaultSortBy="name"
                />
            </DivTableTheme>
        </AppEmbeddedListWrap>
    );
};
