import React, { useState } from 'react';
import { IGqlListRow } from '../../../../../lib/dataDisplay/List';
import {
    ActionsCell,
    BooleanCell,
    LinkCell,
    ValueCell,
} from '../../../../../lib/dataDisplay/List/components/ListCells';
import { AGENT_VIEW_ROUTE_FACTORY } from '../../../../App.routes';
import { ListRow } from '../../../../../lib/dataDisplay/List/components/ListRow';
import { CountryFlagAndName } from '../../../../../lib/dataDisplay/CountryFlagAndName';
import { TableCell } from '@mui/material';
import { AgentCapabilities, AgentFragment, EntityType } from '../../../../../interfaces/model';
import { CollapseTrigger } from '../../../../../lib/buttons/CollapseTrigger';
import { CollapsibleRow } from '../../../../../lib/legacy/CollapsibleRow';
import { AgentCapabilitiesList } from '../AgentCapabilitiesList/AgentCapabilitiesList';
import { AgentEntityDeleteButton } from './AgentEntityDeleteButton';
import { AgentManagementLabel } from '../../../../common/AgentManagementLabel';

export interface IAgentEntitiesListItem {
    id: UUID;
    entityType: EntityType;
    shortCode: string;
    name: string;
    headquarters: string;
    totalPermissions: number;
    manageStructure: boolean;
    uploadKyc: boolean;
    uploadDataroom: boolean;
    capabilities: AgentCapabilities[];
    deleteable: boolean;
    assignedAgents: AgentFragment[];
    isAgentExt: boolean;
}

interface IComponentProps {
    agentName: string;
    agentRef: UUID;
}

type IAgentEntitiesListRowProps = IGqlListRow<IAgentEntitiesListItem, IComponentProps>;

export const AgentEntitiesListRow: React.FC<IAgentEntitiesListRowProps> = ({ item, columns, props }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <ListRow focused={isExpanded}>
                {columns.map(({ id }) => (
                    <React.Fragment key={id}>
                        {id === 'name' && (
                            <LinkCell
                                to={AGENT_VIEW_ROUTE_FACTORY(item.shortCode)}
                                suffix={item.isAgentExt ? <AgentManagementLabel compact /> : void 0}
                            >
                                {item.name}
                            </LinkCell>
                        )}
                        {id === 'headquarters' && (
                            <TableCell>
                                <CountryFlagAndName country={item.headquarters} showFullName />
                            </TableCell>
                        )}
                        {id === 'totalPermissions' && (
                            <ValueCell>
                                {item.totalPermissions} of {Object.keys(AgentCapabilities).length}
                            </ValueCell>
                        )}
                        {id === 'manageStructure' && <BooleanCell value={item.manageStructure} />}
                        {id === 'uploadKyc' && <BooleanCell value={item.uploadKyc} />}
                        {id === 'uploadDataroom' && <BooleanCell value={item.uploadDataroom} />}
                        {id === 'actions' && (
                            <ActionsCell align={'right'}>
                                {item.deleteable && (
                                    <AgentEntityDeleteButton
                                        agentRef={props.agentRef}
                                        assignedAgents={item.assignedAgents}
                                        agentName={props.agentName}
                                        entityName={item.name}
                                        entityType={item.entityType}
                                        entityRef={item.id}
                                    />
                                )}
                                <CollapseTrigger isActive={isExpanded} onClick={() => setIsExpanded(!isExpanded)} />
                            </ActionsCell>
                        )}
                    </React.Fragment>
                ))}
            </ListRow>
            <CollapsibleRow expand={isExpanded} colspan={columns.length}>
                <AgentCapabilitiesList capabilities={item.capabilities} />
            </CollapsibleRow>
        </>
    );
};
