import { FormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ITextInputProps, TextInput } from '../TextInput';
import styles from './PasswordInput.module.css';

const VALUE_PLACEHOLDER = '************';
const DEFAULT_PLACEHOLDER_TEXT = 'Enter password';

export const PasswordInput: React.FC<ITextInputProps & { showPlaceholder?: boolean }> = ({
    showPlaceholder,
    placeholder = DEFAULT_PLACEHOLDER_TEXT,
    ...props
}) => {
    const formik = useContext(FormikContext);
    const [focused, setFocused] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const valueExists = Boolean(props.value ?? formik?.values[props.name!]);
    const showValuePlaceholder = !valueExists && showPlaceholder && !focused && !passwordVisible;

    const handleOnBlur = () => {
        setFocused(false);
        if (props.name) {
            formik.setTouched({ ...formik.touched, [props.name]: true }, true);
        }
    };

    return (
        <TextInput
            {...props}
            type={passwordVisible ? 'text' : 'password'}
            value={showValuePlaceholder ? VALUE_PLACEHOLDER : props.value}
            placeholder={placeholder}
            onFocus={() => setFocused(true)}
            onBlur={handleOnBlur}
            InputProps={{
                ...props.InputProps,
                className: styles.fixWebkitAutocompleteEndAdornment,
                endAdornment: (
                    <InputAdornment position="end" className={styles.endAdornment}>
                        {valueExists && (
                            <IconButton
                                edge="end"
                                size={'small'}
                                title={`${passwordVisible ? 'Hide' : 'Show'} password`}
                                onClick={() => setPasswordVisible(!passwordVisible)}
                            >
                                {!passwordVisible && <VisibilityOff color={'secondary'} fontSize={'small'} />}
                                {passwordVisible && <Visibility color={'secondary'} fontSize={'small'} />}
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
};
