import React from 'react';
import { IDocSubcategory, IDocumentsTree, Year } from '../../getGroupedDocumentsTree';
import { KYCDocCategory, KYCDocType, KYCDocumentFragment } from '../../../../../../../../interfaces/model';
import { kycDocCategoryLabels } from '../../../../../../../App.const';
import { getEntries } from '../DocumentsGroupsTree.helpers';
import { TreeFolder } from './Tree/TreeFolder';

export const Categories: React.FC<{
    value: IDocumentsTree;
    children: (
        category: KYCDocCategory,
        categoryItems: Record<KYCDocType, Record<IDocSubcategory, Record<Year, KYCDocumentFragment[]>>>,
        offset: number
    ) => React.ReactNode;
}> = ({ value, children }) => {
    const entries = getEntries(value);
    if (entries.length === 1) {
        const [category, categoryItems] = entries[0];
        return <>{children(category, categoryItems, 0)}</>;
    }

    return (
        <>
            {getEntries(value).map(([category, categoryItems]) => (
                <TreeFolder key={category} id={category} label={kycDocCategoryLabels[category]} offset={0}>
                    {children(category, categoryItems, 1)}
                </TreeFolder>
            ))}
        </>
    );
};
