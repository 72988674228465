import Grid from '@mui/material/Grid';
import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import { Box, Collapse, Stack, Switch } from '@mui/material';
import { ACCOUNT_FIELDS, getCurrencyOptions, getPurposeOptions } from './BankAccountEditForm.const';
import { FACILITY_CURRENCIES } from '../../../pages/Facilities/Facilities.const';
import { FormTextInput } from 'lib/forms/components/FormTextInput';
import { bankAccountPurposeLabels, DEFAULT_PURPOSES } from '../BankAccountEditDialog.const';
import { FormDropDown } from 'lib/forms/components/FormDropDown';
import { BankAccountPurpose, Currency } from 'interfaces/model';
import { AccountFields } from './AccountFields';
import { Fieldset } from 'lib/forms/Fieldset';

interface IBankAccountEditFormProps {
    availablePurposes?: BankAccountPurpose[];
    availableCurrencies?: Currency[];
    isExistingCombination: (purpose: BankAccountPurpose | undefined, currency: Currency | undefined) => boolean;
}

const PurposeTooltipText: React.FC = () => (
    <span>
        Users can select both single and multi-purpose bank accounts.
        <br />
        <br />
        If more than one account is present, the platform will prioritise single purpose bank accounts (e.g. fee only)
        then look for multipurpose accounts (principal, fee and interest) when generating invoices.
    </span>
);

export const BankAccountEditForm: React.FC<IBankAccountEditFormProps> = ({
    availablePurposes = DEFAULT_PURPOSES,
    availableCurrencies = FACILITY_CURRENCIES,
    isExistingCombination,
}) => {
    const formik = useContext(FormikContext);
    const currency = formik.values.currency as string | undefined;
    const purpose = formik.values.purpose as BankAccountPurpose | undefined;
    const getFields = (type: keyof typeof ACCOUNT_FIELDS) =>
        ACCOUNT_FIELDS[type][currency!] || ACCOUNT_FIELDS[type].OTHER;

    const correspondingBankEnabled = formik.values.corresponding;
    const intermediaryBankEnabled = formik.values.intermediary;

    const getDisabledOptionTooltipText = (combinationPurpose: BankAccountPurpose, combinationCurrency: Currency) =>
        `"${bankAccountPurposeLabels[combinationPurpose!]} ${combinationCurrency}" bank account already exists`;

    return (
        <Stack spacing={3}>
            <Grid spacing={2} container>
                <FormTextInput name={'accountName'} label={'Account name'} required />
                <FormDropDown
                    name={'purpose'}
                    label={'Account Purpose'}
                    tooltip={<PurposeTooltipText />}
                    options={getPurposeOptions(availablePurposes)}
                    getOptionDisabled={(option) => isExistingCombination(option.value, currency as Currency)}
                    getDisabledOptionTooltipText={
                        currency ? (option) => getDisabledOptionTooltipText(option.value, currency as Currency) : void 0
                    }
                    required
                    xs={6}
                />
                <FormDropDown
                    name={'currency'}
                    label={'Currency'}
                    options={getCurrencyOptions(availableCurrencies)}
                    getOptionDisabled={(option) => isExistingCombination(purpose, option.value)}
                    getDisabledOptionTooltipText={
                        purpose ? (option) => getDisabledOptionTooltipText(purpose, option.value) : void 0
                    }
                    required
                    xs={6}
                />
            </Grid>
            <Fieldset title={'Beneficiary bank details'} marginBottom={0}>
                <Grid spacing={2} container>
                    <AccountFields name={'accountFields'} fields={getFields('BENEFICIARY')} />
                </Grid>
            </Fieldset>
            <Fieldset
                title={
                    <Box justifyContent={'space-between'} display={'flex'}>
                        <span>Intermediary bank details</span>
                        <Switch
                            size={'small'}
                            checked={intermediaryBankEnabled}
                            onClick={() =>
                                formik.setValues({
                                    ...formik.values,
                                    intermediary: !intermediaryBankEnabled,
                                    intermediaryFields: intermediaryBankEnabled ? {} : formik.values.intermediaryFields,
                                })
                            }
                        />
                    </Box>
                }
                marginBottom={0}
            >
                <Collapse in={intermediaryBankEnabled}>
                    <Grid spacing={2} container>
                        <AccountFields name={'intermediaryFields'} fields={getFields('INTERMEDIARY')} />
                    </Grid>
                </Collapse>
            </Fieldset>
            <Fieldset
                title={
                    <Box justifyContent={'space-between'} display={'flex'}>
                        <span>Corresponding bank details</span>
                        <Switch
                            size={'small'}
                            checked={correspondingBankEnabled}
                            onClick={() =>
                                formik.setValues({
                                    ...formik.values,
                                    corresponding: !correspondingBankEnabled,
                                    correspondingFields: correspondingBankEnabled
                                        ? {}
                                        : formik.values.correspondingFields,
                                })
                            }
                        />
                    </Box>
                }
                marginBottom={0}
            >
                <Collapse in={correspondingBankEnabled}>
                    <Grid spacing={2} container>
                        <AccountFields name={'correspondingFields'} fields={getFields('CORRESONDING')} />
                    </Grid>
                </Collapse>
            </Fieldset>
        </Stack>
    );
};
