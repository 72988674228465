import cl from 'classnames';
import React from 'react';
import { Avatar, Badge } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import styles from '../UserProfileMenuItem/UserProfileMenuItem.module.css';
import { getAvatarColors } from './getAvatarColors';

interface IAvatarIconProps {
    name: string;
    size?: 'small';
    isAdmin: boolean;
}

const getAvatarLettersFromName = (name: string | undefined = '') => {
    const [firstName = '', lastName = ''] = name.trim().split(' ');
    return `${firstName[0]}${lastName[0] || ''}`.toUpperCase();
};

export const AvatarIcon: React.FC<IAvatarIconProps> = ({ size, name, isAdmin }) => {
    const [bgcolor, textColor] = getAvatarColors(name);

    return (
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<AdminPanelSettingsIcon color={'primary'} fontSize={'small'} />}
            invisible={!isAdmin}
        >
            <Avatar
                className={cl(styles.avatar, size === 'small' && styles.sizeSmall)}
                sx={{
                    bgcolor,
                    color: textColor,
                    border: '1px solid color-mix(in oklab, currentColor 10%, transparent)',
                }}
            >
                {getAvatarLettersFromName(name || '')}
            </Avatar>
        </Badge>
    );
};
