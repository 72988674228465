import cl from 'classnames';
import { Box, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import React, { MutableRefObject } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './AppMenuExtraItemsGroup.module.css';
import { IMenuGroup } from '../MenuGroup/MenuGroup';
import { HoverMenu } from '../HoverMenu';

interface IAppMenuExtraItemsGroupProps {
    containerRef: MutableRefObject<HTMLElement | undefined>;
    itemsVisibility: boolean[];
    items: IMenuGroup[];
    className?: string;
}

export const AppMenuExtraItemsGroup: React.FC<IAppMenuExtraItemsGroupProps> = ({
    containerRef,
    itemsVisibility,
    className,
    items,
}) => {
    const hiddenItems = items.filter((item, index) => !itemsVisibility[index]);
    const containerHeight = containerRef.current?.getBoundingClientRect().height;
    const lastVisibleItemIndex = itemsVisibility.findIndex((visible) => !visible) - 1;
    const lastVisibleElementRects = containerRef.current?.children[lastVisibleItemIndex]?.getBoundingClientRect();
    const leftOffset = (lastVisibleElementRects?.left || 0) + (lastVisibleElementRects?.width || 0) + 8;
    const isActive = hiddenItems.some(({ active, subItems }) => active || subItems.some((item) => item.active));

    const triggerElement = (
        <Box
            className={cl(styles.moreOptionsButton, isActive && styles.active)}
            sx={{ height: containerHeight, left: leftOffset }}
        >
            <IconButton size={'medium'} color={'inherit'}>
                <MoreVertIcon />
            </IconButton>
        </Box>
    );

    const renderMenuItem = ({ url, label, active }: IMenuGroup) => {
        return (
            <Link className={active ? 'active' : 'inactive'} key={url} to={url}>
                <MenuItem key={url}>
                    <span>{label}</span>
                </MenuItem>
            </Link>
        );
    };

    return (
        <HoverMenu triggerElement={triggerElement} className={className}>
            {hiddenItems?.map((item) => {
                const { subItems } = item;

                if (subItems.length > 0) {
                    return [
                        <Divider textAlign="left">{item.label}</Divider>,
                        ...subItems.map((subItem) => renderMenuItem(subItem)),
                        <Divider />,
                    ];
                }

                return renderMenuItem(item);
            })}
        </HoverMenu>
    );
};
