import React, { useContext } from 'react';
import cl from 'classnames';
import { Close } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import styles from './DialogCloseButton.module.css';
import { FormikContext } from 'formik';

export const DialogCloseButton: React.FC<IconButtonProps> = ({ className, sx = {}, disabled, ...props }) => {
    const formik = useContext(FormikContext);

    return (
        <IconButton className={cl(styles.button, className)} disabled={formik?.isSubmitting || disabled} {...props}>
            <Close />
        </IconButton>
    );
};
