import React from 'react';
import { Delete } from '@mui/icons-material';
import { Role, useSetRoleMutation } from '../../../../../../../interfaces/model';
import {
    IListIconButtonProps,
    ListIconButton,
} from '../../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { useConfirmDialog } from '../../../../../../../lib/modals/ConfirmDialog';
import { useAppSnackbar } from '../../../../../../components/AppNotification';

interface IRoleDeleteIconButtonProps extends Omit<IListIconButtonProps, 'role' | 'icon'> {
    role: Pick<Role, 'id' | 'name'>;
    onSuccess: () => void;
}

export const RoleDeleteIconButton: React.FC<IRoleDeleteIconButtonProps> = ({ role, onSuccess, ...props }) => {
    const snackBar = useAppSnackbar();
    const [mutateRole] = useSetRoleMutation();
    const [confirmDialog] = useConfirmDialog();

    const onConfirm = async () => {
        try {
            await mutateRole({
                variables: {
                    role: {
                        id: role.id,
                        deleted: true,
                    },
                },
            });
            onSuccess();
            snackBar.show({ message: 'Role has been deleted' });
        } catch (e) {
            snackBar.showError({ message: 'Failed to delete the role' }, e);
        }
    };

    const openDialog = (e: React.MouseEvent) => {
        e.preventDefault();
        confirmDialog.open({
            body: 'You are about to delete a role. Are you sure?',
            title: `Delete "${role.name}" role`,
            onConfirm,
        });
    };

    return <ListIconButton icon={Delete} onClick={openDialog} {...props} />;
};
