import { useAppSnackbar } from '../../App/components/AppNotification';

export const useClipboard = () => {
    const snackBar = useAppSnackbar();

    return (text: string, params?: { onSuccess?: () => void; onError?: () => void }) => {
        const { onError, onSuccess } = params || {};
        navigator.clipboard
            .writeText(text)
            .then(
                onSuccess ||
                    (() =>
                        snackBar.show({
                            message: 'The value has been copied to clipboard',
                        }))
            )
            .catch(
                onError ||
                    (() =>
                        snackBar.show({
                            message: 'Failed to copy value to clipboard',
                            severity: 'error',
                        }))
            );
    };
};
