import React from 'react';
import { MaskedStringInput } from './MaskedStringInput';

interface INifTaxNumberInputProps {
    id: string;
    disabled?: boolean;
    value: string;
    onChange: (value: string) => void;
}

export const NIF_REGEX = /^[a-zA-Z][0-9]{7}[0-9]?[a-zA-Z]$/;
export const nifTaxNumberValidator = (value: string) => value?.toUpperCase().match(NIF_REGEX) || !value;

export const NifTaxNumberInput: React.FC<INifTaxNumberInputProps> = ({ id, disabled, value, onChange }) => {
    const isValid = nifTaxNumberValidator(value);

    return (
        <MaskedStringInput
            id={id}
            inputProps={{
                mask: 'a0000000[0]a',
            }}
            onChange={(newValue) => onChange(newValue?.replace(/[^a-zA-Z0-9]/g, '').toUpperCase())}
            placeholder={'A00000000A'}
            value={value}
            error={!isValid}
            disabled={disabled}
            fullWidth
        />
    );
};
