import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { CounterpartyDocsButtonListCell } from '../../../components/CounterpartyDocsButtonListCell';
import { ActionsCell, LinkCell } from '../../../../../../lib/dataDisplay/List/components/ListCells';
import { CounterpartyKYCListActionButton } from '../CounterpartyKYCListActionButton';
import { CounterpartyStatusCell, ICounterpartyStatusCellDetails } from '../../../components/CounterpartyStatusCell';
import { CounterpartyStatus, CounterpartyTargetFragment } from '../../../../../../interfaces/model';
import { getEntityOrFundRoute } from '../../../getEntityOrFundRoute';
import { IListRow } from '../../../../../../lib/dataDisplay/List';
import { OppositeCounterpartiesKYCLink } from '../OppositeCounterpartiesKYCLink';
import { NonFalsy } from '../../../../../../lib/utils/helpers';

export interface ICounterpartyChildrenListRowItem {
    id: string;
    name: string;
    topCoRef: UUID;
    shortCode: Maybe<string>;
    status: Maybe<CounterpartyStatus>;
    counterpartyKYCStatus: Maybe<{ targetRef: UUID; status: Maybe<CounterpartyStatus> }[]>;
    statusDetails?: ICounterpartyStatusCellDetails;
    isFund?: boolean;
    docs?: never;
    switch?: never;
    actions?: never;
}

type ICounterpartyChildrenListRowProps = IListRow<
    ICounterpartyChildrenListRowItem,
    { targets: CounterpartyTargetFragment[]; topCoRef: UUID | null }
>;

export const CounterpartyKYCChildrenListRow: React.FC<ICounterpartyChildrenListRowProps> = ({
    data,
    columns,
    highlightText,
    props,
}) => {
    return (
        <TableRow data-testid={'CounterpartiesChildrenListRow'}>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'name' && (
                        <LinkCell to={getEntityOrFundRoute(data)} highlight={highlightText}>
                            {data.name}
                        </LinkCell>
                    )}
                    {id === 'status' && <CounterpartyStatusCell value={data.status} details={data.statusDetails} />}
                    {id === 'docs' && <CounterpartyDocsButtonListCell parentRef={data.id} />}
                    {id === 'switch' && (
                        <TableCell>
                            <OppositeCounterpartiesKYCLink value={data} targets={props.targets} />
                        </TableCell>
                    )}
                    {id === 'actions' && (
                        <ActionsCell>
                            {props.topCoRef && (
                                <CounterpartyKYCListActionButton
                                    status={data.status}
                                    entities={[data]}
                                    targets={props.targets.map((target) => target.id).filter(NonFalsy)}
                                    topCoRef={props.topCoRef}
                                />
                            )}
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
