import React from 'react';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { Fieldset } from 'lib/forms/Fieldset';
import { IEntityInitialForm } from '../../../Entities/CreateEntityWizardDialog/useEntityEditFormik/useEntityEditFormik.const';
import { SingleDocumentList } from '../../../../components/Documents/components/SingleDocumentList';
import { mapDocumentToFragment } from '../../../../components/Documents/Documents.const';
import { KYCCategoriesTypesFragment, KYCDocumentFragment } from 'interfaces/model';
import { EmptyStatePlaceholder } from '../../../../common/EmptyStatePlaceholder';
import { ValidationMessage } from 'lib/forms/ValidationMessage';
import { kycDocTypeLabels } from '../../../../App.const';

interface IFundDocumentsStep {
    topCoRef: UUID | undefined;
    requirements: KYCCategoriesTypesFragment[];
}

export const FundDocumentsStep: React.FC<IFundDocumentsStep> = ({ topCoRef, requirements }) => {
    const formik = useFormikContext<IEntityInitialForm>();
    const fundDocTypes = requirements.map(({ type }) => type);
    const fieldsets = fundDocTypes.map((type) => ({
        title: kycDocTypeLabels[type],
        docType: type,
    }));

    return (
        <Fieldset
            title={'Supporting Documents'}
            description={'Add additional documents that will help us verify your loan fund'}
            spacing={2}
        >
            <div>
                {fieldsets.length === 0 && (
                    <Box marginTop={'16px'}>
                        <EmptyStatePlaceholder
                            elevation={0}
                            title={'This loan fund has no KYC requirements'}
                            imageName={'bulk_upload'}
                        />
                    </Box>
                )}
                {fieldsets.length > 0 &&
                    fieldsets.map(({ title, docType }) => (
                        <Fieldset key={docType} title={title}>
                            <div>
                                <SingleDocumentList
                                    parent={{
                                        topCoRef,
                                        id: formik.values.id,
                                        name: formik.values.name,
                                        shortCode: formik.values.shortCode || void 0,
                                        docTypes: fundDocTypes,
                                    }}
                                    docType={docType}
                                    value={
                                        mapDocumentToFragment(formik.values.documents[docType]) as KYCDocumentFragment
                                    }
                                    onSubmit={async (document) => {
                                        const deleted = document.deleted;

                                        formik.setFieldValue('documents', {
                                            ...formik.values.documents,
                                            [docType]: deleted ? void 0 : document,
                                        });
                                    }}
                                    listEmbedded
                                />
                                <ValidationMessage field={`documents.${docType}`} />
                            </div>
                        </Fieldset>
                    ))}
            </div>
        </Fieldset>
    );
};
