import React, { useContext, useMemo } from 'react';

export interface IParentContext {
    update: () => Promise<void>;
}

export const ParentContext = React.createContext<IParentContext>({ update: () => Promise.resolve() });

export const useParentContext = () => useContext<IParentContext>(ParentContext);

interface IParentContextProvider {
    /**
     * Must be memoized, otherwise children will re-render on every render
     */
    onUpdate: () => Promise<unknown>;
    children?: React.ReactNode;
}

export const ParentContextProvider: React.FC<IParentContextProvider> = ({ onUpdate, children }) => {
    const parentProviderValue = useMemo(
        (): IParentContext => ({
            update: async () => {
                await onUpdate();
            },
        }),
        [onUpdate]
    );
    return <ParentContext.Provider value={parentProviderValue}>{children}</ParentContext.Provider>;
};
