import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
    getEntityFilesFromRequirements,
    getEntityInitialValues,
    IEntityEdit,
    IEntityInitialForm,
    prepareEntityModelValue,
    validationSchemaFactory,
} from './useEntityEditFormik.const';
import {
    DocumentInInput,
    DocumentStorage,
    EntityType,
    KYCDocType,
    useRolesRelationsLazyQuery,
    useSetDocumentsMutation,
    useSetEntityMutation,
    useSetRoleMutation,
} from 'interfaces/model';
import { useParentContext } from 'lib/cdk/ParentContext';
import { useAppSnackbar } from '../../../../components/AppNotification';
import { proxyAPIValidationError, handleGqlFormMutationError, scrollToFirstInvalidControl } from 'lib/forms';
import { useTaxIdsDocumentsUpload } from '../../../../common/TaxIds/useTaxIdsDocumentsUpload';

interface IEntityEditFormikProps {
    entity?: IEntityEdit;
    parentRef?: string;
    topCoRef?: string;
    entityType?: EntityType;
    parentEntityType?: EntityType;
    onSaveSuccess: () => void;
    enableReinitialize?: boolean;
    validateOnMount?: boolean;
    kycRequirements?: { type: KYCDocType }[];
}

export const useEntityEditFormik = (props: IEntityEditFormikProps) => {
    const parent = useParentContext();
    const snackbar = useAppSnackbar();
    const [loadDirectors] = useRolesRelationsLazyQuery();
    const uploadTaxIdsDocuments = useTaxIdsDocumentsUpload();

    const [updateRole, updateRoleQuery] = useSetRoleMutation();
    const [mutateEntity, mutateEntityQuery] = useSetEntityMutation();
    const [saveDocuments, saveDocumentsQuery] = useSetDocumentsMutation();
    const { kycRequirements } = props;

    const submitDocuments = async (entityId: UUID, values: IEntityInitialForm) => {
        const documents = await Promise.all(
            Object.entries(values.documents).map(async ([docType, document]): Promise<DocumentInInput> => {
                return {
                    ...document,
                    parentRef: entityId,
                    docType: docType as KYCDocType,
                    storage: DocumentStorage.KYC,
                };
            })
        );

        await saveDocuments({ variables: { documents } });
    };

    const submitTaxIdFiles = async (entityId: UUID, values: IEntityInitialForm) => {
        const taxIds = values.taxIds || [];
        const taxIdsWithKycId = await uploadTaxIdsDocuments(taxIds, entityId);
        await mutateEntity({
            variables: {
                entity: {
                    id: entityId,
                    taxIds: taxIdsWithKycId,
                },
            },
        });
    };

    const submitDirectors = async (entityRef: UUID, values: IEntityInitialForm) => {
        const directorsRefs = values.directors;
        const { data, error } = await loadDirectors({ variables: { rolesRef: directorsRefs } });
        if (error) {
            throw error;
        }

        const directors = data?.roles.data || [];
        await Promise.all(
            directors.map(({ id, entityRelations }) => {
                const existingRelation = entityRelations?.find((relation) => relation.entityId === entityRef);
                const restRelations = entityRelations?.filter((relation) => relation.entityId !== entityRef) || [];
                return updateRole({
                    variables: {
                        role: {
                            id,
                            entityRelations: [
                                ...restRelations,
                                {
                                    ubo: false,
                                    psc: false,
                                    entityId: entityRef,
                                    ...existingRelation,
                                    enabled: true,
                                    director: true,
                                },
                            ],
                        },
                    },
                });
            })
        );
    };

    const submitEntity = async (values: IEntityInitialForm) => {
        const entity = prepareEntityModelValue(values);
        const { data, errors } = await mutateEntity({ variables: { entity } });
        if (errors) {
            throw errors[0];
        }
        return data?.entity;
    };

    const initialValues = getEntityInitialValues({
        entity: props.entity,
        entityType: props.entityType,
        parentRef: props.parentRef,
        topCoRef: props.topCoRef,
    });

    const [prevValues, setPrevValues] = useState<Partial<typeof initialValues>>({});
    const error = updateRoleQuery.error || mutateEntityQuery.error || saveDocumentsQuery.error;
    const formik = useFormik({
        initialValues,
        validateOnMount: props.validateOnMount,
        validationSchema: validationSchemaFactory(),
        enableReinitialize: props.enableReinitialize !== false,
        validate: proxyAPIValidationError(prevValues, error),
        onSubmit: async (values, helpers) => {
            setPrevValues(values);
            try {
                const entity = await submitEntity(values);
                if (entity?.id) {
                    await submitDirectors(entity.id, values);
                    await submitDocuments(entity.id, values);
                    await submitTaxIdFiles(entity.id, values);
                    await parent.update();
                    props.onSaveSuccess();
                    helpers.resetForm();
                    snackbar.show({ message: 'Entity has been saved' });
                }
            } catch (e) {
                scrollToFirstInvalidControl();
                helpers.setFieldValue('currentStep', 0);
                if (!handleGqlFormMutationError(e, values, helpers)) {
                    snackbar.showError({ message: 'Failed to save the entity' }, e);
                }
            }
        },
    });

    const setFieldValue = formik.setFieldValue;
    useEffect(() => {
        // workaround to fix formik entered values reset on entity type change
        if (kycRequirements) {
            setFieldValue('files', getEntityFilesFromRequirements(props.entity, kycRequirements));
        }
    }, [setFieldValue, kycRequirements, props.entity]);

    return formik;
};
