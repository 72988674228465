import React from 'react';
import { EntityType, OnboardingStep, useLegalEntitiesSetupListQuery } from '../../../../interfaces/model';
import { clientOnboardingProgressLabels } from './ClientOnboardingSummary/ClientOnboardingProgressItem';
import { IClientOnboardingStepComponent } from './interfaces/IClientOnboardingStepComponent';
import { StructureAndOwnershipSetup } from './steps/StructureAndOwnershipSetup';
import { BusinessProfileSetup } from './steps/BusinessProfileSetup';
import { LegalEntitiesSetup } from './steps/LegalEntitiesSetup';
import { CLIENT_ONBOARDING_ROUTES } from '../../../App.routes';
import { FundsSetup } from './steps/FundsSetup/FundsSetup';
import { IClientOnboardingStep } from './interfaces';
import { PeopleSetup } from './steps/PeopleSetup';
import { LegalEntityPreview } from './steps/LegalEntitiesSetup/LegalEntityPreview';
import { PeopleSetupPreview } from './steps/PeopleSetup/PeopleSetupPreview';
import { FundsSetupPreview } from './steps/FundsSetup/FundsSetupPreview';

export enum ClientOnboardingStep {
    STRUCTURE_AND_OWNERSHIP = 'STRUCTURE_AND_OWNERSHIP',
    BUSINESS_PROFILE = 'BUSINESS_PROFILE',
    LEGAL_ENTITIES = 'LEGAL_ENTITIES',
    FUNDS = 'FUNDS',
    PEOPLE = 'PEOPLE',
}

export const CLIENT_ONBOARDING_STEPS_ROUTE_COMPONENT_MAP: Record<
    ClientOnboardingStep,
    {
        title: string;
        route: string;
        previewRoute?: string;
        Component: React.FC<IClientOnboardingStepComponent>;
        PreviewComponent?: React.FC;
    }
> = {
    BUSINESS_PROFILE: {
        title: clientOnboardingProgressLabels.BUSINESS_PROFILE,
        route: CLIENT_ONBOARDING_ROUTES.BUSINESS_PROFILE,
        Component: BusinessProfileSetup,
    },
    STRUCTURE_AND_OWNERSHIP: {
        title: clientOnboardingProgressLabels.STRUCTURE_AND_OWNERSHIP,
        route: CLIENT_ONBOARDING_ROUTES.STRUCTURE_AND_OWNERSHIP,
        Component: StructureAndOwnershipSetup,
    },
    LEGAL_ENTITIES: {
        title: clientOnboardingProgressLabels.LEGAL_ENTITIES,
        route: CLIENT_ONBOARDING_ROUTES.LEGAL_ENTITIES,
        previewRoute: CLIENT_ONBOARDING_ROUTES.LEGAL_ENTITIES_PREVIEW_ROUTE_FACTORY(),
        Component: LegalEntitiesSetup,
        PreviewComponent: LegalEntityPreview,
    },
    FUNDS: {
        title: clientOnboardingProgressLabels.FUNDS,
        route: CLIENT_ONBOARDING_ROUTES.FUNDS,
        previewRoute: CLIENT_ONBOARDING_ROUTES.FUNDS_PREVIEW_ROUTE_FACTORY(),
        Component: FundsSetup,
        PreviewComponent: FundsSetupPreview,
    },
    PEOPLE: {
        title: clientOnboardingProgressLabels.PEOPLE,
        route: CLIENT_ONBOARDING_ROUTES.PEOPLE,
        previewRoute: CLIENT_ONBOARDING_ROUTES.PEOPLE_PREVIEW_ROUTE_FACTORY(),
        Component: PeopleSetup,
        PreviewComponent: PeopleSetupPreview,
    },
};

export const CLIENT_ONBOARDING_STEPS_MAP: Partial<Record<EntityType, IClientOnboardingStep[]>> = {
    LENDER_TOP_LEVEL: [
        { step: ClientOnboardingStep.BUSINESS_PROFILE },
        { step: ClientOnboardingStep.STRUCTURE_AND_OWNERSHIP },
        { step: ClientOnboardingStep.LEGAL_ENTITIES },
        { step: ClientOnboardingStep.PEOPLE },
        { step: ClientOnboardingStep.FUNDS },
    ],
    BORROWER_TOP_LEVEL: [
        { step: ClientOnboardingStep.BUSINESS_PROFILE },
        { step: ClientOnboardingStep.STRUCTURE_AND_OWNERSHIP },
        { step: ClientOnboardingStep.LEGAL_ENTITIES },
        { step: ClientOnboardingStep.PEOPLE },
    ],
    AGENT_TOP_LEVEL: [
        { step: ClientOnboardingStep.BUSINESS_PROFILE },
        { step: ClientOnboardingStep.STRUCTURE_AND_OWNERSHIP },
        { step: ClientOnboardingStep.LEGAL_ENTITIES },
        { step: ClientOnboardingStep.PEOPLE },
    ],
    FUNDADMIN_TOP_LEVEL: [
        { step: ClientOnboardingStep.BUSINESS_PROFILE },
        { step: ClientOnboardingStep.STRUCTURE_AND_OWNERSHIP },
        { step: ClientOnboardingStep.LEGAL_ENTITIES },
        { step: ClientOnboardingStep.PEOPLE },
    ],
};

export const CLIENT_ONBOARDING_PROGRESS_STEPS_MAPPING: Record<ClientOnboardingStep, OnboardingStep | null> = {
    BUSINESS_PROFILE: OnboardingStep.STEP_1,
    STRUCTURE_AND_OWNERSHIP: OnboardingStep.STEP_2,
    LEGAL_ENTITIES: OnboardingStep.STEP_3,
    FUNDS: OnboardingStep.STEP_4,
    PEOPLE: OnboardingStep.STEP_5,
};

export interface IClientOnboardingContext {
    steps: IClientOnboardingStep[];
    updateData: () => Promise<unknown>;
    topCoRef: UUID | undefined;
    entityType: EntityType | undefined;
}

export const ClientOnboardingContext = React.createContext<IClientOnboardingContext>({
    topCoRef: void 0,
    updateData: async () => {},
    entityType: EntityType.UNKNOWN,
    steps: [],
});

export const isCompleteOnboardingStep = (step: ClientOnboardingStep, progress: OnboardingStep[]): boolean => {
    return progress.includes(CLIENT_ONBOARDING_PROGRESS_STEPS_MAPPING[step]!);
};

export const isOnboardingComplete = (progress: OnboardingStep[], entityType: EntityType | undefined) => {
    const steps = CLIENT_ONBOARDING_STEPS_MAP[entityType!];
    return !steps || steps.every(({ step }) => isCompleteOnboardingStep(step, progress));
};

const ENTITY_TYPES_MAP: Partial<Record<EntityType, EntityType[]>> = {
    LENDER_TOP_LEVEL: [EntityType.LENDER_INVESTMENT_BANK, EntityType.LENDER_INVESTMENT_MANAGER],
    BORROWER_TOP_LEVEL: [EntityType.BORROWER_SUBSIDIARY],
    AGENT_TOP_LEVEL: [EntityType.AGENT],
    FUNDADMIN_TOP_LEVEL: [EntityType.FUNDADMIN],
};

export const useLegalEntitiesList = (topCoRef: UUID, entityType: EntityType) => {
    const entityTypes = ENTITY_TYPES_MAP[entityType!];
    return useLegalEntitiesSetupListQuery({
        fetchPolicy: 'cache-and-network',
        skip: !topCoRef || !entityTypes,
        variables: {
            topCoRef: topCoRef!,
            entityTypes,
        },
    });
};
