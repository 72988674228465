import React from 'react';
import { DocumentsSelectListRow, IDocumentsSelectListRowItem } from './DocumentsSelectListRow';
import {
    Direction,
    DocPurpose,
    useDocumentsQuery,
    DocumentFilterInput,
    KYCDocTypeFragment,
    KYCDocumentFragment,
} from '../../../../../interfaces/model';
import { GqlQueryList } from '../../../../../lib/dataDisplay/List/GqlQueryList';
import { IDocumentViewDialogParent } from '../DocumentsViewDialog/interfaces';
import { NonFalsy, NOOP_ASYNC } from '../../../../../lib/utils/helpers';
import { IGqlListColumn } from '../../../../../lib/dataDisplay/List';

interface IDocumentsSelectListProps {
    selected: KYCDocumentFragment[];
    onSelect: (selected: KYCDocumentFragment[]) => void;
    skipFetch: boolean;
    filter: DocumentFilterInput;
    parent: IDocumentViewDialogParent | undefined;
    updateData?: () => Promise<void>;
    onDocTypesChange: (docTypes: KYCDocTypeFragment[]) => void;
    multiselect: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
}

export const DocumentsSelectList: React.FC<IDocumentsSelectListProps> = ({
    skipFetch,
    parent,
    filter,
    selected,
    onSelect,
    multiselect,
    updateData = NOOP_ASYNC,
    onDocTypesChange,
    disabled,
    children,
}) => {
    const hideSelect = filter.purpose === DocPurpose.OTHER;

    const COLUMNS: IGqlListColumn<IDocumentsSelectListRowItem>[] = (
        [
            !hideSelect && ({ id: 'id', shrink: true } as const),
            { id: 'icon', shrink: true },
            { id: 'name', label: 'Name' },
            {
                id: 'docType',
                label:
                    !filter.purpose || filter.purpose === DocPurpose.FACILITY ? (
                        <span>
                            Category
                            <br />/ Subcategory
                        </span>
                    ) : (
                        'Category'
                    ),
            },
            { id: 'shares', label: 'Location' },
            { id: 'updatedAt', label: 'Last update' },
            { id: 'actions', label: 'Actions', shrink: true },
        ] as const
    ).filter(NonFalsy);

    return (
        <GqlQueryList
            columns={COLUMNS}
            query={useDocumentsQuery}
            rowComponent={DocumentsSelectListRow}
            rowComponentProps={{
                selected,
                onSelect,
                parent,
                multiselect,
                disabled: disabled || (filter.purpose === DocPurpose.KYC && selected.length > 0),
                updateData,
            }}
            getResult={(data) => data?.documents}
            defaultSortDirection={Direction.DESC}
            defaultSortBy={'updatedAt'}
            variables={{
                parentRef: parent?.id,
                filter: {
                    id: parent?.topCoRef ? void 0 : parent?.id,
                    name: filter.name,
                    purpose: filter.purpose,
                    docType: filter.docType,
                    docSubcategory: filter.docSubcategory,
                    uploadToParentRef: filter.uploadToParentRef,
                    uploadToStorage: filter.uploadToStorage,
                    topCoRef: parent?.topCoRef,
                },
            }}
            onCompleted={(data) => onDocTypesChange(data.documents.docTypes || [])}
            highlightText={filter.name ? String(filter.name) : ''}
            skipFetch={!parent || skipFetch}
            children={children}
        />
    );
};
