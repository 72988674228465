import React from 'react';
import Stack from '@mui/material/Stack';
import { IValueCellProps, ValueCell } from './ValueCell';
import { INoValuePlaceholderProps, NoValuePlaceholder } from '../../../../cdk/NoValuePlaceholder';
import { Box } from '@mui/material';
import { Highlight } from 'react-highlighter-ts';

export interface IDoubleValueCellProps extends IValueCellProps {
    main: React.ReactNode;
    alternative: React.ReactNode;
    alternativeClassName?: string;
    alternativeSuffix?: React.ReactNode;
    children?: React.ReactNode;
    highlightMain?: string;
    showNoValuePlaceholder?: boolean;
    noValuePlaceholderProps?: INoValuePlaceholderProps;
}

export const DoubleValueCell: React.FC<IDoubleValueCellProps> = ({
    main,
    alternative,
    alternativeClassName,
    alternativeSuffix,
    children,
    showNoValuePlaceholder = true,
    noValuePlaceholderProps = { notApplicable: true },
    highlightMain,
    ...props
}) => (
    <ValueCell {...props}>
        <Box display={'flex'} alignItems={'center'}>
            <Stack spacing={0.5}>
                <span className={'subtitle2'}>
                    {main ? (
                        <Highlight search={highlightMain}>{main}</Highlight>
                    ) : (
                        alternative ||
                        (showNoValuePlaceholder ? <NoValuePlaceholder {...noValuePlaceholderProps} /> : null)
                    )}
                </span>
                {main && alternative && (
                    <span className={alternativeClassName}>
                        {alternativeSuffix}
                        {alternative}
                    </span>
                )}
            </Stack>
            {children}
        </Box>
    </ValueCell>
);
