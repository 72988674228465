import React from 'react';
import { DocumentStorage, useStructureAndOwnershipKYCQuery } from '../../../../../../interfaces/model';
import { ClientOnboardingNextStepButton } from '../../ClientOnboardingFrame/ClientOnboardingNextStepButton';
import { IClientOnboardingStepComponent } from '../../interfaces/IClientOnboardingStepComponent';
import { ClientOnboardingContentFrame } from '../../ClientOnboardingContentFrame';
import { StructureAndOwnershipSetupForm } from './StructureAndOwnershipSetupForm';
import { DocumentUploadDialog } from '../../../../../components/Documents/components/DocumentUploadDialog';
import { NonApplicableDocumentDialog } from '../../../../../components/Documents/components/CreateNonApplicableDocumentDialog';
import { NOOP, voidWrapper } from '../../../../../../lib/utils/helpers';
import { AddDocumentsDialog } from '../../../../../components/Documents/components/AddDocumentsDialog';

const DESCRIPTION_TEXT =
    'People with significant control (PSC) and Ultimate Beneficial Owners (UBO) ' +
    'having an equity ownership of 25% or more.';

interface IStructureAndOwnershipSetupProps extends IClientOnboardingStepComponent {
    onUploadSuccess?: () => void;
}

export const StructureAndOwnershipSetup: React.FC<IStructureAndOwnershipSetupProps> = ({
    stepIndex,
    disabled,
    topCo,
    onUploadSuccess = NOOP,
}) => {
    const { data, loading, error, refetch } = useStructureAndOwnershipKYCQuery({
        fetchPolicy: 'cache-and-network',
        skip: !topCo?.id,
        variables: { topCoRef: topCo?.id! },
    });
    const updateData = voidWrapper(refetch);

    return (
        <ClientOnboardingContentFrame
            label={'Structure and Ownership'}
            description={DESCRIPTION_TEXT}
            error={error}
            action={
                <ClientOnboardingNextStepButton
                    stepIndex={stepIndex}
                    disabled={loading || disabled}
                    onClick={async () => {
                        onUploadSuccess();
                        return true;
                    }}
                />
            }
        >
            <StructureAndOwnershipSetupForm
                loading={loading}
                data={data?.entity.kycs || []}
                parent={{
                    id: data?.entity.id || '',
                    name: data?.entity.name || '',
                    shortCode: data?.entity.shortCode || '',
                    topCoRef: data?.entity.topCoRef,
                    docTypes: data?.entity.kycRequirements || [],
                }}
                updateData={updateData}
            />
            <DocumentUploadDialog config={{ storage: DocumentStorage.KYC }} updateData={updateData} />
            <NonApplicableDocumentDialog onSaveSuccess={updateData} />
            <AddDocumentsDialog updateData={updateData} />
        </ClientOnboardingContentFrame>
    );
};
