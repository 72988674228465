import React from 'react';
import Box from '@mui/material/Box';
import { Alert } from '@mui/material';
import { ApolloError } from '@apollo/client';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { DataErrorPlaceholder } from '../../../../lib/dataDisplay/DataErrorPlaceholder';
import { ClientOnboardingNavigationPortal } from './ClientOnboardingFrame/ClientOnboardingNavigationPortal';

interface IClientOnboardingContentFrameProps {
    label: string;
    description?: string;
    action: React.ReactNode;
    component?: React.ElementType;
    error?: ApolloError | undefined;
    children?: React.ReactNode;
}

export const ClientOnboardingContentFrame: React.FC<IClientOnboardingContentFrameProps> = ({
    label,
    description,
    component,
    error,
    action,
    children,
}) => {
    return (
        <Box component={component} display={'flex'} flexDirection={'column'} width={'100%'}>
            <Box marginBottom={'16px'}>
                <h1>{label}</h1>
            </Box>

            {error && <DataErrorPlaceholder error={error} margin={'auto'} />}
            {!error && (
                <>
                    {description && (
                        <>
                            <Box marginBottom={'32px'}>
                                <Alert icon={<TipsAndUpdatesIcon sx={{ fontSize: '24px' }} />} severity={'success'}>
                                    {description}
                                </Alert>
                            </Box>
                        </>
                    )}
                    {children}
                </>
            )}
            <ClientOnboardingNavigationPortal>{action}</ClientOnboardingNavigationPortal>
        </Box>
    );
};
