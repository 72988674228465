import { hasDirectorRelation, IRoleForm } from './RoleEditDialog/useRoleEditFormik/useRoleEditFormik.const';
import { dateToModelValue, timeToModelValue } from '../../../lib/utils/date';
import { Capabilities, RoleInInput } from '../../../interfaces/model';
import { NonFalsy } from '../../../lib/utils/helpers';
import { UserType } from './RoleEditDialog/steps/RoleGeneralInfoEdit/RoleGeneralInfoEdit.const';

export const prepareRoleModelValue = (topCoRef: UUID, role: IRoleForm): RoleInInput => {
    const isDirector = hasDirectorRelation(role.entityRelations);
    const allCapabilities = Array.from(
        new Set(
            [
                ...(role.memberCapabilities || []),
                ...(role.additionalCapabilities || []),
                role.userType === UserType.ADMIN ? Capabilities.MANAGE_STRUCTURE : null,
            ].filter(NonFalsy)
        )
    );

    return {
        email: role.email,
        totpRequired: role.totpRequired,
        entityRelations: (role.entityRelations || [])
            .filter(({ enabled }) => enabled)
            .map(({ id, director, enabled, psc = false, ubo = false }) => ({
                entityId: id,
                enabled,
                director,
                psc,
                ubo,
            })),
        id: role.id,
        isAdmin: role.isAdmin,
        jobTitle: role.jobTitle,
        login: role.login,
        name: role.name,
        password: role.password,
        currentPassword: role.currentPassword,
        allMemberCapabilities: role.allMemberCapabilities,
        allEmailNotifications: role.allEmailNotifications,
        phone: role.phone,
        topCoRef,
        capabilities: allCapabilities,
        superadminCapabilities: (role.superadminCapabilities || []).filter(NonFalsy),
        emailNotifications: (role.emailNotifications || []).filter(NonFalsy),
        birthday: isDirector ? dateToModelValue(role?.birthday) : void 0,
        nationality: isDirector ? role?.nationality : void 0,
        address: isDirector
            ? {
                  streetName: role?.streetName,
                  postcode: role?.postcode,
                  country: role?.country,
                  suite: role?.suite,
                  city: role?.city,
              }
            : void 0,
        sessionTimeout: !!role.sessionTimeout ? role.sessionTimeout * 60 : null,
        autoLogoutAt: role?.logoutAfterSpecificTime ? timeToModelValue(role?.autoLogoutAt) : null,
        timezone: role?.logoutAfterSpecificTime ? role?.timezone : null,
        singleSession: role?.singleSession,
    };
};
