import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, BoxProps } from '@mui/material';
import styles from './BackButton.module.css';

export const BackButton: React.FC<BoxProps> = ({ children, ...props }) => {
    return (
        <Box display={'flex'} alignItems={'center'} className={styles.backButtonContainer} {...props}>
            <ArrowBackIosIcon sx={{ fontSize: '10px' }} />
            {children}
        </Box>
    );
};
