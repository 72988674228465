import React, { forwardRef } from 'react';
import { Box, BoxProps } from '@mui/material';

interface IListCellItemWrapperProps extends BoxProps {
    className?: string;
}

/**
 * Wraps list cell content with negative margins to reduce row height
 */
export const ListCellItemWrapper: React.FC<IListCellItemWrapperProps> = forwardRef(
    ({ children, className, ...props }, ref) => {
        return (
            <Box marginTop={'-8px'} marginBottom={'-8px'} className={className || ''} ref={ref} {...props}>
                {children}
            </Box>
        );
    }
);
