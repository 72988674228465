import { useFormikContext } from 'formik';
import { flattenObject } from '../../utils/helpers';
import { scrollToFirstInvalidControl } from '../../forms';
import { IFormWizardStep } from './FormikWizard';

export const useFormikWizardNavigation = (props: { steps: IFormWizardStep[]; nonLinear?: boolean }) => {
    const formik = useFormikContext<{ currentStep: number }>();
    const { steps, nonLinear } = props;
    const currentStep = formik.values.currentStep || 0;

    const setStepNumber = async (stepNumber: number) => {
        await formik.setFieldValue('currentStep', stepNumber);
    };

    const nextStep = async (nextStepIndex: number) => {
        const isLastStep = nextStepIndex === steps.length;
        const errors = await formik.validateForm();
        const invalidFields = Object.keys(flattenObject(errors));

        const focusOnFirstInvalidField = async () => {
            const firstInvalidStepIndex = steps.findIndex(({ valid }) => !valid);
            if (firstInvalidStepIndex >= 0) {
                await setStepNumber(firstInvalidStepIndex);
                invalidFields.forEach((key) => formik.setFieldTouched(key, true));
                scrollToFirstInvalidControl();
            }
        };

        if (invalidFields.length > 0 && !nonLinear && !isLastStep) {
            await focusOnFirstInvalidField();
        } else if (isLastStep) {
            if (formik.isValid) {
                await formik.submitForm();
            } else {
                await focusOnFirstInvalidField();
            }
        } else {
            await setStepNumber(nextStepIndex);
        }
    };

    const prevStep = async (stepIndex?: number) => {
        const prevStepIndex = stepIndex ?? currentStep - 1;
        await setStepNumber(prevStepIndex < 0 ? 0 : prevStepIndex);
    };

    return { prevStep, nextStep, currentStep };
};
