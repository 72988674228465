import React from 'react';
import { useDispatch } from 'react-redux';
import { ApolloError } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import { appShowSnackbar } from '../../../../App.actions';
import { getTraceIdFromApolloError } from '../../../../../lib/utils/helpers';
import { LoanFund, useSetLoanFundMutation } from '../../../../../interfaces/model';
import { ListIconButton } from '../../../../../lib/dataDisplay/List/components/ListIconButton';
import { useConfirmDialog } from '../../../../../lib/modals/ConfirmDialog';

interface IFundDeleteIconButtonProps {
    fund: Pick<LoanFund, 'id' | 'name'>;
    onSuccess: () => void;
}

export const FundDeleteIconButton: React.FC<IFundDeleteIconButtonProps> = ({ fund, onSuccess }) => {
    const dispatch = useDispatch();
    const [{ open }] = useConfirmDialog();
    const [mutateLoanFund] = useSetLoanFundMutation();
    const handleDelete = async () => {
        try {
            await mutateLoanFund({
                variables: {
                    loanFund: {
                        id: fund.id,
                        deleted: true,
                    },
                },
            });
            onSuccess();
            dispatch(appShowSnackbar({ message: 'Loan fund has been deleted' }));
        } catch (e) {
            dispatch(
                appShowSnackbar({
                    message: 'Failed to delete the loan fund',
                    traceId: getTraceIdFromApolloError(e as ApolloError),
                    severity: 'error',
                })
            );
        }
    };
    const openDialog = (e: React.MouseEvent) => {
        e.preventDefault();
        open({
            body: 'You are about to delete a loan fund. Are you sure?',
            title: `Delete "${fund.name}" loan fund`,
            onConfirm: handleDelete,
        });
    };

    return <ListIconButton icon={Delete} onClick={openDialog} />;
};
