import React from 'react';
import { Link } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { TableRow, Tooltip } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { ListIconButton } from '../../../../../../../lib/dataDisplay/List/components/ListIconButton';
import { ListCellItemWrapper } from '../../../../../../../lib/dataDisplay/List/components/ListCellItemWrapper';
import {
    ActionsCell,
    ColoredTextCell,
    JurisdictionCell,
    TimestampCell,
    ValueCell,
} from '../../../../../../../lib/dataDisplay/List/components/ListCells';
import { useCapability } from '../../../../../../hooks/useCapability';
import { ROLE_VIEW_ROUTE_FACTORY } from '../../../../../../App.routes';
import { IListRow } from '../../../../../../../lib/dataDisplay/List';
import { ledgercommRoleLabels } from '../../../../../../App.const';
import { useRoleEditDialog } from '../../../../../Roles/RoleEditDialog';
import { RoleDeleteIconButton } from './RoleDeleteIconButton';
import { RoleListRowActionsMenu } from './RoleListRowActionsMenu';
import { IconWithTooltip } from '../../../../../../../lib/icons/IconWithTooltip';
import { RoleName } from '../../../../../Roles/RoleName';
import { Capabilities } from '../../../../../../../interfaces/model';

export interface IRoleListItem {
    id: UUID;
    topCoRef: string;
    shortCode: Maybe<string>;
    name: string;
    nationality: Maybe<string>;
    email: Maybe<string>;
    jobTitle: Maybe<string>;
    hasEditAccess: Maybe<boolean>;
    emailVerified: Maybe<boolean>;
    deactivated: boolean;
    lastLoggedIn: Maybe<Timestamp>;
    isAdmin: Maybe<boolean>;
    capabilities: Maybe<Capabilities[]>;
    entityRelations: Maybe<{ director?: boolean }[]> | undefined;
    hasSwitchAccess: boolean;
    actions?: never;
}

export interface IRoleListRowConfig {
    additionalActions?: boolean;
    directorBadge?: boolean;
    adminBadge?: boolean;
}

type IComponentProps = {
    reloadData: () => void;
    config?: IRoleListRowConfig;
};

type IRoleListRowProps = IListRow<IRoleListItem, IComponentProps>;

export const RoleListRow: React.FC<IRoleListRowProps> = ({ data, columns, props }) => {
    const canManageStructure = useCapability(Capabilities.MANAGE_STRUCTURE);
    const [{ open: openRoleEditDialog }] = useRoleEditDialog();
    const { additionalActions = true } = props.config || {};

    return (
        <TableRow component={Link} to={ROLE_VIEW_ROUTE_FACTORY(data.shortCode!)} hover>
            {columns.map(({ id }) => (
                <React.Fragment key={id}>
                    {id === 'name' && (
                        <ValueCell nowrap={false}>
                            <RoleName value={data} config={props.config} />
                        </ValueCell>
                    )}
                    {id === 'email' && (
                        <ValueCell placeholderProps={{ text: 'Not set' }}>
                            <ListCellItemWrapper>
                                {data.email}
                                {data.emailVerified && (
                                    <IconWithTooltip
                                        text={'Verified'}
                                        Icon={VerifiedIcon}
                                        color={'success'}
                                        sx={{ marginLeft: '4px' }}
                                    />
                                )}
                            </ListCellItemWrapper>
                        </ValueCell>
                    )}
                    {id === 'nationality' && <JurisdictionCell value={data.nationality} />}
                    {id === 'jobTitle' && (
                        <ValueCell>
                            <ListCellItemWrapper>
                                {ledgercommRoleLabels[data.jobTitle!] || data.jobTitle}
                                {data.isAdmin && (
                                    <Tooltip title={'System Administrator'}>
                                        <AdminPanelSettingsIcon
                                            color={'primary'}
                                            fontSize={'small'}
                                            sx={{ marginLeft: '4px' }}
                                        />
                                    </Tooltip>
                                )}
                            </ListCellItemWrapper>
                        </ValueCell>
                    )}
                    {id === 'lastLoggedIn' && (
                        <TimestampCell value={data.lastLoggedIn} placeholderProps={{ text: 'Never' }} />
                    )}
                    {id === 'deactivated' && (
                        <ColoredTextCell
                            sx={{ width: '120px' }}
                            value={data.deactivated ? 'deactivated' : 'active'}
                            textMap={{ deactivated: 'Deactivated', active: 'Active' }}
                            colorsMap={{ deactivated: 'secondary', active: 'success' }}
                        />
                    )}

                    {id === 'actions' && (
                        <ActionsCell>
                            {data.hasEditAccess && (
                                <>
                                    <ListIconButton
                                        icon={Edit}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            openRoleEditDialog({
                                                roleId: data.id,
                                                roleName: data.name || 'N/A',
                                                topCoRef: data.topCoRef,
                                            });
                                        }}
                                    />
                                    {canManageStructure && (
                                        <RoleDeleteIconButton role={data} onSuccess={props.reloadData} />
                                    )}
                                    {additionalActions && (
                                        <RoleListRowActionsMenu role={data} updateData={props.reloadData} />
                                    )}
                                </>
                            )}
                        </ActionsCell>
                    )}
                </React.Fragment>
            ))}
        </TableRow>
    );
};
