import { useLocalStorage } from '../../../../../lib/hooks/useLocalStorage';
import { useUserDetails } from '../../../../hooks/useUserDetails';

const LOCALHOST_KEY = 'users';

export interface IControlledUserRecord {
    id: string;
    value: number;
}

type IControlledUser = Record<string, IControlledUserRecord>;

export const useControlledUsersLog = () => {
    const { role } = useUserDetails();
    const [controlledUsers, setControlledUsers] = useLocalStorage<Record<string, IControlledUser>>(LOCALHOST_KEY, {});
    const users = Object.values(controlledUsers[role?.shortCode!] || {});

    const setUser = (user: { shortCode: Maybe<string> } | undefined) => {
        const id = role?.shortCode;
        const shortCode = user?.shortCode;
        const roleRecentUsers = controlledUsers[id!] || {};

        if (shortCode && id) {
            setControlledUsers({
                ...controlledUsers,
                [id]: {
                    ...roleRecentUsers,
                    [shortCode]: {
                        id: shortCode,
                        value: Date.now(),
                    },
                },
            });
        }
    };

    return [users, setUser] as const;
};
