import React, { useEffect } from 'react';
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import { LazyQueryHookOptions, QueryTuple } from '@apollo/client/react/types/types';
import { useFormikField } from '../forms/useFormikField';
import { useDebounce } from '../hooks/useDebounce';

export interface BaseSuggestInputProps<IOption extends { id: string }, TData, TVariables extends { search: string }> {
    query: (options?: LazyQueryHookOptions<TData, TVariables>) => QueryTuple<TData, TVariables>;
    getOptions: (data?: TData) => IOption[] | undefined;
    name: string;
    placeholder?: string;
    transformValue?: (value: string) => string;
    disabled?: boolean;
    debounceDelay?: number;
    id?: string;
}

interface SuggestInputProps<IOption extends { id: string }, TData, TVariables extends { search: string }>
    extends BaseSuggestInputProps<IOption, TData, TVariables> {
    getValue: (values: any) => Maybe<IOption>; // TODO ret rid of any
    getOptionLabel: (option: IOption) => string;
    getMenuOptionLabel: (option: IOption) => string | React.ReactElement;
    onChange: (value: IOption) => void;
}

export function SuggestInput<IOption extends { id: string }, TData, TVariables extends { search: string }>({
    query,
    getOptions,
    name: fieldName,
    placeholder,
    getValue,
    transformValue = (arg: string) => arg,
    disabled,
    debounceDelay = 300,
    getOptionLabel,
    getMenuOptionLabel,
    onChange,
    id,
}: SuggestInputProps<IOption, TData, TVariables>) {
    const [fetchData, { data, loading }] = query({
        fetchPolicy: 'cache-and-network',
    });
    const {
        formik,
        meta: { error, touched },
    } = useFormikField<string, Dict<string>>(fieldName);
    const fieldValue = formik?.values[fieldName] || '';
    const value = getValue(formik?.values);

    const debouncedSearchTerm = useDebounce(fieldValue, debounceDelay);

    useEffect(() => {
        if (debouncedSearchTerm) {
            fetchData({ variables: { search: debouncedSearchTerm } as TVariables });
        }
    }, [debouncedSearchTerm, fetchData]);

    return (
        <Autocomplete
            disableClearable
            fullWidth
            value={value!}
            options={fieldValue?.length > 1 ? getOptions(data) || [] : []}
            loading={loading}
            noOptionsText={!value?.id ? 'Start typing to get suggestions' : `Nothing is found by "${value?.id}"`}
            disabled={formik?.isSubmitting || disabled}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {getMenuOptionLabel(option)}
                </li>
            )}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        id,
                    }}
                    name={fieldName}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && (
                                    <Box display="flex" marginRight="-24px" marginTop="-8px" marginBottom="-8px">
                                        <CircularProgress color="primary" size={20} />
                                    </Box>
                                )}
                                {!loading && params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    fullWidth
                    error={touched && !!error}
                    helperText={touched && error}
                    onChange={(e) => {
                        const newSearch = transformValue(e.target.value);
                        formik?.setFieldValue(fieldName, newSearch);
                    }}
                />
            )}
            onChange={(e, option) => onChange(option as IOption)}
        />
    );
}
