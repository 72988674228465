import isNil from 'lodash/isNil';
import { FormikContext } from 'formik';
import Stack from '@mui/material/Stack';
import React, { useContext } from 'react';
import { FileDropArea, IFileDropAreaProps } from './FileDropArea';
import { FileList } from './FileList';

export const FileDrop: React.FC<IFileDropAreaProps> = ({
    name,
    value: valueProp,
    onValueChange,
    disabled,
    ...props
}) => {
    const formik = useContext(FormikContext);
    const formField = name ? formik?.getFieldProps(name) : void 0;
    const value = (!isNil(valueProp) ? valueProp : formField?.value) || '';

    const handleChange = (newValue: File[]) => {
        if (onValueChange) {
            onValueChange(newValue);
        } else if (formik && name) {
            formik.setFieldValue(name, newValue);
        }
    };

    return (
        <Stack spacing={1}>
            {value.length > 0 && <FileList value={value} onChange={handleChange} disabled={disabled} />}
            {!disabled && (props.multiple || value.length === 0) && (
                <FileDropArea name={name} value={value} onValueChange={handleChange} {...props} />
            )}
        </Stack>
    );
};
