import React from 'react';
import { EntityType, IdType } from '../../../interfaces/model';
import { FACILITIES_ROUTE, FUND_ROUTE, getEntityListRouteByType, ROLE_ROUTE } from '../../../App/App.routes';
import { Breadcrumb } from './Breadcrumb';

interface IRootModel {
    idType: IdType;
    entity?: Maybe<{
        entityType?: EntityType;
    }>;
}

interface IRootBreadcrumbProps {
    value: IRootModel | undefined;
}

const getRootRoute = (value: IRootModel | undefined) => {
    switch (value?.idType) {
        case IdType.ENTITY:
            return getEntityListRouteByType(value.entity?.entityType);
        case IdType.FACILITY:
            return FACILITIES_ROUTE;
        case IdType.LOAN_FUND:
            return FUND_ROUTE;
        case IdType.ROLE:
            return ROLE_ROUTE;
    }
};

const getRootTitle = (model: IRootModel | undefined) => {
    switch (model?.entity?.entityType) {
        case EntityType.AGENT:
        case EntityType.AGENT_TOP_LEVEL:
            return 'Agents';
        case EntityType.SPONSOR:
        case EntityType.SPONSOR_TOP_LEVEL:
            return 'Sponsors';
        case EntityType.BORROWER_SUBSIDIARY:
        case EntityType.BORROWER_TOP_LEVEL:
            return 'Borrowers';
        case EntityType.FUNDADMIN:
        case EntityType.FUNDADMIN_TOP_LEVEL:
            return 'Fund Administrators';
        case EntityType.LENDER_TOP_LEVEL:
        case EntityType.LENDER_INVESTMENT_BANK:
        case EntityType.LENDER_INVESTMENT_MANAGER:
            return 'Lenders';
    }

    switch (model?.idType) {
        case IdType.FACILITY:
            return 'Facilities';
        case IdType.LOAN_FUND:
            return 'Funds';
        case IdType.ROLE:
            return 'Roles';
        default:
            return 'Unknown';
    }
};

export const RootBreadcrumb: React.FC<IRootBreadcrumbProps> = ({ value }) => {
    return <Breadcrumb to={getRootRoute(value)}>{getRootTitle(value)}</Breadcrumb>;
};
